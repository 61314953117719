import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { formGenerator } from '../../../../helpers/formGenerator';
import classes from './style';
import genericClasses from '../../../../theme/GenericClasses';

interface IEditForm {
  form: () => Record<string, any>;
  formik: FormikProps<any>;
  title: string;
}

const EditFormGenerator: FC<IEditForm> = ({ title, form, formik }) =>
  (
    <>
      <Box sx={genericClasses.borderNoneTextField}>
        <Box sx={classes.titleContainer}>
          <Typography sx={classes.titleFont}>{title}</Typography>
        </Box>
        {form().map((item: any) =>
          formGenerator(item, formik))}
      </Box>

      <Box sx={classes.dividerContainer} />
    </>
  );

export default EditFormGenerator;
