import * as Yup from 'yup';
import { AdminAllowanceNames, AllowancesNames } from '@pharmaplan/common';
import { allowanceValidator, hourlyRateValidator } from '../../../helpers/commonValidators';

const Validator = () =>
  Yup.object().shape({
    [AdminAllowanceNames.mealAllowance]: allowanceValidator(),
    [AdminAllowanceNames.accommodationAllowance]: allowanceValidator(),
    [AdminAllowanceNames.travelAllowance]: allowanceValidator(),

    [AdminAllowanceNames.pharmacistHourlyRate]: hourlyRateValidator(),
    [AdminAllowanceNames.pharmacyHourlyRate]: hourlyRateValidator(),
    [AllowancesNames.emergencyFees]: allowanceValidator(),
  });

export default Validator;
