import { FormikValues, useFormik } from 'formik';
import {
  calendarDate,
  getAdminCalendar,
  IAdminDashboardFilterAPIParams,
} from '@pharmaplan/common';

import { adminCalendarParams } from '../../selectors/calendarSelector';
import { setAdminCalendar } from '../../reducers/mainCalendarReducer';
import { appendTimeToDate } from '../../helpers/workshift/functions';
import { MomentTimeFormats } from '../../helpers/Constants';
import { getISO, mapFormat } from '../../helpers/Functions';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';

const { fullDate } = MomentTimeFormats;
export const adminDashboardFilterInitialState = {
  daysInWeek: '',
  banners: [],
  startTime: null,
  endTime: null,
  startDate: null,
  endDate: null,
};

interface IUseAdminDashboardFilter {
  setFilterState: (
    value: React.SetStateAction<{
      submitted: boolean;
      showFilter: boolean;
    }>
  ) => void;
  filterState: {
    submitted: boolean;
    showFilter: boolean;
  };
}
const useAdminDashboardFilter = ({
  setFilterState,
  filterState,
}: IUseAdminDashboardFilter) => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(adminCalendarParams);

  const cDate = useAppSelector(calendarDate);
  const startDate = mapFormat(getISO(cDate.startDate));
  const endDate = mapFormat(getISO(cDate.endDate));

  const handleSubmit = (values: FormikValues) => {
    const {
      startDate: formikStartDate,
      endDate: formikEndDate,
      startTime,
      endTime,
      banners,
      daysInWeek,
    } = values ?? {};

    const sTime = startTime
      ? appendTimeToDate(startDate, startTime).toFormat(fullDate)
      : null;
    const eTime = endTime
      ? appendTimeToDate(startDate, endTime).toFormat(fullDate)
      : null;

    setFilterState({ ...filterState, submitted: true });
    dispatch(setAdminCalendar(values as IAdminDashboardFilterAPIParams));
    dispatch(
      getAdminCalendar({
        startDate: formikStartDate
          ? mapFormat(formikStartDate.toISO())
          : startDate,
        endDate: formikEndDate ? mapFormat(formikEndDate.toISO()) : endDate,
        startTime: sTime,
        endTime: eTime,
        banners,
        daysInWeek,
      }),
    );
  };

  const formik = useFormik({
    initialValues: params,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const resetFilter = () => {
    dispatch(
      getAdminCalendar({
        startDate,
        endDate,
      }),
    );
    setFilterState({ ...filterState, submitted: false });
    dispatch(setAdminCalendar(adminDashboardFilterInitialState));
  };

  return { formik, resetFilter };
};

export default useAdminDashboardFilter;
