import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';

import { MomentTimeFormats } from '../../../helpers/Constants';
import strings from '../../../localization';

import RegistrationStatistics from './RegistrationStatistics';
import ActiveUserStatistics from './ActiveUserStatistics';
import WorkshiftStatistics from './WorkshiftStatistics';
import FinancialInsights from './FinancialInsights';
import StatisticBlock from './StatisticBlock';
import styles from './styles';

const today = new Date();

const PharmaPlanStatistics = () => {
  const currentYear = today.getFullYear();
  const currentQuarter = Math.floor((today.getMonth() + 3) / 3);

  const formik = useFormik({
    initialValues: {
      years: currentYear,
      quarter: currentQuarter,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const { years, quarter } = formik.values ?? {};

  const start = DateTime.now()
    .set({ year: years, month: quarter * 3 - 2, day: 1 })
    .toFormat(MomentTimeFormats.yearMonthDateNumericLuxonStandard);

  const end = DateTime.now()
    .set({ year: years, month: quarter * 3 })
    .endOf('month')
    .toFormat(MomentTimeFormats.yearMonthDateNumericLuxonStandard);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>{strings.expressReport}</Typography>
      <StatisticBlock />
      <Grid rowGap="32px" direction="column" container>
        <Grid item>
          <RegistrationStatistics start={start} end={end} formik={formik} />
        </Grid>
        <Grid item>
          <ActiveUserStatistics start={start} end={end} />
        </Grid>
        <Grid item>
          <WorkshiftStatistics start={start} end={end} />
        </Grid>
        <Grid item>
          <FinancialInsights />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PharmaPlanStatistics;
