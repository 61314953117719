import { useEffect } from 'react';
import { adminPharmaciesInfo, convertToDecimals, getPharmaciesInfo } from '@pharmaplan/common';
import { FormikProps } from 'formik';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';

const useExpressBookingHr = (formik: FormikProps<any>, id: string) => {
  const dispatch = useAppDispatch();
  const isFromCalendar = !id;

  const pharmaciesInfo = useAppSelector(adminPharmaciesInfo);

  const { values, setFieldValue } = formik ?? {};
  const { pharmacyId, pharmacies, pharmacySelected } = values ?? {};
  const { hourlyRate, pharmacyHourlyRate } = pharmaciesInfo ?? {};

  const handlePharmacyRate = () => {
    setFieldValue('pharmacyHourlyRate', convertToDecimals(pharmacyHourlyRate));
    setFieldValue('pharmacistHourlyRate', convertToDecimals(hourlyRate));
  };

  useEffect(() => {
    if (pharmacyId && isFromCalendar) {
      dispatch(getPharmaciesInfo(pharmacyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  useEffect(() => {
    if (isFromCalendar) {
      handlePharmacyRate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourlyRate, pharmacySelected]);

  useEffect(() => {
    if (!pharmacies.trim() && !pharmacySelected && isFromCalendar) {
      setFieldValue('pharmacyHourlyRate', '');
      setFieldValue('pharmacistHourlyRate', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacies, pharmacySelected]);
};

export default useExpressBookingHr;
