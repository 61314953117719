import React, { useEffect } from 'react';
import {
  adminActions,
  adminPharmacistOnVacation,
  ConfirmationTypes,
  pharmacistActions,
  resetStatus,
  selectPharmacistPreSubmitAvailabilities,
  successOrSelector,
} from '@pharmaplan/common';

import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { resetDialog, setDialog } from '../reducers/dialogReducer';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import useAdmin from './useAdmin';

const successAction = [
  pharmacistActions.preSubmitAvailabilities,
  adminActions.adminPreSubmitAvailabilities,
];

const useAvailabilitiesCriteria = (
  confirmAction: () => void,
  workNow = true,
) => {
  const dispatch = useAppDispatch();
  const { isAdmin } = useAdmin();

  const preSubmit = useAppSelector(selectPharmacistPreSubmitAvailabilities);
  const adminPreSubmit = useAppSelector(adminPharmacistOnVacation);

  const success = useAppSelector((state) =>
    successOrSelector(successAction, state));

  const { isPharmacistOnVacation } = (isAdmin ? adminPreSubmit : preSubmit) ?? {};

  const showWarning = () => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.availabilitiesConflict}
            confirmAction={() => {
              confirmAction();
              dispatch(resetDialog());
            }}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: false,
      }),
    );
  };

  useEffect(() => {
    console.log(workNow);
    if (success && workNow) {
      dispatch(resetStatus(successAction));
      if (isPharmacistOnVacation) {
        showWarning();
        return;
      }
      confirmAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, isPharmacistOnVacation]);
};

export default useAvailabilitiesCriteria;
