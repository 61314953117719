const classes = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBlock: '16px 24px',
  },
  text: {
    fontSize: '14px',
    color: '#4a4056',
  },
};

export default classes;
