import { TypeOfUser } from '@pharmaplan/common';
import { createAction } from '@reduxjs/toolkit';

export const actionTypes = {
  SET_DATA: 'SET_DATA',
  RESET_ALL: 'RESET_ALL',
};

export const setData = createAction<{
  data: object;
  userType: TypeOfUser;
  type: string;
  email: string;
}>(actionTypes.SET_DATA);

export const resetAll = createAction(actionTypes.RESET_ALL);
