import React, { FC, useEffect } from 'react';

import { Stack } from '@mui/system';

import {
  adminActions,
  adminGetPharmacistNotes,
  adminPharmacistNotes,
  getPharmacyDetails,
  successSelector,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { IDynamicTableObject } from '../../../DynamicTable/types';
import DynamicTable from '../../../DynamicTable';
import { renderScreen, setBack } from '../../../../actions/drawerActions';
import { ScreenTypes } from '../../../../helpers/Constants';

import {
  adminReplacementNotesRows,
  adminReplacementsNotesHeaders,
} from './helper';
import AdminPharmacyCard from '../../../common/AdminPharmacyCard';

interface IAdminReplacementNotes {
  pharmacistId: string;
  pharmacyId: string;
}

const AdminReplacementNotes: FC<IAdminReplacementNotes> = ({
  pharmacistId,
  pharmacyId,
}) => {
  const dispatch = useAppDispatch();

  const loadSuccess = useAppSelector((state) =>
    successSelector([adminActions.pharmacistNotes], state));

  const notes = useAppSelector(adminPharmacistNotes);

  const table: IDynamicTableObject = {
    headerBar: [],
    headers: adminReplacementsNotesHeaders(),
    rows: adminReplacementNotesRows(notes),
  };

  const goBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 8,
        screenType: ScreenTypes.pharmacistList,
        pharmacistId,
      }),
    );
  };

  useEffect(() => {
    dispatch(adminGetPharmacistNotes({ pharmacistId, pharmacyId }));
    dispatch(getPharmacyDetails(pharmacyId));
    dispatch(setBack(goBack));
    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const back = () => {
    dispatch(
      renderScreen({
        screenNumber: 7,
        screenType: ScreenTypes.pharmacistList,
        pharmacistId,
        pharmacyId,
      }),
    );
  };

  return (
    <Stack>
      <AdminPharmacyCard pharmacyId={pharmacyId} back={back} />
      <DynamicTable
        showHeader={false}
        noContainerStyling
        loadSuccess={loadSuccess}
        table={table}
        hidePagination
      />
    </Stack>
  );
};

export default AdminReplacementNotes;
