import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import GoogleMapReact from 'google-map-react';
import { Grid, Box, IconButton } from '@mui/material';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';

import {
  getTypeOfUser,
  setAddress,
  setLocation,
  userLocation,
} from '@pharmaplan/common';
import ReverseGeocode from '@pharmaplan/common/helpers/ReverseGeocode';
import MyLocationIcon from '@mui/icons-material/MyLocation';

import { SystemCssProperties } from '@mui/system';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import CustomButton from '../../../common/CustomButton/CustomButton';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';
import { resetDialog } from '../../../../reducers/dialogReducer';

import Marker from './Marker/Marker';
import { defaultValues, truncateDecimals } from './helper';
import style, {
  control,
  indicatorsContainer,
  input,
  menu,
  message,
  option,
  placeholder,
} from './style';

interface IProfileLocationMapView {
  setSelected?: Dispatch<SetStateAction<string>>;
  customStyles?: Record<string, object>;
  isSignup?: boolean;
  isModal?:boolean;
}

const defaultProps = {
  customStyles: {},
  isSignup: false,
};

const { center, zoom } = defaultValues;
const { lng: defaultLongitude, lat: defaultLatitude } = center;

const assertCss = (assertStyle?: object) =>
assertStyle as SystemCssProperties;

const ProfileLocationMapView = ({
  setSelected,
  customStyles,
  isSignup,
  isModal,
}: IProfileLocationMapView) => {
  const dispatch = useAppDispatch();
  const { height } = useWindowDimensions();

  const location = useAppSelector(userLocation);
  const user = useAppSelector(getTypeOfUser);

  const { latitude, longitude } = location ?? {};

  const [mapPlace, setMapPlace] = useState({
    center: {
      lat: latitude ?? defaultLatitude,
      lng: longitude ?? defaultLongitude,
    },
  });

  const { center: mapCenter } = mapPlace ?? {};
  const { lat: mapLat, lng: mapLng } = mapCenter ?? {};

  const [value, setValue] = useState<null | {
    value: string;
    label: string;
  }>(null);

  const classes = style(height);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMapPlace({
            center: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
          setValue(null);
        },
        (error) => {
          console.error(error);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleSubmit = () => {
    ReverseGeocode(mapLat, mapLng, process.env.REACT_APP_GEO_KEY ?? '').then(
      (address) => {
        dispatch(setAddress(address));
        dispatch(
          setLocation({
            latitude: truncateDecimals(mapLat, 10),
            longitude: truncateDecimals(mapLng, 10),
          }),
        );
      },
    );

    if (!isSignup) {
      setSelected?.(user === strings.pharmacy ? 'coordinates' : 'contacts');
    }

    if (isSignup || isModal) {
      dispatch(resetDialog());
    }
  };

  const handleMapChange = (e: GoogleMapReact.ChangeEventValue) => {
    setMapPlace((prev) =>
      ({ ...prev, center: e.center }));
  };

  useEffect(() => {
    const locationVal = value !== null ? value?.label : '';

    if (locationVal) {
      geocodeByAddress(locationVal)
        .then((results) =>
          getLatLng(results[0]))
        .then(({ lat, lng }: { lat: number; lng: number }) => {
          setMapPlace((prevState) =>
            ({ ...prevState, center: { lat, lng } }));
        });
    }
  }, [value]);

  return (
    <Grid
      xs={12}
      container
      sx={[classes.container, assertCss(customStyles?.container)]}
    >
      <Grid
        item
        xs={6}
        sx={[classes.selectContainer, assertCss(customStyles?.subContainer)]}
      >
        <GooglePlacesAutocomplete
          debounce={500}
          selectProps={{
            placeholder: strings.mapSelectLabel,
            value,
            onChange: setValue,
            isClearable: true,
            isSearchable: true,
            styles: {
              input,
              option,
              control,
              menu,
              noOptionsMessage: message,
              loadingMessage: message,
              singleValue: message,
              valueContainer: message,
              placeholder,
              indicatorsContainer,
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Box sx={classes.mapViewContainer}>
          <GoogleMapReact
            defaultCenter={center}
            center={mapCenter}
            defaultZoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onChange={handleMapChange}
          />

          <Marker />

          <Box sx={classes.currentLocationPinContainer}>
            <IconButton onClick={getUserLocation}>
              <MyLocationIcon />
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Grid
        sx={[
          classes.buttonContainer,
          isSignup ? assertCss(customStyles?.footer) : {},
        ]}
        container
      >
        <CustomButton onClick={handleSubmit} label={strings.confirm} />
      </Grid>
    </Grid>
  );
};

ProfileLocationMapView.defaultProps = defaultProps;

export default ProfileLocationMapView;
