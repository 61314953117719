import { ChartTypes, ICharts } from '../../../common/Charts/ChartBuilder/types';
import strings from '../../../../localization';
import { approvedPendingStatsKey } from '../../../../helpers/Constants';
import ThemeConstants from '../../../../theme/ThemeConstants';
import { monthValueFormatter } from '../../../../helpers/Functions';

const { pending, approved } = approvedPendingStatsKey;

export const newRegistrationsConfig = (
  registeredPharmacies: Array<any>,
  registeredPharmacists: Array<any>,
): Array<ICharts> =>
  [
    {
      key: 1,
      type: ChartTypes.bar,
      userTypeTitle: strings.pharmacies,
      chartSettings: {
        height: 239,
        dataset: registeredPharmacies,
        yAxis: [{ id: 'leftAxis', tickMinStep: 1 }],
        xAxis: [
          {
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: monthValueFormatter,
          },
        ],
        series: [
          {
            dataKey: 'data',
            type: 'bar',
            color: ThemeConstants.chartColors.green100,
            label: strings.newUserRegistrations,
          },
        ],
      },
    },
    {
      key: 2,
      userTypeTitle: strings.pharmacists,
      type: ChartTypes.bar,
      chartSettings: {
        height: 239,
        dataset: registeredPharmacists,
        xAxis: [
          {
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: monthValueFormatter,
          },
        ],
        yAxis: [{ id: 'leftAxis', tickMinStep: 1 }],
        series: [
          {
            type: 'bar',
            dataKey: 'data',
            label: strings.newUserRegistrations,
            color: ThemeConstants.chartColors.blue100,
          },
        ],
      },
    },
  ];

export const registrationActivationStatus = (
  pendingApprovedPharmacies: Array<any>,
  pendingApprovedPharmacists: Array<any>,
): Array<ICharts> =>
  [
    {
      key: 1,
      type: ChartTypes.bar,
      userTypeTitle: strings.pharmacies,
      chartSettings: {
        height: 239,
        dataset: pendingApprovedPharmacies,
        xAxis: [
          {
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: monthValueFormatter,
          },
        ],
        yAxis: [{ id: 'leftAxis', tickMinStep: 1 }],
        series: [
          {
            type: 'bar',
            dataKey: pending,
            label: strings.pharmaciesPendingActivation,
            color: ThemeConstants.chartColors.blue800,
          },
          {
            type: 'bar',
            dataKey: approved,
            label: strings.formatString(
              strings.approvedUsers,
              strings.pharmacies,
            ) as string,
            color: ThemeConstants.chartColors.green800,
          },
        ],
      },
    },
    {
      key: 2,
      type: ChartTypes.bar,
      userTypeTitle: strings.pharmacists,
      chartSettings: {
        height: 239,
        dataset: pendingApprovedPharmacists,
        xAxis: [
          {
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: monthValueFormatter,
          },
        ],
        yAxis: [{ id: 'leftAxis', tickMinStep: 1 }],
        series: [
          {
            type: 'bar',
            dataKey: pending,
            label: strings.pharmacistsPendingActivation,
            color: ThemeConstants.chartColors.green900,
          },
          {
            type: 'bar',
            dataKey: approved,
            label: strings.formatString(
              strings.approvedUsers,
              strings.pharmacists,
            ) as string,
            color: ThemeConstants.chartColors.blue900,
          },
        ],
      },
    },
  ];
