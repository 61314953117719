import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  HelpTypes,
  adminActions,
  adminResetValue,
  getAdminReportsFilters,
  resetStatus,
  successOrSelector,
  MiscType,
  adminReports,
} from '@pharmaplan/common';
import { FormikValues, useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useAppDispatch } from '../useAppDispatch';
import { IDynamicTableObject } from '../../components/DynamicTable/types';

import useAdminReportsHandler from './useAdminReportsHandler';
import useAutoComplete from '../useAutocomplete';
import {
  MomentTimeFormats,
  customDropdownTypes,
} from '../../helpers/Constants';
import { useAppSelector } from '../useAppSelector';
import useDrawer from '../useDrawer';
import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../localization';
import useTableSelectCheckbox from '../useTableSelectCheckbox';
import { hasAction } from '../../components/Reports/helpers';
import useWorkshiftDelete from '../Admin/useWorkshiftDelete';
import useTableSort from '../useTableSort';

const { All } = customDropdownTypes;
const { pharmacist } = HelpTypes;

const initialValues = {
  type: '',
  startDate: null,
  endDate: null,
  replacementType: All,
  pharmacistId: '',
  pharmacists: '',
  pharmacistSelected: '',
  availabilityStatus: All,
};

const {
  approveBooking,
  requestCancellation,
  deletePharmacistAvailabilities,
  expressBooking,
  deletePharmacyWorkshift,
  editAdminWorkshift,
  deleteMultipleWorkshift,
} = adminActions;

const successActions = [
  approveBooking,
  requestCancellation,
  deletePharmacistAvailabilities,
  expressBooking,
  deletePharmacyWorkshift,
  editAdminWorkshift,
  deleteMultipleWorkshift,
];
const deleteActions = [deletePharmacistAvailabilities, deletePharmacyWorkshift, deleteMultipleWorkshift];

const useAdminReports = () => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const { isDesc, orderBy, handleSorting, order, resetSorting } = useTableSort();

  const data = useAppSelector(adminReports);

  const selectableData = useMemo(
    () =>
      data?.data
        .filter((item) =>
          hasAction(item.startDate))
        .map((item) =>
          item.workShiftId),
    [data.data],
  );

  const {
    selectAll,
    selectedIds,
    handlePress,
    handleSelectedIds,
    handleSelectAll,
    allIds,
    handleReset: resetCheckboxes,
  } = useTableSelectCheckbox({
    page,
    useOfEdit: false,
    userType: MiscType.All,
    dataLength: selectableData.length,
  });

  const { showMultipleWorkshiftsDelete } = useWorkshiftDelete();

  const customSelectAll = (toBeSet: boolean) => {
    let ids: Array<string> = [];

    if (toBeSet) {
      ids = selectableData;
      handleSelectedIds({ [page]: ids });
    } else {
      handleSelectedIds({ [page]: [] });
    }

    handleSelectAll({ [page]: toBeSet });
  };

  const deleteWorkshifts = () => {
    showMultipleWorkshiftsDelete(allIds);
  };

  const { api, reportType, headers, rows, handleDropdown } = useAdminReportsHandler(selectedIds, handlePress);

  const { closeDrawer } = useDrawer();

  const { debouncedInput } = useAutoComplete({
    userTypes: [pharmacist],
  });

  const successes = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const deleteSuccess = useAppSelector((state) =>
    successOrSelector(deleteActions, state));

  const onSubmit = (values: FormikValues) => {
    const {
      startDate,
      endDate,
      replacementType,
      pharmacistId,
      availabilityStatus,
      pharmacistSelected,
    } = values ?? {};

    setPage(1);
    resetCheckboxes();

    dispatch(
      api({
        startDate: startDate
          ? (startDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        endDate: endDate
          ? (endDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        type: reportType,
        page: 1,
        replacementType: handleDropdown(replacementType) as number,
        availabilityStatus: handleDropdown(availabilityStatus) as number,
        pharmacistId: pharmacistSelected ? pharmacistId : '',
      }),
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  const { totalCount } = data ?? {};
  const { resetForm, values } = formik ?? {};
  const {
    replacementType,
    availabilityStatus,
    pharmacists,
    pharmacistId,
    startDate,
    endDate,
  } = values ?? {};

  const handledType = handleDropdown(replacementType) as number;
  const handledStatus = handleDropdown(availabilityStatus) as number;

  const callWithFilters = (pagination?: number, sortBy?: string, desc?: boolean) => {
    dispatch(
      api({
        startDate: startDate
          ? (startDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        endDate: endDate
          ? (endDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        page: pagination ?? 1,
        type: reportType,
        replacementType: handledType,
        availabilityStatus: handledStatus,
        pharmacistId: values.pharmacistSelected ? pharmacistId : '',
        sortBy,
        desc,
      }),
    );
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    const gotoPage = selectedPage + 1;
    setPage(gotoPage);
    callWithFilters(gotoPage, orderBy, isDesc);
  };

  const table: IDynamicTableObject = {
    headerBar: [],
    headers,
    rows,
  };

  const handleReset = () => {
    resetForm();
    setPage(1);
    dispatch(adminResetValue(['adminReports']));
  };

  useEffect(() => {
    if (successes && startDate && endDate) {
      callWithFilters(page);
      closeDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successes]);

  useEffect(() => {
    debouncedInput(pharmacists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacists]);

  useEffect(() => {
    resetSorting();
    dispatch(getAdminReportsFilters(reportType));
    if (startDate && endDate) {
      callWithFilters();
      setPage(1);
    } else {
      handleReset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (deleteSuccess) {
      showSuccess(dispatch, strings.recordDeletedSuccessfully);
      resetCheckboxes();
      dispatch(resetStatus(deleteActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      callWithFilters(1, orderBy, isDesc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  return {
    table,
    page,
    totalCount,
    handlePagination,
    handleReset,
    formik,
    selectAll,
    deleteWorkshifts,
    selectAllFunc: customSelectAll,
    selectableData,
    orderBy,
    isDesc,
    handleSorting,
    order,
  };
};

export default useAdminReports;
