import { FormikValues, useFormik } from 'formik';
import { adminActions, adminGetAllNotes, adminGetAllPharmacyNotes, adminGetNote, adminGetPharmacyNote, adminNote, adminPharmacyNote, adminUpdateNote, adminUpdatePharmacyNote, resetStatus, successOrSelector } from '@pharmaplan/common';
import { useEffect } from 'react';
import { AdminNotesType } from '../../../helpers/Constants';
import { useAppDispatch } from '../../useAppDispatch';
import Validator from '../../../components/Admin/AdminNotes/validator';
import { useAppSelector } from '../../useAppSelector';
import { resetDialog } from '../../../reducers/dialogReducer';

const { pharmacist } = AdminNotesType;

const successAction = [adminActions.updateNote, adminActions.updatePharmacyNote];

const useUpdateAdminNotes = (noteId: string, id: string, notesType: AdminNotesType) => {
  const dispatch = useAppDispatch();

  const noteItem = useAppSelector(adminNote);
  const pharmacyNoteItem = useAppSelector(adminPharmacyNote);

  const updateSuccess = useAppSelector((state) =>
    successOrSelector(successAction, state));

  const isPharmacist = notesType === pharmacist;

  const { note: pharmacistNote } = noteItem ?? {};
  const { note: pharmacyNote } = pharmacyNoteItem ?? {};

  const note = isPharmacist ? pharmacistNote : pharmacyNote;

  const onSubmit = (values: FormikValues) => {
    const { note: formikNote } = values ?? {};

    isPharmacist
      ? dispatch(
        adminUpdateNote({
          adminPharmacistNoteId: noteId,
          notes: formikNote,
        }),
      )
      : dispatch(
        adminUpdatePharmacyNote({
          adminPharmacyNoteId: noteId,
          notes: formikNote,
        }),
      );
  };

  const getAllNotes = () => {
    isPharmacist ? dispatch(adminGetAllNotes(id)) : dispatch(adminGetAllPharmacyNotes(id));
  };

  const formik = useFormik({
    initialValues: {
      note,
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: Validator(),
  });

  useEffect(() => {
    isPharmacist
      ? dispatch(adminGetNote(noteId))
      : dispatch(adminGetPharmacyNote(noteId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      getAllNotes();
      dispatch(resetStatus(successAction));
      dispatch(resetDialog());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  return { formik };
};

export default useUpdateAdminNotes;
