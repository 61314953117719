import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    display: 'flex',
    paddingTop: '12px',
  },

  labelContainer: {
    flex: 2,
    marginX: '5px',
    textAlign: 'left',
  },

  valueContainer: {
    flex: 3,
    textAlign: 'left',
  },

  labelFont: {
    color: '#8690AA',
    fontWeight: 400,
    fontSize: 13,
  },

  valueFont: {
    color: '#161C2C',
    fontSize: 14,
    fontWeight: 500,
    marginRight: '10px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
