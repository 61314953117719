const classes = {
  horizontalTimePicker: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& label': {
      flex: 2,
    },
    '& .MuiTextField-root': {
      flex: 4,
    },
  },
};

export default classes;
