import { SxProps, Theme } from '@mui/system';

const classes = {
  formLabel: {
    color: 'primary.inputLabel',
    '&.Mui-focused': {
      color: 'primary.inputLabel',
    },
  },

  radioLabelText: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      color: 'common.grey850',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
