/* eslint-disable react/no-danger */
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import {
  getTermsAndConditions,
  istermsAgreed,
  termsAndConditionsHTMLTemplate,
} from '@pharmaplan/common';
import { DialogContent } from '@mui/material';
import classes from './styles';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import CustomDialogActions from '../../common/CustomModal/ModalCommon/CustomDialogActions/CustomDialogActions';
import strings from '../../../localization';
import { resetDialog } from '../../../reducers/dialogReducer';

const TermsAndConditionsModal: React.FC<{ isCancellation?: boolean }> = ({
  isCancellation,
}) => {
  const dispatch = useAppDispatch();
  const termsAndConditionsHTML = useAppSelector(termsAndConditionsHTMLTemplate);

  useEffect(() => {
    dispatch(getTermsAndConditions());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DialogContent>
        <Box sx={classes.container} px={5}>
          <div dangerouslySetInnerHTML={{ __html: termsAndConditionsHTML }} />
        </Box>
      </DialogContent>
      {!isCancellation && (
        <Box sx={classes.footerContainer}>
          <CustomDialogActions
            primaryBtnTitle={strings.agree}
            primaryFunction={() => {
              dispatch(istermsAgreed(true));
              dispatch(resetDialog());
            }}
            secondaryFunction={() => {
              dispatch(istermsAgreed(false));
              dispatch(resetDialog());
            }}
            secondaryBtnTitle={strings.cancel}
          />
        </Box>
      )}
    </>
  );
};

export default TermsAndConditionsModal;
