export default {
  container: {
    backgroundColor: 'white',
    maxWidth: 781,
    width: '100%',
    padding: 5,
    height: 'auto',
  },
  buttons: {
    padding: 2,
  },
  back: { mb: '15px' },
  backText: {
    fontWeight: '500',
    fontSize: 16,
    ml: 1,
  },
};
