import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import strings from '../../../localization';
import classes from './styles';
import { Constants } from '../../../helpers/Constants';

const defaultProps = {};

const SignupHeader = () =>
  (
    <Box component="div" sx={classes.container}>
      <Box component="div" sx={classes.joinTextContainer}>
        <Typography component="span" sx={classes.joinText}>{strings.join}</Typography>
        <Typography component="span" sx={classes.pharmaPlanText}>
          {strings.pharma}
          {strings.plan}
        </Typography>
        <Box component="div">
          <Typography component="span" sx={classes.alreadyMemberText}>
            {strings.alreadyMember}
            <Link href={Constants.paths.login} underline="hover" sx={classes.loginText}>
              {strings.login}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );

SignupHeader.defaultProps = defaultProps;

export default SignupHeader;
