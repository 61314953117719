import { SxProps, Theme } from '@mui/system';

const classes = {
  mainContianer: {
    padding: '24px',
  },

  description: {
    alignItems: 'self-start',
    marginBottom: '40px',
    '& label': {
      flex: 2,
    },
    '& .MuiTextField-root': {
      flex: '3 !important',
    },
  },

  dropdown: {
    marginBottom: '31px',
    '& label': {
      flex: 2,
    },
    '& .MuiOutlinedInput-root': {
      flex: '3 !important',
    },
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
