import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    mb: '20px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  detailContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: '600',
    fontSize: 20,
    color: 'common.grey850',
  },
  subTitle: {
    fontSize: 12,
    color: 'common.gray',
  },
  divider: {
    height: '13px',
    width: '1px',
    mx: '16px',
    backgroundColor: 'common.borderInput',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
