import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import {
  adminActions,
  selectMatchingAvailabilities,
  successSelector,
} from '@pharmaplan/common';

import PharmacyCard from '../../SelfService/PharmacyCard';
import DynamicTable from '../../DynamicTable';
import WithSkeleton from '../../common/WithSkeleton';

import useMatchingAvailabilities, {
  IMatchingAvailabilities,
} from '../../../hooks/MatchingAvailabilities/useMatchingAvailabilities';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { Constants, ReplacementTypes } from '../../../helpers/Constants';
import strings from '../../../localization';

import OtherAvailabilities from './OtherAvailabilities/OtherAvailabilities';
import AvailabilitiesHeader from './AvailabilitiesHeader/AvailabilitiesHeader';
import AvailabilitiesFooter from './AvailabilitiesFooter';
import classes from './style';
import { handleClick } from './helper';
import NoAvailabilities from './NoAvailabilities/NoAvailabilities';
import genericClasses from '../../../theme/GenericClasses';

const MatchingAvailabilities: FC<IMatchingAvailabilities> = ({
  workshiftId,
  type,
  fromScreen,
}) => {
  const matchingAvailabilities = useAppSelector(selectMatchingAvailabilities);

  const successAction = adminActions.getMatchingAvailabilities;
  const loadSuccess = useAppSelector((state) =>
    successSelector([successAction], state));

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: Constants.variant.text,
  })(Typography);

  const {
    city,
    typeReplacement,
    matchingAvailabilities: availabilitiesData,
  } = matchingAvailabilities ?? {};

  const emptyContainerComponent = availabilitiesData.length === 0 ? (
    <NoAvailabilities message={strings.noMatchingAvailabilitiesToShow} />
  ) : null;

  const { table, showOtherAvailabilities, setShowOtherAvailabilities } = useMatchingAvailabilities({ workshiftId, type, fromScreen });

  return (
    <>
      <Box sx={classes.root}>
        <PharmacyCard
          type={type}
          event={matchingAvailabilities}
          successAction={successAction}
          showPharmacyName
          CustomContent={(
            <SkeletonTypography sx={classes.cityFont}>
              {city}
            </SkeletonTypography>
          )}
          CustomFooter={(
            <>
              <SkeletonTypography sx={classes.replacementTitle}>
                {strings.replacementType}
              </SkeletonTypography>
              <SkeletonTypography sx={classes.replacement}>
                {ReplacementTypes()[typeReplacement]}
              </SkeletonTypography>
            </>
          )}
        />
      </Box>

      <DynamicTable
        table={table}
        hidePagination
        loadSuccess={loadSuccess}
        showHeader={false}
        noContainerStyling
        customTableHeadStyle={classes.tableHead}
        stickyHeader
        customContainerStyle={genericClasses.tableScroll}
        emptyContainerComponent={emptyContainerComponent}
        customHeader={
          <AvailabilitiesHeader title={strings.matchingAvailabilities} />
        }
        customFooter={
          showOtherAvailabilities && (
            <AvailabilitiesFooter
              label={strings.showOtherAvailabilities}
              onClick={handleClick(setShowOtherAvailabilities, false)}
            />
          )
        }
      />

      {!showOtherAvailabilities && (
        <OtherAvailabilities
          workshiftId={workshiftId}
          type={type}
          setShowOtherAvailabilities={setShowOtherAvailabilities}
        />
      )}
    </>
  );
};

export default MatchingAvailabilities;
