import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const styles = {
  container: {
    paddingX: '24px',
    paddingBottom: '20px',
  },
  title: {
    color: ThemeConstants.grey[800],
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '10px',
  },
  checkboxLabel: {
    fontSize: 14,
  },
  horizontalField: {
    '& fieldset': { border: 'none' },
    '& label': {
      flex: 2,
      marginBottom: 3,
    },
    '& .MuiTextField-root': {
      flex: 1,
    },
  },
  timeContainer: {
    display: 'flex',
    gap: '30px',
    marginBottom: '24px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
