import React, { FC, useEffect, useState } from 'react';

import { Box } from '@mui/system';

import {
  AdminLegendVariant,
  adminResetValue,
  getPharmacistBookingsList,
  getPharmacistBookingsListPaginations,
  getPharmacistDetails,
  pharmacistBookingList,
} from '@pharmaplan/common';
import { renderScreen, setFooterShow } from '../../../actions/drawerActions';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import AdminPharmacistCard from '../Profile/Pharmacist/PharmacistViewProfile/AdminPharmacistCard';
import PharmacistBookingList from './PharmacistBookingList';
import { OtherScreens, ScreenTypes } from '../../../helpers/Constants';

interface IPharmacistBookings {
  pharmacistId: string;
}

const resetKey = 'pharmacistBookingList';

const PharmacistBookings: FC<IPharmacistBookings> = ({ pharmacistId }) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);

  const pharmacistBookings = useAppSelector(pharmacistBookingList);

  useEffect(() => {
    dispatch(getPharmacistDetails(pharmacistId));
    dispatch(getPharmacistBookingsList({ pharmacistId, paging: { page: 1 } }));
    dispatch(setFooterShow(false));
    return () => {
      dispatch(adminResetValue([resetKey]));
    };
  }, []);

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        getPharmacistBookingsListPaginations({ pharmacistId, paging: { page } }),
      );
    }
  }, [page]);

  const ratingGoBack = () => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.pharmacistList,
        screenNumber: 2,
        pharmacistId,
      }),
    );
  };

  return (
    <Box>
      <AdminPharmacistCard
        pharmacistId={pharmacistId}
        type={AdminLegendVariant.posted}
        ratingGoBack={ratingGoBack}
        hasOwner={false}
        fromScreen={OtherScreens.AdminPharmacistBookingList}
      />
      <PharmacistBookingList
        data={pharmacistBookings.data}
        page={page}
        setPage={setPage}
      />
    </Box>
  );
};

export default PharmacistBookings;
