import { SxProps, Theme } from '@mui/system';

const classes = {
  timeContainer: {
    display: 'flex',
    gap: '30px',
    marginBottom: '16px',
    '& .MuiInputBase-root': {
      border: '1px solid',
      borderColor: 'primary.main',
      color: 'common.grey850',
      filter: 'drop-shadow(0px 4px 15px rgba(72, 161, 77, 0.15));',
    },
  },

  titleTime: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'grey.500',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
