import React, { useEffect, useMemo, useState } from 'react';

import { FormikValues, useFormik } from 'formik';
import { Grid } from '@mui/material';

import {
  getLanguages,
  getPharmacyAdminProfile,
  language,
  pharmacyAdminActions,
  pharmacyAdminProfile,
  resetStatus,
  successSelector,
  updatePharmacyAdminProfile,
} from '@pharmaplan/common';

import ProfileFormGenerator from '../../Profile/ProfileForm/ProfileFormGenerator';
import ResponsiveClasses from '../../../theme/ResponsiveClasses';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import ContentContainer from '../../common/ContentContainer';
import ProfileSettings from '../../Profile/ProfileSettings';
import AdsContainer from '../../common/AdsContainer';

import { pharmacyAdminGeneralProfile } from './helper';
import validator from './validator';
import { showSuccess } from '../../Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../../localization';
import useGetItems from '../../../hooks/useGetItems';
import useLanguage from '../../../hooks/useLanguge';

const successAction = pharmacyAdminActions.updatePharmacyAdminProfile;

const PharmacyAdminProfile = () => {
  const dispatch = useAppDispatch();

  const { getLanguageCode } = useGetItems();
  const { changeLang } = useLanguage();

  const [selected, setSelected] = useState('general');

  const languages = useAppSelector(getLanguages);
  const currentLang = useAppSelector(language);

  const profile = useAppSelector(pharmacyAdminProfile);
  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const handleSelection = (screen: string) => {
    setSelected(screen);
  };

  const handleSubmit = (values: FormikValues) => {
    const {
      email,
      languageId,
      name,
      description,
      phone,
      mobile,
      province,
      pharmacyIds,
      address,
      pharmacyCompanyId,
      city,
      postalCode,
    } = values ?? {};

    dispatch(
      updatePharmacyAdminProfile({
        pharmacyCompanyId,
        name,
        email,
        languageId,
        description,
        pharmacyIds,
        phone,
        mobile,
        province,
        address,
        city,
        postalCode,
      }),
    );
  };

  const { pharmacies, pharmacyCompany, provinces } = profile ?? {};
  const { user } = pharmacyCompany ?? {};

  const imageList = useMemo(
    () =>
      pharmacies?.map((item) => {
        const { id, logoId, userName, email } = item;
        return {
          id,
          logoId,
          name: userName,
          email,
        };
      }),
    [pharmacies],
  );

  const formik = useFormik({
    initialValues: {
      ...user,
      ...pharmacyCompany,
      pharmacyIds: pharmacies.map((item) =>
        item.id),
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: validator,
  });

  const profileMap = pharmacyAdminGeneralProfile(
    languages,
    imageList,
    provinces,
  ).general;

  useEffect(() => {
    dispatch(getPharmacyAdminProfile());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      const languageCode = getLanguageCode(formik.values.languageId);
      const isCurrentAndUserLangSame = languageCode === currentLang;

      if (!isCurrentAndUserLangSame) {
        changeLang(languageCode || currentLang);
      }

      showSuccess(dispatch, strings.savedSuccessfully);
      dispatch(resetStatus([successAction]));
      dispatch(getPharmacyAdminProfile());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Grid spacing={2} container>
      {/* Settigns list */}
      <Grid xs={3} lg={2} item>
        <ProfileSettings selected={selected} onClick={handleSelection} />
      </Grid>
      {/* Content List */}
      <Grid xs={9} lg={8} item>
        <ContentContainer>
          {profileMap.map((item) => {
            const { form, title, hideSave } = item;
            return (
              <ProfileFormGenerator
                key={title}
                formik={formik}
                items={form()}
                title={title}
                hideSave={hideSave}
              />
            );
          })}
        </ContentContainer>
      </Grid>
      <Grid sx={ResponsiveClasses.hideOnLg} lg={2} item>
        <AdsContainer />
      </Grid>
    </Grid>
  );
};

export default PharmacyAdminProfile;
