import React, { useEffect, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  adminActions,
  adminBroadcastGroup,
  getBroadcastGroup,
  getSelectedIds,
  resetStatus,
  setSelectedIds,
  successSelector,
  updateBroadcastGroup,
} from '@pharmaplan/common';

import { FormikValues } from 'formik';
import { Typography } from '@mui/material';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import {
  BroadcastGroupActionType,
  ScreenTypes,
} from '../../../helpers/Constants';
import strings from '../../../localization';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import styles from '../../../components/Admin/BroadcastList/styles';
import useDrawer from '../../useDrawer';
import { renderScreen } from '../../../actions/drawerActions';
import { selectOpen } from '../../../selectors/drawerSelector';

interface IUseBroadcastGroupEdit {
  type: BroadcastGroupActionType;
  buttonClicked: boolean;
}

const { create, edit } = BroadcastGroupActionType;
const successAction = adminActions.updateBroadcastGroup;

const useBroadcastGroupEdit = ({ type, buttonClicked }: IUseBroadcastGroupEdit) => {
  const dispatch = useAppDispatch();

  const broadcastGroup = useAppSelector(adminBroadcastGroup);
  const editIds = useAppSelector(getSelectedIds);

  const navigate = useNavigate();
  const { openDrawer } = useDrawer();

  const { broadcastGroupUsers } = broadcastGroup ?? {};
  const ids = useMemo(
    () =>
      broadcastGroupUsers.map((item) =>
        item.userId),
    [broadcastGroupUsers],
  );

  const success = useAppSelector((state) =>
    successSelector([successAction], state));
  const isDrawerClosed = useAppSelector(selectOpen);

  const { state } = useLocation();

  const { id, groupName } = state ?? {};

  const isEdit = type === edit;

  const header = `${strings.editGroup} - ${groupName}`;
  const selectedUsersTitle = `${strings.selectedUsers} (${ids.length})`;

  const showDetails = () => {
    openDrawer();
    dispatch(
      renderScreen({
        screenNumber: 1,
        screenType: ScreenTypes.broadcastList,
        eventId: id,
        extraParameters: {
          enableDelete: true,
        },
      }),
    );
  };

  const NumberOfIdComponent = () =>
    (
      <Typography sx={styles.title}>
        {header}
        <Typography sx={styles.selectedUsers} component="a" onClick={showDetails}>
          {selectedUsersTitle}
        </Typography>
      </Typography>
    );

  const title = {
    [create]: strings.createNewGroup,
    [edit]: <NumberOfIdComponent />,
  };

  const confirmEditAction = (selectedIds: Array<string>) =>
    (values: FormikValues) => {
      const { groupName: formikName } = values ?? {};
      dispatch(
        updateBroadcastGroup({
          groupId: id,
          groupName: formikName,
          userIds: selectedIds,
        }),
      );
    };

  useEffect(() => {
    dispatch(setSelectedIds(ids));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  useEffect(() => {
    if (!isDrawerClosed && isEdit) {
      dispatch(getBroadcastGroup(id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerClosed]);

  useEffect(() => {
    if (success && buttonClicked) {
      showSuccess(dispatch, strings.updatedSuccessfullyFormat);
      navigate(-1);
      dispatch(resetStatus([successAction]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return { title: title[type], confirmEditAction, editIds, isEdit, isDrawerClosed };
};

export default useBroadcastGroupEdit;
