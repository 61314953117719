import React from 'react';

import {
  adminActivateUser,
  adminDeletePendingUser,
  ConfirmationTypes,
  HelpTypes,
} from '@pharmaplan/common';
import { setDialog } from '../../reducers/dialogReducer';
import { useAppDispatch } from '../useAppDispatch';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import { setFooter } from '../../actions/drawerActions';
import strings from '../../localization';

const usePharmacistProfileActions = (userType: HelpTypes) => {
  const dispatch = useAppDispatch();

  const deleteUser = (personnelId: string) =>
    () => {
      dispatch(
        adminDeletePendingUser({
          id: personnelId,
          userType,
        }),
      );
    };

  const activateUser = (personnelId: string) =>
    () => {
      dispatch(
        adminActivateUser({
          id: personnelId,
          userType,
        }),
      );
    };

  const confirmAction = (confirmationType: ConfirmationTypes, personnelId: string) =>
    () => {
      const action = confirmationType === ConfirmationTypes.activateUser
        ? activateUser
        : deleteUser;

      dispatch(
        setDialog({
          Component: (
            <ConfirmationModal
              confirmAction={action(personnelId)}
              type={confirmationType}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: false,
        }),
      );
    };

  const handleButton = (id: string) => {
    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.activate,
            onClick: confirmAction(ConfirmationTypes.activateUser, id),
          },
          secondaryButton: {
            label: strings.delete,
            onClick: confirmAction(ConfirmationTypes.deleteEntry, id),
          },
        },
      }),
    );
  };
  return { handleButton };
};

export default usePharmacistProfileActions;
