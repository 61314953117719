const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  subContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingX: 5,
    paddingBottom: 2,
  },
  button: {
    paddingX: 5,
  },
  title: {
    fontSize: 24,
    color: 'grey.500',
  },
  message: {
    textAlign: 'center',
    fontWeight: '500',
    color: 'common.grey850',
  },
  closeContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    color: 'common.grey350',
    margin: '10px',
    backgroundColor: 'common.lightBackgroundColor',
    padding: '3px',
  },
  buttonContainer: { marginBottom: '40px' },
  topPadding: {
    minHeight: 52,
  },
};

export default classes;
