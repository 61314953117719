const classes = {
  customRadioButtonContainer: {
    paddingRight: '10px',
  },
  tableBorderHide: {
    borderBottom: '0px!important',
  },
  formCell: {
    borderBottom: '0px!important',
    alignItems: 'center',
  },
  headerClass:
    { '& .MuiSlider-thumb': {
      display: 'none',
    },
    '& .MuiSlider-rail': {
      display: 'none',
    },
    '& .MuiSlider-mark': {
      display: 'none',
    },
    '& .MuiSlider-track': {
      display: 'none',
    },

    },
};

export default classes;
