import React from 'react';
import { Box, Grid, SxProps } from '@mui/material';
import { FormikProps } from 'formik';
import { SystemStyleObject } from '@mui/system';
import classes from './style';

interface ICustomLabel {
  label: string;
  name: string;
  formik?: FormikProps<any>;
  xs: number;
  image?: boolean;
  value?: string;
  customClass?: SxProps;
}
const defaultProps = {
  image: false,
  formik: null,
  value: null,
  customClass: {},
};

const CustomLabel = ({
  label,
  image,
  formik,
  name,
  xs,
  value,
  customClass,
}: ICustomLabel) => {
  const handleValue = formik?.values?.[name] || value;
  return (
    <Grid
      xs={xs}
      sx={[classes.container, customClass as SystemStyleObject]}
      container
    >
      <Grid xs={12} sx={classes.label} item>
        {label}
      </Grid>
      <Grid xs={12} sx={classes.value} item>
        {image ? (
          <Box
            component="img"
            style={classes.image}
            src={handleValue}
            alt="logo banner"
          />
        ) : (
          handleValue
        )}
      </Grid>
    </Grid>
  );
};

CustomLabel.defaultProps = defaultProps;
export default CustomLabel;
