import React, { Fragment } from 'react';

import { Box, Divider, Grid, Typography } from '@mui/material';
import { FormikValues, useFormik } from 'formik';

import {
  IAdminConfiguration,
  adminConfiguration,
  editAdminConfiguration,
} from '@pharmaplan/common';

import CustomButton from '../../../common/CustomButton';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { formGenerator } from '../../../../helpers/formGenerator';
import strings from '../../../../localization';

import { adminEditFormConfig } from './helper';
import { adminConfigurationValidator } from './validator';
import styles from './style';
import useSelfService from '../../../../hooks/useSelfService';

const EditAdminConfiguration = () => {
  const dispatch = useAppDispatch();
  const { isSelfService } = useSelfService();

  const adminConfig = useAppSelector(adminConfiguration);

  const onSubmit = (values: FormikValues) => {
    const {
      minHourWorkshift: formikMinHoursWorkshift,
      pharmacyRate: formikPharmacyRate,
      pharmacistRate: formikPharmacistRate,
      ratePharmacistDays: formikRatePharmacistDays,
      technicianRate: formikTechnicianRate,
      cancellationPercentFees: formikCancellationPercent,
      pharmaPlanPercentFees: formikPercentFees,
      noCancellationPeriod: formikCancellationPeriod,
      bookingStateExpiration: formikBookingState,
      freeCancellationPeriod: formikFreeCancellation,
      reminder1After: formikReminder1,
      reminder2Before: formikReminder2,
      enableChat,
    } = values ?? {};

    const modifiedValues = {
      ...values,
      ratePharmacistDays: parseInt(formikRatePharmacistDays, 10),
      minHourWorkshift: parseFloat(formikMinHoursWorkshift),
      pharmacyRate: parseFloat(formikPharmacyRate),
      pharmacistRate: parseFloat(formikPharmacistRate),
      technicianRate: parseFloat(formikTechnicianRate),
      enableChat,
    };

    const selfServiceValues = {
      ...modifiedValues,
      pharmaPlanPercentFees: parseFloat(formikPercentFees),
      cancellationPercentFees: parseFloat(formikCancellationPercent),
      noCancellationPeriod: parseInt(formikCancellationPeriod, 10),
      bookingStateExpiration: parseInt(formikBookingState, 10),
      freeCancellationPeriod: parseInt(formikFreeCancellation, 10),
      reminder1After: parseInt(formikReminder1, 10),
      reminder2Before: parseInt(formikReminder2, 10),
    };

    dispatch(
      editAdminConfiguration(
        isSelfService
          ? { ...(selfServiceValues as IAdminConfiguration) }
          : { ...(modifiedValues as IAdminConfiguration) },
      ),
    );
  };

  const formik = useFormik({
    initialValues: { ...adminConfig },
    enableReinitialize: true,
    onSubmit,
    validationSchema: adminConfigurationValidator,
  });

  const { handleSubmit } = formik ?? {};

  return (
    <Box sx={styles.root}>
      {adminEditFormConfig().map((item) => {
        const { key, title, form } = item ?? {};

        return (
          <Fragment key={key}>
            <Box sx={styles.container}>
              <Typography sx={styles.titleFont}>{title}</Typography>
              <Divider sx={styles.divider} />
            </Box>

            <Grid container xs={12}>
              {form().map((formItem) =>
                formGenerator(formItem, formik))}
            </Grid>
          </Fragment>
        );
      })}
      <CustomButton
        customButtonStyle={styles.button}
        customClass={styles.buttonContainer}
        label={strings.save}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default EditAdminConfiguration;
