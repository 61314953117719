import { useState } from 'react';

import {
  calendarDate,
  calendarFilter,
  getPharmacistCalendar,
  getPharmacyCalendar,
  TypeOfUser,
} from '@pharmaplan/common';

import useUser from './useUser';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';

const useSaveAndRemain = () => {
  const dispatch = useAppDispatch();

  const [saveAndRemain, setSaveAndRemain] = useState(false);
  const cDate = useAppSelector(calendarDate);
  const filter = useAppSelector(calendarFilter);

  const { userType } = useUser();

  const refreshCalendar = () => {
    dispatch(
      userType === TypeOfUser.pharmacist
        ? getPharmacistCalendar({ ...filter, ...cDate })
        : getPharmacyCalendar(cDate),
    );
  };

  const handleSetSave = (callback: () => void) =>
    () => {
      setSaveAndRemain(true);
      callback();
    };

  const resetSaveAndRemain = () => {
    setSaveAndRemain(false);
  };

  return { saveAndRemain, handleSetSave, resetSaveAndRemain, refreshCalendar };
};

export default useSaveAndRemain;
