import {
  AverageFinancialKey,
  FinancialStatsKey,
} from '../../../../helpers/Constants';
import { currencyFormatter, hoursFormatter, monthValueFormatter } from '../../../../helpers/Functions';
import strings from '../../../../localization';
import ThemeConstants from '../../../../theme/ThemeConstants';
import {
  ChartTypes,
  IBarChart,
  ICompositeChart,
} from '../../../common/Charts/ChartBuilder/types';

const { bar, composite } = ChartTypes;
const { averageHourlyProfit, averagePharmacistRate, averagePharmacyRate } = AverageFinancialKey;
const { totalBookedHours, pharmacistPayout, pharmacyBilling, grossProfit } = FinancialStatsKey;

export const financialInsightsStatsConfig = (
  dataset: Array<any>,
): Array<ICompositeChart> =>
  [
    {
      key: 1,
      type: composite,
      xs: 12,
      useDoubleYAxis: true,
      margin: {
        right: 70,
      },
      rightLabelXTranslate: '20px',
      chartSettings: {
        height: 239,
        dataset,
        series: [
          {
            type: 'bar',
            dataKey: pharmacyBilling,
            color: ThemeConstants.chartColors.green250,
            label: strings.pharmacyBilling,
            valueFormatter: currencyFormatter,
          },
          {
            type: 'bar',
            dataKey: pharmacistPayout,
            color: ThemeConstants.chartColors.pink900,
            label: strings.pharmacistPayout,
            valueFormatter: currencyFormatter,
          },
          {
            type: 'line',
            dataKey: grossProfit,
            color: ThemeConstants.chartColors.blue300,
            label: strings.pharmaplanGrossProfit,
            valueFormatter: currencyFormatter,
          },
          {
            type: 'line',
            dataKey: totalBookedHours,
            yAxisId: 'rightAxis',
            color: ThemeConstants.chartColors.pink980,
            label: strings.totalBookedHours,
            valueFormatter: hoursFormatter,
          },
        ],
        yAxis: [{ id: 'leftAxis' }, { id: 'rightAxis', label: `${strings.hours} (Hrs)` }],
        xAxis: [{ scaleType: 'band', dataKey: 'month', valueFormatter: monthValueFormatter }],
      },
    },
  ];

export const averageFinancialStatsConfig = (
  mergedDataset: Array<any>,
): Array<IBarChart> =>
  [
    {
      key: 1,
      type: bar,
      xs: 12,
      chartSettings: {
        height: 239,
        dataset: mergedDataset,
        yAxis: [{ id: 'leftAxis' }, { id: 'rightAxis' }],
        xAxis: [{ scaleType: 'band', dataKey: 'month', valueFormatter: monthValueFormatter }],
        series: [
          {
            type: 'bar',
            dataKey: averagePharmacyRate,
            label: strings.averagePharmacyRate,
            color: ThemeConstants.chartColors.averagePharmacyRate,
            valueFormatter: currencyFormatter,
          },
          {
            type: 'bar',
            dataKey: averagePharmacistRate,
            label: strings.averagePharmacistRate,
            color: ThemeConstants.chartColors.averagePharmacistRate,
            valueFormatter: currencyFormatter,
          },
          {
            type: 'bar',
            dataKey: averageHourlyProfit,
            label: strings.averageHourlyProfit,
            color: ThemeConstants.chartColors.averageHourlyRate,
            valueFormatter: currencyFormatter,
          },
        ],
      },
    },
  ];
