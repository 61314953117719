import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { FormikProps } from 'formik';

import PendingTabs from '../../../PendingTabs';
import strings from '../../../../../localization';
import SelectAllHeader from '../../../../DynamicTable/SelectAllHeader';
import { ListOfWorkshiftsTabTypes } from '../../../../../helpers/Constants';

import ListOfWorkshiftsInfo from './ListOfWorkshiftsInfo';
import { tabConfig, workshiftStatuses } from './helper';
import styles from './style';

interface IListOfWorkshiftsHeader {
  formik: FormikProps<any>;
  setSelected: React.Dispatch<React.SetStateAction<ListOfWorkshiftsTabTypes>>;
  selectedTab: ListOfWorkshiftsTabTypes;
  selectAll: boolean;
  handleSelectAll: (toBeSet: boolean) => void;
  handleDelete: () => void;
  selectableData: Array<string>;
}

const ListOfWorkshiftsHeader: FC<IListOfWorkshiftsHeader> = ({
  selectedTab,
  formik,
  setSelected,
  selectAll,
  handleSelectAll,
  handleDelete,
  selectableData,
}) => {
  const handleTabClick = (tab: ListOfWorkshiftsTabTypes) => {
    setSelected(tab);
  };

  const isPast = selectedTab === ListOfWorkshiftsTabTypes.pastWorkshifts;
  const statuses = workshiftStatuses(isPast);
  const noSelectableData = selectableData?.length === 0;

  return (
    <Box>
      <Box sx={styles.root}>
        <Typography sx={styles.title}>{strings.listOfWorkshifts}</Typography>
      </Box>

      <ListOfWorkshiftsInfo
        selectedTab={selectedTab}
        formik={formik}
        workshiftStatuses={statuses}
      />

      <PendingTabs
        selectedTab={selectedTab}
        tabConfig={tabConfig(handleTabClick)}
      />
      {!noSelectableData && !isPast && (
        <SelectAllHeader
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
          showDelete
          handleDelete={handleDelete}
        />
      )}
    </Box>
  );
};

export default ListOfWorkshiftsHeader;
