import * as Yup from 'yup';
import strings from '../../localization';

const ResendEmailValidator = () =>
  Yup.object().shape({
    sendEmailToPharmacist: Yup.boolean(),
    sendEmailToPharmacy: Yup.boolean().test(
      'at-least-one-checked',
      strings.selectAtleastOneEmailOptions,
      function atLeastOneChecked() { // Named function here
        const { sendEmailToPharmacist, sendEmailToPharmacy } = this.parent;
        return sendEmailToPharmacist || sendEmailToPharmacy;
      },
    ),
  });

export default ResendEmailValidator;
