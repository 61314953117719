import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const styles = {
  free: {
    backgroundColor: ThemeConstants.common.freeGray,
    color: ThemeConstants.common.blue600,
  },
  booked: {
    backgroundColor: ThemeConstants.admin.bookedLight,
    color: ThemeConstants.admin.booked,
  },
  posted: {
    backgroundColor: ThemeConstants.admin.postedLight,
    color: ThemeConstants.admin.posted,
  },
  requested: {
    backgroundColor: ThemeConstants.admin.requestedLight,
    color: ThemeConstants.admin.requested,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
