const classes = {
  secondaryButton: {
    color: 'grey.800',
  },

  modalButton: {
    fontSize: '1rem',
    paddingTop: '1px',
    paddingBottom: '1px',
    minWidth: '80px',
    minHeight: '36px',
    fontWeight: '500',
  },
  modalActionsContainer: {
    padding: '0px 40px 20px 20px;',
  },
};

export default classes;
