import {
  PharmacistActionsBasedStatKey,
  PharmacyActionsBasedStatKey,
} from '../../../../helpers/Constants';
import { monthValueFormatter } from '../../../../helpers/Functions';
import strings from '../../../../localization';
import ThemeConstants from '../../../../theme/ThemeConstants';
import { ChartTypes, ICharts } from '../../../common/Charts/ChartBuilder/types';

const { createWorkshift, updatePharmacy, deleteWorkshift, updateWorkshift } = PharmacyActionsBasedStatKey;
const {
  createAvailability,
  updateAvailability,
  updatePharmacist,
  requestBooking,
} = PharmacistActionsBasedStatKey;

export const activeUserLoginStatistics = (
  activePharmacies: Array<any>,
  activePharmacists: Array<any>,
): Array<ICharts> =>
  [
    {
      key: 1,
      type: ChartTypes.bar,
      userTypeTitle: strings.pharmacies,
      chartSettings: {
        height: 239,
        dataset: activePharmacies,
        xAxis: [
          {
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: monthValueFormatter,
          },
        ],
        yAxis: [{ id: 'leftAxis', tickMinStep: 1 }],
        series: [
          {
            type: 'bar',
            dataKey: 'data',
            label: strings.formatString(
              strings.activeUsers,
              strings.pharmacies.toLowerCase(),
            ) as string,
            color: ThemeConstants.chartColors.purple300,
          },
        ],
      },
    },
    {
      key: 2,
      type: ChartTypes.bar,
      userTypeTitle: strings.pharmacists,
      chartSettings: {
        height: 239,
        dataset: activePharmacists,
        xAxis: [
          {
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: monthValueFormatter,
          },
        ],
        yAxis: [{ id: 'leftAxis', tickMinStep: 1 }],
        series: [
          {
            type: 'bar',
            dataKey: 'data',
            label: strings.formatString(
              strings.activeUsers,
              strings.pharmacists.toLowerCase(),
            ) as string,
            color: ThemeConstants.chartColors.pink650,
          },
        ],
      },
    },
  ];

export const statsBasedOnActions = (
  pharmacyActions: Array<any>,
  pharmacistActions: Array<any>,
): Array<ICharts> =>
  [
    {
      key: 1,
      type: ChartTypes.bar,
      userTypeTitle: strings.pharmacies,
      chartSettings: {
        height: 239,
        dataset: pharmacyActions,
        xAxis: [
          {
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: monthValueFormatter,
          },
        ],
        yAxis: [{ id: 'leftAxis' }],
        series: [
          {
            type: 'bar',
            dataKey: createWorkshift,
            label: strings.createWorkshift,
            color: ThemeConstants.chartColors.purple200,
          },
          {
            type: 'bar',
            dataKey: updateWorkshift,
            label: strings.updateWorkshift,
            color: ThemeConstants.chartColors.orange500,
          },
          {
            type: 'bar',
            dataKey: deleteWorkshift,
            label: strings.deleteWorkshift,
            color: ThemeConstants.chartColors.green200,
          },
          {
            type: 'bar',
            dataKey: updatePharmacy,
            label: strings.updateProfile,
            color: ThemeConstants.chartColors.pink600,
          },
        ],
      },
    },
    {
      key: 2,
      type: ChartTypes.bar,
      userTypeTitle: strings.pharmacists,
      chartSettings: {
        height: 239,
        dataset: pharmacistActions,
        yAxis: [{ id: 'leftAxis' }],
        xAxis: [
          {
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: monthValueFormatter,
          },
        ],
        series: [
          {
            type: 'bar',
            dataKey: createAvailability,
            label: strings.createAvailability,
            color: ThemeConstants.chartColors.green950,
          },
          {
            type: 'bar',
            dataKey: updateAvailability,
            label: strings.updateAvailability,
            color: ThemeConstants.chartColors.orange900,
          },
          {
            type: 'bar',
            dataKey: requestBooking,
            label: strings.requestBooking,
            color: ThemeConstants.chartColors.pink800,
          },
          {
            type: 'bar',
            dataKey: updatePharmacist,
            label: strings.updateProfile,
            color: ThemeConstants.chartColors.grey200,
          },
        ],
      },
    },
  ];
