import { Box } from '@mui/material';
import React from 'react';
import { InputTypes } from '../../../../helpers/Constants';
import strings from '../../../../localization';
import { ReactComponent as UploadIcon } from '../../../../assets/svg/upload.svg';
import classes from './style';
import genericClasses from '../../../../theme/GenericClasses';

interface IUploadBox {
  fileType: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
}

const UploadBox = ({ fileType, onChange, onDrop }: IUploadBox) => {
  const inputFile = React.useRef<HTMLInputElement>(null);

  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      onDrop(e);
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  const onContainerClick = () => {
    inputFile?.current?.click();
  };

  return (
    <div className="UploadBox-container" data-testid="test">
      <Box
        component="div"
        tabIndex={0}
        onKeyDown={onContainerClick}
        onClick={onContainerClick}
        onDragEnter={dragEvents.onDragEnter}
        onDragLeave={dragEvents.onDragLeave}
        onDragOver={dragEvents.onDragOver}
        onDrop={dragEvents.onDrop}
        style={classes.customUploadInputMainContainer}
        data-testid="test"
      >
        <Box sx={classes.customUploadInputContainer} component="div">
          <>
            <UploadIcon style={classes.customUploadInputIcon} />
            <Box component="div">
              <input
                style={genericClasses.hidden}
                accept={fileType.accept}
                ref={inputFile}
                onChange={handleChange}
                type={InputTypes.file}
              />
              <Box component="span" sx={genericClasses.bold}>
                {strings.clickToUpload}
              </Box>
              {` ${strings.or} ${strings.dragAndDrop}`}
            </Box>
            <Box component="div">{fileType.note}</Box>
          </>
        </Box>
      </Box>
    </div>
  );
};

export default UploadBox;
