import React, { FC, useEffect } from 'react';

import { Box } from '@mui/system';

import {
  AdminLegendVariant,
  IAdminRequestData,
  adminActions,
  adminEvent,
  getAdminEvent,
  isLoadingSelector,
  resetStatus,
} from '@pharmaplan/common';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { setFooterShow, setTitle } from '../../../actions/drawerActions';
import strings from '../../../localization';
import DrawerOutOfDateTitle from '../../common/DrawerOutOfDateTitle';

import SingleRequest from './SingleRequest';

interface IRequestList {
  date: string;
}

const RequestList: FC<IRequestList> = ({ date }) => {
  const dispatch = useAppDispatch();
  const successAction = adminActions.getAdminEvent;

  const loading = useAppSelector((state) =>
    isLoadingSelector([successAction], state));
  const requestList = useAppSelector(adminEvent);

  useEffect(() => {
    const title = (
      <DrawerOutOfDateTitle
        date={date}
        dateLabel={strings.requestedWorkshiftsFormatted}
      />
    );

    dispatch(setTitle({ title }));
    dispatch(setFooterShow(false));
    dispatch(
      getAdminEvent({
        type: AdminLegendVariant.requested,
        date: date.split('T')[0],
      }),
    );

    return () => {
      dispatch(resetStatus([adminActions.getAdminEvent]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Box>
      {loading ? (
        <SingleRequest
          successAction={adminActions.getAdminEvent}
          event={requestList?.[0] as IAdminRequestData}
        />
      ) : (
        (requestList as IAdminRequestData[]).map((item) =>
          (
            <SingleRequest
              successAction={adminActions.getAdminEvent}
              key={item.workshiftId}
              event={item}
            />
          ))
      )}
    </Box>
  );
};

export default RequestList;
