// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import BackIcon from '@mui/icons-material/ArrowBack';

import { Grid, IconButton, Typography } from '@mui/material';

import {
  getLanguages,
  getProfileUtils,
  pharmacistRegions,
  proInfo,
  userContact,
  getTypeOfUser,
  userCoordinates,
  userPharmacyInfo,
  istermsAgreed,
  isPharmacyAdminLoginSelector,
  TypeOfUser,
} from '@pharmaplan/common';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useSelfService from '../../../hooks/useSelfService';
import useProfileForm from '../../../hooks/useProfileForm/useProfileForm';
import useUserDelete from '../../../hooks/useUserDelete';
import {
  pharmacistSignupFields,
  pharmacySignupFields,
} from '../../SignupFlow/signupForms';
import strings from '../../../localization';

import {
  IPharmacistForm,
  IPharmacistSignupForm,
  IPharmacyForm,
  IPharmacySignupForm,
} from '../types';
import { pharmacistFields, pharmacyField } from './Forms';
import ProfileFormGenerator from './ProfileFormGenerator';
import style from './style';

interface IProfileForm {
  selected: string;
  formik: any;
  setSelected?: React.Dispatch<React.SetStateAction<string>>;
  isSignup?: boolean;
}

const defaultProps = {
  isSignup: false,
};

type a =
  | IPharmacistForm
  | IPharmacyForm
  | IPharmacySignupForm
  | IPharmacistSignupForm
  | object;

const { pharmacist, pharmacy } = TypeOfUser;

const ProfileForm = ({
  setSelected,
  selected,
  formik,
  isSignup,
}: IProfileForm) => {
  const dispatch = useAppDispatch();

  const { isSelfService } = useSelfService();
  const { showPickYourLocation, showTermsAndConditions } = useProfileForm();
  const isPharmacyAdminLogin = useAppSelector(isPharmacyAdminLoginSelector);

  const allLanguages = useAppSelector(getLanguages);
  const proInfoData = useAppSelector(proInfo);
  const proUtils = useAppSelector(getProfileUtils);
  const contact = useAppSelector(userContact);
  const regions = useAppSelector(pharmacistRegions);
  const userType = useAppSelector(getTypeOfUser);
  const coordinates = useAppSelector(userCoordinates);
  const userPharmacy = useAppSelector(userPharmacyInfo);

  const { handleDelete } = useUserDelete();

  const isPharmacist = userType === pharmacist;
  const isPharmacy = userType === pharmacy;

  const handleChange = (e: boolean) =>
    dispatch(istermsAgreed(e));

  const form = (): a => {
    if (isPharmacist) {
      return isSignup
        ? pharmacistSignupFields({
          proUtils,
          regions,
          showTermsAndConditions,
          showPickYourLocation,
          handleChange,
          isSelfService,
          contactPreferences: proUtils?.contactPreferences ?? [],
        })
        : pharmacistFields({
          languages: allLanguages,
          proInfoData,
          contact,
          proUtils,
          regions,
          isSelfService,
          handleDelete,
          setSelected,
          contactPreferences: proUtils?.contactPreferences ?? [],
        });
    }

    if (isPharmacy) {
      return isSignup
        ? pharmacySignupFields({
          softwares: proUtils?.softwares ?? [],
          provinces: proUtils?.provinces ?? [],
          banners: proUtils?.banners ?? [],
          contactPreferences: proUtils?.contactPreferences ?? [],
          showTermsAndConditions,
          showPickYourLocation,
          handleChange,
          isSelfService,
        })
        : pharmacyField({
          languages: allLanguages,
          softwares: userPharmacy?.softwares || [],
          provinces: coordinates?.provinces ?? [],
          isSelfService,
          handleDelete,
          setSelected,
          contactPreferences: userPharmacy?.contactPreferences ?? [],
          isPharmacyAdmin: isPharmacyAdminLogin,
        });
    }

    return {};
  };

  const handlerRender = () => {
    if (!Array.isArray(form()?.[selected as keyof typeof form])) {
      switch (form()[selected as keyof typeof form]?.type) {
        case 'single':
          return (
            <ProfileFormGenerator
              formik={formik}
              items={form()[selected as keyof a].form()}
              title={form()[selected as keyof a].title}
              hideSave={isSignup}
            />
          );

        case 'table':
          return (
            <ProfileFormGenerator
              formik={formik}
              items={form()[selected as keyof a]?.form()}
              title={form()[selected as keyof a]?.title}
              table
              hideSave={isSignup}
              isSignup={isSignup}
            />
          );
        case 'map':
          return (
            <ProfileFormGenerator
              formik={formik}
              items={form()[selected as keyof a]?.form()}
              title={form()[selected as keyof a]?.title}
              map
              setSelected={setSelected}
              hideSave
              isSignup={isSignup}
            />
          );
        default:
          return null;
      }
    } else {
      return form()?.[selected as keyof a]?.map((item) =>
        (
          <ProfileFormGenerator
            key={item.name}
            formik={formik}
            hideSave={item.hideSave}
            items={item.form()}
            title={item.title}
          />
        ));
    }
  };
  return (
    <Grid sx={style.container} container>
      {form()[selected as keyof typeof form]?.back && (
        <IconButton
          onClick={() =>
            setSelected?.(form()[selected as keyof typeof form]?.back)}
          style={style.backContainer}
        >
          <BackIcon />
          <Typography>{strings.back}</Typography>
        </IconButton>
      )}
      {handlerRender()}
    </Grid>
  );
};

ProfileForm.defaultProps = defaultProps;

export default ProfileForm;
