import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from '../controllers/httpClient';
import UserController from '../controllers/UserController';
import { AppIDType } from '../models/IAppID';
import { IMultipleAvailabilities } from '../models/Models';
import {
  IChangePasswordParams,
  IContact,
  IGeneral,
  IProfessionalInfo,
} from '../models/PharmacistModels';
import { statusDispatch } from './globalActions';
import { IAdminMultipleAvailabilityCalendar } from '../models';

export const userActions = {
  login: 'user/LOGIN',
  profile: 'user/PROFILE',
  general: 'user/GENERAL',
  generalUpdate: 'user/GENERAL_UPDATE',
  professionalInformation: 'PROFESSIONALINFORMATION',
  professionalInformation_update: 'PROFESSIONALINFORMATION_UPDATE',
  contact: 'user/CONTACT',
  contactUpdate: 'user/CONTACT_UPDATE',
  logos: 'user/LOGOS',
  logoById: 'user/LogoById',
  logout: 'user/logout',
  setLanguage: 'set/user/language',
  changePassword: 'user/changePassword',
  setMultipleAvailabilities: 'calendar/setMultiple',
};

export const logout = createAction(userActions.logout);
export const setMultipleAvailabilities = createAction<
  Array<IAdminMultipleAvailabilityCalendar>
>(userActions.setMultipleAvailabilities);


export const updateUserContact = createAsyncThunk(
  userActions.contactUpdate,
  async (values: IContact, { rejectWithValue }) => {
    try {
      const resp = await UserController.contactUpdate(values);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserContact = createAsyncThunk(
  userActions.contact,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.contact();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserGeneral = createAsyncThunk(
  userActions.general,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.general();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateUserGeneral = createAsyncThunk(
  userActions.generalUpdate,
  async (values: IGeneral, { rejectWithValue }) => {
    try {
      const resp = await UserController.generalUpdate(values);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProInfo = createAsyncThunk(
  userActions.professionalInformation,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.proInfo();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateProInfo = createAsyncThunk(
  userActions.professionalInformation_update,
  async (values: IProfessionalInfo, { rejectWithValue }) => {
    try {
      const resp = await UserController.proInfoUpdate(values);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  userActions.profile,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.profile();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllBannerLogos = createAsyncThunk(
  userActions.logos,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getAllBannerLogos();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLogoById = createAsyncThunk(
  userActions.logoById,
  async ({ logoId }: { logoId: string }, { rejectWithValue }) => {
    try {
      const resp = await UserController.getBannerLogoByLogoID(logoId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChangePassword = createAsyncThunk(
  userActions.changePassword,
  async (params: IChangePasswordParams, { rejectWithValue }) => {
    try {
      const resp = await UserController.changePassword(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
