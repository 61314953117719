import { SxProps, Theme } from '@mui/system';

const classes = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  ratingInfo: {
    display: 'flex',
    alignItems: 'center',
  },

  reviewsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  pharmacistName: {
    fontSize: '20px',
    fontWeight: 600,
    color: 'common.grey850',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },

  reviewsText: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'common.grey850',
    marginLeft: '6px',
  },

  ratingText: {
    fontSize: '18px',
    fontWeight: 700,
    color: 'common.grey850',
  },

  totalRatingText: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'common.grey850',
    textAlign: 'center',
    justifyContent: 'center',
    paddingLeft: '5px',
  },

  ratingIcon: {
    paddingRight: '9px',
  },

  container: {
    padding: '32px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
