import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  toolbarContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 2,
    zIndex: 20,
  },

  toolbarHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },

  calendarHeading: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '32px',
    color: 'common.grey850',
    userSelect: 'none',
  },

  filterCont: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
  },

  navigateDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 33,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  navigateDate: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  navigationToggle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2px',
    userSelect: 'none',
  },

  searchIcon: {
    mt: '3px',
  },

  toggleViewContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  dropdownCustomHeight: {
    display: 'flex',
    '& .MuiOutlinedInput-root': {
      maxHeight: '32px',
    },
    marginBottom: '0px',
  },

  todayButton: {
    marginLeft: '20px',
    color: ThemeConstants.grey[500],
  },

  multipleSelectButtons: {
    display: 'flex',
  },

  resetButton: {
    mr: 1,
  },

  clearButton: {
    mr: 2,
  },

  checkboxLabel: {
    fontSize: 16,
    fontWeight: '500',
  },

  adminFilterContainer: {
    mt: '10px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
