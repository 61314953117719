import { TypeOfUser } from '@pharmaplan/common';
import { ReactComponent as CoordinateIcon } from '../../../assets/svg/coordinateIcon.svg';
import { ReactComponent as PrimaryIcon } from '../../../assets/svg/primaryIcon.svg';
import { ReactComponent as SecondaryIcon } from '../../../assets/svg/secondaryIcon.svg';
import { ReactComponent as AccountingIcon } from '../../../assets/svg/accountingIcon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/svg/infoIcon.svg';
import { ReactComponent as professionalInfo } from '../../../assets/svg/professionalInfo.svg';
import { ReactComponent as softwareIcon } from '../../../assets/svg/softwareIcon.svg';
import { ReactComponent as contactsIcon } from '../../../assets/svg/contactsIcon.svg';
import { ReactComponent as generalInfoIcon } from '../../../assets/svg/generalInfoIcon.svg';
import { ReactComponent as aboutUsIcon } from '../../../assets/svg/aboutUsIcon.svg';
import { ReactComponent as privacyPolicyIcon } from '../../../assets/svg/privacyPolicyIcon.svg';
import strings from '../../../localization';

const commonList = () =>
  [
    {
      title: strings.aboutUs,
      icon: aboutUsIcon,
      id: 'aboutUs',
    },
    {
      title: strings.privacyPolicy,
      icon: privacyPolicyIcon,
      id: 'privacyPolicy',
    },
  ];

const genericAdminSettings = [{
  title: strings.general,
  icon: generalInfoIcon,
  id: 'general',
}];

export const settingsList = () =>
  ({
    [TypeOfUser.pharmacist]: [
      {
        title: strings.general,
        icon: generalInfoIcon,
        id: 'general',
      },
      {
        title: strings.professionalInfo,
        icon: professionalInfo,
        id: 'professionalInfo',
      },
      {
        title: strings.software,
        icon: softwareIcon,
        id: 'software',
      },
      {
        title: strings.contacts,
        icon: contactsIcon,
        id: 'contacts',
      },
      ...commonList(),
    ],
    [TypeOfUser.pharmacy]: [
      {
        id: 'pharmacyInfo',
        icon: InfoIcon,
        title: strings.pharmacyInfo,
      },
      {
        id: 'coordinates',
        icon: CoordinateIcon,
        title: strings.coordinates,
      },
      {
        id: 'primary',
        icon: PrimaryIcon,
        title: strings.primary,
      },
      {
        id: 'secondary',
        icon: SecondaryIcon,
        title: strings.secondary,
      },
      {
        id: 'accounting',
        icon: AccountingIcon,
        title: strings.accounting,
      },
      ...commonList(),
    ],
    [TypeOfUser.superAdmin]: genericAdminSettings,
    [TypeOfUser.admin]: genericAdminSettings,
    [TypeOfUser.pharmacyAdmin]: genericAdminSettings,
  });
