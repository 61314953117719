import React, { FC, useEffect } from 'react';

// eslint-disable-next-line import/no-unresolved
import { GroupChannelListItemBasicProps } from '@sendbird/uikit-react/types/modules/GroupChannelList/components/GroupChannelListItem/GroupChannelListItemView';
import { Box, Tooltip, Typography } from '@mui/material';

import {
  addAssignedChannels,
  ChatStatus,
  getAssignedChannels,
  userIdSelector,
} from '@pharmaplan/common';

import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import useMetadata from '../../../../hooks/Admin/Chat/useMetadata';
import { IChannelData } from '../../../../helpers/Constants';
import strings from '../../../../localization';

import styles from './styles';

interface GroupChannelItemProps extends GroupChannelListItemBasicProps {}
interface IGroupChannelPreview {
  channelData: IChannelData;
  props: GroupChannelItemProps;
  setChannelUrl: React.Dispatch<React.SetStateAction<IChannelData>>;
  segment: string;
  setUnreadSegments: React.Dispatch<
    React.SetStateAction<{
      11: Record<string, boolean>;
      10: Record<string, boolean>;
    }>
  >;
}

const GroupChannelPreview: FC<IGroupChannelPreview> = ({
  props,
  setChannelUrl,
  channelData,
  segment,
  setUnreadSegments,
}) => {
  const dispatch = useAppDispatch();

  const { channel, isSelected } = props ?? {};

  const userId = useAppSelector(userIdSelector);
  const assignedChannels = useAppSelector(getAssignedChannels);

  const {
    lastMessage,
    coverUrl: cover,
    name,
    url,
    unreadMessageCount,
  } = channel ?? {};
  const metadata = channelData[url] ?? {};
  const { users, username } = metadata ?? {};

  const { message, sender } = (lastMessage as any) ?? {};
  const { userId: senderId } = sender ?? {};
  const isMe = senderId === userId;

  useMetadata({ setChannelUrl, url, channelData });

  const statusHandler = () => {
    if (!users) return ChatStatus.available;
    if (users === userId) return ChatStatus.assignedToMe;
    if (users !== userId) return ChatStatus.engaged;

    return ChatStatus.available;
  };

  const statusConfig = {
    [ChatStatus.engaged]: {
      style: styles.engaged,
      label: `${strings.formatString(
        strings.engagedWithFormat,
        username ?? '',
      )}`,
    },
    [ChatStatus.available]: {
      style: styles.available,
      label: strings.available,
    },
    [ChatStatus.assignedToMe]: {
      style: styles.assignedToMe,
      label: strings.assignedToMe,
    },
    [ChatStatus.away]: {
      style: styles.assignedToMe,
      label: strings.away,
    },
  };

  const { label, style } = statusConfig[statusHandler()];
  const isNotEngaged = statusHandler() !== ChatStatus.engaged;
  const selectedSegment = channelData[url]?.user_type !== segment;
  const onlyShowNotEngaged = !!unreadMessageCount && isNotEngaged;

  useEffect(() => {
    if (users === userId) {
      dispatch(addAssignedChannels([...assignedChannels, url]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (isNotEngaged) {
      setUnreadSegments((prev) =>
        ({
          ...prev,
          [channelData?.[url]?.user_type]: {
            ...prev[
            channelData?.[url]?.user_type as unknown as keyof typeof prev
            ],
            [url]: unreadMessageCount,
          },
        }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessageCount, channelData]);

  return (
    <Box
      sx={[
        styles.channelItemContainer,
        isSelected ? styles.selectedChannel : {},
        selectedSegment && styles.hideUser,
      ]}
    >
      <Box sx={styles.statusContainer}>
        <img style={styles.avatar} src={cover} alt="cover" />
        <Tooltip title={label}>
          <Box sx={[style, styles.dot]} />
        </Tooltip>
      </Box>
      <Box sx={[styles.channelInfoContainer]}>
        <Typography sx={styles.channelTitle}>{name}</Typography>
        <Typography sx={styles.lastMessage}>
          {isMe ? `${strings.you}: ` : ''}
          {message || ''}
        </Typography>
      </Box>
      {onlyShowNotEngaged && (
        <Box sx={[styles.count, styles.shakingCount]}>{unreadMessageCount}</Box>
      )}
    </Box>
  );
};

export default GroupChannelPreview;
