import { useEffect, useRef } from 'react';

import {
  getCounterOffer,
  getEventWithLocation,
  pharmacistActions,
  pharmacyActions,
  resetStatus,
  successOrSelector,
  successSelector,
} from '@pharmaplan/common';

import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import { selectFooterPaginationCurrent } from '../../selectors/drawerSelector';
import { IServiceWorkshiftDetails } from '../../components/SelfService/ServiceWorkshiftDetails/ServiceWorkshiftDetails';
import useServiceWorkshiftDetails from './useServiceWorkshiftDetails';
import { resetDrawer } from '../../actions/drawerActions';
import useUsersLocation, { Position, PositionError } from '../useUsersLocation';

const useServiceWorkshiftDetailsEvent = ({
  eventIds,
  eventType,
}: IServiceWorkshiftDetails) => {
  const dispatch = useAppDispatch();

  const current = useAppSelector(selectFooterPaginationCurrent);

  const getEventSuccess = useAppSelector((state) =>
    successSelector([pharmacistActions.getPharmacistEvent], state));

  const successes = useAppSelector((state) =>
    successOrSelector(
      [
        pharmacistActions.cancelBooking,
        pharmacistActions.cancelRequestedBooking,
        pharmacistActions.requestBooking,
        pharmacyActions.ignoreRequest,
        pharmacyActions.approveRequest,
        pharmacistActions.rejectUpdatedTimings,
        pharmacistActions.acceptUpdatedTimings,
        pharmacistActions.selfCancelBooking,
      ],
      state,
    ));

  const id = useRef(eventIds[current - 1]);

  const {
    serviceConfig,
    goToCounterOffer,
    buttonConfig,
    isBooked,
    isWorkshift,
    isSelfWorkshift,
    isCounterOffer,
  } = useServiceWorkshiftDetails(id.current, eventType);

  const getWorkshiftDetails = (
    latitude: number | null = null,
    longitude: number | null = null,
  ) => {
    dispatch(
      getEventWithLocation({
        latitude,
        longitude,
        id: id.current,
        type: eventType,
      }),
    );
  };

  const locationError = (error: PositionError) => {
    console.warn(`ERROR(${error.code}): ${error.message}`);

    getWorkshiftDetails();
  };

  const locationSuccess = (position: Position) => {
    const { coords } = position;
    const { latitude, longitude } = coords ?? {};

    getWorkshiftDetails(latitude, longitude);
  };

  useUsersLocation(locationSuccess, locationError, [current]);

  useEffect(() => {
    id.current = eventIds[current - 1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    if (getEventSuccess) {
      isCounterOffer && dispatch(getCounterOffer(id.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEventSuccess, isCounterOffer]);

  useEffect(() => {
    if (successes) {
      dispatch(resetDrawer());
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successes]);

  return {
    serviceConfig,
    goToCounterOffer,
    buttonConfig,
    isBooked,
    isWorkshift,
    isSelfWorkshift,
    isCounterOffer,
  };
};

export default useServiceWorkshiftDetailsEvent;
