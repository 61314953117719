import React, { FC } from 'react';

import { Box } from '@mui/system';

import {
  IAdminRequestedPharmacistsData,
  adminActions,
} from '@pharmaplan/common';
import { genericConfigBuilder } from '../../AvailableWorkshifts/helpers';
import strings from '../../../../localization';
import useRequestedPharmacistActions from '../../../../hooks/AvailableWorkshift/useRequestedPharmacistActions';
import CustomAvatarSection from '../../../common/CustomAvatarSection';
import { LocationText } from '../../AvailableWorkshifts/AvailableWorkshifts';

import styles from './styles';

interface IPharmacistList {
  event: IAdminRequestedPharmacistsData;
  image?: string;
}

const PharmacistList: FC<IPharmacistList> = ({ event, image }) => {
  const { actions } = useRequestedPharmacistActions();

  const { pharmacistName, pharmacistCity, bookingId, workshiftId, startDate, pharmacistHourlyRate, pharmacyId } = event ?? {};

  const config = genericConfigBuilder(
    <LocationText label={pharmacistCity} />,
    strings.pharmacist,
  );

  return (
    <Box sx={styles.container}>
      <CustomAvatarSection
        successAction={adminActions.getRequestedPharmacists}
        config={config}
        hourlyRate={pharmacistHourlyRate}
        title={pharmacistName}
        avatar={image}
        menuActions={actions(bookingId, workshiftId, startDate, pharmacyId)}
      />
    </Box>
  );
};

export default PharmacistList;
