import React, { FC } from 'react';

import { Typography, Tooltip } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { Close } from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import { Box } from '@mui/system';

import {
  getTypeOfUser,
  createGroupChannel,
  userUnreadCountSelector,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import strings from '../../localization';

import styles from './styles';

interface IChatButton {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatButton: FC<IChatButton> = ({ show, setShow }) => {
  const dispatch = useAppDispatch();

  const count = useAppSelector(userUnreadCountSelector);
  const userType = useAppSelector(getTypeOfUser);

  const tooltip = show ? strings.close : strings.chatWithUs;

  const requestSupport = () => {
    if (show) {
      setShow(false);
      return;
    }

    dispatch(createGroupChannel(userType.toLowerCase()));
  };

  return (
    <Fab
      sx={styles.floatingContainer}
      color="primary"
      aria-label={strings.chat}
      onClick={requestSupport}
    >
      {!!count && (
        <Box sx={styles.floating}>
          <Typography sx={styles.text}>{count}</Typography>
        </Box>
      )}
      <Tooltip title={tooltip}>
        {show ? <Close /> : <ChatIcon />}
      </Tooltip>
    </Fab>
  );
};

export default ChatButton;
