import React, { useEffect, useRef } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBack';
import { FormikValues, useFormik } from 'formik';

import {
  createProfile,
  getLanguages,
  doesPharmacyExist,
  getTypeOfUser,
  istermsAgreedStatus,
  pharmacyExists,
  resetPharmacyExists,
  setAddress,
  userAddress,
  userLocation,
  getUserProps,
  Language,
} from '@pharmaplan/common';

import CustomButton from '../../common/CustomButton';
import ProfileForm from '../../Profile/ProfileForm';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { pharmacyPersist } from '../../../selectors/formPersistSelector';

import { filterLanguage } from '../../../helpers/Functions';

import { pharmacySteps } from '../helper';
import strings from '../../../localization';

import styles from './styles';

interface IPharmacySignupFlow {
  step: number;
  changeStep: (steps: object, values: FormikValues, type: string) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const PharmacySignupFlow = ({
  step,
  setStep,
  changeStep,
}: IPharmacySignupFlow) => {
  const dispatch = useAppDispatch();

  const isMounted = useRef(false);

  const pharmacyPersistSelector = useAppSelector(pharmacyPersist);
  const userType = useAppSelector(getTypeOfUser);
  const isTerms = useAppSelector(istermsAgreedStatus);
  const getUserAddress = useAppSelector(userAddress);
  const location = useAppSelector(userLocation);
  const languages = useAppSelector(getLanguages);
  const userProps = useAppSelector(getUserProps);
  const isPharmacyExist = useAppSelector(pharmacyExists);

  const pharmacyStep = pharmacySteps()[step as keyof typeof pharmacySteps];
  const isLastStep = step === Object.keys(pharmacySteps()).length - 1;
  const validStep = step !== 0;
  const isStepZero = step === 0;
  const isStepGreater = step > 0;

  const buttonLabel = isLastStep ? strings.submit : strings.next;
  const { latitude, longitude } = location ?? {};
  const { pharmacyInfo, coordinates, primary, secondary, accounting } = pharmacyPersistSelector ?? {};
  const { type: pharmacyStepType, validator } = pharmacyStep;

  const { userType: role, language, ...rest } = userProps ?? {};

  const handleSubmit = (values: FormikValues) => {
    const allData = {
      ...rest,
      ...pharmacyInfo,
      ...coordinates,
      ...primary,
      ...secondary,
      ...accounting,
      ...values,
      latitude,
      longitude,
      role,
      languageId: language,
    };

    dispatch(
      createProfile({
        params: allData,
        user: userType,
      }),
    );
  };

  const goToNextStep = (values: FormikValues) => {
    isStepZero
      ? dispatch(doesPharmacyExist(values.name))
      : changeStep(pharmacySteps(), values, pharmacyStepType);
  };

  const submit = (values: FormikValues) => {
    isLastStep ? handleSubmit(values) : goToNextStep(values);
  };

  const formik = useFormik({
    initialValues: {
      ...pharmacyPersistSelector[pharmacyStepType],
      languageId: filterLanguage(languages, language ?? Language.fr)?.name,
      latitude: null,
      longitude: null,
    },

    onSubmit: submit,
    enableReinitialize: true,
    validationSchema: validator,
  });

  const handleBackButton = () => {
    isStepGreater
      && setStep((prevStep) =>
        prevStep - 1);
  };

  const {
    setFieldValue,
    values: formikValues,
    handleSubmit: formikHandleSubmit,
  } = formik ?? {};
  const { termsAndConditions } = formikValues ?? {};

  const shouldShowSubmit = isLastStep && !termsAndConditions;

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else if (!isPharmacyExist) {
      changeStep(pharmacySteps(), formik.values, pharmacyStepType);
      dispatch(resetPharmacyExists());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPharmacyExist]);

  useEffect(() => {
    setFieldValue('termsAndConditions', isTerms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTerms]);

  useEffect(() => {
    getUserAddress.length > 0 && setFieldValue('address', getUserAddress);
    (latitude ?? 0) && setFieldValue('latitude', latitude);
    (longitude ?? 0) && setFieldValue('longitude', longitude);

    return () => {
      dispatch(setAddress(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserAddress]);

  return (
    <>
      <Box>
        <ProfileForm
          selected={pharmacyStepType}
          formik={formik}
          isSignup
        />
      </Box>

      <Box display="flex" flex={1} alignSelf="flex-end" alignItems="flex-end">
        {validStep && (
          <IconButton sx={styles.back} onClick={handleBackButton}>
            <BackIcon />
            <Typography sx={styles.backText}>{strings.back}</Typography>
          </IconButton>
        )}

        <CustomButton
          label={buttonLabel}
          customClass={styles.buttons}
          onClick={formikHandleSubmit}
          disabled={shouldShowSubmit}
        />
      </Box>
    </>
  );
};

export default PharmacySignupFlow;
