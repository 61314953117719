import { SxProps, Theme } from '@mui/system';

const classes = {
  root: {
    marginBottom: '24px',
  },

  title: {
    color: 'grey.800',
    fontSize: 16,
    fontWeight: 600,
  },

  cityFont: {
    color: 'black',
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: '13px',
  },

  replacementTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: 'common.grey850',
  },

  replacement: {
    fontSize: 14,
    fontWeight: 500,
    color: 'common.grey850',
  },

  titleContainer: {
    padding: '16px 0px 0px 16px',
  },

  tableHead: {
    fontSize: 12,
    fontWeight: 600,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
