import {
  DashboardType,
  calendarDate,
  getActiveDashboard,
  getAdminCalendar,
  getAdminMap,
  mapDate,
} from '@pharmaplan/common';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { getISO, mapFormat, parseMapDate } from '../../helpers/Functions';
import { resetDialog } from '../../reducers/dialogReducer';
import { resetDrawer } from '../../actions/drawerActions';

const useAdminDashboard = () => {
  const dispatch = useAppDispatch();

  const cDate = useAppSelector(calendarDate);
  const mDate = useAppSelector(mapDate);
  const activeDash = useAppSelector(getActiveDashboard);

  const { calendar } = DashboardType;

  const resetPopAndDrawer = () => {
    dispatch(resetDialog());
    dispatch(resetDrawer());
  };

  const dashBasedRequest = () => {
    const isCalendar = activeDash === calendar;
    if (isCalendar) {
      dispatch(
        getAdminCalendar({
          startDate: mapFormat(getISO(cDate.startDate)),
          endDate: mapFormat(getISO(cDate.endDate)),
        }),
      );
      return;
    }
    dispatch(getAdminMap(parseMapDate(mDate)));
  };

  return { dashBasedRequest, resetPopAndDrawer };
};

export default useAdminDashboard;
