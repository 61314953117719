import { Constants, TextFieldVariants } from '../../../helpers/Constants';
import strings from '../../../localization';
import styles from './styles';
import { IInputModalConfig } from '../../Modals/InputModal/InputModal';

export const cancellationForm = (): IInputModalConfig[] =>
  [
    {
      key: '0',
      name: 'date',
      type: Constants.formInputType.typography,
      gridLabel: strings.selectedDat,
      fieldXs: 8,
      style: styles.text,
      labelXs: 4,
    },
    {
      key: '1',
      name: 'pharmacyName',
      type: Constants.formInputType.typography,
      style: styles.text,
      gridLabel: strings.pharmacyName,
      fieldXs: 8,
      labelXs: 4,
    },
    {
      key: '2',
      name: 'time',
      type: Constants.formInputType.typography,
      style: styles.text,
      gridLabel: strings.time,
      fieldXs: 8,
      labelXs: 4,
    },
    {
      key: '3',
      name: 'reason',
      type: Constants.formInputType.text,
      gridLabel: strings.reason,
      multiline: true,
      variant: TextFieldVariants.filled,
      fieldXs: 8,
      labelXs: 4,
    },
  ];

export const resendEmailForm = (): IInputModalConfig[] =>
  [
    {
      key: '0',
      name: 'pharmacyName',
      type: Constants.formInputType.typography,
      gridLabel: strings.pharmacyName,
      fieldXs: 8,
      style: styles.text,
      labelXs: 4,
      gridContainerStyle: styles.gridContainer,
    },
    {
      key: '1',
      name: 'pharmacistName',
      type: Constants.formInputType.typography,
      style: styles.text,
      gridLabel: strings.pharmacistName,
      fieldXs: 8,
      labelXs: 4,
      gridContainerStyle: styles.gridContainer,
    },
    {
      key: '2',
      name: 'date',
      type: Constants.formInputType.typography,
      style: styles.text,
      gridLabel: strings.bookingDate,
      fieldXs: 8,
      labelXs: 4,
      gridContainerStyle: styles.gridContainer,
    },
    {
      key: '3',
      name: 'time',
      type: Constants.formInputType.typography,
      gridLabel: strings.time,
      style: styles.text,
      fieldXs: 8,
      labelXs: 4,
      gridContainerStyle: styles.gridContainer,
    },
    {
      key: '4',
      name: 'sendEmailToPharmacist',
      type: Constants.formInputType.checkbox,
      labelClass: styles.label,
      label: strings.sendEmailToPharmacist,
      fieldXs: 10,
      labelXs: 2,
    },
    {
      key: '5',
      name: 'sendEmailToPharmacy',
      type: Constants.formInputType.checkbox,
      label: strings.sendEmailToPharmacy,
      labelClass: styles.label,
      fieldXs: 10,
      labelXs: 2,
    },
  ];
