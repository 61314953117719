import React, { useEffect } from 'react';

import { useFormik } from 'formik';

import { getUserPrimaryContact, updatePharmactContact, userPrimaryContact } from '@pharmaplan/common';
import { userInitialState } from '@pharmaplan/common/reducers/userReducer';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { PrimaryValidator } from '../../FormValidation';
import { ICommonProfile } from '../../PharmacistFlow/General/General';
import ProfileForm from '../../ProfileForm';

const Primary = ({ setSelected }:ICommonProfile) => {
  const dispatch = useAppDispatch();
  const primary = useAppSelector(userPrimaryContact);

  const formik = useFormik({
    initialValues: { ...userInitialState?.primaryContact },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: (values) =>
      dispatch(updatePharmactContact({ values, type: 'primarycontact' })),
    enableReinitialize: true,
    validationSchema: PrimaryValidator(),
  });

  useEffect(() => {
    dispatch(getUserPrimaryContact());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.setValues({ ...primary });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary]);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="primary"
      formik={formik}
    />
  );
};

export default Primary;
