type IMessageFuncs = (message: string) => void;
type ISetAttribute = (attribs: Record<string, object | string>) => void;
const LogHandler = () => {
  // LOCAL LOGGER AS FALLBACK
  let logger = (message: string) => { console.log('fall', message) }
  let recordError = (message: string) => { console.log('fall', message) };
  let setAttributes = (attribs: Record<string, object | string>) => { };
  let crasher = () => { }
  let context: any = null;

  const init = (
    _context: any,
    _crasher: () => void,
    _recordError: IMessageFuncs,
    _logger: IMessageFuncs,
    _setAttributes: ISetAttribute,
  ) => {
    logger = _logger;
    crasher = _crasher;
    recordError = _recordError;
    setAttributes = _setAttributes;
    context = _context;
  }

  const crash = () => {
    crasher.call(context)
  }
  const log = (message: string) => {
    logger.call(context, message);
  }
  const logRecordError = (message: string) => {
    recordError.call(context, message);
  }
  const setAttribs = (attribs: Record<string, object | string>) => {
    setAttributes.call(context, attribs);
  }

  return { init, crash, log, logRecordError, setAttribs };
}

export default LogHandler();