import { Stack } from '@mui/system';
import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Typography } from '@mui/material';
import { AdminAllowanceNames } from '@pharmaplan/common';
import strings from '../../../../localization';
import CustomTextField from '../../../common/CustomTextField';
import styles from './styles';
import { AllowanceAdminConfig, Constants } from '../../../../helpers/Constants';

const { priceSuffix, allowancePlaceholder } = Constants;
const { paidHourlyRate } = AdminAllowanceNames;
interface IAllowanceAdminWorkshift {
  formik: FormikProps<any>;
}

const AllowanceAdminWorkshift: FC<IAllowanceAdminWorkshift> = ({ formik }) =>
  (
    <Stack>
      <Typography sx={styles.title}>{strings.rateAndAllowances}</Typography>
      <CustomTextField
        label={strings.pharmacistHourlyRate}
        name={paidHourlyRate}
        formik={formik}
        placeholder={allowancePlaceholder}
        suffix={priceSuffix}
        customClass={styles.hourlyRate}
        id={paidHourlyRate}
        horizontal
      />
      <CustomTextField
        label={strings.pharmacyHourlyRate}
        name="pharmacyHourlyRate"
        formik={formik}
        placeholder={allowancePlaceholder}
        suffix={priceSuffix}
        customClass={styles.hourlyRate}
        id="pharmacyHourlyRate"
        horizontal
      />
      {AllowanceAdminConfig.map((item) => {
        const { key, label, name } = item;
        return (
          <CustomTextField
            label={label}
            key={key}
            formik={formik}
            placeholder={allowancePlaceholder}
            name={name}
            suffix={priceSuffix}
            id={name}
          />
        );
      })}
    </Stack>
  );

export default AllowanceAdminWorkshift;
