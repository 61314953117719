import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const styles = {
  toolbar: {
    display: 'flex',
    flex: 1,
    marginLeft: '10px',
    maxHeight: '50px !important',
    minHeight: '35px !important',
  },
  navButton: {
    position: 'relative',
    height: '100%',
    borderRadius: 0,
    padding: '11px 8px',
    marginRight: '40px',
    fontSize: 14,
    fontWeight: '500',
  },
  activeButton: {
    borderBottom: '2px solid green',
    fontWeight: '600',
  },
  inActive: {
    color: ThemeConstants.common.grey850,
    opacity: 0.8,
  },
  count: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    backgroundColor: 'common.error',
    justifyContent: 'center',
    alignItems: 'center',
    height: 16,
    width: 16,
    borderRadius: 8,
    fontSize: 11,
    fontWeight: '500',
    color: 'secondary.main',
  },
  shakingCount: {
    '@keyframes shaking': {
      '0%': { scale: '1' },
      '15%': { scale: '1.04' },
      '30%': { scale: '1.08' },
      '40%': { scale: '1.12' },
      '50%': { scale: '1.08' },
      '60%': { scale: '1.04' },
      '100%': { scale: '1.00' },
    },
    animation: 'shaking 1s infinite linear',
    fontSize: 13,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
