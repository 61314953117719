import React, { FC } from 'react';

import { FormikProps } from 'formik';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

import {
  PendingCancellationTabTypes,
  PermissionsOfAdmin,
} from '@pharmaplan/common';
import strings from '../../../../localization';
import PendingTabs from '../../PendingTabs';
import CustomDateRange from '../../../common/CustomDateRange';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import { getDisabledSubmitFilter } from '../../../../selectors/calendarSelector';
import CustomTextField from '../../../common/CustomTextField';
import useAdminPermissions from '../../../../hooks/Admin/useAdminPermissions';
import SelectAllHeader from '../../../DynamicTable/SelectAllHeader';

import styles from './styles';

export enum pharmacyPharmacistAutoComplete {
  selectedPharmacist = 'selectedPharmacist',
  selectedPharmacy = 'selectedPharmacy',
  pharmacistId = 'pharmacistId',
  pharmacyId = 'pharmacyId',
  searchedPharmacy = 'searchedPharmacy',
  searchedPharmacist = 'searchedPharmacist',
}

interface IPendingCancellationHeader {
  formik: FormikProps<any>;
  setSelected: React.Dispatch<
    React.SetStateAction<PendingCancellationTabTypes>
  >;
  selectedTab: PendingCancellationTabTypes;
  handleDelete: () => void;
  handleSelectAll: (toBeSet: boolean) => void;
  selectAll: boolean;
  handleReset: () => void;
}

const {
  pendingCancellations: pendingCancellationsTab,
  cancelledBookings: cancelledBookingsTab,
  rejectedCancellations: rejectedCancellationsTab,
} = PendingCancellationTabTypes;

const PendingCancellationHeader: FC<IPendingCancellationHeader> = ({
  formik,
  selectedTab,
  selectAll,
  setSelected,
  handleDelete,
  handleReset,
  handleSelectAll,
}) => {
  const { can } = useAdminPermissions();
  const canPermanentlyDelete = can(PermissionsOfAdmin.PermanentDelete);

  const disableSubmit = useAppSelector(getDisabledSubmitFilter);

  const handleTabClick = (tab: PendingCancellationTabTypes) => {
    setSelected(tab);
  };

  const isCancelledBookings = selectedTab === cancelledBookingsTab;
  const isRejectedCancellations = selectedTab === rejectedCancellationsTab;
  const allowedDelete = isCancelledBookings || isRejectedCancellations;

  const isAllowedDelete = canPermanentlyDelete && allowedDelete;

  const tabConfig = [
    {
      key: pendingCancellationsTab,
      isDefault: true,
      tabName: `${strings.pendingCancellation}`,
      onClick: handleTabClick,
    },
    {
      key: cancelledBookingsTab,
      tabName: strings.cancelledBookings,
      onClick: handleTabClick,
    },
    {
      key: rejectedCancellationsTab,
      tabName: strings.rejectedCancellations,
      onClick: handleTabClick,
    },
  ];

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PendingTabs
        selectedTab={selectedTab}
        tabConfig={tabConfig}
        header={strings.pendingBookingCancellations}
      />
      <Grid container lg={10} alignItems="center" xs={12} wrap="nowrap">
        <CustomDateRange
          startName="startDate"
          endName="endDate"
          formik={formik}
          preventPast={false}
        />
        <Grid xs={6} sx={styles.codeContainer}>
          <CustomTextField
            formik={formik}
            name="code"
            placeholder={strings.formatString(strings.enterCred, strings.code)}
            id="code"
            label={strings.code}
          />
        </Grid>
        <Grid xs={6} sx={styles.autoCompleteContainer}>
          <CustomTextField
            formik={formik}
            name="pharmacistText"
            placeholder={strings.formatString(
              strings.enterCred,
              `${strings.name}/${strings.email}`,
            )}
            id="pharmacistText"
            label={strings.pharmacist}
          />
        </Grid>
        <Grid xs={6} sx={styles.autoCompleteContainer}>
          <CustomTextField
            formik={formik}
            name="pharmacyText"
            placeholder={strings.formatString(
              strings.enterCred,
              `${strings.name}/${strings.email}`,
            )}
            id="userText"
            label={strings.pharmacy}
          />
        </Grid>

        <FilterSubmitButtons
          handleReset={handleReset}
          disableSubmit={disableSubmit}
        />
      </Grid>
      {isAllowedDelete && (
        <SelectAllHeader
          selectAll={selectAll}
          showDelete
          handleDelete={handleDelete}
          handleSelectAll={handleSelectAll}
        />
      )}
    </Box>
  );
};

export default PendingCancellationHeader;
