import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    padding: '24px 10px 24px 32px',
    backgroundColor: 'white',
  },
  title: {
    color: 'common.grey850',
    fontSize: 18,
    fontWeight: '600',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
