import * as Yup from 'yup';
import strings from '../../../localization';

export const formikRequired = Yup.string().required(strings.requiredField);

const Validator = () =>
  Yup.object().shape({
    from: Yup.number()
      .required(strings.requiredField)
      .typeError(strings.mustBeNumber)
      .min(1, strings.pleaseEnterValidValue)
      .test(
        'max',
        `${strings.formatString(
          strings.fromMustBeLessThanTo,
          strings.from,
          strings.to,
        )}`,
        (value, context) =>
          value <= parseFloat(context.parent.to),
      ),
    to: Yup.number()
      .required(strings.requiredField)
      .typeError(strings.mustBeNumber)
      .min(1, strings.pleaseEnterValidValue),
  });

export default Validator;
