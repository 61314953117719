import { useCallback, useEffect, useMemo } from 'react';
import {
  BookingState,
  PharmacyLegendVariant,
  getBookedPharmacistDetails,
  pharmacistBookingDetails,
  pharmacyActions,
  resetStatus,
  selectPharmacyPreApprove,
  successSelector,
} from '@pharmaplan/common';
import { useAppDispatch } from '../useAppDispatch';
import {
  setBack,
  setButtons,
  setPagination,
} from '../../actions/drawerActions';
import strings from '../../localization';
import { IPharmacistProfile } from '../../components/SelfService/PharmacistProfile/PharmacistProfile';
import { useAppSelector } from '../useAppSelector';
import usePharmacistProfile from './usePharmacistProfile';
import { resetDialog } from '../../reducers/dialogReducer';

const { PendingWithPharmacist, None } = BookingState;
const { booked, requested } = PharmacyLegendVariant;

const usePharmacyPreRequest = ({
  eventId,
  type,
  eventIds,
  date,
  workshiftId,
}: IPharmacistProfile) => {
  const dispatch = useAppDispatch();

  const {
    counterOfferCancellation,
    counterOfferConfirmation,
    approveAction,
    ignoreAction,
    showApproveSuccessModal,
    gotoRequestsList,
    gotoWorkshiftDetails,
    showSoftwareWarning,
    handleSoftwareWarning,
  } = usePharmacistProfile({ type, eventId, date, eventIds, workshiftId });

  const details = useAppSelector(pharmacistBookingDetails);
  const preRequest = useAppSelector(selectPharmacyPreApprove);

  const approveSuccess = useAppSelector((state) =>
    successSelector([pharmacyActions.approveRequest], state));
  const preApproveSuccess = useAppSelector((state) =>
    successSelector([pharmacyActions.pharmacyPreApprove], state));
  const rejectSuccess = useAppSelector((state) =>
    successSelector([pharmacyActions.ignoreRequest], state));

  const { bookingState, counterOfferId } = details ?? {};
  const { isSufficientSoftwareKnowledge } = preRequest ?? {};

  const isWorkshiftDetails = bookingState === PendingWithPharmacist || type === booked;
  const showButtonsWhen = bookingState === None && type === requested;
  const isCounterOffer = counterOfferId && showButtonsWhen;

  const approveRejectActions = useMemo(() => {
    if (isCounterOffer) {
      return {
        primary: counterOfferConfirmation,
        secondary: counterOfferCancellation,
        primaryLabel: strings.accept,
      };
    }
    return {
      primary: handleSoftwareWarning,
      secondary: ignoreAction,
      primaryLabel: strings.confirm,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, isCounterOffer, strings]);

  useEffect(() => {
    dispatch(
      getBookedPharmacistDetails({
        id: eventId,
        eventType: type,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setBack(isWorkshiftDetails ? gotoWorkshiftDetails : gotoRequestsList),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkshiftDetails]);

  useEffect(() => {
    dispatch(
      setPagination({
        show: false,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { primaryLabel, primary, secondary } = approveRejectActions ?? {};
    dispatch(
      setButtons({
        show: !!showButtonsWhen,
        primaryButton: {
          label: showButtonsWhen ? primaryLabel : null,
          onClick: primary,
        },
        secondaryButton: {
          label: showButtonsWhen ? strings.reject : null,
          onClick: secondary,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveRejectActions, showButtonsWhen]);

  useEffect(() => {
    if (approveSuccess) {
      showApproveSuccessModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveSuccess]);

  const runSoftwareCheck = useCallback(() => {
    if (preApproveSuccess) {
      if (isSufficientSoftwareKnowledge === false) {
        showSoftwareWarning();
      } else if (isSufficientSoftwareKnowledge === true) {
        approveAction();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSufficientSoftwareKnowledge, preApproveSuccess]);

  useEffect(() => {
    runSoftwareCheck();
    dispatch(resetStatus([pharmacyActions.pharmacyPreApprove]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSufficientSoftwareKnowledge, preApproveSuccess]);

  useEffect(() => {
    if (rejectSuccess) {
      dispatch(resetDialog());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectSuccess]);

  return { showButtonsWhen, isCounterOffer };
};

export default usePharmacyPreRequest;
