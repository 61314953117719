import { SxProps } from '@mui/system';

const paddingBottom = 12;
const textContainerHeight = 20;

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: textContainerHeight,
    paddingBottom: `${paddingBottom}px`,
    justifyContent: 'space-between',
  },

  value: {
    fontSize: 14,
    fontWeight: 600,
    color: 'grey.800',
  },

  containerWithoutPadding: {
    padding: 0,
  },

  extend: (length: number) =>
    ({
      transition: 'max-height 0.3s ease-out',
      maxHeight: length * (textContainerHeight + paddingBottom),
    }),

  collapse: {
    maxHeight: textContainerHeight + paddingBottom,
    overflow: 'hidden',
    transition: 'max-height 0.3s ease-in',
  },

  label: {
    fontWeight: 400,
    color: 'grey.800',
  },

  belowFifty: {
    color: 'common.error',
  },

} satisfies SxProps<any>;

export default classes;
