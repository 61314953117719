const classes = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '18px',
    justifyContent: 'space-between',
    marginRight: '5px',
  },

  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'grey.800',
  },

  shiftText: {
    fontSize: 12,
    color: 'grey.800',
    fontWeight: 300,
    paddingLeft: '5px',
  },

  pharmacist: {
    fontSize: '14px',
    paddingTop: '8px',
    color: 'grey.800',
  },

  technician: {
    fontSize: '14px',
    paddingTop: '7px',
    color: 'grey.800',
  },

  shiftContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};

export default classes;
