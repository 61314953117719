import { AdminAllowanceNames } from '@pharmaplan/common';
import strings from '../../../localization';

export const config = () =>
  [
    {
      key: '1',
      name: AdminAllowanceNames.pharmacistHourlyRate,
      label: strings.pharmacistHourlyRate,
    },
    {
      key: '2',
      name: AdminAllowanceNames.pharmacyHourlyRate,
      label: strings.pharmacyHourlyRate,
    },
    {
      key: '3',
      name: AdminAllowanceNames.travelAllowance,
      label: strings.travel,
    },
    {
      key: '4',
      name: AdminAllowanceNames.mealAllowance,
      label: strings.meal,
    },
    {
      key: '5',
      name: AdminAllowanceNames.accommodationAllowance,
      label: strings.accomodation,
    },
    {
      key: '6',
      name: AdminAllowanceNames.emergencyFees,
      label: strings.emergencyServiceFees,
    },
  ];

export const checkboxConfig = () =>
  [
    {
      key: '1',
      name: 'sendEmailToPharmacist',
      label: strings.formatString(
        strings.sendEmailNotification,
        strings.pharmacist,
      ) as string,
    },
    {
      key: '2',
      name: 'sendEmailToPharmacy',
      label: strings.formatString(
        strings.sendEmailNotification,
        strings.pharmacy,
      ) as string,
    },
  ];

export const notesConfig = () =>
  [
    {
      key: '1',
      name: 'notesForSalesOrder',
      label: strings.notesForSalesOrder,
    },
    {
      key: '2',
      name: 'notes',
      label: strings.notes,
    },
  ];
