import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { DynamicTableCell } from '../../../helpers/Constants';

import styles from './styles';
import ValueMap from './ValueMap';

interface IDisplayGrid {
  title: string;
  config: Array<{
    label: string;
    value: string;
    key: string;
    type: DynamicTableCell;
  }>;
}

const DisplayGrid: FC<IDisplayGrid> = ({ title, config }) =>
  (
    <Grid xs={11} columns={12} container sx={styles.container}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>{title}</Typography>
      </Box>
      <Grid
        container
        columns={12}
        columnSpacing={4}
        rowSpacing={4}
        sx={styles.gridContainer}
      >
        {config.map((item) => {
          const { label, value, key, type } = item;
          return (
            <Grid item key={key} xs={3}>
              <Typography sx={[styles.label, styles.text]}>{label}</Typography>
              {value && <ValueMap type={type} value={value} />}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );

export default DisplayGrid;
