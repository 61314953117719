import { renderScreen } from '../../../actions/drawerActions';
import { ScreenTypes } from '../../../helpers/Constants';
import strings from '../../../localization';
import { useAppDispatch } from '../../useAppDispatch';

type actionsParams = { pharmacistId: string; pharmacyId: string };
type genericFunction = () => void

const actions = (goToNotes:genericFunction, goToHistory: genericFunction, viewPharmacistProfile: genericFunction) =>
  [
    {
      key: '0',
      onClick: viewPharmacistProfile,
      label: strings.viewDetails,
    },
    {
      key: '1',
      onClick: goToHistory,
      label: strings.historyOfBookings,
    },
    {
      key: '2',
      onClick: goToNotes,
      label: `${strings.pharmacy} ${strings.notes}`,
    },
  ];

const useListOfPharmacists = () => {
  const dispatch = useAppDispatch();

  const goToHistory = (params: actionsParams) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.pharmacyList,
          screenNumber: 3,
          ...params,
        }),
      );
    };

  const goToNotes = (params: actionsParams) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.pharmacyList,
          screenNumber: 4,
          ...params,
        }),
      );
    };

  const listOfPharmacists = (pharmacyId: string) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.pharmacyList,
          screenNumber: 2,
          pharmacyId,
        }),
      );
    };

  const viewPharmacistProfile = (params: actionsParams) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.profile,
          screenNumber: 2,
          pharmacistId: params.pharmacistId,
          back: listOfPharmacists(params.pharmacyId),
        }),
      );
    };

  return {
    menuAction: (params: actionsParams) =>
      actions(goToNotes(params), goToHistory(params), viewPharmacistProfile(params)),
  };
};

export default useListOfPharmacists;
