import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '100px',
  },

  infoContainer: {
    margin: '0px 0px 16px 16px',
  },

  label: {
    fontSize: 12,
    fontWeight: 400,
  },

  info: {
    fontSize: 14,
    fontWeight: 600,
  },

  detailsContainer: {
    marginBottom: '8px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
