import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { serverToday } from '../../../helpers/Functions';
import styles from './styles';
import { ReactComponent as OutOfDateIcon } from '../../../assets/svg/outOfDate.svg';
import strings from '../../../localization';
import { getDayMonthDateYear } from '../../Reports/helpers';

interface IDrawerOutOfDateTitle {
  date: string;
  dateLabel: string;
}

const DrawerOutOfDateTitle: FC<IDrawerOutOfDateTitle> = ({
  date,
  dateLabel,
}) => {
  const isPast = date < (serverToday()?.toISO({ includeOffset: false }) ?? '');

  return (
    <Box sx={styles.locationContainer}>
      {isPast && (
        <Tooltip title={strings.outOfDate}>
          <OutOfDateIcon />
        </Tooltip>
      )}
      <Typography sx={styles.title}>
        {strings.formatString(dateLabel, getDayMonthDateYear(date))}
      </Typography>
    </Box>
  );
};

export default DrawerOutOfDateTitle;
