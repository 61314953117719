import { ITheme } from '.';
import ThemeConstants from '../ThemeConstants';

export default {
  styleOverrides: {
    root: ({ theme }: ITheme) =>
      ({
        textTransform: 'none' as const,
        '&:hover': {
          color: `${theme.palette.primary.main}`,
        },
        '&.Mui-selected': {
          backgroundColor: `${ThemeConstants.common.lightBackgroundColor} !important`,
          color: `${theme.palette.primary.main} !important`,
        },
      }),
  },
};
