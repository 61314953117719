import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as AppleIcon } from '../../../assets/svg/appleLogo.svg';
import { SocialIcons } from '../../../helpers/Constants';
import { ReactComponent as GoogleIcon } from '../../../assets/svg/google.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/svg/Facebook.svg';
import classes from './style';

interface ISocialLoginIcons {
  iconType: `${SocialIcons}`;
  onClick?: () => void;
}

const getSocialIcon = (iconType: `${SocialIcons}`) => {
  switch (iconType) {
    case SocialIcons.google:
      return <GoogleIcon />;
    case SocialIcons.facebook:
      return <FacebookIcon />;
    case SocialIcons.apple:
      return <AppleIcon />;
    default:
      return null;
  }
};

const SocialLoginIcons = ({ iconType, onClick }: ISocialLoginIcons) => {
  const { iconBackground } = classes;

  return (
    <Box component="div" onClick={onClick} sx={iconBackground}>
      {getSocialIcon(iconType)}
    </Box>
  );
};

export default SocialLoginIcons;
