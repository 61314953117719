import React from 'react';
import LoginLayout from '../LoginLayout/LoginLayout';
import SignupHeader from './SignupHeader/SignupHeader';
import SignupForm from './SignupForm/SignupForm';

const Signup = () =>
  (
    <LoginLayout Header={SignupHeader} Form={SignupForm} isSignup />
  );

export default Signup;
