import * as Yup from 'yup';
import strings from '../../../localization';

import { formikRequired } from '../SoftwareList/validator';

const Validator = () =>
  Yup.object().shape({
    name: formikRequired,
    image: Yup.object()
      .shape({
        name: formikRequired,
      })
      .required(strings.requiredField),
  });

export default Validator;
