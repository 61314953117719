import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { FormikValues } from 'formik';

import {
  adminActions,
  deletePharmacyAdmin,
  getPharmacyAdminLists,
  IPharmacyAdminListApiParams,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';

import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import DeactivateDialog from '../../../components/Admin/PharmacistList/DeactivateDialog';
import { Constants, DeactivateModalIcon } from '../../../helpers/Constants';
import { setDialog } from '../../../reducers/dialogReducer';
import strings from '../../../localization';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';

const { updatePharmacyAdministrators } = Constants.paths.admin;
const {
  getPharmacyAdminList: getAction,
  deletePharmacyAdmin: deletePharmacyAdminAction,
} = adminActions;

const usePharmacyAdministratorActions = (apiValues: FormikValues) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loadSuccess = useAppSelector((state) =>
    successSelector([getAction], state));
  const deleteSuccess = useAppSelector((state) =>
    successSelector([deletePharmacyAdminAction], state));

  const handleDelete = (values: FormikValues) => {
    const { id, reason } = values ?? {};
    dispatch(deletePharmacyAdmin({ pharmacyCompanyId: id, reason }));
  };

  const deleteAction = (id: string) =>
    () => {
      dispatch(
        setDialog({
          showCloseButton: true,
          heading: {
            title: '',
          },
          Component: (
            <DeactivateDialog
              id={id}
              heading={strings.deletePharmacyAdmin}
              confirmationMessage={strings.areYouSureDeletePharmacyAdmin}
              iconType={DeactivateModalIcon.warning}
              onSubmit={handleDelete}
            />
          ),
        }),
      );
    };

  const editAction = (id: string) =>
    () => {
      navigate(updatePharmacyAdministrators, { state: { id } });
    };

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(resetStatus([deletePharmacyAdminAction]));
      showSuccess(dispatch, strings.deletedSuccessfully);
      dispatch(getPharmacyAdminLists(apiValues as IPharmacyAdminListApiParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  return { editAction, deleteAction, loadSuccess };
};

export default usePharmacyAdministratorActions;
