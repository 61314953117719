import { createReducer } from '@reduxjs/toolkit';

import { logout } from '@pharmaplan/common';

import {
  renderScreen,
  resetDrawer,
  setBack,
  setButtons,
  setContent,
  setCurrent,
  setDrawerContentPadding,
  setFooter,
  setFooterShow,
  setOpen,
  setPagination,
  setTitle,
} from '../actions/drawerActions';
import { screenConfigs } from '../components/Drawer/configs';
import { IContent, IDrawerState } from '../types/IDrawer';
import { PrimaryAdminNavigation } from '../helpers/Constants';

const initialState: IDrawerState = {
  secondaryRoute: null,
  primaryRoute: PrimaryAdminNavigation.dashboard,
  open: false,
  back: null,
  current: 1,
  footer: {
    show: true,
    buttons: {
      show: true,
      primaryButton: {
        disabled: false,
        label: null,
        customButtonStyle: {},
        variant: 'contained',
        onClick: () =>
          null,
      },
      secondaryButton: {
        disabled: false,
        label: null,
        customButtonStyle: {},
        variant: 'text',
        onClick: () =>
          null,
      },
    },
    pagination: {
      show: true,
      total: 0,
      next: () =>
        null,
      prev: () =>
        null,
    },
  },
  title: {
    customTitleStyle: {},
    title: '',
  },
  content: null,
  drawerContentPadding: '16px',
};

export const drawerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setOpen, (state, action) => {
      state.open = action.payload;
    })

    .addCase(setBack, (state, action) => {
      state.back = action.payload;
    })

    .addCase(setFooter, (state, action) => {
      state.footer = action.payload;
    })

    .addCase(setTitle, (state, action) => {
      state.title = action.payload;
    })

    .addCase(setContent, (state, action) => {
      state.content = action.payload;
    })

    .addCase(setFooterShow, (state, action) => {
      state.footer.show = action.payload;
    })

    .addCase(setButtons, (state, action) => {
      state.footer.buttons = action.payload;
    })

    .addCase(setCurrent, (state, action) => {
      if (state.footer.pagination) {
        state.current = action.payload;
      }
    })

    .addCase(setPagination, (state, action) => {
      state.footer.pagination = action.payload;
    })

    .addCase(setDrawerContentPadding, (state, action) => {
      state.drawerContentPadding = action.payload;
    })

    .addCase(renderScreen, (state, action) => {
      const currentConfig = screenConfigs({
        type: action.payload.type,
        eventIds: action.payload.eventIds,
        eventId: action.payload.eventId,
        date: action.payload.date,
        bookingState: action.payload.bookingState,
        workshiftId: action.payload.workshiftId,
        pharmacistId: action.payload.pharmacistId,
        recType: action.payload.recType,
        recurrenceId: action.payload.recurrenceId,
        pharmacyId: action.payload.pharmacyId,
        hasOwner: action.payload.hasOwner,
        fromScreen: action.payload.fromScreen,
        extraParameters: action.payload.extraParameters,
        back: action.payload.back,
      })[action.payload.screenType as keyof typeof screenConfigs][
        action.payload.screenNumber - 1
      ] as IContent;
      state.content = currentConfig?.content;
      state.title.title = currentConfig?.title;
    })

    .addCase(resetDrawer, () =>
      initialState)

    .addCase(logout.fulfilled, () =>
      initialState);
});

export default drawerReducer;
