import React, { FC, useRef, useState } from 'react';

import { Typography } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import { FormikProps } from 'formik';

import { InputTypes, ButtonSize, Constants } from '../../../helpers/Constants';
import CustomButton from '../CustomButton';

import styles from './styles';

interface IUploadButton {
  errorMessage: string;
  message: string;
  startIcon?: React.ReactNode;
  chooseLabel: string;
  // format: '.png, .gif'
  acceptedFileTypes: string;
  // KB
  fileSize: number;
  formik: FormikProps<any>;
  name: string;
  customContainerStyle?: SxProps;
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  sizeConstraint?: { height: number; width: number };

  // base64
  base64Name?: string;
}

const UploadButton: FC<IUploadButton> = ({
  errorMessage,
  message,
  chooseLabel,
  acceptedFileTypes,
  fileSize,
  startIcon,
  formik,
  name,
  disabled,
  customContainerStyle,
  setDisabled,
  sizeConstraint,
  base64Name,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);

  const handleChooseFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      const fileSizeInKB = selectedFile.size / 1024;
      const blob = URL.createObjectURL(selectedFile);
      const im = new Image();
      im.src = blob;

      im.onload = () => {
        const { naturalHeight, naturalWidth } = im;
        const { height = 0, width = 0 } = sizeConstraint ?? {};
        if (sizeConstraint) {
          const surplusDimensions = naturalHeight > height || naturalWidth > width;
          setDisabled?.(surplusDimensions);
          setFileSizeError(surplusDimensions ? errorMessage : null);
        }
      };

      const reader = new FileReader();

      reader.readAsDataURL(selectedFile);

      reader.onload = () => {
        if (base64Name) {
          console.log('setting', reader.result);
          formik.setFieldValue(base64Name, reader.result);
        }
      };

      if (fileSizeInKB > fileSize) {
        setFileSizeError(errorMessage);
      } else {
        setFileSizeError(null);

        formik.setFieldValue(name, selectedFile);
      }
    }
  };

  return (
    <Box sx={[styles.contentContainer, customContainerStyle] as SxProps<Theme>}>
      <Box
        component="input"
        type={InputTypes.file}
        ref={fileInputRef}
        sx={styles.input}
        accept={acceptedFileTypes}
        onInput={handleFileChange}
      />

      <CustomButton
        startIcon={startIcon}
        label={chooseLabel}
        customClass={styles.chooseFileButton}
        customButtonStyle={styles.buttonWidth}
        size={ButtonSize.small}
        variant={Constants.variant.outlined}
        fullWidth={false}
        disabled={disabled}
        onClick={handleChooseFileClick}
      />

      {fileSizeError ? (
        <Typography sx={styles.errorFont}>{fileSizeError}</Typography>
      ) : (
        <Typography sx={styles.messageFont}>{message}</Typography>
      )}
    </Box>
  );
};

export default UploadButton;
