import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    maxHeight: '84vh',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
