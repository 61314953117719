import { SxProps, Theme } from '@mui/system';

const classes = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  contentSubContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  content: {
    fontSize: '16px',
    fontWeight: 500,
    color: 'common.gray',
  },

  button: {
    '& .MuiButton-root': {
      minWidth: '160px',
    },
  },

  displayNone: {
    display: 'none',
  },

  textContainer: {
    maxWidth: '953px',
    maxHeight: '164px',
    paddingTop: '8px',
  },

  headingContainer: {
    display: 'flex',
  },

  heading: {
    fontSize: '18px',
    fontWeight: 600,
    color: 'primary.inputLabel',
    paddingLeft: '6px',
  },

  privacyPolicyText: {
    paddingLeft: '4px',
    color: 'primary.main',
    textDecoration: 'none',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
