import React, { FC, FunctionComponent, SVGProps } from 'react';

import { Button, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import styles from './styles';

interface IEditorToolbarItems {
  Icon:
    | (OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
        muiName: string;
      })
    | (() => JSX.Element)
    | FunctionComponent<
        SVGProps<SVGSVGElement> & { title?: string | undefined }
      >;
  handleClick: () => void;
  isActive: boolean;
}

const EditorToolbarItems: FC<IEditorToolbarItems> = ({
  Icon,
  handleClick,
  isActive,
}) =>
  (
    <Button
      sx={[styles.buttonContainer, isActive ? styles.active : {}]}
      onClick={handleClick}
    >
      <Icon height={20} width={20} />
    </Button>
  );

export default EditorToolbarItems;
