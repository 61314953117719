import { createReducer } from '@reduxjs/toolkit';
import {
  INotificationState,
  INotifications,
  INotificationsData,
  genericNotifType,
} from '../models/NotificationsModel';
import {
  getNotification,
  getNotificationsCount,
  getNotificationsList,
  getNotificationsListPagination,
  logout,
  setClickedNotification,
  setInitialNotification,
} from '../actions';
import {
  BookingState,
  NotificationsType,
  responseType,
} from '../helpers/Constants';

export interface IClickedNotification {
  isRequested: boolean;
  item: null | INotificationsData;
}

export interface INotificationsInitialState {
  count: number;
  initialNotification: INotificationsData | null | genericNotifType;
  notificationsList: INotifications;
  notificationState: INotificationState;
  clickedNotification: IClickedNotification;
}

const initialState: INotificationsInitialState = {
  count: 0,
  notificationsList: {
    count: 0,
    totalCount: 0,
    data: [],
    pharmacistId: '',
    page: 1,
    itemPerPage: 1,
  },
  notificationState: {
    bookingState: BookingState.None,
    isAccessible: false,
    isViewed: false,
    status: '',
    userName: null,
    bookingId: '',
    notificationDate: '',
    notificationId: '',
    notificationType: NotificationsType.Unknown,
    text: '',
    workshiftId: '',
    isVisited: false,
    header: '',
    startDate: '',
    endDate: '',
    confirmBefore: null,
  },
  initialNotification: null,
  clickedNotification: {
    isRequested: false,
    item: null,
  },
};

const notificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getNotificationsList.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;
      state.notificationsList = action.payload;
    })
    .addCase(getNotificationsListPagination.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;
      state.notificationsList.count = action.payload.count;
      state.notificationsList.data.push(...action.payload.data);
    })
    .addCase(setInitialNotification, (state, action) => {
      state.initialNotification = action.payload;
    })
    .addCase(getNotification.fulfilled, (state, action) => {
      state.notificationState = action.payload;
    })
    .addCase(setClickedNotification, (state, action) => {
      state.clickedNotification = action.payload;
    })
    .addCase(logout.fulfilled, () => {
      return initialState;
    })
    .addCase(getNotificationsCount.fulfilled, (state, action) => {
      state.count = action.payload.count;
    });
});

export default notificationReducer;
