import { Avatar, Box, Typography } from '@mui/material';
import { IBookingsRequested, convertToDecimals, currency } from '@pharmaplan/common';
import React from 'react';
import { ReactComponent as RatingSmall } from '@pharmaplan/common/assets/icons/ratingSmall.svg';
import { ReactComponent as Location } from '@pharmaplan/common/assets/icons/locationSmall.svg';
import AvatarFallback from '@pharmaplan/common/assets/icons/avatarPlaceholder.png';
import { ReactComponent as FavouriteSmall } from '../../../../assets/svg/favIconWithBorder.svg';
import classes from './styles';
import { getDayMonthDateYear } from '../../../Reports/helpers';
import strings from '../../../../localization';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { renderScreen } from '../../../../actions/drawerActions';
import { ScreenTypes } from '../../../../helpers/Constants';
import { IBookingRequestsList } from '../BookingRequestsList';

interface IBookingRequest extends IBookingRequestsList {
  event: IBookingsRequested;
}

const BookingRequest: React.FC<IBookingRequest> = ({
  event,
  date,
  type,
  eventId,
  eventIds,
}) => {
  const dispatch = useAppDispatch();

  const {
    counterContainer,
    container,
    content,
    counterCard,
    counterOfferCard,
    counterOfferCardText,
    counterOfferPrice,
    rowContainer,
    titleContainer,
    titleMargin,
    ratingText,
    nameText,
    subtitle,
    subtitleContainer,
    avatar,
    avatarContainer,
    favouriteContainer,
    offerPrice,
    locationContainer,
  } = classes;

  const {
    counterOfferId,
    counterPrice,
    price,
    image,
    isFavorite,
    rating,
    requestedDate,
    distance = '',
    city,
    bookingId,
    name,
  } = event ?? {};

  const avatarSrc = image || AvatarFallback;
  const displayPrice = counterOfferId ? counterPrice : price;
  const displayRating = ((rating * 5) / 100).toPrecision(2);
  const convertedPrice = `${currency}${convertToDecimals(price)}`;

  const requestedOn = strings.formatString(
    strings.requestedOn,
    getDayMonthDateYear(requestedDate),
  );

  const convertedDisplayPrice = ` $${convertToDecimals(
    displayPrice as number,
  )}`;

  const displayDistance = strings.formatString(strings.numAway, distance ?? '');

  const gotoPharmacistProfile = () => {
    dispatch(
      renderScreen({
        eventId: bookingId,
        workshiftId: eventId,
        date,
        eventIds,
        type,
        screenNumber: 3,
        screenType: ScreenTypes.workshiftSummary,
      }),
    );
  };

  return (
    <Box sx={counterContainer}>
      {counterOfferId && (
        <Box style={counterCard}>
          <Box sx={counterOfferCard}>
            <Typography sx={counterOfferCardText}>
              {strings.counterOffer}
            </Typography>
          </Box>
        </Box>
      )}

      <Box sx={container} onClick={gotoPharmacistProfile}>
        <Box sx={avatarContainer}>
          <Avatar src={avatarSrc} sx={avatar} />

          {isFavorite && (
            <Box sx={favouriteContainer}>
              <FavouriteSmall />
            </Box>
          )}
        </Box>

        <Box sx={content}>
          <Box sx={titleContainer}>
            <Typography sx={nameText}>{name}</Typography>
            <Box sx={rowContainer}>
              <RatingSmall style={titleMargin} />
              <Typography sx={ratingText}>{displayRating}</Typography>
            </Box>
          </Box>

          <Box sx={subtitleContainer}>
            <Typography sx={subtitle}>{requestedOn}</Typography>

            <Box sx={rowContainer}>
              {counterOfferId && (
                <Typography sx={counterOfferPrice}>{convertedPrice}</Typography>
              )}
              <Typography sx={offerPrice}>{convertedDisplayPrice}</Typography>
            </Box>
          </Box>

          <Box sx={subtitleContainer}>
            <Box sx={locationContainer}>
              <Location />
              <Typography sx={subtitle}>{city}</Typography>
            </Box>

            <Typography sx={subtitle}>{displayDistance}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingRequest;
