/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Message } from '@sendbird/uikit-react/GroupChannel/components/Message';
// eslint-disable-next-line import/no-unresolved
import { RenderMessageParamsType } from '@sendbird/uikit-react/types/types';

import MessageContent from './MessageContent';

const CustomMessage = (props: RenderMessageParamsType) =>
  <Message {...props} renderMessageContent={MessageContent} />;

export default CustomMessage;
