import { SxProps, Theme } from '@mui/system';

const logoSize = 75;

const styles = {
  container: {
    backgroundColor: '#F6F7FC33',
    boxShadow: '0px 4px 10px 0px #D4D4D433',
    padding: '16px 40px 24px 24px',
    border: '1px solid',
    borderColor: 'common.border',
    flexDirection: 'row',
    mb: '16px',
    display: 'flex',
    width: '100%',
    maxWidth: '1025px',
  },
  detailsContainer: {
    display: 'flex',
    border: '1px solid',
    borderColor: 'rgba(225, 228, 239, 0.5)',
    width: '100%',
    backgroundColor: 'white',
  },
  emptyDivider: {
    mx: '20px',
  },
  subContainer: {
    mt: '8px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  logo: {
    height: logoSize,
    width: logoSize,
    borderRadius: logoSize / 2,
    m: '0px 16px 9px 0px',
    objectFit: 'contain',
    border: '1px solid',
    borderColor: 'common.border',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
