import React, { FC } from 'react';

import CustomButton from '../../../common/CustomButton';
import { Constants } from '../../../../helpers/Constants';

import classes from './style';

interface IAvailabilitiesFooter {
  label: string;
  onClick: () => void;
}

const { availabilitiesButton, buttonContainer } = classes;
const {
  variant: { text },
} = Constants;

const AvailabilitiesFooter: FC<IAvailabilitiesFooter> = ({
  label,
  onClick,
}) =>
  (
    <CustomButton
      customButtonStyle={availabilitiesButton}
      variant={text}
      customClass={buttonContainer}
      label={label}
      onClick={onClick}
    />
  );
export default AvailabilitiesFooter;
