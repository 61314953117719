import React, { useEffect, useMemo, useRef, useState } from 'react';

import GoogleMapReact from 'google-map-react';
import { Box, Typography } from '@mui/material';

import {
  getNearbyProps,
  ICalendarEventType,
  PharmacistLegendVariant,
  ReportRequestTypes,
  setNearbyProps,
} from '@pharmaplan/common';

import { useAppSelector } from '../../../hooks/useAppSelector';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useDrawerNavigation from '../../../hooks/useDrawerNavigation';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useUsersLocation, { Position } from '../../../hooks/useUsersLocation';
import useMap from '../../../hooks/Map/useMap';
import useAdmin from '../../../hooks/useAdmin';

import { createOffset } from './util';
import './style.scss';
import classes from './style';

const offsetCoords = (
  type: ICalendarEventType,
  lat: number,
  lng: number,
): { lat: number; lng: number } => {
  switch (type) {
    case PharmacistLegendVariant.booked:
      return { lat, lng };
    case PharmacistLegendVariant.requested:
      return createOffset({ latitude: lat, longitude: lng }, 200, 200);
    case ReportRequestTypes.workshift:
      return createOffset({ latitude: lat, longitude: lng }, 300, 300);
    default:
      return { lat: -91, lng: -181 }; // WILL NOT SHOW ON MAP
  }
};

const defaultProps = {
  center: {
    lat: 45.5019,
    lng: -73.5674,
  },
  zoom: 12,
};

const Marker = ({ type, text, onClick }: any) =>
  (
    <Box sx={classes.marker(type)} onClick={onClick}>
      <Typography sx={classes.markerText}>{text}</Typography>
    </Box>
  );

const PharmacistMap = () => {
  const dispatch = useAppDispatch();
  const { height } = useWindowDimensions();
  const { isAdmin } = useAdmin();

  const nearbyProps = useAppSelector(getNearbyProps);

  const circles = useRef<Array<any>>([]);
  const { mapEvents, handleMapNavigation } = useMap();

  const [mapProps, setMapProps] = useState<null | {
    map: any;
    maps: any;
    ref: Element | null;
  }>(null);

  const locationSuccess = (position: Position) => {
    const { coords } = position;
    const { latitude, longitude } = coords ?? {};

    dispatch(setNearbyProps({
      ...nearbyProps,
      latitude,
      longitude,
    }));
  };

  const { openDrawer } = useDrawerNavigation();

  useUsersLocation(locationSuccess);

  const filteredEvents = useMemo(
    () =>
      mapEvents?.filter((item) =>
        item?.latitude !== null),
    [mapEvents],
  );

  const handleApiLoaded = (mRef: any) => {
    setMapProps(mRef);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    circles.current.length !== 0
      && circles.current.map((item) =>
        item.setMap(null));
    circles.current = [];
  }, [filteredEvents]);

  useEffect(() => {
    if (mapProps) {
      filteredEvents?.map((item) =>
        circles.current.push(
          new mapProps.maps.Circle({
            strokeColor: '#F0FFFF',
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: 'rgba(240, 255, 255,0.5)',
            fillOpacity: 0.4,
            map: mapProps.map,
            center: createOffset(
              {
                latitude: Number(item.latitude),
                longitude: Number(item.longitude),
              },
              100,
              100,
            ),
            radius: 500,
          }),
        ));
    }
  }, [mapProps, filteredEvents]);

  const mapNavigation = (type: ICalendarEventType, eventIds: Array<string>) =>
    () =>
      (isAdmin
        ? handleMapNavigation(type, eventIds)
        : openDrawer({
          eventIds,
          type: type ?? PharmacistLegendVariant.booked,
        }));

  return (
    <div style={{ height: (height ?? 1) / 2, width: '100%' }}>
      <GoogleMapReact
        defaultCenter={defaultProps.center}
        center={{ lat: nearbyProps.latitude, lng: nearbyProps.longitude }}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
      >
        {filteredEvents.map((item) =>
          item.events.map((event) => {
            const { count, type, data } = event ?? {};

            return (
              <Marker
                key={event.data[0].id}
                lat={
                  offsetCoords(
                    type,
                    Number(item.latitude),
                    Number(item.longitude),
                  ).lat
                }
                lng={
                  offsetCoords(
                    type,
                    Number(item.latitude),
                    Number(item.longitude),
                  ).lng
                }
                text={count}
                type={type}
                onClick={mapNavigation(
                  type,
                  data.map((ev) =>
                    ev.id),
                )}
              />
            );
          }))}
      </GoogleMapReact>
    </div>
  );
};

export default PharmacistMap;
