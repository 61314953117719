import { FormikProps } from 'formik';
import { adminActions, adminReportsPDF, adminResetValue, getAdminReportsPDF, resetStatus, successSelector } from '@pharmaplan/common';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { useAppDispatch } from '../useAppDispatch';
import useAdminReportsHandler from './useAdminReportsHandler';
import { useAppSelector } from '../useAppSelector';
import { downloadPDF } from '../../helpers/Functions';
import { MomentTimeFormats } from '../../helpers/Constants';

const reportsFileName = 'reports.pdf';
const successAction = adminActions.getAdminReportsPDF;

const useAdminReportsPDF = (formik: FormikProps<any>) => {
  const dispatch = useAppDispatch();
  const { handleDropdown, reportType } = useAdminReportsHandler();

  const pdfSuccess = useAppSelector((state) =>
    successSelector([successAction], state));
  const pdf = useAppSelector(adminReportsPDF);

  const { values } = formik ?? {};
  const { pdfContent } = pdf ?? {};

  const { startDate, endDate, pharmacistId, sortBy } = values ?? {};

  const handledReplacement = handleDropdown(formik.values.replacementType);
  const handledAvailability = handleDropdown(formik.values.availabilityStatus);

  const exportPDF = () => {
    dispatch(
      getAdminReportsPDF({
        startDate: startDate
          ? (startDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        endDate: endDate
          ? (endDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        type: reportType,
        pharmacistId,
        replacementType: handledReplacement as number,
        availabilityStatus: handledAvailability as number,
        page: 0,
        sortBy,
      }),
    );
  };

  useEffect(() => {
    if (pdfSuccess) {
      downloadPDF(pdfContent, reportsFileName);
      dispatch(resetStatus([successAction]));
      dispatch(adminResetValue([successAction]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfContent, pdfSuccess]);

  return { exportPDF };
};

export default useAdminReportsPDF;
