import { useEffect } from 'react';

import { Settings } from 'luxon';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Language,
  language,
  preLoginLang,
  setPreLoginLangWeb,
  setUserLanguage,
} from '@pharmaplan/common';
import LogoutHandler from '@pharmaplan/common/helpers/LogoutHandler';
import { Constants } from '../helpers/Constants';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const { en, fr } = Language;

function useLanguage() {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['language']);

  const preLoginWebLang = useAppSelector(preLoginLang);
  const isUserLoggedIn = LogoutHandler.isUserLoggedIn();
  const userLang = useAppSelector(language);

  const queryParams = new URLSearchParams(search);
  const langFromURL = queryParams.get('lang');

  const changeLang = (lang: string) => {
    setCookie('language', lang, { path: '/', maxAge: Constants.cookieExpiry });
    dispatch(isUserLoggedIn ? setUserLanguage(lang) : setPreLoginLangWeb(lang));
    Settings.defaultLocale = lang;

    !isUserLoggedIn && window.location.reload();
  };

  const changeSignupLang = (lang: string) => {
    setCookie('language', lang, { path: '/', maxAge: Constants.cookieExpiry });
    dispatch(setPreLoginLangWeb(lang));
    Settings.defaultLocale = lang;
  };

  const setLangFromURL = () => {
    const forceFrOrEn = langFromURL === en ? en : fr;

    if (!isUserLoggedIn) {
      setCookie('language', forceFrOrEn, {
        path: '/',
        maxAge: Constants.cookieExpiry,
      });
    }

    queryParams.delete('lang');
    const updatedURL = `${pathname}`;
    navigate(updatedURL, { replace: true });
    window.location.reload();
  };

  useEffect(() => {
    isUserLoggedIn && changeLang(userLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  useEffect(() => {
    langFromURL && setLangFromURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langFromURL]);

  return { changeLang, changeSignupLang, preLoginWebLang, cookies };
}

export default useLanguage;
