import { SxProps, Theme } from '@mui/system';

const styles = {
  container: (isFr: boolean) =>
    ({
      padding: '10px',
      paddingBottom: 0,
      mt: '15px',
      mr: '20px',
      minHeight: isFr ? '330px' : '300px',
    }),
  barContainer: {
    border: '0.2px solid',
    borderRadius: '13px',
    backgroundColor: '#F7F9FB',
    borderColor: 'rgba(51,51,51,0.5)',
  },
  pieContainer: {
    backgroundColor: 'rgba(243,243,243,0.6)',
    borderRadius: '20px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    mr: '19px',
  },
  title: {
    fontSize: 12,
    fontWeight: '500',
    color: 'black',
  },
} satisfies Record<
  string,
  ((isFr: boolean) => SxProps<Theme>) | SxProps<Theme>
>;

export default styles;
