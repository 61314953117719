import React from 'react';

import { Avatar, Typography, Box } from '@mui/material';

import { profileImage } from '@pharmaplan/common';

import { useAppSelector } from '../../../../hooks/useAppSelector';
import useUser from '../../../../hooks/useUser';

import styles from './styles';

const GroupChannelListTitle = () => {
  const { name = '' } = useUser();

  const avatar = useAppSelector(profileImage);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.avatarContainer}>
        <Avatar src={avatar} sx={styles.avatar} imgProps={{ sx: styles.img }} />
        <Typography sx={styles.name}>{name}</Typography>
      </Box>
    </Box>
  );
};

export default GroupChannelListTitle;
