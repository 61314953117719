import { IBookingsRequested } from '@pharmaplan/common';

export enum sortTypes {
  ascending = 'ascending',
  descending = 'descending',
}

const { ascending, descending } = sortTypes;

const sorter = (events: Array<IBookingsRequested>) => {
  const sortByBoolean = (property: keyof IBookingsRequested) => {
    const booleanSort = (x: IBookingsRequested, y: IBookingsRequested) =>
      Number(y[property]) - Number(x[property]);
    return events.sort(booleanSort);
  };

  const sortByNumeric = (
    property: keyof IBookingsRequested,
    sortIn: sortTypes,
  ) => {
    const numericSort = (a: IBookingsRequested, b: IBookingsRequested) => {
      switch (sortIn) {
        case descending:
          return (b[property] as number) - (a[property] as number);
        case ascending:
          return (a[property] as number) - (b[property] as number);
        default:
          return (a[property] as number) - (b[property] as number);
      }
    };
    return events.sort(numericSort);
  };

  const sortByDate = (property: keyof IBookingsRequested) => {
    const dateSort = (a: IBookingsRequested, b: IBookingsRequested) => {
      if (a[property] > b[property]) {
        return -1;
      }
      if (a[property] < b[property]) {
        return 1;
      }
      return 0;
    };

    return events.sort(dateSort);
  };

  return {
    sortByBoolean,
    sortByNumeric,
    sortByDate,
  };
};
export default sorter;
