import { SxProps, Theme } from '@mui/system';

const styles = {
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  title: {
    fontSize: '12px',
    fontWeight: 400,
    mb: '4px',
  },
  valueContainer: {
    display: 'flex',
  },
  value: {
    fontSize: '14px',
    fontWeight: 500,
  },
  chip: {
    display: 'flex',
    borderRadius: 42,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
