import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ThemeConstants from '../../../../theme/ThemeConstants';

export const style = {
  buttonsContainer: {
    flex: 1,
    paddingX: 2,
    paddingY: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    display: 'flex',
    backgroundColor: 'white',
  },
  button: {
    fontWeight: '500',
    fontSize: '12px',
  },
  clearButton: {
    color: 'black',
  },
  submitButton: {
    paddingX: 3,
  },
  monthTitle: {
    color: ThemeConstants.grey[800],
    fontWeight: '600',
  },
};

const useStyles = makeStyles((theme: Theme) =>
  ({

    downChevronIcon: {
      cursor: 'pointer',
      color: theme.palette.common.grey350,
    },
    yearLabel: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    yearCalendar: {
      padding: '6px',
      border: 'none',
    },
    yearCalendarContainer: {
      borderRadius: '4px',
      zIndex: 100,
      position: 'absolute',
      border: 'none',
    },

    yearCalendarHidden: {
      display: 'none',
    },

    monthViewReorder: {

      flex: '0 0 25% !important',
      fontWeight: 500,
      fontSize: '16px',
      margin: '0.5em 0px !important',
      padding: '1.5em 6px !important',
      borderRadius: '10px',
      '& --now': {
        backgroundColor: 'red',
      },
    },

  }));

export default useStyles;
