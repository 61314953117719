import { SxProps, Theme } from '@mui/system';

const classes = {
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px',
  },

  logoContainer: {
    display: 'flex',
    flex: 1,
    paddingBlock: '10px 20px',
    paddingInline: '8px',
  },

  contentContainer: {
    paddingRight: '32px',
  },

  box: {
    backgroundColor: 'common.lightBackgroundColor',
    borderRadius: '4px',
    padding: '24px',
    minWidth: '240px',
    maxWidth: '240px',
  },

  pharmacyNameText: {
    fontSize: 20,
    fontWeight: 600,
    color: 'common.grey850',
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '16px',
  },

  locationContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '3px',
  },

  cityText: {
    fontSize: 14,
    fontWeight: 400,
    color: 'common.grey850',
    paddingLeft: '8px',
  },

  amountText: {
    fontSize: 26,
    fontWeight: 500,
    color: 'primary.main',
  },

  labelText: {
    fontSize: 14,
    fontWeight: 400,
    color: 'common.gray',
  },

  logoStyle: {
    height: 75,
    width: 75,
    border: '1px solid',
    borderColor: 'common.border',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
