import { useEffect, useRef, EffectCallback, DependencyList } from 'react';

export const useComponentDidUpdate = (effect: EffectCallback, dependencies: DependencyList) => {
  const hasMounted = useRef(false);

  useEffect(
    () => {
      if (!hasMounted.current) {
        hasMounted.current = true;
        return;
      }
      effect();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies,
  );
};
