import { SxProps, Theme } from '@mui/system';

const classes = {
  buttons: {
    padding: 2,
  },

  back: {
    mb: '15px',
  },

  backText: {
    fontWeight: '500',
    fontSize: 16,
    ml: 1,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
