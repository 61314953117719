import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    paddingX: '23px',
  },
  notes: {
    mb: 10,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
