import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    backgroundColor: 'secondary.main',
    margin: '16px 24px',
    padding: '24px 32px',
  },
  crumbContainer: {
    mb: '16px',
  },
  fieldsContainer: {
    ml: 0,
  },
  permissionsTitleContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    mb: '11px',
  },
  selectPermissionsTitle: {
    fontWeight: '500',
    fontSize: 14,
    color: 'grey.800',
  },
  dividerLine: {
    ml: '8px',
    flex: 1,
  },
  title: {
    fontWeight: '600',
    fontSize: 22,
    color: 'common.grey850',
    mb: '23px',
  },
  buttonContainer: {
    marginTop: '42px',
    justifyContent: 'flex-end',
  },
  noUnderline: {
    textDecoration: 'none',
    fontSize: 14,
  },
  prevLink: {
    color: 'grey.500',
  },
  emailContainer: {
    position: 'relative',
    minHeight: 40,
  },
  confirmButton: {
    minWidth: 20,
    position: 'absolute',
    right: 10,
    top: 30,
  },
  errorContainer: {
    mt: '4px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
