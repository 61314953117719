import React from 'react';

import { onMessage, getMessaging } from 'firebase/messaging';
import toast from 'react-hot-toast';

import ToastNotification from '../../components/ToastNotification';
import genericClasses from '../../theme/GenericClasses';

const duration = 5 * 60 * 1000;

const useForegroundNotifications = () => {
  const messaging = getMessaging();

  onMessage(messaging, (payload) => {
    toast(
      (t) =>
        <ToastNotification data={payload.data as any} toastParams={t} />,
      { duration, style: genericClasses.toastWidth },
    );
  });
};

export default useForegroundNotifications;
