import {
  acceptUpdatedTimings,
  approveRequest,
  getTypeOfUser,
  NotificationsType,
} from '@pharmaplan/common';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';

interface IApproveActionParams {
  bookingId: string;
  callback?: () => void;
}

const {
  BookingRequest,
  CounterOfferSubmission,
  PharmacistReminderApproveBooking,
  BookedWorkshiftUpdate,
  PharmacyApprovedBooking,
} = NotificationsType;

const useNotificationActions = () => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(getTypeOfUser);

  const approveAction = ({ bookingId, callback }: IApproveActionParams) =>
    () => {
      callback?.();
      dispatch(approveRequest({ userType, bookingId }));
    };

  const approveUpdatedTiming = ({
    bookingId,
    callback,
  }: IApproveActionParams) =>
    () => {
      callback?.();
      dispatch(acceptUpdatedTimings(bookingId));
    };

  const confirmAction = {
    [BookingRequest]: approveAction,
    [CounterOfferSubmission]: approveAction,
    [PharmacistReminderApproveBooking]: approveAction,
    [BookedWorkshiftUpdate]: approveUpdatedTiming,
    [PharmacyApprovedBooking]: approveAction,
  };

  return { confirmAction };
};

export default useNotificationActions;
