import React, { Fragment, useCallback, useEffect } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikValues, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  adminActions,
  adminDetails,
  AdminTypes,
  allAdminPermissions,
  ConfirmationTypes,
  getAdminDetails,
  getLanguages,
  resetStatus,
  successSelector,
  updateAdmin,
} from '@pharmaplan/common';
import { formGenerator } from '../../../../../helpers/formGenerator';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import strings from '../../../../../localization';
import CustomBreadcrumbs from '../../../../common/CustomBreadcrumbs';
import CustomButton from '../../../../common/CustomButton';
import { showSuccess } from '../../../Profile/Pharmacist/PharmacistViewProfile/helper';
import { ReactComponent as EditField } from '../../../../../assets/svg/editField.svg';
import useConfirmTextField from '../../../../../hooks/useConfirmTextField';
import CustomTextField from '../../../../common/CustomTextField';
import genericClasses from '../../../../../theme/GenericClasses';

import AdminPermissions from '../AdminPermissions';
import { fieldConfig, updateCrumbs } from '../helper';
import styles from '../styles';
import Validator from '../validator';

const successAction = adminActions.updateAdmin;

const UpdateAdmin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allPermissions = useAppSelector(allAdminPermissions);

  const { state } = useLocation();
  const { userId } = state ?? {};
  const { confirmation, disabled } = useConfirmTextField(
    ConfirmationTypes.editEmail,
  );

  const allLanguages = useAppSelector(getLanguages);
  const details = useAppSelector(adminDetails);

  const { email, permissions, userName, userType, languageId, phone, supportAgentName } = details ?? {};
  const isSuperAdmin = userType.toString() === `${AdminTypes.superadmin}`;

  const permMap = permissions.map((item) =>
    item.permissionId);

  const allPerms = allPermissions.permissions.map((item) =>
    item.permissionId);
  const success = useAppSelector((selectState) =>
    successSelector([successAction], selectState));

  const handleSubmit = (values: FormikValues) => {
    const {
      email: formikEmail,
      username: formikUsername,
      languageId: formikLanguageId,
      userType: formikUserType,
      permissions: formikPermissions,
      phone: formikPhone,
      supportAgentName: formikSupportAgentName,
    } = values;

    dispatch(
      updateAdmin({
        userId,
        email: formikEmail,
        username: formikUsername,
        languageId: formikLanguageId,
        userType: formikUserType,
        phone: formikPhone,
        permissions: formikPermissions,
        supportAgentName: formikSupportAgentName,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      email,
      username: userName,
      languageId,
      userType,
      phone,
      permissions: isSuperAdmin ? allPerms : permMap,
      supportAgentName,
    },
    onSubmit: handleSubmit,
    validationSchema: Validator(),
    enableReinitialize: true,
  });

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.updatedSuccessfullyFormat);
      dispatch(resetStatus([successAction]));
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dispatch(getAdminDetails(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmButton = useCallback(
    () =>
      (
        <Button sx={styles.confirmButton} onClick={confirmation}>
          <EditField />
        </Button>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isSuperAdmin) {
      formik.setFieldValue('permissions', allPerms);
    } else {
      formik.setFieldValue('permissions', permMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  const handleClick = (adminTypes: AdminTypes) => {
    if (adminTypes.toString() === `${AdminTypes.superadmin}`) {
      formik.setFieldValue(
        'permissions',
        allPermissions.permissions.map((item) =>
          item.permissionId),
      );
    } else {
      formik.setFieldValue('permissions', []);
    }
  };

  const title = `${strings.edit} ${strings.administrator}`;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.crumbContainer}>
        <CustomBreadcrumbs crumbs={updateCrumbs} />
      </Box>
      <Typography sx={styles.title}>
        {title}
      </Typography>
      <Grid container xs={10} columnSpacing={4}>
        <Grid item xs={4}>
          <Box sx={styles.emailContainer}>
            <CustomTextField
              customClass={{
                ...genericClasses.profileInputMargins,
                ...styles.fieldsContainer,
              }}
              name="email"
              id="email"
              formik={formik}
              label={strings.email}
              disabled={disabled}
            />
            {disabled && <ConfirmButton />}
          </Box>
        </Grid>
        {fieldConfig(allLanguages, handleClick)
          .slice(1)
          .map((item) => {
            const { key } = item;
            return <Fragment key={key}>{formGenerator(item, formik)}</Fragment>;
          })}
      </Grid>
      <AdminPermissions formik={formik} />
      <Grid container xs={10} sx={styles.buttonContainer}>
        <CustomButton label={strings.save} onClick={formik.handleSubmit} />
      </Grid>
    </Box>
  );
};

export default UpdateAdmin;
