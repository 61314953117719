import { SxProps, Theme } from '@mui/system';

const classes = {
  title: {
    paddingTop: '16px',
    paddingX: '16px',
    fontSize: 16,
    fontWeight: '600',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
