import { useState } from 'react';

const useSelectedTabs = <T, >(initValue: T) => {
  const [selectedTabs, setSelectedTabs] = useState<T>(initValue);

  const handleTabClick = (tab: T) => {
    setSelectedTabs(tab);
  };

  return { selectedTabs, setSelectedTabs, handleTabClick };
};

export default useSelectedTabs;
