import {
  adminSettings,
  allAdminPermissions,
  PermissionsOfAdmin,
} from '@pharmaplan/common';
import useAdmin from '../useAdmin';
import { useAppSelector } from '../useAppSelector';

const useAdminPermissions = () => {
  const details = useAppSelector(adminSettings);
  const allPermissions = useAppSelector(allAdminPermissions);

  const { isSuperAdmin } = useAdmin();

  const allPermissionTypes = allPermissions.permissions.map(
    (item) =>
      item.type,
  );

  const matchingTypes = details?.permissions
    ?.filter((detailPermission) =>
      allPermissions.permissions.some(
        (permission) =>
          detailPermission.permissionId === permission.permissionId,
      ))
    .map(
      (detailPermission) =>
        allPermissions.permissions.find(
          (permission) =>
            detailPermission.permissionId === permission.permissionId,
        )?.type,
    );

  const can = (permission: PermissionsOfAdmin) =>
    (isSuperAdmin ? allPermissionTypes : matchingTypes)?.includes(permission);

  return { can };
};

export default useAdminPermissions;
