import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

import { activeDate, AdminLegendVariant } from '@pharmaplan/common';
import { useNavigate } from 'react-router-dom';
import styles from './styles';
import strings from '../../../../localization';
import { Constants, PrimaryAdminNavigation } from '../../../../helpers/Constants';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getVisibleDatesMonth } from '../../../../helpers/Functions';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setPrimaryRoute } from '../../../../actions/navbarActions';

interface IAdminLegend {
  variant: `${AdminLegendVariant}`;
  totalHrs: number;
  totalCount: number;
  customClass?: Record<string, unknown>;
}

const { bookingReport, requestedReport, postedWorkshiftReport } = Constants.paths.admin;
const { booked, posted, requested } = AdminLegendVariant;

const AdminLegend = ({
  variant,
  totalHrs,
  totalCount,
  customClass,
}: IAdminLegend) => {
  const isTotalVariant = variant === AdminLegendVariant.total;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentDate = useAppSelector(activeDate);
  const { start, end } = getVisibleDatesMonth(currentDate);

  const pathMap = {
    [booked]: bookingReport,
    [posted]: postedWorkshiftReport,
    [requested]: requestedReport,
  };

  const onClick = () => {
    dispatch(setPrimaryRoute(PrimaryAdminNavigation.reports));
    navigate(pathMap[variant as keyof typeof pathMap], {
      state: { start, end },
    });
  };

  return (
    <Grid
      wrap="nowrap"
      variant="text"
      component={Button}
      disabled={isTotalVariant}
      onClick={onClick}
      container
      sx={[
        styles.legendContainerBg(variant),
        isTotalVariant ? {} : styles.container(variant),
        customClass as SystemStyleObject,
      ]}
    >
      <Grid
        sx={[styles.startContainer, styles.columnContainer]}
        item
        xs={12}
        md={12}
      >
        <Box sx={styles.startContainer} component="div">
          <Typography noWrap sx={styles.legendTitle}>
            {strings[variant]}
          </Typography>
        </Box>
        <Box
          component="div"
          sx={[
            styles.legendTotalHrs,
            styles.totalHrsCountColor(variant),
            styles.startContainer,
          ]}
        >
          {strings.formatString(strings.hoursWorked, Math.trunc(totalHrs))}
        </Box>
      </Grid>
      <Grid item xs={10} md={2} sx={styles.totalCountContainer}>
        <Box
          sx={[styles.legendTotalCount, styles.totalHrsCountColor(variant)]}
          component="div"
        >
          {totalCount}
        </Box>
      </Grid>
    </Grid>
  );
};
export default AdminLegend;
