const classes = {
  container: {
    backgroundColor: 'common.lightBackgroundColor',
    padding: '12px',
    borderRadius: '10px',
  },
  containerWeekend: {
    marginTop: '16px',
  },
};

export default classes;
