import theme from '../../../theme';

const classes = {
  imageMenuItem: {
    width: '100%',
    maxHeight: '30px',
    maxWidth: '90px',
    height: 'auto',
  },
  dropdownContainer: {
    marginBottom: '1.3rem',
  },
  dropdownPlaceholder: {
    color: 'grey.600',
  },
  customTextFieldRequireLabel: { color: 'error.main', marginLeft: '2px' },
  horizontalDropdown: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      // marginRight: 1,
      flex: 2,
    },
    '& .MuiOutlinedInput-root': {
      flex: 4,
    },
    '& .MuiFilledInput-root': {
      flex: 4,
    },
  },
  error: { color: '#d32f2f', marginLeft: 10, minHeight: '20px' },

  placeholderText: {
    '& .MuiOutlinedInput-input': {
      color: `${theme.palette.grey[400]}`,
      fontWeight: 400,
      fontSize: '14px',
    },
    '& .MuiFilledInput-input': {
      color: `${theme.palette.grey[400]}`,
      fontWeight: 400,
      fontSize: '14px',
    },
  },
  removeFocus: {
    '& :focus': {
      backgroundColor: 'white !important',
    },
  },
  imageStyle: {
    marginTop: '5px',
  },
  customFilledInput: {
    maxHeight: '37px !important',
    '&.MuiFilledInput-root': {
      '&:before': {
        borderBottom: 'none',
        content: "''",
        position: 'relative',
        border: 'none',
      },
      '&:after': {
        borderBottom: 'none',
        border: 'none',
      },
      borderRadius: '4px',
      backgroundColor: 'common.lightBackgroundColor',
    },
    '& .MuiFilledInput-input': {
      paddingTop: '8px',
      fontSize: 14,
    },
  },
};

export default classes;
