import React, { FC, useCallback } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  adminActions,
  convertToDecimals,
  IAdminPharmacistBookingData,
  ITEMS_PER_PAGE,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { renderScreen } from '../../../../actions/drawerActions';
import { ScreenTypes } from '../../../../helpers/Constants';
import { timeString } from '../../../../helpers/Functions';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useGetItems from '../../../../hooks/useGetItems';
import strings from '../../../../localization';
import CustomAvatarSection from '../../../common/CustomAvatarSection';
import { getDayMonthDateYear } from '../../../Reports/helpers';
import { LocationText } from '../../AvailableWorkshifts/AvailableWorkshifts';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import genericClasses from '../../../../theme/GenericClasses';
import NoAvailabilities from '../../MatchingAvailabilities/NoAvailabilities';

import styles from '../styles';

interface ISinglePharmacistBooking {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  data: Array<IAdminPharmacistBookingData>;
}

const successAction = adminActions.getPharmacistBookingsList;

const SinglePharmacistBooking: FC<ISinglePharmacistBooking> = ({
  page,
  setPage,
  data,
}) => {
  const dispatch = useAppDispatch();
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const isEmpty = data.length !== 0;
  const { getImage } = useGetItems();

  const observerCallback = useCallback(
    (entries: { isIntersecting: any }[]) => {
      const paginationCondition = data.length >= page * ITEMS_PER_PAGE;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, data],
  );

  const { listEndRef } = useInfiniteScroll({ observerCallback });

  const config = useCallback(
    (startDate: string, endDate: string, city: string) => {
      const parsedDate = getDayMonthDateYear(startDate);
      const time = timeString(startDate, endDate, timeFormat);

      return [
        { key: '1', value: `${strings.date}: ${parsedDate}` },
        { key: '2', value: `${strings.time}: ${time}` },
        { key: '3', value: <LocationText label={city} /> },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const goToPharmacyBookingDetails = (bookingId: string, pharmacistId: string) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 3,
          screenType: ScreenTypes.pharmacistList,
          eventId: bookingId,
          pharmacistId,
        }),
      );
    };

  const menuActions = (bookingId: string, pharmacistId: string) =>
    [
      {
        key: '1',
        label: strings.viewDetails,
        onClick: goToPharmacyBookingDetails(bookingId, pharmacistId),
      },
    ];

  return (
    <Box sx={styles.listContainer}>
      <Typography sx={[styles.title, styles.items]}>
        {strings.listOfBookedPharmacists}
      </Typography>
      {isEmpty ? (
        <>
          {data?.map((item) => {
            const {
              logoId,
              name,
              startDate,
              endDate,
              city,
              paidHourlyRate,
              pharmacistId,
              bookingId,
            } = item ?? {};
            return (
              <Box key={bookingId} sx={styles.items}>
                <CustomAvatarSection
                  avatar={getImage(logoId)}
                  hourlyRate={convertToDecimals(paidHourlyRate) as number}
                  title={name}
                  successAction={successAction}
                  config={config(startDate, endDate, city)}
                  menuActions={menuActions(bookingId, pharmacistId)}
                  showFullImage={false}
                />
              </Box>
            );
          })}
          <Box
            component="div"
            ref={listEndRef}
            sx={genericClasses.paginationDiv}
          />
        </>
      ) : (
        <NoAvailabilities message={strings.noDataAvailable} />
      )}
    </Box>
  );
};

export default SinglePharmacistBooking;
