import React, { useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box, Card, Grid } from '@mui/material';
import { FormikValues, useFormik } from 'formik';

import {
  doesEmailExist,
  emailExists,
  getLanguages,
  preLoginLang,
  resetApproval,
  setSignedUp,
  setUserProps,
  SocialLoginType,
  username,
} from '@pharmaplan/common';
import { encryptPassword } from '@pharmaplan/common/helpers/utils';
import { useAppSelector } from '../../hooks/useAppSelector';
import classes from './styles';
import strings from '../../localization';
import { ReactComponent as LoginImageOne } from '../../assets/svg/loginImg2.svg';
import { ReactComponent as LoginImageTwo } from '../../assets/svg/loginImg1.svg';
import { ILoginLayout } from '../../types/ILoginLayout';
import LoginSocial from './LoginSocial/LoginSocial';
import Validator from '../Signup/SignupForm/Validator';
import { resetAll } from '../../actions/persistActions';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { Constants } from '../../helpers/Constants';
import Footer from '../Footer';
import CustomLink from '../common/CustomLink';
import {
  getBrowser,
  getPlatformOS,
  setPageTitle,
} from '../../helpers/Functions';
import useLanguage from '../../hooks/useLanguge';
import useNavAfterLogin from '../../hooks/useNavAfterLogin';

const {
  mainContainer,
  gridContainer,
  outerContainer,
  signupMargin,
  socialContainer,
  loginCard,
  loginContainer,
  loginImageContainer,
  loginImageOne,
  loginImageTwo,
  rememberMeNoAccount,
  rememberMeSignup,
  responsive,
} = classes;

const {
  paths: { signup: signupPath, signupFlow },

  appName,
} = Constants;

const LoginLayout = ({ Header, Form, isSignup }: ILoginLayout) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { changeSignupLang } = useLanguage();
  const isMounted = useRef(false);

  const name = useAppSelector(username);
  const languages = useAppSelector(getLanguages);

  const preLoginLanguage = useAppSelector(preLoginLang);
  const isEmailExist = useAppSelector(emailExists);

  useNavAfterLogin();

  const setUserPreferredLang = (language: string) => {
    const filterLanguage = languages.find(
      ({ languageId }) =>
        languageId === language,
    );

    const { code } = filterLanguage ?? {};

    if (preLoginLanguage !== code) {
      changeSignupLang(code ?? preLoginLanguage);
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const { email } = values ?? {};
    dispatch(doesEmailExist(email));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      role: '',
      language: '',
      token: '',
      loginType: SocialLoginType.google,
      isMediaLogin: false,
    },

    onSubmit: handleSubmit,
    validationSchema: Validator,
    enableReinitialize: true,
  });

  const { values } = formik ?? {};
  const { email, password, role, language, isMediaLogin, token, loginType } = values ?? {};

  const handleLogins = () => {
    dispatch(resetAll());
    setUserPreferredLang(language);
    dispatch(
      setUserProps({
        email,
        password: isMediaLogin ? null : encryptPassword(password),
        userType: role,
        appid: 'pp-windows',
        token: isMediaLogin ? token : null,
        loginType: isMediaLogin ? loginType : null,
        language,
        device: {
          osType: getPlatformOS() ?? '',
          type: getBrowser(),
          deviceToken: null,
        },
      }),
    );
    dispatch(setSignedUp(true));
    dispatch(resetApproval());
    navigate(signupFlow);
  };

  useEffect(() => {
    setPageTitle(`${strings.welcomeTo} ${appName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else if (!isEmailExist) {
      handleLogins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailExist]);

  return (
    <Box component="div" sx={mainContainer}>
      <Grid container sx={gridContainer}>
        <Grid item>
          <Box sx={[outerContainer, signupMargin]}>
            <Card sx={loginCard}>
              <Header />
              <Box component="div">
                <Box sx={loginContainer}>
                  <Form formik={formik} />
                </Box>
                {!name || isSignup ? (
                  <Box sx={socialContainer}>
                    <LoginSocial formik={formik} isSignup={isSignup ?? false} />
                  </Box>
                ) : (
                  <Box sx={rememberMeSignup}>
                    <Box component="div" sx={rememberMeNoAccount}>
                      {strings.noAccount}
                    </Box>
                    <CustomLink linkText={strings.signup} to={signupPath} />
                  </Box>
                )}
              </Box>
            </Card>

            {/* Bottom SVGs of Card */}
            <Box sx={[loginImageContainer, responsive]}>
              <LoginImageOne style={loginImageOne} />
              <LoginImageTwo style={loginImageTwo} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default LoginLayout;
