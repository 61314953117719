import React, { useEffect, useState } from 'react';
import { Grid, GridSize, SxProps } from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { FormikProps } from 'formik';
import { DateTime } from 'luxon';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import strings from '../../../localization';
import { setDisableSubmitFilter } from '../../../reducers/mainCalendarReducer';
import genericClasses from '../../../theme/GenericClasses';
import CustomDatePicker from '../CustomDatePicker';
import { serverTodayMinusOne } from '../../../helpers/Functions';

interface IDateRange {
  startName: string;
  endName: string;
  preventPast: boolean | undefined;
  startMin?: DateTime;
  startMax?: DateTime;
  endMax?: DateTime;
  formik: FormikProps<any>;
  preventFuture?: boolean;
  defaultMonth?: DateTime;
  xs?: boolean | GridSize;
  disabled?: boolean;
  customClass?: SxProps;
}

const DateRange = ({
  defaultMonth,
  startName,
  endName,
  formik,
  preventPast,
  startMin,
  startMax,
  endMax,
  xs = 6,
  preventFuture = false,
  disabled = false,
  customClass = genericClasses.profileInputMargins,
}: IDateRange) => {
  const [error, setError] = useState<{
    startError: string | string[] | null;
    endError: string | string[] | null;
  }>({
    startError: ' ',
    endError: ' ',
  });

  const dispatch = useAppDispatch();

  const errorConfig = (reason: DateValidationError) => {
    const invalidDate = reason === 'invalidDate';
    const minDate = reason === 'minDate';

    if (invalidDate) {
      return strings.pleaseEnterValidDate;
    }

    if (minDate) {
      return strings.minimumDate;
    }

    return ' ';
  };

  const handleStartError = (reason: DateValidationError) => {
    setError((prev) =>
      ({
        ...prev,
        startError: errorConfig(reason),
      }));
  };

  const handleEndError = (reason: DateValidationError) => {
    setError((prev) =>
      ({
        ...prev,
        endError: errorConfig(reason),
      }));
  };

  useEffect(() => {
    dispatch(
      setDisableSubmitFilter(error.endError === ' ' && error.startError === ' '),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <Grid item xs={xs}>
        <CustomDatePicker
          defaultMonth={defaultMonth}
          error={error.startError as string}
          minDate={preventPast ? serverTodayMinusOne : startMin}
          maxDate={preventFuture ? serverTodayMinusOne : startMax}
          onError={handleStartError}
          customClass={customClass}
          label={strings.startDate}
          readOnly
          formik={formik}
          name={startName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={xs}>
        <CustomDatePicker
          defaultMonth={formik.values[startName]}
          error={error.endError as string}
          minDate={formik.values[startName]}
          maxDate={preventFuture ? serverTodayMinusOne : endMax}
          readOnly
          onError={handleEndError}
          customClass={customClass}
          label={strings.endDate}
          formik={formik}
          name={endName}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default DateRange;
