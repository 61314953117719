import { IAdminPharmacistHistoryData, TimeFormat } from '@pharmaplan/common';
import { DynamicTableCell } from '../../../../helpers/Constants';
import { timeString } from '../../../../helpers/Functions';
import strings from '../../../../localization';
import { getDayMonthDateYear } from '../../../Reports/helpers';

const { text } = DynamicTableCell;

export const adminPharmacistHistoryHeaders = () =>
  [
    { key: '2', label: strings.code },
    { key: '3', label: `${strings.date}` },
    { key: '4', label: strings.time },
  ];

export const adminPharmacistHistoryRow = (
  data: Array<IAdminPharmacistHistoryData>,
  timeFormat: TimeFormat,
) =>
  data?.map((item) => {
    const { bookingId, code, endDate, startDate } = item;

    const date = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);

    return {
      key: bookingId,
      data: [
        {
          key: '1',
          value: code,
          type: text,
        },
        {
          key: '2',
          value: date,
          type: text,
        },
        {
          key: '3',
          value: time,
          maxWidth: 100,
          type: text,
        },
      ],
    };
  });
