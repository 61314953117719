import React, { useEffect } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  approvePendingCancellation,
  getPendingCancellations,
  IAdminApprovePendingCancellationApiParams,
  IAdminRejectPendingCancellationApiParams,
  rejectPendingCancellation,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import {
  approvePendingCancellationForm,
  rejectPendingCancellationForm,
} from '../../../components/Admin/PendingCancellations/helper';
import InputModal from '../../../components/Modals/InputModal';
import strings from '../../../localization';
import { setDialog } from '../../../reducers/dialogReducer';
import { useAppDispatch } from '../../useAppDispatch';
import Validator from '../../../components/Admin/PendingCancellations/validator';
import { useAppSelector } from '../../useAppSelector';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import { resetDrawer } from '../../../actions/drawerActions';

enum actions {
  approve = 'approve',
  reject = 'reject',
}

const {
  rejectPendingCancellation: rejectPendingCancellationAction,
  approvePendingCancellation: approvePendingCancellationAction,
} = adminActions;

const usePendingCancellationActions = () => {
  const dispatch = useAppDispatch();

  const approveSuccess = useAppSelector((state) =>
    successSelector([approvePendingCancellationAction], state));

  const rejectionSuccess = useAppSelector((state) =>
    successSelector([rejectPendingCancellationAction], state));

  const handleSubmit = (type: actions) =>
    (values: FormikValues) => {
      if (type === actions.approve) {
        dispatch(
          approvePendingCancellation(
          values as IAdminApprovePendingCancellationApiParams,
          ),
        );
      } else {
        dispatch(
          rejectPendingCancellation(
          values as IAdminRejectPendingCancellationApiParams,
          ),
        );
      }
    };

  const approveAction = (cancellationId: string, code: string) => {
    const initialValues = {
      cancellationId,
      code,
      notes: '',
      sendEmailToPharmacist: true,
      sendEmailToPharmacy: true,
    };

    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={handleSubmit(actions.approve)}
            initialValues={initialValues}
            submitLabel={strings.approveCancellation}
            config={approvePendingCancellationForm}
            validator={Validator()}
          />
        ),
        maxWidth: 'sm',
        heading: {
          title: strings.approveCancellation,
        },
        showCloseButton: true,
      }),
    );
  };

  const rejectAction = (cancellationId: string, code: string) => {
    const initialValues = {
      cancellationId,
      code,
      notes: '',
    };

    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={handleSubmit(actions.reject)}
            initialValues={initialValues}
            submitLabel={strings.rejectCancellation}
            config={rejectPendingCancellationForm}
            validator={Validator()}
          />
        ),
        maxWidth: 'sm',
        heading: {
          title: strings.rejectCancellation,
        },
        showCloseButton: true,
      }),
    );
  };

  const resetActions = () => {
    dispatch(
      resetStatus([
        approvePendingCancellationAction,
        rejectPendingCancellationAction,
      ]),
    );
    dispatch(resetDrawer());
    dispatch(getPendingCancellations({ page: 1 }));
  };

  useEffect(() => {
    if (approveSuccess || rejectionSuccess) {
      showSuccess(
        dispatch,
        approveSuccess ? strings.approvedSuccess : strings.rejectedSuccess,
      );
      resetActions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveSuccess, rejectionSuccess]);
  return { approveAction, rejectAction };
};

export default usePendingCancellationActions;
