import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  PopoverOrigin,
  SxProps,
  Theme,
} from '@mui/material';
import React from 'react';
import genericClasses from '../../../theme/GenericClasses';
import classes from './style';

interface ICustomMenuList {
  id?: string;
  items: Array<ICustomMenuItem>;
  disableFixedDropdown?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  margin?: number;
  disableRipple?: boolean;
  customIconButtonStyle?: SxProps;
  menuStyle?: SxProps;
  customMenuTextStyle?: SxProps;
  keepMounted?: boolean;
  children: React.ReactNode;
}

interface ICustomMenuItem {
  label: string | string[];
  icon?: string;
  key: string;
  onClick: (e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

const defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  disableRipple: true,
  margin: 0,
};

const CustomMenuList = ({
  id,
  items,
  anchorOrigin,
  transformOrigin,
  disableRipple,
  margin,
  disableFixedDropdown = false,
  keepMounted = false,
  customIconButtonStyle,
  children,
  customMenuTextStyle,
  menuStyle,
}: ICustomMenuList) => {
  const [anchorElNav, setAnchorElNav] = React.useState<HTMLButtonElement | null>(null);

  // to do change this type
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorElNav(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <Box sx={classes.customMenuContainer} component="div">
      <IconButton
        id={id}
        size="small"
        sx={customIconButtonStyle}
        onClick={handleOpenMenu}
        disableRipple={disableRipple}
      >
        {children}
      </IconButton>
      <Menu
        sx={menuStyle ?? classes.customMenu}
        id="menu-appbar"
        anchorEl={anchorElNav}
        marginThreshold={margin}
        style={
          disableFixedDropdown
            ? {}
            : (genericClasses.fixedDropdown as React.CSSProperties)
        }
        keepMounted={keepMounted}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        open={Boolean(anchorElNav)}
        onClose={handleCloseMenu}
      >
        {items.map((item) =>
          (
            <MenuItem
              key={item.key}
              onClick={(e) => {
                item.onClick(e);
                handleCloseMenu();
              }}
            >
              {item.icon && (
              <Box
                sx={classes.customMenuItemIcon}
                component="img"
                src={item.icon}
              />
              )}
              <Typography
                sx={
                [
                  classes.customMenuItemLabel,
                  customMenuTextStyle,
                ] as SxProps<Theme>
              }
              >
                {item.label}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};
CustomMenuList.defaultProps = defaultProps;

export default CustomMenuList;
