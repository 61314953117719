import { createReducer } from '@reduxjs/toolkit';
import {
  getBookedPharmacistDetails,
  getBookedRating,
  getBookingRequested,
  getHourlyRate,
  getPharmacyCalendar,
  getPharmacyHistory,
  getPharmacyNotes,
  getPharmacyReplacementPdf,
  getPharmacyReplacements,
  getPharmacyReportFilter,
  getPharmacyReports,
  getPharmacyReportsPage,
  getPharmacyReportsPdf,
  getPharmacyWorkshift,
  getRating,
  getRatingItems,
  logout,
  getReportIssue,
  reportIssue,
  getPharmacyReplacementsWeb,
  doesPharmacyExist,
  resetPharmacyExists,
  pharmacyCancelBooking,
  pharmacyPreApprove,
  getPharmacyHistoryPagination,
  getPharmacistReplacementDetails,
  getPharmacyCalendarWithoutLoad,
} from '../actions';
import { BookingState, Language, responseType } from '../helpers/Constants';
import { ICancelRequestedBooking, IPDF } from '../models';

import {
  IBookedRating,
  IBookedRatingDetails,
  IBookingsRequested,
  IPharmacistDetails,
  IPharmacyCalendar,
  IPharmacyFilterData,
  IPharmacyHistory,
  IPharmacyNotes,
  IPharmacyPreApprove,
  IPharmacyReplacements,
  IPharmacyReports,
  IRate,
  IRating,
  IRatingItems,
  IReportIssueData,
  IReportIssueResponse,
  IWorkshift,
} from '../models/PharmacyModels';

export interface IPharmacyState {
  replacements: IPharmacyReplacements;
  pharmacyHourlyRate: { hourlyRate: number };
  notes: Array<IPharmacyNotes>;
  history: IPharmacyHistory;
  calendar: Array<IPharmacyCalendar>;
  workshift: IWorkshift;
  reports: IPharmacyReports;
  filterData: Array<IPharmacyFilterData>;
  pdf: IPDF;
  pharmacistDetails: IPharmacistDetails;
  replacementPharmacistDetails: IPharmacistDetails;
  bookingsRequested: Array<IBookingsRequested>;
  ratingItems: IRatingItems;
  rate: IRate;
  rating: IRating;
  reportIssueData: Array<IReportIssueData>;
  reportIssueResponse: IReportIssueResponse;
  bookedRating: IBookedRatingDetails;
  pharmacyExists: boolean | null;
  cancelBookingMessage: ICancelRequestedBooking;
  preApprove: IPharmacyPreApprove;
}

export const pharmacyInitialState: IPharmacyState = {
  pharmacyHourlyRate: { hourlyRate: 0 },
  replacements: {
    page: 1,
    totalCount: 0,
    itemPerPage: 0,
    count: 0,
    pharmacyId: '',
    data: [],
  },
  bookingsRequested: [],
  workshift: {
    id: '',
    code: '',
    selectedDate: '',
    startHour: '',
    startMinute: '',
    endHour: '',
    endMinute: '',
    totalHour: '',
    totalMinute: '',
    booked: false,
    notes: '',
    active: false,
    createDate: '',
    endDate: new Date().toISOString(),
    startDate: new Date().toISOString(),
    pharmacyId: '',
    recurrenceId: '',
    recurrence: {
      createDate: '',
      day: 0,
      endDate: '',
      month: 0,
      notes: '',
      recurrenceId: '',
      recurrenceType: 0,
      startDate: '',
      updateDate: '',
      weekDays: '',
      weekNumber: 0,
    },
    counterStartDate: '',
    counterEndDate: '',
    counterHourlyRate: 0,
  },
  notes: [],
  history: {
    page: 1,
    itemPerPage: 1,
    totalCount: 1,
    pharmacistId: '',
    pharmacistName: '',
    data: [],
  },
  calendar: [],
  reports: {
    page: 1,
    totalCount: 0,
    count: 0,
    data: [],
  },
  filterData: [],
  pdf: {
    pdfContent: '',
  },
  pharmacistDetails: {
    pharmacistId: '',
    profilePicture: null,
    counterEndDate: '',
    counterStartDate: '',
    counterNote: '',
    counterOfferId: '',
    isFavorite: false,
    startDate: '',
    endDate: '',
    bookingState: BookingState.None,
    firstName: '',
    lastName: '',
    languageId: '',
    language: {
      languageId: '',
      code: Language.en,
      name: '',
      default: false,
    },
    phone: '',
    mobile: '',
    softwares: [],
    rating: null,
    pharmacistCapacity: {
      from: 0,
      to: 0,
      published: false,
      pharmacistCapacityId: '',
      createDate: '',
      updateDate: null,
    },
  },
  replacementPharmacistDetails: {
    pharmacistId: '',
    profilePicture: null,
    counterEndDate: '',
    counterStartDate: '',
    counterNote: '',
    counterOfferId: '',
    isFavorite: false,
    startDate: '',
    endDate: '',
    bookingState: BookingState.None,
    firstName: '',
    lastName: '',
    languageId: '',
    language: {
      languageId: '',
      code: Language.en,
      name: '',
      default: false,
    },
    phone: '',
    mobile: '',
    softwares: [],
    rating: null,
    pharmacistCapacity: {
      from: 0,
      to: 0,
      published: false,
      pharmacistCapacityId: '',
      createDate: '',
      updateDate: null,
    },
  },
  ratingItems: {
    favorite: false,
    pharmacistRatingItems: [],
  },
  rate: {
    bookingId: '',
    levels: [],
    favorite: false,
  },
  rating: {
    isFavorite: false,
    name: '',
    pharmacistId: '',
    reviews: 0,
    totalRating: '',
    ratings: [],
  },
  reportIssueData: [],
  reportIssueResponse: {
    bookingId: '',
    code: '',
    createDate: '',
    description: '',
    issueId: '',
    issueType: 0,
    reportedBy: '',
    reportedDate: '',
    reporterType: 0,
  },
  bookedRating: {
    data: [],
    comment: '',
    firstName: '',
    lastName: '',
    pharmacistId: '',
    isFavorite: false,
  },
  pharmacyExists: true,
  cancelBookingMessage: {
    message: '',
  },
  preApprove: {
    isSufficientSoftwareKnowledge: null,
    softwareLevel: 0,
    name: '',
  },
};

const pharmacyReducer = createReducer(pharmacyInitialState, (builder) => {
  builder
    .addCase(getPharmacyCalendar.fulfilled, (state, action) => {
      state.calendar = action.payload;
    })
    .addCase(getPharmacyCalendarWithoutLoad.fulfilled, (state, action) => {
      state.calendar = action.payload;
    })
    .addCase(getPharmacyReplacementsWeb.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) {
        state.replacements = { ...pharmacyInitialState.replacements };
      } else {
        state.replacements = action.payload;
      }
    })
    .addCase(getPharmacyReplacements.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;
      if (action.payload.page !== 1) {
        state.replacements.page = action.payload?.page;
        state.replacements.count = action.payload?.count;
        state.replacements.data.push(...action.payload?.data);
        state.replacements.pharmacyId = action.payload?.pharmacyId;
        return;
      }

      state.replacements = action.payload;
    })
    .addCase(getPharmacyReports.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) {
        state.reports = pharmacyInitialState.reports;
      }
      state.reports = action.payload;
    })
    .addCase(getPharmacyReportsPage.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;

      state.reports.page = action.payload?.page;
      state.reports.count = action.payload?.count;
      state.reports.data.push(...action.payload?.data);
    })
    .addCase(getPharmacyHistoryPagination.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;

      state.history.page = action.payload?.page;
      state.history.totalCount = action.payload?.totalCount;
      state.history.data.push(...action.payload?.data);
    })
    .addCase(getPharmacyWorkshift.fulfilled, (state, action) => {
      state.workshift = action.payload;
    })
    .addCase(getPharmacyNotes.fulfilled, (state, action) => {
      state.notes = action.payload;
    })
    .addCase(getPharmacyReportFilter.fulfilled, (state, action) => {
      state.filterData = action.payload;
    })
    .addCase(getPharmacyReportsPdf.fulfilled, (state, action) => {
      state.pdf = action.payload;
    })
    .addCase(getPharmacyReplacementPdf.fulfilled, (state, action) => {
      state.pdf = action.payload;
    })
    .addCase(getBookedPharmacistDetails.fulfilled, (state, action) => {
      state.pharmacistDetails = action.payload;
    })
    .addCase(getPharmacyHistory.fulfilled, (state, action) => {
      state.history = action.payload;
    })
    .addCase(getBookingRequested.fulfilled, (state, action) => {
      state.bookingsRequested = action.payload;
    })
    .addCase(getHourlyRate.fulfilled, (state, action) => {
      state.pharmacyHourlyRate = action.payload;
    })
    .addCase(getRatingItems.fulfilled, (state, action) => {
      state.ratingItems = action.payload;
    })
    .addCase(getRating.fulfilled, (state, action) => {
      state.rating = action.payload;
    })
    .addCase(getReportIssue.fulfilled, (state, action) => {
      state.reportIssueData = action.payload;
    })
    .addCase(reportIssue.fulfilled, (state, action) => {
      state.reportIssueResponse = action.payload;
    })
    .addCase(getBookedRating.fulfilled, (state, action) => {
      state.bookedRating = action.payload;
    })
    .addCase(doesPharmacyExist.fulfilled, (state, action) => {
      state.pharmacyExists = action.payload;
    })
    .addCase(pharmacyCancelBooking.fulfilled, (state, action) => {
      state.cancelBookingMessage = action.payload;
    })
    .addCase(resetPharmacyExists, (state) => {
      state.pharmacyExists = true;
    })
    .addCase(pharmacyPreApprove.fulfilled, (state, action) => {
      state.preApprove = action.payload;
    })
    .addCase(getPharmacistReplacementDetails.fulfilled, (state, action) => {
      state.replacementPharmacistDetails = action.payload;
    })
    .addCase(logout.fulfilled, () => pharmacyInitialState);
});

export default pharmacyReducer;
