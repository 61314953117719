import { useEffect } from 'react';

import { FormikProps } from 'formik';

import { setLocation, userAddress, userLocation } from '@pharmaplan/common';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';

interface IUseSyncLocationFormik {
  formik: FormikProps<any>;
  latitude: number | null;
  longitude: number | null;
}

const useSyncLocationFormik = ({
  formik,
  latitude,
  longitude,
}: IUseSyncLocationFormik) => {
  const dispatch = useAppDispatch();

  const location = useAppSelector(userLocation);
  const address = useAppSelector(userAddress);
  const { latitude: locationLat, longitude: locationLong } = location ?? {};

  useEffect(() => {
    formik.setFieldValue('latitude', locationLat);
    formik.setFieldValue('longitude', locationLong);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationLat, locationLong]);

  useEffect(() => {
    if (address) {
      formik.setFieldValue('address', address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    dispatch(setLocation({ latitude, longitude }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude]);

  return null;
};

export default useSyncLocationFormik;
