import React, { useEffect, useState } from 'react';

import { FormikValues, useFormik } from 'formik';

import {
  ReportRequestTypes,
  getIncomeDetails,
  getIncomeReports,
  getReportsFilterInfo,
  logos,
  selectIncomeDetails,
  selectIncomeReports,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import {
  formatDate,
  incomeDetailsHeaders,
  incomeDetailsMapper,
  incomeReportHeaders,
  incomeReportMapper,
} from '../../components/Reports/IncomeReport/helper';
import CustomButton from '../../components/common/CustomButton';
import { serverToday, setPageTitle } from '../../helpers/Functions';
import strings from '../../localization';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { MomentTimeFormats } from '../../helpers/Constants';
import useTableSort from '../useTableSort';

const useIncomeReport = () => {
  const dispatch = useAppDispatch();

  const [isFiltered, setIsFiltered] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isDetailedIncome, setIsDetailedIncome] = useState(false);
  const [page, setPage] = useState(1);
  const [pharmacyId, setPharmacyId] = useState('');

  const { isDesc, orderBy, handleSorting, order, resetSorting } = useTableSort();

  const today = serverToday();
  const pastThirty = serverToday().minus({ day: 30 });

  const startInitial = pastThirty.toFormat(MomentTimeFormats.fullDate);
  const endInitial = today.toFormat(MomentTimeFormats.fullDate);

  const initialValues = {
    startDate: null,
    endDate: null,
    pharmacyIds: [],
  };

  const allLogos = useAppSelector(logos);
  const incomeReports = useAppSelector(selectIncomeReports);
  const detailedReports = useAppSelector(selectIncomeDetails);
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const { data, totalCount } = incomeReports ?? {};
  const { data: detailedReportsData, totalCount: detailsTotalCount } = detailedReports ?? {};

  const getLogo = (logoId: string) => {
    const { image } = allLogos.find(({ logoId: allLogoId }) =>
      allLogoId === logoId) ?? {};
    return image;
  };

  const onSubmit = (values: FormikValues) => {
    const {
      startDate: formikStartDate,
      endDate: formikEndDate,
      pharmacyIds: formikPharmacyIds,
    } = values ?? {};

    const isEmpty = Object.values(values).every(
      (x) =>
        x?.length === 0 || x === null,
    );

    setIsFiltered(!isEmpty);

    dispatch(
      getIncomeReports({
        startDate: formatDate(formikStartDate),
        endDate: formatDate(formikEndDate),
        pharmacyIds: formikPharmacyIds,
        page: 1,
      }),
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const { values } = formik ?? {};
  const { startDate, endDate, pharmacyIds } = (values as any) ?? {};

  const dispatchIncomeReport = (sortBy?: string, desc?: boolean, isClear = false) => {
    const {
      startDate: formikStartDate,
      endDate: formikEndDate,
      pharmacyIds: formikPharmacyIds,
    } = formik.values ?? {};

    if (isClear) {
      dispatch(
        getIncomeReports({
          ...initialValues,
          page: 1,
          sortBy,
          desc,
        }),
      );
    } else {
      dispatch(
        getIncomeReports({
          startDate: formatDate(formikStartDate ?? ''),
          endDate: formatDate(formikEndDate ?? ''),
          pharmacyIds: formikPharmacyIds,
          page: 1,
          sortBy,
          desc,
        }),
      );
    }
  };

  const dispatchIncomeDetails = (sortBy?: string, desc?: boolean) => {
    dispatch(
      getIncomeDetails({
        startDate: startDate ? formatDate(startDate) : '',
        endDate: endDate ? formatDate(endDate) : '',
        pharmacyId,
        page: 1,
        sortBy,
        desc,
      }),
    );
  };

  const goToIncomeReport = () => {
    setPage(1);
    dispatchIncomeReport();
    setIsDetailedIncome(false);
    setPharmacyId('');
    resetSorting();
  };

  const goToIncomeDetails = () => {
    setPage(1);
    setShowFilter(false);
    setIsDetailedIncome(true);
    resetSorting();
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    const commonValues = {
      startDate: startDate ? formatDate(startDate) : '',
      endDate: endDate ? formatDate(endDate) : '',
      page: selectedPage + 1,
    };

    setPage(selectedPage + 1);

    if (isDetailedIncome) {
      dispatch(
        getIncomeDetails({
          ...commonValues,
          pharmacyId,
        }),
      );
    } else {
      dispatch(
        getIncomeReports({
          ...commonValues,
          pharmacyIds,
          page: selectedPage + 1,
        }),
      );
    }
  };

  const reportConfig = () => {
    if (isDetailedIncome) {
      return {
        headers: incomeDetailsHeaders(),
        rows: incomeDetailsMapper(detailedReportsData, timeFormat),
        totalCount: detailsTotalCount,
      };
    }

    return {
      headers: incomeReportHeaders(),
      rows: incomeReportMapper(data, getLogo, setPharmacyId),
      totalCount,
    };
  };

  const { headers, rows } = reportConfig() ?? {};

  const handleClear = () => {
    formik.resetForm();
    setPage(1);
    setIsFiltered(false);
    dispatch(getReportsFilterInfo(ReportRequestTypes.booked));
    dispatchIncomeReport(undefined, undefined, true);
  };

  const table = {
    title: strings.incomeReport,
    headers,
    headerBar: [
      {
        Component: (
          <CustomButton
            variant="outlined"
            label={strings.clear}
            onClick={handleClear}
          />
        ),
        key: '1',
        name: 'clear',
        display: !isFiltered,
      },
    ],
    rows,
  };

  useEffect(() => {
    dispatch(getReportsFilterInfo(ReportRequestTypes.booked));
    setPageTitle(strings.formatString(`${strings.incomeReport}`) as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pharmacyId) {
      dispatchIncomeDetails();
      goToIncomeDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  useEffect(() => {
    formik.setFieldValue('startDate', startInitial);
    formik.setFieldValue('endDate', endInitial);

    setIsFiltered(true);

    dispatch(
      getIncomeReports({
        startDate: formatDate(startInitial),
        endDate: formatDate(endInitial),
        pharmacyIds: [],
        page: 1,
      }),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      if (pharmacyId && isDetailedIncome) {
        dispatchIncomeDetails(orderBy, isDesc);
      } else {
        dispatchIncomeReport(orderBy, isDesc);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order, isDetailedIncome, pharmacyId]);

  return {
    table,
    handlePagination,
    formik,
    handleClear,
    goToIncomeReport,
    page,
    showFilter,
    isDetailedIncome,
    reportConfig,
    isDesc,
    orderBy,
    handleSorting,
    order,
  };
};

export default useIncomeReport;
