import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  ({
    calendarMapIcons: {
      maxHeight: '24px',
      maxWidth: '32px',
    },

    calendarMapToggle: {
      backgroundColor: theme.palette.common.lightBackgroundColor,
      border: `1px solid ${theme.palette.common.lightBackgroundColor}`,
      maxHeight: '30px',
      borderRadius: '5px',
      marginRight: '9px',
      marginLeft: '9px',
      marginTop: '5px',
      cursor: 'pointer',
    },
    calendarMapToggleIconCont: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',

      background: 'none',
      color: 'inherit',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
    },

    // active state for icons
    calendarMapIconContActive: {
      backgroundColor: theme.palette.secondary.main,
    },

    calendarMapIconsActive: {
      '& path': {
        '&:first-child': {
          fill: theme.palette.primary.main,
        },
        '&:nth-child(2)': {
          fill: theme.palette.secondary.main,
        },
      },
    },
  }));

export default useStyles;
