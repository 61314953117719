import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FormikProps } from 'formik';

import strings from '../../../../localization';
import DateTimeRange from '../../../common/DateTimeRange';

import classes from './styles';

const UpdateDateTimeRange: FC<{formik: FormikProps<any>}> = ({ formik }) => {
  const handleNegativeDates = () => {
    formik.setFieldValue('endTime', null);
  };

  return (
    <>
      <Typography sx={classes.titleTime}>
        {strings.time.toUpperCase()}
      </Typography>

      <Box component="div" sx={classes.timeContainer}>
        <DateTimeRange
          customStartHandler={handleNegativeDates}
          formik={formik}
          startName="startTime"
          endName="endTime"
          horizontal={false}
          removeMargin
          removeLabel
        />
      </Box>
    </>
  );
};
export default UpdateDateTimeRange;
