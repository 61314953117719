import { SxProps, Theme } from '@mui/system';

const classes = {
  reminderCheckbox: {
    color: '#4A5056',
    fontSize: 11,
    lineHeight: '15.385px',
  },

  reminderCheckboxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  labelUserLSEContainer: {
    marginLeft: '1px',
  },

  labelUserLSE: {
    fontSize: 14,
    fontWeight: 500,
    color: 'primary.inputLabel',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
