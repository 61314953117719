import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import useGetItems from '../../../hooks/useGetItems';
import strings from '../../../localization';

import styles from './styles';

interface IPharmacyImageLists {
  items: Array<{
    id: string;
    logoId: string;
    email: string;
    name: string;
  }>;
}

const PharmacyImageLists: FC<IPharmacyImageLists> = ({ items }) => {
  const { getImage } = useGetItems();
  return (
    <div>
      {items.map((item) => {
        const { id, logoId, email, name } = item;
        const image = getImage(logoId);
        return (
          <Box key={id} sx={styles.container}>
            <img src={image} style={styles.image} alt={strings.banners} />
            <Typography sx={styles.label}>
              {name}
              {' '}
              (
              {email}
              )
            </Typography>
          </Box>
        );
      })}
    </div>
  );
};

export default PharmacyImageLists;
