import React, { FC, useCallback } from 'react';

import { Box, IconButton, Tooltip } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import {
  getIsWatchlisted,
  setIsWatchlisted,
  WatchlistedVariants,
} from '@pharmaplan/common';

import strings from '../../../../localization';

import styles from './styles';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';

interface IWatchlisted {
  inCalendar?: boolean;
  variant?: WatchlistedVariants;
}

const Watchlisted: FC<IWatchlisted> = ({ variant, inCalendar = false }) => {
  const dispatch = useAppDispatch();

  const isWatchlisted = useAppSelector(getIsWatchlisted);

  const isInteractive = variant === WatchlistedVariants.interactive;
  const tooltipTitle = inCalendar ? null : strings.watchlist;

  const WatchlistIcon = isWatchlisted ? (
    <BookmarkIcon sx={styles.icon} />
  ) : (
    <BookmarkBorderIcon sx={styles.icon} />
  );

  const handleOnClick = () => {
    dispatch(setIsWatchlisted(!isWatchlisted));
  };

  const renderWatchlistIcon = useCallback(() => {
    if (isInteractive) {
      return (
        <IconButton sx={styles.iconContainer} onClick={handleOnClick}>
          {WatchlistIcon}
        </IconButton>
      );
    }

    return <BookmarkIcon sx={inCalendar ? styles.calendarIcon : styles.icon} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatchlisted]);

  return (
    <Box id="watchlist" sx={styles.container} component="div">
      <Tooltip title={tooltipTitle}>{renderWatchlistIcon()}</Tooltip>
    </Box>
  );
};

export default Watchlisted;
