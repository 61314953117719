import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { getLanguages, TypeOfUser } from '@pharmaplan/common';
import { FormikProps, FormikValues } from 'formik';
import { radioButtonMapper } from '@pharmaplan/common/helpers/utils';
import { InputTypes } from '../../../helpers/Constants';
import strings from '../../../localization';
import { useAppSelector } from '../../../hooks/useAppSelector';
import CustomButton from '../../common/CustomButton/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import CustomDropdown from '../../common/CustomDropdown/CustomDropdown';
import classes from './styles';
import CustomRadioButton from '../../common/CustomRadioButton';

interface ISignupForm {
  formik: FormikProps<FormikValues>;
}

const SignupForm = ({ formik }: ISignupForm) => {
  const { values, handleSubmit, errors, touched } = formik ?? {};
  const { language: errorsLanguage } = errors ?? {};
  const { language: touchedLanguage } = touched ?? {};
  const { isMediaLogin } = values ?? {};

  const languagesInfo = useAppSelector(getLanguages);

  const { button } = classes;

  const { email, password } = InputTypes;
  const { pharmacist, pharmacy } = TypeOfUser;
  const { formatString } = strings;

  const fieldMapper = [
    {
      key: 1,
      name: 'email',
      placeholder: formatString(strings.enterCred, strings.email),
      id: 'email',
      disabled: false,
      showStrentghBar: false,
      label: strings.email,
      type: email,
      hint: null,
    },
    {
      key: 2,
      name: 'password',
      placeholder: formatString(strings.enterCred, strings.password),
      id: 'password',
      disabled: false,
      showStrentghBar: true,
      label: strings.password,
      type: password,
      hint: strings.minCharacters,
    },
    {
      key: 3,
      name: 'confirmPassword',
      placholder: formatString(strings.enterCred, strings.confirmPassword),
      id: 'confirmPassword',
      disabled: false,
      showStrentghBar: false,
      label: strings.confirmPassword,
      type: password,
      hint: null,
    },
  ];

  const socialFields = [
    {
      key: 1,
      name: 'email',
      disabled: true,
      placeholder: formatString(strings.enterCred, strings.email),
      id: 'email',
      showStrentghBar: false,
      label: strings.email,
      type: email,
      hint: null,
    },
  ];

  const fields = isMediaLogin ? socialFields : fieldMapper;

  const roles = [
    { key: pharmacist, label: strings.pharmacist },
    { key: pharmacy, label: strings.pharmacyOwner },
  ];

  return (
    <Box component="form">
      {fields.map((item) => {
        const {
          key,
          name,
          disabled,
          placeholder,
          id,
          label,
          hint,
          type,
          showStrentghBar,
        } = item ?? {};

        return (
          <CustomTextField
            key={key}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            id={id}
            formik={formik}
            label={label}
            type={type}
            showStrentghBar={showStrentghBar}
            hint={hint}
          />
        );
      })}

      <CustomDropdown
        showPlaceholder
        label={strings.role}
        placeholder={strings.selectRole}
        name="role"
        formik={formik}
        menuItems={roles}
      />

      <Box component="div" sx={classes.radioButtonContainer}>
        <CustomRadioButton
          name="language"
          formik={formik}
          radioLabel={strings.preferredLanguage}
          radioItems={radioButtonMapper(languagesInfo, 'languageId', 'name')}
          row
        />
        {errorsLanguage && touchedLanguage && (
          <Typography style={classes.error}>
            {errorsLanguage as string}
          </Typography>
        )}
      </Box>

      <CustomButton
        customClass={button}
        label={strings.signup}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default memo(SignupForm);
