import { debounce } from '@mui/material';
import { HelpTypes, searchUsers } from '@pharmaplan/common';
import { useCallback } from 'react';
import { useAppDispatch } from './useAppDispatch';

interface IUseAutoComplete {
  userTypes: Array<HelpTypes>;
}

const useAutoComplete = ({ userTypes }: IUseAutoComplete) => {
  const dispatch = useAppDispatch();

  const getInput = (input: string) => {
    if (input) {
      dispatch(
        searchUsers({
          userTypes,
          query: input,
        }),
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedInput = useCallback(debounce(getInput, 300), []);

  return { debouncedInput };
};

export default useAutoComplete;
