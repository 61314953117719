import { Box } from '@mui/system';
import { getPasswordStrength } from '@pharmaplan/common/helpers/utils';
import React from 'react';
import classes from './style';

interface ICustomStrengthBar {
  password: string;
}

const CustomStrengthBar = ({ password }: ICustomStrengthBar) => {
  const { level, bgColor, rates } = getPasswordStrength(password);

  return (
    <Box
      component="div"
      sx={password.length > 0 ? classes.container : classes.hideStrengthBar}
    >
      {/* we should change inline style */}
      {Object.keys(rates).map((rate) =>
        (
          <Box
            key={rate}
            component="span"
            sx={[
              classes.item,
              {
                backgroundColor:
                parseInt(rate, 10) <= level ? bgColor : '#CFD8DC',
              },
            ]}
          />
        ))}
    </Box>
  );
};

export default CustomStrengthBar;
