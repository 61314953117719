import { AnyAction, createAction } from '@reduxjs/toolkit';

export const statusActions = {
  reset: 'reset/status',
  resetReport: 'reset/report',
};

export const resetStatus = createAction<Array<string> | null | undefined>(
  statusActions.reset
);
export const resetReportStatus = createAction(statusActions.resetReport);
