import React, { FC, useEffect } from 'react';

import { Grid, SxProps, Theme, Typography } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import { successSelector } from '@pharmaplan/common';
import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useTransferList, { IListState } from '../../../hooks/useTransferList';

import SingleTransferList from './SingleTransferList';
import CustomButton from '../CustomButton';
import styles from './styles';

export interface ITransferListItem {
  key: string;
  label: string;
  email: string;
  preventRemove?: boolean;
}

export enum TransferListDir {
  left = 'left',
  right = 'right',
}

interface ITransferList {
  list: IListState;
  setList: React.Dispatch<React.SetStateAction<IListState>>;
  left?: Array<ITransferListItem>;
  right?: Array<ITransferListItem>;
  leftRef: React.MutableRefObject<null>;
  rightRef: React.MutableRefObject<null>;
  successAction: string;
  leftOrnament?: React.ReactNode;
  leftListStyle?: SxProps<any>;
  rightListStyle?: SxProps<Theme>;
  leftDesc: string;
  leftTitle: string;
  rightDesc: string;
  rightTitle: string;
  allowSingle?: boolean;
}

export enum FeedbackType {
  positive = 1,
  negative = 0,
}

const initFeedback = { type: null, message: '' };

const TransferList: FC<ITransferList> = ({
  list,
  setList,
  leftRef,
  rightRef,
  successAction,
  leftOrnament,
  leftListStyle,
  rightListStyle,
  leftDesc,
  leftTitle,
  rightDesc,
  allowSingle = true,
  rightTitle,
}) => {
  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const {
    handleAdd,
    handleRemove,
    selectItem,
    selectedItems,
    setFeedback,
    highlightedItems,
    feedback,
    handleAddWithRestriction,
  } = useTransferList(setList);

  useEffect(() => {
    if (success) {
      setFeedback(initFeedback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const disabled = selectedItems.length === 0;

  return (
    <Grid container xs={11} direction="row" sx={styles.container}>
      <Grid item xs={4}>
        <SingleTransferList
          startOrnament={leftOrnament}
          removedItems={highlightedItems.left}
          onItemClick={selectItem}
          listStyle={leftListStyle}
          selectedItems={selectedItems}
          title={leftTitle}
          description={leftDesc}
          items={[...highlightedItems.left, ...list.left]}
          inRef={leftRef}
        />
      </Grid>
      <Grid
        item
        sx={genericClasses.center}
        xs={4}
        flexDirection="column"
        gap={2}
      >
        <CustomButton
          endIcon={<DoubleArrowIcon />}
          customButtonStyle={styles.addButton}
          disabled={disabled}
          label={strings.add}
          onClick={allowSingle ? handleAddWithRestriction : handleAdd}
        />
        <Typography
          sx={[
            styles.addContainer,
            feedback.type === FeedbackType.negative
              ? styles.errorFeedback
              : styles.positiveFeedback,
          ]}
        >
          {feedback.message}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <SingleTransferList
          listStyle={rightListStyle}
          selectedItems={highlightedItems.right}
          title={rightTitle}
          description={rightDesc}
          items={list.right}
          handleRemove={handleRemove}
          inRef={rightRef}
        />
      </Grid>
    </Grid>
  );
};

export default TransferList;
