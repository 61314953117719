import React, { useEffect } from 'react';
import { FormikValues } from 'formik';
import {
  IAdminUpdateContactPreferenceApiParams,
  adminActions,
  adminContactPreference,
  adminGetContactPreference,
  adminGetContactPreferencesList,
  adminResetValue,
  adminUpdateContactPreference,
  resetStatus,
  successOrSelector,
  successSelector,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../useAppDispatch';
import { setDialog } from '../../../reducers/dialogReducer';
import InputModal from '../../../components/Modals/InputModal';
import { addNewContactPreferencesForm } from '../../../components/Admin/ContactPreferences/helper';
import Validator from '../../../components/Admin/ContactPreferences/validator';
import { useAppSelector } from '../../useAppSelector';
import strings from '../../../localization';
import useTableSort from '../../useTableSort';

type IInitialValues = Record<string, string | boolean>;

const {
  getContactPreference,
  createContactPreference: createAction,
  updateContactPreference: updateAction,
  deleteContactPreference,
} = adminActions;

const successAction = getContactPreference;
const addSuccess = createAction;
const updateSuccess = updateAction;
const deleteSuccess = deleteContactPreference;

const useContactPreferences = () => {
  const dispatch = useAppDispatch();

  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const contactPreference = useAppSelector(adminContactPreference);

  const addUpdateDeleteSuccess = useAppSelector((state) =>
    successOrSelector([updateSuccess, addSuccess, deleteSuccess], state));

  const { name, published, contactPreferenceId } = contactPreference ?? {};

  const { isDesc, orderBy, handleSorting, order } = useTableSort();

  const editInitialValues = {
    name,
    published,
    contactPreferenceId,
  };

  const openForm = (
    initialValues: IInitialValues,
    onSubmit: (values: FormikValues) => void,
    label: string,
    heading: string,
  ) => {
    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={onSubmit}
            initialValues={initialValues}
            submitLabel={label}
            config={addNewContactPreferencesForm()}
            validator={Validator()}
          />
        ),
        heading: {
          title: heading,
        },
        showCloseButton: true,
      }),
    );
  };

  const editHeading = `${strings.edit} ${strings.contactPreference}`;

  const updateContactPreference = (values: FormikValues) => {
    dispatch(adminUpdateContactPreference(values as IAdminUpdateContactPreferenceApiParams));
  };

  const showEdit = () => {
    openForm(
      editInitialValues,
      updateContactPreference,
      strings.update,
      editHeading,
    );
  };

  const handleUpdate = (prefId: string) => {
    dispatch(adminGetContactPreference(prefId));
  };

  useEffect(() => {
    if (success && editInitialValues.contactPreferenceId) {
      showEdit();
      dispatch(resetStatus([successAction]));
      dispatch(adminResetValue(['contactPreference']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, editInitialValues.contactPreferenceId]);

  useEffect(() => {
    if (addUpdateDeleteSuccess) {
      dispatch(adminGetContactPreferencesList({ paging: {} }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateDeleteSuccess]);

  useEffect(() => {
    dispatch(adminGetContactPreferencesList({ paging: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderBy) {
      dispatch(adminGetContactPreferencesList({ paging: {
        sortBy: orderBy,
        desc: isDesc,
      } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  return { openForm, showEdit, handleUpdate, order, orderBy, isDesc, handleSorting };
};

export default useContactPreferences;
