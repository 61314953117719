import { SxProps, Theme } from '@mui/system';

const size = 28;

const styles = {
  senderContainer: {
    display: 'inline-flex',
    minWidth: '40px',
    position: 'relative',
  },
  avatar: {
    height: size,
    width: size,
    border: '1px solid',
    borderColor: 'common.border',
    bottom: '2px',
    left: '0',
    position: 'absolute',
    objectFit: 'contain',
  },
  img: {
    objectFit: 'contain',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
