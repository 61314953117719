import { SxProps, Theme } from '@mui/material';

const styles = {
  container: {
    paddingLeft: '23px',
    paddingRight: '23px',
  },
  notes: {
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
