import React, { FC } from 'react';

import PriceLabel from '../PriceLabel/PriceLabel';
import { IConfigData, ICounterOfferConfig } from '../types';

interface IBillContent {
  counterOfferCriteria?: ICounterOfferConfig;
  rateAndTime: Array<IConfigData>;
  billDetails: Array<IConfigData>;
  isCounterOffer?: boolean;
  successAction: string;
}

const BillContent: FC<IBillContent> = ({
  counterOfferCriteria,
  rateAndTime,
  billDetails,
  isCounterOffer,
  successAction,
}) => {
  const contentMapper = [
    ...(isCounterOffer ? rateAndTime : []),
    ...billDetails,
  ];

  return (
    <>
      {contentMapper.map((item) => {
        const { key, name, value = 0, counterOfferValue = 0, customStyle = {} } = item ?? {};
        const { checkCriteria, checker } = counterOfferCriteria ?? {};

        const price = checker?.(value, counterOfferValue) && value;
        const counterPrice = isCounterOffer && counterOfferValue;
        const validateCriteria = !!item[checkCriteria as keyof typeof item];

        return (
          validateCriteria && (
            <PriceLabel
              successAction={successAction}
              key={key}
              title={name}
              price={price}
              counterPrice={counterPrice}
              customStyle={customStyle}
            />
          )
        );
      })}
    </>
  );
};

export default BillContent;
