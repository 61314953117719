import { SxProps, Theme } from '@mui/system';

const styles = {
  error: {
    color: 'common.error',
    fontSize: 12,
    marginTop: '3px',
  },

  horizontalValue: {
    flex: 4,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
