const classes = {
  container: {
    display: 'flex',
  },
  item: {
    flex: 1,
    margin: '2px',
    marginBottom: '10px',
    marginTop: '5px',
    height: '5px',
    borderRadius: '2px',
  },
  hideStrengthBar: {
    display: 'none',
  },
};

export default classes;
