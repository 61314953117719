import { useEffect } from 'react';

import {
  adminBroadcastGroupUsers,
  adminResetValue,
  getBroadcastFilter,
  getPublishedSoftwareList,
  HelpTypes,
  IAdminBroadcastUserGroupApiData,
  MiscType,
  publishedSoftwareList,
  resetStatus,
} from '@pharmaplan/common';
import {
  createBroadcastGroupHeaders,
  createBroadcastGroupRows,
} from '../../../components/Admin/BroadcastList/CreateEditBroadcastGroup/helper';

import { IDynamicTableObject } from '../../../components/DynamicTable/types';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import useGetItems from '../../useGetItems';
import useUserProfileLink from '../useUserProfileLink';

interface IUseBroadcastGroupCreateTable {
  handlePress: (id: string) => () => void;
  selectedIds: Array<string>;
  userType: HelpTypes | MiscType | string;
}
const usersKey = 'broadcastGroupUsers';

const useBroadcastGroupCreateTable = ({
  handlePress,
  selectedIds,
  userType,
}: IUseBroadcastGroupCreateTable) => {
  const dispatch = useAppDispatch();

  const publishedSoftwares = useAppSelector(publishedSoftwareList);
  const { goToUserProfile } = useUserProfileLink();
  const { getImage } = useGetItems();

  const groupUsers = useAppSelector(adminBroadcastGroupUsers);
  const { data } = groupUsers ?? {};

  const table: IDynamicTableObject = {
    headerBar: [],
    headers: createBroadcastGroupHeaders(userType),
    rows: createBroadcastGroupRows({
      data: data as IAdminBroadcastUserGroupApiData[],
      getImage,
      handlePress,
      selectedItems: selectedIds,
      userType,
      goToUserProfile,
      publishedSoftwares,
    }),
  };

  useEffect(() => {
    dispatch(getBroadcastFilter());
    dispatch(getPublishedSoftwareList());
    return () => {
      dispatch(resetStatus());
      dispatch(adminResetValue([usersKey]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { table };
};

export default useBroadcastGroupCreateTable;
