import React, { FC } from 'react';

import { Box } from '@mui/material';

import CustomAvatarSection from '../../../common/CustomAvatarSection';
import { IMenuActions } from '../../../common/CustomAvatarSection/CustomAvatarSection';

import classes from './style';

interface IProfileHeader {
  successAction: string;
  config: Array<{ key: string; value: string | React.ReactNode }>;
  avatar: string | null;
  title: string;
  menuActions: Array<IMenuActions> | null;
  customFooter: React.ReactNode;
  showMenu?: boolean;
}

const ProfileHeader: FC<IProfileHeader> = ({
  successAction,
  config,
  avatar,
  title,
  showMenu = true,
  menuActions,
  customFooter,
}) =>
  (
    <Box sx={classes.container}>
      <CustomAvatarSection
        customContainerStyle={classes.avatarSection}
        successAction={successAction}
        configStyle={classes.configStyle}
        avatar={avatar}
        config={config}
        title={title}
        customTitleStyle={classes.title}
        avatarSize={75}
        customMenuContainerStyle={classes.menuContainer}
        customDetailContainerStyle={classes.detailContainer}
        menuActions={showMenu ? menuActions : null}
        customFooter={customFooter}
      />
    </Box>
  );
export default ProfileHeader;
