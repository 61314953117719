import React, { FC } from 'react';

import { Box, Button, SxProps, Theme, Typography } from '@mui/material';

import classes from './style';

export interface IPendingTabConfig {
  key: string | number;
  tabName: string;
  onClick: (tab: any) => void;
}

interface IPendingTabs {
  header?: string;
  tabConfig: Array<IPendingTabConfig>;
  selectedTab: string | number;
  EndAdornment?: React.ReactNode | null;
  rootStyle?: SxProps<Theme>;
  buttonStyle?: SxProps<Theme>;
  showHeader?: boolean;
  showDivider?: boolean;
}

const PendingTabs: FC<IPendingTabs> = ({
  header,
  tabConfig,
  selectedTab,
  EndAdornment,
  rootStyle,
  buttonStyle,
  showHeader = true,
  showDivider,
}) =>
  (
    <>
      {showHeader && (
      <Box sx={classes.headerContainer}>
        <Typography sx={classes.headerFont}>{header}</Typography>
        {EndAdornment}
      </Box>
      )}
      <Box sx={[classes.root, rootStyle] as SxProps<Theme>}>
        {tabConfig.map((item, index) => {
          const { key, tabName, onClick } = item ?? {};
          const isSelected = key === selectedTab;
          const notLast = index !== tabConfig.length;

          return (
            <>
              <Button
                key={key}
                onClick={() =>
                  onClick(key)}
                sx={
                [
                  classes.navButton,
                  isSelected ? classes.activeButton : classes.inActive,
                  buttonStyle,
                ] as SxProps<Theme>
              }
                variant="text"
              >
                {tabName}
              </Button>
              {showDivider && notLast && <Box sx={classes.divider} />}
            </>
          );
        })}
      </Box>
    </>
  );

export default PendingTabs;
