import React, { FC, ReactElement } from 'react';

import { Tooltip } from '@mui/material';

import { AvailabilityTypes, ICalendarEventType, PharmacistLegendVariant } from '@pharmaplan/common';

import CalendarTooltipContent from './CalendarTooltipContent';
import { IDurations } from '../../../../types/IDurations';
import { getISO } from '../../../../helpers/Functions';

import styles from './style';

interface ICalendarTooltip {
  children: ReactElement<any, any>;
  eventType: ICalendarEventType;
  durations: Array<IDurations>;
  onClick: (inId: string) => void;
  date?: Date;
}

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, -5],
    },
  },
];

const CalendarTooltip: FC<ICalendarTooltip> = ({
  children,
  eventType,
  durations,
  onClick,
  date,
}) => {
  const parsedDate = getISO(date ?? new Date());

  const isNewVacation = (eventType as unknown) === AvailabilityTypes.newVacation;
  const isVacations = isNewVacation || eventType === PharmacistLegendVariant.vacation;

  return (
    <Tooltip
      arrow
      placement="top-end"
      disableHoverListener={isVacations}
      title={(
        <CalendarTooltipContent
          onClick={onClick}
          durations={durations}
          eventType={eventType}
          date={parsedDate}
        />
      )}
      componentsProps={{
        tooltip: {
          sx: styles.container(eventType),
        },
        arrow: {
          sx: styles.arrow(eventType),
        },
      }}
      slotProps={{
        popper: {
          modifiers,
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CalendarTooltip;
