import React, { FC, ReactNode, memo } from 'react';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Box } from '@mui/material';

import DrawerHeader from './DrawerHeader/DrawerHeader';
import DrawerFooter from './DrawerFooter/DrawerFooter';
import useDrawer from '../../hooks/useDrawer';
import {
  selectDrawerContentPadding,
  selectFooterShow,
} from '../../selectors/drawerSelector';
import { useAppSelector } from '../../hooks/useAppSelector';
import { isIpad } from '../../helpers/Constants';

import classes from './style';

const {
  container,
  header,
  content: contentStyle,
  innerContent,
  footer,
} = classes;

const DrawerContent: FC<{ content: ReactNode }> = ({ content }) => {
  const drawerFooter = useAppSelector(selectFooterShow);
  const drawerContentPadding = useAppSelector(selectDrawerContentPadding);

  return (
    <Box sx={container} display="flex" flexDirection="column">
      <Box sx={header}>
        <DrawerHeader />
      </Box>
      <Box sx={contentStyle} padding={drawerContentPadding}>
        <Box sx={innerContent}>{content}</Box>
      </Box>
      {drawerFooter && (
        <Box sx={footer}>
          <DrawerFooter />
        </Box>
      )}
    </Box>
  );
};

const SwipeableTemporaryDrawer: FC = () => {
  const { isOpen, openDrawer, closeDrawer, content } = useDrawer();

  return (
    <Box component="div">
      <SwipeableDrawer
        PaperProps={{ sx: container }}
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
        ModalProps={{
          slotProps: { backdrop: { invisible: true } },
        }}
        disableBackdropTransition={!isIpad}
        disableDiscovery={isIpad}
      >
        <DrawerContent content={content} />
      </SwipeableDrawer>
    </Box>
  );
};

export default memo(SwipeableTemporaryDrawer);
