import { IAllLanguages, IProvinces } from '@pharmaplan/common';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import strings from '../../../localization';
import { Constants, telephoneInputMask } from '../../../helpers/Constants';

export const pharmacyAdminGeneralProfile = (
  languages: IAllLanguages[],
  imageList: Array<{ id: string; logoId: string; name: string; email: string }>,
  provinces:Array<IProvinces>,
) =>
  ({
    general: [
      {
        title: strings.general.toUpperCase(),
        type: 'multiple',
        hideSave: true,
        form: () =>
          [
            {
              name: 'name',
              label: strings.userName,
              requireLabel: true,
              type: Constants.formInputType.text,
              xs: 6,
            },
            {
              name: 'email',
              label: strings.email,
              requireLabel: true,
              disabled: true,
              type: Constants.formInputType.text,
              xs: 6,
            },
            {
              label: strings.preferredLanguage,
              type: Constants.formInputType.dropdown,
              xs: 6,
              requireLabel: true,
              name: 'languageId',
              id: 'languageId',
              menuItems: dropdownMapper(languages, 'languageId', 'name'),
            },
            {
              name: 'mobile',
              label: strings.mobile,
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              xs: 6,
            },
            {
              name: 'phone',
              label: strings.phone,
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              requireLabel: true,
              xs: 6,
            },
          ],
      },
      {
        title: strings.address.toUpperCase(),
        hideSave: true,
        form: () =>
          [
            {
              name: 'address',
              label: strings.address,
              type: Constants.formInputType.text,
              xs: 6,
            },
            {
              name: 'city',
              label: strings.city,
              type: Constants.formInputType.text,
              xs: 6,
            },
            {
              name: 'province',
              label: strings.province,
              type: Constants.formInputType.dropdown,
              menuItems: dropdownMapper(provinces, 'key', 'name'),
              xs: 6,
            },
            {
              name: 'postalCode',
              label: strings.postalCode,
              type: Constants.formInputType.text,
              xs: 6,
            },
          ],
      },
      {
        title: strings.myPharmacies.toUpperCase(),
        form: () =>
          [
            {
              name: 'pharmacies',
              type: Constants.formInputType.imageNameList,
              imageItems: imageList,
            },
          ],
      },
    ],
  });
