import React, { FC } from 'react';

import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { logos, selectIncomeDetails } from '@pharmaplan/common';

import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ReactComponent as LocationIcon } from '../../../../assets/svg/location.svg';

import classes from './style';
import ThemeConstants from '../../../../theme/ThemeConstants';

interface IIncomeHeaderSummary {
  key: number;
  label: string;
  amount: string;
}

interface IIncomeHeader {
  summary: Array<IIncomeHeaderSummary>;
  showLogo?: boolean;
}

const {
  root,
  logoContainer,
  contentContainer,
  box,
  amountText,
  labelText,
  logoStyle,
  pharmacyNameText,
  cityText,
  infoContainer,
  locationContainer,
} = classes;

const IncomeHeader: FC<IIncomeHeader> = ({ summary, showLogo = false }) => {
  const incomeDetails = useAppSelector(selectIncomeDetails);
  const allLogos = useAppSelector(logos);

  const { logoId, city, pharmacyName } = incomeDetails ?? {};
  const logo = allLogos?.find((log) =>
    log.logoId === logoId)?.image;

  return (
    <Box sx={root}>
      {showLogo && (
        <Box sx={logoContainer}>
          <Avatar
            imgProps={{ style: { objectFit: 'contain' } }}
            src={logo}
            sx={logoStyle}
          />
          <Box sx={infoContainer}>
            <Typography sx={pharmacyNameText}>{pharmacyName}</Typography>
            <Box sx={locationContainer}>
              <LocationIcon fill={ThemeConstants.common.gray} />
              <Typography sx={cityText}>{city}</Typography>
            </Box>
          </Box>
        </Box>
      )}

      {summary.map((item) => {
        const { label, amount, key } = item ?? {};

        return (
          <Box key={key} sx={contentContainer}>
            <Box sx={box}>
              <Typography sx={amountText}>{amount}</Typography>
              <Typography sx={labelText}>{label}</Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default IncomeHeader;
