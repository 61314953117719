import React, { useEffect, useState, useMemo } from 'react';

import { Box } from '@mui/system';

import {
  IPostedShifts,
  getPostedShifts,
  pharmacistActions,
  postedShifts,
  resetPostedShifts,
  resetStatus,
  successSelector,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import usePreRequest from '../../../../hooks/usePreRequest';
import { resetDialog } from '../../../../reducers/dialogReducer';
import DynamicTable from '../../../DynamicTable/DynamicTable';
import strings from '../../../../localization';
import { ReactComponent as CloseBtn } from '../../../../assets/svg/closeBtn.svg';

import WorkshiftTitle from '../WorkshiftTitle';
import { headers, rowsMapper } from './helpers';
import classes from './styles';

interface IInfoProps {
  pharmacyLogo: string;
  id: string;
}

const PharmacyPostedList = ({ pharmacyLogo, id }: IInfoProps) => {
  const dispatch = useAppDispatch();

  const { preRequestBooking } = usePreRequest();

  const [initialShiftsList, setInitialShiftsList] = useState<
  Array<IPostedShifts>
  >([]);

  const postedShiftsList = useAppSelector(postedShifts);
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const style = classes('common.gray');

  const isInitialShiftsListEmpty = initialShiftsList.length === 0;

  const updatedPostedShiftsList: Array<IPostedShifts> = useMemo(() => {
    if (isInitialShiftsListEmpty) {
      return postedShiftsList;
    }

    const updatedList: Array<IPostedShifts> = initialShiftsList.map(
      (innerPostedShiftItem: IPostedShifts) => {
        const postedShiftItemExists = postedShiftsList.some(
          (postedShiftItem) =>
            postedShiftItem?.workShiftId === innerPostedShiftItem?.workShiftId,
        );

        if (postedShiftItemExists) {
          return { ...innerPostedShiftItem, requested: false };
        }

        return { ...innerPostedShiftItem, requested: true };
      },
    );

    return updatedList;
  }, [initialShiftsList, postedShiftsList, isInitialShiftsListEmpty]);

  const isPostShiftRequestDone = useAppSelector((state) =>
    successSelector([pharmacistActions.requestBooking], state));

  const closeDialog = () =>
    dispatch(resetDialog());

  const isBookingRequestButtonDisabled = (workShiftId: string) =>
    updatedPostedShiftsList.some((workShiftItem) => {
      if (workShiftItem?.workShiftId === workShiftId) {
        return workShiftItem?.requested;
      }
      return false;
    });

  const onBookingRequestButtonPressed = (workShiftId: string) =>
    () => {
      preRequestBooking(workShiftId);
    };

  const buttonConfig = (workShiftId: string) => {
    const label = isBookingRequestButtonDisabled(workShiftId) ? strings.requested : strings.requestBooking;
    const buttonStyle = isBookingRequestButtonDisabled(workShiftId) ? style.requestedButton : style.requestButton;

    return {
      variant: 'outlined',
      label,
      style: buttonStyle,
      disabled: isBookingRequestButtonDisabled(workShiftId),
      handlePress: onBookingRequestButtonPressed(workShiftId),
    };
  };

  const Table = {
    title: '',
    headers: headers(),
    rows: rowsMapper(
      updatedPostedShiftsList,
      timeFormat,
      dispatch,
      buttonConfig,
    ),
    headerBar: [],
  };

  useEffect(() => {
    if (isInitialShiftsListEmpty) {
      setInitialShiftsList(postedShiftsList);
    }
  }, [postedShiftsList, isInitialShiftsListEmpty]);

  useEffect(() => {
    dispatch(getPostedShifts(id));

    return () => {
      dispatch(resetPostedShifts());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPostShiftRequestDone) {
      dispatch(getPostedShifts(id));
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostShiftRequestDone]);

  return (
    <Box>
      <WorkshiftTitle
        image={pharmacyLogo}
        title={strings.singlePostedWorkshift}
        onClose={closeDialog}
        CloseBtn={CloseBtn}
        customStyle={style}
      />

      <DynamicTable table={Table} loadSuccess hidePagination />
    </Box>
  );
};

export default PharmacyPostedList;
