import React, { FC, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Box } from '@mui/system';

import {
  BroadcastNotificationType,
  getBroadcastGroupsList,
  getBroadcastNotification,
} from '@pharmaplan/common';
import {
  BroadcastGroupActionType,
  BroadcastNotificationDropdown,
} from '../../../../helpers/Constants';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import strings from '../../../../localization';
import CustomDropdown from '../../../common/CustomDropdown';
import CustomTableBack from '../../../common/CustomTableBack';
import BroadcastFields from './BroadcastFields';

import styles from './styles';
import genericClasses from '../../../../theme/GenericClasses';

interface ICreateEditBroadcastNotification {
  type: BroadcastGroupActionType;
}

const { create, edit } = BroadcastGroupActionType;

const CreateEditBroadcastNotification: FC<ICreateEditBroadcastNotification> = ({
  type,
}) => {
  const dispatch = useAppDispatch();

  const { state } = useLocation();
  const { id, notificationType } = state ?? {};

  const formik = useFormik({
    initialValues: {
      type: notificationType || '',
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  useEffect(() => {
    dispatch(
      getBroadcastGroupsList({ pagingModel: { page: 0, itemPerPage: 0 } }),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getBroadcastNotification(id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const createEditConfig = {
    [edit]: { title: strings.editBroadcastNotification },
    [create]: { title: strings.createNewBroadcastNotification },
  };

  const { title } = createEditConfig[type];
  const isValueSelected = !!formik.values.type;

  return (
    <Box sx={styles.container}>
      <CustomTableBack backLabel={strings.backToBroadcastNotifications} />
      <Typography sx={styles.title}>{title}</Typography>
      <Box sx={styles.mainDropdown}>
        <CustomDropdown
          label={strings.selectANotificationType}
          menuItems={BroadcastNotificationDropdown}
          formik={formik}
          name="type"
        />
      </Box>

      {isValueSelected ? (
        <BroadcastFields
          actionType={type}
          type={formik.values.type as BroadcastNotificationType}
        />
      ) : (
        <Box sx={[styles.emptyContainer, genericClasses.center]}>
          <Typography sx={styles.emptyTitle}>
            {strings.pleaseSelectNotificationType}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CreateEditBroadcastNotification;
