import React from 'react';

import { FormikValues } from 'formik';

import { deleteBroadcastGroup } from '@pharmaplan/common';

import DeactivateDialog from '../../../components/Admin/PharmacistList/DeactivateDialog';
import Validator from '../../../components/Admin/BroadcastList/CreateEditBroadcastGroup/validator';
import { DeactivateModalIcon } from '../../../helpers/Constants';
import { setDialog } from '../../../reducers/dialogReducer';
import { useAppDispatch } from '../../useAppDispatch';
import strings from '../../../localization';

const useBroadcastGroupActions = () => {
  const dispatch = useAppDispatch();

  const deleteGroup = (values: FormikValues) => {
    const { id, reason } = values ?? {};
    dispatch(deleteBroadcastGroup({ groupId: id, reason }));
  };

  const handleDelete = (groupId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              onSubmit={deleteGroup}
              confirmationMessage={strings.areYouSureDeleteEntry}
              id={groupId}
              heading={strings.delete}
              iconType={DeactivateModalIcon.warning}
              validator={Validator()}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: true,
        }),
      );
    };

  return { handleDelete };
};

export default useBroadcastGroupActions;
