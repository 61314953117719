import * as Yup from 'yup';
import strings from '../../localization';

const Validator = () =>
  Yup.object().shape({
    selectedDate: Yup.string().required(strings.requiredField),
    startTime: Yup.string().when('allDay', {
      is: false,
      then: () =>
        Yup.string().required(strings.requiredField),
    }),
    endTime: Yup.string().when('allDay', {
      is: false,
      then: () =>
        Yup.string().required(strings.requiredField),
    }),
  });

export default Validator;
