import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import classes from './style';

interface IAvailabilitiesHeader {
  title: string;
}

const { titleContainer, titleFont } = classes;

const AvailabilitiesHeader: FC<IAvailabilitiesHeader> = ({ title }) =>
  (
    <Box sx={titleContainer}>
      <Typography sx={titleFont}>{title}</Typography>
    </Box>
  );

export default AvailabilitiesHeader;
