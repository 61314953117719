import React from 'react';

import { Box } from '@mui/system';
import { Paper } from '@mui/material';

import { clientAdvertisements, Language, language } from '@pharmaplan/common';

import { useAppSelector } from '../../../hooks/useAppSelector';
import EnglishAd from '../../../assets/svg/englishAd.svg';
import FrenchAd from '../../../assets/svg/frenchAd.svg';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import AdsCarousel from './AdsCarousel';
import classes from './style';

const { en, fr } = Language;

const AdsContainer = () => {
  const { height } = useWindowDimensions();

  const userLanguage = useAppSelector(language);
  const ads = useAppSelector(clientAdvertisements);

  const { adsContainer, adsContainerImg, dynamicHeight } = classes;

  const adConfig = {
    [en]: {
      src: EnglishAd,
    },
    [fr]: {
      src: FrenchAd,
    },
  };

  const { src } = adConfig[userLanguage as keyof typeof adConfig];

  return (
    <Box sx={[adsContainer]} component={Paper}>
      {ads?.length ? (
        <AdsCarousel ads={ads} height={dynamicHeight(height ?? 1).height} />
      ) : (
        <Box
          component="img"
          sx={[adsContainerImg]}
          src={src}
          alt="PharmaPlan ad"
        />
      )}
    </Box>
  );
};

export default AdsContainer;
