import { SxProps, Theme } from '@mui/system';

const classes = {
  rightNavbarContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  navbarContainer: {
    boxShadow: 'none',
  },
  avatarContain: {
    objectFit: 'contain',
  },
  avatarCover: {
    objectFit: 'cover',
  },
  mainSubContainer: {
    paddingLeft: '24px !important',
    paddingRight: '24px !important',
  },

  guestNav: {
    maxHeight: 65,
  },
  navbarToolbar: {
    width: '100%',
    maxHeight: 65,
    minHeight: '30px !important',
    display: 'flex',
    justifyContent: 'space-between',
  },

  navbarLangTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: 'common.grey850',
    ml: 1,
  },

  telephoneText: {
    fontSize: 15,
    fontWeight: 500,
  },

  navbarMenuContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  navbarUserTitle: {
    fontSize: '16px',
    fontWeight: 400,
    marginRight: '10px',
  },

  navbarAvatar: {
    border: '2px solid black',
    borderColor: 'primary.main',
  },

  container: {
    display: 'flex',
  },

  welcomeText: {
    fontSize: 16,
    color: 'grey.800',
  },

  languagePadding: {
    paddingRight: 4,
  },

  logo: {
    objectFit: 'contain',
    height: 150,
    width: 188,
  },
  navLogo: {
    textDecoration: 'none',
  },

  customBadge: {
    '& .MuiBadge-badge': {
      right: 5,
      top: 6,
      fontSize: '6.5px',
      height: 12,
      minWidth: 12,
      backgroundColor: '#EB4869',
      fontWeight: 500,
      color: 'white',
      padding: '2px',
      border: '2px solid white',
    },
  },

  notificationWidth: {
    width: '450px',
  },

  switchPharmacyText: {
    color: 'common.grey850',
    fontSize: 14,
    fontWeight: '400',
  },

  switchPharmacy: { my: 2 },

  divider: {
    height: '20px',
    width: '1px',
    alignSelf: 'center',
    backgroundColor: 'common.grey850',
    mr: '22px',
    ml: '18px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
