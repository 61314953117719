import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import {
  IAllLanguages,
  IContactPreferences,
  IFilterSoftware,
  IProvinces,
  ServiceTypes,
} from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import {
  Constants,
  telephoneInputMask,
} from '../../../../../helpers/Constants';
import strings from '../../../../../localization';
import dayIcon from '../../../../../assets/svg/newDayIcon.svg';
import nightIcon from '../../../../../assets/svg/newNightIcon.svg';
import classes from './style';

interface IPharmacyEditConfig {
  languages: IAllLanguages[];
  softwares: IFilterSoftware[];
  provinces: IProvinces[];
  contactPreferences: IContactPreferences[];
  preventChangeEmail: boolean;
}

const selfServiceConfigKey = 'pharmaGapConfigKey';
const { mapButton } = Constants.formInputType;

export const pharmacyEditConfig = ({
  languages,
  softwares,
  provinces,
  contactPreferences,
  preventChangeEmail,
}: IPharmacyEditConfig) => {
  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

  const arr = [
    {
      title: strings.general,
      key: 1,
      form: () =>
        [
          {
            label: strings.email,
            type: Constants.formInputType.changeEmail,
            requireLabel: true,
            disabled: preventChangeEmail,
            name: 'email',
          },
          {
            label: strings.ownerCompanyName,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'name',
          },
          {
            label: strings.shortPharmacyName,
            type: Constants.formInputType.text,
            name: 'pharmacyShortName',
            maxLength: 100,
          },
          {
            label: strings.banner,
            type: Constants.formInputType.text,
            disabled: true,
            name: 'banner',
            placeholder: ' ',
          },
          {
            label: strings.bannerNumber,
            type: Constants.formInputType.text,
            customHelperText: strings.representFranchisenumber,
            name: 'number',
          },
          {
            label: strings.software,
            type: Constants.formInputType.dropdown,
            name: 'softwareId',
            requireLabel: true,
            id: 'softwareId',
            menuItems: dropdownMapper(softwares, 'softwareId', 'name'),
          },
          {
            label: strings.contactPreferences,
            type: Constants.formInputType.dropdown,
            multiple: true,
            name: 'selectedContactPreferences',
            id: 'selectedContactPreferences',
            menuItems: dropdownMapper(
              contactPreferences,
              'contactPreferenceId',
              'name',
            ),
          },
          {
            label: strings.preferredLanguage,
            type: Constants.formInputType.dropdown,
            name: 'languageId',
            requireLabel: true,
            id: 'languageId',
            menuItems: dropdownMapper(languages, 'languageId', 'name'),
            dropdownErrorContainer: false,
          },
          {
            label: strings.dontSendReminder,
            type: Constants.formInputType.checkbox,
            name: 'sendReminder',
            id: 'sendReminder',
            labelClass: classes.reminderCheckbox,
            customClass: classes.reminderCheckboxContainer,
          },
          {
            label: strings.pharmaplanID,
            type: Constants.formInputType.text,
            requireLabel: true,
            disabled: true,
            name: 'code',
            placeholder: ' ',
          },
          {
            label: strings.administrator,
            type: Constants.formInputType.text,
            requireLabel: true,
            disabled: true,
            name: 'administrator',
            placeholder: ' ',
          },
        ],
    },
    {
      title: strings.coordinates,
      key: 2,
      form: () =>
        [
          isSelfService && {
            label: strings.mapButtonLabel,
            fieldLabel: strings.pharmacistLocation,
            type: mapButton,
            xs: 6,
            name: 'pharmacistLocation',
            id: 'pharmacistLocation',
            requireLabel: true,
            fullWidth: true,
          },
          {
            label: strings.pharmacyPhone,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            requireLabel: true,
            name: 'phone',
            maxLength: 12,
          },
          {
            label: strings.pharmacyFax,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            name: 'fax',
            maxLength: 12,
          },
          {
            label: strings.emergencyPhone,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            requireLabel: true,
            name: 'emergencyPhone',
          },
          {
            label: strings.address,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'address',
          },
          {
            label: strings.city,
            type: Constants.formInputType.text,
            name: 'city',
            requireLabel: true,
          },
          {
            type: Constants.formInputType.dropdown,
            name: 'province',
            menuItems: dropdownMapper(provinces, 'key', 'name'),
            label: strings.province,
            requireLabel: true,
          },
          {
            label: strings.postalCode,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'postalCode',
            maxLength: 10,
          },
          {
            label: strings.region,
            type: Constants.formInputType.text,
            disabled: true,
            name: 'region',
            placeholder: ' ',
          },
        ],
    },
    {
      title: strings.operationalInfo,
      key: 3,
      form: () =>
        [
          {
            key: 'weekVolume',
            label: strings.weekVolumes,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'weekVolume',
          },
          {
            key: 'weekDayPharmacists',
            label: strings.pharmacistDuringWeek,
            type: Constants.formInputType.text,
            requireLabel: true,
            rightIcon: dayIcon,
            name: 'weekDayPharmacists',
          },
          {
            key: 'weekNightPharmacists',
            label: strings.pharmacistDuringWeek,
            type: Constants.formInputType.text,
            requireLabel: true,
            rightIcon: nightIcon,
            name: 'weekNightPharmacists',
          },
          {
            key: 'weekDayTechs',
            label: strings.techDuringWeek,
            type: Constants.formInputType.text,
            requireLabel: true,
            rightIcon: dayIcon,
            name: 'weekDayTechs',
          },
          {
            key: 'weekNightTechs',
            label: strings.techDuringWeek,
            type: Constants.formInputType.text,
            requireLabel: true,
            rightIcon: nightIcon,
            name: 'weekNightTechs',
          },
          {
            key: 'weekendVolume',
            label: strings.weekEndVolumes,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'weekendVolume',
          },
          {
            key: 'weekendDayPharmacists',
            label: strings.pharmacistOnTheWeekend,
            type: Constants.formInputType.text,
            requireLabel: true,
            rightIcon: dayIcon,
            name: 'weekendDayPharmacists',
          },
          {
            key: 'weekendNightPharmacists',
            label: strings.pharmacistOnTheWeekend,
            type: Constants.formInputType.text,
            requireLabel: true,
            rightIcon: nightIcon,
            name: 'weekendNightPharmacists',
          },
          {
            key: 'weekendDayTechs',
            label: strings.techDuringWeekend,
            type: Constants.formInputType.text,
            requireLabel: true,
            rightIcon: dayIcon,
            name: 'weekendDayTechs',
          },
          {
            key: 'weekendNightTechs',
            label: strings.techDuringWeekend,
            type: Constants.formInputType.text,
            requireLabel: true,
            rightIcon: nightIcon,
            name: 'weekendNightTechs',
          },
        ],
    },
    {
      title: strings.contactDetails,
      key: 4,
      form: () =>
        [
          {
            key: 'primaryContactName',
            label: strings.primaryContactName,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'primaryContactName',
          },
          {
            key: 'primaryContactPhone',
            label: strings.primaryContactPhone,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            requireLabel: true,
            name: 'primaryContactPhone',
          },
          {
            key: 'primaryContactPhoneExt',
            label: strings.contactPhoneExtension,
            type: Constants.formInputType.text,
            name: 'primaryContactPhoneExt',
            maxLength: 6,
          },
          {
            key: 'primaryContactMobile',
            label: strings.primaryContactMobile,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            name: 'primaryContactMobile',
          },
          {
            key: 'primaryContactEmail',
            label: strings.primaryContactEmail,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'primaryContactEmail',
          },
          {
            key: 'communicationEmail',
            label: strings.communicationsEmail,
            type: Constants.formInputType.text,
            requireLabel: false,
            name: 'communicationEmail',
          },
          {
            key: 'secondaryContactName',
            label: strings.secondaryContactName,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'secondaryContactName',
          },
          {
            key: 'secondaryContactPhone',
            label: strings.secondaryContactPhone,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            name: 'secondaryContactPhone',
          },
          {
            key: 'secondaryContactPhoneExt',
            label: strings.contactPhoneExtension,
            type: Constants.formInputType.text,
            name: 'secondaryContactPhoneExt',
            maxLength: 6,
          },
          {
            key: 'secondaryContactMobile',
            label: strings.secondaryContactMobile,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            name: 'secondaryContactMobile',
          },
          {
            key: 'secondaryContactEmail',
            label: strings.secondaryContactEmail,
            type: Constants.formInputType.text,
            name: 'secondaryContactEmail',
          },
          {
            key: 'accountingContactName',
            label: strings.accountingContactName,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'accountingContactName',
          },
          {
            key: 'accountingContactPhone',
            label: strings.accountingContactPhone,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            requireLabel: true,
            name: 'accountingContactPhone',
          },
          {
            key: 'accountingContactPhoneExt',
            label: strings.contactPhoneExtension,
            type: Constants.formInputType.text,
            name: 'accountingContactPhoneExt',
            maxLength: 6,
          },
          {
            key: 'accountingContactMobile',
            label: strings.accountingContactMobile,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            name: 'accountingContactMobile',
          },
          {
            key: 'accountingContactEmail',
            label: strings.accountingContactEmail,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'accountingContactEmail',
          },
        ],
    },
    {
      title: strings.preferences,
      key: 5,
      form: () =>
        [
          {
            key: 'useLSE',
            label: strings.useLabSchedulingExt,
            type: Constants.formInputType.checkbox,
            name: 'useLSE',
            id: 'useLSE',
            labelClass: classes.labelUserLSE,
            customClass: classes.labelUserLSEContainer,
          },
        ],
    },
    {
      title: strings.hourlyRates,
      key: 6,
      form: () =>
        [
          {
            key: 'hourlyRate',
            label: strings.pharmacistHourlyRate,
            type: Constants.formInputType.text,
            requireLabel: true,
            suffix: '$',
            name: 'hourlyRate',
            id: 'hourlyRate',
          },
          {
            key: 'pharmacyHourlyRate',
            label: strings.pharmacyHourlyRate,
            type: Constants.formInputType.text,
            requireLabel: true,
            suffix: '$',
            name: 'pharmacyHourlyRate',
            id: 'pharmacyHourlyRate',
          },
          {
            key: 'hourlyRateTech',
            label: strings.technicianHourlyRate,
            type: Constants.formInputType.text,
            requireLabel: true,
            suffix: '$',
            name: 'hourlyRateTech',
            id: 'hourlyRateTech',
          },
        ],
    },
    {
      title: strings.configurations,
      key: selfServiceConfigKey,
      form: () =>
        [
          {
            key: 'noCancellationPeriod',
            label: strings.noCancellationPeriod,
            type: Constants.formInputType.text,
            requireLabel: true,
            suffix: strings.days,
            name: 'noCancellationPeriod',
            id: 'noCancellationPeriod',
          },
          {
            key: 'pharmaPlanPercentFees',
            label: strings.pharmaPlanFees,
            type: Constants.formInputType.text,
            requireLabel: true,
            suffix: '%',
            name: 'pharmaPlanPercentFees',
            id: 'pharmaPlanPercentFees',
          },
          {
            key: 'cancellationPercentFees',
            label: strings.cancellationFees,
            type: Constants.formInputType.text,
            requireLabel: true,
            suffix: '%',
            name: 'cancellationPercentFees',
            id: 'cancellationPercentFees',
          },
        ],
    },
  ];

  if (!isSelfService) {
    return arr.filter((item) =>
      item.key !== selfServiceConfigKey);
  }

  return arr;
};
