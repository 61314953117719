import { SxProps, Theme } from '@mui/system';

const classes = {
  workshiftContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '12px',
    alignItems: 'center',
  },

  recurrencePadding: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateTimeContainer: {
    marginTop: '20px',
  },

  counterOfferPadding: {
    paddingTop: '12px',
  },

  counterOfferText: {
    color: 'primary.main',
    fontWeight: '600',
    paddingLeft: '8px',
    paddingTop: '12px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
