import React, { FC } from 'react';

import { IconButton, Tooltip } from '@mui/material';

import { ReactComponent as DownloadPDFIcon } from '../../../assets/svg/downloadPDFIcon.svg';
import strings from '../../../localization';

interface IDownloadPDF {
  onClick: () => void;
  disabled?: boolean;
}

const DownloadPDF: FC<IDownloadPDF> = ({ disabled = false, onClick }) =>
  (
    <Tooltip title={strings.exportAsAPdf}>
      <IconButton disabled={disabled} onClick={onClick}>
        <DownloadPDFIcon />
      </IconButton>
    </Tooltip>
  );

export default DownloadPDF;
