import React, { useEffect } from 'react';
import { FormikValues } from 'formik';

import {
  adminActions,
  adminIgnoreData,
  adminIgnoreRequest,
  adminResetValue,
  getIgnoreDetails,
  resetStatus,
  successSelector,
  AdminLegendVariant,
  PermissionsOfAdmin,
  PermissionsRemovalKeys,
} from '@pharmaplan/common';
import { renderScreen } from '../../actions/drawerActions';
import InputModal from '../../components/Modals/InputModal';
import { OtherScreens, ScreenTypes } from '../../helpers/Constants';
import strings from '../../localization';
import { setDialog } from '../../reducers/dialogReducer';
import { genericFunction } from './useAvailableWorkshiftActions';
import { ignoreForm } from '../../components/Admin/RequestedPharmacistList/helpers';
import styles from '../../components/Admin/RequestedPharmacistList/PharmacistList/styles';
import { getDayMonthDateYear } from '../../components/Reports/helpers';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { serverToday } from '../../helpers/Functions';
import { removeBasedOnPermissions } from '../../helpers/permissionsHelper';
import useAdminPermissions from '../Admin/useAdminPermissions';

export const menuActions = (
  viewDetailsAction: genericFunction,
  ignoreAction: genericFunction,
  approveAction: genericFunction,
  matchingAvailabilities: genericFunction,
  expressBookingAction: genericFunction,
) =>
  [
    {
      key: '1',
      label: strings.viewDetails,
      onClick: viewDetailsAction,
    },
    {
      key: '2',
      label: strings.approve,
      onClick: approveAction,
    },
    {
      key: '3',
      label: strings.ignore,
      onClick: ignoreAction,
    },
    {
      key: '4',
      label: strings.matchingAvailabilities,
      onClick: matchingAvailabilities,
    },
    {
      key: PermissionsRemovalKeys.ExpressBooking,
      label: strings.expressBooking,
      onClick: expressBookingAction,
    },
  ];

const useRequestedPharmacistActions = () => {
  const dispatch = useAppDispatch();
  const ignoreDetails = useAppSelector(adminIgnoreData);
  const today = serverToday().toISO({ includeOffset: false }) ?? '';
  const { can } = useAdminPermissions();

  const getIgnoreDetailSuccess = useAppSelector((state) =>
    successSelector([adminActions.getIgnoreRequestDetails], state));
  const {
    startDate,
    pharmacistName,
    pharmacyName,
    bookingId: id,
  } = ignoreDetails ?? {};

  const ignore = (values: FormikValues) => {
    dispatch(
      adminIgnoreRequest({
        bookingId: id,
        note: values.note,
        sendEmailToPharmacist: values.sendEmailToPharmacist,
      }),
    );
  };

  const initialValues = {
    startDate: getDayMonthDateYear(startDate),
    note: '',
    pharmacyName,
    pharmacistName,
    sendEmailToPharmacist: true,
    loader: ignoreDetails.bookingId,
  };

  const viewDetailsAction = (bookingId: string, workshiftId: string, pharmacyId: string) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 3,
          screenType: ScreenTypes.requestedList,
          eventId: bookingId,
          workshiftId,
          pharmacyId,
        }),
      );
    };

  const goToApproveBooking = (
    bookingId: string,
    workshiftId: string,
    fromScreen: OtherScreens,
    eventIds?: Array<string> | null,
  ) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 4,
          screenType: ScreenTypes.requestedList,
          eventId: bookingId,
          workshiftId,
          eventIds: eventIds ?? [],
          fromScreen,
        }),
      );
    };

  const goToMatchingAvailabilities = (workshiftId: string) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.availableWorkshift,
          screenNumber: 4,
          eventId: workshiftId,
          type: AdminLegendVariant.requested,
        }),
      );
    };

  const goToExpressBooking = (bookingId: string, workshiftId: string) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.availableWorkshift,
          screenNumber: 5,
          eventId: bookingId,
          workshiftId,
          type: AdminLegendVariant.requested,
        }),
      );
    };

  useEffect(() => {
    if (getIgnoreDetailSuccess && initialValues.loader) {
      dispatch(
        setDialog({
          Component: (
            <InputModal
              submitLabel={strings.ignore}
              onSubmit={ignore}
              initialValues={initialValues}
              config={ignoreForm}
            />
          ),
          heading: {
            title: strings.ignoreWorkshift,
          },
          customStyle: {
            customTitleContainer: styles.modalMargin,
          },
          showCloseButton: true,
        }),
      );
      dispatch(adminResetValue(['adminIgnoreData']));
      dispatch(resetStatus([adminActions.getIgnoreRequestDetails]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.loader, getIgnoreDetailSuccess]);

  const ignoreRequestAction = (bookingId: string) =>
    () => {
      dispatch(getIgnoreDetails(bookingId));
    };

  const actions = (bookingId: string, workshiftid: string, date: string, pharmacyId: string) => {
    let menu = menuActions(
      viewDetailsAction(bookingId, workshiftid, pharmacyId),
      ignoreRequestAction(bookingId),
      goToApproveBooking(bookingId, workshiftid, OtherScreens.AdminCalendar),
      goToMatchingAvailabilities(workshiftid),
      goToExpressBooking(bookingId, workshiftid),
    );
    if (date < today) {
      menu = menu.slice(0, 1);
    }

    menu = removeBasedOnPermissions(
      menu,
      [PermissionsOfAdmin.ExpressBooking],
      can,
    );
    return menu;
  };

  return {
    actions,
    ignoreRequestAction,
    goToApproveBooking,
  };
};

export default useRequestedPharmacistActions;
