import React, { FC, memo } from 'react';

import {
  BookingState,
  ICalendarEventType,
  IPharmacistDetails,
  PharmacistLegendVariant,
  PharmacyLegendVariant,
} from '@pharmaplan/common';
import AvatarFallback from '@pharmaplan/common/assets/icons/avatarPlaceholder.png';

import ServiceCard from '../ServiceCard/ServiceCard';
import strings from '../../../localization';
import { ScreenTypes } from '../../../helpers/Constants';

import PharmacistCardFooter from './PharmacistCardFooter/PharmacistCardFooter';
import PharmacistCardContent from './PharmacistCardContent/PharmacistCardContent';

interface IPharmacistCard {
  pharmacistDetails: Partial<IPharmacistDetails>;
  screenType: ScreenTypes;
  workshiftId?: string;
  date?: string;
  eventIds?: Array<string>;
  eventId?: string;
  type?: ICalendarEventType;
  successAction: string;
  imageSuccessAction?: string;
  goBack?: () => void;
}

const { None } = BookingState;
const { requested } = PharmacyLegendVariant;
const { booked } = PharmacistLegendVariant;
const { workshiftSummary, pharmacistProfile, replacement } = ScreenTypes;

const PharmacistCard: FC<IPharmacistCard> = ({
  eventId,
  pharmacistDetails,
  type,
  date,
  eventIds,
  workshiftId,
  screenType,
  successAction,
  imageSuccessAction,
  goBack,
}) => {
  const {
    firstName = '',
    lastName = '',
    language,
    requestedDate,
    pharmacistCapacity,
    postalCode,
    city,
    profilePicture,
    rating,
    pharmacistId = '',
    isFavorite = false,
    bookingState,
    bookingStateExpiration,
    timezone,
    mobile,
  } = pharmacistDetails ?? {};

  const { totalRating } = rating ?? {};

  const isBooked = type === booked;
  const isSummary = screenType === workshiftSummary;
  const isPharmacistProfile = screenType === pharmacistProfile;
  const isReplacement = screenType === replacement;

  const isRequested = type === requested;
  const isBookingStateNone = bookingState === None;

  const calcRating = ((totalRating ?? 0) * 5) / 100;

  const cardContent = {
    eventId,
    firstName,
    lastName,
    avatar: profilePicture ?? AvatarFallback,
    requestedDate: requestedDate ?? '',
    rating: calcRating,
    pharmacistId,
    type,
    isFavorite,
    bookingState,
    bookingStateExpiration,
    isSummary,
    timezone,
    workshiftId,
    date,
    eventIds,
  };

  const { from, to } = pharmacistCapacity ?? {};

  const pharmacistFooterConfig = [
    {
      key: '0',
      primaryTitle: strings.preferredLanguage,
      primaryValue: language?.name,
      secondaryTitle: strings.pharmacistCapacity,
      secondaryValue: `${from} - ${to}`,
    },
    {
      key: '1',
      primaryTitle: strings.town,
      primaryValue: city,
      secondaryTitle: strings.postalCode,
      secondaryValue: postalCode,
    },
    isBooked || isReplacement
      ? {
        key: '2',
        primaryTitle: strings.mobile,
        primaryValue: mobile,
      }
      : {},
  ];

  const controlledType = () => {
    if (isRequested && isBookingStateNone) {
      return null;
    }
    return type;
  };

  return (
    <ServiceCard
      eventType={controlledType() as keyof typeof controlledType}
      content={(
        <PharmacistCardContent
          successAction={successAction}
          cardContent={cardContent}
          screenType={screenType}
          imageSuccessAction={imageSuccessAction}
          goBack={goBack}
        />
      )}
      footer={
        (isPharmacistProfile || isReplacement) && (
          <PharmacistCardFooter
            successAction={successAction}
            footerContent={pharmacistFooterConfig}
          />
        )
      }
    />
  );
};

export default memo(PharmacistCard);
