const classes = {
  mapMarkerContainer: {
    width: '26px',
    height: '37px',
    overflow: 'hidden',
    position: 'absolute',
    left: '50%',
    top: '48%',
    zIndex: 0,
  },

  mapMarker: {
    width: '26px',
    height: '37px',
    border: '0px',
    padding: '0px',
    margin: '0px',
    maxWidth: 'none',
    color: 'red',
  },
};

export default classes;
