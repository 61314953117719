import { createTheme } from '@mui/material/styles';
import { default as components } from './overrides';
import palette from './palette';
import breakpoints from './breakpoints';

const theme = createTheme({
  palette,
  breakpoints,
  components,
  typography: {
    fontFamily: [
      'Poppins',
    ].join(','),
  },
});

export default theme;
