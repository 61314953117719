import { useEffect, useRef } from 'react';

interface IUseInfiniteScroll {
  observerCallback: (entries: { isIntersecting: any;}[]) => void
}

const useInfiniteScroll = ({ observerCallback }: IUseInfiniteScroll) => {
  const listEndRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      threshold: 0.8,
    });

    if (listEndRef.current) {
      observer.observe(listEndRef.current);
    }

    return () => {
      if (listEndRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(listEndRef.current);
      }
    };
  }, [observerCallback]);

  return { listEndRef };
};

export default useInfiniteScroll;
