/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect } from 'react';

import { debounce } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';

import { getActivePharmacistList } from '@pharmaplan/common';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import CustomTextField from '../../../../common/CustomTextField';
import strings from '../../../../../localization';
import styles from '../styles';

interface IIncompatiblePharmacistSearch {
  setPage: React.Dispatch<
    React.SetStateAction<{
      left: number;
      right: number;
    }>
  >;
}

const IncompatiblePharmacistSearch: FC<IIncompatiblePharmacistSearch> = ({ setPage }) => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const { search } = formik.values;
  const searchPharmacists = (input: string) => {
    setPage((prev) =>
      ({
        left: 1,
        right: prev.right,
      }));
    dispatch(getActivePharmacistList({ page: 1, userText: input }));
  };

  const debouncedSearchPharmacists = useCallback(
    debounce(searchPharmacists, 300),
    [],
  );

  useEffect(() => {
    debouncedSearchPharmacists(search);
  }, [search]);

  return (
    <Box sx={styles.searchContainer}>
      <CustomTextField
        name="search"
        customClass={styles.borderRadiusRemove}
        formik={formik}
        id="search"
        errorContainer={false}
        placeholder={strings.search}
      />
    </Box>
  );
};

export default IncompatiblePharmacistSearch;
