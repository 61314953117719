import React, { FC, useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProps } from 'formik';

import { adminBroadcastGroupList, allBroadcastGroups, getAllBroadcastGroups, PermissionsOfAdmin } from '@pharmaplan/common';
import {
  BroadcastNotificationDropdown,
  BroadcastTabsType,
} from '../../../../helpers/Constants';
import strings from '../../../../localization';
import CustomTextField from '../../../common/CustomTextField';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import PendingTabs from '../../PendingTabs';
import useBroadcastTabs from '../../../../hooks/Admin/BroadcastList/useBroadcastTabs';
import CreateNew from '../../../common/CreateNew';
import CustomDropdown from '../../../common/CustomDropdown';
import CustomDateRange from '../../../common/CustomDateRange';
import { serverTodayMinusOne } from '../../../../helpers/Functions';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useAdminPermissions from '../../../../hooks/Admin/useAdminPermissions';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';

import styles from '../styles';

const { groups, notifications } = BroadcastTabsType;
const { BroadcastNotification } = PermissionsOfAdmin;

interface IBroadcastListHeader {
  selectedTabs: BroadcastTabsType;
  handleTabClick: (tab: BroadcastTabsType) => void;
  formik: FormikProps<any>;
  handleReset: () => void;
}

const BroadcastListHeader: FC<IBroadcastListHeader> = ({
  selectedTabs,
  handleTabClick,
  formik,
  handleReset,
}) => {
  const dispatch = useAppDispatch();
  const { createNewConfig } = useBroadcastTabs(selectedTabs);

  const { can } = useAdminPermissions();
  const canUseBroadcast = can(BroadcastNotification);
  const broadcastGroups = useAppSelector(adminBroadcastGroupList);

  const { label, onClick } = createNewConfig;
  const allGroups = useAppSelector(allBroadcastGroups);
  const { data } = allGroups;

  const groupsDropdown = useMemo(
    () =>
      data.map((item) =>
        ({
          key: item.broadcastGroupId,
          label: item.name,
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [broadcastGroups],
  );

  const tabConfig = [
    {
      key: notifications,
      tabName: strings.broadcastNotifications,
      onClick: handleTabClick,
      isDefault: true,
    },
    {
      key: groups,
      tabName: strings.broadcastGroups,
      onClick: handleTabClick,
    },
  ];

  const sizeConfig = {
    [notifications]: { xsSize: 12, lgSize: 10 },
    [groups]: { xsSize: 10, lgSize: 6 },
  };

  const { xsSize, lgSize } = sizeConfig[selectedTabs];
  const isGroups = selectedTabs === groups;

  useEffect(() => {
    if (!isGroups) {
      dispatch(getAllBroadcastGroups());
    }
  }, [isGroups]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PendingTabs
        header={strings.broadcastNotifications}
        selectedTab={selectedTabs}
        tabConfig={tabConfig}
        EndAdornment={canUseBroadcast ? <CreateNew label={label} onClick={onClick} /> : null}
      />
      <Grid container lg={lgSize} alignItems="center" xs={xsSize} wrap="nowrap">
        {isGroups ? (
          <Grid item xs={6}>
            <CustomTextField
              label={strings.groupName}
              name="groupName"
              id="groupName"
              formik={formik}
            />
          </Grid>
        ) : (
          <>
            <Grid xs={6} item>
              <CustomDropdown
                menuItems={BroadcastNotificationDropdown}
                name="type"
                label={strings.notificationType}
                formik={formik}
              />
            </Grid>
            <Grid xs={8} item sx={styles.filterMargin}>
              <CustomDropdown
                formik={formik}
                menuItems={groupsDropdown}
                multiple
                name="groupIds"
                customClass={styles.groupDropdown}
                label={strings.group}
              />
            </Grid>
            <Grid sx={styles.dateMargin} container>
              <CustomDateRange
                startName="startDate"
                endName="endDate"
                startMax={serverTodayMinusOne}
                endMax={serverTodayMinusOne}
                formik={formik}
                preventPast={false}
              />
            </Grid>
          </>
        )}
        <FilterSubmitButtons handleReset={handleReset} />
      </Grid>
    </Box>
  );
};

export default BroadcastListHeader;
