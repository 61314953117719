import {
  IAdminAdvertisement,
  IAdvertisementPlatformData,
  PublishTypes,
} from '@pharmaplan/common';
import strings from '../../../localization';
import {
  DynamicTableCell,
  MomentTimeFormats,
} from '../../../helpers/Constants';
import { getDayMonthDateYear } from '../../Reports/helpers';

import editIcon from '../../../assets/svg/updateNoteIcon.svg';
import deleteIcon from '../../../assets/svg/delete.svg';
import { serverToday } from '../../../helpers/Functions';

const { text, checkbox, bar, imageCell } = DynamicTableCell;

interface IAdvertisingRows {
  data: Array<IAdminAdvertisement>;
  allPlatformList: Array<IAdvertisementPlatformData>;
  handleEdit: (id: string) => () => void;
  handleDelete: (id: string) => () => void;
}

export const advertisingHeaders = () =>
  [
    {
      key: '0',
      label: strings.name,
      sortingKey: 'name',
    },
    {
      key: '1',
      label: strings.campaignImage,
      sortingKey: 'imageName',
    },
    {
      key: '2',
      label: strings.expiryDate,
      sortingKey: 'expiry',
    },
    {
      key: '3',
      label: strings.platform,
    },
    {
      key: '4',
      label: strings.published,
      sortingKey: 'isPublished',
    },
    {
      key: '5',
      label: strings.action,
    },
  ];

export const advertisingRows = ({
  data,
  allPlatformList,
  handleDelete,
  handleEdit,
}: IAdvertisingRows) =>
  data?.map((item) => {
    const {
      advertisementId,
      name,
      imageName,
      plaformTypes,
      publishType,
      expiry,
      imageUrl,
    } = item ?? {};

    const isPermanentlyPublished = publishType === PublishTypes.PublishPermanently;
    const today = serverToday()
      .toFormat(MomentTimeFormats.fullDate)
      .slice(0, -1);

    let expiryDate = '-';

    if (isPermanentlyPublished) {
      expiryDate = strings.publishedPermanently;
    } else if (expiry) {
      expiryDate = getDayMonthDateYear(expiry ?? '');
    }

    const hasNotExpired = (expiry ?? '') >= (today ?? '');
    const checked = isPermanentlyPublished || hasNotExpired;

    const platformList = plaformTypes?.map(
      (platformType) =>
        allPlatformList.find((platform) =>
          platform.key === platformType)?.value,
    );

    return {
      key: advertisementId,
      data: [
        {
          key: 1,
          value: name,
          type: text,
        },
        {
          key: 2,
          value: { imageUrl, label: imageName },
          type: imageCell,
        },
        {
          key: 3,
          value: expiryDate,
          type: text,
        },
        {
          key: 4,
          value: platformList.join(', '),
          type: text,
        },
        {
          key: 5,
          value: {
            checked,
            disabled: true,
          },
          type: checkbox,
        },
        {
          key: 6,
          value: [
            {
              key: 1,
              icon: editIcon,
              tooltip: strings.edit,
              onClick: handleEdit(advertisementId),
            },
            {
              key: 2,
              icon: deleteIcon,
              tooltip: strings.delete,
              onClick: handleDelete(advertisementId),
            },
          ],
          type: bar,
        },
      ],
    };
  });
