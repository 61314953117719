const classes = {
  closeIcon: {
    color: 'common.grey350',
    backgroundColor: 'common.lightBackgroundColor',
    padding: '3px',
  },

  modalHeading: {
    color: 'grey.500',
    fontSize: '1.25rem',
    fontWeight: '400',
  },

  secondaryButton: {
    color: 'common.grey850',
  },

  modalButton: {
    fontSize: '1rem',
    paddingTop: '1px',
    paddingBottom: '1px',
    minWidth: '80px',
    minHeight: '36px',
    fontWeight: '500',
  },

  transparentPaper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
};

export default classes;
