import React, { memo, useMemo } from 'react';

import { FormikProps } from 'formik';
import { Box, Typography } from '@mui/material';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import {
  appleAuthHelpers,
  AppleAuthResponse,
  AppleSigninErrorResponse,
  useScript,
} from 'react-apple-signin-auth';
import {
  LoginSocialFacebook,
  IResolveParams,
  objectType,
} from 'reactjs-social-login';
import jwtDecode from 'jwt-decode';

import {
  login,
  SocialLoginType,
} from '@pharmaplan/common';
import { uniqueHash } from '@pharmaplan/common/helpers/utils';
import strings from '../../../localization';
import SocialLoginIcons from '../../common/SocialLoginIcons';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { SocialIcons } from '../../../helpers/Constants';

import { getFacebookDetails, getUserDetails } from './SocialController';
import classes from './style';
import { getBrowser, getPlatformOS } from '../../../helpers/Functions';

interface ILoginSocial {
  formik: FormikProps<any>;
  isSignup: boolean;
}

type TokenResponse = Omit<
  CodeResponse,
  'error' | 'error_description' | 'error_uri'
>;

const { google: googleLoginType, facebook: facebookLogin } = SocialLoginType;

const { google, facebook, apple } = SocialIcons;

const LoginSocial = ({ formik, isSignup }: ILoginSocial) => {
  const dispatch = useAppDispatch();

  const { container, continueText, socialContainer } = classes;

  const { setValues } = formik ?? {};
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const mediaLogin = (
    email: string,
    token: string,
    loginType: SocialLoginType,
  ) => {
    if (isSignup) {
      setValues({
        email,
        token,
        isMediaLogin: true,
        loginType,
        role: '',
        device: {
          osType: getPlatformOS() ?? '',
          type: getBrowser(),
          deviceToken: null,
        },
      });
    } else {
      dispatch(
        login({
          email,
          token,
          loginType,
          appId: 'windows',
          device: {
            osType: getPlatformOS() ?? '',
            type: getBrowser(),
            deviceToken: null,
          },
        }),
      );
    }
  };

  const onGoogleLoginSuccess = (tokenResponse: TokenResponse) => {
    const { code } = tokenResponse;

    getUserDetails(code).then((data) => {
      const { email, token, loginType } = data ?? {};

      if (email) {
        mediaLogin(email, token ?? '', loginType ?? googleLoginType);
      }
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    flow: 'auth-code',
  });

  const handleGoogleLogin = () => {
    googleLogin();
  };

  const handleAppleError = (error:AppleSigninErrorResponse) => {
    // HANDLING OF ERROR CAN BE DONE HERE
    console.log(error);
  };

  const onAppleLoginSuccess = (response: AppleAuthResponse) => {
    const { id_token: idToken } = response.authorization ?? {};
    const jwt = jwtDecode<{ email: string }>(idToken);
    console.log(jwt);
    const { email } = jwt ?? {};
    email && mediaLogin(email, idToken, SocialLoginType.apple);
  };

  const appleCsrfHash = useMemo(() =>
    uniqueHash(), []);

  const handleAppleLogin = () => {
    appleAuthHelpers.signIn({
      authOptions: {
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID as string,
        scope: 'email',
        redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URL as string,
        state: appleCsrfHash,
        nonce: 'nonce',
        usePopup: true,
      },
      onSuccess: onAppleLoginSuccess,
      onError: handleAppleError,
    });
  };

  const handleResolve = async ({ provider, data }: IResolveParams) => {
    const { userID, accessToken } = data ?? {};
    const params = await getFacebookDetails(userID, accessToken);
    const { email, token, loginType } = params ?? {};

    if (email) {
      mediaLogin(email, token ?? '', loginType ?? facebookLogin);
    }
  };

  const handleOnReject = (err: string | objectType) => {
    console.log(err);
  };

  return (
    <Box component="div" sx={container}>
      <Typography sx={continueText}>{strings.continueWith}</Typography>
      <Box component="div" sx={socialContainer}>
        <SocialLoginIcons onClick={handleAppleLogin} iconType={apple} />
        <SocialLoginIcons onClick={handleGoogleLogin} iconType={google} />

        <LoginSocialFacebook
          isOnlyGetToken
          appId={process.env.REACT_APP_FACEBOOK_APP_ID ?? ''}
          onResolve={handleResolve}
          onReject={handleOnReject}
        >
          <SocialLoginIcons iconType={facebook} />
        </LoginSocialFacebook>
      </Box>
    </Box>
  );
};

export default memo(LoginSocial);
