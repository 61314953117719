import { SxProps, Theme } from '@mui/material';
import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  counterText: {
    fontSize: 14,
    color: ThemeConstants.grey[500],
  },
  counterValueText: {
    fontWeight: '600',
  },
  title: {
    fontSize: 18,
    color: ThemeConstants.grey[500],
    textTransform: 'uppercase',
  },
  align: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    border: `0.5px solid ${ThemeConstants.grey[500]}`,
    flex: 1,
    ml: 1,
  },
  itemsContainer: {
    marginTop: '10px',
  },
  labelContainer: {
    marginBottom: '20px',
  },
  timeContainer: {
    display: 'flex',
    gap: '30px',
    marginBottom: '16px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
