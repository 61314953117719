const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    color: 'grey.500',
  },
  closeContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  subContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  message: {
    textAlign: 'center',
    fontWeight: '500',
    color: 'grey.800',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    // marginX: '40px',
    marginBottom: '40px',
  },
  button: {
    paddingX: 2,
    fontSize: 14,
    // margin: 1,
  },
  closeIcon: {
    color: 'common.grey350',
    margin: '10px',
    backgroundColor: 'common.lightBackgroundColor',
    padding: '3px',
  },
};

export default classes;
