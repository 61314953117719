import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    padding: '20px 37px 20px 15px',
  },
  title: {
    fontWeight: '600',
    fontSize: 22,
    color: 'common.grey850',
    mb: '24px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
