import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  infoHeading: {
    fontWeight: 700,
    fontSize: '30px',
    textAlign: 'center',
  },

  infoDesc: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
  },

  otpInput: {
    '& .MuiOutlinedInput-root': {
      maxHeight: 60,
      maxWidth: 46,
      backgroundColor: 'common.otpInput',
      borderRadius: '12px',
      '& :focus': {
        border: '1px solid',
        borderColor: 'primary.main',
        maxHeight: '26px',
        maxWidth: 46,
        zIndex: 200,
        borderRadius: '12px',
      },
    },
    marginRight: 1,
  },

  input: {
    fontSize: '30px',
  },

  resendContainer: {
    display: 'flex',
    marginTop: '20px',
  },

  resendCode: {
    color: 'primary.main',
    fontSize: 16,
    textTransform: 'none',
  },

  timer: {
    color: 'grey.800',
    ml: '5px',
  },

  button: {
    display: 'flex',
    marginTop: 10,
  },

  disabledButton: {
    '&:disabled': {
      backgroundColor: 'primary.main',
      color: 'white',
      opacity: 0.6,
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
