import { Theme } from '@mui/material/styles';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiInputLabel from './MuiInputLabel';
import MuiMenuItem from './MuiMenuItem';
import MuiFormLabel from './MuiFormLabel';
import MuiRadio from './MuiRadio';
import MuiSelect from './MuiSelect';
import MuiButton from './MuiButton';
import MuiDialogTitle from './MuiDialogTitle';
import MuiTableCell from './MuiTableCell';
import MuiTablePagination from './MuiTablePagination';
import MuiDialogContent from './MuiDialogContent';
import MuiDialogActions from './MuiDialogActions';
import MuiDrawer from './MuiDrawer';

export default {
  MuiOutlinedInput,
  MuiInputLabel,
  MuiMenuItem,
  MuiFormLabel,
  MuiRadio,
  MuiButton,
  MuiDialogTitle,
  MuiTableCell,
  MuiTablePagination,
  MuiSelect,
  MuiDialogContent,
  MuiDialogActions,
  MuiDrawer,
};

export interface ITheme {
  theme: Theme;
}
