import React, { FC, useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProps } from 'formik';

import {
  adminPharmacistSearchedDetailsFilters,
  getPharmacistDetailsSearchFilters,
  getPublishedSoftwareList,
  publishedSoftwareList,
} from '@pharmaplan/common';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import { PharmacistListTabTypes } from '../../../../helpers/Constants';
import strings from '../../../../localization';
import CustomTextField from '../../../common/CustomTextField';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import PendingTabs from '../../PendingTabs';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import CustomDropdown from '../../../common/CustomDropdown';
import genericClasses from '../../../../theme/GenericClasses';
import styles from './styles';

interface IPharmacistListHeader {
  selectedTab: PharmacistListTabTypes;
  handleTabClick: (tab: PharmacistListTabTypes) => void;
  formik: FormikProps<any>;
  handleReset: () => void;
}

const PharmacistListHeader: FC<IPharmacistListHeader> = ({
  selectedTab,
  handleTabClick,
  formik,
  handleReset,
}) => {
  const dispatch = useAppDispatch();

  const filterData = useAppSelector(adminPharmacistSearchedDetailsFilters);
  const softwares = useAppSelector(publishedSoftwareList);

  const isDetailsSearch = selectedTab === PharmacistListTabTypes.detailsSearch;

  const tabConfig = [
    {
      key: PharmacistListTabTypes.activePharmacists,
      isDefault: true,
      tabName: strings.activePharmacists,
      onClick: handleTabClick,
    },
    {
      key: PharmacistListTabTypes.deactivatedPharmacists,
      tabName: strings.deactivatedPharmacists,
      onClick: handleTabClick,
    },
    {
      key: PharmacistListTabTypes.hiddenPharmacists,
      tabName: strings.hiddenPharmacists,
      onClick: handleTabClick,
    },
    {
      key: PharmacistListTabTypes.deletedPharmacists,
      tabName: strings.userDeletedAccounts,
      onClick: handleTabClick,
    },
    {
      key: PharmacistListTabTypes.detailsSearch,
      tabName: strings.detailsSearch,
      onClick: handleTabClick,
    },
  ];

  useEffect(() => {
    if (isDetailsSearch) {
      dispatch(getPharmacistDetailsSearchFilters());
      dispatch(getPublishedSoftwareList());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const pharmacistCapacitiesItems = useMemo(
    () =>
      filterData.pharmacistCapacities.map((item) => {
        const { from, to, pharmacistCapacityId } = item;
        return {
          label: `${from}-${to}`,
          key: pharmacistCapacityId,
        };
      }),
    [filterData.pharmacistCapacities],
  );

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PendingTabs
        selectedTab={selectedTab}
        tabConfig={tabConfig}
        header={strings.pharmacistsList}
      />
      <Grid container lg={5} alignItems="center" xs={7} wrap="nowrap">
        {isDetailsSearch ? (
          <>
            <Grid item xs={6}>
              <CustomDropdown
                label={strings.software}
                name="softwareIds"
                labelStyles={styles.softwareMenuStyles}
                id="softwareIds"
                formik={formik}
                multiple
                menuItems={dropdownMapper(
                  softwares,
                  'softwareId',
                  'description',
                )}
              />
            </Grid>
            <Grid item xs={6} sx={genericClasses.filterMargin}>
              <CustomDropdown
                label={strings.capacityFrom}
                name="capacityIds"
                customMenuStyles={styles.capacityMenuStyles}
                id="capacityIds"
                multiple
                formik={formik}
                menuItems={pharmacistCapacitiesItems}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={6}>
            <CustomTextField
              label={strings.user}
              placeholder={strings.enterNameEmail}
              name="userText"
              id="userText"
              formik={formik}
            />
          </Grid>
        )}
        <FilterSubmitButtons handleReset={handleReset} />
      </Grid>
    </Box>
  );
};

export default PharmacistListHeader;
