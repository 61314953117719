import { createAction } from "@reduxjs/toolkit"

export const errorActions = {
    CUSTOM_ERROR: 'CUSTOM_ERROR',
    RESET_ERROR: 'ERROR/reset',
};

export const setCustomError = createAction<{
    errorType: number;
    message: string;
    result: any; 
}>(errorActions.CUSTOM_ERROR);

export const resetError = createAction(errorActions.RESET_ERROR);
