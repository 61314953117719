import { SxProps, Theme } from '@mui/system';

const classes = {
  root: {
    backgroundColor: 'secondary.main',
    border: '1px solid',
    borderColor: 'common.border',
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.04))',
    borderRadius: '4px',
    padding: '21px',
  },

  container: {
    padding: '24px 24px 0px 24px',
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '12px',
  },

  contentTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },

  titleFont: {
    color: 'grey.500',
    fontWeight: 500,
    marginRight: '8px',
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  titleSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  labelStyle: {
    color: '#56607E',
    fontSize: 14,
    fontWeight: 400,
  },

  valueStyle: {
    color: 'grey.500',
    fontSize: 14,
    fontWeight: 400,
  },

  editIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  titleDivider: {
    flex: 1,
    marginLeft: '16px',
    borderColor: 'common.border',
  },

  divider: {
    backgroundColor: 'primary.main',
    height: '32px',
    width: '4px',
    marginRight: '21px',
  },

  mainTitle: {
    color: 'primary.main',
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
  },

  maskedTextContainer: {
    alignItems: 'center',
    display: 'flex',
  },

  passwordIcon: {
    width: 16,
    height: 16,
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
