import React from 'react';
import DashboardFilter from '@pharmaplan/common/assets/icons/dashboardFilter.svg';
import ResetDashboardFilter from '@pharmaplan/common/assets/icons/resetDashboardFilter.svg';

import {
  ArrayToDD,
  dropdownMapper,
  dropdownMinMaxMapper,
} from '@pharmaplan/common/helpers/utils';
import {
  ICalendarFilterInfo,
  IPharmacyFilterData,
  IReportFilterInfo,
} from '@pharmaplan/common';
import { DateTime } from 'luxon';
import strings from '../localization';
import { Constants, PharmacyWorkshiftType } from './Constants';
import incomeReportStyles from '../components/Reports/IncomeReport/style';

export type BannerName = Array<{ name: string; pharmacyChainId: string }>;

export const dashboardFilter = (
  filterValues: ICalendarFilterInfo,
  secondaryButtonClick: () => void,
  bannerName: BannerName,
) =>
  ({
    primaryButton: {
      icon: <img src={DashboardFilter} alt={strings.submit} />,
    },
    secondaryButton: {
      onClick: secondaryButtonClick,
      icon: <img src={ResetDashboardFilter} alt={strings.reset} />,
    },
    title: strings.general.toUpperCase(),
    type: 'single',
    form: [
      {
        label: strings.banner,
        type: Constants.formInputType.dropdown,
        xs: 2,
        name: 'banners',
        id: 'banners',
        imageMenu: false,
        multiple: true,
        menuItems: dropdownMapper(bannerName, 'pharmacyChainId', 'name') || [],
      },
      {
        label: strings.area,
        type: Constants.formInputType.dropdown,
        xs: 2,
        name: 'cities',
        id: 'area',
        menuItems: ArrayToDD(filterValues?.cities) || [],

        multiple: true,
      },
      {
        label: strings.software,
        type: Constants.formInputType.dropdown,
        xs: 2,
        name: 'softwares',
        id: 'softwares',
        menuItems:
        dropdownMapper(filterValues?.softwares, 'softwareId', 'name') || [],

        multiple: true,
      },
      {
        label: strings.weekVolumes,
        type: Constants.formInputType.dropdown,
        xs: 2,
        name: 'weekVolume',
        id: 'weekVolume',
        menuItems: dropdownMinMaxMapper(filterValues?.weekVolumes, 'label') || [],
      },
      {
        label: strings.weekEndVolumes,
        type: Constants.formInputType.dropdown,
        xs: 2,
        name: 'weekendVolume',
        id: 'weekendVolume',
        menuItems:
        dropdownMinMaxMapper(filterValues?.weekendVolumes, 'label') || [],
      },
      {
        name: 'minHourRate',
        label: strings.minimumHourlyRate,
        type: Constants.formInputType.text,
        xs: 2,
        maxLength: 3,
        customType: Constants.input.type.number,
      },
    ],
  });

export const bookingReportFilter = (
  filterValues: IReportFilterInfo,
  handleClear: () => void,
  bannerName: BannerName,
) =>
  ({
    primaryButton: {
      icon: <img src={DashboardFilter} alt={strings.submit} />,
    },
    secondaryButton: {
      icon: <img src={ResetDashboardFilter} alt={strings.clear} />,
      onClick: handleClear,
    },
    title: strings.general.toUpperCase(),
    form: [
      {
        label: strings.banner,
        type: Constants.formInputType.dropdown,
        xs: 3,
        name: 'banners',
        id: 'banners',
        imageMenu: false,
        multiple: true,
        menuItems:
        dropdownMapper(bannerName, 'pharmacyChainId', 'name') || [],
      },
      {
        label: strings.pharmacyName,
        type: Constants.formInputType.dropdown,
        xs: 3,
        name: 'pharmacyId',
        id: 'pharmacyId',
        menuItems:
        dropdownMapper(
          filterValues?.pharmacies,
          'pharmacyId',
          'pharmacyName',
        ) || [],
      },
      {
        type: Constants.formInputType.datePicker,
        xs: 6,
        name: 'pharmacyName',
        id: 'pharmacyName',
      },
    ],
  });

export const pharmacyBookingReportFilter = (
  filterValues: IPharmacyFilterData[],
  handleClear: () => void,
) =>
  ({
    primaryButton: {
      icon: <img src={DashboardFilter} alt={strings.submit} />,
    },
    secondaryButton: {
      icon: <img src={ResetDashboardFilter} alt={strings.clear} />,
      onClick: handleClear,
    },
    title: strings.general.toUpperCase(),
    form: [
      {
        label: strings.pharmacistName,
        type: Constants.formInputType.dropdown,
        xs: 3,
        name: 'pharmacistId',
        id: 'pharmacistId',
        menuItems: dropdownMapper(filterValues, 'pharmacistId', 'name') || [],
      },
      {
        type: Constants.formInputType.datePicker,
        xs: 6,
        name: 'pharmacyName',
        id: 'pharmacyName',
      },
    ],
  });

export const pharmacyWorkshiftReportFilter = (handleClear: () => void) =>
  ({
    primaryButton: {
      icon: <img src={DashboardFilter} alt={strings.submit} />,
    },
    secondaryButton: {
      icon: <img src={ResetDashboardFilter} alt={strings.clear} />,
      onClick: handleClear,
    },
    title: strings.general.toUpperCase(),
    form: [
      {
        label: strings.workshiftType,
        type: Constants.formInputType.dropdown,
        xs: 3,
        name: 'workshiftTypes',
        id: 'workshiftTypes',
        menuItems: dropdownMapper(PharmacyWorkshiftType(), 'key', 'name') || [],
      },
      {
        type: Constants.formInputType.datePicker,
        xs: 6,
        name: 'pharmacyName',
        id: 'pharmacyName',
      },
    ],
  });

export const workshiftAndRequestedReportFilter = (
  filterValues: IReportFilterInfo,
  handleClear: () => void,
  bannerName: BannerName,
) =>
  ({
    primaryButton: {
      icon: <img src={DashboardFilter} alt={strings.submit} />,
    },
    secondaryButton: {
      icon: <img src={ResetDashboardFilter} alt={strings.clear} />,
      onClick: handleClear,
    },
    title: strings.general.toUpperCase(),
    form: [
      {
        label: strings.banner,
        type: Constants.formInputType.dropdown,
        xs: 3,
        name: 'banners',
        id: 'banners',
        imageMenu: false,
        multiple: true,
        menuItems:
        dropdownMapper(bannerName, 'pharmacyChainId', 'name') ?? [],
      },
      {
        type: Constants.formInputType.datePicker,
        xs: 6,
        preventPast: true,
        name: 'pharmacyName',
        id: 'pharmacyName',
      },
    ],
  });

export const availableReportFilter = (handleClear: () => void) =>
  ({
    primaryButton: {
      icon: <img src={DashboardFilter} alt={strings.submit} />,
    },
    secondaryButton: {
      icon: <img src={ResetDashboardFilter} alt={strings.clear} />,
      onClick: handleClear,
    },
    title: strings.general.toUpperCase(),
    form: [
      {
        type: Constants.formInputType.datePicker,
        xs: 6,
        preventPast: true,
        name: 'pharmacyName',
        id: 'pharmacyName',
      },
    ],
  });

export const incomeReportFilter = (
  filterValues: IReportFilterInfo,
  handleClear: () => void,
  endMax: DateTime,
) =>
  ({
    primaryButton: {
      icon: <img src={DashboardFilter} alt={strings.submit} />,
    },
    secondaryButton: {
      icon: <img src={ResetDashboardFilter} alt={strings.clear} />,
      onClick: handleClear,
    },
    title: strings.general.toUpperCase(),
    form: [
      {
        type: Constants.formInputType.datePicker,
        xs: 6,
        name: 'date',
        id: 'date',
        endMax,
      },
      {
        label: strings.pharmacy,
        type: Constants.formInputType.dropdown,
        xs: 3,
        name: 'pharmacyIds',
        customMenuStyles: incomeReportStyles.dropdown,
        id: 'pharmacyIds',
        menuItems: dropdownMapper(
          filterValues?.pharmacies,
          'pharmacyId',
          'pharmacyName',
        ),
        multiple: true,
      },
    ],
  });
