import { SxProps, Theme } from '@mui/system';

const styles = {
  mainContainer: {
    display: 'flex',
    backgroundColor: 'common.lightBackgroundColor',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },

  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '10px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
