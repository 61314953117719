import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  AxisConfig,
  BarSeriesType,
  CardinalDirections,
  ChartsXAxisProps,
  ChartsYAxisProps,
  LineSeriesType,
  PieSeriesType,
  PieValueType,
  ScaleName,
  ScatterSeriesType,
} from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/internals';

export enum ChartTypes {
  bar = 'bar',
  line = 'line',
  pie = 'pie',
  composite = 'composite'
}

interface IChartSetting<T> {
  yAxis: MakeOptional<AxisConfig<ScaleName, any, ChartsYAxisProps>, 'id'>[];
  xAxis: MakeOptional<AxisConfig<ScaleName, any, ChartsXAxisProps>, 'id'>[];
  series: T;
  height: number;
  dataset: Array<any>;

}

export type CommonProps = {
  key: number;
  sx?: SxProps<Theme>
  xs?: number;
  useDoubleYAxis?: boolean;
  userTypeTitle?: string;
  margin?: Partial<CardinalDirections<number>>;
  rightLabelXTranslate?: string
}

export interface IBarChart extends CommonProps {
  type: ChartTypes.bar;
  chartSettings: IChartSetting<(BarSeriesType | PieSeriesType<MakeOptional<PieValueType, 'id'>> | LineSeriesType | ScatterSeriesType)[]>;
}

export interface IPieChart extends CommonProps {
  type: ChartTypes.pie;
  chartSettings: Omit<IChartSetting<MakeOptional<PieSeriesType<MakeOptional<PieValueType, 'id'>>, 'type'>[]>, 'xAxis' | 'yAxis' | 'dataset'>;
}

export interface ICompositeChart extends CommonProps {
  type: ChartTypes.composite;
  chartSettings: IChartSetting<(BarSeriesType | PieSeriesType<MakeOptional<PieValueType, 'id'>> | LineSeriesType | ScatterSeriesType)[]>;
}

export type ICharts = (IBarChart | IPieChart | ICompositeChart);

export interface IChartsBuilder {
  config: Array<ICharts>;

}
