import React, { FC } from 'react';
import { Box, Grid, IconButton, SxProps, Tooltip } from '@mui/material';

import CustomCheckbox from '../../common/CustomCheckbox';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete.svg';
import strings from '../../../localization';

import styles from './styles';

interface ISelectAllHeaderDelete {
  showDelete?: true;
  handleDelete: () => void;
  selectAll: boolean;
  handleSelectAll: (toBeSet: boolean) => void;
  checkBoxContainer?: SxProps;
  mainContainer?: SxProps;
}

interface ISelectAllHeaderWithoutDelete {
  showDelete?: false;
  handleDelete?: () => void;
  selectAll: boolean;
  handleSelectAll: (toBeSet: boolean) => void;
  checkBoxContainer?: SxProps;
  mainContainer?: SxProps;
}

const SelectAllHeader: FC<
  ISelectAllHeaderDelete | ISelectAllHeaderWithoutDelete
> = ({
  showDelete,
  handleDelete,
  selectAll,
  handleSelectAll,
  checkBoxContainer,
  mainContainer,
}) =>
  (
    <Grid container sx={[styles.container, mainContainer] as SxProps}>
      <Box sx={checkBoxContainer}>
        <CustomCheckbox
          label={strings.selectAll}
          id="selectAll"
          handleChange={(e) =>
            handleSelectAll(e.target.checked)}
          value={selectAll}
          name="selectAll"
        />
      </Box>
      {showDelete && (
      <Tooltip title={strings.deleteSelected}>
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      )}
    </Grid>
  );

export default SelectAllHeader;
