import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  footerContainer: {
    borderTop: `2px solid ${ThemeConstants.common.border}`,
    width: '100%',
    paddingTop: '20px',
  },
};

export default classes;
