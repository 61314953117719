import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/system';
import { IconButton, Tooltip } from '@mui/material';

import {
  ConfirmationTypes,
  pharmacyAddIncompatible,
  pharmacyRemoveIncompatible,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { ReactComponent as Compatible } from '../../../assets/svg/compatible.svg';
import { ReactComponent as Incompatible } from '../../../assets/svg/incompatible.svg';
import strings from '../../../localization';
import { setDialog } from '../../../reducers/dialogReducer';
import ConfirmationModal from '../../Modals/ConfirmationModal';

import styles from './styles';

interface ICustomIncompatible {
  initState: boolean | undefined;
  pharmacistId: string;
}

const CustomIncompatible = ({
  initState,
  pharmacistId,
}: ICustomIncompatible) => {
  const dispatch = useAppDispatch();
  const [isIncompatible, setIsIncompatible] = useState(initState);

  const isMounted = useRef(false);

  useEffect(() => {
    setIsIncompatible(initState);
  }, [initState]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    !isIncompatible
      ? dispatch(pharmacyRemoveIncompatible(pharmacistId))
      : dispatch(pharmacyAddIncompatible(pharmacistId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIncompatible]);

  const handleRequest = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    () => {
      e.stopPropagation();
      setIsIncompatible((prev) =>
        !prev);
    };

  const confirm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(
      setDialog({
        showCloseButton: false,
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            type={
              isIncompatible
                ? ConfirmationTypes.markCompatible
                : ConfirmationTypes.markIncompatible
            }
            confirmAction={handleRequest(e)}
          />
        ),
      }),
    );
  };

  return (
    <Box sx={styles.container}>
      <Tooltip
        title={strings.formatString(
          strings.markFormat,
          isIncompatible ? strings.compatible : strings.incompatible,
        )}
      >
        <Box component={IconButton} onClick={confirm}>
          {isIncompatible ? <Incompatible /> : <Compatible />}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default CustomIncompatible;
