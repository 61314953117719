import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    mb: '20px',
  },
  label: {
    flex: 2,
    fontSize: 14,
    fontWeight: '500',
    color: 'grey.500',
  },
  recipientsContainer: {
    flex: 4,
    backgroundColor: 'common.lightBackgroundColor',
    borderRadius: '4px',
    paddingBottom: '8px',
    display: 'flex',
    maxHeight: 84,
    overflow: 'auto',
  },
  textChip: {
    backgroundColor: 'secondary.main',
    padding: '2px 8px',
    borderRadius: '20px',
    fontSize: 12,
    lineHeight: '14px',
    color: 'grey.600',
    whiteSpace: 'nowrap',
    marginLeft: '8px',
    marginTop: '8px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
