import React, { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IAllRegions, IPharmacistRegionList } from '@pharmaplan/common';
import { FormikProps } from 'formik';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { useStyles } from './style';
import strings from '../../../localization';
import Checkbox from '../Checkbox';

interface ICustomAccordion {
  items: IAllRegions | IPharmacistRegionList;
  formik: FormikProps<any>;
  showTitle?: boolean;
}

const CustomAccordion = ({ items, formik, showTitle = true }: ICustomAccordion) => {
  const { values, setValues } = formik ?? {};
  const { allRegions, selectedRegions } = items ?? {};

  const classes = useStyles();

  const handleClickCheckbox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  const handleCheck = (key: string, checked: boolean) => {
    let arr = [...values];

    if (checked) {
      arr = arr.filter((e: string) =>
        e !== key);
    } else {
      arr.push(key);
    }

    setValues(arr);
  };

  const isAllSelected = values.length === allRegions.length;

  useEffect(() => {
    setValues(selectedRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const allGeoKeys = Object.keys(allRegions).map(
    (singleReg) =>
      allRegions[singleReg as unknown as number]?.key,
  );

  return (
    <Grid xs={12} component="div">
      {showTitle && (
      <Grid xs={12}>
        <Box className={classes.header} component="div">
          {strings.region}
        </Box>
      </Grid>
      )}

      <Accordion
        disableGutters
        onClick={(e) => {
          e.stopPropagation();
        }}
        defaultExpanded={false}
        expanded={false}
      >
        <AccordionSummary
          classes={{ content: classes.content }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={1}>
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                setValues(isAllSelected ? [] : allGeoKeys);
              }}
              checked={isAllSelected}
              color="primary"
            />
          </Grid>
          <Typography className={classes.text}>{strings.allRegions}</Typography>
        </AccordionSummary>
      </Accordion>

      {allRegions?.map((item) => {
        const { key, regionList, description, code } = item ?? {};
        const checked = values?.includes?.(key);
        const preventExpand = !regionList ? false : undefined;

        return (
          <Accordion
            key={key}
            disableGutters
            defaultExpanded={preventExpand}
            expanded={preventExpand}
          >
            <AccordionSummary
              classes={{ content: classes.content }}
              expandIcon={regionList && <ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid item xs={1}>
                <Checkbox
                  checked={checked}
                  color="primary"
                  onClick={(e) => {
                    handleCheck(key, checked);
                    handleClickCheckbox(e);
                  }}
                />
              </Grid>

              <Typography className={classes.text}>
                {description}
                {code && ` - ${code}`}
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.details}>
              <Grid container>
                <Grid xs={1} />
                <Grid className={classes.detailsItem} xs={11}>
                  {regionList?.map((reg) => {
                    const { code: regCode, description: regDescription } = reg ?? {};

                    return (
                      <Typography key={regCode}>
                        {`${regDescription}`}
                        {regCode && ` - ${regCode}`}
                      </Typography>
                    );
                  })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Grid>
  );
};

export default CustomAccordion;
