import { useEffect } from 'react';

import {
  chatPreLogout,
  getAssignedChannels,
  isLoadingSelector,
  resetStatus,
  userActions,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';

const useMakeChannelAvailable = () => {
  const dispatch = useAppDispatch();

  const assignedChannels = useAppSelector(getAssignedChannels);
  const logoutSuccess = useAppSelector((state) =>
    isLoadingSelector([userActions.logout], state));

  useEffect(() => {
    if (logoutSuccess) {
      dispatch(chatPreLogout(assignedChannels));
      dispatch(resetStatus([userActions.logout]));
    }
  }, [logoutSuccess]);
};

export default useMakeChannelAvailable;
