import { useState } from 'react';

import {
  FeedbackType,
  ITransferListItem,
} from '../components/common/TransferList/TransferList';

import strings from '../localization';

export type IListState = {
  left: Array<ITransferListItem>;
  right: Array<ITransferListItem>;
};

const initState = {
  left: [],
  right: [],
};

const initFeedback = { type: null, message: '' };

const useTransferList = (
  setList: React.Dispatch<React.SetStateAction<IListState>>,
) => {
  const [highlightedItems, setHighlightedItems] = useState<IListState>(initState);
  const [selectedItems, setSelectedItems] = useState<Array<ITransferListItem>>(
    [],
  );
  const [feedback, setFeedback] = useState<{
    type: FeedbackType | null;
    message: string;
  }>(initFeedback);

  const handleAdd = () => {
    setList((prev) =>
      ({
        left: prev.left.filter((item) =>
          !selectedItems.includes(item)),
        right: [...prev.right, ...selectedItems],
      }));
    setHighlightedItems((prev) =>
      ({
        left: prev.left.filter((item) =>
          !selectedItems.includes(item)),
        right: [...prev.right, ...selectedItems],
      }));
    setSelectedItems([]);
  };

  const handleAddWithRestriction = () => {
    if (selectedItems.length > 1) {
      setFeedback({
        type: FeedbackType.negative,
        message: strings.youCannotMoveMoreIncompatiblePharmacists,
      });
    } else if (selectedItems.length === 1) {
      setFeedback({
        type: FeedbackType.positive,
        message: strings.formatString(
          strings.youMoveUserIncompatiblePharmacist,
          selectedItems[0].label,
        ) as string,
      });
      setList((prev) =>
        ({
          left: prev.left.filter((item) =>
            !selectedItems.includes(item)),
          right: [...prev.right, ...selectedItems],
        }));
      setHighlightedItems((prev) =>
        ({
          left: prev.left.filter((item) =>
            !selectedItems.includes(item)),
          right: [...prev.right, ...selectedItems],
        }));
      setSelectedItems([]);
    }
  };

  const removeItem = (items: ITransferListItem[], item: ITransferListItem) =>
    items.filter((e) =>
      e.key !== item.key);

  const addItem = (items: ITransferListItem[], item: ITransferListItem) =>
    [
      ...items,
      item,
    ];

  const filterByKey = (item: ITransferListItem) =>
    (selected: ITransferListItem) =>
      selected.key === item.key;

  const selectItem = (item: ITransferListItem) =>
    () => {
      setSelectedItems((prev) => {
        if (prev.some(filterByKey(item))) {
          return removeItem(prev, item);
        }
        return addItem(prev, item);
      });
    };

  const updateList = (prev: IListState, itemToRemove: ITransferListItem) =>
    ({
      ...prev,
      right: prev.right.filter((item) =>
        itemToRemove.key !== item.key),
    });

  const updateHighlightedItems = (
    prev: IListState,
    itemToRemove: ITransferListItem,
  ) =>
    ({
      ...prev,
      left: [itemToRemove, ...prev.left],
    });

  const handleRemove = (itemToRemove: ITransferListItem) =>
    () => {
      setFeedback(initFeedback);
      setList((prev) =>
        updateList(prev, itemToRemove));
      setHighlightedItems((prev) =>
        updateHighlightedItems(prev, itemToRemove));
    };

  return {
    handleAdd,
    selectItem,
    handleRemove,
    setHighlightedItems,
    selectedItems,
    setFeedback,
    feedback,
    highlightedItems,
    handleAddWithRestriction,
  };
};

export default useTransferList;
