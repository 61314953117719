import { SxProps, Theme } from '@mui/system';

const styles = {

  root: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'common.border',
  },

  logoContainer: {
    display: 'flex',
    flex: 1,
    paddingBlock: '10px 20px',
    paddingInline: '8px',
  },

  container: {
    display: 'flex',
    paddingX: '24px',
    paddingY: '16px',
  },

  box: {
    backgroundColor: 'common.lightBackgroundColor',
    borderRadius: '4px',
    padding: '24px',
    minWidth: '240px',
    maxWidth: '240px',
  },

  pharmacyNameText: {
    fontSize: 20,
    fontWeight: 600,
    color: 'common.grey850',
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  locationIcon: {
    marginRight: '5px',
  },

  cityText: {
    fontSize: 14,
    fontWeight: 400,
    color: 'common.grey850',
  },

  logoStyle: {
    height: 75,
    width: 75,
    border: '1px solid',
    borderColor: 'common.border',
    marginRight: '16px',
  },

  contactText: {
    fontSize: 14,
    fontWeight: 500,
    color: 'common.grey850',
  },

  divider: {
    marginX: '32px',
    borderLeft: '1px solid',
    borderLeftColor: 'common.border',
    height: 40,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
