import React, { FC, useEffect, useMemo } from 'react';

import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';

import {
  AdminLegendVariant,
  adminActions,
  getRequestedPharmacists,
  isLoadingSelector,
  requestedPharmacists,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import PharmacyCard from '../../SelfService/PharmacyCard';
import WithSkeleton from '../../common/WithSkeleton';
import { LocationText } from '../AvailableWorkshifts/AvailableWorkshifts';
import strings from '../../../localization';
import useGetItems from '../../../hooks/useGetItems';
import { renderScreen, setBack } from '../../../actions/drawerActions';
import { ScreenTypes } from '../../../helpers/Constants';
import useOptimizedAvatar from '../../../hooks/useOptimizedAvatar';

import PharmacistList from './PharmacistList';
import styles from './styles';
import AdminHourlyRateFooter from '../../common/AdminHourlyRateFooter';

interface IRequestedPharmacistList {
  workshiftId: string;
}

const RequestedPharmacistList: FC<IRequestedPharmacistList> = ({
  workshiftId,
}) => {
  const successAction = adminActions.getRequestedPharmacists;

  const dispatch = useAppDispatch();
  const { getImage } = useGetItems();
  const requestedPharmacistsList = useAppSelector(requestedPharmacists);

  const loading = useAppSelector((state) =>
    isLoadingSelector([successAction], state));

  const pharmacyEvent = requestedPharmacistsList?.[0];
  const {
    pharmacyHourlyRate,
    pharmacyCity,
    logoId,
    pharmacyName,
    startDate,
    softwareName,
    pharmacyId,
  } = pharmacyEvent ?? {};

  const SkeletonTypography = WithSkeleton({ successAction, variant: 'text' })(
    Typography,
  );

  const goToRequestedList = () => {
    dispatch(
      renderScreen({
        screenNumber: 1,
        screenType: ScreenTypes.requestedList,
        date: startDate,
      }),
    );
  };

  const goToPharmacistRequestedList = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.requestedList,
        eventId: workshiftId,
      }),
    );
  };

  const goToViewDetails = () => {
    dispatch(
      renderScreen({
        screenNumber: 3,
        screenType: ScreenTypes.availableWorkshift,
        eventId: workshiftId,
        type: AdminLegendVariant.requested,
        back: goToPharmacistRequestedList,
        pharmacyId,
      }),
    );
  };

  const count = loading ? 0 : requestedPharmacistsList.length;
  const showCount = `${strings.bookingRequests} (${count})`;

  const pharmacistIds = requestedPharmacistsList.map(
    (item) =>
      item.pharmacistId,
  );

  const { getPharmacistImage } = useGetItems();

  const renderPharmacistList = () => {
    if (loading) {
      return <PharmacistList event={pharmacyEvent} key={workshiftId} />;
    }

    return requestedPharmacistsList.map((item) =>
      (
        <PharmacistList
          image={getPharmacistImage(item.pharmacistId)}
          event={item}
          key={item.workshiftId}
        />
      ));
  };

  const pIds = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      pharmacistIds,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestedPharmacistsList],
  );

  useOptimizedAvatar(pIds);

  useEffect(() => {
    dispatch(getRequestedPharmacists(workshiftId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setBack(goToRequestedList));

    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <Stack>
      <PharmacyCard
        type={AdminLegendVariant.requested}
        successAction={adminActions.getRequestedPharmacists}
        CustomContent={(
          <LocationText
            containerStyle={styles.footerContainer}
            label={(
              <SkeletonTypography sx={styles.footer}>
                {pharmacyCity}
              </SkeletonTypography>
            )}
          />
        )}
        event={{
          ...pharmacyEvent,
          city: pharmacyName,
          logoImage: getImage(logoId),
          hourlyRate: pharmacyHourlyRate,
        }}
        CustomFooter={(
          <AdminHourlyRateFooter
            pharmacyHourlyRate={pharmacyHourlyRate}
            successAction={successAction}
            software={softwareName}
          />
        )}
        contentClick={goToViewDetails}
      />

      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>{showCount}</Typography>
      </Box>

      {renderPharmacistList()}
    </Stack>
  );
};

export default RequestedPharmacistList;
