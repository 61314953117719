import React from 'react';

import { Grid } from '@mui/material';

import {
  pharmacistActions,
  reportFilterInfo,
  selectIncomeDetails,
  selectIncomeReports,
  successOrSelector,
} from '@pharmaplan/common';

import { useAppSelector } from '../../../hooks/useAppSelector';
import useIncomeReport from '../../../hooks/incomeReport/useIncomeReport';
import { incomeReportFilter } from '../../../helpers/Filters';
import ResponsiveClasses from '../../../theme/ResponsiveClasses';

import DynamicTable from '../../DynamicTable';
import AdsContainer from '../../common/AdsContainer';
import IncomeHeader from './IncomeHeader/IncomeHeader';
import { calculateTotalRevenueAndWorkshifts, summaryConfig } from './helper';
import { serverTodayMinusOne } from '../../../helpers/Functions';
import EmptyIncompatibleMatches from '../../Admin/IncompatibleMatches/EmptyIncompatibleMatches';
import strings from '../../../localization';

const IncomeReport = () => {
  const {
    formik,
    table,
    handleClear,
    handlePagination,
    isDetailedIncome,
    page,
    goToIncomeReport,
    reportConfig,
    orderBy,
    order,
    handleSorting,
  } = useIncomeReport();

  const { totalCount } = reportConfig() ?? {};

  const filterValues = useAppSelector(reportFilterInfo);
  const incomeReports = useAppSelector(selectIncomeReports);
  const detailedIncomeReport = useAppSelector(selectIncomeDetails);

  const loadSuccess = useAppSelector((state) =>
    successOrSelector(
      [pharmacistActions.incomeReports, pharmacistActions.incomeDetails],
      state,
    ));

  const { data } = incomeReports ?? {};
  const { revenue, totalCount: detailTotalCount } = detailedIncomeReport ?? {};
  const noData = data.length === 0;

  const { totalRevenue, totalWorkshiftsCount } = calculateTotalRevenueAndWorkshifts(data);

  const filterConfig = incomeReportFilter(filterValues, handleClear, serverTodayMinusOne);

  const summary = isDetailedIncome
    ? summaryConfig(revenue, detailTotalCount)
    : summaryConfig(totalRevenue, totalWorkshiftsCount);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={10}>
        <DynamicTable
          formik={formik}
          table={table}
          showFilter
          filter={filterConfig}
          loadSuccess={loadSuccess}
          page={page}
          showBackButton={isDetailedIncome}
          order={order}
          orderBy={orderBy}
          handleSort={handleSorting}
          customHeader={
            <IncomeHeader summary={summary} showLogo={isDetailedIncome} />
          }
          emptyContainerComponent={noData ? <EmptyIncompatibleMatches message={strings.pleaseEnterStartDate} /> : null}
          backFunction={goToIncomeReport}
          handlePagination={handlePagination}
          totalCount={totalCount}
        />
      </Grid>
      <Grid sx={ResponsiveClasses.hideOnLg} item xs={2}>
        <AdsContainer />
      </Grid>
    </Grid>
  );
};

export default IncomeReport;
