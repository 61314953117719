import * as Yup from 'yup';
import { DurationTypes } from '../../../helpers/Constants';
import strings from '../../../localization';

const Validator = () =>
  Yup.object().shape({
    weeklyRecurrence: Yup.string().when('repeatEvery', {
      is: DurationTypes.week,
      then: () =>
        Yup.string().required(strings.pleaseSelectDay),
    }),
    weekDayRecurrence: Yup.string().when('repeatEvery', {
      is: DurationTypes.weekday,
      then: () =>
        Yup.string().required(strings.pleaseSelectDay),
    }),
  });

export default Validator;
