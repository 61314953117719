import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { IStore } from '@pharmaplan/common/types/IStore';
import {
  getEmail,
  getTypeOfUser,
  profileUtils,
  resetStatus,
  setSignedUp,
  successSelector,
  userActions,
} from '@pharmaplan/common';

import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import { Constants } from '../../helpers/Constants';
import { userPersist } from '../../selectors/formPersistSelector';
import { resetAll } from '../../actions/persistActions';

const { paths } = Constants;
const { login } = paths;

const useSignupFlow = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userType = useAppSelector(getTypeOfUser);
  const email = useAppSelector(getEmail);
  const userPersistEmail = useAppSelector(userPersist);

  const isSignupSuccess = useAppSelector((state: IStore) =>
    successSelector([userActions.createProfile], state));

  const notPersistedEmail = userPersistEmail !== email;

  useEffect(() => {
    if (isSignupSuccess) {
      navigate(login);
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignupSuccess]);

  useEffect(
    () =>
      () => {
        dispatch(setSignedUp(false));
      },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    dispatch(resetStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notPersistedEmail) {
      dispatch(resetAll());
    }
    dispatch(profileUtils(userType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSignupFlow;
