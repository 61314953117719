import { emailRegex } from '@pharmaplan/common';
import * as Yup from 'yup';
import { postalCodeRegExp } from '../../../../helpers/Constants';
import strings from '../../../../localization';

export const pharmacyAdminModel = {
  name: Yup.string().required(strings.requiredField),
  description: Yup.string(),
  email: Yup.string()
    .matches(emailRegex, strings.emailValid)
    .required(strings.requiredField),
  languageId: Yup.string().required(strings.requiredField),
  province: Yup.string(),
  address: Yup.string(),
  city: Yup.string(),
  postalCode: Yup.string().matches(
    postalCodeRegExp,
    strings.postalCodeValidation,
  ),
  phone: Yup.string()
    .required(strings.requiredField)
    .min(
      12,
      strings.formatString(
        strings.phoneValidation,
        strings.homePhone,
      ) as string,
    ),
  mobile: Yup.string()
    .min(
      12,
      strings.formatString(strings.phoneValidation, strings.mobile) as string,
    )
    .nullable(),
};

const PharmacistEditProfileValidator = () =>
  Yup.object().shape(pharmacyAdminModel);

export default PharmacistEditProfileValidator;
