import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { FormikValues, useFormik } from 'formik';
import { Box } from '@mui/system';
import { TableCell, TableRow } from '@mui/material';

import {
  adminActions,
  adminBroadcastGroupUsers,
  getBroadcastGroupUsers,
  handleAll,
  HelpTypes,
  MiscType,
  successSelector,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useTableSelectCheckbox from '../../../../hooks/useTableSelectCheckbox';
import useTableSort from '../../../../hooks/useTableSort';
import strings from '../../../../localization';
import DynamicTable from '../../../DynamicTable';
import CreateBroadcastGroupHeader from './CreateEditBroadcastGroupHeader';
import CustomButton from '../../../common/CustomButton';
import useBroadcastCreateActions from '../../../../hooks/Admin/BroadcastList/useBroadcastCreateActions';
import { resetDialog } from '../../../../reducers/dialogReducer';
import { BroadcastGroupActionType } from '../../../../helpers/Constants';
import useBroadcastGroupEdit from '../../../../hooks/Admin/BroadcastList/useBroadcastGroupEdit';
import useUserNotFound from '../../../../hooks/useUserNotFound';
import EmptyIncompatibleMatches from '../../IncompatibleMatches/EmptyIncompatibleMatches';
import useBroadcastGroupCreateTable from '../../../../hooks/Admin/BroadcastList/useBroadcastGroupCreateTable';

import styles from './styles';

const initialValues = {
  userType: MiscType.All,
  city: '',
  userText: '',
  softwareIds: [],
  banners: [],
  weekVolume: null,
  weekendVolume: null,
};

interface ICreateEditBroadcastGroup {
  type: BroadcastGroupActionType;
}

const CreateBroadcastGroup: FC<ICreateEditBroadcastGroup> = ({ type }) => {
  const dispatch = useAppDispatch();
  const isMounted = useRef(false);

  const [page, setPage] = useState(1);
  const [buttonClicked, setButtonClicked] = useState(false);

  const success = useAppSelector((state) =>
    successSelector([adminActions.getBroadcastGroupUsers], state));

  const groupUsers = useAppSelector(adminBroadcastGroupUsers);

  const { data, totalCount } = groupUsers;
  const { showUserNotFound } = useUserNotFound(
    success,
    !!data.length,
    '',
    <EmptyIncompatibleMatches message={strings.emptyIncompatiblePrompt} />,
  );

  const { title, confirmEditAction, editIds, isEdit, isDrawerClosed } = useBroadcastGroupEdit({
    type,
    buttonClicked,
  });

  const { isDesc, orderBy, handleSorting, order } = useTableSort();

  const handleApiWithFilters = (
    values: FormikValues,
    inPage?: number,
    sortBy?: string,
    desc?: boolean,
  ) => {
    const {
      userType,
      city,
      userText,
      softwareIds,
      banners,
      weekendVolume,
      weekVolume,
    } = values ?? {};

    const parsedWeekend = JSON.parse(weekendVolume);
    const parsedWeek = JSON.parse(weekVolume);

    dispatch(
      getBroadcastGroupUsers({
        page: inPage ?? 1,
        userType: handleAll<HelpTypes>(userType),
        city,
        userText,
        softwareIds,
        banners,
        weekendVolume: parsedWeekend,
        weekVolume: parsedWeek,
        sortBy,
        desc,
      }),
    );
  };

  const handleSubmit = (values: FormikValues) => {
    setPage(1);
    handleApiWithFilters(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const { userType } = formik.values;

  const {
    handlePress,
    selectAllFunc,
    selectedIds,
    selectAll,
    handleReset: reset,
    handleEdit,
    allIds,
    setIsAlreadyIndexed,
    setIdsAlreadyThere,
  } = useTableSelectCheckbox({
    useOfEdit: true,
    page,
    nameOfData: 'broadcastGroupUsers',
    nameOfEdit: 'broadcastGroup',
    userType,
    dataLength: data.length,
  });

  const { table } = useBroadcastGroupCreateTable({
    handlePress,
    selectedIds,
    userType,
  });

  const { handleSaveGroup, resetConfirmation } = useBroadcastCreateActions({
    allIds,
    type,
    editAction: confirmEditAction,
  });

  const getUserList = () => {
    dispatch(
      getBroadcastGroupUsers({
        page: 1,
        userType: handleAll<HelpTypes>(userType),
      }),
    );
  };

  const handleReset = () => {
    formik.resetForm();
    getUserList();
    setPage(1);
    reset();
    dispatch(resetDialog());
  };

  const handleClick = () => {
    handleSaveGroup();
    setButtonClicked(true);
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    handleSubmit(formik.values);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  useEffect(() => {
    if (isEdit && data.length && editIds.length) {
      handleEdit({ data, idProperty: 'userId' })(editIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editIds, data]);

  useEffect(() => {
    setIdsAlreadyThere({ editIds, removedIds: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editIds]);

  useEffect(() => {
    if (!isDrawerClosed && isEdit) {
      setIsAlreadyIndexed({});
    }
  }, [isDrawerClosed, isEdit]);

  const EndAdornment = useCallback(
    () =>
      (
        <TableRow>
          <TableCell colSpan={10} sx={styles.cell}>
            <Box sx={styles.buttonContainer}>
              <CustomButton
                disabled={!allIds.length}
                label={strings.saveGroup}
                onClick={handleClick}
              />
            </Box>
          </TableCell>
        </TableRow>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds],
  );

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      handleApiWithFilters(formik.values, 1, orderBy, isDesc);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  const handlePagination = (_: unknown, selectedPage: number) => {
    const goToPage = selectedPage + 1;
    setPage(goToPage);
    handleApiWithFilters(formik.values, goToPage);
  };

  return (
    <DynamicTable
      totalCount={totalCount}
      page={page}
      table={table}
      showHeader={false}
      loadSuccess={success}
      handlePagination={handlePagination}
      emptyContainerComponent={showUserNotFound()}
      handleSort={handleSorting}
      order={order}
      orderBy={orderBy}
      CustomEndAdornment={<EndAdornment />}
      customHeader={(
        <CreateBroadcastGroupHeader
          selectAll={selectAll}
          title={title}
          formik={formik}
          handleSelectAll={selectAllFunc({ data, idProperty: 'userId' })}
          handleReset={() =>
            resetConfirmation(handleReset)}
        />
      )}
    />
  );
};

export default CreateBroadcastGroup;
