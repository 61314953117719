import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';

const ChatManager = () => {
  const store = useSendbirdStateContext();
  const sdk = store?.stores?.sdkStore?.sdk;

  const updateMetadata = (channel:string, data:Record<string, any>) => {
    sdk?.groupChannel
      ?.getChannel(channel)
      .then((inst) =>
        inst.updateMetaData(data, true));
  };

  const assignToCurrentUser = (
    username: string,
    userId: string,
    channel: string,
  ) => {
    sdk?.groupChannel
      ?.getChannel(channel)
      .then((inst) =>
        inst.updateChannel({ customType: userId }));

    updateMetadata(channel, { username, users: userId });
  };

  const makeChannelAvailable = (channel: string) => {
    updateMetadata(channel, { username: '', users: '' });
  };

  return { assignToCurrentUser, makeChannelAvailable };
};

export default ChatManager;
