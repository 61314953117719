import { Link, SxProps } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface ICustomLink {
  to: string;
  linkText: string;
  underline?: 'hover' | 'none' | 'always';
  classnames?: SxProps
}

const defaultProps = {
  underline: 'hover',
  classnames: {},
};

const CustomLink = ({ to, linkText, underline, classnames }: ICustomLink) =>
  (
    <div className="CustomLink-container" data-testid="test">
      <Link component={RouterLink} to={to} underline={underline} sx={classnames}>
        {linkText}
      </Link>
    </div>
  );

CustomLink.defaultProps = defaultProps;

export default CustomLink;
