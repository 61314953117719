import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, List, Skeleton } from '@mui/material';
import {
  ITEMS_PER_PAGE,
  getNotificationsList,
  getNotificationsListPagination,
  notificationActions,
  notificationsList,
  successOrSelector,
} from '@pharmaplan/common';
import { INotificationsData } from '@pharmaplan/common/models/NotificationsModel';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import NotificationItem from './NotificationItems/NotificationItem';
import classes from './styles';

interface INotifications {
  closeNotification:()=>void;
}

const Notifications:FC<INotifications> = ({ closeNotification }) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const [notifData, setNotifData] = useState<Array<INotificationsData>>([]);

  const allNotifications = useAppSelector(notificationsList);
  const loadSuccess = useAppSelector((state) =>
    successOrSelector([notificationActions.getNotificationsList], state));

  const { data } = allNotifications ?? {};

  useEffect(() => {
    setNotifData(data);
  }, [data]);

  const {
    container,
    root,
    skeletonContainer,
    skeletonAvatar,
    skeletonText,
    skeletonTextcontainer,
  } = classes;

  const listEndRef = useRef(null);

  const observerCallback = useCallback(
    (entries: { isIntersecting: any }[]) => {
      const paginationCondition = notifData.length >= page * ITEMS_PER_PAGE;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    [page, notifData],
  );

  const skeletonConfig = [
    {
      key: 0,
      width: '80%',
    },
    {
      key: 1,
      width: '60%',
    },
    {
      key: 2,
      width: '40%',
    },
  ];

  useEffect(() => {
    dispatch(getNotificationsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    page !== 1
      && dispatch(
        getNotificationsListPagination({ page, itemPerPage: ITEMS_PER_PAGE }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    if (listEndRef.current) {
      observer.observe(listEndRef.current);
    }

    return () => {
      if (listEndRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(listEndRef.current);
      }
    };
  }, [observerCallback]);

  const handleNotifDelete = (notificationId: string) => {
    const remainingNotifs = notifData.filter(
      (item) =>
        item.notificationId !== notificationId,
    );
    setNotifData(remainingNotifs);
  };

  return (
    <Box sx={root}>
      <List sx={container}>
        {loadSuccess ? (
          notifData.map((item: INotificationsData) => {
            const { notificationId } = item;

            return (
              <NotificationItem
                closeNotification={closeNotification}
                handleDeleteNotif={handleNotifDelete}
                key={notificationId}
                item={item}
              />
            );
          })
        ) : (
          <Box sx={skeletonContainer}>
            <Skeleton variant="circular" sx={skeletonAvatar} />

            <Box sx={skeletonTextcontainer}>
              {skeletonConfig.map((item) => {
                const { key, width } = item ?? {};

                return (
                  <Skeleton
                    key={key}
                    variant="text"
                    sx={skeletonText}
                    width={width}
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </List>
      <Box component="div" ref={listEndRef} />
    </Box>
  );
};

export default Notifications;
