import {
  IPharmacyDetails,
  adminActions,
  convertToDecimals,
  getPharmacyDetails,
  pharmacyDetails,
  resetStatus,
  submitPharmacyDetails,
  successSelector,
} from '@pharmaplan/common';
import { FormikValues, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import {
  IFromScreen,
  renderScreen,
  setBack,
  setFooter,
  setFooterShow,
} from '../../../actions/drawerActions';
import strings from '../../../localization';
import OutcomeModal from '../../../components/Modals/OutcomeModal';
import {
  OutcomeModalTypes,
  ScreenTypes,
  SecondaryAdminNavigation,
} from '../../../helpers/Constants';
import { setDialog } from '../../../reducers/dialogReducer';
import pharmacyEditValidator from '../../../components/Admin/Profile/Pharmacy/PharmacyEditProfile/Validator';
import { rateValidation } from '../../../helpers/Functions';
import useSelfService from '../../useSelfService';

const successAction = adminActions.submitPharmacyDetails;

const usePharmacyEditProfile = (
  fromScreen?: IFromScreen,
  hasOwner?: boolean,
  back?: () => void,
) => {
  const dispatch = useAppDispatch();
  const { isSelfService } = useSelfService();

  const details = useAppSelector(pharmacyDetails);
  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const { pharmacy } = details ?? {};
  const { hourlyRate, hourlyRateTech, pharmacyId, pharmacyHourlyRate } = pharmacy ?? {};

  const showSavedSuccessfully = () => {
    dispatch(
      setDialog({
        Component: (
          <OutcomeModal
            type={OutcomeModalTypes.success}
            message={strings.profileSaved}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const handleOnSubmit = (values: FormikValues) => {
    const {
      hourlyRate: formikHourlyRate,
      hourlyRateTech: formikHourlyRateTech,
      pharmaPlanPercentFees,
      cancellationPercentFees,
      noCancellationPeriod,
    } = values ?? {};

    if (isSelfService) {
      dispatch(
        submitPharmacyDetails({
          ...values,
          hourlyRate: parseFloat(formikHourlyRate),
          hourlyRateTech: parseFloat(formikHourlyRateTech || 0),
          noCancellationPeriod: parseInt(noCancellationPeriod, 10),
          pharmaPlanPercentFees: parseFloat(pharmaPlanPercentFees),
          cancellationPercentFees: parseFloat(cancellationPercentFees),
        } as IPharmacyDetails),
      );
    } else {
      dispatch(
        submitPharmacyDetails({
          ...values,
          hourlyRate: parseFloat(formikHourlyRate),
          hourlyRateTech: parseFloat(formikHourlyRateTech || 0),
        } as IPharmacyDetails),
      );
    }
  };

  const onSubmit = (values: FormikValues) => {
    const {
      hourlyRate: formikHourlyRate,
      pharmacyHourlyRate: formikPharmacyHourlyRate,
    } = values ?? {};

    const isPharmacyGreaterPharmacist = rateValidation({
      pharmacistRate: formikHourlyRate,
      pharmacyRate: formikPharmacyHourlyRate,
      dispatch,
    });

    if (isPharmacyGreaterPharmacist) {
      handleOnSubmit(values);
    }
  };

  const formik = useFormik({
    initialValues: {
      ...pharmacy,
      hourlyRate: convertToDecimals(hourlyRate),
      hourlyRateTech: convertToDecimals(hourlyRateTech),
      pharmacyHourlyRate: convertToDecimals(pharmacyHourlyRate),
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: pharmacyEditValidator,
  });

  const { handleSubmit } = formik ?? {};

  const gotoPharmacyProfile = () => {
    dispatch(
      renderScreen({
        screenNumber: 1,
        screenType: ScreenTypes.profile,
        pharmacyId,
        fromScreen,
        hasOwner,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.saveChanges,
            onClick: handleSubmit,
          },
        },
      }),
    );

    if (fromScreen !== SecondaryAdminNavigation.pharmacyList) {
      dispatch(setBack(back ?? gotoPharmacyProfile));
    }

    return () => {
      dispatch(setFooterShow(false));
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      showSavedSuccessfully();
      dispatch(resetStatus([successAction]));
      dispatch(getPharmacyDetails(pharmacyId));
      gotoPharmacyProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return { formik };
};

export default usePharmacyEditProfile;
