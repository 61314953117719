import React, { useEffect, useState } from 'react';
import {
  ToUserTypings,
  TypeOfUser,
  getNotificationsCount,
  getTypeOfUser,
  notificationsCount,
  profileImage,
} from '@pharmaplan/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { useAppSelector } from '../useAppSelector';
import strings from '../../localization';
import {
  getMultipleEventAdd,
} from '../../selectors/calendarSelector';
import { useAppDispatch } from '../useAppDispatch';
import { Constants, OutcomeModalTypes } from '../../helpers/Constants';
import { setTempEvents, setTempVacations } from '../../reducers/mainCalendarReducer';

import useEnforceUpdate from '../useEnforceUpdate';
import useAdmin from '../useAdmin';
import useUser from '../useUser';
import { logoutHandler } from '../../helpers/Functions';
import { setDialog } from '../../reducers/dialogReducer';
import OutcomeModal from '../../components/Modals/OutcomeModal';

const { pharmacist, pharmacy } = TypeOfUser;
const { sessionTimeOut } = OutcomeModalTypes;

const useNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isAdmin } = useAdmin();

  const { name = '' } = useUser();

  const [notifications, setNotifications] = useState<HTMLButtonElement | null>(
    null,
  );

  const userType = useAppSelector(getTypeOfUser);
  const multipleEventAdd = useAppSelector(getMultipleEventAdd);
  const avatar = useAppSelector(profileImage);
  const notificationCount = useAppSelector(notificationsCount);

  const { pathname } = location ?? {};

  const isPharmacist = userType === TypeOfUser.pharmacist;
  const isReports = pathname === Constants.paths.reports;
  const isNotificationOpen = !!notifications;

  const userConfig = ToUserTypings({
    [pharmacist]: {
      buttonLabel: strings.availabilities,
    },
    [pharmacy]: {
      buttonLabel: strings.workshifts,
    },
  });

  // SESSION TIMEOUT
  const handleIdle = () => {
    logoutHandler();
    dispatch(
      setDialog({
        Component: (
          <OutcomeModal
            type={sessionTimeOut}
            message={strings.yourSessionHasTimedOut}
          />
        ),
        showCloseButton: true,
        heading: { title: '' },
      }),
    );
  };

  const { REACT_APP_SESSION_TIMEOUT, REACT_APP_ADMIN_TIMEOUT } = process.env ?? {};

  useIdleTimer({
    onIdle: handleIdle,
    crossTab: true,
    timeout:
      parseInt(
        (isAdmin ? REACT_APP_ADMIN_TIMEOUT : REACT_APP_SESSION_TIMEOUT) ?? '0',
        10,
      )
      * 60
      * 1000,
  });

  const handleNotificationClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setNotifications(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotifications(null);
  };

  useEnforceUpdate();

  useEffect(() => {
    if (multipleEventAdd) {
      dispatch(setTempEvents([]));
      dispatch(setTempVacations([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleEventAdd]);

  useEffect(() => {
    dispatch(getNotificationsCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isNotificationOpen]);

  return {
    name,
    userType,
    avatar,
    notificationCount,
    userConfig,
    isPharmacist,
    isReports,
    isNotificationOpen,
    notifications,
    pathname,
    navigate,
    dispatch,
    handleNotificationClick,
    handleNotificationClose,
  };
};

export default useNavbar;
