import React, { useEffect } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  adminCreatePharmacistCapacity,
  adminGetPharmacistCapacityList,
  adminPharmacistCapacityList,
  IAdminCreatePharmacistCapacityApiParams,
  PermissionsOfAdmin,
} from '@pharmaplan/common';

import useConfigurationsSuccess from '../../../hooks/Admin/useConfigurationsSuccess';
import usePublishConfigurations from '../../../hooks/Admin/usePublishConfigurations';
import usePharmacistCapacities from '../../../hooks/PharmacistCapacities/usePharmacistCapacities';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useDeleteConfirmation from '../../../hooks/Admin/useDeleteConfirmation';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import useTableSort from '../../../hooks/useTableSort';
import { setDialog } from '../../../reducers/dialogReducer';
import strings from '../../../localization';
import { DeleteConfirmationType } from '../../../helpers/Constants';

import CreateNew from '../../common/CreateNew';
import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';
import InputModal from '../../Modals/InputModal';

import {
  addNewPharmacistCapacityForm,
  canCreateNew,
  pharmacistCapacityHeaders,
  pharmacistCapacityMap,
} from './helper';
import Validator from './validator';
import styles from './styles';

const initialValues = {
  from: '',
  to: '',
  published: false,
};

const { createPharmacistCapacity: createAction, updatePharmacistCapacity } = adminActions;

const PharmacistCapacities = () => {
  const dispatch = useAppDispatch();

  const { can } = useAdminPermissions();

  const { isDesc, orderBy, handleSorting, order } = useTableSort();

  const canChangeParameters = can(PermissionsOfAdmin.ChangeParameters);
  const canDelete = can(PermissionsOfAdmin.Delete);

  useConfigurationsSuccess({
    addAction: createAction,
    editAction: updatePharmacistCapacity,
  });

  const pharmacistCapacityList = useAppSelector(adminPharmacistCapacityList);
  const { handleUpdate } = usePharmacistCapacities();
  const { showDeleteConfirmation } = useDeleteConfirmation(
    DeleteConfirmationType.pharmacistCapacities,
  );
  const {
    setPublishedRecords,
    handlePublish,
    mapPublishedRecords,
    publishedRecords,
  } = usePublishConfigurations();

  useEffect(() => {
    const pharmacistCapacityPublishMap = mapPublishedRecords(
      pharmacistCapacityList,
      'pharmacistCapacityId',
      'published',
    );
    setPublishedRecords(pharmacistCapacityPublishMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacistCapacityList]);

  useEffect(() => {
    dispatch(adminGetPharmacistCapacityList({ paging: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderBy) {
      dispatch(
        adminGetPharmacistCapacityList({
          paging: {
            sortBy: orderBy,
            desc: isDesc,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  const createPharmacistCapacity = (values: FormikValues) => {
    dispatch(
      adminCreatePharmacistCapacity(
        values as IAdminCreatePharmacistCapacityApiParams,
      ),
    );
  };

  const createModal = () => {
    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={createPharmacistCapacity}
            initialValues={initialValues}
            submitLabel={strings.create}
            config={addNewPharmacistCapacityForm()}
            validator={Validator()}
          />
        ),
        heading: {
          title: strings.addNew,
        },
        showCloseButton: true,
      }),
    );
  };

  const table: IDynamicTableObject = {
    title: strings.pharmacistCapacities,
    rows: pharmacistCapacityMap(
      pharmacistCapacityList,
      publishedRecords,
      handlePublish,
      handleUpdate,
      showDeleteConfirmation,
      canChangeParameters,
      canDelete,
    ),
    headers: pharmacistCapacityHeaders(),
    headerBar: canCreateNew(canChangeParameters, {
      Component: <CreateNew label={strings.createNew} onClick={createModal} />,
      key: '1',
    }),
  };

  return (
    <DynamicTable
      customContainerStyle={styles.container}
      table={table}
      order={order}
      orderBy={orderBy}
      handleSort={handleSorting}
      hidePagination
      loadSuccess
    />
  );
};

export default PharmacistCapacities;
