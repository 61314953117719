import React, { FC, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikValues, useFormik } from 'formik';
import { Schema } from 'yup';

import { TextFieldVariants } from '../../../../helpers/Constants';
import strings from '../../../../localization';
import genericClasses from '../../../../theme/GenericClasses';
import CustomButton from '../../../common/CustomButton';
import CustomCheckbox from '../../../common/CustomCheckbox';
import CustomTextField from '../../../common/CustomTextField';

import ChainUploadBanner from './ChainUploadBanner';
import styles from './styles';

interface IChainUploadModal {
  initialValues: Record<string, any>;
  onSubmit: (values: FormikValues) => void;
  validator?: Schema;
  submitLabel: string;
  inUse?: boolean;
}

const ChainUploadModal: FC<IChainUploadModal> = ({
  initialValues,
  validator,
  onSubmit,
  submitLabel,
  inUse = false,
}) => {
  const [disabled, setDisabled] = useState(false);
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validator,
  });

  const handleClear = () => {
    formik.setFieldValue('image', null);
    formik.setFieldValue('isLogoChanged', true);
    setDisabled(false);
  };

  const handlePublish = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('published', e.target.checked);
  };

  const { name } = formik.values.image ?? {};

  return (
    <Box sx={styles.container}>
      <Grid container>
        <Grid item xs={6} sx={styles.labelContainer}>
          <Typography sx={styles.label}>{strings.name}</Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            variant={TextFieldVariants.filled}
            customTextFieldStyle={genericClasses.customFilledInput}
            formik={formik}
            name="name"
            id="name"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Typography sx={[styles.label, styles.checkboxLabel]}>
            {strings.published}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomCheckbox
            name="published"
            disabled={inUse}
            handleChange={handlePublish}
            value={formik.values.published}
            label=""
            id="published"
            customClass={genericClasses.checkboxContainer}
            customCheckboxStyle={[
              genericClasses.checkboxStyle,
              styles.customCheckbox,
            ]}
          />
        </Grid>
      </Grid>
      <ChainUploadBanner
        name={name}
        handleClear={handleClear}
        formik={formik}
        image={initialValues.logoImage}
        setDisabled={setDisabled}
      />
      <Box sx={styles.buttonContainer}>
        <CustomButton
          onClick={formik.handleSubmit}
          customClass={styles.button}
          label={submitLabel}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

export default ChainUploadModal;
