import axios from 'axios';
import { googleGeocode } from './Constants';

interface IResult {
  data: {
    results: Array<{
      address_components: Array<{
        long_name: string;
        short_name: string;
      }>;
    }>;
  };
}
const ReverseGeocode = async (
  latitude: number,
  longitude: number,
  apiKey: string
) => {
  try {
    const resp: IResult = await axios.get(
      googleGeocode(latitude, longitude, apiKey)
    );
    return `${resp.data?.results[0].address_components[0].long_name}, ${resp.data?.results[0].address_components[1].long_name}`;
  } catch (err) {
    return err as string;
  }
};

export default ReverseGeocode;
