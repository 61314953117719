import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import {
  forgotPassword,
  resetStatus,
  successSelector,
  userActions,
} from '@pharmaplan/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { classes } from './style';
import CustomTextField from '../common/CustomTextField';
import strings from '../../localization';
import { ReactComponent as LockIcon } from '../../assets/svg/lockIcon.svg';
import LoginCustomCard from '../common/LoginCustomCard';
import { Constants, LoginType } from '../../helpers/Constants';
import Validator from './Validator';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';

const { resetPassword } = Constants.paths;

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isReset = pathname === resetPassword;

  const handleSubmit = (values: { email: string }) => {
    dispatch(forgotPassword(values.email));
  };
  const resetSuccess = useAppSelector((state) =>
    successSelector([userActions.forgotPassword], state));

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: handleSubmit,
    validationSchema: Validator,
  });

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    resetSuccess
      && navigate(Constants.paths.otpForm, {
        state: { email: formik.values.email },
      });
    dispatch(resetStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSuccess]);

  const textArray = [
    {
      key: 1,
      text: isReset ? strings.resetPassword : strings.setPassword,
      sx: classes.infoHeading,
    },
    {
      key: 2,
      text: strings.resetDesc,
      sx: classes.infoDesc,
    },
  ];

  return (
    <LoginCustomCard
      icon={<LockIcon />}
      textArray={textArray}
      buttonTitle={strings.send}
      loginType={LoginType.resetPassword}
      onClick={formik.handleSubmit}
    >
      <CustomTextField
        name="email"
        placeholder="name@mail.com"
        id="email"
        formik={formik}
        label={strings.email}
      />
    </LoginCustomCard>
  );
};

export default ResetPassword;
