import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/system';
import { Badge, Button, Paper, Typography } from '@mui/material';

import {
  ITEMS_PER_PAGE,
  TypeOfUser,
  activeUserList,
  getActiveUsers,
  logos,
  selectActiveUsersSegment,
  setActiveUsersSegment,
  activeUserCount,
  successSelector,
  adminActions,
} from '@pharmaplan/common';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';

import ActiveUserItem from './ActiveUserItem';
import styles from './styles';

const segments = [
  { key: TypeOfUser.pharmacist, label: strings.pharmacists },
  { key: TypeOfUser.pharmacy, label: strings.pharmacies },
];

const ActiveUsers = () => {
  const dispatch = useAppDispatch();
  const allLogos = useAppSelector(logos);
  const success = useAppSelector((state) =>
    successSelector([adminActions.getActiveUsers], state));
  const segment = useAppSelector(selectActiveUsersSegment);
  const activeCount = useAppSelector(activeUserCount);
  const { height } = useWindowDimensions();

  const [page, setPage] = useState(1);
  const listEndRef = useRef(null);
  const activeUsers = useAppSelector(activeUserList);

  const handleSegmentClick = (seg: TypeOfUser) =>
    () => {
      dispatch(setActiveUsersSegment(seg));
    };

  useEffect(() => {
    dispatch(getActiveUsers({ page, user: segment }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, page]);

  const observerCallback = useCallback(
    (entries: { isIntersecting: boolean }[]) => {
      const paginationCondition = activeUsers.length >= page * ITEMS_PER_PAGE;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    [page, activeUsers],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    if (listEndRef.current) {
      observer.observe(listEndRef.current);
    }

    return () => {
      if (listEndRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(listEndRef.current);
      }
    };
  }, [observerCallback]);

  return (
    <Box component={Paper} sx={styles.container}>
      <Typography sx={styles.title}>{strings.connectedUsers}</Typography>

      <Box sx={styles.segment}>
        {segments.map((item) => {
          const { key, label } = item;
          const isActive = key === segment;
          const currentTab = isActive && activeUsers?.length > 0;
          const otherTab = !isActive && activeCount > 0;
          const showActiveDot = (currentTab || otherTab) && success;

          const count = isActive ? activeUsers.length : activeCount;

          return (
            <Button
              key={key}
              fullWidth
              variant="text"
              sx={[
                styles.segmentButtons,
                isActive ? styles.active : styles.inActive,
              ]}
              onClick={handleSegmentClick(key)}
            >
              {showActiveDot ? (
                <Badge
                  badgeContent={count}
                  color="error"
                  sx={styles.badge}
                >
                  <Typography
                    sx={[
                      styles.text,
                      styles.segmentButtons,
                      isActive ? styles.activeText : styles.inActive,
                    ]}
                  >
                    {label}
                  </Typography>
                </Badge>
              ) : (
                <Typography
                  sx={[
                    styles.text,
                    styles.segmentButtons,
                    isActive ? styles.activeText : styles.inActive,
                  ]}
                >
                  {label}
                </Typography>
              )}
            </Button>
          );
        })}
      </Box>
      <Box sx={[styles.list, styles.dynamicHeight(height ?? 1)]}>
        {activeUsers.map((item) => {
          const { id, logoId } = item ?? {};
          const { image } = allLogos.find((it) =>
            it.logoId === logoId) ?? {};

          return (
            <ActiveUserItem
              segment={segment}
              logo={image}
              key={id}
              item={item}
            />
          );
        })}
        <Box component="div" ref={listEndRef} />
      </Box>
    </Box>
  );
};

export default ActiveUsers;
