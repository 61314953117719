import { FormikValues } from 'formik';

import {
  AllowanceTypes,
  IAdminWorkshiftDetails,
  IWorkshift,
  ServiceTypes,
  convertToDecimals,
  IAdminBookedPharmacistDetails,
  IAdminRequestedDetailsData,
} from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';

import useAdmin from './useAdmin';

const useSelfService = () => {
  const { isAdmin } = useAdmin();

  const serviceType = ServiceHandler.getService();

  const isSelfService = serviceType === ServiceTypes.self;

  // SPECIFIC RETURN VALUES ARE CONVERTED TO 2 SF DECIMALS
  const updateParser = (
    event:
      | IWorkshift
      | IAdminWorkshiftDetails
      | IAdminBookedPharmacistDetails
      | IAdminRequestedDetailsData,
    valuesToBeParsed: Array<string>,
  ) => {
    if (isAdmin || isSelfService) {
      return valuesToBeParsed.reduce((acc: { [key: string]: number }, curr) => {
        acc[curr] = (convertToDecimals(
            event[curr as keyof typeof event] as unknown as number,
        ) as number) ?? 0;
        return acc;
      }, {});
    }
    return {};
  };

  // VALUES SENT TO API ARE CONVERTED STRING -> FLOAT
  const workshiftParser = (values: FormikValues, keyToParse: Array<string>) => {
    if (isAdmin || isSelfService) {
      return keyToParse.reduce((acc: { [key: string]: number }, curr) => {
        acc[curr] = parseFloat(values[curr] || '0');
        return acc;
      }, {});
    }
    return {};
  };

  // IF TYPE DOES NOT MATCH THEN VALUE SENT WILL BE 0
  const allowedValuesParser = (
    values: FormikValues,
    parsedValues: FormikValues,
    valueCriteria: Record<
      string,
      {
        shouldBe: AllowanceTypes;
        checkFrom: string;
      }
    >,
  ) => {
    if (isAdmin || isSelfService) {
      return Object.keys(valueCriteria).reduce(
        (acc: { [key: string]: number }, key) => {
          acc[key] = values[valueCriteria[key].checkFrom] === valueCriteria[key].shouldBe
            ? parsedValues[key]
            : 0;
          return acc;
        },
        {},
      );
    }
    return {};
  };
  return { workshiftParser, isSelfService, updateParser, allowedValuesParser };
};

export default useSelfService;
