import React, { FC, useRef } from 'react';
import { MenuItemData, NestedDropdown } from 'mui-nested-menu';
import { Box, InputLabel } from '@mui/material';
import { FormikProps } from 'formik';
import { IGenericMultilevelDropdown } from '@pharmaplan/common';
import strings from '../../../localization';
import styles from './style';

interface IMultilevelDropdown {
  name: string;
  label: string;
  formik: FormikProps<any>;
  menuItems: Array<IGenericMultilevelDropdown>;
}

interface DropdownItem {
  label: string;
  items?: DropdownItem[];
  callback?: (
    event: React.MouseEvent<HTMLElement>,
    cItem: MenuItemData
  ) => void;
}

const MultilevelDropdown: FC<IMultilevelDropdown> = ({
  name,
  label,
  menuItems,
  formik,
}) => {
  const { values, setFieldValue } = formik ?? {};
  const ref = useRef<HTMLButtonElement | null>(null);

  const createCallback = (key: string, value: string, inKey: string, inValue: string) =>
    (event: React.MouseEvent<HTMLElement>, cItem: MenuItemData) =>
      setFieldValue(name, {
        label: value,
        key,
        module: inKey,
        moduleLabel: inValue,
      });

  const mapModules = (
    key: string,
    value: string,
    modules: Array<IGenericMultilevelDropdown>,
  ): DropdownItem[] =>
    modules?.map((inItem) => {
      const { key: inKey, value: inValue } = inItem ?? {};

      return {
        label: inValue,
        callback: createCallback(key, value, inKey, inValue),
      };
    }) ?? [];

  const parseItem = (item: IGenericMultilevelDropdown): DropdownItem => {
    const { key, value, modules } = item ?? {};

    return {
      label: value,
      items: mapModules(key, value, modules),
    };
  };

  const parsedModules = (): DropdownItem[] =>
    menuItems?.map(parseItem) ?? [];

  const dropdownStruct = {
    label: values[name]?.moduleLabel
      ? `${values[name]?.label} - ${values[name]?.moduleLabel}`
      : strings.selectAction,
    items: parsedModules(),
  };

  return (
    <Box component="div" sx={styles.root}>
      <InputLabel>{label}</InputLabel>
      <Box sx={styles.menuContainer}>
        <NestedDropdown
          menuItemsData={dropdownStruct}
          MenuProps={{
            sx: [
              {
                '& .MuiPaper-root': {
                  minWidth: ref.current?.clientWidth,
                  maxWidth: ref.current?.clientWidth,
                },
              },
              styles.menu,
            ],
          }}
          ButtonProps={{
            ref,
            disableRipple: true,
            fullWidth: true,
            variant: 'text',
            sx: [
              styles.container,
              styles.field,
              values[name]?.moduleLabel ? styles.selected : styles.placeholder,
            ],
          }}
        />
      </Box>
    </Box>
  );
};

export default MultilevelDropdown;
