import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const styles = {
  normal: {
    color: ThemeConstants.pharmacy.posted,
    backgroundColor: ThemeConstants.pharmacy.postedLight,
  },
  critical: {
    backgroundColor: ThemeConstants.admin.availabilityLight,
    color: ThemeConstants.admin.availability,
  },
  urgent: {
    backgroundColor: ThemeConstants.admin.requestedLight,
    color: ThemeConstants.admin.requested,
  },
  severe: {
    backgroundColor: ThemeConstants.admin.bookedLight,
    color: ThemeConstants.admin.booked,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
