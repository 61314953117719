import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  createNote,
  createPharmacyNote,
  ToUserTypings,
  TypeOfUser,
  updateNote,
  updatePharmacyNote,
} from '@pharmaplan/common';
import { useFormik } from 'formik';
import React from 'react';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import useUser from '../../../../hooks/useUser';
import strings from '../../../../localization';
import { resetDialog } from '../../../../reducers/dialogReducer';
import CustomButton from '../../../common/CustomButton';
import CustomTextField from '../../../common/CustomTextField';
import classes from './styles';
import Validator from './Validator';

interface IAddNoteModal {
  id: string;
  update: boolean;
}

const AddNoteModal = ({ id, update }: IAddNoteModal) => {
  const dispatch = useAppDispatch();

  const closeDialog = () => {
    dispatch(resetDialog());
  };

  const { notes, userType } = useUser();

  const userConfig = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      findId: 'pharmacistPharmacyNoteId',
      updateNote,
      updateBaseParams: { noteId: id },
      createBaseParams: { pharmacyId: id },
      createNote,
    },
    [TypeOfUser.pharmacy]: {
      findId: 'pharmacyPharmacistNoteId',
      updateNote: updatePharmacyNote,
      updateBaseParams: { pharmacistNotesId: id },
      createBaseParams: { pharmacistId: id },
      createNote: createPharmacyNote,
    },
  });

  const text = update
    ? notes.find(
      (item: { [x: string]: string }) =>
        item[userConfig[userType].findId] === id,
    )?.note
    : '';

  const handleSubmit = (values: { note: string }) => {
    dispatch(
      update
        ? userConfig[userType].updateNote({
          ...userConfig[userType].updateBaseParams,
          note: values.note,
        })
        : userConfig[userType].createNote({
          ...userConfig[userType].createBaseParams,
          note: values.note,
        }),
    );
    closeDialog();
  };

  const formik = useFormik({
    initialValues: {
      note: text ?? '',
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: Validator,
  });

  return (
    <Box sx={classes.container}>
      <Typography sx={classes.title}>
        {update ? strings.updateNote : strings.createNewNote}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography>{strings.notes}</Typography>
        </Grid>
        <Grid item xs={10}>
          <CustomTextField
            variant="filled"
            multiline
            maxLength={200}
            id="note"
            name="note"
            formik={formik}
          />
        </Grid>
      </Grid>
      <Box sx={classes.buttonContainer}>
        <CustomButton
          customClass={classes.buttons}
          variant="outlined"
          label={strings.cancel}
          onClick={closeDialog}
        />
        <CustomButton
          customClass={classes.buttons}
          label={update ? strings.update : strings.create}
          onClick={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default AddNoteModal;
