import { PharmacyListTabTypes } from '../../../../helpers/Constants';
import strings from '../../../../localization';

type HandleTabClick = (tab: PharmacyListTabTypes) => void;

export const tabConfig = (handleTabClick: HandleTabClick) =>
  [
    {
      key: PharmacyListTabTypes.activePharmacies,
      isDefault: true,
      tabName: strings.activePharmacies,
      onClick: handleTabClick,
    },
    {
      key: PharmacyListTabTypes.deactivatedPharmacies,
      tabName: strings.deactivatedPharmacies,
      onClick: handleTabClick,
    },
    {
      key: PharmacyListTabTypes.appointedPharmacies,
      tabName: strings.appointedPharmacies,
      onClick: handleTabClick,
    },
    {
      key: PharmacyListTabTypes.deletedPharmacies,
      tabName: strings.userDeletedAccounts,
      onClick: handleTabClick,
    },
  ];
