const classes = {
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '12px',
    fontWeight: 400,
  },
  valueContainer: {
    display: 'flex',
  },
  value: {
    fontSize: '14px',
    fontWeight: 500,
  },
  strike: {
    display: 'flex',
    fontSize: '13px',
    fontWeight: 400,
    textDecorationLine: 'line-through',
    paddingRight: '6px',
    alignItems: 'center',
  },
};

export default classes;
