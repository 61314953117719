import { SxProps, Theme } from '@mui/system';

const styles = {
  avatar: {
    width: 100,
    height: 100,
    border: '1px solid',
    borderColor: 'common.border',
  },

  imgProp: {
    style: {
      objectFit: 'contain',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
