import {
  Box,
  Popover,
  PopoverOrigin,
  PopoverPosition,
  PopoverReference,
  PopoverVirtualElement,
} from '@mui/material';
import React, { ReactNode, useRef } from 'react';
import CustomDialogTitle from '../ModalCommon/CustomDialogTitle';
import classes from './style';

interface ICustomPopover {
  children: ReactNode;
  open: boolean;
  popoverTitle?: string;
  onClose: () => void;
  anchorReference?: PopoverReference;
  anchorPosition?: PopoverPosition;
  anchorEl?:
    | null
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement);
  anchorOrigin?: PopoverOrigin;
  hasTitle?: boolean;
  customWidth?: object | null;
  removeTitleMargin?: boolean;
}

const CustomPopover = ({
  children,
  open,
  popoverTitle,
  onClose,
  anchorPosition,
  anchorReference,
  anchorEl,
  anchorOrigin,
  hasTitle,
  customWidth,
  removeTitleMargin,
}: ICustomPopover) => {
  const containerRef = useRef();

  const customTitleContainer = {
    customTitleContainer: removeTitleMargin ? classes.removeTitleMargin : {},
  };

  return (
    <Box ref={containerRef}>
      <Popover
        open={open}
        anchorPosition={anchorPosition}
        anchorReference={anchorReference}
        anchorEl={anchorEl}
        marginThreshold={60}
        anchorOrigin={anchorOrigin}
        onClose={onClose}
        slotProps={{
          paper: {
            style: customWidth ?? classes.popupPaper,
          },
        }}
      >
        <Box component="div" sx={classes.popoverContentWrapper}>
          {hasTitle && (
            <CustomDialogTitle
              customStyle={customTitleContainer}
              headingTitle={popoverTitle}
              onClickClose={onClose}
            />
          )}
          <Box component="div" sx={classes.scrollableContent}>
            {children}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default CustomPopover;
