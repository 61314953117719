import { getUserSecondaryContact, updatePharmactContact, userSecondaryContact } from '@pharmaplan/common';
import { userInitialState } from '@pharmaplan/common/reducers/userReducer';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';

import { SecondaryValidator } from '../../FormValidation';
import { ICommonProfile } from '../../PharmacistFlow/General/General';
import ProfileForm from '../../ProfileForm';

const Secondary = ({ setSelected }:ICommonProfile) => {
  const dispatch = useAppDispatch();
  const secondary = useAppSelector(userSecondaryContact);

  const formik = useFormik({
    initialValues: { ...userInitialState?.secondaryContact },
    onSubmit: (values) => {
      dispatch(updatePharmactContact({ values, type: 'secondarycontact' }));
    },
    enableReinitialize: true,
    validationSchema: SecondaryValidator(),
  });

  useEffect(() => {
    dispatch(getUserSecondaryContact());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.setValues({ ...secondary });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondary]);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="secondary"
      formik={formik}
    />
  );
};

export default Secondary;
