import {
  adminActions,
  adminBookedPharmacistDetails,
  adminBookingDetails,
  AdminLegendVariant,
  adminWorkshiftDetails,
  getWorkshiftDetails,
} from '@pharmaplan/common';
import { useAppSelector } from '../useAppSelector';

const { booked, posted, requested } = AdminLegendVariant;
const {
  getWorkshiftDetails: getWorkshiftDetailsAction,
  getBookedPharmacistDetails,
} = adminActions;

const useViewDetailsPharmacy = (
  type: Omit<AdminLegendVariant, AdminLegendVariant.total>,
) => {
  const workshiftDetails = useAppSelector(adminWorkshiftDetails);
  const bookedDetails = useAppSelector(adminBookingDetails);

  const details = {
    [posted]: workshiftDetails,
    [requested]: workshiftDetails,
    [booked]: bookedDetails,
  };

  const api = {
    [posted]: getWorkshiftDetails,
    [requested]: getWorkshiftDetails,
    [booked]: adminBookedPharmacistDetails,
  };

  const successAction = {
    [posted]: getWorkshiftDetailsAction,
    [requested]: getWorkshiftDetailsAction,
    [booked]: getBookedPharmacistDetails,
  };

  return {
    details: details[type as keyof typeof details],
    api: api[type as keyof typeof api],
    successAction: successAction[type as keyof typeof successAction],
  };
};

export default useViewDetailsPharmacy;
