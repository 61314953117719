import { CSSObject } from '@emotion/react';

export declare type CSSObjectWithLabel = CSSObject & {
  label?: string;
};

const classes = (height: number | undefined) =>
  ({
    selectContainer: {
      position: 'absolute',
      top: '16px',
      left: '10px',
      zIndex: 1,
      width: '100%',
    },
    container: {
      position: 'relative',
    },
    mapViewContainer: {
      height: (height ?? 1) / 2,
      width: '100%',
      position: 'relative',
    },
    mapMarkerContainer: {
      width: '26px',
      height: '37px',
      overflow: 'hidden',
      position: 'absolute',
      left: '50%',
      top: '48%',
      zIndex: 0,
    },
    mapMarker: {
      width: '26px',
      height: '37px',
      border: '0px',
      padding: '0px',
      margin: '0px',
      maxWidth: 'none',
      color: 'red',
    },
    buttonContainer: {
      justifyContent: 'end',
      paddingTop: '22px',
    },
    currentLocationMarkerContainer: {
      color: 'blue',
    },
    currentLocationPinContainer: {
      position: 'absolute', top: '145px', right: '10px', background: 'white' },
  });

export const input = (provided: CSSObjectWithLabel) =>
  ({

    ...provided,
    color: '#333333',
    fontWeight: 400,
    fontSize: '12px',
    borderRadius: '4px',
    borderStyle: 'none',
    border: 0,
  });

export const option = (provided: CSSObjectWithLabel) =>
  ({
    ...provided,
    color: '#333333',
    fontWeight: 400,
    borderBottom: '0.5px solid #E1E4EF',
    fontSize: '12px',
  });

export const control = (provided: CSSObjectWithLabel) =>
  ({
    ...provided,
    '&:hover': { borderColor: 'none', borderStyle: 'none' },
    borderColor: 'none',
    borderStyle: 'none',
    boxShadow: 'none',
  });

export const menu = (provided: CSSObjectWithLabel) =>
  ({
    ...provided,
    margin: 0,
    paddingInline: 16,
    boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.1)',
  });

export const message = (provided: CSSObjectWithLabel) =>
  ({
    ...provided,
    color: '#333333',
    fontWeight: 400,
    fontSize: '12px',
  });

export const placeholder = (provided: CSSObjectWithLabel) =>
  ({
    ...provided,
    color: '#96A0BC',
    fontWeight: 400,
    fontSize: '12px',
  });

export const indicatorsContainer = (provided: CSSObjectWithLabel) =>
  ({
    ...provided,
    '&:hover': { color: '#96A0BC' },
  });

export default classes;
