import React from 'react';

import {
  IAdvertisementPlatformData,
  IAllLanguages,
  MiscType,
} from '@pharmaplan/common';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import { ReactComponent as UploadIcon } from '../../../../assets/svg/uploadCloud.svg';
import { Constants } from '../../../../helpers/Constants';
import strings from '../../../../localization';
import styles from './styles';

const { text, dropdown, uploadIconLabel } = Constants.formInputType;

interface IAdvertisingForm {
  nameOfImage: string;
  onClickAll: (clickedItem: string) => void;
  showPictureUploadModal: () => void;
  languages: IAllLanguages[];
  platformTypes: Array<
    IAdvertisementPlatformData | { key: MiscType; value: string }
  >;
}

export const advertisingForm = ({
  nameOfImage,
  showPictureUploadModal,
  languages,
  onClickAll,
  platformTypes,
}: IAdvertisingForm) =>
  [
    {
      xs: 3,
      key: '1',
      name: 'name',
      label: strings.name,
      type: text,
      requireLabel: true,
      placeholder: strings.formatString(strings.enterCred, strings.name),
    },
    {
      xs: 3,
      key: '2',
      name: 'platforms',
      label: strings.platform,
      requireLabel: true,
      multiple: true,
      onClick: onClickAll,
      type: dropdown,
      menuItems: dropdownMapper(platformTypes, 'key', 'value'),
      placeholder: strings.formatString(strings.selectUser, strings.platform),
    },
    {
      xs: 3,
      key: '3',
      name: 'languageId',
      label: strings.language,
      type: dropdown,
      requireLabel: true,
      menuItems: dropdownMapper(languages, 'languageId', 'name'),
      placeholder: strings.formatString(strings.selectUser, strings.language),
    },
    {
      xs: 3,
      key: '4',
      name: 'navigateUrl',
      label: strings.url.toUpperCase(),
      type: text,
      placeholder: strings.formatString(
        strings.enterCred,
        strings.url.toUpperCase(),
      ),
    },
    {
      key: '5',
      uploadLabel: nameOfImage,
      label: strings.uploadFile,
      variant: Constants.variant.outlined,
      type: uploadIconLabel,
      customClass: styles.uploadButton(nameOfImage),
      startIcon: <UploadIcon />,
      name: 'imageName',
      xs: 3,
      onClick: showPictureUploadModal,
    },
  ];
