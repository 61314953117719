import { SxProps, Theme } from '@mui/system';

const classes = {
  text: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    fontWeight: '500',
    fontSize: 18,
    color: 'grey.800',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
