const classes = {
  dayCell: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '13px',
    paddingTop: '6px',
  },
};

export default classes;
