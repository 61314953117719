import React, { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';

import { ReactComponent as MenuIcon } from '@pharmaplan/common/assets/icons/menuIcon.svg';
import { ChipType, SkeletonVariants } from '../../../helpers/Constants';

import styles from './styles';
import CustomMenuList from '../CustomMenuList';
import CustomChip from '../CustomChip';
import withSkeleton from '../WithSkeleton/WithSkeleton';
import OptimizedAvatar from '../OptimizedAvatar/OptimizedAvatar';

export interface IMenuActions {
  key: string;
  onClick: () => void;
  label: string | string[];
}

type GenericStyle = Record<string, unknown>;

interface ICustomAvatarSection {
  config: Array<{ key: string; value: string | React.ReactNode }>;
  title: string;
  menuActions: Array<IMenuActions> | null;
  successAction: string;
  avatar?: string | null;
  hourlyRate?: number;
  configStyle?: GenericStyle;
  customFooter?: React.ReactNode;
  customContainerStyle?: GenericStyle;
  customMenuContainerStyle?: GenericStyle;
  customDetailContainerStyle?: GenericStyle;
  customTitleStyle?: GenericStyle;
  avatarSize?: number;
  imageSuccessAction?: string;
  favorite?: boolean;
  showFullImage?: boolean;
  onClick?: (e: any) => void;
}

const CustomAvatarSection: FC<ICustomAvatarSection> = ({
  config,
  title,
  avatar,
  hourlyRate,
  menuActions,
  successAction,
  customContainerStyle,
  customDetailContainerStyle,
  configStyle,
  customMenuContainerStyle,
  customTitleStyle,
  customFooter,
  imageSuccessAction,
  favorite,
  avatarSize = 60,
  showFullImage = true,
  onClick,
}) => {
  const SkeletonTypography = withSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const style = styles(avatarSize);

  return (
    <Box sx={[style.root, customContainerStyle ?? {}]}>
      <Box sx={style.container}>
        <Box
          component={onClick ? Button : 'span'}
          onClick={onClick}
          sx={[style.container, style.subContainer, onClick ? style.hoverlessButton : {}]}
        >
          <OptimizedAvatar
            successAction={imageSuccessAction}
            avatarSize={avatarSize}
            src={avatar}
            favorite={favorite}
            showFullImage={showFullImage}
          />

          <Box
            sx={[
              customDetailContainerStyle ?? style.detailsContainer,
              style.endContainer,
            ]}
          >
            <SkeletonTypography sx={customTitleStyle ?? style.title}>
              {title}
            </SkeletonTypography>
            {config.map((item) =>
              (
                <SkeletonTypography
                  key={item.key}
                  sx={[style.subtitles, configStyle] as SxProps}
                >
                  {item.value}
                </SkeletonTypography>
              ))}
          </Box>
        </Box>
        <Box sx={style.endContainer}>
          {menuActions && (
            <Box sx={customMenuContainerStyle ?? style.menuContainer}>
              <CustomMenuList
                margin={10}
                disableFixedDropdown
                customMenuTextStyle={style.menuText}
                customIconButtonStyle={style.button}
                items={menuActions}
              >
                <MenuIcon height={24} width={24} />
              </CustomMenuList>
            </Box>
          )}
          {!!hourlyRate && (
            <CustomChip
              label={`$${hourlyRate}/Hr`}
              type={ChipType.hourlyRate}
            />
          )}
        </Box>
      </Box>
      {!!customFooter && <Box sx={style.footerDivider}>{customFooter}</Box>}
    </Box>
  );
};
export default CustomAvatarSection;
