export const defaultValues = {
  center: {
    lat: 45.5019,
    lng: -73.5674,
  },
  zoom: 12,
};

export function truncateDecimals(num: number, digits: number) {
  const numS = num.toString();
  const decPos = numS.indexOf('.');
  const substrLength = decPos === -1 ? numS.length : 1 + decPos + digits;
  const trimmedResult = numS.substring(0, substrLength);
  const finalResult = Number.isNaN(trimmedResult as unknown as number)
    ? 0
    : trimmedResult;

  return parseFloat(finalResult as string);
}
