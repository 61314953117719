import React, { FC } from 'react';
import { ReactComponent as ContactIcon } from '@pharmaplan/common/assets/icons/contactIcon.svg';
import { ReactComponent as AddressIcon } from '@pharmaplan/common/assets/icons/addressIcon.svg';
import { pharmacistEvent } from '@pharmaplan/common';
import { Box, Typography } from '@mui/material';
import strings from '../../../../localization';
import ThemeConstants from '../../../../theme/ThemeConstants';
import CustomButton from '../../../common/CustomButton';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import classes from './styles';
import WithSkeleton from '../../../common/WithSkeleton';
import { Constants } from '../../../../helpers/Constants';
import { IContactDetails } from '../ContactDetails';

const {
  variant: { text },
} = Constants;

const ContactContent: FC<IContactDetails> = ({ successAction }) => {
  const event = useAppSelector(pharmacistEvent);

  const {
    phone,
    city,
    province,
    postalCode,
    region,
    address,
    longitude,
    latitude,
  } = event ?? {};

  const fullAddress = `${address}, ${city}, ${province}, ${postalCode}, ${region} `;

  const contactDetailsConfig = [
    {
      key: '0',
      title: strings.contact,
      value: phone,
      icon: ContactIcon,
    },
  ];

  const mapURL = `http://www.google.com/maps/place/${latitude},${longitude}`;

  const openMap = () => {
    window.open(mapURL, '_blank');
  };

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: 'text',
  })(Typography);

  return (
    <>
      <Box sx={classes.container}>
        {contactDetailsConfig.map((item) =>
          (
            <Box key={item.key} sx={classes.iconRow}>
              <item.icon fill={ThemeConstants.grey[800]} />
              <Box sx={classes.infoColumn}>
                <SkeletonTypography sx={classes.title}>
                  {item.title}
                </SkeletonTypography>
                <SkeletonTypography sx={classes.value}>
                  {item.value}
                </SkeletonTypography>
              </Box>
            </Box>
          ))}
      </Box>

      <Box sx={classes.addressContainer}>
        <AddressIcon fill={ThemeConstants.grey[800]} />
        <SkeletonTypography sx={classes.address}>
          {fullAddress}
        </SkeletonTypography>
      </Box>

      <CustomButton
        customClass={classes.buttonContainer}
        customButtonStyle={classes.button}
        variant={text}
        label={strings.viewPharmacyLocation}
        onClick={openMap}
      />
    </>
  );
};

export default ContactContent;
