const classes = {
  closeIcon: {
    color: 'common.grey350',
    backgroundColor: 'common.lightBackgroundColor',
    padding: '3px',
  },
  modalHeading: {
    color: 'grey.500',
    fontSize: '1.25rem',
    fontWeight: '400',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default classes;
