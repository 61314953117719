import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  adminActions,
  adminGetListOfPharmacies,
  adminGetListOfPharmaciesPagination,
  AdminLegendVariant,
  adminListOfPharmacies,
  getPharmacistDetails,
  ITEMS_PER_PAGE,
  successSelector,
} from '@pharmaplan/common';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { renderScreen, setFooterShow } from '../../../../actions/drawerActions';
import { ScreenTypes } from '../../../../helpers/Constants';
import useListOfPharmacies from '../../../../hooks/PharmacistList/useListOfPharmacies';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useGetItems from '../../../../hooks/useGetItems';
import strings from '../../../../localization';
import CustomAvatarSection from '../../../common/CustomAvatarSection';
import { LocationText } from '../../AvailableWorkshifts/AvailableWorkshifts';
import AdminPharmacistCard from '../../Profile/Pharmacist/PharmacistViewProfile/AdminPharmacistCard';
import styles from './styles';
import NoAvailabilities from '../../MatchingAvailabilities/NoAvailabilities';
import genericClasses from '../../../../theme/GenericClasses';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';

interface IListOfPharmacies {
  pharmacistId: string;
}

const successAction = adminActions.listOfPharmacies;
const ListOfPharmacies: FC<IListOfPharmacies> = ({ pharmacistId }) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const { menuAction } = useListOfPharmacies();

  const listOfPharmacies = useAppSelector(adminListOfPharmacies);

  const loadSuccess = useAppSelector((state) =>
    successSelector([successAction], state));

  const { data } = listOfPharmacies ?? {};
  const { getImage } = useGetItems();

  const observerCallback = useCallback(
    (entries: { isIntersecting: any }[]) => {
      const paginationCondition = data.length >= page * ITEMS_PER_PAGE;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    [page, data],
  );

  const { listEndRef } = useInfiniteScroll({ observerCallback });

  useEffect(() => {
    dispatch(
      adminGetListOfPharmacies({
        paging: {
          page: 1,
        },
        pharmacistId,
      }),
    );
    dispatch(getPharmacistDetails(pharmacistId));
    dispatch(setFooterShow(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        adminGetListOfPharmaciesPagination({
          paging: {
            page,
          },
          pharmacistId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const rowConfig = (replacements: string, hours: string, city: string) =>
    [
      {
        key: '1',
        value: `${strings.totalReplacements}: ${replacements}`,
      },
      {
        key: '2',
        value: `${strings.totalHours}: ${hours}`,
      },
      {
        key: '3',
        value: <LocationText label={city} />,
      },
    ];

  const back = () => {
    dispatch(
      renderScreen({
        screenNumber: 8,
        screenType: ScreenTypes.pharmacistList,
        pharmacistId,
      }),
    );
  };

  return (
    <Box>
      <AdminPharmacistCard
        pharmacistId={pharmacistId}
        type={AdminLegendVariant.posted}
        ratingGoBack={back}
      />
      <Typography sx={styles.title}>{strings.pharmacyList}</Typography>
      {data.length === 0 ? (
        <NoAvailabilities message={strings.noDataAvailable} />
      ) : data.map((item) => {
        const {
          pharmacyName,
          pharmacyId,
          location,
          logoId,
          favourite,
          totalHours,
          totalWorkshift,
        } = item;
        return (
          <Box key={pharmacyId} sx={styles.itemContainer}>
            <CustomAvatarSection
              customMenuContainerStyle={styles.menuContainer}
              imageSuccessAction={successAction}
              config={rowConfig(totalWorkshift, totalHours, location)}
              favorite={favourite}
              avatar={getImage(logoId)}
              title={pharmacyName}
              menuActions={menuAction({ pharmacistId, pharmacyId })}
              successAction={successAction}
              showFullImage={false}
            />
          </Box>
        );
      })}
      {loadSuccess && (
      <div ref={listEndRef} style={genericClasses.paginationDiv} />
      )}
    </Box>
  );
};

export default ListOfPharmacies;
