import React, { FC, useEffect, useMemo } from 'react';

import {
  adminActiveUserStatistics,
  adminWorkshiftStatistics,
  getWorkshiftStatistics,
} from '@pharmaplan/common';

import { PharmacistActionsBasedStatKey, PharmacyActionsBasedStatKey } from '../../../../helpers/Constants';
import ChartsContainer from '../../../common/Charts/ChartsContainer';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import ChartBuilder from '../../../common/Charts/ChartBuilder';
import { mergeDatasets } from '../../../../helpers/Functions';
import ChartLayout from '../../../common/Charts/ChartLayout';
import strings from '../../../../localization';

import { activeUserLoginStatistics, statsBasedOnActions } from './helper';

interface IActiveUserStatistics {
  start:string;
  end:string;
}

const ActiveUserStatistics:FC<IActiveUserStatistics> = ({ start, end }) => {
  const dispatch = useAppDispatch();

  const activeUserStats = useAppSelector(adminActiveUserStatistics);
  const { activePharmacies, activePharmacists } = activeUserStats ?? {};

  const workshiftActionStats = useAppSelector(adminWorkshiftStatistics);
  const {
    createWorkshift,
    updateWorkshift,
    deleteWorkshift,
    updatePharmacy,
    createAvailability,
    updateAvailability,
    requestedByPharmacist,
    updatePharmacist,
  } = workshiftActionStats ?? {};

  const pharmacyMerged = useMemo(
    () =>
      mergeDatasets(
        [createWorkshift, updateWorkshift, deleteWorkshift, updatePharmacy],
        [
          PharmacyActionsBasedStatKey.createWorkshift,
          PharmacyActionsBasedStatKey.updateWorkshift,
          PharmacyActionsBasedStatKey.deleteWorkshift,
          PharmacyActionsBasedStatKey.updatePharmacy,
        ],
      ),
    [createWorkshift, deleteWorkshift, updatePharmacy, updateWorkshift],
  );

  const pharmacistMerged = useMemo(
    () =>
      mergeDatasets(
        [
          createAvailability,
          updateAvailability,
          requestedByPharmacist,
          updatePharmacist,
        ],
        [
          PharmacistActionsBasedStatKey.createAvailability,
          PharmacistActionsBasedStatKey.updateAvailability,
          PharmacistActionsBasedStatKey.requestBooking,
          PharmacistActionsBasedStatKey.updatePharmacist,
        ],
      ),
    [
      createAvailability,
      requestedByPharmacist,
      updateAvailability,
      updatePharmacist,
    ],
  );

  useEffect(() => {
    dispatch(
      getWorkshiftStatistics({
        startDate: start,
        endDate: end,
      }),
    );
  }, [start, end]);

  return (
    <ChartLayout title={strings.activeUserStatistics}>
      <ChartsContainer title={strings.activeUsersBasedOnLogin}>
        <ChartBuilder
          config={activeUserLoginStatistics(
            activePharmacies,
            activePharmacists,
          )}
        />
      </ChartsContainer>
      <ChartsContainer title={strings.activeUsersBasedOnActions}>
        <ChartBuilder
          config={statsBasedOnActions(pharmacyMerged, pharmacistMerged)}
        />
      </ChartsContainer>
    </ChartLayout>
  );
};

export default ActiveUserStatistics;
