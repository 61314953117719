import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  adminActions,
  getPharmacyDetails,
  PermissionsOfAdmin,
  pharmacyDetails,
} from '@pharmaplan/common';
import { IFromScreen } from '../../../actions/drawerActions';
import { OtherScreens } from '../../../helpers/Constants';
import usePharmacyProfileViewDetails from '../../../hooks/Admin/Profile/usePharmacyViewDetails';
import { useAppSelector } from '../../../hooks/useAppSelector';
import CustomAvatarSection from '../CustomAvatarSection';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';

import AdminPharmacyCardFooter from './AdminPharmacyCardFooter';
import styles from './styles';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useGetItems from '../../../hooks/useGetItems';
import { LocationText } from '../../Admin/AvailableWorkshifts/AvailableWorkshifts';

interface IAdminPharmacyCard {
  pharmacyId: string;
  avatar?: string;
  fromScreen?: IFromScreen;
  hasOwner?: boolean;
  back?: () => void;
  customFooter?: React.ReactNode;
}

const AdminPharmacyCard: FC<IAdminPharmacyCard> = ({
  pharmacyId,
  avatar,
  hasOwner,
  fromScreen,
  back,
  customFooter,
}) => {
  const dispatch = useAppDispatch();
  const { actions } = usePharmacyProfileViewDetails(fromScreen, hasOwner, back);
  const { can } = useAdminPermissions();
  const { getSoftwareName } = useGetItems();

  const details = useAppSelector(pharmacyDetails);

  const canChangeParameters = can(PermissionsOfAdmin.ChangeParameters);

  const { pharmacy, softwares } = details ?? {};
  const { name, code, city, softwareId } = pharmacy ?? {};

  const config = [
    {
      key: '0',
      value: <Typography sx={styles.codeFont}>{code}</Typography>,
    },
    {
      key: '1',
      value: (
        <Typography sx={styles.codeFont}>
          {getSoftwareName(softwares, softwareId)}
        </Typography>
      ),
    },
    {
      key: '2',
      value: <LocationText label={city} />,
    },
  ];

  const noActionsScreens = [
    OtherScreens.AdminReports,
    OtherScreens.DeletedPendingUsers,
    OtherScreens.AdminNotes,
    OtherScreens.BroadcastGroups,
  ];

  const noActionsScreen = noActionsScreens.includes(fromScreen as OtherScreens);

  const isDeletedPending = fromScreen === OtherScreens.DeletedPendingUsers;
  const isDeletedPharmacyList = fromScreen === OtherScreens.DeletedPharmacyList;
  const isAdminNotes = fromScreen === OtherScreens.AdminNotes;

  const isDeletedList = isDeletedPending || isDeletedPharmacyList;

  const successAction = isDeletedList
    ? adminActions.deletedUserProfile
    : adminActions.getPharmacyDetails;

  useEffect(() => {
    if (pharmacyId && isAdminNotes) {
      dispatch(getPharmacyDetails(pharmacyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.container}>
      <CustomAvatarSection
        customContainerStyle={styles.avatarSection}
        successAction={successAction}
        avatar={avatar}
        config={config}
        imageSuccessAction={successAction}
        title={name}
        customTitleStyle={styles.title}
        avatarSize={75}
        configStyle={styles.config}
        showFullImage={false}
        customMenuContainerStyle={styles.menuContainer}
        customDetailContainerStyle={styles.detailContainer}
        menuActions={
          noActionsScreen || !canChangeParameters
            ? null
            : actions(pharmacyId, name)
        }
        customFooter={
          customFooter ?? (
            <AdminPharmacyCardFooter
              pharmacyId={pharmacyId}
              hasOwner={hasOwner}
              fromScreen={fromScreen}
              successAction={successAction}
            />
          )
        }
      />
    </Box>
  );
};

export default AdminPharmacyCard;
