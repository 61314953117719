import { createAction } from '@reduxjs/toolkit';
import { IIncomeFilter, IReportFilters } from '../reducers/reportsReducer';
import { IBookingsRequested } from '../models';

export const reportActions = {
  updateFilter: 'reports/filter/update',
  resetFilter: 'reports/filter/reset',
  setSortBy: 'set/sortBy',
  updateIncomeFilter: 'reports/incomeFilter/update',
};

export const updateReportFilter = createAction<IReportFilters>(
  reportActions.updateFilter
  );

  export const updateIncomeFilter = createAction<IIncomeFilter>(
    reportActions.updateIncomeFilter
    );

  export const setSortBy = createAction<keyof IBookingsRequested>(
    reportActions.setSortBy
  );

export const resetFilter = createAction(reportActions.resetFilter);

