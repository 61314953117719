import * as Yup from 'yup';
import strings from '../../../../../localization';
import {
  nameRegex,
  onlyNumbersRegex,
  postalCodeRegExp,
} from '../../../../../helpers/Constants';

const PharmacistEditProfileValidator = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.alphaOnly),
    lastName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.alphaOnly),
    preferdLanguage: Yup.string().required(strings.requiredField),
    licenseNumber: Yup.string()
      .matches(
        onlyNumbersRegex,
        strings.formatString(
          strings.numberValidation,
          strings.licenseNo,
        ) as string,
      )
      .required(strings.requiredField),
    pharmacistCapacityId: Yup.string().required(strings.requiredField),
    graduationYear: Yup.string().required(strings.requiredField),
    otherLanguages: Yup.string().nullable(),
    province: Yup.string().required(strings.requiredField),
    address: Yup.string().required(strings.requiredField),
    city: Yup.string().required(strings.requiredField),
    postalCode: Yup.string()
      .required(strings.requiredField)
      .matches(postalCodeRegExp, strings.postalCodeValidation),
    phone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.homePhone,
        ) as string,
      ),
    mobile: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      ),
    latitude: Yup.string().nullable().required(strings.requiredField),
  });

export default PharmacistEditProfileValidator;
