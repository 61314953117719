import React, { FC } from 'react';

import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import {
  AdminLegendVariant,
  IAdminRequestData,
  adminActions,
  isLoadingSelector,
} from '@pharmaplan/common';
import strings from '../../../../localization';
import PharmacyCard from '../../../SelfService/PharmacyCard';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { renderScreen } from '../../../../actions/drawerActions';
import { ScreenTypes, SkeletonVariants } from '../../../../helpers/Constants';
import { ReactComponent as RequestBookingIcon } from '../../../../assets/svg/requestBookingIcon.svg';
import useGetItems from '../../../../hooks/useGetItems';
import { LocationText } from '../../AvailableWorkshifts/AvailableWorkshifts';
import WithSkeleton from '../../../common/WithSkeleton';

import styles from './styles';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import AdminHourlyRateFooter from '../../../common/AdminHourlyRateFooter';

interface ISingleRequest {
  event: IAdminRequestData;
  successAction: string;
}

const SingleRequest: FC<ISingleRequest> = ({ event, successAction }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) =>
    isLoadingSelector([successAction], state));

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const {
    workshiftId,
    pharmacyHourlyRate: hourlyRate,
    count,
    pharmacyId,
    logoId,
    city,
    softwareName,
    startDate,
  } = event ?? {};

  const { getImage } = useGetItems();

  const goToPharmacistRequestedList = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.requestedList,
        eventId: workshiftId,
      }),
    );
  };

  const goToRequestedList = () => {
    dispatch(
      renderScreen({
        screenNumber: 1,
        screenType: ScreenTypes.requestedList,
        date: startDate,
      }),
    );
  };

  const goToViewDetails = () => {
    dispatch(
      renderScreen({
        screenNumber: 3,
        screenType: ScreenTypes.availableWorkshift,
        eventId: workshiftId,
        type: AdminLegendVariant.requested,
        back: goToRequestedList,
        pharmacyId,
      }),
    );
  };

  return (
    <Box sx={styles.mainContainer}>
      <PharmacyCard
        customContainerStyle={styles.container}
        successAction={adminActions.getAdminEvent}
        customTitleStyle={styles.title}
        showPharmacyName
        contentClick={goToViewDetails}
        CustomContent={(
          <LocationText
            containerStyle={styles.footerContainer}
            label={
              <SkeletonTypography sx={styles.footer}>{city}</SkeletonTypography>
            }
          />
        )}
        event={{ ...event, hourlyRate, logoImage: getImage(logoId ?? '') }}
        CustomFooter={(
          <AdminHourlyRateFooter
            pharmacyHourlyRate={hourlyRate}
            software={softwareName}
            successAction={successAction}
          />
        )}
        type={AdminLegendVariant.requested}
      />
      <IconButton
        onClick={goToPharmacistRequestedList}
        sx={styles.iconContainer}
      >
        <Box sx={styles.iconSubContainer}>
          {!loading && count && (
            <Box sx={styles.countContainer}>
              <Typography sx={styles.countText}>
                +
                {count}
              </Typography>
            </Box>
          )}
          <Tooltip title={strings.bookingRequests}>
            <RequestBookingIcon />
          </Tooltip>
        </Box>
      </IconButton>
    </Box>
  );
};

export default SingleRequest;
