import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IProtectedCustomRoute {
  allow: boolean;
  route: string;
}

const ProtectedCustomRoute = ({ allow, route }: IProtectedCustomRoute) =>
  (allow ? <Outlet /> : <Navigate to={route} replace />);

export default ProtectedCustomRoute;
