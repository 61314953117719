import React, { FC, memo, useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { INotificationsData } from '@pharmaplan/common/models/NotificationsModel';
import AvatarFallback from '@pharmaplan/common/assets/icons/avatarPlaceholder.png';
import { ReactComponent as CalendarIcon } from '@pharmaplan/common/assets/icons/calendarIconNew.svg';
import {
  NotificationGroups,
  NotificationIdMap,
  NotificationsMap,
  PositiveIcons,
  ToUserTypings,
  TypeOfUser,
  getNotification,
  getTypeOfUser,
  logos,
  showConfirmFor,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import { ReactComponent as GreenTick } from '../../../../assets/svg/filledGreenTick.svg';
import { ReactComponent as Ticker } from '../../../../assets/svg/ticker.svg';
import { ReactComponent as NotAssignedIcon } from '../../../../assets/svg/notAssignedIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/deleteIcon.svg';
import { ReactComponent as UpdateIcon } from '../../../../assets/svg/updateIcon.svg';
import ThemeConstants from '../../../../theme/ThemeConstants';
import {
  formatDate,
  getDayMonthDateYear,
  getHourMinute,
} from '../../../Reports/helpers';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { timeString } from '../../../../helpers/Functions';
import strings from '../../../../localization';

import NotificationHeader from './NotificationHeader/NotificationHeader';
import classes from './styles';
import useNotification from '../../../../hooks/useNotification';
import NotificationActions from './NotificationActions';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { showError } from '../../../Admin/Profile/Pharmacist/PharmacistViewProfile/helper';

interface INotificationItem {
  item: INotificationsData;
  handleDeleteNotif: (notificationId: string) => void;
  closeNotification: () => void;
}

type styleProperty = Record<string, SxProps<Theme> | string | number>;

interface INotificationContent {
  key: number;
  icon: React.JSX.Element;
  info: string;
  infoStyle: styleProperty;
  containerStyle: Array<styleProperty> | styleProperty;
  iconStyle?: styleProperty;
}

const { pharmacist, pharmacy } = TypeOfUser;
const { positive, update, delete: deleteGroup } = NotificationGroups;
const { tick, cancel } = PositiveIcons;

const NotificationItem: FC<INotificationItem> = ({
  item,
  handleDeleteNotif,
  closeNotification,
}) => {
  const dispatch = useAppDispatch();

  const allLogos = useAppSelector(logos);
  const userType = useAppSelector(getTypeOfUser);
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const [visited, setVisited] = useState(false);

  const { handleActions } = useNotification(closeNotification);

  const { grey, eventChip, primary } = ThemeConstants;

  const {
    container,
    secondaryText,
    dateText,
    confirmContainer,
    confirmText,
    notVisited,
    secondaryContainer,
    shadowStyle,
    alignCenter,
    secondaryIconContainer,
    itemContainer,
  } = classes;

  const {
    notificationId,
    profilePicture,
    text,
    notificationDate,
    notificationType,
    isVisited,
    header,
    confirmBefore,
    startDate,
    endDate,
  } = item ?? {};

  useEffect(() => {
    setVisited(isVisited);
  }, [isVisited]);

  const { iconType } = NotificationIdMap[notificationType] ?? {};

  const isTick = iconType === tick;
  const isCancel = iconType === cancel;

  const getLogo = (event: INotificationsData) => {
    const { logoId: eventLogoId } = event;
    const { image } = allLogos.find(({ logoId }) =>
      logoId === eventLogoId) ?? {};
    return image;
  };

  const notificationAge = formatDate(notificationDate, '', true);

  const dateTime = `${getDayMonthDateYear(startDate)} | ${timeString(
    startDate,
    endDate,
    timeFormat,
  )}`;

  const confirmByDateTime = `${getDayMonthDateYear(
    confirmBefore ?? '',
  )} | ${getHourMinute(confirmBefore ?? '', timeFormat)} EST`;

  const userBasedLogos = ToUserTypings({
    [pharmacist]: getLogo(item),
    [pharmacy]: profilePicture,
  });

  const logo = userBasedLogos[userType];

  const handleDrawer = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    handleActions(item);
    e.stopPropagation();
  };

  const handleNegativeActionsDrawer = () => {
    showError(dispatch, strings.actionCannotBePerformed);
    dispatch(getNotification(notificationId));
  };

  const positiveIconHandler = () => {
    if (isTick) {
      return <GreenTick fill={eventChip.requestedText} />;
    }

    if (isCancel) {
      return <NotAssignedIcon />;
    }

    return <GreenTick fill={primary.main} />;
  };

  const handleGroups = () => {
    const notificationGroup = NotificationsMap[notificationType];

    switch (notificationGroup) {
      case positive:
        return {
          icon: positiveIconHandler(),
          action: handleDrawer,
        };

      case deleteGroup:
        return {
          icon: <DeleteIcon />,
          action: handleNegativeActionsDrawer,
        };

      case update:
        return {
          icon: <UpdateIcon />,
          action: handleDrawer,
        };

      default:
        return {
          icon: <NotAssignedIcon />,
          action: handleNegativeActionsDrawer,
        };
    }
  };

  const { icon: handledIcon, action: handledAction } = handleGroups();
  const includesConfirmBefore = showConfirmFor.includes(notificationType);

  const notificationContent: Array<INotificationContent> = [
    {
      key: 0,
      icon: handledIcon,
      info: text,
      infoStyle: secondaryText,
      containerStyle: secondaryContainer,
      iconStyle: secondaryIconContainer,
    },
    {
      key: 1,
      icon: <CalendarIcon fill={grey[500]} />,
      info: dateTime,
      infoStyle: dateText,
      containerStyle: [secondaryContainer, alignCenter],
    },
  ];

  const confirmBeforeObject = {
    key: 2,
    icon: (
      <Box sx={classes.ticker}>
        <Ticker />
      </Box>
    ),
    info: strings.formatString(strings.confirmBy, confirmByDateTime) as string,
    infoStyle: confirmText,
    containerStyle: [confirmContainer],
  };

  if (includesConfirmBefore) {
    notificationContent.push(confirmBeforeObject);
  }

  const notificationContentMapper = () =>
    notificationContent.map((content, index) => {
      const { key, icon, info, infoStyle, containerStyle, iconStyle } = content ?? {};
      const lastElement = notificationContent.length - 1;

      return (
        <Box key={key} sx={itemContainer}>
          <Box sx={containerStyle}>
            <Box display="flex" sx={iconStyle}>
              {icon}
            </Box>
            <Typography sx={infoStyle}>{info}</Typography>
          </Box>
          {index === lastElement && (
            <NotificationActions
              visited={visited}
              setVisited={setVisited}
              notification={item}
              handleDeleteNotif={handleDeleteNotif}
            />
          )}
        </Box>
      );
    });

  return (
    <ListItem
      sx={[container, shadowStyle, !visited ? notVisited : {}]}
      alignItems="flex-start"
      onClick={handledAction}
    >
      <ListItemAvatar>
        <Avatar
          alt="Avatar"
          sx={classes.image}
          imgProps={{ style: classes.image }}
          src={logo ?? AvatarFallback}
        />
      </ListItemAvatar>

      <ListItemText
        primary={(
          <NotificationHeader
            header={header}
            notificationAge={notificationAge as string}
          />
        )}
        secondary={notificationContentMapper()}
      />
    </ListItem>
  );
};

export default memo(NotificationItem);
