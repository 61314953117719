import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import styles from '../styles';

import { AdminNotesType, TextFieldVariants } from '../../../../helpers/Constants';
import useUpdateAdminNotes from '../../../../hooks/Admin/AdminNotes/useUpdateAdminNotes';
import CustomTextField from '../../../common/CustomTextField';
import strings from '../../../../localization';
import CustomButton from '../../../common/CustomButton';

interface IAdminUpdateNote {
  noteId: string;
  id: string;
  notesType: AdminNotesType;
}

const AdminPharmacistUpdateNote: FC<IAdminUpdateNote> = ({
  noteId,
  id,
  notesType,
}) => {
  const { formik } = useUpdateAdminNotes(noteId, id, notesType);
  const { handleSubmit } = formik ?? {};

  return (
    <Grid container sx={styles.updateNoteContainer}>
      <Grid xs={4} item>
        <Typography>{strings.notes}</Typography>
      </Grid>
      <Grid xs={8} item>
        <CustomTextField
          formik={formik}
          name="note"
          id="note"
          variant={TextFieldVariants.filled}
          multiline
          horizontal
        />
      </Grid>
      <Grid
        container
        sx={[styles.notesSubmitContainer, styles.buttonContainer]}
      >
        <CustomButton label={strings.update} onClick={handleSubmit} />
      </Grid>
    </Grid>
  );
};

export default AdminPharmacistUpdateNote;
