import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = {
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    padding: 0,
    mb: '6px',
  },
  locationIcon: {
    marginRight: '5px',
  },
  menu: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  footerContainer: {
    backgroundColor: 'secondary.main',
    p: '12px 24px 24px 24px',
    borderTop: '1px solid',
    borderColor: 'common.border',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
