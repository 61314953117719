import { SxProps, Theme } from '@mui/system';

const styles = {
  tableContainer: {
    paddingX: '16px',
  },
  title: {
    paddingTop: '16px',
    paddingX: '16px',
    fontSize: 16,
    fontWeight: '600',
  },
  tableBox: {
    marginTop: '17px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
