import React, { FC, Fragment, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { FormikValues, useFormik } from 'formik';
import { Box } from '@mui/system';

import {
  adminGetAdvertisementFilters,
  advertisementFilters,
  getLanguages,
  Language,
  MiscType,
} from '@pharmaplan/common';

import { useAppSelector } from '../../../../hooks/useAppSelector';
import { formGenerator } from '../../../../helpers/formGenerator';

import useCreateEditAdvertising from '../../../../hooks/Admin/AdvertisingCampaigns/useCreateEditAdvertising';
import useAdvertisingSuccess from '../../../../hooks/Admin/AdvertisingCampaigns/useAdvertisingSuccess';
import { resetDialog, setDialog } from '../../../../reducers/dialogReducer';
import { BroadcastGroupActionType } from '../../../../helpers/Constants';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import CustomTableBack from '../../../common/CustomTableBack';
import CustomButton from '../../../common/CustomButton';
import strings from '../../../../localization';

import UploadAdvertisement from './UploadAdvertisement';
import AdvertisingPublish from './AdvertisingPublish';
import { advertisingForm } from './helper';
import validator from './validator';
import styles from './styles';
import FullScreenImage from '../../../common/FullScreenImage';

interface ICreateEditAdvertisingCampaigns {
  type: BroadcastGroupActionType;
}

const CreateEditAdvertisingCampaign: FC<ICreateEditAdvertisingCampaigns> = ({
  type,
}) => {
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);
  const [base64, setShowBase64] = useState('');

  const languages = useAppSelector(getLanguages);

  const filters = useAppSelector(advertisementFilters);
  const { platforms } = filters ?? {};

  useAdvertisingSuccess();

  const formattedLanguage = [
    {
      code: Language.en,
      default: false,
      languageId: MiscType.All,
      name: strings.all,
    },
    ...languages,
  ];

  const formattedPlatforms = [
    {
      key: MiscType.All,
      value: strings.all,
    },
    ...platforms,
  ];

  const { title, label, submit, initialValues } = useCreateEditAdvertising(type);

  const formik = useFormik({
    initialValues,
    onSubmit: submit,
    enableReinitialize: true,
    validationSchema: validator(),
  });

  const handleImageSubmit = (values: FormikValues) => {
    const { image, isImageChanged } = values ?? {};
    let form = null;

    if (image) {
      form = new FormData();
      form.append('image', image);
    }

    formik.setFieldValue('form', form);
    formik.setFieldValue('isImageChanged', isImageChanged);
    formik.setFieldValue('imageName', image.name);

    dispatch(resetDialog());
  };

  const showHideImage = (showHide:boolean) =>
    () => {
      setShow(showHide);
    };

  const handleImageSync = (image:string) => {
    setShowBase64(image);
  };

  const showUploadModal = () => {
    dispatch(
      setDialog({
        showCloseButton: true,
        Component: (
          <UploadAdvertisement
            existingImageName={formik.values.imageName}
            handleSubmit={handleImageSubmit}
            handleOpen={showHideImage(true)}
            syncImage={handleImageSync}
          />
        ),
        heading: {
          title: strings.uploadPicture,
        },
      }),
    );
  };

  const handleAllClick = () => {
    if (formik.values.platforms.includes(MiscType.All as any)) {
      setTimeout(() =>
        formik.setFieldValue('platforms', []), 0);
    } else {
      setTimeout(
        () =>
          formik.setFieldValue('platforms', [
            MiscType.All,
            ...platforms.map((item) =>
              item.key),
          ]),
        0,
      );
    }
  };

  const onClickAll = (clickedItem: string) => {
    if (clickedItem === MiscType.All) {
      handleAllClick();
    }
  };

  useEffect(() => {
    dispatch(adminGetAdvertisementFilters());
  }, []);

  console.log(base64);
  return (
    <>
      {show && <FullScreenImage image={base64 || initialValues.imageUrl} hideImage={showHideImage(false)} />}
      <Box sx={styles.container}>
        <CustomTableBack backLabel={strings.backToAdvertisingCampaigns} />
        <Typography sx={styles.title}>{title}</Typography>
        <Grid container xs={12} columnSpacing={4}>
          {advertisingForm({
            languages: formattedLanguage,
            nameOfImage: formik.values.imageName,
            showPictureUploadModal: showUploadModal,
            platformTypes: formattedPlatforms,
            onClickAll,
          }).map((item) => {
            const { key } = item;
            return <Fragment key={key}>{formGenerator(item, formik)}</Fragment>;
          })}
        </Grid>

        <AdvertisingPublish formik={formik} />

        <Box sx={styles.buttonContainer}>
          <CustomButton
            onClick={formik.handleSubmit}
            label={label}
            customButtonStyle={styles.button}
          />
        </Box>
      </Box>
    </>
  );
};

export default CreateEditAdvertisingCampaign;
