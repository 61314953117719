import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    margin: '0 0 38px 20px',
    padding: '1px 32px 16px 32px',
    backgroundColor: 'secondary.main',
    boxSizing: 'border-box',
    // width: '99%',
  },
  title: {
    fontWeight: '600',
    fontSize: 22,
    color: 'common.grey850',
    mb: '32px',
  },
  mainDropdown: {
    maxWidth: 320,
  },
  subTitleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    mb: '32px',
    mt: '28px',
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '600',
    mr: '12px',
    textTransform: 'uppercase',
  },
  divider: {
    flex: 1,
  },
  fieldsContainer: {
    mr: '100px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    mr: '100px',
  },
  emptyContainer: {
    backgroundColor: 'secondary.main',
    minHeight: '55vh',
  },
  emptyTitle: {
    color: 'footer.verticalLine',
    fontSize: 16,
    fontWeight: '700',
  },
  signatureContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginBottom: 2,
  },
  signatureLabelContainer: {
    flex: 2,
    color: 'grey.800',
    fontWeight: '500',
    fontSize: 14,
  },
  editorContainer: {
    flex: 4,
    overflow: 'auto',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
