import React, { FC } from 'react';

import { Box, Rating, Typography } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import styles from './style';

interface IAllLabelRatings {
  label: string;
  value: number;
  percentage?: number;
}

const AllLabelRatings: FC<IAllLabelRatings> = ({
  label,
  value,
  percentage,
}) => {
  const showPercentage = `${percentage}%`;
  const rating = (value / 100) * 5;

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.label}>{label}</Typography>
      <Box sx={styles.container}>
        <Rating
          name="all-ratings"
          sx={styles.icon}
          value={percentage ? rating : value}
          precision={0.5}
          readOnly
          emptyIcon={<StarBorderIcon sx={styles.emptyIcon} />}
        />
        {!!percentage && (
          <Typography sx={styles.percentage}>{showPercentage}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default AllLabelRatings;
