import { createReducer } from '@reduxjs/toolkit';
import {
  addAssignedChannels,
  adminCreateGroupChannel,
  createGroupChannel,
  logout,
  setAdminChannelProperties,
  setSelectedClient,
  setUnreadCount,
} from '../actions';
import { IAdminCreatedChannelProperties } from '../models/ChatModels';

export interface IChatReducerState {
  channelUrl: string;
  adminChannelUrl: string;
  clientUnreadCount: number;
  adminUnreadCount: number;
  assignedChannels: Array<string>;
  userUnreadCount: number;
  selectedClient: string;
  adminChannelProperties: IAdminCreatedChannelProperties;
}

export const chatInitialState: IChatReducerState = {
  channelUrl: '',
  adminChannelUrl: '',
  clientUnreadCount: 0,
  adminUnreadCount: 0,
  assignedChannels: [],
  userUnreadCount: 0,
  selectedClient: '',
  adminChannelProperties: {
    channelName: '',
    userType: '',
    personnelId: '',
  },
};

const chatReducer = createReducer(chatInitialState, (builder) =>
  builder
    .addCase(createGroupChannel.fulfilled, (state, action) => {
      state.channelUrl = action.payload;
    })
    .addCase(addAssignedChannels, (state, action) => {
      state.assignedChannels = action.payload;
    })
    .addCase(setUnreadCount, (state, action) => {
      state.userUnreadCount = action.payload;
    })
    .addCase(setSelectedClient, (state, action) => {
      state.selectedClient = action.payload;
    })
    .addCase(setAdminChannelProperties, (state, action) => {
      state.adminChannelProperties = action.payload;
    })
    .addCase(adminCreateGroupChannel.fulfilled, (state, action) => {
      state.adminChannelUrl = action.payload;
    })
    .addCase(logout.fulfilled, (state, action) => {
      return chatInitialState;
    })
);

export default chatReducer;
