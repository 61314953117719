import React, { useEffect } from 'react';

import {
  adminActions,
  adminDeactivatePharmacist,
  adminHidePharmacist,
  AdminLegendVariant,
  getActivePharmacistList,
  getPharmacistDetails,
  PermissionsOfAdmin,
  PermissionsRemovalKeys,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { useNavigate } from 'react-router-dom';
import { FormikValues } from 'formik';
import { renderScreen } from '../../actions/drawerActions';

import { IPharmacistListActionsParams } from '../../components/Admin/PharmacistList/PharmacistList';
import {
  AdminNotesType,
  Constants,
  DeactivateModalIcon,
  OtherScreens,
  ScreenTypes,
} from '../../helpers/Constants';

import strings from '../../localization';
import { setDialog } from '../../reducers/dialogReducer';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import DeactivateDialog from '../../components/Admin/PharmacistList/DeactivateDialog/DeactivateDialog';
import useAdminPermissions from '../Admin/useAdminPermissions';
import useDrawer from '../useDrawer';
import { setMultipleAddEvents, enableAddCalendarEvent } from '../../reducers/mainCalendarReducer';

const {
  hidePharmacist: hidePharmacistAction,
  deactivatePharmacist: deactivatePharmacistAction,
} = adminActions;

const {
  CreateAvailability,
  CreateMultipleAvailability,
  Edit,
  Deactivate,
  Hide,
} = PermissionsRemovalKeys;
const { createMultiple } = Constants.paths.admin;

const usePharmacistList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hideSuccess = useAppSelector((state) =>
    successSelector([hidePharmacistAction], state));
  const deactivatedSuccess = useAppSelector((state) =>
    successSelector([deactivatePharmacistAction], state));

  const { can } = useAdminPermissions();
  const { openDrawer } = useDrawer();

  const cannotActivate = !can(PermissionsOfAdmin.Activation);
  const cannotPost = !can(PermissionsOfAdmin.Posting);
  const cannotChangeParameters = !can(PermissionsOfAdmin.ChangeParameters);

  const deactivatePharmacist = (values: FormikValues) => {
    const { reason, id } = values ?? {};
    dispatch(adminDeactivatePharmacist({ pharmacistId: id, reason }));
  };

  const showCreateAvailabilityModal = ({ pharmacistId, pharmacistName }: IPharmacistListActionsParams) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 9,
          screenType: ScreenTypes.pharmacistList,
          pharmacistId,
          extraParameters: {
            name: pharmacistName,
          },
        }),
      );
    };

  const goToAdminNotes = (pharmacistId: string) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.pharmacistList,
          extraParameters: {
            notesType: AdminNotesType.pharmacist,
          },
          pharmacistId,
        }),
      );
    };

  const goToShowBookings = (pharmacistId: string) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 2,
          screenType: ScreenTypes.pharmacistList,
          pharmacistId,
        }),
      );
    };

  const goToShowAvailabilities = (pharmacistId: string) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 4,
          screenType: ScreenTypes.pharmacistList,
          pharmacistId,
        }),
      );
    };

  const goToEditPharmacist = (pharmacistId: string) =>
    () => {
      openDrawer();
      dispatch(getPharmacistDetails(pharmacistId));
      dispatch(
        renderScreen({
          screenNumber: 4,
          screenType: ScreenTypes.profile,
          pharmacistId,
          fromScreen: OtherScreens.PharmacistList,
          type: AdminLegendVariant.posted,
        }),
      );
    };

  const viewRatings = (pharmacistId: string) =>
    () => {
      navigate(Constants.paths.admin.viewRatings, {
        state: pharmacistId,
      });
    };

  useEffect(() => {
    if (hideSuccess) {
      dispatch(resetStatus([hidePharmacistAction]));
      dispatch(getActivePharmacistList({ page: 1 }));
      showSuccess(dispatch, strings.hiddenSuccessfuly);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideSuccess]);

  const hidePharmacist = (values:FormikValues) => {
    const { id, reason } = values ?? {};
    dispatch(adminHidePharmacist({ pharmacistId: id, reason }));
  };

  const showHidePharmacistDialog = (pharmacistId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              id={pharmacistId}
              onSubmit={hidePharmacist}
              iconType={DeactivateModalIcon.warning}
              confirmationMessage={strings.areYouSureHidePharmacist}
              heading={strings.hidePharmacist}
            />
          ),
          showCloseButton: true,
          heading: {
            title: '',
          },
        }),
      );
    };

  const showDeactivateDialog = (pharmacistId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              id={pharmacistId}
              onSubmit={deactivatePharmacist}
              iconType={DeactivateModalIcon.warning}
              confirmationMessage={strings.areYouSureDeactivatePharmacist}
              heading={strings.deactivatePharmacist}
            />
          ),
          showCloseButton: true,
          heading: {
            title: '',
          },
        }),
      );
    };

  const goToListOfPharmacies = (pharmacistId: string) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 8,
          screenType: ScreenTypes.pharmacistList,
          pharmacistId,
        }),
      );
    };

  const goToCreateMultipleAvailabilities = (pharmacistId: string) =>
    () => {
      dispatch(setMultipleAddEvents(true));
      dispatch(enableAddCalendarEvent(true));
      navigate(`${createMultiple}`, {
        state: { id: pharmacistId },
      });
    };

  const showCreateVacation = ({ pharmacistId, pharmacistName }: IPharmacistListActionsParams) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.createVacation,
          pharmacistId,
          extraParameters: {
            name: pharmacistName,
          },
        }),
      );
    };

  const actions = (params: IPharmacistListActionsParams) => {
    let arr = [
      {
        key: CreateAvailability,
        onClick: showCreateAvailabilityModal(params),
        label: `${strings.create} ${strings.availability}`,
      },
      {
        key: CreateMultipleAvailability,
        onClick: goToCreateMultipleAvailabilities(params.pharmacistId),
        label: strings.createMultipleAvailabilities,
      },
      {
        key: '3',
        onClick: goToShowAvailabilities(params.pharmacistId),
        label: strings.formatString(strings.view, strings.availabilities),
      },
      {
        key: '13',
        onClick: showCreateVacation(params),
        label: strings.createVacation,
      },
      {
        key: '4',
        onClick: goToShowBookings(params.pharmacistId),
        label: strings.formatString(strings.view, strings.booking),
      },
      {
        key: '5',
        onClick: goToListOfPharmacies(params.pharmacistId),
        label: strings.formatString(strings.view, strings.listOfPharmacies),
      },
      {
        key: '6',
        onClick: viewRatings(params.pharmacistId),
        label: strings.viewRating,
      },
      {
        key: '7',
        onClick: goToAdminNotes(params.pharmacistId),
        label: strings.adminNotes,
      },
      {
        key: Hide,
        onClick: showHidePharmacistDialog(params.pharmacistId),
        label: strings.hide,
      },
      {
        key: Deactivate,
        onClick: showDeactivateDialog(params.pharmacistId),
        label: strings.deactivate,
      },
      {
        key: Edit,
        onClick: goToEditPharmacist(params.pharmacistId),
        label: strings.editProfileOnly,
      },
    ];

    if (cannotChangeParameters) {
      arr = arr.filter((item) =>
        item.key !== Edit);
    }
    if (cannotActivate) {
      arr = arr.filter(
        (item) =>
          ![Hide, Deactivate].includes(item.key as PermissionsRemovalKeys),
      );
    }
    if (cannotPost) {
      arr = arr.filter(
        (item) =>
          ![CreateAvailability, CreateMultipleAvailability].includes(
            item.key as PermissionsRemovalKeys,
          ),
      );
    }

    return arr;
  };

  useEffect(() => {
    if (deactivatedSuccess) {
      dispatch(resetStatus([deactivatePharmacistAction]));
      dispatch(getActivePharmacistList({ page: 1 }));
      showSuccess(dispatch, strings.deactivatedSuccessfully);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivatedSuccess]);

  return { actions };
};

export default usePharmacistList;
