import { SxProps, Theme } from '@mui/system';

const classes = {
  rejectText: {
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: 400,
    paddingLeft: '4px',
  },

  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    marginTop: '6px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
