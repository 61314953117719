import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    alignItems: 'center',
  },
  addContainer: {
    maxWidth: 212,
    textAlign: 'center',
  },
  addButton: {
    maxWidth: 140,
    minWidth: 140,
    paddingHorizontal: 0,
  },

  positiveFeedback: {
    color: 'primary.main',
    fontSize: 12,
    fontWeight: '600',
  },
  errorFeedback: {
    fontSize: 12,
    color: 'common.counterRed',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
