import { RootState } from '../store';

export const selectDrawerState = (state: RootState) =>
  state.drawer;

export const selectOpen = (state: RootState) =>
  selectDrawerState(state).open;
export const selectBack = (state: RootState) =>
  selectDrawerState(state).back;
export const selectFooter = (state: RootState) =>
  selectDrawerState(state).footer;
export const selectTitle = (state: RootState) =>
  selectDrawerState(state).title;
export const selectContent = (state: RootState) =>
  selectDrawerState(state).content;
export const selectDrawerContentPadding = (state: RootState) =>
  selectDrawerState(state).drawerContentPadding;

// Selectors for Footer
export const selectFooterShow = (state: RootState) =>
  selectFooter(state).show;
export const selectFooterButtons = (state: RootState) =>
  selectFooter(state).buttons;
export const selectFooterPagination = (state: RootState) =>
  selectFooter(state).pagination;

// Selectors for Buttons in Footer
export const selectFooterButtonsShow = (state: RootState) =>
  selectFooterButtons(state).show;
export const selectFooterPrimaryButton = (state: RootState) =>
  selectFooterButtons(state).primaryButton;
export const selectFooterSecondaryButton = (state: RootState) =>
  selectFooterButtons(state).secondaryButton;

// Selectors for Pagination in Footer
export const selectFooterPaginationShow = (state: RootState) =>
  selectFooterPagination(state).show;
export const selectFooterPaginationTotal = (state: RootState) =>
  selectFooterPagination(state).total;
export const selectFooterPaginationCurrent = (state: RootState) =>
  state.drawer.current;
export const selectFooterPaginationNext = (state: RootState) =>
  selectFooterPagination(state).next;
export const selectFooterPaginationPrev = (state: RootState) =>
  selectFooterPagination(state).prev;
