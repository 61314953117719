import { SxProps, Theme } from '@mui/system';

const classes = {
  popupPaper: {
    width: '400px',
  },

  removeTitleMargin: {
    '&.MuiTypography-root': {
      marginBottom: 0,
    },
  },
  popoverContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '80vh',
  },

  scrollableContent: {
    flexGrow: 1,
    overflowY: 'auto',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
