import { SxProps, Theme } from '@mui/system';

const classes = {
  root: {
    borderRadius: '10px',
    backgroundColor: 'white',
    marginBottom: '16px',
    paddingX: '23px',
    paddingY: '18px',
  },

  container: {
    maxHeight: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  titleFont: {
    color: 'grey.800',
    fontSize: 16,
    fontWeight: 600,
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
