import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import styles from './styles';

interface INumberLabel {
  value: number | string;
  label: string;
}

const NumberLabel: FC<INumberLabel> = ({ value, label }) =>
  (
    <Box>
      <Typography sx={styles.valueStyle}>{value}</Typography>
      <Typography sx={styles.labelStyle}>{label}</Typography>
    </Box>
  );

export default NumberLabel;
