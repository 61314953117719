import { SxProps, Theme } from '@mui/system';

const size = 32;

const styles = {
  container: {
    display: 'flex',
    ml: '20px',
    alignItems: 'center',
  },
  image: {
    height: size,
    width: size,
    borderRadius: size / 2,
    objectFit: 'contain',
  },
  label: {
    m: '12px',
    fontSize: 14,
    fontWeight: '400',
    color: 'grey.800',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
