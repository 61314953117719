import React, { useCallback } from 'react';

import EmptyIncompatibleMatches from '../components/Admin/IncompatibleMatches/EmptyIncompatibleMatches';

import strings from '../localization';

const useUserNotFound = (
  loadSuccess: boolean,
  show: boolean,
  customMessage?: string,
  promptComponent?: React.ReactNode,
) => {
  const showBox = () => {
    if (show) {
      return null;
    }
    return (
      <EmptyIncompatibleMatches
        message={customMessage ?? strings.userNotFound}
      />
    );
  };

  const showUserNotFound = useCallback(() => {
    if (loadSuccess) {
      return showBox();
    }
    if (!show) {
      return promptComponent;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSuccess, show]);

  return { showUserNotFound };
};

export default useUserNotFound;
