import ThemeConstants from '../../theme/ThemeConstants';

const classes = {
  dashboardContainer: {
    width: '100%',
  },
  legendGrid: {
    marginBottom: '8px',
  },
  onboarding: {
    options: {
      primaryColor: ThemeConstants.primary.main,
    },
  },
};

export default classes;
