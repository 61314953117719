import { PermissionsOfAdmin, PermissionsRemovalKeys } from '@pharmaplan/common';

const { ExpressBooking, Delete, PermanentDelete } = PermissionsRemovalKeys;
const {
  ExpressBooking: ExpressBookingPermission,
  Delete: DeletePermission,
  PermanentDelete: PermanentDeletePermission,
} = PermissionsOfAdmin;

const permissionsKeyMap = {
  [ExpressBookingPermission]: ExpressBooking,
  [DeletePermission]: Delete,
  [PermanentDeletePermission]: PermanentDelete,
};

export const removeBasedOnPermissions = (
  list: Array<{ key: string }>,
  permissions: Array<PermissionsOfAdmin>,
  can: (param: PermissionsOfAdmin) => boolean,
) => {
  let result: any[] = list;
  const permissionsDisabled = permissions.map((item) =>
    ({
      canDo: can(item),
      permission: item,
    }));

  permissionsDisabled.forEach((item) => {
    const keyToRemove = permissionsKeyMap[item.permission as keyof typeof permissionsKeyMap];
    if (!item.canDo) {
      result = result.filter((inItem) =>
        inItem.key !== keyToRemove);
    }
  });

  return result;
};
