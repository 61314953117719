import { SxProps, Theme } from '@mui/system';

const styles = {
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  contactNameTitle: {
    fontSize: 12,
    color: 'common.grey850',
  },

  contactName: {
    fontWeight: 500,
    color: 'common.grey850',
  },

  container: {
    display: 'flex', justifyContent: 'space-between',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
