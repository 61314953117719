import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';
import Resizer from 'react-image-file-resizer';
import {
  CalendarStateKey,
  convertToDecimals,
  currency,
  HelpTypes,
  IAllLanguages,
  IFilterSoftware,
  Language,
  logout,
  MiscType,
  TimeFormat,
} from '@pharmaplan/common';
import strings from '../localization';
import { DateFormats, MomentTimeFormats, serverDateKey } from './Constants';
import {
  getDayMonthDateYear,
  getHourMinute,
} from '../components/Reports/helpers';
import { showError } from '../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import { store } from '../store';

export const stripLeadingZeros = (val: string) =>
  parseInt(val, 10);

export const MonthWeek = () =>
  [
    { key: CalendarStateKey.month, label: strings.month },
    { key: CalendarStateKey.week, label: strings.week },
  ];

export const generateUniquieId = () =>
  uuid();

export const downloadPDF = (pdf: string, fileName: string) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const isPast = (date: Date) =>
  (DateTime.fromJSDate(date).toISO({ includeOffset: false }) ?? '')
  < (DateTime.now().minus({ day: 1 }).toISO({ includeOffset: false }) ?? '');

export const resizeFile = (file: File): Promise<Blob> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      80,
      80,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as Blob);
      },
      'blob',
    );
  });

export const getBrowserLang = () => {
  const browserLang = navigator.language.toLowerCase().substring(2, 0);
  const setBrowserLang = browserLang === Language.en ? Language.en : Language.fr;

  return setBrowserLang;
};

export const getId = (date: string) =>
  new Date(date)?.toISOString()?.split('T')?.[0];

export const exists = (list: Array<any>, check: string) =>
  list.some((item) =>
    item.id === getId(check));

export const convertPercentageToRange = (percentage: number) => {
  const level = Math.ceil(percentage / 20);
  return Math.min(level, 5);
};

export const timeString = (
  sDate: string,
  eDate: string,
  timeFormat: TimeFormat,
) =>
  `${getHourMinute(sDate, timeFormat)} - ${getHourMinute(eDate, timeFormat)}`;

export const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, 0);
};

export const handleDate = (date: string | Date) => {
  if (typeof date === 'string') {
    return date;
  }
  return DateTime.fromJSDate(date);
};

export const setPageTitle = (title: string) => {
  document.title = strings.formatString(
    strings.pageHeaderTitle,
    title,
  ) as string;
};

export const filterLanguage = (
  languages: Array<IAllLanguages>,
  languageToCompare: string,
) => {
  const langObj = languages.find(
    (item) =>
      item.languageId === languageToCompare,
  );
  return langObj;
};

export const createMultipleDate = (date: string, hour: number) =>
  `${DateTime.fromISO(date)
    .set({ hour, minute: 0, second: 0 })
    .toISO({ includeOffset: false })
    ?.slice(0, -4)}Z`;

export const mapFormat = (date: string) =>
  DateTime.fromISO(date, { setZone: true }).toFormat(
    MomentTimeFormats.fullDate,
  );

export const parseMapDate = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) =>
  ({
    startDate: mapFormat(startDate),
    endDate: mapFormat(endDate),
  });

export const getZeroHourDate = (isoDate: string) =>
  DateTime.fromISO(isoDate).set({ hour: 0, minute: 0, second: 0 });

export const serverTodayMinusOne = getZeroHourDate(
  sessionStorage.getItem(serverDateKey) ?? '',
);

export const serverToday = () =>
  DateTime.fromISO(sessionStorage.getItem(serverDateKey) ?? '').set({
    hour: 0,
    minute: 0,
    second: 0,
  });

export const getISO = (date: Date) =>
  DateTime.fromJSDate(date).toISO({ includeOffset: false }) ?? '';

export const getHourMinuteSeconds = (date: string, timeFormat: TimeFormat) =>
  (timeFormat === TimeFormat.twelveHours
    ? DateTime.fromISO(date, { setZone: true }).toFormat(
      DateFormats.twelveHoursSeconds,
    )
    : DateTime.fromISO(date, { setZone: true }).toFormat(
      DateFormats.hourMinutesSeconds,
    ));

export const EmptyStringForZero = (val: number | null | undefined) =>
  val || '';

export const getSoftwareName = (data: IFilterSoftware[], softwareId: string) =>
  data.find((item) =>
    item.softwareId === softwareId)?.name ?? '';

export const getLanguageName = (data: IAllLanguages[], languageId: string) =>
  data.find((item) =>
    item.languageId === languageId)?.name ?? '';

export const getMinusCurrentYear = (year: number): number =>
  new Date().getFullYear() - year;

export const adminUserTypeBasedSearch = (
  val: HelpTypes | MiscType | null,
  returnVal: null | Array<HelpTypes>,
) => {
  if (val === MiscType.All) {
    return returnVal;
  }
  return val;
};

export const openBase64PDF = (base64: string, converted: boolean) => {
  const pdfWindow = window.open('');
  const prefixPdf = converted ? '' : 'data:application/pdf;base64,';
  pdfWindow?.document.write(
    `<iframe width='100%' height='100%' style="position: absolute; top: 0; left: 0;" frameborder="0" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true" src='${prefixPdf}${encodeURI(
      base64,
    )}'></iframe>`,
  );
};

export const openBase64Doc = (
  base64: string,
  converted: boolean,
  fileName: string,
) => {
  const prefixDoc = converted ? '' : 'data:application/octet-stream;base64,';
  const downloadLink = document.createElement('a');
  downloadLink.href = `${prefixDoc}${base64}`;
  downloadLink.download = fileName ?? 'download';
  downloadLink.click();
};

export const convertToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      // Check if reader.result is a string
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to convert the file to base64.'));
      }
    };

    reader.readAsDataURL(file);
  });

export function getPlatformOS() {
  const { userAgent } = window.navigator;

  const isIOS = (/iPad|iPhone|iPod/.test(userAgent)
      || (/Mac|Mac OS|MacIntel/gi.test(userAgent)
        && (navigator.maxTouchPoints > 1 || 'ontouchend' in document)))
    && !(window as any).MSStream;

  switch (true) {
    case /Macintosh|Mac|Mac OS|MacIntel|MacPPC|Mac68K/gi.test(userAgent):
      return 'macintosh';
    case isIOS:
      return 'iOS';
    case /'Win32|Win64|Windows|Windows NT|WinCE/gi.test(userAgent):
      return 'windows';
    case /Android/gi.test(userAgent):
      return 'android';
    case /Linux/gi.test(userAgent):
      return 'linux';
    default:
      return 'unknown';
  }
}

export function getBrowser() {
  switch (true) {
    case (navigator.userAgent.indexOf('Opera')
      || navigator.userAgent.indexOf('OPR')) !== -1:
      return 'opera';
    case navigator.userAgent.indexOf('Edg') !== -1:
      return 'edge';
    case navigator.userAgent.indexOf('Chrome') !== -1:
      return 'chrome';
    case navigator.userAgent.indexOf('Safari') !== -1:
      return 'safari';

    case navigator.userAgent.indexOf('MSIE') !== -1
      || !!(document as any).documentMode === true:
      return 'ie';
    default:
      return 'unknown';
  }
}

export const rateValidation = ({
  pharmacyRate,
  pharmacistRate,
  dispatch,
}: {
  pharmacyRate: number | string;
  pharmacistRate: number | string;
  dispatch: typeof store.dispatch;
}) => {
  const isPharmacyGreater = parseFloat(pharmacyRate as string) > parseFloat(pharmacistRate as string);
  if (!isPharmacyGreater) {
    showError(dispatch, strings.pharmacistRateCannotBeMoreThanPharmacy);
  }
  return isPharmacyGreater;
};

// DESERIALIZE BACK TO DATETIME
export const deserializeISODate = (date: string) =>
  DateTime.fromISO(date, { setZone: true });

export const getVisibleDatesMonth = (date: string) => {
  const parsedDate = DateTime.fromISO(date);

  const start = parsedDate
    .startOf('month')
    .startOf('week')
    .toISO({ includeOffset: false });
  const end = parsedDate
    .endOf('month')
    .endOf('week')
    .toISO({ includeOffset: false });

  return { start, end };
};

export const logoutHandler = () => {
  store.dispatch(logout());
};

/*
  @params
  expected: array which includes allowed types is found in url or not
  depth: get type after how many slashes e.g. /admin/dashboard/broadcast-list <- type at depth 3
  fallback: if not in expected what should be the fallback type to return
*/
export const parseUrlType = <T, >(
  url: string,
  expected: Array<T>,
  depth: number,
  fallback: T,
): [string, T] => {
  const pathParams = url.split('/');
  const prefix = pathParams.slice(0, depth).join('/');
  const type = pathParams.pop() as T;

  if (expected.includes(type)) {
    return [prefix, type];
  }
  return [prefix, fallback];
};

// NOTE: DATASET AND KEYS MUST BE IN SAME ORDER
export const mergeDatasets = <T extends readonly [] | readonly any[]>(
  datasets: T,
  datasetKeys: { [K in keyof T]: any },
) => {
  const dataKeys = (primaryIndex: number) =>
    datasetKeys.reduce((acc: { [x: string]: any }, _: any, i: number) => {
      acc[datasetKeys[i]] = datasets[i][primaryIndex]?.data;
      return acc;
    }, {});

  return Array.from(new Array(3)).map((_, i) =>
    ({
      month: datasets[0][i]?.month,
      ...dataKeys(i),
    }));
};

export const getLastNYears = (n: number) => {
  const currentYear = new Date().getFullYear();
  const yearsDropdown = [];

  for (let i = 0; i < n; i += 1) {
    const year = currentYear - i;
    yearsDropdown.push({
      key: year,
      label: year.toString(),
    });
  }

  return yearsDropdown;
};

export const monthValueFormatter = (value: number) =>
  DateTime.now().set({ month: value }).toFormat('LLL');

export const currencyFormatter = (value: number | null) =>
  `${currency}${convertToDecimals(value ?? 0.0)}`;

export const hoursFormatter = (value: number | null) =>
  `${value} Hrs`;

export const isPastServerDate = (startDate: string) =>
  (serverToday().toISO({ includeOffset: false }) ?? '') > startDate;

export const formatDateTimeRange = (
  startDate: string,
  endDate: string,
  timeFormat: TimeFormat,
) =>
  `${getDayMonthDateYear(startDate)} | ${getHourMinute(
    startDate,
    timeFormat,
  )} - ${getHourMinute(endDate, timeFormat)}`;
