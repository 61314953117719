import { createReducer } from '@reduxjs/toolkit';
import { logout } from '../actions';
import {
  resetFilter,
  setSortBy,
  updateIncomeFilter,
  updateReportFilter,
} from '../actions/reportActions';
import { PharmacyWorkshiftStatus } from '../helpers/Constants';
import { IBookingsRequested } from '../models';

export interface IReportFilters {
  startDate: string;
  endDate: string;
  banner: Array<string>;
  pharmacyId: string;
  pharmacistId: string;
  workshiftTypes: PharmacyWorkshiftStatus | string;
}

export interface IIncomeFilter {
  startDate: string;
  endDate: string;
  pharmacyIds: Array<string>;
}

export interface IReport {
  filterValues: IReportFilters;
  sortBy: keyof IBookingsRequested;
  incomeFilter: IIncomeFilter;
}

const initialState: IReport = {
  filterValues: {
    startDate: '',
    endDate: '',
    banner: [],
    pharmacyId: '',
    pharmacistId: '',
    workshiftTypes: '',
  },
  sortBy: 'requestedDate',
  incomeFilter: {
    startDate: '',
    endDate: '',
    pharmacyIds: [],
  },
};

const reportsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateReportFilter, (state, action) => {
      state.filterValues = action.payload;
    })
    .addCase(updateIncomeFilter, (state, action) => {
      state.incomeFilter = action.payload;
    })
    .addCase(setSortBy, (state, action) => {
      state.sortBy = action.payload;
    })
    //reset all on logout
    .addCase(logout.fulfilled, () => initialState)
    .addCase(resetFilter, (state) => {
      return initialState;
    });
});

export default reportsReducer;
