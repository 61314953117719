import { Breadcrumbs } from '@mui/material';
import React, { FC } from 'react';

interface ICustomBreadcrumbs {
  crumbs: Array<any>;
}
const CustomBreadcrumbs: FC<ICustomBreadcrumbs> = ({ crumbs }) =>
  <Breadcrumbs separator=">">{crumbs}</Breadcrumbs>;

export default CustomBreadcrumbs;
