import React, { FC, useEffect } from 'react';

import { Stack } from '@mui/system';

import { AdminLegendVariant, adminActions, pharmacyDetails, successOrSelector } from '@pharmaplan/common';

import styles from './styles';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

import DynamicTable from '../../DynamicTable';
import AdminCreateNote from './AdminCreateUpdateNotes/AdminCreateNote';
import { AdminNotesType, OtherScreens } from '../../../helpers/Constants';
import AdminPharmacistCard from '../Profile/Pharmacist/PharmacistViewProfile/AdminPharmacistCard';
import { setBack } from '../../../actions/drawerActions';
import useAdminNotes from '../../../hooks/Admin/AdminNotes/useAdminNotes';
import strings from '../../../localization';
import NoAvailabilities from '../MatchingAvailabilities/NoAvailabilities';
import { useAppSelector } from '../../../hooks/useAppSelector';
import AdminPharmacyCard from '../../common/AdminPharmacyCard';
import useGetItems from '../../../hooks/useGetItems';

interface IAdminNotes {
  id: string;
  notesType: AdminNotesType;
  back?: () => void;
}

const successActions = [adminActions.getAllNotes, adminActions.getAllPharmacyNotes];

const AdminNotes: FC<IAdminNotes> = ({ id, notesType, back }) => {
  const dispatch = useAppDispatch();

  const { table, ratingGoBack, data, isPharmacist } = useAdminNotes(id, notesType);
  const { getImage } = useGetItems();

  const details = useAppSelector(pharmacyDetails);

  const loadSuccess = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const { pharmacy } = details ?? {};
  const { logoId } = pharmacy ?? {};

  const avatar = getImage(logoId);

  const emptyContainerComponent = data.length === 0 ? (
    <NoAvailabilities message={strings.noDataAvailable} />
  ) : null;

  const renderHeader = () => {
    if (isPharmacist) {
      return (
        <AdminPharmacistCard
          pharmacistId={id}
          fromScreen={OtherScreens.AdminNotes}
          type={AdminLegendVariant.posted}
          ratingGoBack={ratingGoBack}
        />
      );
    }

    return (
      <AdminPharmacyCard
        pharmacyId={id}
        fromScreen={OtherScreens.AdminNotes}
        avatar={avatar}
      />
    );
  };

  useEffect(() => {
    dispatch(setBack(back ?? null));

    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack sx={styles.stackContainer}>
      {renderHeader()}
      <DynamicTable
        table={table}
        loadSuccess={loadSuccess}
        noContainerStyling
        showHeader={false}
        hidePagination
        emptyContainerComponent={emptyContainerComponent}
        customHeader={<AdminCreateNote id={id} notesType={notesType} />}
      />
    </Stack>
  );
};

export default AdminNotes;
