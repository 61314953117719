import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const styles = {
  container: {
    backgroundColor: ThemeConstants.common.lightBackgroundColor,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
