import { SxProps, Theme } from '@mui/system';

const classes = {
  counterOfferTitle: {
    fontSize: '22px',
    fontWeight: 700,
    color: 'common.grey850',
    marginBottom: '9px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
