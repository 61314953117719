import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import {
  adminActions,
  adminBroadcastGroupSelectedUsers,
  successSelector,
} from '@pharmaplan/common';

import useBroadcastGroup from '../../../../hooks/Admin/BroadcastList/useBroadcastGroup';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { SkeletonVariants } from '../../../../helpers/Constants';
import { getDayMonthDateYear } from '../../../Reports/helpers';
import genericClasses from '../../../../theme/GenericClasses';
import WithSkeleton from '../../../common/WithSkeleton';
import strings from '../../../../localization';

import BroadcastGroupCard from './BroadcastGroupCard';
import styles from './styles';

interface IBroadcastGroup {
  broadcastGroupId: string;
  enableDelete?: boolean;
}

const successAction = adminActions.getBroadcastGroupSelectedUsers;

const BroadcastGroup: FC<IBroadcastGroup> = ({
  broadcastGroupId,
  enableDelete = false,
}) => {
  const details = useAppSelector(adminBroadcastGroupSelectedUsers);

  const loadSuccess = useAppSelector((state) =>
    successSelector([successAction], state));

  const { group } = details ?? {};

  const { userName, createDate } = group ?? {};

  const { listEndRef, modifiedData, handleDelete } = useBroadcastGroup(broadcastGroupId);

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const createdInfoConfig = [
    {
      key: 0,
      label: strings.createdDate,
      info: getDayMonthDateYear(createDate),
    },
    {
      key: 1,
      label: strings.createdBy,
      info: userName,
    },
  ];

  const handleClick = (id: string) => {
    handleDelete(id);
  };

  return (
    <Box>
      <Box sx={styles.container}>
        {createdInfoConfig.map((item) => {
          const { key, label, info } = item ?? {};

          return (
            <Box key={key} sx={styles.infoContainer}>
              <SkeletonTypography sx={styles.label}>{label}</SkeletonTypography>
              <SkeletonTypography sx={styles.info}>{info}</SkeletonTypography>
            </Box>
          );
        })}
      </Box>

      {modifiedData.map((item) => {
        const { id } = item ?? {};

        return (
          <Box key={id} component="div" sx={styles.detailsContainer}>
            <BroadcastGroupCard
              enableDelete={enableDelete}
              data={item}
              handleDelete={handleClick}
            />
          </Box>
        );
      })}

      {loadSuccess && (
        <Box
          component="div"
          ref={listEndRef}
          style={genericClasses.paginationDiv}
        />
      )}
    </Box>
  );
};

export default BroadcastGroup;
