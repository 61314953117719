import React from 'react';

import ProfileLocationMapView from '../components/Profile/ProfileForm/ProfileLocationMapView';
import { setDialog } from '../reducers/dialogReducer';
import styles from '../components/Profile/ProfileForm/style';

import { useAppDispatch } from './useAppDispatch';
import strings from '../localization';

const usePickLocationModal = () => {
  const dispatch = useAppDispatch();

  const showPickYourLocation = () => {
    dispatch(
      setDialog({
        heading: {
          title: strings.pickYourLocationOnMap,
        },
        Component: (
          <ProfileLocationMapView
            isModal
            customStyles={{
              container: styles.mapContainerSignUpFlow,
              subContainer: styles.selectContainer,
              footer: styles.footerContainer,
            }}
          />
        ),
        maxWidth: 'lg',
        customStyle: { customModalHeading: styles.customModalHeading },
        showCloseButton: true,
      }),
    );
  };

  return { showPickYourLocation };
};

export default usePickLocationModal;
