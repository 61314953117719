import React, { FC, useEffect, useMemo } from 'react';

import { Box } from '@mui/system';

import {
  AdminLegendVariant,
  IBookedPharmacistData,
  adminActions,
  adminEvent,
  getAdminEvent,
  isLoadingSelector,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { setBack, setFooterShow, setTitle } from '../../../actions/drawerActions';
import strings from '../../../localization';
import DrawerOutOfDateTitle from '../../common/DrawerOutOfDateTitle';

import SingleBooking from './SingleBooking';
import useOptimizedAvatar from '../../../hooks/useOptimizedAvatar';
import useGetItems from '../../../hooks/useGetItems';

interface IBookingList {
  date: string;
}

const BookingList: FC<IBookingList> = ({ date }) => {
  const dispatch = useAppDispatch();
  const bookingList = useAppSelector(adminEvent);

  const loading = useAppSelector((state) =>
    isLoadingSelector([adminActions.getAdminEvent], state));

  useEffect(() => {
    const title = (
      <DrawerOutOfDateTitle
        date={date}
        dateLabel={strings.bookedWorkshiftsFormatted}
      />
    );

    dispatch(setTitle({ title }));
    dispatch(setBack(null));

    dispatch(setFooterShow(false));
    dispatch(
      getAdminEvent({
        date: date.split('T')[0],
        type: AdminLegendVariant.booked,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pharmacistIds = (bookingList as IBookedPharmacistData[]).map(
    (item) =>
      item.pharmacistId,
  );

  const pIds = useMemo(() =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pharmacistIds, [bookingList]);

  useOptimizedAvatar(pIds);
  const { getPharmacistImage } = useGetItems();

  return (
    <Box>
      {loading ? (
        <SingleBooking
          image=""
          item={bookingList?.[0] as IBookedPharmacistData}
        />
      ) : (
        (bookingList as IBookedPharmacistData[]).map((item) =>
          (
            <SingleBooking
              image={getPharmacistImage(item.pharmacistId)}
              key={item.bookingId}
              item={item}
            />
          ))
      )}
    </Box>
  );
};

export default BookingList;
