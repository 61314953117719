import { SxProps, Theme } from '@mui/system';

const styles = {

  root: {
    mb: '16px',
  },

  title: {
    fontSize: 20,
    fontWeight: 600,
    color: 'common.grey850',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
