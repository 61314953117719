import { SxProps, Theme } from '@mui/system';

const styles = {
  root: {
    padding: '24px',
  },

  tableContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '40px',
  },

  tableTitleFont: {
    fontSize: 18,
    fontWeight: 600,
  },

  button: {
    width: '140px',
  },

  tableContainer: {
    padding: '0px 32px 32px 32px',
  },

  container: {
    boxSizing: 'border-box',
    width: '100%',
  },

  bgPositive: {
    backgroundColor: 'common.lightGreen',
    color: 'primary.main',
    maxWidth: '70px',
  },

  bgNegative: {
    backgroundColor: 'primary.light',
    color: 'common.grey350',
    maxWidth: '70px',
  },

  gridLabel: {
    marginTop: '1px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
