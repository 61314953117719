import { useEffect, useRef } from 'react';

import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import {
  adminCreateAvailabilities,
  adminPreSubmitAvailabilities,
  allDayEnd,
  allDayStart,
  getTypeOfUser,
  IVacationsParams,
  pharmacistActions,
  preSubmitVacations,
  resetStatus,
  SelectionType,
  successSelector,
  TypeOfUser,
} from '@pharmaplan/common';
import { IAvailabilityModel } from '@pharmaplan/common/models/Models';

import { getTempEvents, getTempVacations } from '../selectors/calendarSelector';
import { createMultipleDate } from '../helpers/Functions';

import useVacationsCriteria from './CreateVacations/useVacationsCriteria';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import useAvailabilitiesCriteria from './useAvailabilitiesCriteria';
import useAdmin from './useAdmin';

const { admin, superAdmin } = TypeOfUser;
const { preSubmitVacations: preSubmitAction } = pharmacistActions;

const useMultipleSave = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { createVacations } = useVacationsCriteria(SelectionType.multiple);
  const { isAdmin } = useAdmin();

  const apiParams = useRef<null | IVacationsParams>();

  const userType = useAppSelector(getTypeOfUser);
  const tempEvents = useAppSelector(getTempEvents);
  const tempVacations = useAppSelector(getTempVacations);

  const success = useAppSelector((st) =>
    successSelector([preSubmitAction], st));

  const { id } = state ?? {};

  const userEventDispatch = {
    [admin]: (params: IAvailabilityModel): AsyncThunkAction<any, any, any> =>
      adminPreSubmitAvailabilities({ pharmacistId: id ?? '', model: params }),
    [superAdmin]: (
      params: IAvailabilityModel,
    ): AsyncThunkAction<any, any, any> =>
      adminPreSubmitAvailabilities({ pharmacistId: id ?? '', model: params }),
  };

  const selectedDateList = tempEvents.map(
    (item: { selectedDate: string | unknown[] }) =>
      `${item.selectedDate.slice(0, -4)}Z`,
  );

  const handleAvailabilitiesSave = () => {
    const tempAvailabilities = [...tempEvents];

    tempAvailabilities.sort((a, b) =>
      (a.selectedDate as string).localeCompare(b.selectedDate));

    dispatch(
      userEventDispatch[userType as keyof typeof userEventDispatch]({
        startDate: createMultipleDate(tempAvailabilities[0]?.selectedDate, allDayStart),
        endDate: createMultipleDate(
          tempAvailabilities[tempAvailabilities.length - 1]?.selectedDate,
          allDayEnd,
        ),
        recurrenceType: 0,
        selectedDates: selectedDateList,
      }),
    );
  };

  const availabilityAPI = {
    [admin]: (params: IAvailabilityModel): AsyncThunkAction<any, any, any> =>
      adminCreateAvailabilities({ pharmacistId: id ?? '', model: params }),
    [superAdmin]: (
      params: IAvailabilityModel,
    ): AsyncThunkAction<any, any, any> =>
      adminCreateAvailabilities({ pharmacistId: id ?? '', model: params }),
  };

  const dispatchCreateAvailability = () => {
    const tempAvailabilities = [...tempEvents];

    tempAvailabilities.sort((a, b) =>
      (a.selectedDate as string).localeCompare(b.selectedDate));

    dispatch(availabilityAPI[userType as keyof typeof availabilityAPI]({
      startDate: createMultipleDate(tempAvailabilities[0]?.selectedDate, allDayStart),
      endDate: createMultipleDate(
        tempAvailabilities[tempAvailabilities.length - 1]?.selectedDate,
        allDayEnd,
      ),
      recurrenceType: 0,
      selectedDates: selectedDateList,
    }));
  };

  const handleVacationsSave = () => {
    const vacationsCopy = [...tempVacations];

    vacationsCopy.sort((a, b) =>
      (a.selectedDate as string).localeCompare(b.selectedDate));

    const params = {
      startDate: createMultipleDate(
        vacationsCopy[0]?.selectedDate,
        allDayStart,
      ),
      endDate: createMultipleDate(
        vacationsCopy[vacationsCopy.length - 1]?.selectedDate,
        allDayEnd,
      ),
      selectedDates: selectedDateList,
    };

    apiParams.current = params;

    dispatch(
      preSubmitVacations(params),
    );
  };

  useEffect(() => {
    if (success && apiParams.current) {
      createVacations(apiParams.current);
      apiParams.current = null;
      dispatch(resetStatus([preSubmitAction]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, apiParams.current]);

  useAvailabilitiesCriteria(dispatchCreateAvailability, isAdmin);

  return {
    handleSave: handleAvailabilitiesSave,
  };
};

export default useMultipleSave;
