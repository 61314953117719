import React from 'react';

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FormikProps } from 'formik';

import { MonthlyYearlyRecurrence, Ordinals } from '@pharmaplan/common';

import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';
import CustomDropdown from '../../common/CustomDropdown';
import CustomTextField from '../../common/CustomTextField';
import { daysDropdown } from '../../../helpers/Constants';

import classes from './style';

interface IMonthYearRecurrence {
  formik: FormikProps<any>;
}

const defaultProps = {};

const MonthYearRecuurence = ({ formik }: IMonthYearRecurrence) => {
  const { values } = formik;
  const { onDay } = values;

  const radioItems = [
    {
      label: strings.onDay,
      value: MonthlyYearlyRecurrence.day,
    },
    {
      label: strings.onThe,
      value: MonthlyYearlyRecurrence.weekdaysAndMonths,
    },
  ];

  const menuItems = [
    { key: Ordinals.first, label: strings.first },
    { key: Ordinals.second, label: strings.second },
    { key: Ordinals.third, label: strings.third },
    { key: Ordinals.fourth, label: strings.fourth },
  ];

  return (
    <FormControl
      sx={[genericClasses.addAvailabilityMargin] as SystemStyleObject}
    >
      <RadioGroup
        row
        aria-labelledby="radio-buttons-group-label"
        name="recurrencDayMonth"
        value={formik.values.recurrencDayMonth}
        onChange={(e) => {
          formik.setFieldValue('recurrencDayMonth', e.target.value);
        }}
      >
        <Grid container sx={[classes.mb, classes.onDay]}>
          <Grid item xs={3}>
            <FormControlLabel
              key={radioItems[0].value}
              value={radioItems[0].value}
              control={<Radio />}
              label={radioItems[0].label}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              formik={formik}
              disabled={
                formik.values.recurrencDayMonth
                === MonthlyYearlyRecurrence.weekdaysAndMonths
              }
              id="onDay"
              name="onDay"
              type="text"
              placeholder={onDay}
            />
          </Grid>
        </Grid>
        <Grid container sx={[classes.mb]}>
          <Grid item xs={3}>
            <FormControlLabel
              key={radioItems[1].value}
              value={radioItems[1].value}
              control={<Radio />}
              label={radioItems[1].label}
            />
          </Grid>
          <Grid item xs={4} sx={classes.mr}>
            <FormControl sx={genericClasses.flex}>
              <CustomDropdown
                formik={formik}
                menuItems={menuItems}
                name="onThe"
                disabled={
                  formik.values.recurrencDayMonth
                  !== MonthlyYearlyRecurrence.weekdaysAndMonths
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={genericClasses.flex}>
              <CustomDropdown
                formik={formik}
                menuItems={daysDropdown() ?? []}
                name="onTheDay"
                disabled={
                  formik.values.recurrencDayMonth
                  !== MonthlyYearlyRecurrence.weekdaysAndMonths
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

MonthYearRecuurence.defaultProps = defaultProps;

export default MonthYearRecuurence;
