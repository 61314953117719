import { createAction } from '@reduxjs/toolkit';

import {
  BookingState,
  ICalendarEventType,
  PendingCancellationTabTypes,
  RecurrenceActions,
} from '@pharmaplan/common';

import {
  OtherScreens,
  ScreenTypes,
  SecondaryAdminNavigation,
} from '../helpers/Constants';
import { IScreenConfig } from '../components/Drawer/configs';

import { IButtons, IFooter, IPagination, ITitle } from '../types/IDrawer';

export type IFromScreen =
  | SecondaryAdminNavigation
  | OtherScreens
  | PendingCancellationTabTypes
  | null;

export type ICustomBack = () => void;

interface IRenderScreen {
  type?: ICalendarEventType;
  eventIds?: Array<string> | string;
  eventId?: string;
  date?: string;
  bookingState?: BookingState;
  screenType: ScreenTypes;
  screenNumber: number;
  workshiftId?: string;
  pharmacistId?: string;
  recType?: RecurrenceActions;
  recurrenceId?: string;
  pharmacyId?: string;
  fromScreen?: IFromScreen;
  hasOwner?: boolean;
  back?: ICustomBack;
  extraParameters?: any;
}

export const actionTypes = {
  SET_OPEN: 'SET_OPEN',
  SET_BACK: 'SET_BACK',
  SET_FOOTER: 'SET_FOOTER',
  SET_TITLE: 'SET_TITLE',
  SET_CONTENT: 'SET_CONTENT',
  SET_FOOTER_SHOW: 'SET_FOOTER_SHOW',
  SET_BUTTONS: 'SET_BUTTONS',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_CURRENT: 'SET_CURRENT',
  RENDER_SCREEN: 'RENDER_SCREEN',
  RESET_DRAWER: 'RESET_DRAWER',
  SET_DRAWER_CONTENT_PADDING: 'SET_DRAWER_CONTENT_PADDING',
};

export const setOpen = createAction<boolean>(actionTypes.SET_OPEN);
export const setBack = createAction<(() => void) | null>(actionTypes.SET_BACK);
export const setFooter = createAction<IFooter>(actionTypes.SET_FOOTER);
export const setTitle = createAction<ITitle>(actionTypes.SET_TITLE);

export const setContent = createAction<IScreenConfig>(actionTypes.SET_CONTENT);

export const setFooterShow = createAction<boolean>(actionTypes.SET_FOOTER_SHOW);
export const setButtons = createAction<IButtons>(actionTypes.SET_BUTTONS);

export const setPagination = createAction<IPagination>(
  actionTypes.SET_PAGINATION,
);

export const setCurrent = createAction<number>(actionTypes.SET_CURRENT);

export const renderScreen = createAction<IRenderScreen>(
  actionTypes.RENDER_SCREEN,
);

export const setDrawerContentPadding = createAction<string>(
  actionTypes.SET_DRAWER_CONTENT_PADDING,
);

export const resetDrawer = createAction(actionTypes.RESET_DRAWER);
