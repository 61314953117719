import { IStore } from "../types/IStore";

export const getIsUserLoggedIn = (state: IStore) => state.user.isLoggedIn;
export const getIsUserSignedUp = (state: IStore) => state.user.isSignedUp;
export const getIsUserApproved = (state: IStore) => state.user.isApproved;
export const userContact = (state: IStore) => state.user.contact
export const selectUserSoftware = (state: IStore) => state.user.software
export const userGeneral = (state: IStore) => state.user.general;
export const proInfo = (state:IStore) => state.user.proInfo;
export const logos = (state:IStore) => state.user.logos;
export const getEmail = (state:IStore) => state.user.email;
export const changePassword = (state:IStore) => state.user.changePassword;
export const getProfileUtils = (state:IStore) => state.user.utils;
export const language = (state:IStore) => state.user.language;
export const preLoginLang = (state:IStore) => state.user.preLoginWebLang;
export const getTypeOfUser = (state: IStore) =>
  state.user.userType;

export const userPrimaryContact = (state:IStore) => state.user.primaryContact;
export const userSecondaryContact = (state:IStore) => state.user.secondaryContact;
export const userAccountingContact = (state:IStore) => state.user.accountingContact;
export const userPharmacyInfo = (state:IStore) => state.user.pharmacyInfo;
export const userCoordinates = (state:IStore) => state.user.coordinates;
export const profileImage = (state:IStore) => state.user.profileImage;
export const userPreferredLanguage = (state:IStore) => state.user.preferredLanguage;
export const userPreferredTimeFormat = (state: IStore) => state.user.timeFormat;
export const userPreferredTheme = (state: IStore) => state.user.userTheme

export const isRequestedReset = (state:IStore) => state.user.requestedReset;
export const isValidOtp = (state:IStore) => state.user.isValidOtp;
export const faqSelector = (state:IStore) => state.user.faq;
export const username = (state:IStore) => state.user.userName;
export const termsAndConditionsHTMLTemplate = (state: IStore) => state.user.termsAndConditionsHTMLTemplate;
export const istermsAgreedStatus = (state: IStore) => state.user.contact.termsAndConditions;
export const userLocation = (state:IStore) => state.user.userLocation;
export const userAddress = (state:IStore) => state.user.address;
export const getEnforceProfileUpdate = (state:IStore) => state.user.enforceProfileUpdate;
export const getUserProps = (state:IStore) => state.user.userProps;
export const emailExists = (state: IStore) => state.user.emailExists;
export const startTour = (state: IStore) => state.user.startTour;
export const pharmacistFirstName = (state:IStore) => state.user.general.firstName;
export const pharmacistLastName = (state:IStore) => state.user.general.lastName;
export const pharmacyName = (state:IStore) => state.user.pharmacyInfo.name;
export const isUserAvatar = (state:IStore) => state.user.isAvatar;
export const userConfigurations = (state: IStore) => state.user.userConfigurations;
export const userIdSelector = (state: IStore) => state.user.userId;
export const didRequestChangeEmail = (state: IStore) => state.user.didRequestChangeEmail;
export const enforceLogout = (state: IStore) => state.user.enforceLogout;
export const nameSelector = (state: IStore) => state.user.name;
export const isPharmacyAdminLoginSelector = (state: IStore) => state.user.isPharmacyAdminLogin; 
export const enableChat = (state: IStore) => state.user.enableChat;
export const clientAdvertisements = (state: IStore) => state.user.advertisement;