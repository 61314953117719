import React, { FC } from 'react';
import { Box } from '@mui/system';
import {
  IAdminRequestData,
  IAdminWorkshiftDetails,
  IPharmacistEvent,
  convertToDecimals,
  counterOffer,
} from '@pharmaplan/common';
import { Typography } from '@mui/material';
import classes from './styles';
import strings from '../../../../localization';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import WithSkeleton from '../../../common/WithSkeleton';
import useAdmin from '../../../../hooks/useAdmin';

interface IPharmacyCardFooter {
  event: Partial<IPharmacistEvent> &
    Partial<IAdminWorkshiftDetails | IAdminRequestData>;
  successAction: string;
}

const PharmacyCardFooter: FC<IPharmacyCardFooter> = ({
  event,
  successAction,
}) => {
  const counterOfferEvent = useAppSelector(counterOffer);

  const { isAdmin } = useAdmin();
  const hourlyRateLabel = isAdmin
    ? strings.pharmacyHourlyRate
    : strings.hourlyRate;

  const { hourlyRate, softwareName, counterOfferId } = event || {};
  const { counterHourlyRate } = counterOfferEvent || {};
  const isValidHourlyRate = counterOfferId && counterHourlyRate;

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: 'text',
  })(Typography);

  return (
    <Box sx={classes.mainContainer}>
      <Box sx={classes.itemContainer}>
        <SkeletonTypography sx={classes.title}>
          {hourlyRateLabel}
        </SkeletonTypography>
        <Box sx={classes.valueContainer}>
          {isValidHourlyRate ? (
            <>
              <SkeletonTypography sx={classes.strike}>
                $
                {convertToDecimals(hourlyRate)}
              </SkeletonTypography>
              <SkeletonTypography sx={classes.value}>
                $
                {convertToDecimals(counterHourlyRate)}
              </SkeletonTypography>
            </>
          ) : (
            <SkeletonTypography sx={classes.value}>
              $
              {convertToDecimals(hourlyRate)}
            </SkeletonTypography>
          )}
        </Box>
      </Box>
      <Box sx={classes.itemContainer}>
        <SkeletonTypography sx={classes.title}>
          {strings.software}
        </SkeletonTypography>
        <SkeletonTypography sx={classes.value}>
          {softwareName}
        </SkeletonTypography>
      </Box>
    </Box>
  );
};

export default PharmacyCardFooter;
