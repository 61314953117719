import { Box } from '@mui/material';
import React from 'react';
import classes from './style';

interface IWeekHeader {
  label: string;
}

const WeekHeader = ({ label }: IWeekHeader) => {
  const [date, day] = label.split(' ');
  return (
    <Box component="div" sx={classes.weekHeaderContainer}>
      <Box sx={classes.weekHeaderDate}>{date}</Box>
      <Box sx={classes.weekHeaderDay}>{day}</Box>
    </Box>
  );
};

export default WeekHeader;
