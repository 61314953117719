/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as FavouriteNotActive } from '@pharmaplan/common/assets/icons/favouriteNotActive.svg';
import { ReactComponent as FavouriteActive } from '@pharmaplan/common/assets/icons/favourtieActive.svg';
import {
  IHistoryParams,
  TypeOfUser,
  isLoadingSelector,
  pharmacistActions,
  pharmacyActions,
  toType,
} from '@pharmaplan/common';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useUser from '../../../hooks/useUser';
import classes from './styles';
import { useAppSelector } from '../../../hooks/useAppSelector';
import ThemeConstants from '../../../theme/ThemeConstants';
import strings from '../../../localization';

interface ICustomFavourite {
  initState: boolean | undefined;
  pharmacyId: string;
  loading: Array<string>;
  setLoading: (loadingId: Array<string>) => void;
  disabled: boolean;
}

const CustomFavourite = ({
  initState,
  pharmacyId,
  loading,
  setLoading,
  disabled,
}: ICustomFavourite) => {
  const dispatch = useAppDispatch();
  const [isLike, setIsLike] = useState(initState);
  const isMounted = useRef(false);
  const clickable = useRef(true);

  const load = useAppSelector((state) =>
    isLoadingSelector(
      [
        pharmacyActions.addFavourite,
        pharmacyActions.removeFavourite,
        pharmacistActions.addFavourite,
        pharmacistActions.removeFavourite,
      ],
      state,
    ));

  const { addFavourite, removeFavourite, userType } = useUser();
  const params = {
    [TypeOfUser.pharmacist]: { pharmacyId },
    [TypeOfUser.pharmacy]: pharmacyId,
  };

  useEffect(() => {
    setIsLike(initState);
  }, [initState]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    !isLike
      ? dispatch(
          removeFavourite(
            params[toType<typeof params>(userType)] as IHistoryParams & string,
          ) as AsyncThunkAction<any, string, any>,
      )
      : dispatch(
          addFavourite(
            params[toType<typeof params>(userType)] as IHistoryParams & string,
          ) as AsyncThunkAction<any, string, any>,
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLike]);

  const handleRequest = (e: any) => {
    e.stopPropagation();
    if (clickable.current) {
      setLoading([...loading, pharmacyId]);
      setIsLike((prev) =>
        !prev);
    }
  };

  const Icon = useCallback(() => {
    switch (true) {
      case load && loading.includes(pharmacyId):
        clickable.current = false;
        return (
          <CircularProgress
            size={25}
            sx={{ color: ThemeConstants.common.counterRed }}
          />
        );
      case isLike:
        return <FavouriteActive />;
      case !isLike:
        return <FavouriteNotActive />;
      default:
        return <FavouriteNotActive />;
    }
  }, [load, loading, pharmacyId, isLike]);

  useEffect(() => {
    if (!load) {
      clickable.current = true;
      setLoading([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  return (
    <Box>
      <Tooltip title={isLike ? strings.removeFavorite : strings.addFavorite}>
        <Box
          disabled={disabled}
          sx={[!clickable.current && classes.nonClickableContainer]}
          component={clickable.current ? IconButton : 'div'}
          onClick={handleRequest}
        >
          <Icon />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default CustomFavourite;
