import { ITheme } from '.';

export default {
  styleOverrides: {
    root: ({ theme }: ITheme) =>
      ({
        color: '#6E757C',
        fontSize: '14px',
        borderColor: theme.palette.grey[200],

      }),
    head: () =>
      ({
        color: '#4A5056',
        paddingBottom: '5px',
      }),
  },
};
