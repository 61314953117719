import { useEffect, useState } from 'react';

const useFocusTab = () => {
  const [isFocused, setIsFocused] = useState(false);
  const onFocusBlur = (isInFocus: boolean) =>
    () => {
      setIsFocused(isInFocus);
    };

  useEffect(() => {
    window.addEventListener('focus', onFocusBlur(true));
    window.addEventListener('blur', onFocusBlur(false));

    return () => {
      window.removeEventListener('focus', onFocusBlur(true));
      window.removeEventListener('blur', onFocusBlur(false));
    };
  }, []);

  return { isFocused };
};

export default useFocusTab;
