import { TypeOfUser, getTypeOfUser } from '@pharmaplan/common';
import { useAppSelector } from './useAppSelector';

const { admin, superAdmin } = TypeOfUser;

const useAdmin = () => {
  const userType = useAppSelector(getTypeOfUser);

  const adminUser = userType === admin;
  const superAdminUser = userType === superAdmin;

  const isAdmin = adminUser || superAdminUser;

  return { isAdmin, isSuperAdmin: superAdminUser };
};

export default useAdmin;
