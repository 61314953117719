import { Box } from '@mui/system';
import React from 'react';
import { ReactComponent as SuccessIcon } from '@pharmaplan/common/assets/icons/successIcon.svg';
import { ReactComponent as ErrorIcon } from '@pharmaplan/common/assets/icons/errorIcon.svg';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  errorType,
  getErrorType,
  getUserConfigurations,
} from '@pharmaplan/common';
import { useNavigate } from 'react-router-dom';
import { Constants, OutcomeModalTypes } from '../../../helpers/Constants';
import strings from '../../../localization';
import CustomButton from '../../common/CustomButton';
import classes from './styles';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { resetDialog } from '../../../reducers/dialogReducer';
import { ReactComponent as WarningIcon } from '../../../assets/svg/warningIcon.svg';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { logoutHandler } from '../../../helpers/Functions';

const OutcomeModal = ({
  type,
  message,
  customClose,
  showCloseButton,
  showTopPadding,
}: {
  type: OutcomeModalTypes;
  message: string | React.ReactNode;
  showCloseButton?: boolean;
  showTopPadding?: boolean;
  customClose?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const errorCode = useAppSelector(getErrorType);
  const navigate = useNavigate();

  const config = {
    [OutcomeModalTypes.success]: {
      icon: <SuccessIcon />,
      title: strings.savedSuccessfully,
    },
    [OutcomeModalTypes.congrats]: {
      icon: <SuccessIcon />,
      title: strings.congratulations,
    },
    [OutcomeModalTypes.error]: {
      icon: <ErrorIcon />,
      title: `${strings.error}!`,
    },
    [OutcomeModalTypes.noCancellation]: {
      icon: <WarningIcon />,
      title: strings.cancelBooking,
    },
    [OutcomeModalTypes.selfServiceCancelConfirmation]: {
      icon: <SuccessIcon />,
      title: strings.cancelBooking,
    },
    [OutcomeModalTypes.bookingPending]: {
      icon: <SuccessIcon />,
      title: strings.pendingBooking,
    },
    [OutcomeModalTypes.updateTiming]: {
      icon: <SuccessIcon />,
      title: strings.updateTiming,
    },
    [OutcomeModalTypes.submitSuccess]: {
      icon: <SuccessIcon />,
      title: strings.submitSuccessfully,
    },
    [OutcomeModalTypes.requestSuccess]: {
      icon: <SuccessIcon />,
      title: strings.submitSuccessfully,
    },
    [OutcomeModalTypes.softwareWarning]: {
      icon: <WarningIcon />,
      title: '',
    },
    [OutcomeModalTypes.activationSuccess]: {
      icon: <SuccessIcon />,
      title: strings.success,
    },
    [OutcomeModalTypes.deletionSuccess]: {
      icon: <SuccessIcon />,
      title: strings.success,
    },
    [OutcomeModalTypes.justSuccess]: {
      icon: <SuccessIcon />,
      title: strings.success,
    },
    [OutcomeModalTypes.adminCreatedSuccess]: {
      icon: <SuccessIcon />,
      title: strings.adminCreatedSuccessfully,
    },
    [OutcomeModalTypes.sessionReset]: {
      icon: <SuccessIcon />,
      title: strings.sessionReset,
    },
    [OutcomeModalTypes.sessionTimeOut]: {
      icon: <WarningIcon />,
      title: strings.sessionTimedout,
    },
    [OutcomeModalTypes.pharmacyAdminAppointedSuccess]: {
      icon: <SuccessIcon />,
      title: strings.pharmacyAppointedSuccessfully,
    },
  };

  const handleClose = () => {
    const { EnforceUpdatePassword, deletedAccount, refreshConfigurations } = errorType;
    switch (errorCode) {
      case EnforceUpdatePassword:
        navigate(Constants.paths.resetPassword);
        break;
      case deletedAccount:
        logoutHandler();
        break;
      case refreshConfigurations:
        dispatch(getUserConfigurations());
        break;
      default:
        break;
    }
    dispatch(resetDialog());
  };

  return (
    <Box sx={classes.container} maxWidth="xs">
      {showCloseButton && (
        <Box sx={classes.closeContainer}>
          <IconButton size="small" sx={classes.closeIcon} onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      )}
      {showTopPadding && <Box sx={classes.topPadding} />}
      <Box sx={classes.subContainer}>{config[type].icon}</Box>
      <Box sx={classes.subContainer}>
        <Typography sx={classes.title}>{config[type]?.title}</Typography>
      </Box>
      <Box sx={classes.subContainer}>
        <Typography sx={classes.message}>{message}</Typography>
      </Box>
      <Box sx={classes.buttonContainer}>
        <CustomButton
          customButtonStyle={classes.button}
          label={strings.ok}
          onClick={customClose ?? handleClose}
        />
      </Box>
    </Box>
  );
};
export default OutcomeModal;
