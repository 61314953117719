import { ChatMessageTypes } from '@pharmaplan/common';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { useEffect } from 'react';

interface IUseCannedMessages {
  url: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const useCannedMessages = ({ url, setShow }: IUseCannedMessages) => {
  const store = useSendbirdStateContext();

  const sdk = store?.stores?.sdkStore?.sdk;

  useEffect(() => {
    sdk?.groupChannel?.getChannel(url).then((res) => {
      const { lastMessage } = res ?? {};
      if (!lastMessage) {
        setShow(true);
        return;
      }

      const { customType } = lastMessage ?? {};
      setShow(customType === ChatMessageTypes.ChatClosed);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk]);
};

export default useCannedMessages;
