import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = {
  title: {
    fontWeight: 600,
    fontSize: 21,
    color: 'common.grey850',
    marginBottom: '24px',
  },

  root: {
    marginX: '25px',
    backgroundColor: 'white',
    borderRadius: '4px',
  },

  container: {
    padding: '32px',
  },

  backButtonText: {
    color: 'grey.600',
    marginLeft: '5px',
    fontSize: 16,
    fontWeight: 500,
  },

  backIcon: {
    color: 'grey.600',
  },

  backButtonContainer: {
    display: 'flex',
    padding: '8px 12px',
    borderRadius: 10,
    alignItems: 'center',
    marginBottom: '16px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
