import {
  ITEMS_PER_PAGE,
  PharmacistLegendVariant,
  ReportRequestTypes,
} from '../helpers/Constants';
import Routes from '../helpers/Routes';
import { IReplacementsParams } from '../models/Models';
import {
  ICalendarFilter,
  ICancelBooking,
  IHistoryApiParams,
  IHistoryParams,
  IIncomeDetailsParams,
  IIncomeReportParams,
  IPreSubmitVacationsParams,
  IReportFilterParams,
  IReportOmitAvailability,
  IReportsPDFParams,
  IRequestBookingParams,
  IUpdateVacationsParams,
  IVacationsParams,
  IWorkshiftParams,
} from '../models/PharmacistModels';
import httpClient from './httpClient';
import {
  ICreateAvailabilityRequestParams,
  IUpdateAvailabilitRequestParams,
} from './types';

class PharmacistController {
  basePath: string;

  constructor() {
    this.basePath = Routes.pharmacist.pharmacistBasePath;
  }

  pharmacistCalendar = async (filter: ICalendarFilter) =>
    httpClient.post(`${this.basePath}/${Routes.pharmacist.calendar}`, {
      calRequest: {
        ...filter,
      },
    });

  pharmacistPostedShifts = async (id: string) =>
    httpClient.get(`${this.basePath}${Routes.pharmacist.posted}/${id}`);

  pharmacistFilterValues = async () =>
    httpClient.get(
      `${this.basePath}${Routes.pharmacist.pharmacistCalendar}`,
      {}
    );

  pharmacistPicture = async () =>
    httpClient.get(`${this.basePath}${Routes.pharmacist.picture}`);

  pharmacistSummary = async (month: number) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.summary}`, {
      month: month.toString(),
    });

  getAvailability = async (id: string) => {
    return httpClient.get(
      `${this.basePath}${Routes.pharmacist.availabilites.dateRangeAvailability}/${id}`
    );
  };

  pharmacistMap = async (filter: ICalendarFilter) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.map}`, {
      calRequest: {
        ...filter,
      },
    });

  createAvailability = async (params: ICreateAvailabilityRequestParams) => {
    return httpClient.post(
      `${this.basePath}${Routes.pharmacist.availabilites.create}`,
      {
        multipleAvailabilityModel: {
          ...params,
        },
      }
    );
  };

  updateAvailability = async (params: IUpdateAvailabilitRequestParams) => {
    return httpClient.post(
      `${this.basePath}${Routes.pharmacist.availabilites.update}`,
      {
        multipleAvailabilityModel: {
          ...params.data,
          availabilityId: params.availabilityId,
          recurrenceId: params.recurrenceId || null,
        },
      }
    );
  };
  deleteAvailability = async (id?: string, recurrenceId?: string) =>
    httpClient.post(
      `${this.basePath}${Routes.pharmacist.availabilites.delete}`,
      {
        availableTimeId: id,
        recurrenceId: recurrenceId || null,
      }
    );

  requestBooking = async (params: IRequestBookingParams) =>
    httpClient.post(
      `${this.basePath}${Routes.pharmacist.bookings.submitRequest}`,
      {
        workshiftid: params.workshiftid,
        counterOffer: params?.counterOffer,
      }
    );

  cancelBooking = async (params: ICancelBooking) =>
    httpClient.post(`${this.basePath}${Routes.general.cancelRequest}`, params);

  cancelRequestedBooking = async (bookingIdArr: string) =>
    httpClient.post(
      `${this.basePath}${Routes.pharmacist.bookings.deleteRequest}`,
      {
        list: [bookingIdArr],
      }
    );

  reports = async (params: IReportFilterParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.reports}`, {
      reportFilters: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  replacements = async (params: IReplacementsParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.replacements}`, {
      pagingModel: {
        itemPerPage: ITEMS_PER_PAGE,
        ...params,
      },
    });

  history = async (params: IHistoryApiParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.history}`, {
      ...params,
      paging: {
        ...params.paging,
        itemPerPage: ITEMS_PER_PAGE,
      },
    });

  addFavourite = async (params: IHistoryParams) =>
    httpClient.post(
      `${this.basePath}${Routes.pharmacist.addFavourite}`,
      params
    );

  removeFavourite = async (params: IHistoryParams) =>
    httpClient.post(
      `${this.basePath}${Routes.pharmacist.removeFavourite}`,
      params
    );

  pharmacyNames = async () =>
    httpClient.get(`${this.basePath}${Routes.pharmacist.replacements}`);

  reportsFilterInfo = async (type: IReportOmitAvailability) =>
    httpClient.get(`${this.basePath}${Routes.pharmacist.reports}/${type}`);

  pictureUpload = async (form: any) =>
    httpClient.post(
      `${Routes.pharmacist.fileupload}${Routes.pharmacist.pharmacist}`,
      form,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

  deletePicture = async () =>
    httpClient.delete(`${this.basePath}${Routes.pharmacist.picture}`);

  resumeUpload = async (form: { document: Buffer; path: string }) =>
    httpClient.post(
      `${this.basePath}${Routes.pharmacist.resume}?path=${form.path}`,
      form.document,
      {
        headers: { 'Content-Type': 'application/pdf' },
      }
    );

  pharmacistResume = async () =>
    httpClient.get(`${this.basePath}${Routes.pharmacist.resume}`);

  deleteResume = async () =>
    httpClient.delete(`${this.basePath}${Routes.pharmacist.resume}`);

  createNote = async (pharmacyId: string, note: string) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.notes.createNote}`, {
      pharmacyId: pharmacyId,
      notes: note,
    });

  allNotes = async (pharmacyId: string) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.notes.allNotes}`, {
      pharmacyId: pharmacyId,
    });

  updateNote = async (noteId: string, note: string) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.notes.updateNote}`, {
      pharmacyNotesId: noteId,
      notes: note,
    });

  deleteNote = async (noteId: string) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.notes.deleteNote}`, {
      pharmacyNotesId: noteId,
    });

  getRegions = async () =>
    httpClient.get(`${this.basePath}${Routes.pharmacist.regions.region}`);

  updateRegions = async (regionIds: Array<string>) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.regions.region}`, {
      regions: regionIds,
    });

  createRegion = async (regionId: string) =>
    httpClient.post(
      `${this.basePath}${Routes.pharmacist.regions.createRegion}`,
      {
        regionId,
      }
    );
  deleteRegion = async (regionId: string) =>
    httpClient.delete(
      `${this.basePath}${Routes.pharmacist.regions.region}/${regionId}`
    );

  getReplacementsPDF = async () =>
    httpClient.get(
      `${this.basePath}${Routes.pharmacist.replacements}${Routes.general.getPDF}`
    );

  getReportsPdf = async (params: IReportsPDFParams) =>
    httpClient.post(
      `${this.basePath}/${params.reportFilters.type}${Routes.general.getPDF}`,
      params
    );

  getCounterOfferDetails = async (bookingId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.pharmacist.counterOffer}/${bookingId}`
    );

  acceptUpdatedTimings = async (bookingId: string) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.pharmacy.updateTime}${Routes.pharmacy.approve}`,
      { bookingId }
    );

  rejectUpdatedTimings = async (bookingId: string) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.pharmacy.updateTime}${Routes.pharmacy.reject}`,
      { bookingId }
    );

  selfPharmacistCancelBooking = async (bookingId: string) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.general.cancel}`,
      { bookingId }
    );

  preRequest = async (workshiftid: string) =>
    httpClient.post(
      `${this.basePath}${Routes.pharmacist.bookings.preBooking}`,
      {
        workshiftid,
      }
    );

  income = async (params: IIncomeReportParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.income}`, {
      criteria: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  incomeDetails = async (params: IIncomeDetailsParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.incomeDetails}`, {
      criteria: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  getEvent = async (params: IWorkshiftParams) =>
    httpClient.post(`${this.basePath}${Routes.general.workshifts}`, params);

  watchlist = async (workshiftId: string) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.watchlist}`, {
      workshiftId,
    });

  deleteWatchlist = async (workshiftId: string) =>
    httpClient.delete(
      `${this.basePath}${Routes.pharmacist.watchlist}/${workshiftId}`
    );

  preSubmitVacations = async (params: IPreSubmitVacationsParams) =>
    httpClient.post(
      `${this.basePath}${Routes.pharmacist.vacations}${Routes.general.preSubmit}`,
      {
        model: params,
      }
    );

  pharmacistVacations = async (params: IVacationsParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacist.vacations}`, {
      model: params,
    });

  getPharmacistVacations = async (id: string) =>
    httpClient.get(`${this.basePath}${Routes.pharmacist.vacations}/${id}`);

  deletePharmacistVacations = async (id: string) =>
    httpClient.delete(`${this.basePath}${Routes.pharmacist.vacations}/${id}`);

  updatePharmacistVacations = async (params: IUpdateVacationsParams) =>
    httpClient.put(`${this.basePath}${Routes.pharmacist.vacations}`, {
      model: params,
    });

  preSubmitAvailabilities = async (params: ICreateAvailabilityRequestParams) =>
    httpClient.post(
      `${this.basePath}${Routes.general.availabilities}${Routes.general.preSubmit}`,
      {
        multipleAvailabilityModel: params,
      }
    );
}
export default new PharmacistController();
