import React, { FC } from 'react';

import { FormikValues, useFormik } from 'formik';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';

import {
  AllowancesNames,
  RecurrenceActions,
  TypeOfUser,
  convertToDecimals,
  getRecurrence,
  keyToParse,
  pharmacyWorkshift,
  recurrenceType,
  updateWorkshift,
  valueCriteria,
  valuesToBeParsed,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useSelfService from '../../../../hooks/useSelfService';
import useUpdateWorkshift from '../../../../hooks/workshift/useUpdateWorkshift';
import CustomTextField from '../../../common/CustomTextField';
import AddUpdateEvents from '../../../common/AddUpdateEvents/AddUpdateEvents';
import { selectFooterPaginationCurrent } from '../../../../selectors/drawerSelector';
import { toJSDate } from '../../../Dashboard/MainCalendar/helpers';
import {
  makeReqParams,
  setHour,
} from '../../../../helpers/workshift/functions';
import genericClasses from '../../../../theme/GenericClasses';
import { InvertedRepeatMap, isIpad } from '../../../../helpers/Constants';
import strings from '../../../../localization';

import AllowanceWorkshift from '../AllowanceWorkshift';
import Validator from '../Validator';
import classes from './styles';

interface IUpdate {
  eventIds: Array<string>;
  recurrenceId?: string;
  recType?: RecurrenceActions;
}

const {
  travelAllowanceType,
  accommodationAllowanceType,
  mealAllowanceType,
  emergencyFees,
} = AllowancesNames;

const { Never } = recurrenceType;
const { pharmacist } = TypeOfUser;
const { series } = RecurrenceActions;

const Update: FC<IUpdate> = ({ eventIds, recurrenceId, recType }) => {
  const dispatch = useAppDispatch();
  const current = useAppSelector(selectFooterPaginationCurrent);

  const currentWorkshift = useAppSelector(pharmacyWorkshift);

  const {
    recurrence,
    paidHourlyRate,
    notes,
    endDate,
    startDate,
    emergencyFees: currentEmergencyFees,
  } = currentWorkshift ?? {};

  const { recurrenceType: currentRecurrenceType, endDate: recurrenceEndDate } = recurrence ?? {};

  const recurrenceSelector = useAppSelector(getRecurrence);

  const { repeatEvery } = recurrenceSelector ?? {};

  const id = eventIds[current - 1];
  const { workshiftParser, updateParser, allowedValuesParser, isSelfService } = useSelfService();

  const isSeries = recType === series;

  const { container } = classes;
  const { borderNoneTextField } = genericClasses;

  const handleSubmit = (values: FormikValues) => {
    const parsedValues = workshiftParser(values, keyToParse);
    const allowedValues = allowedValuesParser(
      values,
      parsedValues,
      valueCriteria,
    );

    const allowanceTypes = isSelfService
      ? {
        [travelAllowanceType]: values[travelAllowanceType],
        [accommodationAllowanceType]: values[accommodationAllowanceType],
        [mealAllowanceType]: values[mealAllowanceType],
      }
      : {};

    // NOTE: THE ORDER MATTERS
    dispatch(
      updateWorkshift({
        ...parsedValues,
        ...allowanceTypes,
        ...allowedValues,
        ...makeReqParams(values, recurrenceSelector),
        recurrenceId: isSeries ? recurrenceId ?? '' : null,
        workshiftId: id,
      }),
    );
  };

  const recurrenceTypeForWorkshift = isSeries
    ? currentRecurrenceType ?? Never
    : repeatEvery;

  const setEmergencyFees = currentEmergencyFees === 0 ? '' : currentEmergencyFees;

  const formik = useFormik({
    initialValues: {
      ...currentWorkshift,
      ...updateParser(currentWorkshift, valuesToBeParsed),

      [travelAllowanceType]:
        currentWorkshift[
          travelAllowanceType as keyof typeof currentWorkshift
        ]?.toString(),
      [accommodationAllowanceType]:
        currentWorkshift[
          accommodationAllowanceType as keyof typeof currentWorkshift
        ]?.toString(),
      [mealAllowanceType]:
        currentWorkshift[
          mealAllowanceType as keyof typeof currentWorkshift
        ]?.toString(),
      [emergencyFees]: setEmergencyFees,

      selectedDate: DateTime.fromISO(startDate, {
        setZone: true,
      }),

      allDay: false,
      paidHourlyRate: convertToDecimals(paidHourlyRate),
      endDate: recurrenceEndDate || endDate,
      notes,
      startTime: toJSDate(startDate),
      endTime: toJSDate(endDate),
      replacement: pharmacist,
      recurrence: InvertedRepeatMap[recurrenceTypeForWorkshift],
      isSelfService,
    },

    enableReinitialize: true,
    validationSchema: Validator(),
    onSubmit: handleSubmit,
  });

  useUpdateWorkshift(eventIds, formik, recurrenceId, recType);

  const { values, setFieldValue } = formik ?? {};
  const { selectedDate } = values ?? {};

  const setUserWorkTimings = () => {
    setFieldValue('startTime', setHour(selectedDate, 8));
    setFieldValue('endTime', setHour(selectedDate, 22));
  };

  const notesSx = isIpad ? classes.notes : {};

  return (
    <Box sx={container}>
      <AddUpdateEvents
        formik={formik}
        setUserWorkTimings={setUserWorkTimings}
        isSeries={isSeries}
        isUpdate
      />

      {isSelfService && <AllowanceWorkshift formik={formik} />}

      <Box sx={notesSx}>
        <CustomTextField
          variant="outlined"
          formik={formik}
          id="notes"
          placeholder=" "
          label={strings.notes}
          name="notes"
          multiline
          maxLength={200}
          customClass={borderNoneTextField}
        />
      </Box>
    </Box>
  );
};

export default Update;
