import { MiscType, PharmacyFilterWorkshifts } from '@pharmaplan/common';
import { ListOfWorkshiftsTabTypes } from '../../../../../helpers/Constants';
import strings from '../../../../../localization';

type HandleTabClick = (tab: ListOfWorkshiftsTabTypes) => void;

interface WorkshiftStatus {
  key: MiscType | PharmacyFilterWorkshifts;
  label: string;
}

export type WorkshiftStatuses = (isPast: boolean) => WorkshiftStatus[];

export const tabConfig = (handleTabClick: HandleTabClick) =>
  [
    {
      key: ListOfWorkshiftsTabTypes.upcomingWorkshifts,
      isDefault: true,
      tabName: strings.upcomingWorkshifts,
      onClick: handleTabClick,
    },
    {
      key: ListOfWorkshiftsTabTypes.pastWorkshifts,
      tabName: strings.pastWorkshifts,
      onClick: handleTabClick,
    },
  ];

export const workshiftStatuses = (isPast: boolean) => {
  const common = [
    { key: MiscType.All, label: strings.all },
    { key: PharmacyFilterWorkshifts.request, label: strings.requested },
    { key: PharmacyFilterWorkshifts.booked, label: strings.booked },
  ];

  if (isPast) {
    return [
      ...common,
      { key: PharmacyFilterWorkshifts.unfulfilled, label: strings.unfulfilled },
    ];
  }

  return [
    ...common,
    { key: PharmacyFilterWorkshifts.posted, label: strings.posted },
  ];
};
