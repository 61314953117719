import { Box } from '@mui/system';
import React from 'react';
import { FormikProps } from 'formik';
import { isIpad } from '../../../helpers/Constants';
import strings from '../../../localization';
import CustomTextField from '../CustomTextField';
import classes from './styles';
import genericClasses from '../../../theme/GenericClasses';

interface INotesField {
  formik: FormikProps<any>;
  disabled?: boolean;
}

const NotesField: React.FC<INotesField> = ({ formik, disabled }) =>
  (
    <Box sx={isIpad ? classes.notes : {}}>
      <CustomTextField
        variant="outlined"
        disabled={disabled}
        formik={formik}
        id="notes"
        placeholder=" "
        label={strings.notes}
        name="notes"
        multiline
        maxLength={200}
        customClass={genericClasses.borderNoneTextField}
      />
    </Box>
  );

export default NotesField;
