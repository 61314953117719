const classes = {
  iconBackground: {
    backgroundColor: 'common.lightBackgroundColor',
    padding: 1,
    borderRadius: 2,
    marginLeft: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default classes;
