import React from 'react';
import { useFormik } from 'formik';
import { userContact } from '@pharmaplan/common';
import ProfileForm from '../../ProfileForm/ProfileForm';
import { ICommonProfile } from '../General/General';
import { useAppSelector } from '../../../../hooks/useAppSelector';

const MyLocationOnMap = ({ setSelected }:ICommonProfile) => {
  const contact = useAppSelector(userContact);

  const formik = useFormik({
    initialValues: {
      ...contact,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {
    },
  });

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="myLocationOnMap"
      formik={formik}
    />
  );
};

export default MyLocationOnMap;
