import { Constants } from '../../../helpers/Constants';
import strings from '../../../localization';
import { IInputModalConfig } from '../../Modals/InputModal/InputModal';
import styles from './styles';

export const ignoreForm: IInputModalConfig[] = [
  {
    key: '0',
    name: 'startDate',
    type: Constants.formInputType.text,
    gridLabel: strings.selectedDat,
    fieldXs: 8,
    labelXs: 4,
    disabled: true,
  },
  {
    key: '1',
    name: 'pharmacyName',
    type: Constants.formInputType.text,
    gridLabel: strings.pharmacy,
    fieldXs: 8,
    labelXs: 4,
    disabled: true,
  },
  {
    key: '2',
    name: 'pharmacistName',
    type: Constants.formInputType.text,
    gridLabel: strings.pharmacist,
    fieldXs: 8,
    labelXs: 4,
    disabled: true,
  },
  {
    key: '3',
    name: 'note',
    type: Constants.formInputType.text,
    gridLabel: strings.notes,
    multiline: true,
    variant: 'filled',
    fieldXs: 8,
    labelXs: 4,
  },
  {
    key: '4',
    name: 'sendEmailToPharmacist',
    type: Constants.formInputType.checkbox,
    gridLabel: ' ',
    label: strings.sendEmailNotificationPharmacist,
    labelClass: styles.checkboxText,
    fieldXs: 8,
    labelXs: 4,
  },
];
