import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const styles = {
  label: {
    fontSize: 16,
  },
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      px: 1,
      color: 'common.grey850',
    },
  },
  textField: {
    backgroundColor: ThemeConstants.common.lightBackgroundColor,
    '& .MuiFilledInput-root:after': {
      borderBottom: 0,
    },
  },
  listBox: {
    backgroundColor: ThemeConstants.common.lightBackgroundColor,
    maxHeight: 250,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
