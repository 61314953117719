import ThemeConstants from '../../theme/ThemeConstants';

const classes = (
  color: string,
  isCenterAligned = false,
  maxWidth = 120,
  minWidth = 50,
) =>
  ({
    tableContainer: {
      boxSizing: 'border-box',
      width: '100%',
      padding: '24px',
      paddingBottom: 0,
      paddingTop: 0,
    },
    navContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 2,
    },
    checkbox: {
      padding: 0,
      paddingLeft: 2,
    },
    replacementText: {
      color: ThemeConstants.grey[500],
    },
    chip: {
      display: 'inline-block',
      borderRadius: '2px',
      padding: '2px 8px',
    },
    chipText: {
      fontSize: 12,
      fontWeight: '500',
    },
    divider: {
      marginX: 1,
    },
    labelText: {
      fontWeight: '500',
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between;',
      alignItems: 'center',
      paddingBlock: '10px 20px',
      paddingTop: '16px',
      paddingInline: '8px',
    },
    tableHeaderText: {
      fontSize: 18,
      fontWeight: 600,
    },
    tableCellAction: {
      padding: '0',
      marginRight: '10px',
      color: ThemeConstants.common.gray,
    },
    cell: {
      minWidth,
      maxWidth,
    },
    note: {
      minWidth: 50,
      maxWidth: 200,
    },
    dateColumn: {
      minWidth: 10,
      maxWidth: 30,
    },
    text: {
      overflowWrap: 'break-word',
      whiteSpace: 'pre-line',
      fontSize: '14px',
      justifyContent: isCenterAligned ? 'center' : 'left',
      display: isCenterAligned ? 'flex' : 'block',
    },
    smallerText: {
      overflowWrap: 'break-word',
      whiteSpace: 'pre-line',
      fontSize: 12,
      color: 'common.gray',
      justifyContent: isCenterAligned ? 'center' : 'left',
      display: isCenterAligned ? 'flex' : 'block',
    },
    smallerLinkText: {
      overflowWrap: 'break-word',
      whiteSpace: 'pre-line',
      fontSize: 12,
      textAlign: 'left',
      justifyContent: 'left',
      display: 'flex',
    },
    underline: {
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'white ',
      },
    },
    customLink: {
      fontSize: 14,
      fontWeight: 500,
    },
    linkSep: {
      mr: '7px',
    },
    cellFontColor: {
      color,
    },
    imageCell: {
      height: 'auto',
      maxHeight: '95px',
      width: 'auto',
      maxWidth: '95px',
      alignItems: 'center',
    },
    maxNote: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 4,
      overflow: 'hidden',
    },
    menuItem: {
      paddingX: 1,
    },
    tableHeaderCell: {
      textAlign: isCenterAligned ? 'center' : 'left',
    },
    backButtonContainer: {
      display: 'flex',
      padding: '8px 12px',
      borderRadius: 10,
      alignItems: 'center',
    },
    backIcon: {
      color: 'grey.600',
    },
    backButtonText: {
      color: 'grey.600',
      marginLeft: '5px',
      fontSize: 16,
      fontWeight: 500,
    },
    basicContainerStyle: {
      width: '100%',
      boxSizing: 'border-box',
      borderRadius: '10px',
    },
    customLinkGeneric: {
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
      textDecoration: 'underline',
      color: 'primary.main',
      transition: 'background-color 0.3s',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    menu: {
      mt: '0px',
    },
    menuText: {
      fontSize: 12,
    },
    menuButton: {
      padding: 0,
      height: 24,
    },
    barStyle: {
      display: 'flex',
      justifyContent: isCenterAligned ? 'center' : 'left',
    },
    labelValueContainer: {
      display: 'flex',
    },
    labelTitle: {
      fontWeight: '600',
      fontSize: 14,
      mr: '2px',
      color: 'common.gray',
    },
    labelValue: {
      fontSize: 14,
      color: 'common.gray',
      maxWidth: '300px',
      minWidth: '96%',
    },
    userType: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: '15px',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingX: 1,
    },
  });

export default classes;
