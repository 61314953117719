/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/system';

import {
  adminActions,
  AdminLegendVariant,
  adminShowAvailabilities,
  adminShowAvailabilitiesPagination,
  getPharmacistDetails,
  ITEMS_PER_PAGE,
  pharmacistAvailabilities,
  resetStatus,
  successSelector,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { Typography } from '@mui/material';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';
import AdminPharmacistCard from '../Profile/Pharmacist/PharmacistViewProfile/AdminPharmacistCard';

import {
  showAvailabilitiesHeader,
  showAvailabilitiesRowConfig,
} from './helper';
import strings from '../../../localization';
import styles from './styles';
import { renderScreen, setFooterShow } from '../../../actions/drawerActions';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import useShowPharmacistAvailabilities from '../../../hooks/Admin/ShowPharmacistAvailabilities/useShowPharmacistAvailabilities';
import { showSuccess } from '../Profile/Pharmacist/PharmacistViewProfile/helper';
import { OtherScreens, ScreenTypes } from '../../../helpers/Constants';
import genericClasses from '../../../theme/GenericClasses';
import NoAvailabilities from '../MatchingAvailabilities/NoAvailabilities';

interface IShowPharmacistAvailabilities {
  pharmacistId: string;
}

const successAction = adminActions.showAvailabilities;
const deleteSuccessAction = adminActions.deletePharmacistAvailabilities;

const ShowPharmacistAvailabities: FC<IShowPharmacistAvailabilities> = ({
  pharmacistId,
}) => {
  const dispatch = useAppDispatch();
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const loadSuccess = useAppSelector((state) =>
    successSelector([successAction], state));

  const deleteSuccess = useAppSelector((state) =>
    successSelector([deleteSuccessAction], state));

  const availabilities = useAppSelector(pharmacistAvailabilities);
  const { actions } = useShowPharmacistAvailabilities();

  const [page, setPage] = useState(1);

  const { data } = availabilities ?? {};

  const observerCallback = useCallback(
    (entries: { isIntersecting: any }[]) => {
      const paginationCondition = data.length >= page * ITEMS_PER_PAGE;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    [page, data],
  );

  const { listEndRef } = useInfiniteScroll({ observerCallback });

  useEffect(() => {
    dispatch(getPharmacistDetails(pharmacistId));
    dispatch(adminShowAvailabilities({ pharmacistId, paging: { page: 1 } }));
    dispatch(setFooterShow(false));
  }, []);

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        adminShowAvailabilitiesPagination({ pharmacistId, paging: { page } }),
      );
    }
  }, [page]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(adminShowAvailabilities({ pharmacistId, paging: { page: 1 } }));
      showSuccess(dispatch, strings.recordDeletedSuccessfully);
      dispatch(resetStatus([deleteSuccessAction]));
    }
  }, [deleteSuccess]);

  const table: IDynamicTableObject = {
    headerBar: [],
    headers: showAvailabilitiesHeader(),
    rows: showAvailabilitiesRowConfig(data, timeFormat, actions),
  };

  const Title = useCallback(
    () =>
      <Typography sx={styles.title}>{strings.availabilities}</Typography>,
    [],
  );

  const ratingGoBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 4,
        pharmacistId,
        screenType: ScreenTypes.pharmacistList,
      }),
    );
  };

  const emptyContainerComponent = data.length === 0 ? (
    <NoAvailabilities message={strings.noAvailabilitiesToShow} />
  ) : null;

  return (
    <Box>
      <AdminPharmacistCard
        pharmacistId={pharmacistId}
        type={AdminLegendVariant.posted}
        hasOwner={false}
        ratingGoBack={ratingGoBack}
        fromScreen={OtherScreens.AdminAvailabilites}
      />
      <DynamicTable
        customContainerStyle={styles.tableContainer}
        noContainerStyling
        emptyContainerComponent={emptyContainerComponent}
        showHeader={false}
        customHeader={<Title />}
        loadSuccess={loadSuccess}
        table={table}
        hidePagination
      />
      {loadSuccess && (
        <div ref={listEndRef} style={genericClasses.paginationDiv} />
      )}
    </Box>
  );
};

export default ShowPharmacistAvailabities;
