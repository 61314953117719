import { createReducer } from '@reduxjs/toolkit';
import {
  login,
  logout,
  getUserContact,
  getUserGeneral,
  setUserLanguage,
  getProInfo,
  getAllBannerLogos,
  getChangePassword,
  profileUtils,
  getSoftware,
  getUserPrimaryContact,
  getUserSecondaryContact,
  getUserAccountingContact,
  getPharmacyInfo,
  getUserCoordinates,
  updateUserGeneral,
  setPreLoginLangWeb,
  forgotPassword,
  setRequestReset,
  verifyOtp,
  getFaq,
  getUsername,
  resetName,
  getTermsAndConditions,
  istermsAgreed,
  setLocation,
  setAddress,
  resetValidOtp,
  resetUpdateOperations,
  resetSignedUp,
  resetApproval,
  setUserProps,
  doesEmailExist,
  setSignedUp,
  smartLogin,
  setStartTour,
  getUserPreferredTheme,
  setUserPreferredTheme,
  getUserConfigurations,
  sendOtp,
  authVerifyOtp,
  setEmailChangeReset,
  resetLoggedIn,
  setUserType,
  resetProfileImage,
  getClientAdvertisements,
} from '../actions/userActions';
import httpClient from '../controllers/httpClient';
import {
  responseType,
  TypeOfUser,
  TimeFormat,
  SocialLoginType,
  Language,
  ColorScheme,
  LoginType,
} from '../helpers/Constants';
import {
  IContact,
  IFaq,
  IGeneral,
  ILogos,
  IProfessionalInfo,
  ISoftware,
  IUtils,
} from '../models/PharmacistModels';
import AvatarFallback from '@pharmaplan/common/assets/icons/avatarPlaceholder.png';
import {
  getAdminPicture,
  getAdminSettings,
  getPharmacistPicture,
  getPharmacyAdminPicture,
  getPharmacyAdminProfile,
  impersonatePharmacy,
} from '../actions';
import { convertBase64ToImage } from '../helpers/utils';
import { ICoordinates, IPharmacyInfo, IUserPropsModel } from '../models';
import serverDateHandler from '../helpers/serverDateHandler';
import { IClientAdvertisement, IUserConfigurations } from '../models/Models';
import LogoutHandler from '../helpers/LogoutHandler';

export interface IUserReducerState {
  isPharmacyAdminLogin: boolean;
  isAvatar: boolean;
  email: string;
  userId: string;
  userProps: IUserPropsModel;
  contact: IContact;
  userName: string;
  name: string;
  enforceProfileUpdate: boolean;
  requestedReset: boolean;
  rememberMeHash: string;
  isValidOtp: boolean;
  isLoggedIn: boolean;
  isSignedUp: boolean;
  isApproved: boolean;
  general: IGeneral;
  profileImage: string | any;
  language: Language;
  preLoginWebLang: string;
  userType: TypeOfUser;
  proInfo: IProfessionalInfo;
  software: Array<ISoftware>;
  logos: Array<ILogos>;
  changePassword: boolean;
  userLocation: {
    latitude: number | null;
    longitude: number | null;
  };
  utils: IUtils;
  coordinates: ICoordinates;
  faq: Array<IFaq>;
  primaryContact: any;
  secondaryContact: any;
  accountingContact: any;
  pharmacyInfo: IPharmacyInfo;
  preferredLanguage: Language;
  timeFormat: TimeFormat;
  termsAndConditionsHTMLTemplate: string;
  address: string;
  emailExists: boolean | null;
  startTour: boolean;
  userTheme: ColorScheme;
  userConfigurations: IUserConfigurations;
  didRequestChangeEmail: boolean;
  enforceLogout: boolean;
  enableChat: boolean;
  advertisement: Array<IClientAdvertisement>;
}

export const userInitialState: IUserReducerState = {
  advertisement: [],
  isPharmacyAdminLogin: false,
  enableChat: true,
  didRequestChangeEmail: false,
  emailExists: null,
  isAvatar: false,
  userId: '',
  userConfigurations: { minHourWorkshift: 4, numberOfDaysToRatePharmacist: 14 },
  userProps: {
    email: '',
    password: '',
    appid: '',
    token: '',
    loginType: SocialLoginType.facebook,
    device: {
      deviceId: '',
      type: '',
      osType: '',
      osVersion: '',
      deviceToken: null,
    },
  },
  profileImage: AvatarFallback,
  email: '',
  enforceProfileUpdate: false,
  userName: '',
  isLoggedIn: false,
  language: Language.en,
  preLoginWebLang: Language.en,
  isSignedUp: false,
  requestedReset: false,
  isValidOtp: false,
  faq: [],
  userType: TypeOfUser.pharmacy,
  rememberMeHash: '',
  contact: {
    address: '',
    avilabilityReminder: false,
    termsAndConditions: false,
    city: '',
    homePhone: '',
    mobile: '',
    postalcode: '',
    preferredReplacementDuration: '',
    preferredWorkingDays: [],
    province: '',
    //Setting this as the default value because this is the client's location
    latitude: 45.5019,
    longitude: -73.5674,
    prefferedReplacementDurations: [],
  },
  general: {
    firstName: '',
    signupType: LoginType.pharmaplan,
    lastName: '',
    preferdLanguage: '',
    timeFormat: TimeFormat.twentyFourHours,
  },
  termsAndConditionsHTMLTemplate: '',
  software: [],
  secondaryContact: {
    secondaryContactName: '',
    secondaryContactPhone: '',
    secondaryContactPhoneExt: '',
    secondaryContactMobile: '',
    secondaryContactEmail: '',
  },
  primaryContact: {
    primaryContactName: '',
    primaryContactPhone: '',
    primaryContactPhoneExt: '',
    primaryContactMobile: '',
    primaryContactEmail: '',
  },
  accountingContact: {
    accountingContactName: '',
    accountingContactPhone: '',
    accountingContactPhoneExt: '',
    accountingContactMobile: '',
    accountingContactEmail: '',
  },
  coordinates: {
    phone: '',
    fax: '',
    emergencyPhone: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
    latitude: 45.5019,
    longitude: -73.5674,
    provinces: [],
  },
  userLocation: {
    latitude: 45.5019,
    longitude: -73.5674,
  },
  pharmacyInfo: {
    name: '',
    languageId: '',
    number: 0,
    softwareId: '',
    pharmacyChainId: '',
    selectedContactPreferences: [],
    weekVolume: '',
    weekendVolume: '',
    weekDayTechs: '',
    weekNightTechs: '',
    weekendDayTechs: '',
    weekendNightTechs: '',
    weekDayPharmacists: '',
    weekNightPharmacists: '',
    weekendDayPharmacists: '',
    weekendNightPharmacists: '',
    logo: '',
    email: '',
    hourlyRate: 0,
    timeFormat: TimeFormat.twentyFourHours,
    softwares: [],
    contactPreferences: [],
  },
  proInfo: {
    englishLevel: '',
    frenchLevel: '',
    graduationYear: '',
    incorporated: false,
    licenseNumber: '',
    otherLanguages: null,
    pharmacistCapacityDesc: '',
    pharmacistCapacityId: '',
    yearsOfExperience: '',
    preferdLanguage: '',
    pharmacistCapacities: [],
    userType: TypeOfUser.pharmacist,
    preferredWorkingDays: '',
    prefferedReplacementDurations: [],
  },
  logos: [],
  changePassword: false,
  isApproved: true,
  preferredLanguage: Language.en,
  timeFormat: TimeFormat.twentyFourHours,
  utils: { softwares: [], banners: [], contactPreferences: [] },
  address: '',
  startTour: false,
  userTheme: ColorScheme.auto,
  enforceLogout: false,
  name: '',
};

const userReducer = createReducer(userInitialState, (builder) => {
  builder
    .addCase(login.fulfilled, (state, action) => {
      const {
        id,
        approved,
        userType,
        enforceProfileUpdate,
        language,
        key,
        serverDate,
        userId,
        name,
        email,
        enableChat,
      } = action.payload ?? {};
      if (approved) {
        state.enforceProfileUpdate = enforceProfileUpdate;
        state.isPharmacyAdminLogin = userType === TypeOfUser.pharmacyAdmin;
        state.userId = userId;
        serverDateHandler.setDate(serverDate);
        state.userType = userType;
        state.language = language?.code;
        state.isLoggedIn = !!userId;
        state.name = name;
        state.email = email;
        state.enableChat = enableChat;
        LogoutHandler.userIsLoggedIn();
      } else {
        state.isApproved = approved;
      }
    })
    .addCase(smartLogin.fulfilled, (state, action) => {
      const {
        userType,
        id,
        language,
        isSignedUp,
        approved,
        serverDate,
        userId,
        name,
        email,
      } = action.payload ?? {};
      const { code } = language;
      state.userType = userType;
      state.userId = userId;
      serverDateHandler.setDate(serverDate);
      state.isSignedUp = !id;
      state.language = code;
      state.isLoggedIn = !state.isSignedUp;
      state.isApproved = approved;
      state.name = name;
      state.email = email;
    })
    //reset all state on logout
    .addCase(logout.fulfilled, (state) => {
      LogoutHandler.userIsLoggedOut();
      return { ...userInitialState, preLoginWebLang: state.preLoginWebLang };
    })
    .addCase(getUserContact.fulfilled, (state, action) => {
      state.contact = action.payload;
    })
    .addCase(setUserProps, (state, action) => {
      state.userProps = action.payload;
      state.userType = action.payload.userType as TypeOfUser;
    })
    .addCase(doesEmailExist.fulfilled, (state, action) => {
      state.emailExists = action.payload;
    })
    .addCase(getUserPrimaryContact.fulfilled, (state, action) => {
      state.primaryContact = action.payload;
    })
    .addCase(getUserSecondaryContact.fulfilled, (state, action) => {
      state.secondaryContact = action.payload;
    })
    .addCase(getUserAccountingContact.fulfilled, (state, action) => {
      state.accountingContact = action.payload;
    })
    .addCase(resetApproval, (state) => {
      state.isApproved = true;
      state.emailExists = null;
    })
    .addCase(getUserGeneral.fulfilled, (state, action) => {
      state.general = action.payload;
      state.preferredLanguage = action.payload.preferdLanguage;
      state.email = action.payload.email;
      state.timeFormat = action.payload.timeFormat;
    })
    .addCase(getUserCoordinates.fulfilled, (state, action) => {
      state.coordinates = action.payload;
    })
    .addCase(setUserLanguage, (state, action) => {
      httpClient.defaults.headers.common['locale'] = action.payload;
      state.language = action.payload as Language;
    })
    .addCase(setPreLoginLangWeb, (state, action) => {
      httpClient.defaults.headers.common['locale'] = action.payload;
      state.preLoginWebLang = action.payload;
    })
    .addCase(getProInfo.fulfilled, (state, action) => {
      state.proInfo = action.payload;
    })
    .addCase(getPharmacyInfo.fulfilled, (state, action) => {
      state.pharmacyInfo = action.payload;
      state.preferredLanguage = action.payload.languageId;
      state.profileImage = action.payload.logo;
      state.email = action.payload.email;
      state.timeFormat = action.payload.timeFormat;
    })
    .addCase(getAllBannerLogos.fulfilled, (state, action) => {
      state.logos = action.payload;
    })
    .addCase(getChangePassword.fulfilled, (state, action) => {
      state.changePassword = action.payload.success;
    })
    .addCase(profileUtils.fulfilled, (state, action) => {
      state.utils = action.payload;
    })
    .addCase(getPharmacistPicture.fulfilled, (state, action) => {
      state.isAvatar = !!action.payload.image;
      state.profileImage = action.payload.image
        ? convertBase64ToImage(action.payload.image, action.payload.contentType)
        : AvatarFallback;
    })
    .addCase(forgotPassword.fulfilled, (state, action) => {
      state.requestedReset = action.payload?.key === responseType.success;
    })
    .addCase(setRequestReset, (state, action) => {
      state.requestedReset = action.payload;
    })
    .addCase(setEmailChangeReset, (state, action) => {
      state.didRequestChangeEmail = action.payload;
    })
    .addCase(verifyOtp.fulfilled, (state, action) => {
      state.isValidOtp = action.payload?.isValidOTP;
    })
    .addCase(authVerifyOtp.fulfilled, (state, action) => {
      state.isValidOtp = action.payload?.isValidOTP;
      state.enforceLogout = action.payload?.enforceLogout;
    })
    .addCase(resetValidOtp, (state) => {
      state.isValidOtp = false;
    })
    .addCase(getFaq.fulfilled, (state, action) => {
      state.faq = action.payload;
    })
    .addCase(getUsername.fulfilled, (state, action) => {
      state.userName = action.payload;
    })
    .addCase(resetName, (state) => {
      state.userName = '';
    })
    .addCase(getSoftware.fulfilled, (state, action) => {
      state.software = action.payload;
    })
    .addCase(getTermsAndConditions.fulfilled, (state, action) => {
      state.termsAndConditionsHTMLTemplate = action.payload.content;
    })
    .addCase(setLocation, (state, action) => {
      state.userLocation = action.payload;
    })
    .addCase(setAddress, (state, action) => {
      state.address = action.payload;
    })
    .addCase(resetUpdateOperations, (state) => {
      state.enforceProfileUpdate = userInitialState.enforceProfileUpdate;
    })
    .addCase(resetSignedUp, (state) => {
      state.isSignedUp = userInitialState.isSignedUp;
    })
    .addCase(setSignedUp, (state, action) => {
      state.isSignedUp = action.payload;
    })
    .addCase(istermsAgreed, (state, action) => {
      state.contact.termsAndConditions = action.payload;
    })
    .addCase(setStartTour, (state, action) => {
      state.startTour = action.payload;
    })
    .addCase(getUserPreferredTheme.fulfilled, (state, action) => {
      state.userTheme = action.payload;
    })
    .addCase(getUserConfigurations.fulfilled, (state, action) => {
      state.userConfigurations = action.payload;
    })
    .addCase(getAdminSettings.fulfilled, (state, action) => {
      state.userConfigurations.minHourWorkshift =
        action.payload?.minHourWorkshift;
    })
    .addCase(sendOtp.fulfilled, (state, action) => {
      state.didRequestChangeEmail =
        action.payload?.key === responseType.success;
    })
    .addCase(resetLoggedIn, (state, action) => {
      state.isLoggedIn = false;
    })
    .addCase(setUserType, (state, action) => {
      state.userType = action.payload;
    })
    .addCase(resetProfileImage, (state) => {
      state.profileImage = AvatarFallback;
    })
    .addCase(setUserPreferredTheme.fulfilled, (state, action) => {
      state.userTheme = action.meta.arg;
    })
    .addCase(getPharmacyAdminProfile.fulfilled, (state, action) => {
      state.name = action.payload?.pharmacyCompany?.user?.userName;
    })
    .addCase(getClientAdvertisements.fulfilled, (state, action) => {
      state.advertisement = action.payload;
    })
    .addCase(getAdminPicture.fulfilled, (state, action) => {
      state.isAvatar = !!action.payload.image;
      state.profileImage = action.payload.image
        ? convertBase64ToImage(action.payload.image, action.payload.contentType)
        : AvatarFallback;
    })
    .addCase(impersonatePharmacy.fulfilled,(state,action)=>{
      state.userId = action.payload?.userId;
    })
    .addCase(getPharmacyAdminPicture.fulfilled, (state, action) => {
      state.isAvatar = !!action.payload.image;
      state.profileImage = action.payload.image
        ? convertBase64ToImage(action.payload.image, action.payload.contentType)
        : AvatarFallback;
    });
});

export default userReducer;
