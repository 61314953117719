import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as Pharmaplan } from '@pharmaplan/common/assets/icons/pharmaplanText.svg';
import { ReactComponent as Logo } from '@pharmaplan/common/assets/icons/bigLogo.svg';

import { Close } from '@mui/icons-material';
import strings from '../../../localization';
import classes from './styles';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { resetDialog } from '../../../reducers/dialogReducer';

interface IApproval {
  showCloseButton: boolean;
}

const ApprovalModal: React.FC<IApproval> = ({ showCloseButton }) => {
  const dispatch = useAppDispatch();
  const handleClose = () =>
    dispatch(resetDialog());

  return (
    <>
      {showCloseButton && (
      <Grid sx={classes.closeContainer}>
        <IconButton size="small" sx={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </Grid>
      )}
      <Grid sx={classes.container} spacing={2} container>
        <Grid item>
          <Logo />
        </Grid>
        <Grid item>
          <Pharmaplan />
        </Grid>
        <Grid item>
          <Typography sx={classes.text}>{strings.thankyouRegistration}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default ApprovalModal;
