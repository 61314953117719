import React from 'react';

import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';

interface ICustomPickerDay {
  day: DateTime;
  pickersDayProps: PickersDayProps<DateTime>;
  disabled?: boolean;
  handleDaySelect: (date: DateTime) => void;
  selectedDates: Array<string>;
}

const CustomPickerDay = ({
  pickersDayProps,
  disabled,
  day,
  selectedDates,
  handleDaySelect,
}: ICustomPickerDay) =>
  (
    <PickersDay
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...pickersDayProps}
      selected={selectedDates?.includes(
        day.toISO({ includeOffset: false }) ?? '',
      )}
      onDaySelect={handleDaySelect}
      disabled={disabled}
    />
  );

export default CustomPickerDay;
