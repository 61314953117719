import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { pipe } from 'rambda';
import { FormikProps } from 'formik';
import classes from './style';
import { reduceWeekToName, selectWeek, weekElements } from './helper';
import genericClasses from '../../../theme/GenericClasses';

interface ICustomWeekSelector {
  handleChange: (selectedMonths: string[]) => void;
  initialWeeks: string;
  customClass: Record<string, unknown>;
  formik: FormikProps<any>;
  name: string;
}

const CustomWeekSelector = ({
  handleChange,
  initialWeeks,
  customClass,
  formik,
  name,
}: ICustomWeekSelector) => {
  const [weeks, setWeeks] = useState(weekElements());

  const isFormikError = formik.touched[name] && !!formik.errors[name];

  useEffect(() => {
    if (initialWeeks.length > 0) {
      initialWeeks.split(',').forEach((week) => {
        setWeeks((prev) =>
          ({
            ...prev,
            [week]: {
              fullName: weeks[week].fullName,
              value: weeks[week]?.value,
              selected: !weeks[week]?.selected,
            },
          }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box component="div" sx={[classes.monthSelectorContainer, customClass]}>
        {Object.keys(weeks).map((mon) => {
          const sundayFirstIndex = (parseInt(mon, 10) + 1) % 7;
          return (
            <Box
              component="div"
              key={mon}
              onClick={() => {
                pipe(selectWeek, reduceWeekToName, handleChange)(
                  sundayFirstIndex?.toString(),
                  setWeeks,
                  weeks,
                );
              }}
              sx={[
                classes.monthElement,
                weeks[sundayFirstIndex].selected && classes.monthSelected,
              ]}
            >
              {weeks[sundayFirstIndex].fullName.slice(0, 1)}
            </Box>
          );
        })}
      </Box>
      {isFormikError && (
        <Typography sx={[genericClasses.error]}>
          {formik.errors[name] as string}
        </Typography>
      )}
    </>
  );
};

export default CustomWeekSelector;
