import {
  getReportIssue,
  pharmacyActions,
  pharmacyReportIssueData,
  pharmacyReportIssueResponse,
  reportIssue,
  successSelector,
} from '@pharmaplan/common';
import React, {

  useEffect } from 'react';
import { FormikValues, useFormik } from 'formik';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import { Box } from '@mui/material';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import CustomDropdown from '../../common/CustomDropdown/CustomDropdown';
import CustomTextField from '../../common/CustomTextField/CustomTextField';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';
import classes from './styles';
import CustomDialogActions from '../../common/CustomModal/ModalCommon/CustomDialogActions/CustomDialogActions';
import { resetDialog, setDialog } from '../../../reducers/dialogReducer';
import Validator from './Validator';
import ReportAnIssueSuccess from './ReportAnIssueSuccess/ReportAnIssueSucess';
import OutcomeModal from '../../Modals/OutcomeModal/OutcomeModal';
import { OutcomeModalTypes } from '../../../helpers/Constants';

interface ReportAnIssueProps {
  bookingId: string;
}
const ReportAnIssue: React.FC<ReportAnIssueProps> = ({ bookingId }) => {
  const dispatch = useAppDispatch();

  const reportIssueData = useAppSelector(pharmacyReportIssueData);
  const reportIssueResp = useAppSelector(pharmacyReportIssueResponse);

  const { code } = reportIssueResp;

  const reportSuccess = useAppSelector((state) =>
    successSelector([pharmacyActions.reportIssue], state));

  const handleSubmit = (values: FormikValues) => {
    dispatch(
      reportIssue({
        bookingId,
        description: values.description,
        issueType: values.issues,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      issues: '',
      description: '',
    },
    onSubmit: handleSubmit,
    validationSchema: Validator(),
  });

  useEffect(() => {
    dispatch(getReportIssue());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportSuccess) {
      dispatch(
        setDialog({
          showCloseButton: true,
          Component: (
            <OutcomeModal
              type={OutcomeModalTypes.submitSuccess}
              message={<ReportAnIssueSuccess refNo={code} />}
            />
          ),
          heading: {
            title: '',
          },
        }),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSuccess]);

  return (
    <>
      <Box sx={classes.mainContianer}>
        <Box sx={classes.dropdown}>
          <CustomDropdown
            label={strings.selectIssue}
            formik={formik}
            horizontal
            name="issues"
            menuItems={dropdownMapper(reportIssueData, 'key', 'name')}
            errorContainer={false}
          />
        </Box>

        <CustomTextField
          formik={formik}
          label={strings.issueDescription}
          name="description"
          id="description"
          placeholder={' '}
          multiline
          horizontal
          maxLength={200}
          customClass={classes.description}
        />
      </Box>

      <Box sx={classes.buttonContainer}>
        <CustomDialogActions
          primaryFunction={formik.handleSubmit}
          primaryBtnTitle={strings.submitIssue}
          secondaryBtnTitle={strings.cancel}
          secondaryFunction={() =>
            dispatch(resetDialog())}
        />
      </Box>
    </>
  );
};

export default ReportAnIssue;
