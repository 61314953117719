import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const styles = {
  titleContainer: {
    backgroundColor: ThemeConstants.secondary.main,
    py: '15px',
    borderRadius: '10px 10px 0 0',
  },
  title: {
    color: ThemeConstants.grey[800],
    fontSize: 16,
    fontWeight: '600',
    ml: '25px',
  },
  footerContainer: {
    mb: '12px',
    mt: '2px',
  },
  footer: {
    fontSize: 12,
    color: ThemeConstants.common.grey850,
  },

  checkboxText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '16px',
    alignItems: 'center',
    mt: 1,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
