import { Box, SxProps, Typography } from '@mui/material';
import React, { FC } from 'react';
import classes from './style';
import WithSkeleton from '../WithSkeleton';

interface IDisplayChip {
  value: string | number;
  style: SxProps;
  successAction?: string;
}

const DisplayChip: FC<IDisplayChip> = ({ value, style, successAction }) => {
  const SkeletonTypography = WithSkeleton({
    successAction: successAction ?? '',
    variant: 'text',
  })(Typography);

  return (
    <Box sx={[classes.root, style] as SxProps}>
      {successAction ? (
        <SkeletonTypography sx={classes.value}>{value}</SkeletonTypography>
      ) : (
        <Typography sx={classes.value}>{value}</Typography>
      )}
    </Box>
  );
};

export default DisplayChip;
