import {createAction, Draft} from '@reduxjs/toolkit';
import {BottomSheetTypes} from '../helpers/Constants';

export const BottomSheetActions = {
  setProps: 'bottomSheet/setProps',
};

export const setProps = createAction<{
  title?: string;
  customStyle?: any;
  type?: BottomSheetTypes;
  extraProps?: any;
  open: boolean;
}>(BottomSheetActions.setProps);
