import React, { FC } from 'react';

import { Box } from '@mui/system';
import { FormikProps } from 'formik';

import AdminPharmacySearchFields from '../../../../common/AdminPharmacySearchFields';
import styles from './styles';

interface IAppointExistingPharmacyHeader {
  formik: FormikProps<any>;
  handleReset: () => void;
}

const AppointExistingPharmacyHeader: FC<IAppointExistingPharmacyHeader> = ({
  formik,
  handleReset,
}) =>
  (
    <Box component="form" onSubmit={formik.handleSubmit} sx={styles.container}>
      <AdminPharmacySearchFields formik={formik} handleReset={handleReset} />
    </Box>
  );

export default AppointExistingPharmacyHeader;
