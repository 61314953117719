import React, { FC, useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';
import { Box } from '@mui/system';

import {
  adminActions,
  adminPharmacistPictures,
  adminPharmacistResume,
  adminResetValue,
  getAdminPharmacistResumes,
  getPharmacistDetails,
  pharmacistDetails,
  resetStatus,
  successSelector,
  uploadPharmacistResume,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import UploadDialog from '../../../../../common/UploadDialog';
import { ReactComponent as UploadIcon } from '../../../../../../assets/svg/fileUploadIcon.svg';
import strings from '../../../../../../localization';

import { showSuccess } from '../helper';
import styles from './style';
import {
  convertToBase64,
  openBase64Doc,
  openBase64PDF,
} from '../../../../../../helpers/Functions';

interface IUploadResume {
  pharmacistId: string;
}

const fileTypes = '.pdf, .doc, .docx';

const UploadResume: FC<IUploadResume> = ({ pharmacistId }) => {
  const dispatch = useAppDispatch();

  const uploadSuccess = useAppSelector((state) =>
    successSelector([adminActions.uploadPharmacistResume], state));

  const fileSuccess = useAppSelector((state) =>
    successSelector([adminActions.getAdminPharmacistResumes], state));

  const resumeData = useAppSelector(adminPharmacistResume);
  const details = useAppSelector(pharmacistDetails);

  const { pharmacist } = details ?? {};
  const { resumeName } = pharmacist ?? {};

  const { resume: resumeBase64 } = resumeData ?? {};

  const onSubmit = (values: FormikValues) => {
    const { resume: selectedFile } = values ?? {};

    let form = null;

    if (selectedFile) {
      form = new FormData();
      form.append('resume', selectedFile);
    }

    dispatch(uploadPharmacistResume({ pharmacistId, form }));
  };

  const formik = useFormik({
    initialValues: {
      resume: {
        name: resumeName,
        size: null,
      },
      isResumeChanged: false,
    },
    enableReinitialize: true,
    onSubmit,
  });

  const { setFieldValue, values } = formik ?? {};
  const { resume, isResumeChanged } = values ?? {};
  const { size, name } = resume ?? {};

  const handleClear = () => {
    setFieldValue('resume', null);
    setFieldValue('isResumeChanged', true);
  };

  const isPdfFile = name?.toLowerCase().endsWith('.pdf');
  const openHandler = isPdfFile ? openBase64PDF : openBase64Doc;

  const openDocument = () => {
    if (resume instanceof File) {
      convertToBase64(resume).then((res) => {
        openHandler(res, true, name);
      });
    } else {
      dispatch(getAdminPharmacistResumes(pharmacistId));
    }
  };

  useEffect(() => {
    if (name) {
      setFieldValue('isResumeChanged', !!size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  useEffect(() => {
    if (fileSuccess) {
      openHandler(resumeBase64, false, name);
      dispatch(adminResetValue([adminActions.getAdminPharmacistResumes]));
      dispatch(resetStatus([adminActions.getAdminPharmacistResumes]));
    }
    dispatch(adminResetValue(['pharmacistResumes']));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSuccess, resumeBase64]);

  useEffect(() => {
    if (uploadSuccess) {
      showSuccess(dispatch, strings.profileDataSaved);
      dispatch(resetStatus());
      dispatch(adminPharmacistPictures([pharmacistId]));
      dispatch(getPharmacistDetails(pharmacistId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSuccess]);

  return (
    <UploadDialog
      formik={formik}
      message={strings.sizeOfResume}
      name="resume"
      fileSize={2000}
      chooseLabel={strings.uploadFile}
      errorMessage={strings.sizeOfResume}
      acceptedFileTypes={fileTypes}
      handleClear={handleClear}
      handleOpen={openDocument}
      disableButton={!isResumeChanged}
      customComponent={(
        <Box sx={styles.root}>
          <UploadIcon width={80} height={80} />
        </Box>
      )}
    />
  );
};

export default UploadResume;
