import React, { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { IRating, totalRatingValue } from '@pharmaplan/common';
import { ReactComponent as RatingStar } from '@pharmaplan/common/assets/icons/ratingStar.svg';

import strings from '../../../../../../localization';
import WithSkeleton from '../../../../../common/WithSkeleton';
import { SkeletonVariants } from '../../../../../../helpers/Constants';

import classes from './styles';

const {
  root,
  pharmacistName,
  reviewsText,
  totalRatingText,
  ratingIcon,
  ratingText,
  container,
  ratingInfo,
} = classes;

interface IViewPharmacistRatingHeader {
  ratingDetails: IRating;
  successAction: string;
}

const ViewPharmacistRatingHeader: FC<IViewPharmacistRatingHeader> = ({
  ratingDetails,
  successAction,
}) => {
  const theme = useTheme();

  const {
    palette: {
      primary: { main },
    },
  } = theme ?? {};

  const SkeletonBox = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Box);

  const { name, totalRating, reviews } = ratingDetails ?? {};
  const parsedTotalRating = parseFloat(totalRating ?? '');
  const outOf = `/${totalRatingValue}`;
  const pharmacistReviews = `(${reviews} ${strings.reviews})`;

  return (
    <Box sx={container}>
      <SkeletonBox sx={root}>
        <Typography sx={pharmacistName}>{name}</Typography>

        <Box sx={ratingInfo}>
          <RatingStar style={ratingIcon} fill={main} />
          <Typography sx={ratingText}>{parsedTotalRating}</Typography>
          <Typography sx={totalRatingText}>{outOf}</Typography>
          <Typography sx={reviewsText}>{pharmacistReviews}</Typography>
        </Box>
      </SkeletonBox>
    </Box>
  );
};

export default ViewPharmacistRatingHeader;
