import { SxProps, Theme } from '@mui/system';
import { WhiteSpace } from '../../../helpers/Constants';

const classes = {
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  userTitle: {
    textAlign: 'center',
    fontSize: 14,
  },
  settingsUserTitle: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#4A5056',
  },
  settingsUserTitleInfo: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'grey.500',
    marginTop: '8px',
  },
  uploadButton: {
    width: '100%',
    position: 'fixed',
    height: '60px',
    backgroundColor: 'red',
  },
  avatarContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  menuItem: {
    marginTop: '2px',
    marginBottom: '2px',
    color: 'grey.500',
    fontSize: '14px',
    minHeight: '56px !important',
    borderBottom: '2px solid',
    borderColor: 'common.lightBackgroundColor',
  },
  avatar: {
    objectFit: 'contain',
  },
  detailsContainer: {
    padding: '10px',
  },
  detailsText: {
    fontSize: '14px',
    color: 'grey.500',
  },
  ellipsize: {
    whiteSpace: 'nowrap' as WhiteSpace,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
