import * as Yup from 'yup';
import strings from '../../../../localization';

const Validator = () =>
  Yup.object().shape({
    note: Yup.string()
      .required(strings.requiredField)
      .matches(/.*\S.*/g, strings.onlySpace),
  });

export default Validator;
