import React, { useEffect } from 'react';

import { useFormik } from 'formik';

import {
  getRegions,
  pharmacistRegions,
  updateRegions,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';

import { ProInfoValidator } from '../../FormValidation';
import ProfileForm from '../../ProfileForm';
import { ICommonProfile } from '../General/General';

const initialValues: Array<string> = [];

const ProfessionalInfo = ({ setSelected }: ICommonProfile) => {
  const dispatch = useAppDispatch();
  const regions = useAppSelector(pharmacistRegions);

  const onSubmit = (values: Array<string>) => {
    dispatch(updateRegions(values));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: ProInfoValidator(),
  });

  const { setValues } = formik ?? {};
  const { selectedRegions } = regions ?? {};

  useEffect(() => {
    dispatch(getRegions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValues(selectedRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegions]);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="geographicAvailability"
      formik={formik}
    />
  );
};

export default ProfessionalInfo;
