import React from 'react';
import { BookingState, pharmacistEvent } from '@pharmaplan/common';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';
import { approvalTime } from '../../../Reports/helpers';
import { ReactComponent as Ticker } from '../../../../assets/svg/ticker.svg';
import { ReactComponent as GreenTick } from '../../../../assets/svg/greenTick.svg';
import { ReactComponent as SmallInfo } from '../../../../assets/svg/smallInfo.svg';
import { ReactComponent as Prescription } from '../../../../assets/svg/prescriptionIcon.svg';
import classes from './styles';

const { PendingWithPharmacist, UpdatedTiming } = BookingState;

const {
  confirmContainer,
  infoContainer,
  confirmText,
  goodNewsText,
  autoRejectText,
  confirm,
  newsContainer,
} = classes;

const ConfirmBox = () => {
  const event = useAppSelector(pharmacistEvent);

  const {
    bookingState,
    timezone,
    approveBookingExpiration,
    bookingStateExpiration,
  } = event ?? {};

  const isPendingWithPharmacist = bookingState === PendingWithPharmacist;

  const confirmTime = approvalTime(
    isPendingWithPharmacist
      ? approveBookingExpiration ?? ''
      : bookingStateExpiration ?? '',
  );

  const title = `${strings.formatString(strings.confirmBefore, confirmTime)} ${
    timezone ?? ''
  }`;

  const stateConfig = {
    [PendingWithPharmacist]: {
      header: strings.goodNews,
      infoMessage: strings.requestWillBeAutoRejected,
      icon: <GreenTick />,
    },
    [UpdatedTiming]: {
      header: strings.pharmacyUpdatedWorking,
      infoMessage: strings.updateWillBeAutoRejected,
      icon: <Prescription />,
    },
  };

  const { icon, header, infoMessage } = stateConfig[bookingState as keyof typeof stateConfig] ?? {};

  return (
    <>
      <Box sx={confirmContainer}>
        {icon}
        <Box sx={newsContainer}>
          <Typography sx={goodNewsText}>{header}</Typography>
          <Box sx={confirm}>
            <Ticker />
            <Typography sx={confirmText}>{title}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={infoContainer}>
        <SmallInfo />
        <Typography style={autoRejectText}>{infoMessage}</Typography>
      </Box>
    </>
  );
};

export default ConfirmBox;
