import React from 'react';
import { Box, Grid, InputLabel } from '@mui/material';
import { FormikProps } from 'formik';
import { ReactComponent as DayIcon } from '../../../assets/svg/dayIcon.svg';
import { ReactComponent as EveningIcon } from '../../../assets/svg/eveningIcon.svg';
import classes from './style';
import CustomTextField from '../CustomTextField';

interface ICustomOpreation {
  formik: FormikProps<any>;
  id: string;
  label: string;
  suffix: string;
  opreationType: string;
  requireLabel: boolean;
}

const CustomOpreation = ({
  suffix,
  formik,
  id,
  label,
  opreationType,
  requireLabel,
}: ICustomOpreation) =>
  (
    <Box
      component="div"
      sx={[classes.customTextFieldContainer]}
      data-testid="test"
    >
      <Grid container>
        <Grid xs={12}>
          <InputLabel>
            {label}
            {requireLabel && (
            <Box component="span" sx={classes.customTextFieldRequireLabel}>
              *
            </Box>
            )}
          </InputLabel>
        </Grid>
        <Grid xs={6}>
          <Grid container wrap="nowrap">
            <Grid>
              <DayIcon style={classes.icon} />
            </Grid>
            <Grid xs={8} item>
              <CustomTextField
                customClass={classes.zeroRadiusInput}
                formik={formik}
                name={`${suffix}Day${opreationType}`}
                id={id}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6}>
          <Grid container wrap="nowrap">
            <Grid alignItems="flex-end">
              <EveningIcon style={classes.icon} />
            </Grid>
            <Grid xs={8} item>

              <CustomTextField
                customClass={classes.zeroRadiusInput}
                formik={formik}
                name={`${suffix}Night${opreationType}`}
                id={id}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
export default CustomOpreation;
