import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import styles from './styles';

interface IOption {
  label: string;
  subtitle: string;
}

const Option: FC<IOption> = (props) => {
  const { label, subtitle } = props ?? {};
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...props} sx={styles.container}>
      <Typography sx={styles.label}>{label}</Typography>
      <Typography sx={styles.subtitle}>{subtitle}</Typography>
    </Box>
  );
};
export default Option;
