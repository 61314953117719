import React from 'react';
import {
  ConfirmationTypes,
  approveRequest,
  getTypeOfUser,
  ignoreRequest,
  pharmacistBookingDetails,
  pharmacyPreApprove,
  selectPharmacyPreApprove,
} from '@pharmaplan/common';
import OutcomeModal from '../../components/Modals/OutcomeModal';
import { setDialog } from '../../reducers/dialogReducer';
import { useAppDispatch } from '../useAppDispatch';
import { OutcomeModalTypes, ScreenTypes } from '../../helpers/Constants';
import strings from '../../localization';
import { useAppSelector } from '../useAppSelector';
import { renderScreen } from '../../actions/drawerActions';
import { IPharmacistProfile } from '../../components/SelfService/PharmacistProfile/PharmacistProfile';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import SoftwareWarning from '../../components/SelfService/ServiceWorkshiftDetails/SoftwareWarning';

const { bookingPending } = OutcomeModalTypes;
const { workshiftSummary } = ScreenTypes;
const { counterOfferCancel, counterOfferConfirm } = ConfirmationTypes;

const usePharmacistProfile = ({
  eventId,
  eventIds,
  workshiftId,
  date,
  type,
}: IPharmacistProfile) => {
  const dispatch = useAppDispatch();

  const details = useAppSelector(pharmacistBookingDetails);
  const preRequest = useAppSelector(selectPharmacyPreApprove);

  const { bookingState } = details ?? {};
  const { softwareLevel, name } = preRequest ?? {};

  const userType = useAppSelector(getTypeOfUser);

  const approveAction = () => {
    dispatch(approveRequest({ userType, bookingId: eventId }));
  };

  const ignoreAction = () => {
    dispatch(ignoreRequest({ userType, bookingId: eventId }));
  };

  const handleSoftwareWarning = () => {
    dispatch(pharmacyPreApprove(eventId));
  };

  const softwarePercentage = (softwareLevel - 1) * 25;
  const softwareWarningMsg = strings.formatString(
    strings.pharmacySoftwareWarning,
    name,
    `${softwarePercentage}`,
  );

  const gotoRequestsList = () => {
    dispatch(
      renderScreen({
        eventId: workshiftId,
        eventIds,
        date,
        type,
        screenNumber: 2,
        screenType: workshiftSummary,
      }),
    );
  };

  const gotoWorkshiftDetails = () => {
    dispatch(
      renderScreen({
        eventIds,
        type,
        bookingState,
        screenNumber: 1,
        screenType: workshiftSummary,
      }),
    );
  };

  const counterOfferConfirmation = () => {
    dispatch(
      setDialog({
        showCloseButton: false,
        Component: (
          <ConfirmationModal
            type={counterOfferConfirm}
            confirmAction={handleSoftwareWarning}
          />
        ),
        heading: {
          title: '',
        },
      }),
    );
  };

  const counterOfferCancellation = () => {
    dispatch(
      setDialog({
        showCloseButton: false,
        Component: (
          <ConfirmationModal
            type={counterOfferCancel}
            confirmAction={ignoreAction}
          />
        ),
        heading: {
          title: '',
        },
      }),
    );
  };

  const showApproveSuccessModal = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <OutcomeModal
            type={bookingPending}
            showCloseButton
            message={strings.bookingPendingForConfirmation}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  const showSoftwareWarning = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.softwareWarning}
            customMessage={<SoftwareWarning message={softwareWarningMsg} />}
            showCloseButton
            confirmAction={approveAction}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  return {
    gotoRequestsList,
    approveAction,
    ignoreAction,
    gotoWorkshiftDetails,
    showApproveSuccessModal,
    counterOfferCancellation,
    counterOfferConfirmation,
    handleSoftwareWarning,
    showSoftwareWarning,
  };
};

export default usePharmacistProfile;
