import { SxProps, Theme } from '@mui/system';

const styles = {
  listContainer: {
  },
  items: {
    marginBottom: '6px',
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    color: 'grey.800',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
