import { SxProps, Theme } from '@mui/system';

const classes = {
  avatarBox: {
    marginY: 2,
    position: 'relative',
    overflow: 'hidden',
    height: 96,
    maxWidth: 96,
    width: '100%',
    border: '3px solid',
    borderColor: '#C4C4C4',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  cameraContainer: {
    position: 'relative',
    bottom: 25,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  settingsAvatar: {
    maxWidth: 96,
    height: 96,
    width: '100%',
  },
  avatarContain: {
    objectFit: 'contain',
  },
  avatarCover: {
    objectFit: 'cover',
  },
  buttonPadding: {
    paddingX: 5,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
