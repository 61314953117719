import { ITheme } from '.';

export default {
  styleOverrides: {
    root: ({ theme }: ITheme) =>
      ({
        padding: '10px 20px 10px 20px',
        marginBottom: '1rem',
        borderBottom: `2px solid ${theme.palette.common.lightBackgroundColor}`,
      }),
  },
};
