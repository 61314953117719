import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    mt: '13px',
    ml: '7px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
