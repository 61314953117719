import { BookingState, ICalendarEventType, PharmacistLegendVariant } from '@pharmaplan/common';

const { UpdatedTiming, PendingWithPharmacist } = BookingState;
const { requested, booked } = PharmacistLegendVariant;

export const showConfirmBox = (bookingState: BookingState, eventType: ICalendarEventType) => {
  const isPendingWithPharmacist = bookingState === PendingWithPharmacist;
  const isUpdatedTiming = bookingState === UpdatedTiming;

  const isBooked = eventType === booked;
  const isRequested = eventType === requested;

  if (isRequested) {
    return isPendingWithPharmacist;
  }

  if (isBooked) {
    return isUpdatedTiming;
  }

  return false;
};
