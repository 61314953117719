import { Theme, SxProps } from '@mui/material';

const classes = {
  root: {
    marginTop: '24px',
    marginBottom: '15px',
  },

  tableHead: {
    fontSize: 12,
    fontWeight: 600,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
