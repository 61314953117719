import { useEffect } from 'react';

import { setFooter } from '../../actions/drawerActions';
import { selectFooterPaginationCurrent } from '../../selectors/drawerSelector';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';

const useDetailsPagination = (eventIds?: Array<string> | null) => {
  const dispatch = useAppDispatch();

  const current = useAppSelector(selectFooterPaginationCurrent);
  const id = eventIds?.[current - 1];

  const paginationModel = { show: true, total: eventIds?.length, current };

  useEffect(() => {
    if (eventIds?.length !== 0) {
      dispatch(
        setFooter({
          show: true,
          buttons: {
            show: false,
            primaryButton: {
              label: null,
              onClick: null,
            },
          },
          pagination: paginationModel,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { id, paginationModel };
};

export default useDetailsPagination;
