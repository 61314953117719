const PublicKeyHandler = () => {
  let key = "";
  const getKey = () => {
    return key;
  }
  const setKey = (inKey: string) => {
    key = inKey;
  }
  return { getKey, setKey }
}

export default PublicKeyHandler();