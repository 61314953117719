import { ConfirmationTypes } from '@pharmaplan/common';
import React, { useState } from 'react';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import { resetDialog, setDialog } from '../reducers/dialogReducer';
import { useAppDispatch } from './useAppDispatch';

const useConfirmTextField = (type: ConfirmationTypes) => {
  const dispatch = useAppDispatch();

  const [disabled, setDisabled] = useState(true);

  const handleConfirm = () => {
    setDisabled(false);
    dispatch(resetDialog());
  };

  const confirmation = () => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal type={type} confirmAction={handleConfirm} />
        ),
        heading: {
          title: '',
        },
        showCloseButton: false,
      }),
    );
  };

  return { disabled, confirmation };
};

export default useConfirmTextField;
