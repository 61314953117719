import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import styles from './styles';

interface IChartsContainer {
  children: React.ReactNode;
  title?: string;
}

const ChartsContainer: FC<IChartsContainer> = ({ children, title }) =>
  (
    <Grid sx={styles.container} xs={6} item>
      {title && <Typography sx={styles.title}>{title}</Typography>}
      <Grid container direction="row">
        {children}
      </Grid>
    </Grid>
  );

export default ChartsContainer;
