import { getSelectedClient, MetaDataKeys, setSelectedClient } from '@pharmaplan/common';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';

import { useAppSelector } from '../../useAppSelector';
import { useAppDispatch } from '../../useAppDispatch';
import useAdmin from '../../useAdmin';

const unique = 'unique';

const useChatUtility = () => {
  const dispatch = useAppDispatch();
  const selectedClient = useAppSelector(getSelectedClient);
  const context = useSendbirdStateContext();
  const { sdk } = context?.stores?.sdkStore ?? {};
  const { isAdmin } = useAdmin();

  const getFromChannelHandler = async (channelUrl: string) => {
    try {
      const channel = await sdk?.groupChannel?.getChannel(channelUrl);
      const metaData = await channel?.getMetaData([MetaDataKeys.users]);
      dispatch(setSelectedClient(metaData?.users));
    } catch (err) {
      console.log(err);
    }
  };

  const removeFromChannelHandler = () => {
    sdk?.groupChannel.removeGroupChannelHandler(unique);
  };

  const getClientStatus = async (channel: any, message: any) => {
    const readMembers = await channel.getReadMembers(message, true);

    const assignedMember = readMembers?.filter((item: any) => {
      const idCheck = isAdmin ? item.metaData?.personnel_id : item.userId;
      return idCheck === selectedClient;
    });
    return assignedMember.length > 0;
  };

  return { getFromChannelHandler, removeFromChannelHandler, getClientStatus };
};

export default useChatUtility;
