const classes = {
  inputMaxWidth: {
    '& .MuiInputBase-root': {
      maxWidth: '51px',
    },
  },
  recurrenceContainer: {
    // minWidth: 369,
    '& fieldset': { border: 'none' },
  },
  timeContainer: {
    display: 'flex',
    gap: '30px',
    marginBottom: '16px',
  },

  checkbox: {
    marginBottom: '1.2rem',
  },

  checkboxLabel: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'grey.800',
  },
};

export default classes;
