import { FormikValues } from 'formik';
import { DateTime, Info } from 'luxon';
import moment from 'moment';

import {
  ICurrentAvailability,
  IWorkshift,
  MonthlyYearlyRecurrence,
  Ordinals,
  PharmacistLegendVariant,
  recurrenceType,
  setRecurrence,
} from '@pharmaplan/common';
import { IRecurrenceState } from '@pharmaplan/common/reducers/recurrenceReducer';

import { weekElements } from '../../components/common/CustomWeekSelector/helper';
import {
  DurationTypes,
  MomentFormatOptions,
  MomentTimeFormats,
  MonthlyRecurrenceDropdown,
} from '../Constants';
import { generateUniquieId } from '../Functions';
import strings from '../../localization/strings';
import { store } from '../../store';

/** This logic should be put at the backend */
export const getRecurringWeekDaysDates = (
  startTime: Date,
  endTime: Date,
  endsOnDate: Date,
  weeklyRecurrence: string[],
) => {
  const weekDatesArr = [];
  const diffInDays = moment(endsOnDate).diff(startTime, 'days');
  let tempStartTime = moment(startTime);
  let tempEndTime = moment(endTime);
  for (let index = 0; index < diffInDays; index += 1) {
    const dayOfTheWeek = tempStartTime
      .format(MomentFormatOptions.shortWeekDayName)
      .toLowerCase();
    if (weeklyRecurrence.includes(dayOfTheWeek)) {
      weekDatesArr.push({
        id: generateUniquieId(),
        start: tempStartTime.toDate(),
        end: tempEndTime.toDate(),
        type: PharmacistLegendVariant.availability,
        title: strings.availabilities,
      });
    }
    tempStartTime = tempStartTime.add(1, 'd');
    tempEndTime = tempEndTime.add(1, 'd');
  }
  return weekDatesArr;
};

export const getRecurringDates = (
  startTime: Date,
  endTime: Date,
  endsOnDate: Date,
  recurringType: 'day' | 'month' | 'year',
) => {
  const dailyDatesArr = [];
  let momentStartTime = moment(startTime);
  let momentEndTime = moment(endTime);
  const momentEndsOnDate = moment(endsOnDate).add(1, recurringType);
  while (momentStartTime.isBefore(momentEndsOnDate, recurringType)) {
    dailyDatesArr.push({
      id: generateUniquieId(),
      start: momentStartTime.toDate(),
      end: momentEndTime.toDate(),
      type: PharmacistLegendVariant.availability,
      title: strings.availability,
    });
    momentStartTime = momentStartTime.add(1, recurringType);
    momentEndTime = momentEndTime.add(1, recurringType);
  }
  return dailyDatesArr;
};

export const getRecurringFourthWeekday = (
  startTime: Date,
  endTime: Date,
  endsOnDate: Date,
) => {
  const monthDatesArr = [];
  let momentStartTime = moment(startTime);
  const dayIntOfTheWeek = momentStartTime.isoWeekday();
  let momentEndTime = moment(endTime);
  const momentEndsOnDate = moment(endsOnDate).add(1, DurationTypes.month);
  while (momentStartTime.isBefore(momentEndsOnDate, DurationTypes.month)) {
    let endOfMonthStart = moment(momentStartTime).endOf('month');
    let endOfMonthEnd = moment(momentEndTime).endOf('month');

    while (endOfMonthStart.day() !== dayIntOfTheWeek) {
      endOfMonthStart = endOfMonthStart.subtract(1, 'days');
      endOfMonthEnd = endOfMonthEnd.subtract(1, 'days');
    }
    monthDatesArr.push({
      id: generateUniquieId(),
      start: endOfMonthStart.toDate(),
      end: endOfMonthEnd.toDate(),
      type: PharmacistLegendVariant.availability,
      title: strings.availability,
    });
    momentStartTime = momentStartTime.add(1, DurationTypes.month);
    momentEndTime = momentEndTime.add(1, DurationTypes.month);
  }
  return monthDatesArr;
};

export const getRecurringMonthDates = (
  startTime: Date,
  endTime: Date,
  endsOnDate: Date,
  monthlyRecurrenceDay: `${MonthlyRecurrenceDropdown}`,
) => {
  if (monthlyRecurrenceDay === MonthlyRecurrenceDropdown.monthlyDate) {
    return getRecurringDates(
      startTime,
      endTime,
      endsOnDate,
      DurationTypes.month,
    );
  }
  return getRecurringFourthWeekday(startTime, endTime, endsOnDate);
};

export const setHour = (d: DateTime, hour: number) =>
  d.set({ hour, minute: 0, second: 0 });

export const setJSHour = (d: DateTime, hour: number) =>
  d.set({ hour, minute: 0, second: 0 }).toJSDate();

export const getWorkTimings = (date: DateTime) =>
  ({
    start: setHour(date, 8),
    end: setHour(date, 12),
  });

export const getDateObject = (isoDate: string) =>
  DateTime.fromISO(isoDate, { setZone: true });

export const setRecurrenceProps = (
  dispatch: typeof store.dispatch,
  recurrence: IRecurrenceState,
  currentAvailability: ICurrentAvailability | IWorkshift,
) => {
  const isRecurrenceDay = !currentAvailability?.recurrence?.weekNumber;
  dispatch(
    setRecurrence({
      ...recurrence,
      repeatEvery: currentAvailability?.recurrence?.recurrenceType,
      endDate:
        currentAvailability?.recurrence?.endDate
        || currentAvailability?.endDate,
      onDay: getDateObject(currentAvailability?.selectedDate).toFormat(
        MomentTimeFormats.dayOfTheMonth,
      ),
      onThe: currentAvailability?.recurrence?.weekNumber || Ordinals.first,
      onTheDay: getDateObject(currentAvailability?.selectedDate).toFormat('c'),
      onTheMonth: `${Info.months('long').indexOf(
        getDateObject(currentAvailability?.selectedDate).toFormat(
          MomentTimeFormats.fullmonthname,
        ),
      ) + 1
      }`,
      recurrencDayMonth: isRecurrenceDay
        ? MonthlyYearlyRecurrence.day
        : MonthlyYearlyRecurrence.weekdaysAndMonths,
      weeklyRecurrence: currentAvailability?.recurrence?.weekDays,
      weekDayRecurrence: currentAvailability?.recurrence?.weekDays,
      weeklyRecurrenceFullDay: currentAvailability?.recurrence?.weekDays
        .split(',')
        .map((item) =>
          weekElements()[item]?.fullName),
    }),
  );
};

export const appendTimeToDate = (date: string, time: string) => {
  const hours = new Date(time)?.getHours();
  const minutes = new Date(time)?.getMinutes();
  const seconds = new Date(time)?.getSeconds();

  return DateTime.fromISO(date, { setZone: true })?.set({
    hour: hours,
    minute: minutes,
    second: seconds,
  });
};

export const makeReqParams = (
  values: FormikValues,
  recurrence: IRecurrenceState,
) => {
  const { weekDayRecurrence, weekendRecurrence, weeklyRecurrence, repeatEvery } = recurrence ?? {};
  const weekdayMap = {
    [recurrenceType.EveryWeekday]: weekDayRecurrence,
    [recurrenceType.EveryWeek]: weeklyRecurrence,
    [recurrenceType.Weekend]: weekendRecurrence,
  };

  const isMonthlyYearly = recurrence?.repeatEvery === recurrenceType.EveryMonth
    || recurrence?.repeatEvery === recurrenceType.EveryYear;
  const isWeekly = !!weekdayMap?.[repeatEvery as keyof typeof weekdayMap];
  const isMonthlYearlyOnDay = recurrence?.recurrencDayMonth === MonthlyYearlyRecurrence.day;
  const hasWeekdays = (isMonthlyYearly && !isMonthlYearlyOnDay) || isWeekly;
  const weekdays = weekdayMap?.[repeatEvery as keyof typeof weekdayMap];
  const endDate = recurrence?.repeatEvery === recurrenceType.Never
    ? values?.selectedDate
    : recurrence?.endDate;
  const weekRec = isWeekly ? weekdays : recurrence.onTheDay;

  const params = {
    startDate: appendTimeToDate(values.selectedDate, values.startTime).toFormat(
      MomentTimeFormats.fullDate,
    ),
    endDate: appendTimeToDate(endDate, values.endTime).toFormat(
      MomentTimeFormats.fullDate,
    ),
    recurrenceType: recurrence.repeatEvery === recurrenceType.Weekend ? recurrenceType.EveryWeek : repeatEvery,
    notes: values.notes,
    weekNumber: isMonthlyYearly && !isMonthlYearlyOnDay ? recurrence?.onThe : 0,
    day: (isMonthlyYearly && isMonthlYearlyOnDay
      ? recurrence?.onDay || 0
      : 0) as number,
    weekDays: hasWeekdays ? weekRec : null,
    month: 0,
  };

  return params;
};
