import { Dispatch, SetStateAction } from 'react';
import strings from '../../../localization';

interface IWeekElements {
  [key: string]: {
    value: string;
    selected: boolean;
    fullName: string;
  };
}

export const weekElements = (): IWeekElements =>
  ({
    1: {
      fullName: strings.mon,
      value: 'M',
      selected: false,
    },
    2: {
      fullName: strings.tue,
      value: 'T',
      selected: false,
    },
    3: {
      fullName: strings.wed,
      value: 'W',
      selected: false,
    },
    4: {
      fullName: strings.thurs,
      value: 'T',
      selected: false,
    },
    5: {
      fullName: strings.fri,
      value: 'F',
      selected: false,
    },
    6: {
      fullName: strings.sat,
      value: 'S',
      selected: false,
    },
    0: {
      fullName: strings.sun,
      value: 'S',
      selected: false,
    },
  });

export const reduceWeekToName = (weeks: IWeekElements) =>
  Object.keys(weeks).reduce((acc: string[], cur: string) => {
    if (weeks[cur].selected) {
      acc.push(cur);
    }
    return acc;
  }, []);

export const selectWeek = (
  week: string,
  setWeeks: Dispatch<SetStateAction<IWeekElements>>,
  weeks: IWeekElements,
) => {
  const selectedMonths = {
    ...weeks,
    [week]: {
      fullName: weeks[week].fullName,
      value: weeks[week]?.value,
      selected: !weeks[week]?.selected,
    },
  };
  setWeeks(selectedMonths);
  return selectedMonths;
};
