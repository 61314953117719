import * as Yup from 'yup';
import { AdminAllowanceNames, AllowancesNames } from '@pharmaplan/common';
import strings from '../../../localization';
import { allowanceValidator, hourlyRateValidator } from '../../../helpers/commonValidators';

const Validator = () =>
  Yup.object().shape({
    pharmacyId: Yup.string().required(strings.requiredField).nullable(),
    pharmacistId: Yup.string().required(strings.requiredField).nullable(),
    [AdminAllowanceNames.mealAllowance]: allowanceValidator(),
    [AdminAllowanceNames.accommodationAllowance]: allowanceValidator(),
    [AdminAllowanceNames.travelAllowance]: allowanceValidator(),
    [AdminAllowanceNames.pharmacistHourlyRate]: hourlyRateValidator(),

    [AllowancesNames.emergencyFees]: allowanceValidator(),
    startTime: Yup.string().required(strings.requiredField),
    endTime: Yup.string().required(strings.requiredField),
    selectedDate: Yup.string().required(strings.requiredField),
    [AdminAllowanceNames.pharmacyHourlyRate]: hourlyRateValidator(),
  });

export default Validator;
