/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, SyntheticEvent, useCallback } from 'react';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  InputLabel,
  SxProps,
  TextField,
  TextFieldVariants,
} from '@mui/material';
import { FormikProps } from 'formik';
import { Close } from '@mui/icons-material';
import Option from './Option';
import styles from './styles';
import CheckboxOption from './CheckboxOption';
import strings from '../../../localization';

type IOptions = { label: string; subtitle: string; id: string };
interface ICustomAutocomplete {
  formik: FormikProps<any>;
  options: Array<IOptions>;

  // HOLDS THE STRING WRITTEN IN TEXTFIELD
  textName: string;
  // HOLDS THE WHOLE OBJECT OF SELECTED VALUE
  autoCompleteName: string;
  // HOLDS ID OF THE SELECTED FIELD
  idName: string;

  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  variant?: TextFieldVariants;
  disabled?: boolean;
  label: string;
  onEndReached?: () => void;
  infiniteScroll?: boolean;
  customLabelStyle?: SxProps;
  customTextFieldStyle?: SxProps;
  customAutoCompleteStyle?: SxProps;
}

const CustomAutocomplete: FC<ICustomAutocomplete> = ({
  textName,
  autoCompleteName,
  formik,
  options,
  idName,
  multiple,
  placeholder,
  disableCloseOnSelect,
  label,
  variant,
  disabled,
  onEndReached,
  infiniteScroll,
  customLabelStyle,
  customTextFieldStyle,
  customAutoCompleteStyle,
}) => {
  const formikErrorValidate = formik.errors[autoCompleteName]
    && formik.touched[autoCompleteName]
    && formik.errors[autoCompleteName];

  const helperText = formikErrorValidate
    ? (formikErrorValidate as React.ReactNode)
    : ' ';

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.type === 'change') {
      formik.setFieldValue(textName, event.target.value.trim());
    }
  };

  const handleAutoCompleteChange = (
    event: SyntheticEvent<Element, Event>,
    value: IOptions | Array<IOptions> | null,
  ) => {
    formik.setFieldValue(autoCompleteName, value);
    if ((value as IOptions)?.id) {
      formik.setFieldValue(idName, (value as IOptions)?.id);
    }
  };

  // REQUIRED TO REMOVE CLIENT-SIDE SEARCH
  const filterOptions = (x: IOptions[]) =>
    x;

  const AutoCompleteInput = useCallback(
    (props: AutocompleteRenderInputParams) =>
      (
        <TextField
          variant={variant}
          onChange={handleTextChange}
          helperText={helperText}
          error={!!formikErrorValidate}
          placeholder={placeholder}
          value={formik.values[textName]}
          {...props}
          sx={[styles.textField, customTextFieldStyle] as SxProps}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values, textName],
  );

  const AutoCompleteOptions = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: IOptions,
      { selected }: AutocompleteRenderOptionState,
    ) => {
      if (multiple) {
        return (
          <CheckboxOption label={option.label} selected={selected} {...props} />
        );
      }
      return (
        <Option label={option.label} subtitle={option.subtitle} {...props} />
      );
    },
    [multiple],
  );

  const onScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight
        === listboxNode.scrollHeight
      && onEndReached
      && infiniteScroll
    ) {
      onEndReached();
    }
  };

  return (
    <>
      <InputLabel sx={[styles.label, customLabelStyle] as SxProps}>
        {label}
      </InputLabel>
      <Autocomplete
        sx={[styles.autocomplete, customAutoCompleteStyle] as SxProps}
        disabled={disabled}
        multiple={multiple}
        clearIcon={multiple ? <Close fontSize="small" /> : null}
        disableCloseOnSelect={disableCloseOnSelect}
        value={formik.values[autoCompleteName]}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id}
        getOptionLabel={(option) =>
          (typeof option === 'string' ? option : option.label)}
        onChange={handleAutoCompleteChange}
        filterOptions={filterOptions}
        ListboxProps={{
          sx: styles.listBox,
          onScroll,
        }}
        renderInput={AutoCompleteInput}
        options={options}
        noOptionsText={strings.noResultsToDisplay}
        renderOption={AutoCompleteOptions}
      />
    </>
  );
};

export default CustomAutocomplete;
