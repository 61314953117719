import { SxProps, Theme } from '@mui/system';

const classes = {
  button: {
    backgroundColor: 'common.lightBackgroundColor',
    color: 'grey.500',
    '&:hover': {
      backgroundColor: 'common.hoverGray',
      color: 'grey.500',
    },
  },
  dropdown: {
    '& .MuiPopover-paper': {
      maxHeight: 400,
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
