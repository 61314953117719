import React from 'react';

import {
  TypeOfUser,
  AdminLegendVariant,
  RecurrenceActions,
  getTypeOfUser,
  PharmacyLegendVariant,
  PharmacistLegendVariant,
} from '@pharmaplan/common';

import UpdateModal from '../components/Modals/UpdateModal';
import { updateTitle } from '../components/Dashboard/MainCalendar/CustomMonthEvent/helper';
import { setDialog } from '../reducers/dialogReducer';
import { isPast } from '../helpers/Functions';
import ICalendar from '../types/ICalendar';
import { getMultipleEventAdd } from '../selectors/calendarSelector';

import useAdminNavigation from './Admin/useAdminNavigation';
import useDrawerNavigation from './useDrawerNavigation';
import useMultipleDelete from './useMultipleDelete';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import useSelfService from './useSelfService';
import useAdmin from './useAdmin';
import useUser from './useUser';
import useDetailsNavigation from './Admin/useDetailsNavigation';

interface IUseCustomMonthEvent {
  event: ICalendar;
  eventIds: Array<string>;
}

const useCustomMonthEvent = ({ event, eventIds }: IUseCustomMonthEvent) => {
  const dispatch = useAppDispatch();
  const { isSelfService } = useSelfService();

  const {
    start,
    type,
    id,
    bookingState,
    recurrenceId,
    startDate,
    pharmacyId,
    workShiftId,
    bookingId,
  } = event ?? {};

  const isMultipleAdd = useAppSelector(getMultipleEventAdd);
  const typeOfUser = useAppSelector(getTypeOfUser).toLowerCase() as TypeOfUser;

  const { isAdmin } = useAdmin();
  const { userType } = useUser();

  const preventClick = () => {
    const pharmacyEvents = [PharmacyLegendVariant.posted, PharmacyLegendVariant.requested];
    const pharmacistEvents = [PharmacistLegendVariant.availability, PharmacistLegendVariant.vacation];

    return isPast(start) && (pharmacyEvents.includes(type as PharmacyLegendVariant) || pharmacistEvents.includes(type as PharmacistLegendVariant));
  };

  const { openDrawer } = useDrawerNavigation();
  const { openAdminDrawer } = useAdminNavigation();
  const { handleNavigation } = useDetailsNavigation();

  const { handleMultipleClicks } = useMultipleDelete();

  // ONLY SHOW WHEN EVENT IS NOT IN PAST AND CREATE EVENTS IS DISABLED
  const showEvent = !preventClick();
  const isRegularOrSuperAdmin = typeOfUser === TypeOfUser.admin.toLowerCase()
    || typeOfUser === TypeOfUser.superAdmin.toLowerCase();

  const handleTooltipClick = (inId: string) => {
    if (!showEvent && !isAdmin) {
      return;
    }
    openDrawer({
      eventIds: [inId],
      type,
      bookingState,
      recurrenceType: RecurrenceActions.series,
      recurrenceId,
    });
  };

  const eventSelect = () => {
    if (handleMultipleClicks({ type, id })) {
      return;
    }

    if (isMultipleAdd && isRegularOrSuperAdmin) {
      handleNavigation({
        id: bookingId ?? '',
        pharmacyId,
        eventType: type,
        date: startDate,
        workshiftId: workShiftId,
      })();
      return;
    }
    if (isRegularOrSuperAdmin) {
      openAdminDrawer({ startDate: start, type: type as AdminLegendVariant });
    } else {
      openDrawer({
        eventIds,
        type,
        bookingState,
        recurrenceType: RecurrenceActions.series,
        recurrenceId,
      });
    }
  };

  const handlePress = (e: MouseEvent) => {
    if (!showEvent && !isAdmin) {
      return;
    }

    Object.hasOwn(updateTitle(isSelfService), type) && recurrenceId
      ? dispatch(
        setDialog({
          showCloseButton: false,
          Component: (
            <UpdateModal
              type={userType}
              handleSelect={(selected: RecurrenceActions) =>
                openDrawer({
                  eventIds,
                  recurrenceType: selected,
                  recurrenceId,
                  type,
                })}
            />
          ),
          heading: {
            title: '',
          },
        }),
      )
      : eventSelect(); // Stop event bubbling
    e.stopPropagation();
  };

  return { handlePress, handleTooltipClick };
};

export default useCustomMonthEvent;
