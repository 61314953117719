const classes = {
  horizontalErrorContainer: {
    display: 'flex',
  },
  horizontalLabel: {
    flex: 2,
  },
  horizontalValue: {
    flex: 4,
  },
};

export default classes;
