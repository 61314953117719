import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    ml: '8px',
  },
  autocomplete: {
    '& .MuiFormControl-root': {
      backgroundColor: 'secondary.main',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '24px',
    pt: '8px',
  },
  titleFont: {
    fontSize: 18,
    fontWeight: 600,
  },
  filterDropdown: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      border: '1px solid',
      maxHeight: '35px',
      color: 'primary.main',
    },
    '& fieldset': { border: 'none' },
  },
  sortContainer: {
    display: 'flex',
    mt: '3px',
    flex: 1,
    ml: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
