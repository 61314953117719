import { DialogActions } from '@mui/material';
import React from 'react';
import { Constants } from '../../../../../helpers/Constants';
import CustomButton from '../../../CustomButton';
import classes from './style';

interface ICustomDialogAction {
  primaryBtnTitle: string;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  secondaryBtnTitle?: string;
  primaryFunction: () => void;
  secondaryFunction?: (() => void) | null;
}

const defaultProps = {
  secondaryBtnTitle: Constants.null,
  primaryDisabled: false,
  secondaryDisabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  secondaryFunction: null,
};

const CustomDialogActions = ({
  secondaryFunction,
  secondaryBtnTitle,
  primaryFunction,
  primaryBtnTitle,
  primaryDisabled,
  secondaryDisabled,
}: ICustomDialogAction) =>
  (
    <DialogActions>
      {secondaryFunction && secondaryBtnTitle && (
      <CustomButton
        label={secondaryBtnTitle}
        variant="text"
        disabled={secondaryDisabled}
        onClick={secondaryFunction}
        customClass={classes.modalButton}
        customButtonStyle={classes.secondaryButton}
      />
      )}
      {primaryFunction && primaryBtnTitle && (
      <CustomButton
        label={primaryBtnTitle}
        color="primary"
        disabled={primaryDisabled}
        onClick={primaryFunction}
        customClass={classes.modalButton}
      />
      )}
    </DialogActions>
  );

CustomDialogActions.defaultProps = defaultProps;

export default CustomDialogActions;
