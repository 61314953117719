import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import genericClasses from '../../../../theme/GenericClasses';
import styles from '../styles';

interface IEmptyIncompatibleMatches {
  message: string | string[];
}

const EmptyIncompatibleMatches: FC<IEmptyIncompatibleMatches> = ({
  message,
}) =>
  (
    <Box sx={[styles.emptyContainer, genericClasses.center]}>
      <Typography sx={styles.emptyPrompt}>{message}</Typography>
    </Box>
  );

export default EmptyIncompatibleMatches;
