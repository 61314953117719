import React, { FC } from 'react';

import { List, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';

import { ReactComponent as Close } from '../../../../assets/svg/listClose.svg';
import genericClasses from '../../../../theme/GenericClasses';

import { ITransferListItem } from '../TransferList';
import styles from './styles';

interface ISingleTransferList {
  items: Array<any>;
  title: string;
  description: string;
  onItemClick?: (item: ITransferListItem) => () => void;
  selectedItems: Array<ITransferListItem>;
  removedItems?: Array<ITransferListItem>;
  handleRemove?: (item: ITransferListItem) => () => void;
  inRef: React.MutableRefObject<null>;
  startOrnament?: React.ReactNode;
  listStyle?: SxProps<any>;
}

const SingleTransferList: FC<ISingleTransferList> = ({
  items,
  title,
  description,
  selectedItems,
  removedItems,
  handleRemove,
  onItemClick,
  inRef,
  startOrnament,
  listStyle,
}) =>
  (
    <List sx={[styles.container, styles.borderColor, styles.zeroPadding]}>
      <Box sx={[styles.headerContainer, styles.borderColor]}>
        <Typography sx={[styles.title, styles.commonFont]}>{title}</Typography>
        <Typography sx={styles.description}>{description}</Typography>
      </Box>
      {startOrnament}
      <Box sx={[styles.listContainer, listStyle] as SxProps}>
        {items.map((item) => {
          const { label, key, email, preventRemove } = item;
          const isSelected = selectedItems.some(
            (selected) =>
              item.key === selected.key,
          );
          const isRemoved = removedItems?.some(
            (selected) =>
              item.key === selected.key,
          );

          return (
            <ListItemButton
              sx={[
                styles.itemContainer,
                isRemoved ? styles.removedStyle : {},
                isSelected ? styles.selectedStyle : {},
              ]}
              key={key}
              onClick={onItemClick?.(item)}
            >
              <Typography sx={[styles.itemLabel, styles.commonFont]}>
                {label}
                <Typography component="span" sx={[styles.email]}>
                  (
                  {email}
                  )
                </Typography>
              </Typography>
              {handleRemove && !preventRemove && (
              <ListItemIcon onClick={handleRemove(item)} sx={styles.itemWidth}>
                <Close />
              </ListItemIcon>
              )}
            </ListItemButton>
          );
        })}
        <div ref={inRef} style={genericClasses.paginationDiv} />
      </Box>
    </List>
  );

export default SingleTransferList;
