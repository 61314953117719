import { ISoftwareResponse } from '@pharmaplan/common/models/Models';
import { adminUpdateSoftware } from '@pharmaplan/common';

import {
  Constants,
  DynamicTableCell,
  TextFieldVariants,
} from '../../../helpers/Constants';
import strings from '../../../localization';
import editIcon from '../../../assets/svg/updateNoteIcon.svg';
import deleteIcon from '../../../assets/svg/delete.svg';
import { IInputModalConfig } from '../../Modals/InputModal/InputModal';

import styles from './styles';
import genericClasses from '../../../theme/GenericClasses';
import { IHandlePublishSignature } from '../../../hooks/Admin/usePublishConfigurations';

export const softwareListHeaders = () =>
  [
    {
      key: '0',
      label: strings.name,
      sortingKey: 'name',
    },
    {
      key: '1',
      label: strings.description,
      sortingKey: 'description',
    },
    {
      key: '2',
      label: strings.published,
      sortingKey: 'published',
    },
    {
      key: '3',
      label: strings.action,
    },
  ];

export const softwareListMap = (
  softwareList: ISoftwareResponse[],
  handlePublish: IHandlePublishSignature,
  publishedRecords: Record<string, boolean>,
  updateAction: (softId: string) => void,
  showDeleteConfirmation: (softId: string) => void,
  canChangeParameters: boolean,
  canDelete: boolean,
) =>
  softwareList?.map((item) => {
    const { softwareId, name, inUse, description } = item ?? {};

    const value = [
      canChangeParameters && {
        key: '1',
        disabled: inUse,
        icon: editIcon,
        tooltip: strings.edit,
        onClick: () =>
          updateAction(softwareId),
      },
      canDelete && {
        key: '2',
        icon: deleteIcon,
        show: false,
        tooltip: strings.delete,
        onClick: () =>
          showDeleteConfirmation(softwareId),
      },
    ];

    return {
      key: softwareId,
      data: [
        {
          key: `0-${softwareId}`,
          value: name,
          type: DynamicTableCell.text,
        },
        {
          key: `1-${softwareId}`,
          value: description,
          type: DynamicTableCell.text,
        },
        {
          key: `2-${softwareId}`,
          value: {
            checked: !!publishedRecords[softwareId],
            tooltipText: strings.inUse,
            disabled: inUse || !canChangeParameters,
            handlePress: handlePublish(
              {
                softwareId,
                name,
                description,
                published: !publishedRecords[softwareId],
              },
              'softwareId',
              adminUpdateSoftware,
            ),
          },
          isCenterAligned: true,
          type: DynamicTableCell.checkbox,
        },
        !inUse
          ? {
            key: `3-${softwareId}`,
            value,
            type: DynamicTableCell.bar,
          }
          : {
            key: `4-${softwareId}`,
            value: {
              label: strings.inUse,
              style: styles.chipStyle,
            },
            type: DynamicTableCell.chip,
          },
      ],
    };
  });

export const addNewSoftwareForm: IInputModalConfig[] = [
  {
    key: '0',
    name: 'name',
    type: Constants.formInputType.text,
    gridLabel: strings.name,
    customTextFieldStyle: styles.customFilledInput,
    variant: TextFieldVariants.filled,
    fieldXs: 8,
    labelXs: 4,
  },
  {
    key: '1',
    name: 'description',
    type: Constants.formInputType.text,
    gridLabel: strings.description,
    multiline: true,
    variant: TextFieldVariants.filled,
    fieldXs: 8,
    labelXs: 4,
  },
  {
    key: '4',
    name: 'published',
    type: Constants.formInputType.checkbox,
    customClass: genericClasses.checkboxContainer,
    customCheckboxStyle: genericClasses.checkboxStyle,
    // gridLabelClass: styles.gridLabel,
    gridLabel: strings.publish,
    label: ' ',
    fieldXs: 8,
    labelXs: 4,
  },
];
