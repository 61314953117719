import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import {
  ITEMS_PER_PAGE,
  adminActions,
  adminGetListOfPharmacists,
  adminGetListOfPharmacistsPagination,
  adminListOfPharmacists,
  getPharmacyDetails,
  pharmacyDetails,
  successSelector,
} from '@pharmaplan/common';
import AdminPharmacyCard from '../../../common/AdminPharmacyCard';
import strings from '../../../../localization';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useGetItems from '../../../../hooks/useGetItems';
import { listRowConfig } from './helper';
import CustomAvatarSection from '../../../common/CustomAvatarSection';
import styles from './style';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setFooterShow } from '../../../../actions/drawerActions';
import useOptimizedAvatar from '../../../../hooks/useOptimizedAvatar';
import genericClasses from '../../../../theme/GenericClasses';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import useListOfPharmacists from '../../../../hooks/Admin/PharmacyList/useListOfPharmacists';
import NoAvailabilities from '../../MatchingAvailabilities/NoAvailabilities';

interface IListOfPharmacists {
  pharmacyId: string;
}

const successAction = adminActions.listOfPharmacists;

const ListOfPharmacists: FC<IListOfPharmacists> = ({ pharmacyId }) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const listOfPharmacists = useAppSelector(adminListOfPharmacists);
  const details = useAppSelector(pharmacyDetails);
  const loadSuccess = useAppSelector((state) =>
    successSelector([successAction], state));

  const { pharmacy } = details ?? {};
  const { logoId } = pharmacy ?? {};
  const { data } = listOfPharmacists ?? {};

  const pharmacistIds = data.map((item) =>
    item.pharmacistId);

  const observerCallback = useCallback(
    (entries: { isIntersecting: any }[]) => {
      const paginationCondition = data.length >= page * ITEMS_PER_PAGE;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    [page, data],
  );

  const { getPharmacistImage, getImage } = useGetItems();
  const { listEndRef } = useInfiniteScroll({ observerCallback });
  const { menuAction } = useListOfPharmacists();

  const avatar = getImage(logoId);

  const pIds = useMemo(
    () =>
      pharmacistIds,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  useOptimizedAvatar(pIds);

  useEffect(() => {
    dispatch(
      adminGetListOfPharmacists({
        paging: {
          page: 1,
        },
        pharmacyId,
      }),
    );
    dispatch(getPharmacyDetails(pharmacyId));
    dispatch(setFooterShow(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        adminGetListOfPharmacistsPagination({
          paging: {
            page,
          },
          pharmacyId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Box>
      <AdminPharmacyCard avatar={avatar} pharmacyId={pharmacyId} />
      <Typography sx={styles.title}>{strings.pharmacistsList}</Typography>
      {data.length === 0 ? (
        <NoAvailabilities message={strings.noDataAvailable} />
      ) : data.map((item) => {
        const {
          totalReplacements,
          favourite,
          totalHours,
          city,
          name,
          pharmacistId,
        } = item;

        return (
          <Box key={pharmacistId} sx={styles.itemContainer}>
            <CustomAvatarSection
              customMenuContainerStyle={styles.menuContainer}
              imageSuccessAction={successAction}
              config={listRowConfig(totalReplacements, totalHours, city)}
              favorite={favourite}
              avatar={getPharmacistImage(pharmacistId)}
              title={name}
              menuActions={menuAction({ pharmacistId, pharmacyId })}
              successAction={successAction}
            />
          </Box>
        );
      })}
      {loadSuccess && (
        <div ref={listEndRef} style={genericClasses.paginationDiv} />
      )}
    </Box>
  );
};

export default ListOfPharmacists;
