import './index.scss';
import React, { useRef } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format, isToday, isThisYear, isYesterday } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import en from 'date-fns/locale/en-US';

// eslint-disable-next-line import/no-extraneous-dependencies
import { GroupChannel } from '@sendbird/chat/groupChannel';

import Icon, { IconTypes, IconColors } from '@sendbird/uikit-react/ui/Icon';
import Label, {
  LabelColors,
  LabelTypography,
} from '@sendbird/uikit-react/ui/Label';
import Loader from '@sendbird/uikit-react/ui/Loader';
import {
  OutgoingMessageStates,
  getOutgoingMessageState,
} from '@sendbird/uikit-react/utils/message/getOutgoingMessageState';
import { getStringSet } from '@sendbird/uikit-react';
import useChatUtility from '../../../../../hooks/Admin/Chat/useChatUtility';

export const MessageStatusTypes = OutgoingMessageStates;
type Falsy = undefined | null | false | 0 | '';

interface MessageStatusProps {
  className?: string;
  message?: any | null;
  channel: Nullable<GroupChannel>;
  isDateSeparatorConsidered?: boolean;
}
export type Nullable<T> = T | null;

export const getLastMessageCreatedAt = ({
  channel,
  locale,
  stringSet = getStringSet('en'),
}: {
  channel: Nullable<GroupChannel>;
  locale: any;
  stringSet: any;
}) => {
  const createdAt = channel?.lastMessage?.createdAt;
  const optionalParam = locale ? { locale } : null;
  if (!createdAt) {
    return '';
  }
  if (isToday(createdAt)) {
    return format(
      createdAt,
      stringSet.DATE_FORMAT__LAST_MESSAGE_CREATED_AT__TODAY,
      optionalParam ?? {},
    );
  }
  if (isYesterday(createdAt)) {
    return stringSet.MESSAGE_STATUS__YESTERDAY || 'Yesterday';
  }
  if (isThisYear(createdAt)) {
    return format(
      createdAt,
      stringSet.DATE_FORMAT__LAST_MESSAGE_CREATED_AT__THIS_YEAR,
      optionalParam ?? {},
    );
  }
  return format(
    createdAt,
    stringSet.DATE_FORMAT__LAST_MESSAGE_CREATED_AT__PREVIOUS_YEAR,
    optionalParam ?? {},
  );
};

export function classnames(...args: (string | Falsy)[]) {
  return args.filter(Boolean).join(' ');
}

export const isSentStatus = (state: string): boolean =>
  state === OutgoingMessageStates.SENT
  || state === OutgoingMessageStates.DELIVERED
  || state === OutgoingMessageStates.READ;

const iconType = {
  [OutgoingMessageStates.SENT]: IconTypes.DONE,
  [OutgoingMessageStates.DELIVERED]: IconTypes.DONE_ALL,
  [OutgoingMessageStates.READ]: IconTypes.DONE_ALL,
  [OutgoingMessageStates.FAILED]: IconTypes.ERROR,
  [OutgoingMessageStates.PENDING]: undefined,
  [OutgoingMessageStates.NONE]: undefined,
};
const iconColor = {
  [OutgoingMessageStates.SENT]: IconColors.SENT,
  [OutgoingMessageStates.DELIVERED]: IconColors.SENT,
  [OutgoingMessageStates.READ]: IconColors.READ,
  [OutgoingMessageStates.FAILED]: IconColors.ERROR,
  [OutgoingMessageStates.PENDING]: undefined,
  [OutgoingMessageStates.NONE]: undefined,
};

const MessageStatus = ({
  className,
  message,
  channel,
  isDateSeparatorConsidered = true,
}: MessageStatusProps): React.ReactElement => {
  const stringSet = getStringSet('en');
  const dateLocale = en;
  const status = getOutgoingMessageState(channel, message);

  const read = useRef(false);
  const { getClientStatus } = useChatUtility();

  const hideMessageStatusIcon = channel?.isGroupChannel?.()
    && (channel.isSuper || channel.isPublic || channel.isBroadcast)
    && !(
      status === OutgoingMessageStates.PENDING
      || status === OutgoingMessageStates.FAILED
    );

  getClientStatus(channel, message).then((res) => {
    read.current = res;
  });

  const updatedType = read.current
    ? iconType[OutgoingMessageStates.READ]
    : iconType[status];
  const updatedColor = read.current
    ? iconColor[OutgoingMessageStates.READ]
    : iconColor[status];

  return (
    <div
      className={[
        ...(Array.isArray(className) ? className : [className]),
        'sendbird-message-status',
      ].join(' ')}
    >
      {status === OutgoingMessageStates.PENDING ? (
        <Loader
          className="sendbird-message-status__icon"
          testID="sendbird-message-status-icon"
          width="16px"
          height="16px"
        >
          <Icon
            type={IconTypes.SPINNER}
            fillColor={IconColors.PRIMARY}
            width="16px"
            height="16px"
          />
        </Loader>
      ) : (
        <Icon
          className={classnames(
            'sendbird-message-status__icon',
            hideMessageStatusIcon && 'hide-icon',
            status !== OutgoingMessageStates.FAILED
              && 'sendbird-message-status--sent',
          )}
          testID="sendbird-message-status-icon"
          type={updatedType || IconTypes.ERROR}
          fillColor={updatedColor}
          width="16px"
          height="16px"
        />
      )}
      {isSentStatus(status) && (
        <Label
          className="sendbird-message-status__text"
          testID="sendbird-message-status-text"
          type={LabelTypography.CAPTION_3}
          color={LabelColors.ONBACKGROUND_2}
        >
          {isDateSeparatorConsidered
            ? format(
              message?.createdAt || 0,
              stringSet.DATE_FORMAT__MESSAGE_CREATED_AT,
              { locale: dateLocale },
            )
            : getLastMessageCreatedAt({
              channel,
              locale: dateLocale,
              stringSet,
            })}
        </Label>
      )}
    </div>
  );
};

export default MessageStatus;
