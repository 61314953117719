const classes = {
  container: {
    padding: '10px',
    paddingBottom: '40px',
  },
  label: {
    fontSize: 16,
    lineHeight: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: '5px',
    color: '#4A5056',
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    fontSize: 16,
    lineHeight: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#96A0BC',
  },
  image: {
    width: '40%',
  },
};

export default classes;
