const classes = {
  container: { display: 'flex' },

  joinText: {
    fontSize: 22,
    fontWeight: 500,
    color: 'common.grey850',
  },

  alreadyMemberText: {
    fontSize: 12,
    fontWeight: 500,
    color: 'grey.400',
  },

  pharmaPlanText: {
    fontSize: 22,
    fontWeight: 700,
    color: 'common.grey850',
    marginLeft: '5px',
  },

  loginText: {
    color: 'primary.main',
    fontSize: 12,
    fontWeight: 600,
    marginLeft: '2px',
  },

  joinTextContainer: { marginBottom: '10px' },

};

export default classes;
