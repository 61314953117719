import { getToken, getMessaging } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { vapidConfig } from '../../Firebase/configs';

const usePushRegistration = () => {
  const [token, setToken] = useState('');

  const messaging = getMessaging();

  useEffect(() => {
    getToken(messaging, vapidConfig)
      .then((currentToken) => {
        if (currentToken) {
          setToken(currentToken);
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { token };
};

export default usePushRegistration;
