import { SxProps, Theme } from '@mui/system';

const classes = {
  rightNavbarContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  availabilitiesButton: {
    '& .MuiButton-root': {
      maxHeight: '30px',
      width: 'max-content',
      paddingLeft: '12px',
      paddingRight: '12px',
    },
    alignSelf: 'center',
    marginRight: '1rem',
  },

  iconLabel: {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: 'common.grey850',
  },

  keyboardIcon: {
    paddingLeft: '12px',
  },

  bottomNavbar: {
    borderTop: '2px solid',
    color: 'common.lightBackgroundColor',
    minHeight: '40px !important',
    paddingLeft: '4px',
    maxHeight: '50px !important',
    justifyContent: 'space-between',
  },

  bottomNavbarItem: {
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '170px',
    borderRadius: 0,
    borderBottom: '2px solid transparent',
    padding: '12px',
  },

  iconButtonRightBorder: {
    border: '0.5px solid',
    color: 'common.borderInput',
    height: '25px',
  },

  iconButtonGridContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  bottomNavbarItemActive: {
    borderBottomColor: 'primary.main',
  },

  bottomNavbarItemIcon: {
    marginRight: '10px',
    width: '20px',
    height: '20px',
  },

  navbarMenusContainer: {
    display: 'flex',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
