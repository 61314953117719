import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import {
  BookingState,
  ICalendarEventType,
  PharmacyLegendVariant,
  pharmacistBookingDetails,
} from '@pharmaplan/common';

import { ReactComponent as InfoIcon } from '../../../../assets/svg/smallInfo.svg';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';

import classes from './styles';

const { requested, booked } = PharmacyLegendVariant;

interface IInfoBox {
  eventType: ICalendarEventType;
  bookingState: BookingState;
}

const { infoContainer, rejectText } = classes;

const { UpdatedTiming, PendingWithPharmacist } = BookingState;

const InfoBox: FC<IInfoBox> = ({ eventType, bookingState }) => {
  const pharmacistDets = useAppSelector(pharmacistBookingDetails);

  const { bookingState: pharmacistBookingState } = pharmacistDets ?? {};

  const isBookingStateConfirm = bookingState === PendingWithPharmacist;
  const isUpdatedTiming = pharmacistBookingState === UpdatedTiming;

  const isRequested = eventType === requested && isBookingStateConfirm;
  const isBooked = eventType === booked && isUpdatedTiming;

  const message = isBookingStateConfirm
    ? strings.requestWillBeAutoRejected
    : strings.updateWillBeAutoCancelled;

  const displayInfo = () => {
    if (isRequested || isBooked) {
      return (
        <Box sx={infoContainer}>
          <InfoIcon />
          <Typography sx={rejectText}>{message}</Typography>
        </Box>
      );
    }

    return <Box />;
  };

  return <>{displayInfo()}</>;
};

export default InfoBox;
