/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { resetAll, setData } from '../actions/persistActions';

// RememberMe and tempRememberMe are used in order to
// ensure that the rememberme state only changes
// after the user has logged in.
export interface IFormPersistReducer {
  user: string;
  pharmacy: {
    pharmacyInfo: {
      name: string;
      languageId: string;
      number: string;
      softwareId: string;
      pharmacyChainId: string;
      selectedContactPreferences: Array<string>;
      weekVolume: string;
      weekendVolume: string;
      weekDayTechs: string;
      weekNightTechs: string;
      weekendDayTechs: string;
      weekendNightTechs: string;
      weekDayPharmacists: string;
      weekNightPharmacists: string;
      weekendDayPharmacists: string;
      weekendNightPharmacists: string;
    };
    coordinates: {
      phone: string;
      fax: string;
      emergencyPhone: string;
      address: string;
      city: string;
      province: string;
      postalCode: string;
    };
    primary: {
      primaryContactName: string;
      primaryContactPhone: string;
      primaryContactPhoneExt: string;
      primaryContactMobile: string;
      primaryContactEmail: string;
    };
    secondary: {
      secondaryContactName: string;
      secondaryContactPhone: string;
      secondaryContactPhoneExt: string;
      secondaryContactMobile: string;
      secondaryContactEmail: string;
    };
    accounting: {
      accountingContactName: string;
      accountingContactPhone: string;
      accountingContactPhoneExt: string;
      accountingContactMobile: string;
      accountingContactEmail: string;
    };
  };
  pharmacist: {
    general: { firstName: string; lastName: string; preferdLanguage: string };
    professionalInfo: {
      licenseNumber: string;
      pharmacistCapacityId: string;
      graduationYear: string;
      yearsOfExperience: string;
      englishLevel: string;
      frenchLevel: string;
      otherLanguages: string;
      incorporated: boolean;
    };
    selectedSoftwares: Array<object>;
    contacts: {
      address: string;
      city: string;
      province: string;
      postalCode: string;
      phone: string;
      mobile: string;
      preferredWorkingDays: Array<string>;
      preferredReplacementDuration: string;
      availabilityReminder: boolean;
      termsAndConditions: boolean;
    };
  };
}

const initalState = {
  user: '',
  pharmacy: {
    pharmacyInfo: {
      name: '',
      languageId: '',
      number: '',
      softwareId: '',
      pharmacyChainId: '',
      selectedContactPreferences: [],
      weekVolume: '',
      weekendVolume: '',
      weekDayTechs: '',
      weekNightTechs: '',
      weekendDayTechs: '',
      weekendNightTechs: '',
      weekDayPharmacists: '',
      weekNightPharmacists: '',
      weekendDayPharmacists: '',
      weekendNightPharmacists: '',
    },
    coordinates: {
      phone: '',
      fax: '',
      emergencyPhone: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
    },
    primary: {
      primaryContactName: '',
      primaryContactPhone: '',
      primaryContactPhoneExt: '',
      primaryContactMobile: '',
      primaryContactEmail: '',
    },
    secondary: {
      secondaryContactName: '',
      secondaryContactPhone: '',
      secondaryContactPhoneExt: '',
      secondaryContactMobile: '',
      secondaryContactEmail: '',
    },
    accounting: {
      accountingContactName: '',
      accountingContactPhone: '',
      accountingContactPhoneExt: '',
      accountingContactMobile: '',
      accountingContactEmail: '',
    },
  },
  pharmacist: {
    general: { firstName: '', lastName: '', preferdLanguage: '' },
    professionalInfo: {
      licenseNumber: '',
      pharmacistCapacityId: '',
      graduationYear: '',
      yearsOfExperience: '',
      englishLevel: '0',
      frenchLevel: '0',
      otherLanguages: '',
      incorporated: false,
    },
    selectedSoftwares: [],
    contacts: {
      address: '',
      city: '',
      province: '',
      postalCode: '',
      phone: '',
      mobile: '',
      preferredWorkingDays: [],
      preferredReplacementDuration: '',
      availabilityReminder: false,
      termsAndConditions: false,
    },
  },
};

const initialState: IFormPersistReducer = {
  ...initalState,
};

const FormPersistReducer = createReducer(initialState, (builder) => {
  builder.addCase(setData, (state, action) => {
    state.user = action.payload?.email;
    if (action.payload?.userType === 'Pharmacy') {
      state.pharmacy = {
        ...state.pharmacy,
        [action.payload?.type]: action.payload.data,
      };
    }
    if (action.payload?.userType === 'Pharmacist') {
      state.pharmacist = {
        ...state.pharmacist,
        [action.payload?.type]: action.payload.data,
      };
    }
  });
  builder.addCase(resetAll, (state) => {
    state.pharmacy = {
      ...initalState?.pharmacy,
    };
    state.pharmacist = {
      ...initalState?.pharmacist,
    };
  });
});

export default FormPersistReducer;
