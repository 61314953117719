import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  getEnforceProfileUpdate,
  ConfirmationTypes,
} from '@pharmaplan/common';

import ConfirmationModal from '../components/Modals/ConfirmationModal';
import { Constants } from '../helpers/Constants';
import { setDialog, resetDialog } from '../reducers/dialogReducer';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { logoutHandler } from '../helpers/Functions';

const useEnforceUpdate = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const enforceUpdate = useAppSelector(getEnforceProfileUpdate);
  const navigate = useNavigate();

  const pathsToShowFor = [
    Constants.paths.calendarDashboard,
    Constants.paths.mapDashboard,
    Constants.paths.changePassword,
    Constants.paths.dashboard,
    Constants.paths.myReplacements,
    Constants.paths.reports,
    Constants.paths.help,
    Constants.paths.history,
    Constants.paths.notes,
  ];

  const enforceModal = () => {
    if (pathsToShowFor.includes(location.pathname)) {
      dispatch(
        setDialog({
          showCloseButton: false,
          Component: (
            <ConfirmationModal
              showCloseButton={false}
              type={ConfirmationTypes.enforceUpdate}
              confirmAction={() => {
                dispatch(resetDialog());
                navigate(Constants.paths.profile);
              }}
              customClose={() => {
                dispatch(resetDialog());
                logoutHandler();
              }}
            />
          ),
        }),
      );
    }
  };

  useEffect(() => {
    enforceUpdate && enforceModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enforceModal, location.pathname]);
};

export default useEnforceUpdate;
