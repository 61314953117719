import React, { FC } from 'react';

import { SxProps } from '@mui/system';

import { IPharmacistEvent, IPharmacyDetails } from '@pharmaplan/common';
import { ReactComponent as DayIcon } from '@pharmaplan/common/assets/icons/dayIconSmall.svg';
import { ReactComponent as NightIcon } from '@pharmaplan/common/assets/icons/nightIconSmall.svg';

import strings from '../../../localization';
import ThemeConstants from '../../../theme/ThemeConstants';
import ServiceCard from '../ServiceCard/ServiceCard';

import PharmacyOperationContent from './PharmacyOperationContent/PharmacyOperationContent';
import classes from './styles';

interface IPharmacyOperation {
  event: IPharmacistEvent | IPharmacyDetails;
  showTechnician?: boolean;
  title?: string;
  customStyle?: SxProps;
  successAction: string;
}

const PharmacyOperation: FC<IPharmacyOperation> = ({ title, event, customStyle, successAction, showTechnician = true }) => {
  const {
    weekVolume,
    weekendVolume,
    weekDayTechs,
    weekendDayTechs,
    weekDayPharmacists,
    weekendDayPharmacists,
    weekNightPharmacists,
    weekNightTechs,
    weekendNightPharmacists,
    weekendNightTechs,
  } = event ?? {};

  const operationConfig = [
    {
      key: '0',
      volume: `${strings.weekVolumes}: ${weekVolume}`,
      container: classes.container,
      data: [
        {
          key: '0',
          name: strings.dayShift.toUpperCase(),
          icon: (
            <DayIcon
              stroke={ThemeConstants.common.grey850}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          ),
          pharmacist: `${strings.pharmacists}: ${weekDayPharmacists}`,
          technician: `${strings.technicians}: ${weekDayTechs}`,
        },
        {
          key: '1',
          name: strings.nightShift.toUpperCase(),
          icon: <NightIcon fill={ThemeConstants.common.grey850} />,
          pharmacist: `${strings.pharmacists}: ${weekNightPharmacists}`,
          technician: `${strings.technicians}: ${weekNightTechs}`,
        },
      ],
    },
    {
      key: '1',
      volume: `${strings.weekEndVolumes}: ${weekendVolume}`,
      container: [classes.container, classes.containerWeekend],
      data: [
        {
          key: '2',
          name: strings.dayShift.toUpperCase(),
          icon: (
            <DayIcon
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke={ThemeConstants.common.grey850}
            />
          ),

          pharmacist: `${strings.pharmacists}: ${weekendDayPharmacists}`,
          technician: `${strings.technicians}: ${weekendDayTechs}`,
        },
        {
          key: '3',
          name: strings.nightShift.toUpperCase(),
          icon: <NightIcon fill={ThemeConstants.common.grey850} />,
          pharmacist: `${strings.pharmacists}: ${weekendNightPharmacists}`,
          technician: `${strings.technicians}: ${weekendNightTechs}`,
        },
      ],
    },
  ];

  return (
    <ServiceCard
      title={title ?? strings.pharmacyOperation}
      customContainerStyle={customStyle}
      content={(
        <PharmacyOperationContent showTechnician={showTechnician} successAction={successAction} operations={operationConfig} />
      )}
    />
  );
};
export default PharmacyOperation;
