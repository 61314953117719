import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  horizontalDatePicker: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& label': {
      flex: 2,
    },
    '& .MuiTextField-root': {
      flex: 4,
    },
  },
  error: {
    '& .MuiFormHelperText-root': {
      color: ThemeConstants.common.error,
    },
  },
  font: {
    '.MuiPickersCalendarHeader-root': {
      width: 'auto',
    },
    '.MuiPickersCalendarHeader-label': {
      fontSize: 16,
      whiteSpace: 'nowrap',
    },
  },
};

export default classes;
