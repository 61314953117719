import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const styles = {
  title: {
    fontSize: '20px',
    marginBottom: '12px',
    fontWeight: 600,
    color: ThemeConstants.grey[800],
  },
  hourlyRate: {
    '& fieldset': { border: 'none' },
    '& label': {
      flex: 2,
      marginBottom: 3,
    },
    '& .MuiTextField-root': {
      flex: 1,
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
