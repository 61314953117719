import React from 'react';
import { Step } from 'react-joyride';
import { Box } from '@mui/material';
import strings from '../localization';
import { ReactComponent as Calendar } from '../assets/svg/calendar-grey.svg';
import genericClasses from '../theme/GenericClasses';

const journeyStepsPharmacist = (): Step[] =>
  [
    {
      content: strings.profileBtn,
      target: '#avatarGuide',
      disableBeacon: true,
    },
    {
      content: strings.dashboardBtn,
      target: '#dashboard',
      disableBeacon: true,
    },
    {
      content: strings.pharmaciesServedGuide,
      target: '#myReplacements',
      disableBeacon: true,
    },
    {
      content: strings.reportsBtn,
      target: '#reports',
      disableBeacon: true,
    },
    {
      content: strings.availabilityWebBtn,
      target: '#availabilties',
      disableBeacon: true,
    },
    {
      content: strings.datePicker,
      target: '#calendarViewDropdown',
      disableBeacon: true,
    },
    {
      content: strings.todayBtn,
      target: '#today',
      disableBeacon: true,
    },
    {
      content: strings.watchlistIcon,
      target: '#watchlist',
      disableBeacon: true,
    },
    {
      content: strings.searchBtn,
      target: '#filter',
      disableBeacon: true,
    },
    {
      content: (
        <Box component="span">
          {strings.mapView}
          <Box component="span" sx={genericClasses.inlineIcon}>
            (
            <Calendar height={24} width={24} />
            )
          </Box>
          {strings.icon}
        </Box>
      ),
      target: '#map',
      disableBeacon: true,
      spotlightPadding: 3,
    },
    {
      content: strings.legendGuidePharmacist,
      target: '#legendContainer',
      disableBeacon: true,
    },
    {
      content: strings.notificationIconPharmacist,
      target: '#notificationIcon',
      disableBeacon: true,
    },
  ];

const journeyStepsPharmacy = (): Step[] =>
  [
    {
      content: strings.profileBtnPharmacy,
      target: '#avatarGuide',
      disableBeacon: true,
    },
    {
      content: strings.dashboardBtn,
      target: '#dashboard',
      disableBeacon: true,
    },
    {
      content: strings.myReplacementsBtn,
      target: '#myReplacements',
      disableBeacon: true,
    },
    {
      content: strings.reportsBtnPharmacy,
      target: '#reports',
      disableBeacon: true,
    },
    {
      content: strings.myWorkShifts,
      target: '#availabilties',
      disableBeacon: true,
    },
    {
      content: strings.datePicker,
      target: '#calendarViewDropdown',
      disableBeacon: true,
    },
    {
      content: strings.todayBtn,
      target: '#today',
      disableBeacon: true,
    },
    {
      content: strings.legendGuide,
      target: '#legendContainer',
      disableBeacon: true,
    },
    {
      content: strings.notificationIcon,
      target: '#notificationIcon',
      disableBeacon: true,
    },
  ];

const journeyStepsButtons = () =>
  ({
    back: strings.backGuide,
    close: strings.close,
    last: strings.end,
    next: strings.next,
    skip: strings.skip,
  });

export { journeyStepsPharmacist, journeyStepsPharmacy, journeyStepsButtons };
