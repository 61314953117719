import React from 'react';
import { Outlet } from 'react-router-dom';
import GuestNavbar from '../Navbar/GuestNavbar';

const GuestLayout = () =>
  (
    <>
      <GuestNavbar />
      <Outlet />

    </>
  );

export default GuestLayout;
