import {
  TableCell,
  Typography,
  Tooltip,
  MenuItem,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { ReactComponent as MenuIcon } from '../../../assets/svg/menuBlock.svg';
import {
  Constants,
  DynamicTableCell,
  statusMap,
} from '../../../helpers/Constants';
import genericClasses from '../../../theme/GenericClasses';
import CustomButton from '../../common/CustomButton';
import CustomFavourite from '../../common/CustomFavourite';
import CustomLink from '../../common/CustomLink';
import classes from '../style';
import { IDynamicTableHeaderBar, IDynamicTableRow } from '../types';
import DisplayChip from '../../common/DisplayChip';
import Checkbox from '../../common/Checkbox';
import CustomMenuList from '../../common/CustomMenuList';
import CustomIncompatible from '../../common/CustomIncompatible';
import strings from '../../../localization';
import ImageCell from '../../common/ImageCell';

interface IDynamicCell {
  item: IDynamicTableRow;
  id: string;
  loading: string[];
  setLoading: React.Dispatch<React.SetStateAction<string[]>>;
}
const { contained } = Constants.variant;
const DynamicCell: FC<IDynamicCell> = ({ item, id, loading, setLoading }) => {
  const {
    fontColor,
    isCenterAligned,
    show,
    type: itemType,
    maxWidth,
    value,
    minWidth,
  } = item ?? {};

  const {
    label,
    style,
    color,
    disabled,
    startIcon,
    checked,
    handlePress,
    tooltipText,
    variant,
    fullWidth,
    customMenuActions,
    imageUrl,
  } = value ?? {};

  const cellStyle = classes(
    fontColor || 'common.gray',
    isCenterAligned,
    maxWidth,
    minWidth,
  );

  switch (itemType) {
    case DynamicTableCell.text:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          <Typography sx={[cellStyle.text]}>
            {value as React.ReactNode}
          </Typography>
        </TableCell>
      );
    case DynamicTableCell.textBox:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          <Typography sx={[cellStyle.text, cellStyle.maxNote]}>
            {value as React.ReactNode}
          </Typography>
        </TableCell>
      );
    case DynamicTableCell.smallerText:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          <Typography sx={cellStyle.smallerText}>
            {value as React.ReactNode}
          </Typography>
        </TableCell>
      );
    case DynamicTableCell.link:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          <CustomLink to={value} linkText={value} />
        </TableCell>
      );
    case DynamicTableCell.customLink:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          <Box sx={cellStyle.barStyle}>
            <Typography
              sx={[cellStyle.customLinkGeneric, cellStyle.customLink]}
              component="a"
              onClick={handlePress}
            >
              {label}
            </Typography>
          </Box>
        </TableCell>
      );
    case DynamicTableCell.customLinkList:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          {value.map(
            (
              iItem: { key: string; label: string; onClick: () => void },
              index: number,
            ) => {
              const { key, label: inLabel, onClick: inOnClick } = iItem;
              const separator = index === (value?.length ?? 0) - 1 ? '' : ',';
              return (
                <Typography
                  key={key}
                  sx={[
                    cellStyle.customLinkGeneric,
                    cellStyle.customLink,
                    cellStyle.linkSep,
                  ]}
                  component="a"
                  onClick={inOnClick}
                >
                  {inLabel}
                  {separator}
                </Typography>
              );
            },
          )}
        </TableCell>
      );
    case DynamicTableCell.smallerCustomLink:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          <Typography
            sx={[cellStyle.customLinkGeneric, cellStyle.smallerLinkText]}
            component="a"
            onClick={handlePress}
          >
            {label}
          </Typography>
        </TableCell>
      );
    case DynamicTableCell.date:
      return (
        <TableCell sx={[cellStyle.dateColumn, cellStyle.cellFontColor]}>
          <Typography sx={cellStyle.text}>
            {value as React.ReactNode}
          </Typography>
        </TableCell>
      );
    case DynamicTableCell.notes:
      return (
        <TableCell sx={[cellStyle.note, cellStyle.cellFontColor]}>
          <Typography sx={cellStyle.text}>
            {value as React.ReactNode}
          </Typography>
        </TableCell>
      );
    case DynamicTableCell.status:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          <Typography
            sx={[
              cellStyle.text,
              { color: statusMap()[value as keyof typeof statusMap] },
            ]}
          >
            {value as React.ReactNode}
          </Typography>
        </TableCell>
      );
    case DynamicTableCell.icon:
      return (
        <TableCell sx={[cellStyle.cell]}>
          {value && (
            <img
              alt="table icon"
              src={(value as string) ?? ''}
              style={cellStyle.imageCell}
            />
          )}
        </TableCell>
      );
    case DynamicTableCell.favourite:
      return (
        <TableCell>
          <CustomFavourite
            loading={loading}
            setLoading={setLoading}
            initState={checked}
            disabled={disabled}
            pharmacyId={id}
          />
        </TableCell>
      );
    case DynamicTableCell.button:
      return (
        <TableCell key={value?.label} sx={[cellStyle.cell]}>
          {show && (
            <CustomButton
              customButtonStyle={style}
              color={color || Constants.color.primary}
              label={label}
              disabled={disabled}
              fullWidth={fullWidth}
              startIcon={startIcon}
              onClick={handlePress}
              variant={variant || contained}
            />
          )}
        </TableCell>
      );
    case DynamicTableCell.bar:
      return (
        <TableCell key={0}>
          <Box component="div" sx={[genericClasses.flex, cellStyle.barStyle]}>
            {value?.map((bar: IDynamicTableHeaderBar) => {
              const {
                tooltip,
                key,
                onClick,
                icon,
                disabled: iconDisabled,
                Component,
              } = bar ?? {};

              return (
                <React.Fragment key={key}>
                  {icon && (
                    <Tooltip
                      key={`tooltip-${key}`}
                      arrow
                      placement="top"
                      title={tooltip}
                    >
                      <span style={genericClasses.flex}>
                        <MenuItem
                          disabled={iconDisabled}
                          key={`menuitem-${key}`}
                          sx={[cellStyle.tableCellAction]}
                          onClick={onClick}
                        >
                          <img src={icon} alt="table bar icons" />
                        </MenuItem>
                      </span>
                    </Tooltip>
                  )}
                  {!!Component && Component}
                </React.Fragment>
              );
            })}
          </Box>
        </TableCell>
      );

    case DynamicTableCell.checkbox:
      return (
        <TableCell>
          <Tooltip title={tooltipText} disableHoverListener={!disabled}>
            <span>
              <Checkbox
                onChange={handlePress}
                checked={checked}
                disabled={disabled}
                sx={cellStyle.checkbox}
              />
            </span>
          </Tooltip>
        </TableCell>
      );
    case DynamicTableCell.iconButton:
      return (
        <TableCell key={0}>
          <Box component="div" sx={genericClasses.center}>
            {value?.map((bar: IDynamicTableHeaderBar) => {
              const {
                tooltip,
                key,
                onClick,
                icon,
                disabled: disabledIcon,
              } = bar ?? {};
              return (
                icon && (
                  <Tooltip arrow placement="top" title={tooltip}>
                    <span>
                      <IconButton
                        key={key}
                        disabled={disabledIcon}
                        sx={cellStyle.tableCellAction}
                        onClick={onClick}
                      >
                        <Box
                          component="img"
                          src={icon}
                          alt="Table Icon Buttons"
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                )
              );
            })}
          </Box>
        </TableCell>
      );
    case DynamicTableCell.chip:
      return (
        <TableCell>
          <Box sx={cellStyle.barStyle}>
            <DisplayChip style={style} value={label} />
          </Box>
        </TableCell>
      );
    case DynamicTableCell.customMenu:
      return (
        <TableCell>
          <CustomMenuList
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            margin={10}
            menuStyle={cellStyle.menu}
            disableFixedDropdown
            customMenuTextStyle={cellStyle.menuText}
            customIconButtonStyle={cellStyle.menuButton}
            items={customMenuActions}
          >
            <Tooltip title={strings.more} arrow placement="top">
              <MenuIcon height={24} width={24} />
            </Tooltip>
          </CustomMenuList>
        </TableCell>
      );
    case DynamicTableCell.incompatible:
      return (
        show && (
          <TableCell>
            <CustomIncompatible pharmacistId={id} initState={value} />
          </TableCell>
        )
      );
    case DynamicTableCell.labelValue:
      return (
        <TableCell>
          {value.map(
            (valueItem: { label: string; value: string; key: string }) => {
              const { label: inLabel, value: inValue, key } = valueItem ?? {};
              return (
                <Box key={key} sx={cellStyle.labelValueContainer}>
                  <Typography
                    component="span"
                    sx={[
                      cellStyle.labelValue,
                      cellStyle.text,
                      cellStyle.maxNote,
                    ]}
                  >
                    <Typography component="span" sx={[cellStyle.labelTitle]}>
                      {inLabel}
                      :
                    </Typography>
                    {inValue}
                  </Typography>
                </Box>
              );
            },
          )}
        </TableCell>
      );

    case DynamicTableCell.userAndType:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          {value?.map((userAndTypeItem: any, index: number) => {
            const {
              key,
              userName,
              userType,
              handlePress: handleUserClick,
              disable,
            } = userAndTypeItem ?? {};

            const isLastIndex = index === value.length - 1;

            const showUsername = isLastIndex ? userName : `${userName}, `;

            return (
              <Box key={key}>
                <Typography
                  sx={[
                    disable ? {} : cellStyle.customLinkGeneric,
                    cellStyle.customLink,
                  ]}
                  component="a"
                  onClick={disable ? null : handleUserClick}
                >
                  {showUsername}
                </Typography>
                <Typography sx={cellStyle.userType}>{userType}</Typography>
              </Box>
            );
          })}
        </TableCell>
      );
    case DynamicTableCell.imageCell:
      return (
        <TableCell sx={[cellStyle.cell, cellStyle.cellFontColor]}>
          <ImageCell src={imageUrl} label={label} />
        </TableCell>
      );
    default:
      return <div />;
  }
};

export default DynamicCell;
