import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import strings from '../../../localization';
import styles from './styles';
import FullScreenImage from '../FullScreenImage';

interface IImageCell {
  src: string;
  label: string;
}

const ImageCell: FC<IImageCell> = ({ src, label }) => {
  const [showImage, setShowImage] = useState(false);

  const showHideImage = (actionType: boolean) =>
    () => {
      setShowImage(actionType);
    };

  return (
    <Box sx={styles.container}>
      {showImage && (
        <FullScreenImage image={src} hideImage={showHideImage(false)} />
      )}
      <Box sx={styles.overlayContainer}>
        <img src={src} alt={strings.picture} style={styles.image} />
        <IconButton sx={styles.overlay} onClick={showHideImage(true)}>
          <VisibilityIcon sx={{ color: 'white' }} />
        </IconButton>
      </Box>
      <Typography sx={styles.label}>{label}</Typography>
    </Box>
  );
};

export default ImageCell;
