import {
  adminActions,
  getPharmacistRegions,
  pharmacistRegionList,
  resetStatus,
  successSelector,
  updatePharmacistRegions,
} from '@pharmaplan/common';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import { ScreenTypes } from '../../../helpers/Constants';
import {
  renderScreen,
  setBack,
  setFooter,
  setFooterShow,
} from '../../../actions/drawerActions';
import strings from '../../../localization';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import { IAdminGeographicAvailability } from '../../../components/Admin/Profile/AdminGeographicAvailability/AdminGeographicAvailability';

const initialValues: Array<string> = [];

const useGeographicAvailability = ({
  pharmacistId,
  type,
  hasOwner,
  fromScreen,
  back,
}: IAdminGeographicAvailability) => {
  const dispatch = useAppDispatch();

  const regions = useAppSelector(pharmacistRegionList);
  const success = useAppSelector((state) =>
    successSelector([adminActions.updatePharmacistRegions], state));

  const { selectedRegions } = regions ?? {};

  const onSubmit = (values: Array<string>) => {
    dispatch(
      updatePharmacistRegions({
        pharmacistId,
        regions: values,
      }),
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, setValues } = formik ?? {};

  const gotoPharmacistProfile = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.profile,
        pharmacistId,
        type,
        hasOwner,
        fromScreen,
      }),
    );
  };

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.profileDataSaved);
      dispatch(resetStatus());
      gotoPharmacistProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    setValues(selectedRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegions]);

  useEffect(() => {
    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.saveChanges,
            onClick: handleSubmit,
          },
        },
      }),
    );

    dispatch(getPharmacistRegions(pharmacistId));
    dispatch(setBack(back ?? gotoPharmacistProfile));

    return () => {
      dispatch(setFooterShow(false));
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { formik };
};

export default useGeographicAvailability;
