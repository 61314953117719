/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { resetStatus, successSelector } from '@pharmaplan/common';
import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../localization';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';

interface IUseConfigurationsSuccess {
  addAction: string;
  editAction: string;
}

const useConfigurationsSuccess = ({
  addAction,
  editAction,
}: IUseConfigurationsSuccess) => {
  const dispatch = useAppDispatch();

  const addSuccess = useAppSelector((state) =>
    successSelector([addAction], state));
  const editSuccess = useAppSelector((state) =>
    successSelector([editAction], state));

  // HANDLE ADD SUCCESS
  useEffect(() => {
    if (addSuccess) {
      showSuccess(dispatch, strings.addedSuccessfullyFormat);
      dispatch(resetStatus([addAction]));
    }
  }, [addSuccess]);

  // HANDLE EDIT SUCCESS
  useEffect(() => {
    if (editSuccess) {
      showSuccess(dispatch, strings.updatedSuccessfullyFormat);
      dispatch(resetStatus([editAction]));
    }
  }, [editSuccess]);
};

export default useConfigurationsSuccess;
