import { emailRegex } from '@pharmaplan/common';
import * as Yup from 'yup';
import strings from '../../../localization';

export const emailValidation = () =>
  Yup.string().matches(emailRegex, strings.emailValid).trim().required(strings.requiredField);

export const rememberValidator = () =>
  Yup.object().shape({
    password: Yup.string().required(strings.requiredField),
  });

const Validator = () =>
  Yup.object().shape({
    email: emailValidation(),
    password: Yup.string().required(strings.requiredField),
  });

export default Validator;
