import { SxProps, Theme } from '@mui/material';

const classes = {
  firstImage: {
    position: 'absolute',
  },
  secondImage: {
    left: '23%',
    position: 'absolute',
  },
  thirdImage: {
    left: '46%',
    position: 'absolute',
  },
  image: {
    height: 45,
    width: 45,
    borderRadius: '200px',
    border: '1.5px solid #ADB6CF',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  subContainer: {
    flex: 1,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  textContainer: {
    alignItems: 'center',
    // flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    color: 'common.grey850',
    marginLeft: '21px',
  },

  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
