import { SxProps, Theme } from '@mui/system';

const classes = {

  checkPosition: {
    marginLeft: '10px',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'row-reverse',
  },

  checkboxText: {
    textAlign: 'left',
    flex: 1,
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
