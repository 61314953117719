const classes = {
  root: {
    borderRadius: '0px 16px 16px 16px',
  },

  pharmacySummaryRoot: {
    backgroundColor: '#F6F7FC',
    borderRadius: '0px 16px 16px 16px',
  },
};
export default classes;
