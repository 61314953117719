import React, { useEffect, useState } from 'react';

import { FormikProps } from 'formik';

import {
  ConfirmationTypes,
  ICalendarEventType,
  PharmacyLegendVariant,
  deleteWorkshift,
  getBookedPharmacistDetails,
  pharmacistBookingDetails,
  pharmacyActions,
  pharmacyWorkshift,
  resetStatus,
  successOrSelector,
} from '@pharmaplan/common';

import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import OutcomeModal from '../../components/Modals/OutcomeModal';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import useCancellationBooking from '../useCancellationBooking';
import { resetDialog, setDialog } from '../../reducers/dialogReducer';
import { selectFooterPaginationCurrent } from '../../selectors/drawerSelector';
import { renderScreen, setCurrent } from '../../actions/drawerActions';

import {
  Constants,
  OutcomeModalTypes,
  ScreenTypes,
} from '../../helpers/Constants';
import strings from '../../localization';

const { updateTiming } = OutcomeModalTypes;
const {
  updateTiming: confirmUpdateTiming,
  deleteWorkshift: confirmDeleteWorkshift,
} = ConfirmationTypes;

const { updateWorkshift } = ScreenTypes;

const { booked } = PharmacyLegendVariant;

const {
  variant: { text },
} = Constants;

const useWrokshiftSummaryModals = (
  eventIds: Array<string>,
  eventType: ICalendarEventType,
  formik: FormikProps<any>,
) => {
  const dispatch = useAppDispatch();
  const { handleSelfCancellation } = useCancellationBooking();

  const [show, setShow] = useState(false);

  const current = useAppSelector(selectFooterPaginationCurrent);
  const pharmacistDets = useAppSelector(pharmacistBookingDetails);

  const updateTimingsSuccess = useAppSelector((state) =>
    successOrSelector([pharmacyActions.updateTimings], state));

  const currentWorkshift = useAppSelector(pharmacyWorkshift);

  const {
    startDate: pharmacistStartDate,
    confirmationDate = '',
    gracePeriodInHours = 0,
  } = pharmacistDets ?? {};

  const { startDate } = currentWorkshift ?? {};

  const id = eventIds[current - 1];

  const { handleSubmit: formikHandleSubmit } = formik ?? {};

  const isBooked = eventType === booked;

  const savePaginationIndex = () => {
    dispatch(setCurrent(current));
  };

  const getPharmacist = () => {
    dispatch(
      getBookedPharmacistDetails({
        id,
        eventType,
      }),
    );
  };

  const showUpdateTimingSuccessModal = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <OutcomeModal
            type={updateTiming}
            message={strings.updatesArePending}
            showCloseButton
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  const confirmAction = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            type={confirmUpdateTiming}
            confirmAction={() => {
              dispatch(resetDialog());
              setShow(true);
            }}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteWorkshift({
        id,
        recurrenceId: '',
      }),
    );

    dispatch(resetDialog());
  };

  const confirmRejection = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            type={confirmDeleteWorkshift}
            confirmAction={handleDelete}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  const goToUpdateWorkshift = () => {
    savePaginationIndex();

    dispatch(
      renderScreen({
        eventIds,
        eventId: id,
        type: eventType,
        screenNumber: 2,
        date: startDate,
        screenType: updateWorkshift,
      }),
    );
  };

  const selfCancellation = () => {
    handleSelfCancellation(
      pharmacistStartDate,
      confirmationDate,
      id,
      gracePeriodInHours,
    );
  };

  const setUpdateTiming = () => {
    setShow(false);
  };

  const handleActions = () => {
    if (isBooked) {
      if (show) {
        return {
          primaryButton: {
            label: strings.saveChanges,
            onClick: formikHandleSubmit,
          },
          secondaryButton: {
            label: strings.discardChanges,
            variant: text,
            onClick: setUpdateTiming,
          },
        };
      }
      return {
        primaryButton: {
          label: strings.updateTiming,
          onClick: confirmAction,
        },
        secondaryButton: {
          label: strings.cancelBooking,
          variant: text,
          onClick: selfCancellation,
        },
      };
    }
    return {
      primaryButton: {
        label: strings.updateWorkshift,
        onClick: goToUpdateWorkshift,
      },

      secondaryButton: {
        label: strings.deleteWorkshift,
        variant: text,
        onClick: confirmRejection,
      },
    };
  };

  useEffect(() => {
    if (updateTimingsSuccess) {
      showUpdateTimingSuccessModal();
      setShow(false);
      getPharmacist();
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTimingsSuccess]);

  return { show, handleActions, getPharmacist };
};

export default useWrokshiftSummaryModals;
