import React from 'react';

import { useNavigate } from 'react-router-dom';
import { FormikValues } from 'formik';

import {
  BroadcastNotificationType,
  deleteBroadcastNotification,
} from '@pharmaplan/common';

import Validator from '../../../components/Admin/BroadcastList/CreateEditBroadcastNotification/validator';
import DeactivateDialog from '../../../components/Admin/PharmacistList/DeactivateDialog';
import { Constants, DeactivateModalIcon } from '../../../helpers/Constants';
import { setDialog } from '../../../reducers/dialogReducer';
import { useAppDispatch } from '../../useAppDispatch';
import strings from '../../../localization';

const { editNotification } = Constants.paths.admin;

const useBroadcastNotificationActions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleEdit = (notificationId: string, notificationType: BroadcastNotificationType) =>
    () => {
      navigate(editNotification, {
        state: { id: notificationId, notificationType },
      });
    };

  const confirmAction = (values: FormikValues) => {
    const { id, reason } = values ?? {};
    dispatch(
      deleteBroadcastNotification({ broadcastNotificationId: id, reason }),
    );
  };

  const handleDelete = (id: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              onSubmit={confirmAction}
              confirmationMessage={strings.areYouSureDeleteEntry}
              id={id}
              heading={strings.delete}
              iconType={DeactivateModalIcon.warning}
              validator={Validator()}
            />
          ),
          showCloseButton: true,
          heading: {
            title: '',
          },
        }),
      );
    };

  return { handleEdit, handleDelete };
};

export default useBroadcastNotificationActions;
