import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

import {
  adminActions,
  AdminLegendVariant,
  findMatchingWorkshifts,
  pharmacistMatchingWorkshifts,
  successSelector,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { renderScreen, setBack, setFooterShow } from '../../../actions/drawerActions';
import { OtherScreens, ScreenTypes } from '../../../helpers/Constants';
import useUserProfileLink from '../../../hooks/Admin/useUserProfileLink';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';
import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';
import AdminPharmacistCard from '../Profile/Pharmacist/PharmacistViewProfile/AdminPharmacistCard';

import { showAvailabilitiesHeader } from '../ShowPharmacistAvailabilities/helper';
import NoAvailabilities from '../MatchingAvailabilities/NoAvailabilities';

import {
  availabilitiesRowConfig,
  matchingWorkshiftsHeaders,
  matchingWorkshiftsRowConfig,
} from './helper';
import styles from './styles';

interface IMatchingWorkshifts {
  availabilityId: string;
  code: string;
  pId?: string;
}

const successAction = adminActions.matchingWorkshifts;

const MatchingWorkshifts: FC<IMatchingWorkshifts> = ({
  availabilityId,
  code,
  pId,
}) => {
  const dispatch = useAppDispatch();

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const matchingWorkshifts = useAppSelector(pharmacistMatchingWorkshifts);

  const loadSuccess = useAppSelector((state) =>
    successSelector([successAction], state));

  const { pharmacistId, startDate, endDate, matchingAvailabilities } = matchingWorkshifts ?? {};

  useEffect(() => {
    dispatch(findMatchingWorkshifts(availabilityId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const availabilitiesTable: IDynamicTableObject = {
    headers: showAvailabilitiesHeader().slice(0, 3),
    rows: availabilitiesRowConfig({ startDate, endDate, code }, timeFormat),
    headerBar: [],
  };

  const ratingGoBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 5,
        screenType: ScreenTypes.pharmacistList,
        date: code,
        eventId: availabilityId,
      }),
    );
  };

  const goToApproveBooking = (workshiftId: string) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 4,
          screenType: ScreenTypes.requestedList,
          fromScreen: OtherScreens.MatchingWorkshifts,
          eventId: availabilityId,
          workshiftId,
          type: AdminLegendVariant.posted,
          back: ratingGoBack,
        }),
      );
    };

  const { goToUserProfile } = useUserProfileLink(ratingGoBack);

  const workshiftsTable: IDynamicTableObject = {
    headerBar: [],
    headers: matchingWorkshiftsHeaders(),
    rows: matchingWorkshiftsRowConfig(
      matchingAvailabilities,
      goToUserProfile,
      timeFormat,
      goToApproveBooking,
    ),
  };

  const goBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 4,
        screenType: ScreenTypes.pharmacistList,
        pharmacistId,
      }),
    );
  };

  useEffect(() => {
    if (pharmacistId && !pId) {
      dispatch(setBack(goBack));
    }

    dispatch(setFooterShow(false));

    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacistId]);

  const emptyContainerComponent = matchingAvailabilities.length === 0 ? (
    <NoAvailabilities message={strings.noMatchingWorkshiftsToShow} />
  ) : null;

  return (
    <Stack>
      <AdminPharmacistCard
        ratingGoBack={ratingGoBack}
        pharmacistId={pharmacistId ?? pId}
        type={AdminLegendVariant.posted}
        hasOwner={false}
        fromScreen={OtherScreens.MatchingWorkshifts}
      />
      <DynamicTable
        customContainerStyle={styles.tableContainer}
        noContainerStyling
        showHeader={false}
        customHeader={
          <Typography sx={styles.title}>{strings.availabilities}</Typography>
        }
        loadSuccess={loadSuccess}
        table={availabilitiesTable}
        hidePagination
      />
      <Box sx={styles.tableBox}>
        <DynamicTable
          customContainerStyle={styles.tableContainer}
          noContainerStyling
          showHeader={false}
          emptyContainerComponent={emptyContainerComponent}
          customHeader={
            <Typography sx={styles.title}>{strings.workshifts}</Typography>
          }
          loadSuccess={loadSuccess}
          table={workshiftsTable}
          hidePagination
        />
      </Box>
    </Stack>
  );
};

export default MatchingWorkshifts;
