const classes = {
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
  },
  infoColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    paddingBottom: '4px',
    color: 'grey.800',
    paddingLeft: '12px',
    fontSize: '12px',
  },
  value: {
    paddingBottom: '4px',
    paddingLeft: '12px',
    color: 'grey.800',
    fontSize: '14px',
    fontWeight: 500,
  },
  addressContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '13px',
    alignItems: 'center',
  },
  address: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'grey.800',
    paddingLeft: '12px',
  },
  buttonContainer: {
    paddingTop: '16px',
  },
  button: {
    backgroundColor: 'common.lightGreen',
    color: 'primary.main',
  },
};

export default classes;
