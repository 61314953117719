import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Constants } from '../helpers/Constants';

interface IProtectedRouteProps {
  authenticated: boolean;
}

const ProtectedRoute = ({ authenticated }: IProtectedRouteProps) =>
  (authenticated ? <Outlet /> : <Navigate to={Constants.paths.login} replace />);

export default ProtectedRoute;
