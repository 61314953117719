import { useEffect } from 'react';
import { useFormik as formikHook, FormikValues } from 'formik';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useComponentDidUpdate } from '../useComponentDidUpdate';
import {
  PharmacistListTabTypes,
  PharmacyListTabTypes,
} from '../../helpers/Constants';

export type TabTypes = PharmacistListTabTypes | PharmacyListTabTypes;

interface IUseFormik {
  initialValues: any;
  onSubmit: (values: FormikValues, inPage?: number) => void;
  page: number;
  selectedTab?: TabTypes;
}

const encodeBase64 = (data: Record<string, any>) => {
  const jsonString = JSON.stringify(data);
  return btoa(jsonString);
};

const decodeBase64 = (base64String: string) => {
  try {
    const jsonString = atob(base64String);
    return JSON.parse(jsonString);
  } catch {
    return {};
  }
};

const isEmptyObj = (obj: object) =>
  Object.keys(obj).length === 0;

const useFormik = ({
  initialValues,
  onSubmit,
  page,
  selectedTab,
}: IUseFormik) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const sParams = new URLSearchParams(location.search);

  const getPageFromUrl = () => {
    const pageParam = sParams.get('page');

    if (pageParam === null) {
      return 1;
    }

    const retPage = parseInt(pageParam, 10);

    if (Number.isNaN(retPage) || page <= 0) {
      return 1;
    }

    return retPage;
  };

  const { pathname } = location ?? {};

  const filterParams = (params: Record<string, any>) =>
    Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) =>
          value !== '' && value != null,
      ),
    );

  const handleSerialize = (values: FormikValues) => {
    const params = {
      ...values,
    };

    const filteredParams = filterParams(params);
    const base64Data = encodeBase64(filteredParams);

    if (selectedTab) {
      setSearchParams({
        page: String(page),
        selectedTab,
        data: base64Data,
      });
    } else {
      setSearchParams({
        page: String(page),
        data: base64Data,
      });
    }
  };

  const clearURL = () => {
    const params = new URLSearchParams();
    params.set('page', String(page));
    selectedTab && params.set('selectedTab', selectedTab);
    navigate(`${pathname}?${params.toString()}`);
  };

  const customFormik = formikHook({
    initialValues,
    onSubmit: (values) => {
      handleSerialize(values);
      onSubmit(values);
    },
    enableReinitialize: true,
  });

  const dataParam = searchParams.get('data');
  const decodedParams = dataParam ? decodeBase64(dataParam) : {};

  useComponentDidUpdate(() => {
    handleSerialize(customFormik.values);
  }, [page]);

  useEffect(() => {
    if (!isEmptyObj(decodedParams)) {
      setTimeout(() => {
        customFormik.setValues(decodedParams);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const checkCallParams = (call: () => void) => {
    if (isEmptyObj(decodedParams)) {
      call();
    }
  };

  useEffect(() => {
    if (decodedParams) {
      onSubmit(decodedParams, getPageFromUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { formik: customFormik, clearURL, checkCallParams };
};

export default useFormik;
