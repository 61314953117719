import React from 'react';
import { Box } from '@mui/material';

import { ReactComponent as PharmaPlanLogo } from '@pharmaplan/common/assets/icons/pharmaplanLogoNew.svg';
import PharmaGapLogo from '../../../assets/img/pharmagap.png';

import useSelfService from '../../../hooks/useSelfService';
import strings from '../../../localization';
import classes from '../style';

const NavbarLogo = () => {
  const { isSelfService } = useSelfService();

  return isSelfService ? (
    <Box
      component="img"
      src={PharmaGapLogo}
      alt={strings.pharmaplan}
      style={classes.logo}
    />
  ) : (
    <PharmaPlanLogo />
  );
};

export default NavbarLogo;
