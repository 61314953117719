import { SxProps, Theme } from '@mui/system';

const styles = {
  active: {
    backgroundColor: 'common.lightBackgroundColor',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
  },
  focusedStyle: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  editor: { overflow: 'auto' },
  toolbarContainer: {
    backgroundColor: 'inherit',
    borderRadius: 0,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
