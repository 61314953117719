import { useEffect } from 'react';

import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler';

import {
  setUnreadCount,
  userIdSelector,
  userUnreadCountSelector,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../useAppDispatch';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import audio from '../../../assets/audio/live_chat.mp3';
import { useAppSelector } from '../../useAppSelector';

const uniqueId = 'uniqueId';

const useUnreadChats = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(userIdSelector);
  const unreadCount = useAppSelector(userUnreadCountSelector);

  const store = useSendbirdStateContext();
  const sdk = store?.stores?.sdkStore?.sdk;
  const notificationSound = new Audio(audio);

  const retrieveUnreadCount = (onRetrieve?: (total: number) => void) => {
    sdk.groupChannel
      ?.getTotalUnreadMessageCount({
        channelCustomTypesFilter: [userId, ''],
      })
      .then((unread) => {
        onRetrieve?.(unread);
        dispatch(setUnreadCount(unread));
      })
      .catch((e) =>
        console.error(e));
  };

  useEffect(() => {
    const handler = new UserEventHandler({
      onTotalUnreadMessageCountChanged: () => {
        retrieveUnreadCount((total) => {
          if (total > unreadCount) {
            notificationSound.play();
          }
        });
      },
    });

    sdk?.addUserEventHandler?.(uniqueId, handler);

    return () => {
      sdk?.removeUserEventHandler?.(uniqueId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, unreadCount]);

  useEffect(() => {
    // INITIAL RETRIEVAL
    retrieveUnreadCount();
  }, [sdk]);
};

export default useUnreadChats;
