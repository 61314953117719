export const classes = {
  card: {
    maxWidth: '420px',
    padding: 6,
    borderRadius: '12px',
    width: '100%',
    marginTop: '50px',
  },

  fullHeight: {
    height: '100vh',
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  cardContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 3,
  },

  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  cardHeaderIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    height: '100px',
    width: '100px',
    backgroundColor: 'common.lightBackgroundColor',
  },

  textContainer: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  childrenContainer: { marginTop: 3 },

  infoHeading: {
    fontWeight: 700,
    fontSize: 36,
  },

  infoDesc: {
    fontWeight: 500,
    fontSize: 16,
  },
};
