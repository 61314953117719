import React, { FC } from 'react';

import Checkbox from '../../Checkbox';
import styles from './styles';

interface ICheckboxOption {
  label: string;
  selected: boolean;
}

const CheckboxOption: FC<ICheckboxOption> = (props) => {
  const { selected, label } = props ?? {};
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <li {...props} style={styles.container}>
      <Checkbox checked={selected} />
      {label}
    </li>
  );
};

export default CheckboxOption;
