import React, { FC } from 'react';

import { Box } from '@mui/system';
import { Avatar, Button, Typography } from '@mui/material';

import {
  AvailabilityTypes,
  getTypeOfUser,
  ICalendarEventType,
  PharmacistLegendVariant,
  profileImage,
  TypeOfUser,
} from '@pharmaplan/common';
import { ReactComponent as ClockIcon } from '../../../../../assets/svg/clockIcon.svg';

import { getMultipleEventAdd } from '../../../../../selectors/calendarSelector';
import useDetailsNavigation from '../../../../../hooks/Admin/useDetailsNavigation';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { IDurations } from '../../../../../types/IDurations';
import useGetItems from '../../../../../hooks/useGetItems';
import Watchlisted from '../../../../common/Watchlist/Watchlisted';
import WavingHand from '../../../../common/WavingHand';
import useAdmin from '../../../../../hooks/useAdmin';

import styles from './style';

interface ICalendarTooltipContent {
  eventType: ICalendarEventType;
  onClick: (inId: string) => void;
  durations: Array<IDurations>;
  date?: string;
}

const CalendarTooltipContent: FC<ICalendarTooltipContent> = ({
  eventType,
  durations,
  date,
  onClick,
}) => {
  const { getImage } = useGetItems();
  const { handleNavigation } = useDetailsNavigation();
  const { isAdmin } = useAdmin();

  const userType = useAppSelector(getTypeOfUser);
  const avatar = useAppSelector(profileImage);

  const isPharmacy = userType === TypeOfUser.pharmacy;
  const isAvailability = eventType === PharmacistLegendVariant.availability;
  const isVacation = eventType === PharmacistLegendVariant.vacation;

  const isTempAvailability = (eventType as unknown) === AvailabilityTypes.tempAvailability;
  const isNewVacation = (eventType as unknown) === AvailabilityTypes.newVacation;

  const isClickable = !(
    isAvailability
    || isTempAvailability
    || isVacation
    || isNewVacation
  );

  const isMultipleAdd = useAppSelector(getMultipleEventAdd);

  const tooltipClick = (id: string, workshiftId: string, pharmacyId: string) =>
    () => {
      if (isClickable) {
        if (isAdmin) {
          handleNavigation({ id, pharmacyId, eventType, date, workshiftId })();
        } else {
          onClick(id);
        }
      }
    };

  return (
    <Box sx={styles.container}>
      {durations.map((item, index) => {
        const {
          key,
          label,
          logoId,
          id,
          workshiftId,
          isMatchingAvailabilityExist,
          pharmacyId,
          bookingId,
          isInWatchlist,
        } = item ?? {};

        const notLastElement = index !== durations.length - 1;
        const image = getImage(logoId ?? '');

        return (
          <Box
            key={key}
            component={isClickable ? Button : 'div'}
            onClick={tooltipClick(
              isMultipleAdd ? bookingId : id,
              workshiftId,
              pharmacyId,
            )}
            sx={[
              styles.infoContainer,
              notLastElement && styles.infoBorder(eventType),
            ]}
          >
            {isClickable ? (
              <Avatar
                src={isPharmacy ? avatar : image}
                sx={styles.imageContainer}
                imgProps={{ style: styles.image }}
              />
            ) : (
              <ClockIcon />
            )}
            {isMatchingAvailabilityExist && <WavingHand />}
            {isInWatchlist && <Watchlisted inCalendar />}
            <Typography sx={styles.infoFont}>{label}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
export default CalendarTooltipContent;
