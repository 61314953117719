import React, { FC } from 'react';

import { Box } from '@mui/material';

import { getLanguages, pharmacyDetails } from '@pharmaplan/common';

import useSyncLocationFormik from '../../../../../hooks/Admin/Profile/useSyncLocationFormik';
import usePharmacyEditProfile from '../../../../../hooks/Admin/Profile/usePharmacyEditProfile';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { IFromScreen } from '../../../../../actions/drawerActions';
import { OtherScreens } from '../../../../../helpers/Constants';
import EditFormGenerator from '../../EditFormGenerator';

import { pharmacyEditConfig } from './helper';

interface IPharmacyEditProfile {
  fromScreen?: IFromScreen;
  hasOwner?: boolean;
  back?: () => void;
}

const { DeletedPendingUsers, DeletedPharmacyList, AppointedPharmacies } = OtherScreens;

const PharmacyEditProfile: FC<IPharmacyEditProfile> = ({
  fromScreen,
  hasOwner,
  back,
}) => {
  const languages = useAppSelector(getLanguages);
  const details = useAppSelector(pharmacyDetails);

  const { pharmacy, softwares, provinces, contactPreferences } = details ?? {};
  const { latitude, longitude } = pharmacy ?? {};
  const { formik } = usePharmacyEditProfile(fromScreen, hasOwner, back);

  const isDeletedPending = fromScreen === DeletedPendingUsers;
  const isDeletedPharmacyList = fromScreen === DeletedPharmacyList;

  const preventChangeEmail = fromScreen === AppointedPharmacies
  || isDeletedPending
  || isDeletedPharmacyList;

  useSyncLocationFormik({ formik, latitude, longitude });

  return (
    <Box>
      {pharmacyEditConfig({
        languages,
        softwares,
        provinces,
        contactPreferences,
        preventChangeEmail,
      }).map((items) => {
        const { form, title, key } = items ?? {};

        return (
          <EditFormGenerator
            key={key}
            formik={formik}
            title={title}
            form={form}
          />
        );
      })}
    </Box>
  );
};

export default PharmacyEditProfile;
