import { IAdminShowAvailabilitiesData, TimeFormat } from '@pharmaplan/common';
import { DynamicTableCell } from '../../../helpers/Constants';
import { timeString } from '../../../helpers/Functions';
import strings from '../../../localization';
import { IMenuActions } from '../../common/CustomAvatarSection/CustomAvatarSection';
import { getDayMonthDateYear } from '../../Reports/helpers';

const { text, customMenu } = DynamicTableCell;
export const showAvailabilitiesRowConfig = (
  availabilities: Array<IAdminShowAvailabilitiesData>,
  timeFormat: TimeFormat,
  menuActions?: (availabilityId: string, code: string) => Array<IMenuActions>,
) =>
  availabilities?.map((item) => {
    const { code, startDate, endDate, availableTimeId } = item ?? {};
    const date = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);
    return {
      key: availableTimeId,
      data: [
        {
          key: `1-${availableTimeId}`,
          value: code,
          type: text,
        },
        {
          key: `2-${availableTimeId}`,
          value: date,
          type: text,
        },
        {
          key: `3-${availableTimeId}`,
          value: time,
          type: text,
        },
        {
          key: `4-${availableTimeId}`,
          value: {
            customMenuActions: menuActions?.(availableTimeId, code),
          },
          type: customMenu,
        },
      ],
    };
  });

export const showAvailabilitiesHeader = () =>
  [
    { key: '1', label: strings.code },
    { key: '2', label: strings.date },
    { key: '3', label: strings.time },
  ];
