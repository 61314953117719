import { useEffect } from 'react';

import {
  adminActions,
  getAdminConfiguration,
  getSalesforceAdmins,
  resetStatus,
  successOrSelector,
  successSelector,
} from '@pharmaplan/common';

import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../localization';
import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';

const {
  createSalesforceAdmins,
  deleteSalesforceAdmins,
  editSalesforceAdmins,
  editAdminConfiguration,
} = adminActions;

const useAdminConfigurationRefresh = () => {
  const actions = [editSalesforceAdmins, editAdminConfiguration];

  const dispatch = useAppDispatch();

  const success = useAppSelector((state) =>
    successOrSelector(actions, state));

  const deleteSuccess = useAppSelector((state) =>
    successSelector([deleteSalesforceAdmins], state));

  const createSuccess = useAppSelector((state) =>
    successSelector([createSalesforceAdmins], state));

  const handleSuccess = () => {
    switch (true) {
      case success:
        showSuccess(dispatch, strings.detailsSaved);
        break;
      case createSuccess:
        showSuccess(dispatch, strings.sfAdminCreatedSuccessfully);
        break;
      case deleteSuccess:
        showSuccess(dispatch, strings.sfAdminDeletedSuccessfully);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getAdminConfiguration());
    dispatch(getSalesforceAdmins());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success || createSuccess || deleteSuccess) {
      dispatch(
        resetStatus([
          createSalesforceAdmins,
          deleteSalesforceAdmins,
          ...actions,
        ]),
      );
      dispatch(getSalesforceAdmins());
      dispatch(getAdminConfiguration());
    }
    handleSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, createSuccess, deleteSuccess]);
};

export default useAdminConfigurationRefresh;
