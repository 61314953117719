import React from 'react';

import LoginLayout from '../LoginLayout/LoginLayout';
import CookiePrompt from '../CookiePrompt';

import LoginHeader from './LoginHeader';
import LoginForm from './LoginForm/LoginForm';

const Login = () =>
  (
    <>
      <LoginLayout Header={LoginHeader} Form={LoginForm} />
      <CookiePrompt />
    </>
  );

export default Login;
