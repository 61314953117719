import { useEffect, useRef } from 'react';

import {
  allDayEnd,
  allDayStart,
  createAvailability,
  IVacationsParams,
  pharmacistActions,
  preSubmitAvailabilities,
  preSubmitVacations,
  reset,
  resetStatus,
  SelectionType,
  successSelector,
} from '@pharmaplan/common';

import { createMultipleDate } from '../helpers/Functions';

import useVacationsCriteria from './CreateVacations/useVacationsCriteria';
import useAvailabilitiesCriteria from './useAvailabilitiesCriteria';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { resetDrawer } from '../actions/drawerActions';
import { showSuccess } from '../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../localization';
import useSaveAndRemain from './useSaveAndCreateAnother';

const { preSubmitVacations: preSubmitAction } = pharmacistActions;

const useAvailabilityVacation = (
  dates: Array<string>,
  showMultiple: boolean,
) => {
  const dispatch = useAppDispatch();
  const apiParams = useRef<null | IVacationsParams>();
  const { createVacations } = useVacationsCriteria(SelectionType.multiple);
  const selectedDateList = dates.map((item) =>
    `${item.slice(0, -4)}Z`);

  const { saveAndRemain, handleSetSave, resetSaveAndRemain, refreshCalendar } = useSaveAndRemain();

  const success = useAppSelector((st) =>
    successSelector([preSubmitAction], st));

  const availabilitySuccess = useAppSelector((state) =>
    successSelector([pharmacistActions.createAvailability], state));

  const handleAvailabilitiesSave = () => {
    const tempAvailabilities = [...dates];

    tempAvailabilities.sort((a, b) =>
      a.localeCompare(b));

    dispatch(
      preSubmitAvailabilities({
        startDate: createMultipleDate(tempAvailabilities[0], allDayStart),
        endDate: createMultipleDate(
          tempAvailabilities[tempAvailabilities.length - 1],
          allDayEnd,
        ),
        recurrenceType: 0,
        selectedDates: selectedDateList,
      }),
    );
  };

  const handleReset = () => {
    dispatch(reset());

    if (saveAndRemain) {
      showSuccess(dispatch, strings.availabilityCreatedSuccessfully);
      resetSaveAndRemain();
      return;
    }

    dispatch(resetDrawer());
  };

  useEffect(() => {
    if (availabilitySuccess) {
      handleReset();
      refreshCalendar();
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilitySuccess]);

  const dispatchCreateAvailability = () => {
    const tempAvailabilities = [...dates];

    tempAvailabilities.sort((a, b) =>
      a.localeCompare(b));

    dispatch(
      createAvailability({
        startDate: createMultipleDate(tempAvailabilities[0], allDayStart),
        endDate: createMultipleDate(
          tempAvailabilities[tempAvailabilities.length - 1],
          allDayEnd,
        ),
        recurrenceType: 0,
        selectedDates: selectedDateList,
      }),
    );
  };

  const handleVacationsSave = () => {
    const vacationsCopy = [...dates];

    vacationsCopy.sort((a, b) =>
      a.localeCompare(b));

    const params = {
      startDate: createMultipleDate(vacationsCopy[0], allDayStart),
      endDate: createMultipleDate(
        vacationsCopy[vacationsCopy.length - 1],
        allDayEnd,
      ),
      selectedDates: selectedDateList,
    };

    apiParams.current = params;

    dispatch(preSubmitVacations(params));
  };

  useAvailabilitiesCriteria(dispatchCreateAvailability, showMultiple);

  useEffect(() => {
    if (success && apiParams.current) {
      createVacations(apiParams.current);
      apiParams.current = null;
      dispatch(resetStatus([preSubmitAction]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, apiParams.current]);

  return {
    handleAvailabilitiesSave,
    handleVacationsSave,
    handleSetSave,
    saveAndRemain,
  };
};

export default useAvailabilityVacation;
