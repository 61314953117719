import { IPostedShifts, TimeFormat } from '@pharmaplan/common';
import { DynamicTableCell } from '../../../../helpers/Constants';
import strings from '../../../../localization';
import { store } from '../../../../store';
import { getDayMonthDateYear, getHourMinute } from '../../../Reports/helpers';

export const headers = () =>
  [

    { key: '0', label: strings.time },
    { key: '1', label: strings.date },
    { key: '2', label: strings.action },
  ];

export const rowsMapper = (data:Array<IPostedShifts>, timeFormat: TimeFormat, dispatch:typeof store.dispatch, buttonConfig:(id:string)=>void) =>
  data?.map((item) =>
    ({
      key: item.workShiftId,
      data: [

        {
          key: `${item.workShiftId}-2`,
          value: getDayMonthDateYear(item.selectedDate),
          type: DynamicTableCell.text,
        },
        {
          key: `${item.workShiftId}-3`,
          value: `${getHourMinute(item.startDate, timeFormat)} - ${getHourMinute(item.endDate, timeFormat)}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${item.workShiftId}-5`,
          value: buttonConfig(item.workShiftId),
          show: true,
          type: DynamicTableCell.button,
        },
      ] }));
