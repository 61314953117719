import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { FormikProps } from 'formik';
import styles from './styles';
import CustomDropdown from '../../CustomDropdown';
import { getLastNYears } from '../../../../helpers/Functions';
import { quarters } from '../../../../helpers/Constants';

interface IChartLayout {
  title: string;
  children: React.ReactNode;
  formik?: FormikProps<any>;
}

const ChartLayout: FC<IChartLayout> = ({ title, children, formik }) =>
  (
    <>
      <Box sx={styles.container}>
        <Typography sx={styles.title}>{title}</Typography>
        {formik && (
        <Box sx={styles.filterContainer}>
          <CustomDropdown
            menuItems={getLastNYears(3)}
            name="years"
            formik={formik}
          />
          <CustomDropdown
            menuItems={quarters}
            name="quarter"
            formik={formik}
          />
        </Box>
        )}
      </Box>
      <Grid wrap="nowrap" columnGap="26px" container>
        {children}
      </Grid>
    </>
  );

export default ChartLayout;
