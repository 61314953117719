import React, { FC, useEffect, useMemo } from 'react';

import { useFormik } from 'formik';
import { Box, Typography } from '@mui/material';

import {
  adminGetPharmacyAdminPicture,
  adminGetPharmacyAdminProfile,
  adminPharmacyAdminPicture,
  adminPharmacyAdminProfile,
  adminResetValue,
  getLanguages,
} from '@pharmaplan/common';

import { useLocation } from 'react-router-dom';
import usePharmacyAdminActionsSuccess from '../../../../hooks/Admin/PharmacyAdministrators/usePharmacyAdminActionSuccess';
import useHandleEditAppoint from '../../../../hooks/Admin/PharmacyAdministrators/useHandleEditAppoint';
import CreatePharmacyAdminItem from './CreateEditPharmacyAdminHeader/CreatePharmacyAdminItem';
import useBidirectionalTransferList from '../../../../hooks/useBidirectionalTransferList';
import UploadPhoto from '../../Profile/Pharmacist/PharmacistViewProfile/UploadPhoto';
import PharmacyTransferList from './PharmacyTransferList/PharmacyTransferList';
import { PharmacyAdminActionTypes } from '../../../../helpers/Constants';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { setDialog } from '../../../../reducers/dialogReducer';
import CustomTableBack from '../../../common/CustomTableBack';
import CustomButton from '../../../common/CustomButton';
import strings from '../../../../localization';

import validator from './validator';
import { config } from './helper';
import styles from './styles';

interface ICreateEditPharmacyAdministrator {
  type: PharmacyAdminActionTypes;
}

const CreateEditPharmacyAdministrator: FC<ICreateEditPharmacyAdministrator> = ({
  type,
}) => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { id } = state ?? {};

  const fromAppoint = type === PharmacyAdminActionTypes.appoint;

  const pharmacyAdminParams = useAppSelector(adminPharmacyAdminProfile);
  const allLanguages = useAppSelector(getLanguages);
  const pharmacyAdminDetails = useAppSelector(adminPharmacyAdminPicture);
  const { name } = pharmacyAdminDetails ?? {};

  const {
    initialValues,
    selectedPharmacies,
    title,
    handleSubmit,
    isAppoint,
    isEdit,
    data,
  } = useHandleEditAppoint({ type });
  usePharmacyAdminActionsSuccess();
  const { provinces } = pharmacyAdminParams ?? {};

  const dataParams = useMemo(
    () =>
      ({
        rightInput: {
          data: selectedPharmacies,
          idKey: 'id',
          nameKey: 'userName',
          emailKey: 'email',
        },
        leftInput: {
          data,
          idKey: 'pharmacyId',
          nameKey: 'name',
          emailKey: 'email',
        },
      }),
    [data, selectedPharmacies],
  );

  const { list, setList, right, left } = useBidirectionalTransferList(
    dataParams as any,
  );

  const pharmacyIds = list.right.map((item) =>
    item.key);

  const showPictureUploadModal = () => {
    dispatch(
      setDialog({
        maxWidth: 'xs',
        showCloseButton: true,
        Component: <UploadPhoto id={id} isPharmacyAdmin />,
        heading: {
          title: strings.uploadPicture,
        },
      }),
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) =>
      handleSubmit(values, pharmacyIds),
    enableReinitialize: true,
    validationSchema: validator,
  });

  useEffect(() => {
    dispatch(adminGetPharmacyAdminProfile());
    if (id) {
      dispatch(adminGetPharmacyAdminPicture(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(
    () =>
      () => {
        dispatch(adminResetValue(['pharmacyAdminDetails']));
      },
    [],
  );

  return (
    <Box sx={styles.container}>
      <Box sx={styles.backContainer}>
        <CustomTableBack
          backLabel={
            fromAppoint
              ? strings.backToAppointPharmacy
              : strings.backToPharmacyAdmin
          }
        />
      </Box>
      <Typography sx={styles.title}>{title}</Typography>
      {config(
        provinces,
        allLanguages,
        isAppoint,
        showPictureUploadModal,
        isEdit,
        name,
      ).map((item) => {
        const { key, title: configTitle, form } = item;
        return (
          <Box key={key} sx={styles.itemContainer}>
            <CreatePharmacyAdminItem
              title={configTitle}
              form={form}
              formik={formik}
            />
          </Box>
        );
      })}
      <PharmacyTransferList
        right={right}
        list={list}
        setList={setList}
        left={left}
      />
      <Box sx={styles.buttonContainer}>
        <CustomButton
          label={strings.save}
          onClick={formik.handleSubmit}
          customClass={styles.buttonSize}
        />
      </Box>
    </Box>
  );
};

export default CreateEditPharmacyAdministrator;
