import React, { useEffect } from 'react';

import { Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import {
  getIsUserApproved,
  resetApproval,
  resetStatus,
  setSignedUp,
  successSelector,
  userActions,
} from '@pharmaplan/common';
import { useAppSelector } from '../hooks/useAppSelector';
import CustomDialog from '../components/common/CustomModal/CustomDialog';
import { setDialog } from '../reducers/dialogReducer';
import { useAppDispatch } from '../hooks/useAppDispatch';
import ApprovalModal from '../components/Modals/ApprovalModal';

import theme from '../theme';
import LoginRouter from './LoginRouter';
import AdminRouter from './AdminRouter';
import PharmaRouter from './PharmaRouter';
import PharmacyAdminRouter from './PharmacyAdminRouter';

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const isApproved = useAppSelector(getIsUserApproved);
  const isSignupSuccess = useAppSelector((state) =>
    successSelector([userActions.createProfile], state));

  const showApprovalPending = () => {
    dispatch(
      setDialog({
        showCloseButton: false,
        Component: <ApprovalModal showCloseButton />,
        heading: {
          title: '',
        },
      }),
    );
  };

  useEffect(() => {
    if (isSignupSuccess || !isApproved) {
      showApprovalPending();
      dispatch(setSignedUp(false));
      dispatch(resetApproval());
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignupSuccess, isApproved]);

  return (
    <ThemeProvider theme={theme}>
      <CustomDialog />
      <Routes>
        {/* PRIVATE ROUTES */}
        {PharmaRouter()}
        {AdminRouter()}
        {PharmacyAdminRouter()}

        {/* PUBLIC ROUTES */}
        {LoginRouter()}
      </Routes>
    </ThemeProvider>
  );
};

export default AppRouter;
