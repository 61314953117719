import { PerformerTypes, TypeOfUser } from '@pharmaplan/common';
import {
  ICustomBack,
  IFromScreen,
  renderScreen,
} from '../../actions/drawerActions';
import { ScreenTypes } from '../../helpers/Constants';
import { useAppDispatch } from '../useAppDispatch';
import useDrawer from '../useDrawer';
import { showError } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../localization';

export type IUserProfileLinkParams = {
  personnelId: string;
  userType: TypeOfUser;
  fromScreen?: IFromScreen | null;
  isUserActive?: boolean;
};

export type IPerformerProfileLinkParams = {
  personnelId: string;
  fromScreen?: IFromScreen;
  performerType: PerformerTypes;
  isUserActive?: boolean;
};

export type IUserProfileLinkSignature = (
  params: IUserProfileLinkParams
) => () => void;

export type IPerformerProfileLinkSignature = (
  params: IPerformerProfileLinkParams
) => () => void;

const useUserProfileLink = (back?: ICustomBack) => {
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawer();

  const goToUserProfile = ({
    personnelId,
    userType,
    fromScreen,
    isUserActive = true,
  }: IUserProfileLinkParams) =>
    () => {
      const screenNumber = userType === TypeOfUser.pharmacy ? 1 : 2;
      if (isUserActive) {
        openDrawer();
        dispatch(
          renderScreen({
            screenType: ScreenTypes.profile,
            screenNumber,
            pharmacistId: personnelId,
            pharmacyId: personnelId,
            fromScreen,
            back,
          }),
        );
      } else {
        showError(dispatch, strings.thisUserIsNotActive);
      }
    };

  const goToPerformerProfile = ({
    personnelId,
    performerType,
    fromScreen,
    isUserActive = true,
  }: IPerformerProfileLinkParams) =>
    () => {
      const isPharmacy = performerType === PerformerTypes.pharmacy;
      const isPharmacist = performerType === PerformerTypes.pharmacist;

      const canView = isPharmacy || isPharmacist;
      const screenNumber = isPharmacy ? 1 : 2;

      if (canView && isUserActive) {
        openDrawer();
        dispatch(
          renderScreen({
            screenType: ScreenTypes.profile,
            screenNumber,
            pharmacistId: personnelId,
            pharmacyId: personnelId,
            fromScreen,
            back,
          }),
        );
      } else {
        showError(dispatch, strings.thisUserIsNotActive);
      }
    };
  return { goToUserProfile, goToPerformerProfile };
};

export default useUserProfileLink;
