import { SxProps, Theme } from '@mui/system';

const styles = {
  borderColor: {
    borderColor: 'common.border',
  },
  zeroPadding: {
    padding: 0,
  },
  container: {
    border: '1px solid',
    borderRadius: '8px',
    height: '100%',
  },
  listContainer: {
    overflow: 'auto',
    minHeight: '345px',
    maxHeight: '345px',
  },
  headerContainer: {
    padding: '20px 16px',
    borderBottom: '1px solid',
  },
  commonFont: {
    fontSize: '14px',
    lineHeight: '22.4px',
  },
  title: {
    fontWeight: '600',
    color: 'common.grey850',
  },
  description: {
    color: 'common.gray',
    fontSize: '12px',
    lineHeight: '16.8px',
    fontWeight: '400',
  },
  itemContainer: {
    display: 'flex',
    padding: '12px 24px',
    maxHeight: 55,
    borderBottom: '1px solid',
    borderColor: 'common.lightBackgroundColor',
  },
  itemLabel: {
    fontWeight: '400',
    flex: 1,
    color: 'grey.800',
  },
  email: {
    fontSize: 12,
    color: 'grey.500',
    marginLeft: '2px',
  },
  selectedStyle: {
    backgroundColor: 'common.lightGreen',
    borderStyle: 'solid',
    borderWidth: '0.5px 0 0.5px 0',
    borderColor: 'primary.main',
  },
  removedStyle: {
    backgroundColor: 'common.hoverGray',
    borderStyle: 'solid',
    borderWidth: '0.5px 0 0.5px 0',
    borderColor: 'common.gray150',
  },
  itemWidth: {
    minWidth: 0,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
