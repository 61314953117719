import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    boxShadow: '0px 6px 20px 0px #D4D4D480',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    paddingX: '95px',
    pb: '20px',
    mx: '111px',
    mt: '61px',
  },
  title: {
    color: 'primary.main',
    mb: '8px',
    mt: '19px',
    textAlign: 'left',
    fontSize: 22,
    fontWeight: '600',
  },
  description: {
    fontWeight: '500',
    fontSize: 16,
    mb: '40px',
    color: 'common.gray',
  },
  subTitle: {
    fontSize: 20,
    mb: '19px',
    fontWeight: '500',
    color: 'common.grey850',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
