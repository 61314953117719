import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const styles = {
  mainContainer: {
    mb: '6px',
  },
  container: {
    mb: '24px',
  },
  subContainer: {
    position: 'relative',
  },
  footerContainer: {
    mb: '12px',
    mt: '2px',
  },
  footer: {
    fontSize: 12,
    color: ThemeConstants.common.grey850,
  },
  menuContainer: {
    position: 'absolute',
    top: 25,
    right: 16,
  },
  button: {
    padding: 0,
    height: 24,
  },
  menuText: {
    fontSize: 12,
  },
  footerBox: {
    borderBottom: '1px solid',
    borderColor: 'common.border',
    mb: '15px',
    pb: '12px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
