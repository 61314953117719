import React from 'react';
import strings from '../../../../localization';
import ServiceCard from '../../ServiceCard/ServiceCard';
import BookingRequestsContent from './BookingRequestsContent';

export interface IBookingRequests {
  profileImage: Array<string | null>;
  onClick?: () => void;
  requestedCount?: number;
}

const BookingRequests: React.FC<IBookingRequests> = ({
  profileImage,
  onClick,
  requestedCount = 0,
}) =>
  (
    <ServiceCard
      title={strings.bookingRequests}
      content={(
        <BookingRequestsContent
          profileImage={profileImage}
          requestedCount={requestedCount}
          onClick={onClick}
        />
    )}
    />
  );

export default BookingRequests;
