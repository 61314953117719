import { SxProps, Theme } from '@mui/system';

const styles = {

  container: {
    padding: '8px 24px 24px 24px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
