import { SxProps, Theme } from '@mui/system';

const styles = {
  mapButton: {
    justifyContent: 'start',
    textTransform: 'none',
    width: '97.5%',
    backgroundColor: 'secondary.main',

    '& .MuiButton-endIcon': {
      marginLeft: 'auto',
    },
  },
  mapButtonHelperTextStyle: {
    mb: '20px',
    color: '#d32f2f',
    fontSize: '0.75rem',
    marginTop: '3px',
    marginLeft: '14px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
