import React, { FC } from 'react';

import { Box, Typography, IconButton, Tooltip } from '@mui/material';

import {
  IBroadcastGroupData,
  PerformerTypes,
  adminActions,
  getSelectedIds,
} from '@pharmaplan/common';

import { ReactComponent as PhoneIcon } from '../../../../../assets/svg/phoneIconOutline.svg';
import { ReactComponent as EmailIcon } from '../../../../../assets/svg/emailIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/svg/delete.svg';
import { ReactComponent as DisabledDeleteIcon } from '../../../../../assets/svg/disableDelete.svg';

import { handleValue } from '../../../Profile/Pharmacy/PharmacyViewProfile/helper';
import { SkeletonVariants, performerTypeMapper } from '../../../../../helpers/Constants';
import useGetItems from '../../../../../hooks/useGetItems';
import OptimizedAvatar from '../../../../common/OptimizedAvatar/OptimizedAvatar';
import WithSkeleton from '../../../../common/WithSkeleton';
import strings from '../../../../../localization';

import { useAppSelector } from '../../../../../hooks/useAppSelector';
import styles from './style';

interface IBroadcastGroupCard {
  data: IBroadcastGroupData;
  enableDelete: boolean;
  handleDelete: (id: string) => void;
}

const successAction = adminActions.getBroadcastGroupSelectedUsers;
const pharmacistImageSuccessAction = adminActions.adminPharmacistPictures;
interface InfoConfigItem {
  key: number;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  info: string | number;
}

const BroadcastGroupCard: FC<IBroadcastGroupCard> = ({
  data,
  enableDelete,
  handleDelete,
}) => {
  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const SkeletonBox = WithSkeleton({
    successAction,
    variant: SkeletonVariants.rounded,
  })(Typography);

  const selectedIds = useAppSelector(getSelectedIds);

  const { id, email, mobile, userType, userName, logoId, userId, communicationEmail } = data ?? {};
  const { getImage, getPharmacistImage } = useGetItems();

  const isPharmacy = userType === PerformerTypes.pharmacy;
  const isPharmacist = userType === PerformerTypes.pharmacist;

  const imgProps = isPharmacy ? styles.avatarContain : styles.avatarCover;
  const isDisabled = selectedIds.length === 0 || selectedIds.length === 1;

  const imageSuccessAction = isPharmacist
    ? pharmacistImageSuccessAction
    : successAction;

  const handleAvatar = () => {
    let src;

    if (isPharmacy) {
      src = getImage(logoId);
      return src;
    }

    src = getPharmacistImage(id);
    return src;
  };

  const handleClick = () => {
    handleDelete(userId);
  };

  const infoConfig = [
    {
      key: 0,
      Icon: EmailIcon,
      info: handleValue(email),
    },
    communicationEmail && {
      key: 1,
      Icon: EmailIcon,
      info: handleValue(communicationEmail),
    },
    {
      key: 2,
      Icon: PhoneIcon,
      info: handleValue(mobile),
    },
  ].filter(Boolean) as InfoConfigItem[];

  // const infoConfig = [
  //   {
  //     key: 0,
  //     Icon: EmailIcon,
  //     info: handleValue(email),
  //   },
  //   ...(communicationEmail ? [{
  //     key: 1,
  //     Icon: EmailIcon,
  //     info: handleValue(communicationEmail),
  //   }] : []),
  //   {
  //     key: 2,
  //     Icon: PhoneIcon,
  //     info: handleValue(mobile),
  //   },
  // ];

  return (
    <Box sx={styles.root}>
      <OptimizedAvatar
        successAction={imageSuccessAction}
        customImgProps={imgProps}
        avatarSize={56}
        src={handleAvatar()}
        showFullImage={!!isPharmacist}
        noSpacing
      />

      <Box flex={1}>
        <Box sx={styles.container}>
          <SkeletonTypography sx={styles.pharmacy}>
            {userName}
          </SkeletonTypography>
          <SkeletonTypography sx={styles.userType}>
            {performerTypeMapper[userType]}
          </SkeletonTypography>
        </Box>

        {infoConfig.map((item, index) => {
          const { key, info, Icon } = item ?? {};
          const isLastElement = index === infoConfig.length - 1;

          return (
            <Box key={key} sx={[styles.infoContainer]}>
              <Box sx={styles.subContainer}>
                <SkeletonBox sx={styles.iconMargin}>
                  <Icon />
                </SkeletonBox>
                <SkeletonTypography sx={styles.infoText}>
                  {info}
                </SkeletonTypography>
              </Box>

              {isLastElement && enableDelete && (
                <SkeletonBox>
                  <Tooltip
                    title={strings.deleteFromGroup}
                    placement="right-start"
                    arrow
                  >
                    <IconButton
                      disableRipple
                      disabled={isDisabled}
                      onClick={handleClick}
                    >
                      {isDisabled ? <DisabledDeleteIcon /> : <DeleteIcon />}
                    </IconButton>
                  </Tooltip>
                </SkeletonBox>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default BroadcastGroupCard;
