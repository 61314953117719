import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { KeyboardArrowDown } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevroRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';

import { activeDate, setActiveDate, updateMapDate } from '@pharmaplan/common';
import CustomButton from '../../../common/CustomButton';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { MomentTimeFormats } from '../../../../helpers/Constants';

import FormattedDate from '../FormattedDate';

import strings from '../../../../localization';
import useStyles, { style } from './style';
import './styles.scss';

interface IDayCalendar {
  setFiltered: Dispatch<
    SetStateAction<{
      submitted: boolean;
      showFilter: boolean;
    }>
  >;
}

const DayCalendar = ({ setFiltered }: IDayCalendar, ref: any) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [popover, setPopover] = useState(false);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const calendarRef = useRef<HTMLDivElement>(null);

  const activeDateSelector = useAppSelector(activeDate);

  const isDisabled = value[0] === null;

  const formatAndSetHourForDate = (date: Date) =>
    DateTime.fromJSDate(date)
      .set({ hour: 20 })
      .toFormat(MomentTimeFormats.fullDate);

  const setStartDate = value[0] ? formatAndSetHourForDate(value[0]) : null;
  const setEndDate = value[1] ? formatAndSetHourForDate(value[1]) : null;

  const getStartOfMonthISODate = () =>
    DateTime.fromISO(activeDateSelector).startOf('month').toISO();

  const getEndOfMonthISODate = () =>
    DateTime.fromISO(activeDateSelector).endOf('month').toISO();

  const handleSubmit = () => {
    setFiltered((prev) =>
      ({ ...prev, submitted: true }));
    dispatch(
      updateMapDate({
        startDate: setStartDate,
        endDate: setEndDate,
      }),
    );
    setPopover(false);
  };

  const resetAll = () => {
    setValue([null, null]);
    setFiltered((prev) =>
      ({ ...prev, submitted: false }));
    dispatch(
      updateMapDate({
        startDate: getStartOfMonthISODate(),
        endDate: getEndOfMonthISODate(),
      }),
    );
  };
  const handleClear = () => {
    resetAll();
    setPopover(false);
  };

  useClickOutside(calendarRef, () =>
    setPopover(false));

  const onMonthChange = ({
    action,
    activeStartDate,
  }: {
    action: string;
    activeStartDate: Date;
  }) => {
    switch (action) {
      case 'prev':
      case 'next':
      case 'drillDown':
        dispatch(
          setActiveDate(
            DateTime.fromJSDate(activeStartDate).startOf('month').toISO() ?? '',
          ),
        );
        break;
      default:
    }
  };

  const handleArrowDownClick = () => {
    setPopover(!popover);
  };

  const monthRender = (_: unknown, date: Date) =>
    DateTime.fromJSDate(date).toFormat('LLLL');

  useImperativeHandle(ref, () =>
    ({
      reset() {
        handleClear();
      },
    }));

  useEffect(() => {
    resetAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDateSelector]);

  return (
    <Box component="div" ref={calendarRef}>
      <Box display="flex" onClick={handleArrowDownClick}>
        <FormattedDate />
        <KeyboardArrowDown className={classes.downChevronIcon} />
      </Box>
      <Box
        component="div"
        className={clsx(
          classes.yearCalendarContainer,
          !popover && classes.yearCalendarHidden,
        )}
      >
        <Calendar
          onActiveStartDateChange={onMonthChange}
          minDetail="year"
          selectRange
          value={value}
          formatMonthYear={monthRender}
          defaultActiveStartDate={new Date(activeDateSelector)}
          className={classes.yearCalendar}
          prevLabel={<ChevronLeftIcon />}
          nextLabel={<ChevroRightIcon />}
          next2Label={null}
          prev2Label={null}
          goToRangeStartOnSelect={false}
          onChange={(dateRange: Array<Date>) =>
            setValue(dateRange as [Date, Date])}
        />

        <Box sx={style.buttonsContainer}>
          <CustomButton
            customButtonStyle={[style.button, style.clearButton]}
            variant="text"
            label={strings.clear}
            onClick={handleClear}
          />

          <CustomButton
            customButtonStyle={[style.button, style.submitButton]}
            label={strings.apply}
            disabled={isDisabled}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default forwardRef(DayCalendar);
