import { SxProps, Theme } from '@mui/system';

const classes = {
  titleContainer: {
    paddingLeft: '6px',
    marginBottom: '16px',
  },

  titleFont: {
    fontSize: 16,
    fontWeight: 600,
    color: 'primary.inputLabel',
  },

  dividerContainer: {
    marginX: '30px',
    marginTop: '12px',
    marginBottom: '32px',
    backgroundColor: 'common.border',
    color: 'common.border',
    height: '1px',
    borderRadius: '4px 4px 0px 0px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
