import React, { useEffect } from 'react';

import { useFormik } from 'formik';

import {
  getUserContact,
  updateUserContact,
  userAddress,
  userContact,
  userLocation,
  setAddress,
  setLocation,
  IContact,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ContactValidator } from '../../FormValidation';
import ProfileForm from '../../ProfileForm';
import { ICommonProfile } from '../General/General';

const Contacts = ({ setSelected }: ICommonProfile) => {
  const dispatch = useAppDispatch();
  const contactsInfo = useAppSelector(userContact);
  const location = useAppSelector(userLocation);
  const getUserAddress = useAppSelector(userAddress);

  const { longitude, latitude } = location ?? {};
  const { latitude: contactsInfoLatitude, longitude: contactsInfoLongitude } = contactsInfo ?? {};

  const handleSubmit = (values: IContact) => {
    const { address } = values ?? {};

    dispatch(
      updateUserContact({
        ...values,
        latitude,
        longitude,
        address,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      ...contactsInfo,
    },

    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: ContactValidator(),
  });

  useEffect(() => {
    dispatch(getUserContact());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getUserAddress.length > 0) {
      formik.setFieldValue('address', getUserAddress);
    }

    return () => {
      dispatch(setAddress(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserAddress]);

  useEffect(() => {
    dispatch(
      setLocation({
        latitude: contactsInfoLatitude,
        longitude: contactsInfoLongitude,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsInfoLatitude, contactsInfoLongitude]);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="contacts"
      formik={formik}
    />
  );
};

export default Contacts;
