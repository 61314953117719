import theme from '../../../theme';

const classes = {
  legendContainerMain: {
    padding: '16px',
  },
  legendContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },

  },
  legendText: {
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: '6px',
  },
};

export default classes;
