import React, { FC } from 'react';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';

import { ReactComponent as CrossMark } from '@pharmaplan/common/assets/icons/close.svg';

import ThemeConstants from '../../../theme/ThemeConstants';
import strings from '../../../localization';

import { styles } from './styles';

interface IFullScreenImage {
  image: string;
  hideImage: () => void;
  hideBackdrop?: boolean;
}

const FullScreenImage: FC<IFullScreenImage> = ({ image, hideImage, hideBackdrop = false }) =>
  (
    <Box sx={[hideBackdrop ? {} : styles.container, styles.displayContainer]}>
      <Box sx={[styles.imageContainer, styles.displayContainer]}>
        <Button
          variant="text"
          sx={[styles.crossContainer, styles.displayContainer]}
          onClick={hideImage}
          component="button"
        >
          <CrossMark
            height={10}
            width={10}
            fill={ThemeConstants.grey[400]}
            stroke={ThemeConstants.grey[400]}
          />
        </Button>
        <Box component="img" style={styles.image} src={image} alt={strings.fullScreen} />
      </Box>
    </Box>
  );

export default FullScreenImage;
