import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const styles = (avatarSize: number) =>
  ({
    root: {
      padding: '14px 16px',
      backgroundColor: ThemeConstants.secondary.main,
    },
    container: {
      display: 'flex',
    },
    subContainer: {
      flex: 1,
    },
    detailsContainer: {
      flex: 1,
      alignItems: 'flex-start',
    },
    imgStyle: {
      objectFit: 'contain',
    },
    avatar: {
      height: avatarSize,
      width: avatarSize,
      border: `2px solid ${ThemeConstants.common.border}`,
      mr: '15px',
      mt: '7px',
    },
    title: {
      fontSize: 16,
      fontWeight: '600',
      mb: '6px',
      color: ThemeConstants.common.grey850,
    },
    subtitles: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'common.grey850',
      my: '3px',
    },
    endContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    menuContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    button: {
      padding: 0,
      height: 24,
    },
    hourlyRateChip: {
      backgroundColor: ThemeConstants.primary.main,
      borderRadius: 42,
      padding: '4px 11px',
    },
    menuText: {
      fontSize: 12,
    },
    hourlyRate: {
      color: ThemeConstants.secondary.main,
    },
    footerDivider: {
      borderTop: '1px solid',
      borderColor: 'common.border',
      paddingTop: '12px',
      marginTop: '18px',
    },
    hoverlessButton: {
      textAlign: 'left',
      p: 0,
      m: 0,
      alignItems: 'flex-start',
      '&:hover': {
        backgroundColor: ThemeConstants.secondary.main,
      },
    },
  } satisfies Record<string, SxProps<Theme>>);

export default styles;
