export const classes = {

  infoHeading: {
    fontWeight: 700,
    fontSize: 36,
    textAlign: 'center',
  },

  infoDesc: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
  },
  passwordMargin: {
    marginBottom: 0.5,
  },
  cardStyle: {
    paddingTop: '20px',
  },
};
