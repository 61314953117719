import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  requested: {
    backgroundColor: 'eventChip.requested',
    color: ThemeConstants.eventChip.requestedText,
  },
  posted: {
    backgroundColor: 'eventChip.posted',
    color: ThemeConstants.eventChip.postedText,
  },
  booked: {
    backgroundColor: 'eventChip.booked',
    color: ThemeConstants.eventChip.bookedText,
  },
  hourlyRate: {
    backgroundColor: 'primary.main',
    color: 'secondary.main',
  },
  pharmacyHourlyRate: {
    backgroundColor: 'common.newBlue',
    color: 'secondary.main',
  },
  chip: {
    borderRadius: '29px',
    height: '20px',
  },
  hourlyRateChip: {
    borderRadius: '42px',
    height: '26px',
  },
};

export default classes;
