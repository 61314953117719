import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '5px',
    paddingTop: '5px',
  },

  pageCount: {
    color: 'grey.600',
    fontSize: 12,
    fontWeight: 600,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
