import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  continueText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'grey.500',
  },
  socialContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 2,
  },
  facebookIcon: {
    backgroundColor: 'common.lightBackgroundColor',
    display: 'flex',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
