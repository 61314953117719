import * as Yup from 'yup';
import { emailRegex } from '@pharmaplan/common';
import { nameRegex, phoneRegex } from '../../../../helpers/Constants';
import strings from '../../../../localization';

export const adminConfigurationValidator = () =>
  Yup.object().shape({
    representativeName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.alphaOnly),
    representativeEmail: Yup.string()
      .required(strings.requiredField)
      .matches(emailRegex, strings.emailValid),
    representativePhone: Yup.string()
      .required(strings.requiredField)
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`),
    ratePharmacistDays: Yup.number()
      .required(strings.requiredField)
      .typeError(strings.mustBeNumber),
    minHourWorkshift: Yup.number()
      .required(strings.requiredField)
      .typeError(strings.mustBeNumber),
    pharmacyRate: Yup.number()
      .required(strings.requiredField)
      .typeError(strings.mustBeNumber),
  });
