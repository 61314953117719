import { SxProps, Theme } from '@mui/system';

const styles = {
  root: {
    display: 'flex',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    gap: '16px',
  },

  avatar: {
    height: 56,
    width: 56,
  },

  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  pharmacy: {
    fontSize: 16,
    color: '#333',
    fontWeight: 600,
  },

  userType: {
    fontSize: 14,
    fontWeight: 500,
    color: '#96A0BC',
  },

  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  iconMargin: {
    marginRight: '8px',
  },

  infoText: {
    fontSize: 14,
    color: '#6E757C',
  },

  avatarContain: {
    objectFit: 'contain',
  },

  avatarCover: {
    objectFit: 'cover',
  },

  subContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
