import { SxProps, Theme } from '@mui/system';

const classes = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingX: '6px',
    paddingY: '2px',
    borderRadius: '2px',
    width: 'max-content',
  },

  bgPositive: {
    backgroundColor: 'common.lightGreen',
    color: 'primary.main',
  },

  bgNegative: {
    backgroundColor: 'primary.light',
    color: 'common.grey350',
  },

  value: {
    fontSize: 12,
    fontWeight: 500,
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
