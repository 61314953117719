import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  passwordMargin: {
    marginBottom: 0.5,
  },
  forgotPassContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
  },
  labelClass: { fontSize: '13px', fontWeight: '500' },
  forgotPass: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'grey.500',
  },
  customLabelContainer: {
    padding: '0 !important',
    paddingBottom: '10px!important',
    paddingTop: '10px!important',
  },
  customLoginButtonContainer:
    { marginTop: '30px' },
  welcomeBack: {
    mb: 3,
    pt: 5,
    fontWeight: '600',
    fontSize: 24,
    color: ThemeConstants.grey[800],
  },
};

export default classes;
