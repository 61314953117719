import React, { FC, useEffect, useMemo } from 'react';

import {
  adminCancellationStatistics,
  adminFinancialStatistics,
  getCancellationStatistics,
  getFinancialStatistics,
} from '@pharmaplan/common';

import { totalWorkshiftStatsKeys } from '../../../../helpers/Constants';
import ChartsContainer from '../../../common/Charts/ChartsContainer';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import ChartBuilder from '../../../common/Charts/ChartBuilder';
import { mergeDatasets } from '../../../../helpers/Functions';
import ChartLayout from '../../../common/Charts/ChartLayout';
import strings from '../../../../localization';

import { cancellationPieConfig, workshiftStatsConfig } from './helper';

interface IWorkshiftStatistics {
  start: string;
  end: string;
}

const WorkshiftStatistics: FC<IWorkshiftStatistics> = ({ start, end }) => {
  const dispatch = useAppDispatch();

  const financialStats = useAppSelector(adminFinancialStatistics);
  const { totalBooked, totalPosted, totalRequested, pharmacistPayout } = financialStats ?? {};

  const cancellationStats = useAppSelector(adminCancellationStatistics);

  const mergedFinancial = useMemo(
    () =>
      mergeDatasets(
        [totalBooked, totalPosted, totalRequested, pharmacistPayout],
        [
          totalWorkshiftStatsKeys.booked,
          totalWorkshiftStatsKeys.posted,
          totalWorkshiftStatsKeys.requested,
          totalWorkshiftStatsKeys.totalContractValue,
        ],
      ),
    [totalBooked, totalPosted, totalRequested, pharmacistPayout],
  );

  useEffect(() => {
    dispatch(
      getCancellationStatistics({
        startDate: start,
        endDate: end,
      }),
    );

    dispatch(
      getFinancialStatistics({
        startDate: start,
        endDate: end,
      }),
    );
  }, [start, end]);

  return (
    <ChartLayout title={strings.workshiftsStatistics}>
      <ChartsContainer title={strings.workshiftTypes}>
        <ChartBuilder config={workshiftStatsConfig(mergedFinancial)} />
      </ChartsContainer>
      <ChartsContainer title={strings.workshiftTypes}>
        <ChartBuilder config={cancellationPieConfig(cancellationStats)} />
      </ChartsContainer>
    </ChartLayout>
  );
};

export default WorkshiftStatistics;
