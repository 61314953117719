import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { ISoftwareFamilarity } from '../types';
import WithSkeleton from '../../../common/WithSkeleton';
import classes from './style';
import { Constants, SoftwareExperience } from '../../../../helpers/Constants';

const SoftwareFamilarity: FC<ISoftwareFamilarity> = ({
  successAction,
  softwareDetails,
}) => {
  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: Constants.variant.text,
  })(Typography);

  return (
    <>
      {softwareDetails.map((item) => {
        const { software, level } = item ?? {};
        const { softwareId, name } = software ?? {};

        return (
          <Box key={softwareId} sx={classes.container}>
            <Box sx={classes.labelContainer}>
              <SkeletonTypography sx={classes.labelFont}>
                {name}
              </SkeletonTypography>
            </Box>
            <Box sx={classes.valueContainer}>
              <SkeletonTypography sx={classes.valueFont}>
                {SoftwareExperience()[level - 1]}
              </SkeletonTypography>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default SoftwareFamilarity;
