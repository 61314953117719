import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const styles = {
  uploadButton: (isName?: string) =>
    ({
      mt: isName ? '6px' : '10px',
      ml: 1,
      maxWidth: '300px',
    }),
  container: {
    backgroundColor: 'secondary.main',
    margin: '16px 24px',
    px: '32px',
    pb: '35px',
  },
  title: {
    fontWeight: '600',
    fontSize: 22,
    color: 'common.grey850',
    mb: '23px',
  },
  publishContainer: {
    borderTop: '1px solid',
    borderColor: ThemeConstants.common.border,
    paddingTop: '24px',
    mt: '32px',
    paddingLeft: 1,
  },
  datePickerContainer: {
    mt: '32px',
    width: 280,
    ml: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: 180,
  },
} satisfies Record<
  string,
  ((isName: string) => SxProps<Theme>) | SxProps<Theme>
>;
export default styles;
