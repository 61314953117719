import React, { useCallback, useEffect, useRef } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import {
  BookingState,
  CalendarStateKey,
  getActiveWebView,
  getTypeOfUser,
  PharmacistLegendVariant,
  TypeOfUser,
} from '@pharmaplan/common';

import useMultipleDelete from '../../../../hooks/useMultipleDelete';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ReactComponent as BellIcon } from '../../../../assets/svg/calendarBellIcon.svg';
import useCustomMonthEvent from '../../../../hooks/useCustomMonthEvent';
import ThemeConstants from '../../../../theme/ThemeConstants';
import genericClasses from '../../../../theme/GenericClasses';
import ICalendarEvent from '../../../../types/ICalendar';
import WavingHand from '../../../common/WavingHand';
import strings from '../../../../localization';

import CalendarTooltip from '../CalendarTooltip';
import classes from './style';
import Watchlisted from '../../../common/Watchlist/Watchlisted';

interface ICustomEventProps {
  event: ICalendarEvent;
  style: (React.CSSProperties & { xOffset: number }) | undefined;
  onSelect: (event: ICalendarEvent) => void;
  eventIds: Array<string>;
}

const CustomMonthEvent = ({
  event,
  style,
  eventIds,
  onSelect,
}: ICustomEventProps) => {
  const { stopMouseDown } = useMultipleDelete();
  const ref = useRef<HTMLElement | null>(null);

  const typeOfUser = useAppSelector(getTypeOfUser).toLowerCase() as TypeOfUser;
  const activeWebView = useAppSelector(getActiveWebView);

  const {
    start,
    end,
    title,
    type,
    id,
    code,
    count,
    bookingState,
    durations,
    isMatchingPrev,
    isDeleted,
    isInWatchlistPrev,
  } = event ?? {};

  const { handlePress, handleTooltipClick } = useCustomMonthEvent({
    event,
    eventIds,
  });

  const isRequestedBooked = type === PharmacistLegendVariant.booked
    || type === PharmacistLegendVariant.requested;
  const isPharmacist = typeOfUser === TypeOfUser.pharmacist.toLowerCase();
  const requiresAction = bookingState === BookingState.PendingWithPharmacist
    || bookingState === BookingState.UpdatedTiming;

  const showBellIcon = isRequestedBooked && isPharmacist && requiresAction;

  const themeConstantsKey = `${typeOfUser.toLowerCase()}s` as keyof typeof ThemeConstants;
  const fillColor = ThemeConstants[themeConstantsKey]?.[type as never];

  const isVacations = type === PharmacistLegendVariant.vacation;
  const showRightBox = activeWebView === CalendarStateKey.month && !isVacations;

  const handleSelect = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // What does this do?
      // onSelect({ start, end, title, type, id, code });
      e.stopPropagation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [end, id, onSelect, start, title, type, code],
  );

  useEffect(() => {
    const refCurrent = ref.current;

    if (refCurrent) {
      refCurrent.addEventListener('click', handlePress);
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('click', handlePress);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="div"
      sx={classes.eventContainer(type, style, typeOfUser, isDeleted)}
      ref={ref}
      onMouseDown={stopMouseDown}
      onClick={handleSelect}
    >
      <CalendarTooltip
        onClick={handleTooltipClick}
        eventType={type}
        durations={durations ?? []}
        date={start}
      >
        <Grid container wrap="nowrap" sx={classes.gridContainer}>

          <Grid item md={9} sx={[classes.titleContainer, classes.center]}>
            <Typography sx={classes.eventTitle(type, typeOfUser, !!isDeleted)}>
              {strings[type]}
            </Typography>

            {showBellIcon && (
              <Box sx={[classes.bellContainer, classes.center]}>
                <BellIcon
                  height={18}
                  width={18}
                  fill={fillColor}
                  stroke={fillColor}
                />
              </Box>
            )}
          </Grid>

          {isMatchingPrev && (
            <Grid sx={[genericClasses.center]} item>
              <WavingHand />
            </Grid>
          )}

          {isInWatchlistPrev && (
            <Grid sx={[genericClasses.center]} item>
              <Watchlisted inCalendar />
            </Grid>
          )}

          {showRightBox && (
            <Grid md={3} item sx={classes.rightGrid}>
              <Box sx={classes.rightBlock(typeOfUser)}>
                <Typography
                  sx={classes.textColor(type, typeOfUser, !!isDeleted)}
                >
                  {isDeleted ? '-' : count}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </CalendarTooltip>
    </Box>
  );
};
export default CustomMonthEvent;
