import React, { FC, useEffect } from 'react';

import { Button } from '@mui/material';

import {
  adminActions,
  adminTakeOwnership,
  ConfirmationTypes,
  getPendingActivations,
  HelpTypes,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { resetDrawer } from '../../../../actions/drawerActions';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';

import styles from './styles';
import { resetDialog, setDialog } from '../../../../reducers/dialogReducer';
import ConfirmationModal from '../../../Modals/ConfirmationModal';
import { Constants } from '../../../../helpers/Constants';

interface ITakeOwnership {
  id: string;
  userType: HelpTypes;
}

const successAction = adminActions.takeOwnership;
const { outlined } = Constants.variant;

const TakeOwnership: FC<ITakeOwnership> = ({ id, userType }) => {
  const dispatch = useAppDispatch();
  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const takeOwnership = () => {
    dispatch(adminTakeOwnership({ id, userType }));
  };

  const confirmTakeOwnership = () => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.takeOwnership}
            confirmAction={takeOwnership}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: false,
      }),
    );
  };

  useEffect(() => {
    if (success) {
      dispatch(resetStatus([successAction]));
      dispatch(resetDrawer());
      dispatch(resetDialog());
      dispatch(getPendingActivations({ page: 1, userType }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Button
      sx={styles.buttonContainer}
      onClick={confirmTakeOwnership}
      variant={outlined}
    >
      {strings.takeOwnership}
    </Button>
  );
};

export default TakeOwnership;
