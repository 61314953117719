import { SxProps, Theme } from '@mui/system';

const styles = {
  text: {
    fontSize: 14,
    marginLeft: '8px',
    color: 'common.gray',
  },

  label: {
    fontSize: 12,
    color: 'primary.inputLabel',
  },

  gridContainer: {
    marginBottom: '24px',
  },

  checkbox: {
    marginBottom: 0,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
