import Routes from '../helpers/Routes';
import { INotificationListParams } from '../models/NotificationsModel';
import httpClient from './httpClient';

class NotificationController {
  basePath: string;
  constructor() {
    this.basePath = Routes.notifications.basePath;
  }

  getNotificationsCount = async () =>
    httpClient.get(
      `${this.basePath}${Routes.notifications.notification}${Routes.notifications.count}`
    );

  getNotificationsList = async (params: INotificationListParams) =>
    httpClient.post(`${this.basePath}${Routes.notifications.notifications}`, {
      pagingModel: params,
    });

  getNotification = async (notificationId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.notifications.notification}/${notificationId}`
    );

  markAsRead = async () =>
    httpClient.get(
      `${this.basePath}${Routes.notifications.notification}${Routes.notifications.markAsRead}`
    );

  deleteNotification = async (notificationId: string) =>
    httpClient.delete(
      `${this.basePath}${Routes.notifications.notification}/${notificationId}`
    );
}

export default new NotificationController();
