import { useEffect, useState } from 'react';

import { adminBookingDetails } from '@pharmaplan/common';
import { useAppSelector } from '../../useAppSelector';

const useExpressBookingDisabled = (formikPharmacistId: string, isBooking:boolean) => {
  const [disabled, setDisabled] = useState(false);
  const bookedDetails = useAppSelector(adminBookingDetails);

  const { pharmacistId } = bookedDetails;

  useEffect(() => {
    if (isBooking) {
      setDisabled(pharmacistId !== formikPharmacistId);
    }
  }, [formikPharmacistId]);

  return { disabled };
};

export default useExpressBookingDisabled;
