import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  calendarIcon: {
    height: 15,
    width: 15,
    color: 'red',
  },

  icon: {
    fontSize: 28,
    color: 'red',
  },

  iconContainer: {
    mt: '2px',
    mr: '4px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
