import { useEffect } from 'react';

const useInterval = (callback: () => void, delay: number, deps: Array<any> = []) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      callback();
    }, delay);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useInterval;
