import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const styles = {
  mainContainer: {
    position: 'relative',
  },
  container: {
    marginBottom: '6px',
  },
  title: {
    maxWidth: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconContainer: {
    position: 'absolute',
    top: 10,
    right: 13,
  },
  iconSubContainer: {
    position: 'relative',
  },
  countContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: 18,
    width: 18,
    backgroundColor: 'primary.main',
    borderRadius: 9,
    top: -9,
    right: -9,
  },
  countText: {
    fontSize: 10,
    fontWeight: '600',
    color: 'secondary.main',
  },
  footerContainer: {
    mb: '12px',
    mt: '2px',
  },
  footer: {
    fontSize: 12,
    color: ThemeConstants.common.grey850,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
