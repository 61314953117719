import { Box, Typography } from '@mui/material';
import React from 'react';
import { ServiceTypes, username } from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import strings from '../../../localization';
import CustomLink from '../../common/CustomLink';
import classes from './style';
import { Constants } from '../../../helpers/Constants';
import { useAppSelector } from '../../../hooks/useAppSelector';

const { pharmagap, pharma, plan } = strings;

const LoginHeader = () => {
  const name = useAppSelector(username);
  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

  const {
    container,
    titleContainer,
    welcomeText,
    pharmaPlanContainer,
    pharmaPlanStyle,
    signUpText,
  } = classes;

  const {
    paths: { signup },
  } = Constants;

  const appType = isSelfService ? pharmagap : `${pharma}${plan}`;

  return (
    <>
      <Box component="div" sx={container}>
        <Box component="div" sx={titleContainer}>
          <Typography component="span" sx={welcomeText}>
            {strings.welcomeTo}
          </Typography>

          <Box sx={pharmaPlanContainer}>
            <Typography component="span" sx={pharmaPlanStyle}>
              {appType}
            </Typography>
          </Box>
        </Box>

        {!name && (
          <Box>
            <Box component="div">{strings.noAccount}</Box>
            <CustomLink linkText={strings.signup} to={signup} />
          </Box>
        )}
      </Box>

      {!name && (
        <Box component="div" sx={signUpText}>
          {strings.signIn}
        </Box>
      )}
    </>
  );
};

export default LoginHeader;
