import { useEffect } from 'react';

import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler';

import { setUnreadCount } from '@pharmaplan/common';

import { useAppDispatch } from '../../useAppDispatch';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import audio from '../../../assets/audio/live_chat.mp3';

const uniqueId = 'uniqueId';

const useClientUnreadChats = () => {
  const dispatch = useAppDispatch();

  const store = useSendbirdStateContext();
  const sdk = store?.stores?.sdkStore?.sdk;
  const notificationSound = new Audio(audio);

  const retrieveUnreadCount = () => {
    sdk.groupChannel?.getTotalUnreadMessageCount().then((unread) => {
      dispatch(setUnreadCount(unread));
    });
  };

  useEffect(() => {
    const handler = new UserEventHandler({
      onTotalUnreadMessageCountChanged: (unread) => {
        const { groupChannelCount } = unread ?? {};
        dispatch(setUnreadCount(groupChannelCount));
        if (unread) {
          notificationSound.play();
        }
      },
    });

    sdk?.addUserEventHandler?.(uniqueId, handler);
    retrieveUnreadCount();

    return () => {
      sdk?.removeUserEventHandler?.(uniqueId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk]);
};

export default useClientUnreadChats;
