import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = {
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginLeft: '5px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
