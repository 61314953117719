import { AsyncThunk } from '@reduxjs/toolkit';

import {
  AdminLegendVariant,
  IAdminWorkshiftDetails,
  IAdminRequestedDetailsData,
  IAdminBookedPharmacistDetails,
  adminWorkshiftDetails,
  adminRequestedDetails,
  adminBookingDetails,
  getWorkshiftDetails,
  getRequestedDetails,
  adminBookedPharmacistDetails,
} from '@pharmaplan/common';

import { ScreenTypes } from '../../../helpers/Constants';
import { useAppSelector } from '../../useAppSelector';

const { posted, requested, booked } = AdminLegendVariant;
interface IDetailsAPIParams<T> {
  api: AsyncThunk<any, string, any>;
  details: T;
  state: string;
  back: {
    screenType: ScreenTypes;
    screenNumber: number;
  };
}

interface IDetailsApiMap {
  [posted]: IDetailsAPIParams<IAdminWorkshiftDetails>;
  [requested]: IDetailsAPIParams<IAdminRequestedDetailsData>;
  [booked]: IDetailsAPIParams<IAdminBookedPharmacistDetails>;
}

const useExpressBookingTypes = () => {
  const workshiftDetails = useAppSelector(adminWorkshiftDetails);
  const requestedDetails = useAppSelector(adminRequestedDetails);
  const bookedDetails = useAppSelector(adminBookingDetails);

  const detailsApiMap: IDetailsApiMap = {
    [posted]: {
      api: getWorkshiftDetails,
      details: workshiftDetails,
      state: 'workshiftDetails',
      back: {
        screenType: ScreenTypes.availableWorkshift,
        screenNumber: 1,
      },
    },
    [requested]: {
      api: getRequestedDetails,
      details: requestedDetails,
      state: 'requestedDetails',
      back: {
        screenType: ScreenTypes.requestedList,
        screenNumber: 2,
      },
    },
    [booked]: {
      api: adminBookedPharmacistDetails,
      details: bookedDetails,
      state: 'bookedPharmacistData',
      back: {
        screenType: ScreenTypes.bookingList,
        screenNumber: 1,
      },
    },
  };

  return { detailsApiMap };
};

export default useExpressBookingTypes;
