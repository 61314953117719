import { ReportRequestTypes } from '@pharmaplan/common';
import { serverToday } from '../helpers/Functions';

const useLegendReportNavigation = (
  type: ReportRequestTypes,
  start: string,
  end: string,
) => {
  const isBooked = type === ReportRequestTypes.booked;
  const startCurrentMonth = serverToday();
  const endCurrentMonth = serverToday().endOf('month');

  return {
    manStart: isBooked ? start : startCurrentMonth,
    manEnd: isBooked ? end : endCurrentMonth,
  };
};

export default useLegendReportNavigation;
