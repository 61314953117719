import React, { FC } from 'react';
import {
  AdminLegendVariant,
  adminActions,
  adminPharmacyHistory,
} from '@pharmaplan/common';

import usePharmacyHistory from '../../../../../hooks/Admin/PharmacyList/usePharmacyHistory';
import AdminPharmacistCard from '../../../Profile/Pharmacist/PharmacistViewProfile/AdminPharmacistCard';
import strings from '../../../../../localization';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import AdminReplacementList from '../../../AdminReplacementList';

export interface GenericReplacementActionTypes {
  pharmacistId: string;
  pharmacyId: string;
}

const successAction = adminActions.pharmacyHistory;

const AdminPharmacyHistory: FC<GenericReplacementActionTypes> = ({
  pharmacistId,
  pharmacyId,
}) => {
  const history = useAppSelector(adminPharmacyHistory);
  const { data } = history ?? {};

  const { table, ratingGoBack, page, setPage } = usePharmacyHistory({
    pharmacistId,
    pharmacyId,
  });

  return (
    <AdminReplacementList
      table={table}
      successAction={successAction}
      title={strings.history}
      data={data}
      page={page}
      setPage={setPage}
      headerComponent={(
        <AdminPharmacistCard
          pharmacistId={pharmacistId}
          type={AdminLegendVariant.posted}
          hasOwner={false}
          ratingGoBack={ratingGoBack}
        />
      )}
    />
  );
};

export default AdminPharmacyHistory;
