import React, { FC } from 'react';

import { Box, SxProps, Typography } from '@mui/material';

import {
  PharmacistLegendVariant,
  PharmacyLegendVariant,
  ToUserTypings,
  TypeOfUser,
  convertToDecimals,
  getTypeOfUser,
  currency,
  perHour,
} from '@pharmaplan/common';

import CustomChip from '../../common/CustomChip';
import { ChipType } from '../../../helpers/Constants';
import { useAppSelector } from '../../../hooks/useAppSelector';

import classes from './style';

interface ServiceCardProps {
  hideDivider?: boolean;
  isCounterOffer?: boolean;
  eventType?: PharmacyLegendVariant | PharmacistLegendVariant | null;
  title?: string;
  hourlyRate?: {
    paidHourlyRate: number;
    counterHourlyRate: number;
  };
  customContainerStyle?: SxProps;
  content: React.ReactNode;
  footer?: React.ReactNode;
}

const ServiceCard: FC<ServiceCardProps> = ({
  title,
  hideDivider = false,
  isCounterOffer,
  eventType,
  hourlyRate,
  customContainerStyle,
  content,
  footer,
}) => {
  const userType = useAppSelector(getTypeOfUser);

  const counterOfferCard = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      dividerStyle: classes.counterDivider,
      background: classes.pharmacistCounterCard,
    },
    [TypeOfUser.pharmacy]: {
      dividerStyle: classes.footerDivider,
      background: classes.pharmacyCounterCard,
    },
  });

  const counterConfig = isCounterOffer
    ? {
      dividerStyle: counterOfferCard[userType].dividerStyle,
      background: counterOfferCard[userType]?.background,
      hourlyRate: hourlyRate?.counterHourlyRate,
    }
    : {
      dividerStyle: classes.footerDivider,
      background: {},
      hourlyRate: hourlyRate?.paidHourlyRate,
    };

  const displayHouryRate = `${currency}${convertToDecimals(
    counterConfig.hourlyRate,
  )}${perHour}`;

  return (
    <Box>
      <Box
        sx={
          [
            classes.root,
            eventType && classes.eventBorder(eventType),
            customContainerStyle,
            counterConfig.background,
          ] as keyof typeof eventType
        }
      >
        {!!title && (
          <Box sx={classes.titleContainer}>
            <Typography sx={classes.title}>{title}</Typography>
            {hourlyRate && (
              <Box sx={classes.hourlyRateContainer}>
                {isCounterOffer && (
                  <Typography sx={classes.strike}>
                    $
                    {hourlyRate?.paidHourlyRate}
                    /Hr
                  </Typography>
                )}
                <CustomChip
                  type={ChipType.hourlyRate}
                  label={displayHouryRate}
                />
              </Box>
            )}
          </Box>
        )}
        <Box>{content}</Box>
        {footer && (
          <Box
            sx={[
              hideDivider
                ? classes.footerWithoutDivider
                : counterConfig.dividerStyle,
            ]}
          >
            {footer}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ServiceCard;
