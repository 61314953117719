import React, { useEffect } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  adminGetPharmacistCapacity,
  adminGetPharmacistCapacityList,
  adminPharmacistCapacity,
  adminResetValue,
  adminUpdatePharmacistCapacity,
  IAdminUpdatePharmacistCapacityApiParams,
  resetStatus,
  successOrSelector,
  successSelector,
} from '@pharmaplan/common';
import InputModal from '../../components/Modals/InputModal';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { setDialog } from '../../reducers/dialogReducer';
import strings from '../../localization';
import Validator from '../../components/Admin/PharmacistCapacities/validator';
import { addNewPharmacistCapacityForm } from '../../components/Admin/PharmacistCapacities/helper';

const {
  getPharmacistCapacity,
  createPharmacistCapacity: createAction,
  updatePharmacistCapacity: updateAction,
  deletePharmacistCapacities,
} = adminActions;
const successAction = getPharmacistCapacity;
const addSuccess = createAction;
const updateSuccess = updateAction;
const deleteSuccess = deletePharmacistCapacities;

const usePharmacistCapacities = () => {
  const dispatch = useAppDispatch();

  const pharmacistCapacity = useAppSelector(adminPharmacistCapacity);
  const { from, to, published, pharmacistCapacityId } = pharmacistCapacity ?? {};

  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const addUpdateDeleteSuccess = useAppSelector((state) =>
    successOrSelector([updateSuccess, addSuccess, deleteSuccess], state));

  const updatePharmacistCapacity = (values: FormikValues) => {
    dispatch(
      adminUpdatePharmacistCapacity(
        values as IAdminUpdatePharmacistCapacityApiParams,
      ),
    );
  };

  const initialValues = {
    from: `${from}`,
    to: `${to}`,
    published,
    pharmacistCapacityId,
  };

  const updateModal = () => {
    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={updatePharmacistCapacity}
            initialValues={initialValues}
            submitLabel={strings.update}
            config={addNewPharmacistCapacityForm()}
            validator={Validator()}
          />
        ),
        heading: {
          title: strings.update,
        },
        showCloseButton: true,
      }),
    );
  };

  useEffect(() => {
    if (addUpdateDeleteSuccess) {
      dispatch(adminGetPharmacistCapacityList({ paging: {} }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateDeleteSuccess]);

  useEffect(() => {
    if (success && initialValues.pharmacistCapacityId) {
      updateModal();
      dispatch(resetStatus([successAction]));
      dispatch(adminResetValue(['pharmacistCapacity']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, initialValues.pharmacistCapacityId]);

  const handleUpdate = (capacityId: string) => {
    dispatch(adminGetPharmacistCapacity(capacityId));
  };

  return { handleUpdate };
};

export default usePharmacistCapacities;
