import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  customTextField: {},
  customTextFieldContainer: { position: 'relative' },
  horizontalTextField: { display: 'flex',
    alignItems: 'center',
    '& label': {
      flex: 1,
    },
    '& .MuiTextField-root': {
      flex: 3,
    },
  },
  customTextFieldRequireLabel: { color: 'error.main', marginLeft: '2px' },
  customTextFieldPasswordIcon: {
    position: 'absolute',
    right: '9px',
    top: '33px',
    cursor: 'pointer',
  },
  filledInput: {
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottom: 'none',
        content: "''",
        position: 'relative',
        border: 'none',
      },
      '&:after': {
        borderBottom: 'none',
        border: 'none',
      },
      padding: '5px 5px 8px',
      backgroundColor: ThemeConstants.common.lightBackgroundColor,
    },
  },
};

export default classes;
