/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';

import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { Box, IconButton, Paper, TextField, Typography } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { CancelOutlined } from '@mui/icons-material';
import { DateTime } from 'luxon';

import { language } from '@pharmaplan/common';

import { ButtonSize, MomentTimeFormats } from '../../../helpers/Constants';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';

import CustomPickerDay from './CustomPickerDay';
import styles from './styles';

interface IMultiSelectCalendar {
  dateMapper?: (date: DateTime) => boolean;
  setSelectedDates: React.Dispatch<React.SetStateAction<Array<string>>>;
  selectedDates: Array<string>;
}

const MultiSelectCalendar: FC<IMultiSelectCalendar> = ({
  selectedDates,
  setSelectedDates,
  dateMapper,
}) => {
  const userLanguage = useAppSelector(language);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const stub = () => {};

  const handleDaySelect = (selectedDay: DateTime) => {
    const isoDay = selectedDay.toISO({ includeOffset: false }) ?? '';
    if (selectedDates.includes(isoDay)) {
      setSelectedDates(
        selectedDates.filter((item: string | null) =>
          item !== isoDay),
      );
    } else {
      setSelectedDates([...selectedDates, isoDay]);
    }
  };

  return (
    <Box sx={[styles.container, styles.font]}>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        adapterLocale={userLanguage}
      >
        <StaticDatePicker
          disablePast
          displayStaticWrapperAs="desktop"
          onChange={stub}
          renderDay={(days, _, pickerDayProps) =>
            (
              <CustomPickerDay
                day={days}
                pickersDayProps={pickerDayProps}
                handleDaySelect={handleDaySelect}
                disabled={dateMapper?.(days)}
                selectedDates={selectedDates}
              />
            )}
          value={DateTime.fromISO(selectedDates[selectedDates.length - 1])}
          renderInput={(props) =>
            (
              <TextField {...props} fullWidth value={selectedDates.join(',')} />
            )}
        />
      </LocalizationProvider>
      <Paper sx={styles.panelContainer}>
        <Typography sx={styles.panelTitle}>{strings.dates}</Typography>
        <Box sx={styles.panelList}>
          {selectedDates?.map((item: string) => {
            const dateTimeFormat = DateTime.fromISO(item);
            const readableFormat = dateTimeFormat.toFormat(
              MomentTimeFormats.dateMonthYear,
            );
            return (
              <Box sx={styles.panelListItem} key={item}>
                <Typography sx={styles.panelListDate}>
                  {readableFormat}
                </Typography>
                <IconButton
                  sx={styles.cancelIcon}
                  size={ButtonSize.small}
                  onClick={() =>
                    handleDaySelect(dateTimeFormat)}
                >
                  <CancelOutlined
                    color="secondary"
                    fontSize={ButtonSize.small}
                  />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      </Paper>
    </Box>
  );
};

export default MultiSelectCalendar;
