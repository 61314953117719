import { useEffect, useMemo, useState } from 'react';
import { ITransferListItem } from '../components/common/TransferList/TransferList';

export interface IInput<T> {
  data: Array<T>;
  idKey: keyof T;
  nameKey: keyof T;
  emailKey: keyof T;
}

interface IUseBidirectionalTransferList<K, T> {
  leftInput: IInput<K>;
  rightInput: IInput<T>;
}

const useBidirectionalTransferList = <LeftType, RightType>({
  leftInput,
  rightInput,
}: IUseBidirectionalTransferList<LeftType, RightType>) => {
  const [list, setList] = useState<{
    left: Array<ITransferListItem>;
    right: Array<ITransferListItem>;
  }>({ left: [], right: [] });

  const {
    data: lData,
    idKey: lIdKey,
    nameKey: lNameKey,
    emailKey: lEmailKey,
  } = leftInput;
  const {
    data: rData,
    idKey: rIdKey,
    nameKey: rNameKey,
    emailKey: rEmailKey,
  } = rightInput;

  const right = useMemo(
    () =>
      rData.map((item) =>
        ({
          key: item[rIdKey] as string,
          label: item[rNameKey] as string,
          email: item[rEmailKey] as string,
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rData],
  );

  const left = useMemo(
    () =>
      lData
        .filter(
          (item) =>
            !list.right
              .map((incom) =>
                incom.key)
              .includes(item[lIdKey] as string),
        )
        .filter(
          (item) =>
            !right.map((incom) =>
              incom.key).includes(item[lIdKey] as any),
        )
        .map((item) =>
          ({
            key: item[lIdKey] as string,

            label: item[lNameKey] as string,
            email: item[lEmailKey] as string,
          })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lData, right],
  );

  useEffect(() => {
    setList((prevList) =>
      ({
        ...prevList,
        left,
      }));
  }, [left]);

  useEffect(() => {
    setList((prevList) =>
      ({
        ...prevList,
        right,
      }));
  }, [right]);

  return { left, right, list, setList };
};

export default useBidirectionalTransferList;
