import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { Box } from '@mui/system';

import { PermissionsOfAdmin } from '@pharmaplan/common';

import { ReactComponent as PlusIcon } from '@pharmaplan/common/assets/icons/plusIcon.svg';
import { ReactComponent as UserIcon } from '../../../../assets/svg/userIcon.svg';
import useAdminPermissions from '../../../../hooks/Admin/useAdminPermissions';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import CustomTextField from '../../../common/CustomTextField';

import genericClasses from '../../../../theme/GenericClasses';
import { Constants } from '../../../../helpers/Constants';
import CustomButton from '../../../common/CustomButton';
import strings from '../../../../localization';

import styles from './styles';

const { outlined } = Constants.variant;
const {
  admin: { createPharmacyAdministrators, appointPharmacyList },
} = Constants.paths;

interface IPharmacyAdminHeader {
  formik: FormikProps<any>;
  handleReset: () => void;
}

const PharmacyAdminHeader: FC<IPharmacyAdminHeader> = ({
  formik,
  handleReset,
}) => {
  const navigate = useNavigate();

  const { can } = useAdminPermissions();
  const canViewPharmacyAdmin = can(PermissionsOfAdmin.PharmacyAdmin);

  const goToCreatePharmacyAdmin = () => {
    navigate(createPharmacyAdministrators);
  };

  const goToAppointPharmacy = () => {
    navigate(appointPharmacyList);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.subContainer}>
        <Typography sx={styles.title}>
          {strings.pharmacyAdministrators}
        </Typography>
        <Box sx={genericClasses.flex}>
          {canViewPharmacyAdmin && (
            <>
              <CustomButton
                onClick={goToAppointPharmacy}
                customClass={styles.button}
                variant={outlined}
                label={strings.appointExistingPharmacy}
                startIcon={<UserIcon />}
              />
              <CustomButton
                onClick={goToCreatePharmacyAdmin}
                variant={outlined}
                label={strings.createNewPharmacyAdmin}
                startIcon={<PlusIcon />}
              />
            </>
          )}
        </Box>
      </Box>
      <Grid
        component="form"
        onSubmit={formik.handleSubmit}
        container
        alignItems="center"
        xs={7}
        wrap="nowrap"
      >
        <Grid xs={4} item>
          <CustomTextField
            label={strings.admin}
            placeholder={strings.enterNameEmail}
            name="adminText"
            id="adminText"
            formik={formik}
          />
        </Grid>
        <Grid xs={4} sx={styles.codeContainer} item>
          <CustomTextField
            label={strings.pharmacy}
            placeholder={strings.enterNameEmail}
            name="pharmacyText"
            id="pharmacyText"
            formik={formik}
          />
        </Grid>
        <FilterSubmitButtons handleReset={handleReset} />
      </Grid>
    </Box>
  );
};

export default PharmacyAdminHeader;
