import React, { FC, useCallback, useEffect, useState } from 'react';

import { Stack } from '@mui/system';
import { Typography } from '@mui/material';

import {
  adminActions,
  adminGetPharmacistHistory,
  adminGetPharmacistHistoryPagination,
  adminPharmacistHistory,
  getPharmacyDetails,
  ITEMS_PER_PAGE,
  successSelector,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { IDynamicTableObject } from '../../../DynamicTable/types';
import DynamicTable from '../../../DynamicTable';

import strings from '../../../../localization';
import { renderScreen, setBack } from '../../../../actions/drawerActions';
import { ScreenTypes } from '../../../../helpers/Constants';
import AdminPharmacyCard from '../../../common/AdminPharmacyCard';
import genericClasses from '../../../../theme/GenericClasses';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';

import {
  adminPharmacistHistoryHeaders,
  adminPharmacistHistoryRow,
} from './helper';
import styles from './styles';

interface IAdminPharmacistHistory {
  pharmacistId: string;
  pharmacyId: string;
}

const AdminPharmacistHistory: FC<IAdminPharmacistHistory> = ({
  pharmacistId,
  pharmacyId,
}) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);

  const loadSuccess = useAppSelector((state) =>
    successSelector([adminActions.pharmacistHistory], state));

  const history = useAppSelector(adminPharmacistHistory);
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const { data, totalCount } = history;

  const table: IDynamicTableObject = {
    headerBar: [],
    headers: adminPharmacistHistoryHeaders(),
    rows: adminPharmacistHistoryRow(data, timeFormat),
  };

  const observerCallback = useCallback(
    (entries: { isIntersecting: any }[]) => {
      const { length } = data ?? {};
      const paginationCondition = length >= page * ITEMS_PER_PAGE && length < totalCount;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, data],
  );

  const { listEndRef } = useInfiniteScroll({ observerCallback });

  const goBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 8,
        screenType: ScreenTypes.pharmacistList,
        pharmacistId,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      adminGetPharmacistHistory({
        pharmacistId,
        pharmacyId,
        paging: { page: 1 },
      }),
    );
    dispatch(getPharmacyDetails(pharmacyId));
    dispatch(setBack(goBack));
    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const back = () => {
    dispatch(
      renderScreen({
        screenNumber: 6,
        screenType: ScreenTypes.pharmacistList,
        pharmacistId,
        pharmacyId,
      }),
    );
  };

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        adminGetPharmacistHistoryPagination({
          pharmacistId,
          pharmacyId,
          paging: { page },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Stack>
      <AdminPharmacyCard pharmacyId={pharmacyId} back={back} />
      <DynamicTable
        showHeader={false}
        customHeader={
          <Typography sx={styles.title}>{strings.history}</Typography>
        }
        noContainerStyling
        loadSuccess={loadSuccess}
        table={table}
        hidePagination
      />
      {loadSuccess && (
        <div ref={listEndRef} style={genericClasses.paginationDiv} />
      )}
    </Stack>
  );
};

export default AdminPharmacistHistory;
