import React, { FC, useEffect, useMemo } from 'react';

import { FormikProps } from 'formik';

import {
  adminActiveUserStatistics,
  getActiveUserStatistics,
} from '@pharmaplan/common';

import { approvedPendingStatsKey } from '../../../../helpers/Constants';
import ChartsContainer from '../../../common/Charts/ChartsContainer';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import ChartBuilder from '../../../common/Charts/ChartBuilder';
import { mergeDatasets } from '../../../../helpers/Functions';
import ChartLayout from '../../../common/Charts/ChartLayout';
import strings from '../../../../localization';

import { registrationActivationStatus, newRegistrationsConfig } from './helper';

interface IRegistrationStatistics {
  formik: FormikProps<any>;
  start: string;
  end: string;
}

const RegistrationStatistics: FC<IRegistrationStatistics> = ({
  formik,
  start,
  end,
}) => {
  const dispatch = useAppDispatch();

  const stats = useAppSelector(adminActiveUserStatistics);
  const {
    registeredPharmacies,
    registeredPharmacists,
    pendingPharmacists,
    pendigPharmacies,
    approvedPharmacies,
    approvedPharmacists,
  } = stats ?? {};

  const approvedPendingPharmacies = useMemo(
    () =>
      mergeDatasets(
        [pendigPharmacies, approvedPharmacies],
        [approvedPendingStatsKey.pending, approvedPendingStatsKey.approved],
      ),
    [approvedPharmacies, pendigPharmacies],
  );

  const approvedPendingPharmacists = useMemo(
    () =>
      mergeDatasets(
        [pendingPharmacists, approvedPharmacists],
        [approvedPendingStatsKey.pending, approvedPendingStatsKey.approved],
      ),
    [approvedPharmacists, pendingPharmacists],
  );

  useEffect(() => {
    dispatch(
      getActiveUserStatistics({
        startDate: start,
        endDate: end,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end, start]);

  return (
    <ChartLayout formik={formik} title={strings.registrationStatistics}>
      <ChartsContainer title={strings.newRegistrations}>
        <ChartBuilder
          config={newRegistrationsConfig(
            registeredPharmacies,
            registeredPharmacists,
          )}
        />
      </ChartsContainer>
      <ChartsContainer title={strings.registrationBasedOnActivation}>
        <ChartBuilder
          config={registrationActivationStatus(
            approvedPendingPharmacies,
            approvedPendingPharmacists,
          )}
        />
      </ChartsContainer>
    </ChartLayout>
  );
};

export default RegistrationStatistics;
