const classes = {
  customMenuContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customMenu: {
    mt: '30px',
    '& :focus': {
      backgroundColor: 'white !important',
    },
  },
  customMenuItemIcon: {
    mr: '10px',
  },
  customMenuItemLabel: {
    fontSize: '14px',
  },

};

export default classes;
