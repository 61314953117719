import { SxProps, Theme } from '@mui/system';

const classes = {
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  codeFont: {
    color: 'common.grey850',
    fontSize: 14,
  },

  contactNameTitle: {
    fontSize: 12,
    color: 'common.grey850',
  },

  contactName: {
    fontWeight: 500,
    color: 'common.grey850',
  },

  customLink: {
    fontWeight: 500,
    fontSize: '14px',
    paddingLeft: '4px',
    paddingY: 0,
  },

  ratingContainer: {
    padding: 0,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },

  ratingText: {
    fontWeight: 500,
    fontSize: '14px',
    paddingLeft: '2px',
  },

  container: {
    display: 'flex', justifyContent: 'space-between',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
