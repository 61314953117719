import { adminGetPharmacyHistory, adminGetPharmacyHistoryPagination, adminPharmacyHistory, getPharmacistDetails, userPreferredTimeFormat } from '@pharmaplan/common';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import { pharmacyHistoryHeaders, pharmacyHistoryRow } from '../../../components/Admin/PharmacyList/ListOfPharmacists/AdminPharmacyHistory/helper';
import { IDynamicTableObject } from '../../../components/DynamicTable/types';
import { renderScreen, setBack } from '../../../actions/drawerActions';
import { ScreenTypes } from '../../../helpers/Constants';
import { GenericReplacementActionTypes } from '../../../components/Admin/PharmacyList/ListOfPharmacists/AdminPharmacyHistory/AdminPharmacyHistory';

const usePharmacyHistory = ({ pharmacistId, pharmacyId }: GenericReplacementActionTypes) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const history = useAppSelector(adminPharmacyHistory);
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const { data } = history ?? {};

  const table: IDynamicTableObject = {
    headerBar: [],
    headers: pharmacyHistoryHeaders(),
    rows: pharmacyHistoryRow(
      data,
      timeFormat,
    ),
  };

  const goBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.pharmacyList,
        pharmacyId,
      }),
    );
  };

  const ratingGoBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 3,
        screenType: ScreenTypes.pharmacyList,
        pharmacistId,
        pharmacyId,
      }),
    );
  };

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        adminGetPharmacyHistoryPagination({
          pharmacistId,
          pharmacyId,
          paging: { page },
        }),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch(adminGetPharmacyHistory({ pharmacistId, pharmacyId, paging: { page: 1 } }));
    dispatch(getPharmacistDetails(pharmacistId));
    dispatch(setBack(goBack));

    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { table, ratingGoBack, page, setPage };
};

export default usePharmacyHistory;
