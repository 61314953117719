import { SxProps, Theme } from '@mui/system';

const styles = {
  autoComplete: {
    '& .MuiAutocomplete-inputRoot': {
      paddingBottom: '2px',
    },
  },

  codeContainer: {
    marginLeft: '32px',
  },

  bannerDropdown: {
    maxHeight: 320,
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
