import { SxProps, Theme } from '@mui/system';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: '30px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
