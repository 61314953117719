import React, { FC, useEffect } from 'react';

import { Avatar, IconButton } from '@mui/material';
import { Box } from '@mui/system';

import {
  adminActions,
  getAdminPicture,
  getPharmacistPicture,
  getPharmacyAdminPicture,
  getTypeOfUser,
  isPharmacyAdminLoginSelector,
  pharmacistActions,
  pharmacyAdminActions,
  pictureUpload,
  profileImage,
  resetStatus,
  successOrSelector,
  TypeOfUser,
  uploadAdminPicture,
  uploadPharmacyAdminPicture,
} from '@pharmaplan/common';
import { ReactComponent as CameraIcon } from '@pharmaplan/common/assets/icons/cameraIcon.svg';

import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { InputTypes } from '../../../helpers/Constants';
import genericClasses from '../../../theme/GenericClasses';

import classes from './styles';

interface ICustomAvatar {
  showFullImage: () => void;
}

const { admin, superAdmin, pharmacyAdmin, pharmacy, pharmacist } = TypeOfUser;

const successActions = [
  pharmacistActions.pictureUpload,
  adminActions.uploadAdminPicture,
  pharmacyAdminActions.uploadPharmacyAdminPicture,
];

const CustomAvatar: FC<ICustomAvatar> = ({ showFullImage }) => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(getTypeOfUser);

  const avatar = useAppSelector(profileImage);
  const isPharmacyAdminLogin = useAppSelector(isPharmacyAdminLoginSelector);

  const inputFile = React.useRef<HTMLInputElement>(null);
  const isPharmacy = userType === pharmacy;

  const uploadSuccess = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const handleUploadAPI = (formData: FormData) => {
    const uploadActions = {
      [superAdmin]: uploadAdminPicture,
      [admin]: uploadAdminPicture,
      [pharmacyAdmin]: uploadPharmacyAdminPicture,
      [pharmacist]: pictureUpload,
    };

    const action = uploadActions[userType as keyof typeof uploadActions];
    dispatch(action(formData));
  };

  const handleGetAPI = () => {
    const getActions = {
      [superAdmin]: getAdminPicture,
      [admin]: getAdminPicture,
      [pharmacyAdmin]: getPharmacyAdminPicture,
      [pharmacist]: getPharmacistPicture,
    };

    const action = getActions[userType as keyof typeof getActions];
    dispatch(action());
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file && file?.length > 0) {
      const formData = new FormData();
      formData.append('image', file[0]);

      handleUploadAPI(formData);
    }
    e.target.value = '';
  };

  const imgProps = isPharmacy || isPharmacyAdminLogin
    ? classes.avatarContain
    : classes.avatarCover;

  useEffect(() => {
    if (uploadSuccess) {
      dispatch(resetStatus(successActions));
      handleGetAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSuccess]);

  return (
    <Box sx={classes.avatarBox}>
      <Avatar
        onClick={showFullImage}
        src={avatar}
        imgProps={{ style: imgProps }}
        sx={classes.settingsAvatar}
      />
      {!isPharmacy && (
        <Box
          onClick={() =>
            inputFile.current?.click()}
          sx={classes.cameraContainer}
        >
          <IconButton color="secondary" sx={classes.buttonPadding}>
            <CameraIcon />
            <input
              style={genericClasses.hidden}
              accept={'image/*'}
              ref={inputFile}
              onChange={handleUpload}
              type={InputTypes.file}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CustomAvatar;
