import React, { useEffect } from 'react';
import { FormikValues, useFormik } from 'formik';
import {
  getChangePassword,
  getEmail,
  getTypeOfUser,
  resetStatus,
  successSelector,
  TypeOfUser,
  userActions,
} from '@pharmaplan/common';
import { useNavigate } from 'react-router-dom';
import { classes } from './style';
import CustomTextField from '../common/CustomTextField';
import strings from '../../localization';
import { ReactComponent as ResetPasswordIcon } from '../../assets/svg/resetPasswordIcon.svg';
import LoginCustomCard from '../common/LoginCustomCard';
import genericClasses from '../../theme/GenericClasses';
import Validator from './Validator';
import {
  Constants,
  InputTypes,
  OutcomeModalTypes,
} from '../../helpers/Constants';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import OutcomeModal from '../Modals/OutcomeModal';
import { setDialog } from '../../reducers/dialogReducer';

const { pharmacist, pharmacy, admin, superAdmin, pharmacyAdmin } = TypeOfUser;

const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userType = useAppSelector(getTypeOfUser);

  const email = useAppSelector(getEmail);

  const success = useAppSelector((state) =>
    successSelector([userActions.changePassword], state));

  const gotoDashboard = () => {
    switch (userType) {
      case superAdmin:
      case admin:
        navigate(Constants.paths.admin.adminCalendarDashboard);
        break;
      case pharmacy:
      case pharmacist:
        navigate(Constants.paths.calendarDashboard);
        break;
      case pharmacyAdmin:
        navigate(Constants.paths.pharmacyAdmin.home);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (success) {
      gotoDashboard();
      dispatch(
        setDialog({
          heading: {
            title: '',
          },
          Component: (
            <OutcomeModal
              type={OutcomeModalTypes.success}
              message={strings.profileSaved}
            />
          ),
          showCloseButton: true,
        }),
      );
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleSubmit = (values: FormikValues) => {
    dispatch(
      getChangePassword({
        email,
        appId: 'windows',
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: handleSubmit,
    validationSchema: Validator,
  });

  const textArray = [
    {
      key: 1,
      text: strings.createNewPw,
      sx: classes.infoHeading,
    },
    {
      key: 2,
      text: strings.newPasswordDesc,
      sx: classes.infoDesc,
    },
  ];

  return (
    <LoginCustomCard
      icon={<ResetPasswordIcon />}
      textArray={textArray}
      buttonTitle={strings.changePassword}
      onClick={formik.handleSubmit}
      fullScreen={false}
    >
      <CustomTextField
        name="oldPassword"
        type={InputTypes.password}
        customClass={genericClasses.loginMargins}
        formik={formik}
        placeholder={strings.formatString(strings.enterCred, strings.password)}
        id="password"
        label={strings.oldPassword}
      />
      <CustomTextField
        name="newPassword"
        type={InputTypes.password}
        customClass={genericClasses.loginMargins}
        showStrentghBar
        formik={formik}
        placeholder={strings.formatString(strings.enterCred, strings.password)}
        id="newPassword"
        label={strings.newPassword}
      />

      <CustomTextField
        name="confirmPassword"
        type={InputTypes.password}
        formik={formik}
        placeholder={strings.formatString(
          strings.enterCred,
          strings.confirmPassword,
        )}
        id="confirmPassword"
        label={strings.confirmPassword}
      />
    </LoginCustomCard>
  );
};

export default ChangePassword;
