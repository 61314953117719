import React, { FC, useEffect } from 'react';

import {
  adminActions,
  getPendingCancellationDetails,
  pendingCancellationDetails,
  PendingCancellationTabTypes,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';
import CustomGridLayout from '../../../common/CustomGridLayout';
import ServiceCard from '../../../SelfService/ServiceCard';
import { getDayMonthDateYear } from '../../../Reports/helpers';
import {
  setFooter,
  setFooterShow,
  setTitle,
} from '../../../../actions/drawerActions';
import usePendingCancellationActions from '../../../../hooks/Admin/PendingCancellations/usePendingCancellationActions';

import styles from './styles';

interface IPendingCancellationDetails {
  cancellationRequestId: string;
  type: PendingCancellationTabTypes;
}

const { rejectedCancellations, cancelledBookings } = PendingCancellationTabTypes;

const successAction = adminActions.getPendingCancellationDetails;

const PendingCancellationDetails: FC<IPendingCancellationDetails> = ({
  cancellationRequestId,
  type,
}) => {
  const dispatch = useAppDispatch();

  const cancellationDetails = useAppSelector(pendingCancellationDetails);
  const { approveAction, rejectAction } = usePendingCancellationActions();

  const {
    requestReason,
    requestCode,
    requestType,
    selectedDate,
    cancellationReason,
    pharmacistName,
    pharmacyName,
    workshiftCode,
    cancellationDate,
    approvedByName,
    availabilityCode,
    cancellationRejectionDate,
    rejectionReason,
    confirmationCode,
    rejectedByName,
    status,
  } = cancellationDetails ?? {};

  const parsedDate = getDayMonthDateYear(selectedDate);
  const parsedRejectedDate = getDayMonthDateYear(
    cancellationRejectionDate ?? '',
  );
  const parsedCancellationDate = getDayMonthDateYear(cancellationDate);

  useEffect(() => {
    dispatch(
      getPendingCancellationDetails({ id: cancellationRequestId, type }),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const cancellationInfoConfig = [
    { key: strings.cancellationCode, value: confirmationCode },
    { key: strings.cancellationDate, value: parsedCancellationDate },
    { key: strings.approvedBy, value: approvedByName },
    { key: strings.cancellationNote, value: cancellationReason },
  ];

  const rejectionInfoConfig = [
    { key: strings.rejectedDate, value: parsedRejectedDate },
    { key: strings.rejectedBy, value: rejectedByName },
    { key: strings.rejectionNote, value: rejectionReason },
  ];

  const requestCancellationConfig = [
    { key: strings.requestStatus, value: status },
    { key: strings.requestType, value: requestType },
    { key: strings.requestCode, value: requestCode },
    { key: strings.requestReason, value: requestReason },
  ];

  const bookingInfo = [
    { key: strings.bookingDate, value: parsedDate },
    { key: strings.pharmacistName, value: pharmacistName },
    { key: strings.pharmacyName, value: pharmacyName },
    { key: strings.workshiftCode, value: workshiftCode },
    { key: strings.availabilityCode, value: availabilityCode },
  ];

  const topGrid = {
    [cancelledBookings]: {
      config: cancellationInfoConfig,
      title: strings.cancellationInfo,
    },
    [rejectedCancellations]: {
      config: rejectionInfoConfig,
      title: strings.rejectedCancellationInfo,
    },
  };

  const handleFooter = () => {
    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.approve,
            onClick: () =>
              approveAction(cancellationRequestId, requestCode),
          },
          secondaryButton: {
            label: strings.reject,
            onClick: () =>
              rejectAction(cancellationRequestId, requestCode),
          },
        },
      }),
    );
  };

  useEffect(() => {
    const title = `${strings.code}: ${requestCode}`;
    dispatch(setTitle({ title }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCode]);

  useEffect(() => {
    if (type === PendingCancellationTabTypes.pendingCancellations) {
      handleFooter();
    } else {
      dispatch(setFooterShow(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancellationDetails]);

  return (
    <>
      {topGrid[type as keyof typeof topGrid] && (
        <ServiceCard
          customContainerStyle={styles.serviceContainer}
          content={(
            <CustomGridLayout
              successAction={successAction}
              title={topGrid[type as keyof typeof topGrid].title}
              config={topGrid[type as keyof typeof topGrid].config}
            />
          )}
        />
      )}
      <ServiceCard
        customContainerStyle={styles.serviceContainer}
        content={(
          <CustomGridLayout
            successAction={successAction}
            title={strings.requestCancellationInfo}
            config={requestCancellationConfig}
          />
        )}
      />
      <ServiceCard
        customContainerStyle={styles.serviceContainer}
        content={(
          <CustomGridLayout
            successAction={successAction}
            title={strings.bookingInfo}
            config={bookingInfo}
          />
        )}
      />
    </>
  );
};

export default PendingCancellationDetails;
