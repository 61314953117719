import React from 'react';

import { Avatar } from '@mui/material';
import { Box } from '@mui/system';

// eslint-disable-next-line import/no-unresolved
import { MessageProfileProps } from '@sendbird/uikit-react/types/ui/MessageContent/MessageProfile';

import { userIdSelector } from '@pharmaplan/common';
import { useAppSelector } from '../../../../hooks/useAppSelector';

import styles from './styles';
import useAdmin from '../../../../hooks/useAdmin';

type senderTypeCast = {
  sender?: {
    profileUrl: string;
    userId: string;
  };
};

const SenderProfile = (props: MessageProfileProps) => {
  const myUserId = useAppSelector(userIdSelector);
  const { isAdmin } = useAdmin();

  const { message } = props ?? {};
  const { sender } = (message as senderTypeCast) ?? {};
  const { profileUrl, userId } = sender ?? {};

  const isSenderMe = userId === myUserId;

  if (isSenderMe) {
    return null;
  }

  return (
    <Box sx={styles.senderContainer}>
      <Avatar
        imgProps={{
          sx: styles.img,
        }}
        sx={styles.avatar}
        src={isAdmin ? profileUrl : 'https://app.pharmaplan.ca/logo192.png'}
      />
    </Box>
  );
};

export default SenderProfile;
