import { SxProps, Theme } from '@mui/system';

const styles = {
  title: {
    fontWeight: 600,
    fontSize: 18,
  },

  container: {
    ml: '16px',
  },

  titleContainer: {
    pt: '16px',
    display: 'flex',
    alignItems: 'center',
    mb: '24px',
  },

  gridContainer: {
    margin: 1,
    marginTop: 0,
    marginBottom: 0,
  },

  bookingLogs: {
    display: 'flex',
    alignItems: 'center',
  },

  pharmacistField: {
    minWidth: 292.24,
  },

  dropdownContainer: {
    ml: '10px',
  },

  autocomplete: {
    '& .MuiFormControl-root': {
      backgroundColor: 'secondary.main',
    },
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
