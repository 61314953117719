import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    marginBottom: '13px',
  },

  infoText: {
    color: 'common.grey850',
    fontSize: '13px',
    fontWeight: 400,
    fontStyle: 'italic',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
