import React, { FC, useCallback, useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import {
  HelpTypes,
  adminActions,
  adminPharmacistPictures,
  pharmacistDetails,
  PermissionsOfAdmin,
} from '@pharmaplan/common';
import { ReactComponent as StarFilled } from '@pharmaplan/common/assets/icons/starFilled.svg';

import ProfileHeader from '../../../ProfileHeader';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import {
  Constants,
  OtherScreens,
  ScreenTypes,
  SecondaryAdminNavigation,
  SkeletonVariants,
} from '../../../../../../helpers/Constants';
import TakeOwnership from '../../../../PendingActivationList/TakeOwnership';
import strings from '../../../../../../localization';
import usePharmacistProfileViewDetails from '../../../../../../hooks/Admin/Profile/usePharmacistViewDetails';
import WithSkeleton from '../../../../../common/WithSkeleton';
import CustomButton from '../../../../../common/CustomButton';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import {
  ICustomBack,
  renderScreen,
} from '../../../../../../actions/drawerActions';
import useGetItems from '../../../../../../hooks/useGetItems';
import useAdminPermissions from '../../../../../../hooks/Admin/useAdminPermissions';

import { IPharmacistViewProfile } from '../PharmacistViewProfile';
import classes from './style';

const { text } = Constants.variant;

interface IAdminPharmacistCard extends IPharmacistViewProfile {
  ratingGoBack?: ICustomBack;
  showActions?: boolean;
}

const { pendingActivation } = SecondaryAdminNavigation;
const { DeletedPendingUsers } = OtherScreens;
const { bookingList } = ScreenTypes;

const AdminPharmacistCard: FC<IAdminPharmacistCard> = ({
  fromScreen,
  pharmacistId,
  type,
  hasOwner,
  showActions = false,
  showMenu,
  ratingGoBack,
}) => {
  const dispatch = useAppDispatch();
  const { can } = useAdminPermissions();

  const details = useAppSelector(pharmacistDetails);

  const canActivate = can(PermissionsOfAdmin.Activation);

  const { pharmacist } = details ?? {};

  const {
    email,
    firstName,
    lastName,
    code,
    pharmacistId: responsePharmacistId,
    rating: { totalRating },
  } = pharmacist ?? {};

  const headerTitle = `${firstName} ${lastName}`;

  const fromPendingActivations = fromScreen === pendingActivation;
  const isDeletedPendingUser = fromScreen === DeletedPendingUsers;
  const isDeletedPharmacistList = fromScreen === OtherScreens.DeletedPharmacistList;

  const noActionsScreens = [
    DeletedPendingUsers,
    OtherScreens.PharmacyList,
    OtherScreens.incompatibleMatches,
    OtherScreens.AdminReports,
    OtherScreens.ListOfWorkshifts,
    OtherScreens.BroadcastGroups,
    OtherScreens.DeletedPendingUsers,
    OtherScreens.DeletedPharmacistList,
  ];

  const noActionsScreen = noActionsScreens.includes(fromScreen as OtherScreens);

  const isDeletedList = isDeletedPendingUser || isDeletedPharmacistList;

  const pId = isDeletedList ? responsePharmacistId : pharmacistId;

  const successAction = isDeletedList
    ? adminActions.deletedUserProfile
    : adminActions.getPharmacistDetails;

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const calcRating = ((totalRating ?? 0) * 5) / 100;

  const viewPharmacistProfile = () => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.profile,
        screenNumber: 2,
        pharmacistId,
        fromScreen,
      }),
    );
  };

  const viewAdminRating = () => {
    dispatch(
      renderScreen({
        screenNumber: 3,
        screenType: bookingList,
        pharmacistId: pId,
        type,
        back: ratingGoBack ?? viewPharmacistProfile,
      }),
    );
  };

  const Rating = useCallback(
    () =>
      (
        <SkeletonTypography sx={classes.ratingContainer}>
          <StarFilled width={11} height={11} />
          <SkeletonTypography sx={classes.ratingText}>
            {calcRating.toPrecision(2)}
          </SkeletonTypography>

          <CustomButton
            customButtonStyle={classes.customLink}
            label={`(${strings.viewRating})`}
            onClick={viewAdminRating}
            variant={text}
          />
        </SkeletonTypography>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calcRating, viewAdminRating],
  );

  const config = [
    {
      key: '0',
      value: <Typography sx={classes.codeFont}>{code}</Typography>,
    },
    {
      key: '1',
      value: <Rating />,
    },
  ];

  const { actions } = usePharmacistProfileViewDetails(fromScreen, hasOwner);
  const { getPharmacistImage } = useGetItems();

  const image = getPharmacistImage(pId);

  useEffect(() => {
    if (pharmacistId) {
      dispatch(adminPharmacistPictures([pId]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacistId]);

  return (
    <ProfileHeader
      successAction={successAction}
      avatar={image ?? ''}
      config={config}
      title={headerTitle}
      menuActions={
        noActionsScreen || !showActions
          ? null
          : actions(pharmacistId, type, ratingGoBack)
      }
      showMenu={showMenu}
      customFooter={(
        <Box sx={classes.footerContainer}>
          <Typography sx={classes.contactNameTitle}>{strings.email}</Typography>

          <SkeletonTypography sx={classes.contactName}>
            {email}
          </SkeletonTypography>

          {fromPendingActivations && !hasOwner && canActivate && (
            <TakeOwnership id={pharmacistId} userType={HelpTypes.pharmacist} />
          )}
        </Box>
      )}
    />
  );
};

export default AdminPharmacistCard;
