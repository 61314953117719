import React from 'react';
import { Link } from '@mui/material';
import { AdminTypes, IAllLanguages } from '@pharmaplan/common';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import {
  adminDropdown,
  Constants,
  telephoneInputMask,
} from '../../../../helpers/Constants';
import strings from '../../../../localization';
import styles from './styles';

const { text, dropdown, masked } = Constants.formInputType;

export const fieldConfig = (
  languages: Array<IAllLanguages>,
  onClick: (adminTypes: AdminTypes) => void,
) =>
  [
    {
      xs: 4,
      key: '1',
      name: 'email',
      label: strings.email,
      type: text,
      placeholder: ' ',
      customTextFieldContainer: styles.fieldsContainer,
    },
    {
      xs: 4,
      key: '2',
      name: 'username',
      placeholder: ' ',
      label: `${strings.user} ${strings.name}`,
      type: text,
    },
    {
      xs: 4,
      key: '-2',
      name: 'supportAgentName',
      placeholder: ' ',
      label: strings.supportAgentName,
      type: text,
    },
    {
      xs: 4,
      key: '3',
      name: 'phone',
      type: masked,
      mask: telephoneInputMask,
      label: `${strings.phone} ${strings.number}`,
    },
    {
      xs: 4,
      key: '4',
      name: 'languageId',
      label: strings.preferredLanguage,
      type: dropdown,
      menuItems: dropdownMapper(languages, 'languageId', 'name'),
      customTextFieldContainer: styles.fieldsContainer,
    },
    {
      xs: 4,
      key: '5',
      name: 'userType',
      label: strings.userType,
      type: dropdown,
      onClick,
      menuItems: adminDropdown.slice(1),
    },
  ];

const currScreen = (
  <Link
    key="curr"
    href="/admin/administrator/administrator-list"
    sx={[styles.noUnderline, styles.prevLink]}
  >
    {strings.administratorList}
  </Link>
);

export const addCrumbs = [
  currScreen,
  <Link
    href={Constants.paths.admin.createNewAdmin}
    sx={styles.noUnderline}
    key="add"
  >
    {strings.formatString(strings.addNewFormat, strings.administrator)}
  </Link>,
];

export const updateCrumbs = [
  currScreen,
  <Link
    sx={styles.noUnderline}
    href={Constants.paths.admin.updateAdmin}
    key="update"
  >
    {strings.edit}
    {' '}
    {strings.administrator}
  </Link>,
];
