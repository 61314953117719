import * as Yup from 'yup';

import { formikRequired } from '../SoftwareList/validator';

const Validator = () =>
  Yup.object().shape({
    name: formikRequired,
  });

export default Validator;
