import React, { FC, Fragment } from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProps } from 'formik';

import {
  adminBroadcastFilter,
  logos,
  publishedSoftwareList,
} from '@pharmaplan/common';
import {
  dropdownMapper,
  dropdownMinMaxMapper,
} from '@pharmaplan/common/helpers/utils';

import { useAppSelector } from '../../../../../hooks/useAppSelector';
import DefaultBanner from '../../../../../assets/svg/defaultBanner.svg';
import FilterSubmitButtons from '../../../../common/FilterSubmitButtons';
import { formGenerator } from '../../../../../helpers/formGenerator';
import SelectAllHeader from '../../../../DynamicTable/SelectAllHeader';

import CustomTableBack from '../../../../common/CustomTableBack';
import strings from '../../../../../localization';

import { filterConfig } from '../helper';
import styles from '../styles';

interface ICreateBroadcastGroupHeader {
  formik: FormikProps<any>;
  handleReset: () => void;
  selectAll: boolean;
  handleSelectAll: (toBeSet: boolean) => void;
  title: string | React.ReactNode;
}

const CreateBroadcastGroupHeader: FC<ICreateBroadcastGroupHeader> = ({
  formik,
  selectAll,
  handleSelectAll,
  handleReset,
  title,
}) => {
  const filter = useAppSelector(adminBroadcastFilter);
  const allLogos = useAppSelector(logos);
  const softwareList = useAppSelector(publishedSoftwareList);

  const { weekVolumes, weekendVolumes } = filter ?? {};
  const preprocessedLogos = allLogos.map((logo) =>
    ({
      ...logo,
      image: logo.image ?? DefaultBanner, // Provide a default image path
    }));

  return (
    <Box>
      <CustomTableBack backLabel={strings.backToBroadcastGroups} />
      <Typography sx={styles.backTitle}>{title}</Typography>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container alignItems="center">
          <>
            {filterConfig({
              type: formik.values.userType,
              logos: dropdownMapper(
                preprocessedLogos,
                'pharmacyChainId',
                'image',
              ),
              softwareItems: dropdownMapper(softwareList, 'softwareId', 'name'),
              weekendVolumeItems: dropdownMinMaxMapper(weekendVolumes, 'label'),
              weekVolumeItems: dropdownMinMaxMapper(weekVolumes, 'label'),
            }).map((item) =>
              (
                <Fragment key={item.key}>{formGenerator(item, formik)}</Fragment>
              ))}
          </>
          <FilterSubmitButtons handleReset={handleReset} />
        </Grid>
        <SelectAllHeader
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
        />
      </Box>
    </Box>
  );
};

export default CreateBroadcastGroupHeader;
