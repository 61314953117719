import React from 'react';

import { Box } from '@mui/system';

const EmptyBox = () =>
  (
    <Box component="div" />
  );

export default EmptyBox;
