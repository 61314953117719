import React, { useState } from 'react';

import { Button, Grid } from '@mui/material';
import MessageInputWrapper from '@sendbird/uikit-react/GroupChannel/components/MessageInputWrapper';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';

import useCannedMessages from '../../../hooks/Admin/Chat/useCannedMessages';
import { Constants } from '../../../helpers/Constants';
import strings from '../../../localization';

import styles from './styles';

const { variant: { outlined } } = Constants;

const CannedMessages = () => {
  const [show, setShow] = useState(true);
  const { sendUserMessage, channelUrl } = useGroupChannelContext();

  const cannedMessages = [
    strings.facingIssueWithApp,
    strings.knowMoreAboutFeatures,
    strings.updatePreviousIssue,
  ];

  const handleCannedMessageClick = (message: string) =>
    () => {
      setShow(false);
      sendUserMessage({ message });
    };

  useCannedMessages({ url: channelUrl, setShow });

  return (
    <>
      {show && (
        <Grid sx={styles.container} mb={1} container>
          {cannedMessages.map((msg) =>
            (
              <Grid item key={msg}>
                <Button
                  onClick={handleCannedMessageClick(msg)}
                  variant={outlined}
                  size="small"
                  sx={styles.button}
                >
                  {msg}
                </Button>
              </Grid>
            ))}
        </Grid>
      )}
      <MessageInputWrapper />
    </>
  );
};

export default CannedMessages;
