import { SxProps, Theme } from '@mui/system';

const classes = {
  customModalHeading: {
    color: 'primary.main',
    fontSize: '1.625rem',
    fontWeight: '600',
  },

  customTitleContainer: {
    padding: '20px 40px 22px 40px',
    marginBottom: '0px',
    borderBottom: '2px solid',
    color: 'common.border',
  },

  cancelText: {
    fontSize: '18px',
    fontWeight: 500,
    color: 'common.grey850',
    textAlign: 'center',
  },

  termsText: {
    fontSize: '18px',
    fontWeight: 500,
    textDecorationLine: 'underline',
    paddingRight: '4px',
    paddingLeft: '4px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
