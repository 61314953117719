import { Grid } from '@mui/material';

import React, { useEffect } from 'react';

import {
  userActions,
  successOrSelector,
  resetStatus,
  getTypeOfUser,
  pharmacistActions,
  getEnforceProfileUpdate,
  TypeOfUser,
} from '@pharmaplan/common';

import strings from '../../localization';
import AdsContainer from '../common/AdsContainer';
import ContentContainer from '../common/ContentContainer';

import ProfileSettings from './ProfileSettings';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

import { setDialog } from '../../reducers/dialogReducer';
import OutcomeModal from '../Modals/OutcomeModal';
import { OutcomeModalTypes } from '../../helpers/Constants';

import General from './PharmacistFlow/General';
import ProfessionalInfo from './PharmacistFlow/ProfessionalInfo';
import Softwares from './PharmacistFlow/Softwares';
import Contacts from './PharmacistFlow/Contacts';
import Geo from './PharmacistFlow/Geo';
import Primary from './PharmacyFlow/Primary';
import Accounting from './PharmacyFlow/Accounting';
import Secondary from './PharmacyFlow/Secondary';
import Coordinates from './PharmacyFlow/Coordinates';
import PharmacyInfo from './PharmacyFlow/PharmacyInfo';
import ResponsiveClasses from '../../theme/ResponsiveClasses';
import MyLocationOnMap from './PharmacistFlow/MyLocationOnMap/MyLocationOnMap';
import { setPageTitle } from '../../helpers/Functions';

const { pharmacist, pharmacy } = TypeOfUser;

const Profile = () => {
  const dispatch = useAppDispatch();

  const userType = useAppSelector(getTypeOfUser);
  const enforceProfileUpdate = useAppSelector(getEnforceProfileUpdate);
  const profileSaveSuccess = useAppSelector((state) =>
    successOrSelector(
      [
        userActions.generalUpdate,
        userActions.contactUpdate,
        userActions.updatePharmacyInfo,
        userActions.professionalInformation_update,
        userActions.softwareUpdate,
        userActions.updateCommonContact,
        userActions.coordinatesUpdate,
        pharmacistActions.updateRegions,
      ],
      state,
    ));

  const isPharmacy = userType === pharmacy;
  const isPharmacist = userType === pharmacist;

  useEffect(() => {
    if (profileSaveSuccess) {
      dispatch(
        setDialog({
          heading: {
            title: '',
          },
          Component: (
            <OutcomeModal
              type={OutcomeModalTypes.success}
              message={strings.profileSaved}
            />
          ),
          showCloseButton: true,
        }),
      );
      dispatch(resetStatus());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSaveSuccess]);

  const initSelectedTab = () => {
    if (isPharmacy) {
      return 'pharmacyInfo';
    }

    if (isPharmacist) {
      return 'general';
    }

    return '';
  };

  const [selected, setSelected] = React.useState(initSelectedTab());

  const showEnforceError = () => {
    dispatch(
      setDialog({
        showCloseButton: true,
        heading: {
          title: '',
        },
        Component: (
          <OutcomeModal
            type={OutcomeModalTypes.error}
            message={strings.updateOperationMessage}
          />
        ),
      }),
    );
  };

  const handleSelection = (screen: string) => {
    if (enforceProfileUpdate) {
      showEnforceError();
      return;
    }
    setSelected(screen);
  };

  const handleRender = () => {
    switch (selected) {
      case 'professionalInfo':
        return <ProfessionalInfo setSelected={setSelected} />;
      case 'general':
        return <General setSelected={setSelected} />;
      case 'software':
        return <Softwares setSelected={setSelected} />;
      case 'contacts':
        return <Contacts setSelected={setSelected} />;
      case 'geographicAvailability':
        return <Geo setSelected={setSelected} />;
      case 'myLocationOnMap':
        return <MyLocationOnMap setSelected={setSelected} />;
        // Pharmacy

      case 'primary':
        return <Primary setSelected={setSelected} />;
      case 'secondary':
        return <Secondary setSelected={setSelected} />;
      case 'accounting':
        return <Accounting setSelected={setSelected} />;
      case 'coordinates':
        return <Coordinates setSelected={setSelected} />;
      case 'pharmacyInfo':
        return <PharmacyInfo setSelected={setSelected} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setPageTitle(strings.profile);
  }, []);

  return (
    <Grid key={selected} container spacing={2}>
      {/* Settigns list */}
      <Grid item xs={3} lg={2}>
        <ProfileSettings selected={selected} onClick={handleSelection} />
      </Grid>
      {/* Content List */}
      <Grid item xs={9} lg={8}>
        <ContentContainer>{handleRender()}</ContentContainer>
      </Grid>
      {/* ads */}
      <Grid sx={ResponsiveClasses.hideOnLg} item lg={2}>
        <AdsContainer />
      </Grid>
    </Grid>
  );
};

export default Profile;
