import { SxProps, Theme } from '@mui/system';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  label: {
    color: '#4A5056',
    fontSize: 12,
    fontWeight: 500,
  },

  container: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    color: 'primary.main',
    marginRight: '8px',
  },

  emptyIcon: {
    color: 'primary.main',
  },

  percentage: {
    fontSize: 12,
    color: 'grey.500',
    minWidth: '45px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
