import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    backgroundColor: 'secondary.main',
    padding: '16px 24px',
    strokeWidth: '1px',
    stroke: 'var(--calendar-border, #E1E4EF)',
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.03))',
    border: '1px solid',
    borderColor: 'common.border',
    borderRadius: '6px',
  },
  titleContainer: {
    paddingLeft: '21px',
    borderLeft: '4px solid',
    borderColor: 'primary.main',
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    color: 'primary.main',
  },
  gridContainer: {
    padding: '21px',
  },
  text: {
    fontSize: '14px',
  },
  label: {
    color: 'common.blue600',
  },
  value: {
    color: 'grey.500',
  },
  image: {
    maxHeight: 35,
    maxWidth: 80,
    height: 'auto',
    width: '100%',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
