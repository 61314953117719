import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '24px',
    marginTop: '6px',
  },

  text: {
    fontSize: 13,
    lineHeight: '16px',
    textAlign: 'center',
    color: 'grey.500',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
