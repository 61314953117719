/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  coordinatesUpdate,
  getUserCoordinates,
  setAddress,
  setLocation,
  userAddress,
  userCoordinates,
  userLocation,
} from '@pharmaplan/common';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { CoordinatesValidator } from '../../FormValidation';
import { ICommonProfile } from '../../PharmacistFlow/General/General';
import ProfileForm from '../../ProfileForm';

const Coordinates = ({ setSelected }: ICommonProfile) => {
  const dispatch = useAppDispatch();
  const coordinates = useAppSelector(userCoordinates);
  const location = useAppSelector(userLocation);
  const getUserAddress = useAppSelector(userAddress);

  const formik = useFormik({
    initialValues: {
      ...coordinates,
    },
    onSubmit: (values) => {
      const filterdValues = { ...values };
      // @ts-ignore
      delete filterdValues.provinces;
      dispatch(
        coordinatesUpdate({
          ...filterdValues,
          latitude: location.latitude,
          longitude: location.longitude,
          address: values?.address,
        }),
      );
    },
    enableReinitialize: true,
    validationSchema: CoordinatesValidator(),
  });

  useEffect(() => {
    dispatch(getUserCoordinates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    getUserAddress.length > 0
      && formik.setFieldValue('address', getUserAddress);

    return () => {
      dispatch(setAddress(''));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserAddress]);

  useEffect(() => {
    dispatch(
      setLocation({
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      }),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates.latitude, coordinates.longitude]);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="coordinates"
      formik={formik}
    />
  );
};

export default Coordinates;
