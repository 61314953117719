import { SxProps, Theme } from '@mui/system';

const styles = {
  button: {
    height: 32,
    minWidth: 140,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
