import { SxProps, Theme } from '@mui/system';

const styles = {
  notesContainer: {
    paddingX: '24px',
    paddingY: '16px',
  },
  updateNoteContainer: {
    paddingX: '24px',
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    mb: '19px',
  },
  notesSubmitContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    paddingBottom: '24px',
  },
  button: {
    minWidth: 120,
  },
  stackContainer: {
    marginBottom: '20px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
