import { useEffect } from 'react';

import { geolocation } from '../helpers/Constants';

export interface Position {
  coords: {
    latitude: number;
    longitude: number;
  };
}

export interface PositionError {
  code: number;
  message: string;
}

const useUsersLocation = (
  successCallback: (positions: Position) => void,
  errorCallback?: (err: PositionError) => void,
  deps: Array<any> = [],
) => {
  const options: PositionOptions = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 5000,
  };

  const errors = (err: PositionError) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  const askLocationPermission = () => {
    if (geolocation in navigator) {
      navigator?.geolocation.getCurrentPosition(
        successCallback,
        errorCallback ?? errors,
        options,
      );
    }
  };

  useEffect(() => {
    askLocationPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useUsersLocation;
