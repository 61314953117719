const classes = {
  eventContainer: {
    borderLeft: '1px solid #E1E4EF',
    padding: '16px',
    marginTop: '10px',
  },
  dayContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '22px',
    color: '#96A0BC',
  },
  todayIndc: {
    borderBottom: '3px solid #11723B',
    width: '75%',
    paddingBottom: '5px',
    textAlign: 'center',
  },
  dayName: {
    fontWeight: '600',
  },
  paperPopover: {
    width: 550,
  },
};

export default classes;
