import * as Yup from 'yup';
import strings from '../localization';

export const allowanceValidator = () =>
  Yup.number()
    .typeError(strings.mustBeNumber)
    .nullable()
    .max(
      999.99,
      strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
    )
    .moreThan(
      -1,
      strings.pleaseEnterValidNumber,
    );

export const hourlyRateValidator = () =>
  Yup.number()
    .typeError(strings.mustBeNumber)
    .required(strings.requiredField)
    .moreThan(
      0,
      strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
    )
    .max(
      999.99,
      strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
    );
