import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { adminActions, successSelector, resetStatus } from '@pharmaplan/common';

import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import OutcomeModal from '../../../components/Modals/OutcomeModal';
import { Constants, OutcomeModalTypes } from '../../../helpers/Constants';
import { setDialog } from '../../../reducers/dialogReducer';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import strings from '../../../localization';

const {
  createPharmacyAdmin: createAction,
  editPharmacyAdmin: editAction,
  appointExistingPharmacy: appointAction,
} = adminActions;

const usePharmacyAdminActionsSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const createSuccess = useAppSelector((st) =>
    successSelector([createAction], st));
  const editSuccess = useAppSelector((st) =>
    successSelector([editAction], st));
  const appointSuccess = useAppSelector((st) =>
    successSelector([appointAction], st));

  const reset = () => {
    dispatch(resetStatus([createAction, editAction, appointAction]));
    navigate(-1);
  };

  useEffect(() => {
    if (createSuccess) {
      reset();
      showSuccess(dispatch, strings.pharmacyAdministratorCreatedSuccessfully);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);

  useEffect(() => {
    if (editSuccess) {
      reset();
      showSuccess(dispatch, strings.pharmacyAdministratorUpdatedSuccessfully);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSuccess]);

  useEffect(() => {
    if (appointSuccess) {
      dispatch(resetStatus([appointAction]));
      navigate(Constants.paths.admin.pharmacyAdministrators);
      dispatch(
        setDialog({
          showCloseButton: true,
          heading: {
            title: '',
          },
          Component: (
            <OutcomeModal
              type={OutcomeModalTypes.pharmacyAdminAppointedSuccess}
              message={strings.accountDetailsHaveBeenEmailed}
            />
          ),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointSuccess]);
};

export default usePharmacyAdminActionsSuccess;
