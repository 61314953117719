import { useEffect } from 'react';

import { chatActions, successSelector } from '@pharmaplan/common';
import GroupChannelHandler from '@sendbird/uikit-react/handlers/GroupChannelHandler';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';

import { IChannelData, IChannelURLData } from '../../../helpers/Constants';

import { useAppSelector } from '../../useAppSelector';

const uniqueId = 'groupHandler';

interface IUseMetadata {
  url: string;
  channelData: IChannelData;
  setChannelUrl: React.Dispatch<React.SetStateAction<IChannelData>>;
}
export declare type MetaData = {
  [key: string]: string;
};

const useMetadata = ({ url, setChannelUrl, channelData }: IUseMetadata) => {
  const store = useSendbirdStateContext();
  const sdk = store?.stores?.sdkStore?.sdk;

  const metadataSuccess = useAppSelector((state) =>
    successSelector([chatActions.addGroupChannelMetadata], state));

  const onMetaData = (res: MetaData) => {
    setChannelUrl((prev: IChannelData) =>
      ({
        ...prev,
        [url]: res as IChannelURLData,
      }));
  };

  useEffect(() => {
    const handleMeta = async () => {
      const channelInstance = await sdk?.groupChannel?.getChannelWithoutCache(
        url,
      );
      channelInstance.getAllMetaData().then(onMetaData);
    };
    handleMeta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, metadataSuccess]);

  useEffect(() => {
    const handleMetadataUpdate = (channel: any, metaData: MetaData) => {
      const { _url: inUrl } = channel ?? {};
      const inMeta = channelData?.[inUrl] ?? {};
      const updatedMeta = { ...inMeta, ...metaData };
      setChannelUrl((prev: IChannelData) =>
        ({
          ...prev,
          [inUrl]: updatedMeta,
        }));
    };

    const handler = new GroupChannelHandler({
      onMetaDataCreated: handleMetadataUpdate,
      onMetaDataUpdated: handleMetadataUpdate,
    });

    sdk.groupChannel.addGroupChannelHandler(uniqueId, handler);
    return () => {
      sdk.groupChannel.removeGroupChannelHandler(uniqueId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, channelData]);
};

export default useMetadata;
