import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    width: '100%',
  },
  navButton: {
    marginRight: 0,
    width: '100%',
  },
  selectDates: {
    ml: 2,
    mb: 2,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
