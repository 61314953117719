import { SxProps, Theme } from '@mui/system';

const styles = {
  titleFont: {
    fontWeight: 600,
    fontSize: 16,
    color: 'grey.800',
  },

  container: {
    marginBottom: '12px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '16px',
  },

  titleContainer: {
    marginBottom: '8px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
