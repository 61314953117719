import React, { FC, useState } from 'react';

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProps } from 'formik';

import { SocialLoginType } from '@pharmaplan/common';
import { ReactComponent as GoogleEmailIcon } from '../../../assets/svg/googleEmailIcon.svg';
import { ReactComponent as PharmaPlanEmailIcon } from '../../../assets/svg/pharmaplanEmail.svg';
import { ReactComponent as AppleEmailIcon } from '../../../assets/svg/appleLogoSocial.svg';
import { ReactComponent as FacebookEmailIcon } from '../../../assets/svg/facebookEmailIcon.svg';

import { Constants } from '../../../helpers/Constants';
import strings from '../../../localization';
import CustomButton from '../CustomButton';
import CustomTextField from '../CustomTextField';
import useChangeEmail from '../../../hooks/Admin/useChangeEmail';

import styles from './styles';

interface IChangeEmailField {
  formik: FormikProps<any>;
  fieldName: string;
  xs: number;
  preventChangeEmail?: boolean;
}

const { text } = Constants.variant;
const { facebook, google, apple } = SocialLoginType;
const pharmaplan = 0;

const ChangeEmailField: FC<IChangeEmailField> = ({
  formik,
  fieldName,
  xs,
  preventChangeEmail,
}) => {
  const loginType = formik.values.signupType ?? pharmaplan;

  const [disabled, setDisabled] = useState(true);

  const { confirmChangeEmail } = useChangeEmail(setDisabled, loginType);

  const icons = {
    [facebook]: FacebookEmailIcon,
    [google]: GoogleEmailIcon,
    [pharmaplan]: PharmaPlanEmailIcon,
    [apple]: AppleEmailIcon,
  };

  const Icon = icons[loginType as keyof typeof icons];

  return (
    <Grid xs={xs} container>
      <Box sx={styles.boxContainer}>
        <Box sx={styles.fieldContainer}>
          <CustomTextField
            customTextFieldStyle={styles.field}
            startAdornment={(
              <Box sx={styles.startAdornment}>
                <Icon />
              </Box>
            )}
            formik={formik}
            label={strings.email}
            id="email"
            disabled={disabled}
            name={fieldName}
          />
        </Box>

        {!preventChangeEmail && (
          <Box sx={styles.buttonContainer}>
            <CustomButton
              onClick={confirmChangeEmail}
              label={strings.edit}
              variant={text}
              customButtonStyle={styles.buttonLabel}
            />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default ChangeEmailField;
