import { useEffect } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  AdminAllowanceNames,
  adminApproveBooking,
  adminApproveData,
  bookAvailabilityData,
  bookAvailablity,
  getApproveDetails,
  getBookAvailablity,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';

import { IApproveBooking } from '../../components/Admin/ApproveBooking/ApproveBooking';
import {
  renderScreen,
  resetDrawer,
  setBack,
} from '../../actions/drawerActions';
import { OtherScreens, ScreenTypes } from '../../helpers/Constants';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import useSelfService from '../useSelfService';
import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../localization';
import { rateValidation } from '../../helpers/Functions';

const keyToParse = [
  AdminAllowanceNames.emergencyFees,
  AdminAllowanceNames.travelAllowance,
  AdminAllowanceNames.mealAllowance,
  AdminAllowanceNames.accommodationAllowance,
  AdminAllowanceNames.pharmacistHourlyRate,
  AdminAllowanceNames.pharmacyHourlyRate,
];

const { bookAvailablity: bookAvailablityAction, approveBooking } = adminActions;

const useApproveBooking = ({
  workshiftId,
  bookingId,
  fromScreen,
  type,
  eventIds,
  back,
}: IApproveBooking) => {
  const dispatch = useAppDispatch();
  const { workshiftParser } = useSelfService();

  const approveDetails = useAppSelector(adminApproveData);
  const bookAvailability = useAppSelector(bookAvailabilityData);

  const bookingSuccess = useAppSelector((state) =>
    successSelector([approveBooking], state));
  const bookingAvailabilitySuccess = useAppSelector((state) =>
    successSelector([bookAvailablityAction], state));

  const isBookAvailability = fromScreen === OtherScreens.MatchingAvailabilities
    || fromScreen === OtherScreens.MatchingWorkshifts;

  const onSubmit = (values: FormikValues) => {
    const {
      accommodationAllowance: parsedAccommodationAllowance,
      travelAllowance: parsedTravelAllowance,
      mealAllowance: parsedMealAllowance,
      emergencyFees: parsedEmergencyFees,
      pharmacistHourlyRate: parsedPharmacistHourlyRate,
      pharmacyHourlyRate,
    } = workshiftParser(values, keyToParse) ?? {};

    const {
      pharmaId: formikPharmaId,
      notesForSalesOrder,
      sendEmailToPharmacist,
      sendEmailToPharmacy,
    } = values ?? {};

    const formikValues = {
      accommodationAllowance: parsedAccommodationAllowance,
      travelAllowance: parsedTravelAllowance,
      mealAllowance: parsedMealAllowance,
      emergencyFees: parsedEmergencyFees,
      pharmacistHourlyRate: parsedPharmacistHourlyRate,
      pharmacyHourlyRate,
      bookingId,
      pharmaId: formikPharmaId,
      notesForSalesOrder,
      sendEmailToPharmacist,
      sendEmailToPharmacy,
    };

    const isPharmacyGreaterPharmacist = rateValidation({
      pharmacistRate: parsedPharmacistHourlyRate,
      pharmacyRate: pharmacyHourlyRate,
      dispatch,
    });

    if (!isPharmacyGreaterPharmacist) {
      return;
    }

    if (isBookAvailability) {
      dispatch(
        bookAvailablity({
          ...formikValues,
          workshiftId,
          availabilityId: bookingId,
        }),
      );
    } else {
      dispatch(adminApproveBooking({ ...formikValues }));
    }
  };

  const config = isBookAvailability
    ? {
      success: bookingAvailabilitySuccess,
      dispatchAction: () =>
        dispatch(
          getBookAvailablity({ workshiftId, availabilityId: bookingId }),
        ),
      approveDetails: bookAvailability,
      onSubmit,
    }
    : {
      success: bookingSuccess,
      dispatchAction: () =>
        dispatch(getApproveDetails(bookingId)),
      approveDetails,
      onSubmit,
    };

  const { success, approveDetails: details, dispatchAction } = config ?? {};

  const goToRequestPharmacist = () => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.requestedList,
        screenNumber: 2,
        eventId: workshiftId,
      }),
    );
  };

  const goToRequestedDetails = () => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.requestedList,
        screenNumber: 3,
        workshiftId,
        eventId: bookingId,
        eventIds,
      }),
    );
  };

  const goToMatchingAvailabilities = () => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.availableWorkshift,
        screenNumber: 4,
        eventId: workshiftId,
        type,
      }),
    );
  };

  const goBack = () => {
    switch (fromScreen) {
      case OtherScreens.MatchingAvailabilities:
        return goToMatchingAvailabilities;
      case OtherScreens.AdminCalendar:
        return goToRequestPharmacist;
      case OtherScreens.AdminMap:
        return goToRequestedDetails;
      default:
        return goToMatchingAvailabilities;
    }
  };

  useEffect(() => {
    dispatchAction();
    if (fromScreen !== OtherScreens.AdminRequestedReports) {
      dispatch(setBack(back ?? goBack()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, workshiftId]);

  useEffect(() => {
    if (success) {
      dispatch(resetStatus([bookAvailablityAction, approveBooking]));
      dispatch(resetDrawer());
      showSuccess(dispatch, strings.approvedSuccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return { details, onSubmit };
};

export default useApproveBooking;
