import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const classes = {
  confirmContainer: {
    display: 'flex',
    backgroundColor: 'eventChip.posted',
    borderRadius: '10px',
    padding: '10px',
    marginBottom: '6px',
  },

  goodNewsText: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'eventChip.postedText',
  },

  confirmText: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'common.grey850',
    marginLeft: '6px',
  },

  autoRejectText: {
    fontSize: '11px',
    fontStyle: 'italic',
    fontWeight: 400,
    color: 'common.grey850',
    paddingLeft: '4px',
  },

  newsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
  },

  confirm: {
    display: 'flex',
    alignItems: 'center',
  },

  infoContainer: {
    display: 'flex',
    marginBottom: '12px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
