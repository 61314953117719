import React, { useEffect } from 'react';
import {
  adminActions,
  adminDeleteContactPreference,
  adminDeletePharmacistCapacities,
  adminDeletePharmacyChains,
  adminDeleteSoftwares,
  resetStatus,
  successOrSelector,
} from '@pharmaplan/common';
import { FormikValues } from 'formik';
import { useAppDispatch } from '../useAppDispatch';
import {
  DeactivateModalIcon,
  DeleteConfirmationType,
} from '../../helpers/Constants';
import DeactivateDialog from '../../components/Admin/PharmacistList/DeactivateDialog';
import strings from '../../localization';
import { setDialog } from '../../reducers/dialogReducer';
import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import { useAppSelector } from '../useAppSelector';

const { pharmacistCapacities, softwares, pharmacyChains, contactPreferences } = DeleteConfirmationType;

const { deletePharmacistCapacities, deletePharmacyChains, deleteSoftwares, deleteContactPreference } = adminActions;

const deleteActions = [
  deletePharmacistCapacities,
  deletePharmacyChains,
  deleteSoftwares,
  deleteContactPreference,
];

const useDeleteConfirmation = (deletionType: DeleteConfirmationType) => {
  const dispatch = useAppDispatch();

  const deleteSuccess = useAppSelector((state) =>
    successOrSelector(deleteActions, state));

  const handleDelete = (values: FormikValues) => {
    const { id, reason } = values ?? {};

    switch (deletionType) {
      case pharmacistCapacities:
        dispatch(
          adminDeletePharmacistCapacities({ pharmacistCapacityId: id, reason }),
        );
        break;
      case pharmacyChains:
        dispatch(adminDeletePharmacyChains({ pharmacyChainId: id, reason }));
        break;
      case softwares:
        dispatch(adminDeleteSoftwares({ softwareId: id, reason }));
        break;
      case contactPreferences:
        dispatch(adminDeleteContactPreference({ contactPreferenceId: id, reason }));
        break;
      default:
        break;
    }
  };

  const showDeleteConfirmation = (id: string) => {
    dispatch(
      setDialog({
        showCloseButton: true,
        Component: (
          <DeactivateDialog
            id={id}
            heading={strings.deleteConfiguration}
            confirmationMessage={strings.confirmDeleteRecord}
            iconType={DeactivateModalIcon.warning}
            onSubmit={handleDelete}
          />
        ),
        heading: {
          title: '',
        },
      }),
    );
  };

  useEffect(() => {
    if (deleteSuccess) {
      showSuccess(dispatch, strings.deletedSuccessfully);
      dispatch(resetStatus(deleteActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  return { showDeleteConfirmation };
};

export default useDeleteConfirmation;
