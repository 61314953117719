import React, { FC } from 'react';

import { Box, Grid, Rating, Typography } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import styles from './style';

interface ILabelRating {
  label: string;
  percentage: string;
  value: number;
}

const LabelRating: FC<ILabelRating> = ({ label, percentage, value }) => {
  const rating = (value / 100) * 5;

  return (
    <Grid item xs={6} lg={3}>
      <Box sx={styles.container}>
        <Typography sx={styles.label}>{label}</Typography>
        <Box sx={styles.infoContainer}>
          <Rating
            name="rating"
            sx={styles.iconRating}
            value={rating}
            precision={0.5}
            readOnly
            emptyIcon={<StarBorderIcon sx={styles.emptyIcon} />}
          />
          <Typography sx={styles.percentage}>{percentage}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default LabelRating;
