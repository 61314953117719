const classes = {
  profileFormContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#96A0BC',
    fontWeight: '600',
  },
  profileTitleLine: {
    flex: 1,
    height: '1px',
    marginLeft: '10px',
    backgroundColor: '#96A0BC',
  },
  buttonContainer: {
    justifyContent: 'end',
    paddingTop: '22px',
  },
  mainHeader: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
  },
};

export default classes;
