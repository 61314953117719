import React from 'react';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomLink from '../../CustomLink';
import classes from './style';
import strings from '../../../../localization';
import { Constants } from '../../../../helpers/Constants';

const BackToLogin = () =>
  (
    <Box sx={classes.iconContainer}>
      <ArrowBackIcon color="primary" sx={classes.backIcon} />
      <CustomLink linkText={strings.backToLogin} to={Constants.paths.login} />
    </Box>
  );

export default BackToLogin;
