import { createAsyncThunk } from "@reduxjs/toolkit";
import MobileRegController, {
  IDeviceDetails,
  IUpdateDetails,
} from "../controllers/MobileRegController";
import { statusDispatch } from "./globalActions";

export const mobileRegistrationActions = {
  registerMobile: "mobile/register",
  getByRegId: "mobile/get/regId",
  getByDeviceId: "mobile/get/deviceId",
  updateDeviceToken: "mobile/update/deviceToken",
};

export const registerMobile = createAsyncThunk(
  mobileRegistrationActions.registerMobile,
  async (deviceDetails: IDeviceDetails, { rejectWithValue }) => {
    try {
      const resp = await MobileRegController.mobileRegisteration(deviceDetails);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getByRegId = createAsyncThunk(
  mobileRegistrationActions.getByRegId,
  async (regId: string, { rejectWithValue }) => {
    try {
      const resp = await MobileRegController.fetchByRegId(regId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getByDeviceId = createAsyncThunk(
  mobileRegistrationActions.getByDeviceId,
  async (deviceId: string, { rejectWithValue }) => {
    try {
      const resp = await MobileRegController.fetchByDeviceId(deviceId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const updateDeviceToken = createAsyncThunk(
  mobileRegistrationActions.updateDeviceToken,
  async (updateDetails: IUpdateDetails, { rejectWithValue }) => {
    try {
      const resp = await MobileRegController.fetchUpdateDeviceToken(updateDetails);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
