import { SxProps, Theme } from '@mui/system';

const size = 76;

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '18px',
  },

  avatar: {
    width: size,
    height: size,
    border: '2px solid',
    color: 'common.border',
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    justifyContent: 'center',
  },

  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  pharmacist: {
    fontWeight: 600,
    fontSize: 20,
    color: 'common.grey850',
  },

  chipContainer: {
    paddingBottom: 10,
  },

  requestedText: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'common.grey850',
  },

  customLink: {
    fontWeight: 500,
    fontSize: '14px',
    paddingLeft: '4px',
  },

  ratingContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '18px',
  },

  ratingText: {
    fontWeight: 500,
    fontSize: '14px',
    paddingLeft: '2px',
  },

  avatarContainer: {
    display: 'flex',
    position: 'relative',
  },

  favIconContainer: {
    position: 'absolute',
    top: 60,
    left: 50,
    zIndex: 1,
  },

  pharmacyCard: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '6px',
  },

  confirmTime: {
    fontSize: '14px',
    fontWeight: 400,
    paddingLeft: '6px',
    color: 'common.grey850',
  },

  titleContainer: {
    display: 'flex',
  },

  iconContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  rightArrow: {
    alignSelf: 'center',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
