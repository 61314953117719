import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    marginBottom: '12px',
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: '10px',
  },
  labelContainer: {
    display: 'flex',
    marginBottom: '12px',
  },
  label: {
    fontSize: 13,
    flex: 1,
    color: 'grey.600',
    marginRight: '8px',
  },
  value: {
    fontSize: 14,
    flex: 1,
    color: 'common.grey850',
    fontWeight: '500',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
