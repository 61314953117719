import React, { FC } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AvatarFallback from '@pharmaplan/common/assets/icons/avatarPlaceholder.png';
import { IBookingRequests } from '../BookingRequests';
import strings from '../../../../../localization';
import classes from './styles';

const BookingRequestsContent: FC<IBookingRequests> = ({
  requestedCount = 0,
  profileImage,
  onClick,
}) => {
  const imageConfig = requestedCount === 0 ? 1 : requestedCount;
  const numberOfAvatars = Math.min(imageConfig, 3);

  const numberOfRequests = () => {
    switch (true) {
      case requestedCount === 0:
        return strings.noRequests;
      case requestedCount === 1:
        return strings.formatString(strings.numberRequest, requestedCount);
      case requestedCount <= 3:
        return strings.formatString(strings.numberRequests, requestedCount);
      case requestedCount > 3:
        return strings.formatString(strings.otherRequests, requestedCount - 3);
      default:
        return '';
    }
  };

  return (
    <Box sx={classes.container}>
      <AvatarGroup spacing="small">
        {Array.from({ length: numberOfAvatars }, (_, index) =>
          (
            <Avatar
              key={index}
              sx={classes.image}
              src={profileImage?.[index] ?? AvatarFallback}
              imgProps={{ style: { objectFit: 'cover' } }}
            />
          ))}
      </AvatarGroup>

      <Box sx={classes.buttonContainer}>
        <Typography style={classes.text}>{numberOfRequests()}</Typography>

        <IconButton onClick={onClick}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default BookingRequestsContent;
