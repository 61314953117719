import * as Yup from 'yup';
import { formikRequired } from '../../SoftwareList/validator';

const Validator = () =>
  Yup.object().shape({
    startTime: formikRequired,
    endTime: formikRequired,
    selectedDate: formikRequired,
  });

export default Validator;
