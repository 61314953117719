// Web app's Firebase configuration
const {
  REACT_APP_PUSH_API_KEY,
  REACT_APP_VAPID_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_MEASUREMENT_ID,
} = process.env;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const apiKey = REACT_APP_PUSH_API_KEY ?? '';
const vapidKey = REACT_APP_VAPID_KEY;

export const firebaseInit = {
  apiKey,
  authDomain: REACT_APP_AUTH_DOMAIN ?? '',
  projectId: REACT_APP_PROJECT_ID ?? '',
  storageBucket: REACT_APP_STORAGE_BUCKET ?? '',
  messagingSenderId: REACT_APP_MESSAGING_ID ?? '',
  appId: REACT_APP_FIREBASE_APP_ID ?? '',
  measurementId: REACT_APP_MEASUREMENT_ID ?? '',
};

export const vapidConfig = { vapidKey };
