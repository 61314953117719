const classes = {

  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },

  subContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: '16px',
    justifyContent: 'center',
  },

  title: {
    paddingBottom: '4px',
    fontSize: '12px',
    color: 'grey.800',
  },

  value: {
    // display: 'flex',
    // flexDirection: 'column',
    fontSize: '14px',
    fontWeight: '500',
    color: 'grey.800',
  },

};

export default classes;
