import React, { FunctionComponent, SVGProps } from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';

import classes from './styles';

interface ITitleProps {
  title: string;
  onClose: () => void;
  CloseBtn: FunctionComponent<SVGProps<SVGSVGElement>>;
  customStyle?: { titleImage?: object; titleContainer: object; title: object };
  image?: any;
}
const defaultProps = {
  customStyle: classes,
  image: null,
};

const WorkshiftTitle = ({
  title,
  onClose,
  CloseBtn,
  customStyle,
  image,
}: ITitleProps) =>
  (
    <Grid container sx={customStyle?.titleContainer}>
      <Box sx={classes.mainContainer} component="span">
        {image && (
        <img style={customStyle?.titleImage} src={image} alt="pharmacy icon" />
        )}
        <Typography sx={customStyle?.title}>
          {title}
        </Typography>
      </Box>

      <IconButton onClick={onClose}>
        <CloseBtn />
      </IconButton>
    </Grid>
  );
export default WorkshiftTitle;

WorkshiftTitle.defaultProps = defaultProps;
