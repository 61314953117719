import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { SocialLoginType } from '@pharmaplan/common';

const decodeToken = (jwt: unknown):string => {
  if (jwt && typeof jwt === 'object' && 'email' in jwt) {
    return jwt.email as string;
  }
  return '';
};

export const getUserDetails = (code: string) =>
  axios
    .post('https://oauth2.googleapis.com/token', {
      code,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_SECRET,
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
      grant_type: 'authorization_code',
    })
    .then((res) =>
      ({
        token: res?.data.id_token,
        email: decodeToken(jwtDecode(res?.data.id_token)),
        loginType: SocialLoginType.google,
      }))
    .catch((err) => {
      console.log(err);
      return { token: null, email: null, loginType: null };
    });

export const getFacebookDetails = async (
  userID: string,
  accessToken: string,
) => {
  try {
    const userData = await axios.get(
      `https://graph.facebook.com/${userID}?fields=id,name,email&access_token=${accessToken}`,
    );
    return {
      token: accessToken,
      email: userData?.data.email,
      loginType: SocialLoginType.facebook,
    };
  } catch (err) {
    console.log(err);
    return { token: null, email: null, loginType: null };
  }
};
