import { createReducer } from "@reduxjs/toolkit";
import { logout } from "../actions";
import { setProps } from "../actions/bottomSheetActions";
import { BottomSheetTypes } from "../helpers/Constants";

export interface IBottomSheetState {
  type?: BottomSheetTypes;
  open: boolean;
  customStyle?: any;
  title?: string;
  extraProps?: any;
}

const initialState: IBottomSheetState = {
  open: false,
};

const bottomSheetReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setProps, (state, action) => {
      state.title = action.payload.title;
      state.customStyle = action.payload.customStyle;
      state.type = action.payload.type;
      state.extraProps = action.payload.extraProps;
      state.open = action.payload.open;
    })
    //reset all on logout
    .addCase(logout.fulfilled, () => initialState);
});

export default bottomSheetReducer;
