import React, { FC, useEffect } from 'react';

import { Box, Stack } from '@mui/system';

import {
  adminActions,
  AllowanceTypes,
  getPharmacistBooking,
  pharmacistBooking,
  PharmacistLegendVariant,
  TypeOfBill,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { ReactComponent as Calendar } from '@pharmaplan/common/assets/icons/calendarIconNew.svg';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import BillDetails from '../../SelfService/BillDetails';
import PharmacyCard from '../../SelfService/PharmacyCard';
import useGetItems from '../../../hooks/useGetItems';
import ServiceCard from '../../SelfService/ServiceCard';
import CustomGridLayout from '../../common/CustomGridLayout';
import strings from '../../../localization';
import { getDayMonthDateYear } from '../../Reports/helpers';
import ThemeConstants from '../../../theme/ThemeConstants';
import { getHourMinuteSeconds } from '../../../helpers/Functions';
import CustomButton from '../../common/CustomButton';
import { renderScreen, setBack } from '../../../actions/drawerActions';
import { Constants, mapUrl, ScreenTypes } from '../../../helpers/Constants';

import styles from './styles';
import AdminHourlyRateFooter from '../../common/AdminHourlyRateFooter';

const { text } = Constants.variant;
interface IPharmacyBookingDetails {
  bookingId: string;
  pharmacistId: string;
}

const successAction = adminActions.getPharmacistBooking;
const { custom } = AllowanceTypes;

const PharmacyBookingDetails: FC<IPharmacyBookingDetails> = ({
  bookingId,
  pharmacistId,
}) => {
  const dispatch = useAppDispatch();
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const { getImage, getLanguageName } = useGetItems();

  const details = useAppSelector(pharmacistBooking);
  const {
    logoId,
    paidHourlyRate,
    pharmacyName,
    createDate,
    address,
    province,
    region,
    number,
    contactName,
    languageId,
    latitude,
    softwareName,
    longitude,
    contactEmail,
    contactPhone,
    accommodationCosts,
    pharmacyHourlyRate,
    pharmacistHourlyRate,
  } = details ?? {};

  const goBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.pharmacistList,
        pharmacistId,
      }),
    );
  };

  useEffect(() => {
    dispatch(getPharmacistBooking(bookingId));
    dispatch(setBack(goBack));

    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generalConfig = [
    { key: strings.name, value: pharmacyName },
    { key: strings.bannerNumber, value: number },
    { key: strings.preferredLanguage, value: getLanguageName(languageId) },
    {
      key: strings.formatString(strings.createdFormat, strings.date) as string,
      value: getDayMonthDateYear(createDate),
      adornments: <Calendar fill={ThemeConstants.common.grey850} />,
    },
    {
      key: strings.formatString(strings.createdFormat, strings.time) as string,
      value: getHourMinuteSeconds(createDate, timeFormat),
    },
    {
      key: strings.software,
      value: softwareName,
    },
  ];

  const location = [address, province, region].filter(Boolean).join(', ');

  const contactConfig = [
    { key: strings.contactName, value: contactName },
    { key: strings.number, value: contactPhone },
    { key: strings.email, value: contactEmail },
    { key: strings.location, value: location },
  ];

  const openMap = () => {
    window.open(mapUrl(latitude, longitude), '_blank');
  };

  const fullEvent = {
    ...details,
    accommodationAllowanceType: custom,
    travelAllowanceType: custom,
    mealAllowanceType: custom,
    accommodationAllowance: accommodationCosts,
  };

  return (
    <Stack>
      <PharmacyCard
        hideBorder
        showPharmacyName
        type={PharmacistLegendVariant.requested}
        CustomContent={<Box sx={styles.empty} />}
        event={{
          ...details,
          hourlyRate: paidHourlyRate,
          logoImage: getImage(logoId) ?? '',
        }}
        CustomFooter={(
          <AdminHourlyRateFooter
            pharmacyHourlyRate={pharmacyHourlyRate ?? 0}
            pharmacistHourlyRate={pharmacistHourlyRate}
            successAction={successAction}
          />
        )}
        successAction={successAction}
      />
      <BillDetails
        billType={TypeOfBill.bill}
        event={fullEvent}
        successAction={successAction}
      />
      <ServiceCard
        customContainerStyle={styles.serviceContainer}
        content={(
          <CustomGridLayout
            successAction={successAction}
            title={strings.generalDetails}
            config={generalConfig}
          />
        )}
      />
      <ServiceCard
        customContainerStyle={styles.serviceContainer}
        hideDivider
        content={(
          <CustomGridLayout
            successAction={successAction}
            title={strings.contactDetails}
            config={contactConfig}
          />
        )}
        footer={(
          <CustomButton
            customClass={styles.buttonContainer}
            customButtonStyle={styles.button}
            variant={text}
            label={strings.viewPharmacyLocation}
            onClick={openMap}
          />
        )}
      />
    </Stack>
  );
};

export default PharmacyBookingDetails;
