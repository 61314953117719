import { LOADING, NOT_STARTED, ERROR, SUCCESS } from '../helpers/Status';
import { StatusTypes } from '../reducers/statusReducer';
import { IStore } from '../types/IStore';

export const statusSelector = (action: string, state: IStore) =>
  state.status[action];

export const hasStatusSelector = (
  action: string,
  status: StatusTypes,
  state: IStore
) => state.status[action] === status;

export const notStartedSelector = (actions: string[], state: IStore) =>
  actions.reduce((prevState, value) => {
    const status = state.status[`${value}`] || NOT_STARTED;
    return prevState && status === NOT_STARTED;
  }, true);

export const isLoadingSelector = (actions: string[], state: IStore) =>
  actions.reduce((prevState, value) => {
    const status = state.status[`${value}`] || NOT_STARTED;
    return prevState || status === LOADING;
  }, false);

export const hasErrorsSelector = (actions: string[], state: IStore) =>
  actions.reduce((prevState, value) => {
    const status = state.status[`${value}`] || NOT_STARTED;
    return prevState || status === ERROR;
  }, false);

export const successSelector = (actions: string[], state: IStore) =>
  actions.reduce((prevState, value) => {
    const status = state.status[`${value}`] || NOT_STARTED;
    return prevState && status === SUCCESS;
  }, true);

export const successOrSelector = (actions: string[], state: IStore) =>
  actions.reduce((prevState, value) => {
    const status = state.status[`${value}`] || NOT_STARTED;
    return prevState || status === SUCCESS;
  }, false);

export const fullStatusSelector = (action: string[], state: IStore) => {
  const status = state.status[`${action}`];
  const error = state.error[`${action}`];
  const isLoading = status === LOADING;
  return {
    status,
    isLoading,
    error,
  };
};