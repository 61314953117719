import React, { FC, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import { isValidOtp, resetValidOtp } from '@pharmaplan/common';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { Constants } from '../../../helpers/Constants';
import useAuthOtp from '../../../hooks/useAuthOtp';
import useChangeEmail from '../../../hooks/Admin/useChangeEmail';

import Otp from './Otp';

const OTP_LENGTH = 6;

interface IInitialValues {
  otp: string;
}

const initialValues: IInitialValues = {
  otp: '',
};

const { paths } = Constants;
const { newPassword } = paths;

interface IOtpForm {
  auth?: boolean;
}

const OtpForm: FC<IOtpForm> = ({ auth }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validOtp = useAppSelector(isValidOtp);
  const { state } = useLocation();

  const { resendOtp, verifyOtp, desc } = useAuthOtp(auth);
  const { prematureLogout } = useChangeEmail();

  const [disable, setDisable] = useState({
    button: true,
    link: true,
  });

  const { email } = state ?? {};

  const onSubmit = (values: IInitialValues) => {
    const { otp } = values ?? {};

    dispatch(verifyOtp({ email, otp }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const { values } = formik ?? {};
  const { otp } = values ?? {};

  const isSameOTPLength = otp.length === OTP_LENGTH;

  const resend = () => {
    dispatch(resendOtp(email));
  };

  useEffect(() => {
    setDisable((prev) =>
      ({
        ...prev,
        button: !isSameOTPLength,
      }));

    if (isSameOTPLength) {
      dispatch(verifyOtp({ email, otp }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp.length]);

  const success = () => {
    if (auth) {
      prematureLogout(email);
    } else {
      navigate(newPassword, { state: { email }, replace: true });
    }
  };

  useEffect(() => {
    if (validOtp) {
      success();
      dispatch(resetValidOtp());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validOtp]);

  return (
    <Otp
      desc={desc}
      resendFunc={resend}
      disable={disable}
      setDisable={setDisable}
      formik={formik}
    />
  );
};

export default OtpForm;
