import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const dotSize = 7;

const styles = {
  container: {
    display: 'flex',
    backgroundColor: ThemeConstants.secondary.main,
    borderBottom: `0.4px solid ${ThemeConstants.common.border}`,
    padding: '12px 16px',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
  },
  name: {
    fontSize: 12,
    fontWeight: '600',
    color: ThemeConstants.common.grey850,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    ml: '3px',
    fontSize: 11,
    color: ThemeConstants.grey[800],
  },
  avatarContainer: {
    position: 'relative',
    maxHeight: 36,
  },
  coverStyle: {
    objectFit: 'cover',
  },
  containStyle: {
    objectFit: 'contain',
  },
  avatar: {
    minWidth: 32,
    maxHeight: 32,
    maxWidth: 32,
    width: '100%',
    border: `1px solid ${ThemeConstants.common.border}`,
  },
  activeDot: {
    position: 'absolute',
    border: `2px solid ${ThemeConstants.secondary.main}`,
    height: dotSize,
    width: dotSize,
    borderRadius: dotSize / 2,
    bottom: 0,
    right: 0,
    backgroundColor: ThemeConstants.primary.main,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
