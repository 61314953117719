import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, Link } from '@mui/material';
import { preLoginLang } from '@pharmaplan/common';
import strings from '../../localization';
import classes from './style';
import { Constants } from '../../helpers/Constants';
import { useAppSelector } from '../../hooks/useAppSelector';

const Footer = () => {
  const lang = useAppSelector(preLoginLang);

  const legalSection = [
    {
      key: 1,
      linkText: strings.termsOfService,
      href: Constants.paths.staticPages[
        lang as keyof typeof Constants.paths.staticPages
      ].termsOfServices,
    },
    {
      key: 2,
      linkText: strings.privacyPolicy,
      href: Constants.paths.staticPages[
        lang as keyof typeof Constants.paths.staticPages
      ].privacyPolicy,
    },
    {
      key: 3,
      linkText: strings.siteMap,
      href: Constants.paths.staticPages[
        lang as keyof typeof Constants.paths.staticPages
      ].siteMap,
    },
  ];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <Grid sx={classes.container} container component="footer">
      <Grid sx={classes.copyRightContainer} item>
        <Typography component="span" sx={classes.copyRightText}>
          {strings.formatString(strings.copyRight, currentYear)}
        </Typography>
        <Typography component="span" sx={classes.copyRightText}>
          <Link href={Constants.zoomJarUrl} underline="hover">
            {strings.zoomJar}
          </Link>
        </Typography>

        <Typography component="span" sx={classes.copyRightText}>
          {strings.rightsReserved}
        </Typography>
      </Grid>
      <Grid sx={classes.legalContainer} item>
        {legalSection.map((item, index) => {
          const isLastElement = index !== legalSection.length - 1;

          return (
            <Fragment key={item.key}>
              <Link href={item.href} sx={classes.footerText} underline="hover">
                {item.linkText}
              </Link>
              <Box sx={classes.verticleLineContainer}>
                {isLastElement && <div style={classes.verticalLine} />}
              </Box>
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Footer;
