import React, { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Moment } from 'moment';
import 'react-calendar/dist/Calendar.css';

import {
  activeDate,
  setActiveWebView,
  CalendarStateKey,
  TypeOfUser,
  getActiveWebView,
  successOrSelector,
  pharmacistActions,
  pharmacyActions,
  reset,
  getNearbyProps,
  AvailabilityTypes,
  WatchlistedVariants,
} from '@pharmaplan/common';
import { ReactComponent as FilledFilter } from '@pharmaplan/common/assets/icons/searchIconGreen.svg';
import { ReactComponent as UnfilledFilter } from '@pharmaplan/common/assets/icons/searchIcon.svg';
import { ReactComponent as CloseFilter } from '@pharmaplan/common/assets/icons/close.svg';
import { ReactComponent as PlusIcon } from '@pharmaplan/common/assets/icons/webPlus.svg';

import CachedIcon from '@mui/icons-material/Cached';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/deleteIcon.svg';

import CustomDropdown from '../../common/CustomDropdown';
import CustomButton from '../../common/CustomButton';
import CustomCheckbox from '../../common/CustomCheckbox';
import Watchlisted from '../../common/Watchlist/Watchlisted';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useAdmin from '../../../hooks/useAdmin';
import useUser from '../../../hooks/useUser';
import useNearbyPharmacies from '../../../hooks/Toolbar/useNearbyPharmacies';
import useAdminToolbarReset from '../../../hooks/Admin/useAdminToolbarReset';
import useMultipleSave from '../../../hooks/useMultipleSave';
import useMultipleDelete from '../../../hooks/useMultipleDelete';
import useAdminContinueCreation from '../../../hooks/Admin/useAdminContinueCreation';

import {
  setMultipleAddEvents,
  setTempEvents,
} from '../../../reducers/mainCalendarReducer';
import { resetDialog } from '../../../reducers/dialogReducer';
import {
  getMultipleEventAdd,
  getTempEvents,
} from '../../../selectors/calendarSelector';
import ThemeConstants from '../../../theme/ThemeConstants';
import { MonthWeek } from '../../../helpers/Functions';
import { Constants } from '../../../helpers/Constants';
import genericClasses from '../../../theme/GenericClasses';
import strings from '../../../localization';

import DashboardFilter from './DashboardFilter';
import DayCalendar from './DayCalendar';
import YearCalendar from './YearCalendar';
import NavigateButtons from './NavigateButtons';
import CalendarMapToggle from './CalendarMapToggle';
import AdminDashboardFilter from './AdminDashboardFilter';
import classes from './style';
import ToolbarAdd from './ToolbarAdd';

export interface IOnNavigate {
  setDate: (date: Moment) => void;
}

interface IToolbarProps {
  title: string | Array<string>;
}

const { pharmacist } = TypeOfUser;

const Toolbar = ({ title }: IToolbarProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { userType } = useUser();

  const tempEvents = useAppSelector(getTempEvents);

  const { isAdmin } = useAdmin();
  const { showConfirmModal } = useAdminContinueCreation();

  const {
    deleteSelectedAvailabilities,
    getDeletedAvailabilities,
    getDeletedVacations,
    handleDeleteReset,
  } = useMultipleDelete();

  const { handleSave } = useMultipleSave();

  const dayCalendarRef = useRef<{ reset:() => void }>();
  const ref = useRef<{ reset:() => void; clear: () => void }>();
  const nearbyProps = useAppSelector(getNearbyProps);

  const [filterState, setFilterState] = useState({
    submitted: false,
    showFilter: false,
  });
  const { checkForLocation } = useNearbyPharmacies();
  const date = useAppSelector(activeDate);
  const activeView = useAppSelector(getActiveWebView);
  const multipleEventAdd = useAppSelector(getMultipleEventAdd);

  useAdminToolbarReset({ filterState, setFilterState });

  const success = useAppSelector((st) =>
    successOrSelector(
      [
        pharmacistActions.createAvailability,
        pharmacyActions.createWorkshift,
        pharmacistActions.vacations,
      ],
      st,
    ));

  const { submitted, showFilter } = filterState;
  const { pathname } = location;
  const {
    paths: { mapDashboard, admin: adminPaths },
  } = Constants;

  const { adminMapDashboard } = adminPaths;

  const isPharmacist = userType === pharmacist;
  const pharmacistToggle = pathname === mapDashboard;
  const adminMapToggle = pathname === adminMapDashboard;
  const mapToggle = isAdmin ? adminMapToggle : pharmacistToggle;

  const hideMonthWeek = !mapToggle;

  const showMap = isPharmacist || isAdmin;

  const shouldShowSelectButton = multipleEventAdd;

  const disableSave = tempEvents.filter(
    (item: { type: string }) =>
      item.type === AvailabilityTypes.tempAvailability,
  ).length === 0;

  const disableDelete = getDeletedAvailabilities().length === 0
    && getDeletedVacations().length === 0;
  const disableReset = disableSave && isAdmin && disableDelete;

  const formik = useFormik({
    initialValues: {
      calendarState: activeView.toLowerCase(),
    },
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const handleReset = () => {
    dispatch(setTempEvents([]));
    handleDeleteReset();
  };

  const handleFilterState = () => {
    setFilterState({
      ...filterState,
      showFilter: !showFilter,
    });
  };

  const filterStates = () => {
    switch (true) {
      case !submitted && !showFilter:
        return (
          <IconButton onClick={handleFilterState}>
            <UnfilledFilter id="filter" />
          </IconButton>
        );
      case !submitted && showFilter:
        return (
          <IconButton
            onClick={() => {
              handleFilterState();
              ref.current?.clear();
            }}
          >
            <CloseFilter fill={ThemeConstants.grey[800]} />
          </IconButton>
        );
      case submitted:
        return (
          <IconButton onClick={handleFilterState}>
            <FilledFilter />
          </IconButton>
        );
      default:
        return null;
    }
  };

  const {
    values: { calendarState },
  } = formik ?? {};

  useEffect(() => {
    if (success && multipleEventAdd) {
      handleReset();
      dispatch(resetDialog());
      dispatch(reset());
    }

    return () => {
      dispatch(setMultipleAddEvents(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dispatch(setActiveWebView(calendarState as CalendarStateKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarState, date]);

  const showFilterCheck = !adminMapToggle && showMap && !multipleEventAdd;

  return (
    <Box sx={classes.toolbarContainer}>
      {!isAdmin && (
        <Box sx={classes.toolbarHeadingContainer}>
          <Typography variant="h5" sx={classes.calendarHeading}>
            {title}
          </Typography>
          <Box sx={genericClasses.flex}>
            {showFilterCheck && (
              <Box sx={classes.filterCont}>
                {submitted && (
                  <CustomButton
                    customClass={classes.clearButton}
                    label={strings.clear}
                    variant="outlined"
                    onClick={() => {
                      ref?.current?.reset();
                      dayCalendarRef.current?.reset();
                    }}
                  />
                )}

                {isPharmacist && (
                  <Watchlisted variant={WatchlistedVariants.interactive} />
                )}

                <Box sx={classes.searchIcon}>{filterStates()}</Box>
              </Box>
            )}
            <ToolbarAdd />
          </Box>
        </Box>
      )}

      {isPharmacist && (
        <DashboardFilter
          filterState={filterState}
          setFilterState={setFilterState}
          ref={ref}
        />
      )}

      <Box sx={classes.navigateDateContainer}>
        <Box sx={classes.navigateDate}>
          <Box
            id="calendarViewDropdown"
            component="div"
            sx={classes.navigationToggle}
          >
            {mapToggle ? (
              <DayCalendar ref={dayCalendarRef} setFiltered={setFilterState} />
            ) : (
              <YearCalendar />
            )}
          </Box>
          <NavigateButtons />
        </Box>

        <Box component="div" sx={classes.toggleViewContainer}>
          {shouldShowSelectButton && (
            <Box sx={classes.multipleSelectButtons}>
              <CustomButton
                startIcon={<CachedIcon />}
                customClass={classes.resetButton}
                onClick={handleReset}
                disabled={disableReset}
                variant={disableReset ? 'contained' : 'outlined'}
                label={strings.reset}
                size="small"
              />

              <CustomButton
                customClass={classes.resetButton}
                startIcon={<PlusIcon />}
                onClick={showConfirmModal(handleSave)}
                disabled={disableSave}
                label={strings.save}
                size="small"
              />

              {isAdmin && (
                <CustomButton
                  size="small"
                  customClass={classes.resetButton}
                  startIcon={
                    <DeleteIcon stroke={ThemeConstants.secondary.main} />
                  }
                  onClick={deleteSelectedAvailabilities}
                  disabled={disableDelete}
                  label={strings.delete}
                  color="error"
                />
              )}
            </Box>
          )}

          {isAdmin && showFilterCheck && (
            <Box sx={classes.filterCont}>
              {submitted && (
                <CustomButton
                  customClass={classes.clearButton}
                  label={strings.clear}
                  variant="outlined"
                  onClick={() => {
                    ref?.current?.reset();
                    dayCalendarRef.current?.reset();
                  }}
                />
              )}
              <Box sx={classes.searchIcon}>{filterStates()}</Box>
            </Box>
          )}

          {mapToggle && (
            <CustomCheckbox
              label={strings.pharmaciesNearby}
              name="toggle"
              value={nearbyProps.showNearBy}
              labelClass={classes.checkboxLabel}
              handleChange={checkForLocation}
              id="toggle"
            />
          )}

          {showMap && <CalendarMapToggle />}

          {hideMonthWeek && (
            <CustomDropdown
              id="monthWeek"
              name="calendarState"
              formik={formik}
              customClass={classes.dropdownCustomHeight}
              menuItems={MonthWeek()}
              placeholder={Constants.null}
            />
          )}
        </Box>
      </Box>
      {!mapToggle && isAdmin && filterState.showFilter && (
        <Box sx={classes.adminFilterContainer}>
          <AdminDashboardFilter
            filterState={filterState}
            setFilterState={setFilterState}
            ref={ref}
          />
        </Box>
      )}
    </Box>
  );
};

export default Toolbar;
