import { PharmacistLegendVariant, PharmacyLegendVariant } from '@pharmaplan/common';
import strings from '../../../../localization';

const { availability } = PharmacistLegendVariant;
const { posted, requested } = PharmacyLegendVariant;

export const updateTitle = (isSelfService: boolean) => {
  if (isSelfService) {
    return {
      [availability]: strings.updateAvailability,
    };
  }

  return {
    [availability]: strings.updateAvailability,
    [posted]: strings.updateWorkshift,
    [requested]: strings.updateWorkshift,
  };
};
