import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import styles from './styles';
import WithSkeleton from '../WithSkeleton';

interface IConfig {
  key: string;
  value: string | null;
  adornments?: React.ReactNode;
}

interface ICustomGridLayout {
  config: Array<IConfig>;
  title?: string;
  successAction: string;
}

const CustomGridLayout: FC<ICustomGridLayout> = ({
  config,
  title,
  successAction,
}) => {
  const SkeletonTypography = WithSkeleton({ successAction, variant: 'text' })(
    Typography,
  );
  return (
    <>
      {title && <Typography sx={styles.title}>{title}</Typography>}
      <Grid container columnSpacing={5} rowSpacing={3}>
        {config.map((item) => {
          const { key, value, adornments } = item ?? {};
          return (
            <Grid key={key} xs={6} item>
              <SkeletonTypography sx={styles.key}>{key}</SkeletonTypography>
              <Box sx={styles.valueContainer}>
                {adornments && (
                  <Box sx={styles.adornmentContainer}>{adornments}</Box>
                )}
                {!!value && (
                <SkeletonTypography sx={styles.value}>
                  {value}
                </SkeletonTypography>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default CustomGridLayout;
