import { SxProps, Theme } from '@mui/system';

const classes = (color: string) =>
  ({
    title: {
      fontWeight: '600',
      fontSize: 18,
      color: 'common.grey850',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 3,
    },
    tableContainer: {
      boxSizing: 'border-box',
      marginLeft: 2,
      marginRight: 2,
      paddingBottom: 0,
      paddingTop: 0,
      width: '97%',
      textAlign: 'center',
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between;',
      alignItems: 'center',
      paddingBotton: '10px',
    },
    titleText: {
      fontWeight: '600',
      fontSize: 14,
      color: 'common.grey850',
    },
    tableCellAction: {
      padding: '0', marginRight: '10px',
    },
    cell: {
      minWidth: 50,
      maxWidth: 120,
    },
    cellFontColor: {
      color,
    },
    imageCell: {
      height: 'auto',
      maxHeight: '80px',
      width: 'auto',
      maxWidth: '80px',
      alignItems: 'center',
    },
    button: {
      color: 'grey.500',
      borderColor: 'grey.500',
    },
    titleImage: {
      height: 'auto',
      maxHeight: '95px',
      width: 'auto',
      marginRight: '10px',
      maxWidth: '95px',
    },
    requestButton: {
      width: 200,
      padding: 1,
    },
    requestedButton: {
      width: 200,
      padding: 1,
      '&.MuiButton-outlined': {
        color: 'eventChip.requestedText',
        border: '1px solid',
      },
    },
  } satisfies Record<string, SxProps<Theme>>);

export default classes;
