import { getUserAccountingContact, updatePharmactContact, userAccountingContact } from '@pharmaplan/common';
import { userInitialState } from '@pharmaplan/common/reducers/userReducer';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { AccountingValidator } from '../../FormValidation';
import { ICommonProfile } from '../../PharmacistFlow/General/General';
import ProfileForm from '../../ProfileForm';

const Accounting = ({ setSelected }:ICommonProfile) => {
  const dispatch = useAppDispatch();
  const accounting = useAppSelector(userAccountingContact);

  const formik = useFormik({
    initialValues: { ...userInitialState?.accountingContact },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: (values) =>
      dispatch(updatePharmactContact({ values, type: 'accountingcontact' })),
    enableReinitialize: true,
    validationSchema: AccountingValidator(),
  });

  useEffect(() => {
    dispatch(getUserAccountingContact());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.setValues({ ...accounting });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounting]);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="accounting"
      formik={formik}
    />
  );
};

export default Accounting;
