import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = {
  iconContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '32px',
  },
  girdContainer: {
    paddingX: '24px',
  },
  reason: {
    fontSize: 18,
    fontWeight: '500',
    color: 'grey.500',
    marginBottom: '24px',
  },
  reasonSubmitContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    paddingBottom: '24px',
  },
  buttons: {
    width: 130,
  },
  cancelButton: {
    color: 'black',
    mr: '15px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headingFont: {
    fontSize: 24,
    color: 'grey.500',
    paddingBottom: '8px',
  },
  confirmationFont: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
  bottomGap: {
    marginBottom: '24px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
