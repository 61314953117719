import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    minHeight: '100vh',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    padding: '10px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
