import { createReducer } from '@reduxjs/toolkit';
import {
  resetSecondaryRoute,
  setPrimaryRoute,
  setSecondaryRoute,
} from '../actions/navbarActions';
import {
  PrimaryAdminNavigation,
  SecondaryAdminNavigation,
} from '../helpers/Constants';

interface INavbarState {
  primaryRoute: PrimaryAdminNavigation | null;
  secondaryRoute: SecondaryAdminNavigation | null;
}

const initialState: INavbarState = {
  secondaryRoute: null,
  primaryRoute: PrimaryAdminNavigation.dashboard,
};

export const navbarReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSecondaryRoute, (state, action) => {
      state.secondaryRoute = action.payload;
    })

    .addCase(resetSecondaryRoute, (state) => {
      state.secondaryRoute = null;
    })
    .addCase(setPrimaryRoute, (state, action) => {
      state.primaryRoute = action.payload;
    });
});

export default navbarReducer;
