import React, { useEffect } from 'react';
import {
  ConfirmationTypes,
  getTypeOfUser,
  ignoreRequest,
  pharmacistEvent,
  pharmacyActions,
  successOrSelector,
} from '@pharmaplan/common';
import { useAppDispatch } from '../useAppDispatch';
import { resetDialog, setDialog } from '../../reducers/dialogReducer';
import OutcomeModal from '../../components/Modals/OutcomeModal';
import { OutcomeModalTypes } from '../../helpers/Constants';
import strings from '../../localization';
import PharmacyPostedList from '../../components/Modals/WorkshiftDetails/PharmacyPostedList';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import { useAppSelector } from '../useAppSelector';

const useServiceWorkshiftDetailsModals = () => {
  const dispatch = useAppDispatch();

  const event = useAppSelector(pharmacistEvent);
  const userType = useAppSelector(getTypeOfUser);

  const { pharmacyId, logoImage } = event ?? {};

  const approveRequestSuccess = useAppSelector((state) =>
    successOrSelector([pharmacyActions.approveRequest], state));

  const ignoreRequestSuccess = useAppSelector((state) =>
    successOrSelector([pharmacyActions.ignoreRequest], state));

  const { rejectBooking } = ConfirmationTypes;
  const { congrats } = OutcomeModalTypes;

  const showConfirmationModal = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <OutcomeModal type={congrats} message={strings.confirmedBookings} />
        ),
        showCloseButton: true,
      }),
    );
  };

  const showPostedList = () => {
    dispatch(
      setDialog({
        Component: (
          <PharmacyPostedList pharmacyLogo={logoImage} id={pharmacyId} />
        ),
        showCloseButton: false,
        maxWidth: 'md',
      }),
    );
  };

  const confirmAction = (id: string) => {
    dispatch(
      setDialog({
        showCloseButton: false,
        Component: (
          <ConfirmationModal
            type={rejectBooking}
            confirmAction={() =>
              dispatch(
                ignoreRequest({ userType, bookingId: id as unknown as string }),
              )}
          />
        ),
        heading: {
          title: '',
        },
      }),
    );
  };

  useEffect(() => {
    if (approveRequestSuccess) {
      showConfirmationModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveRequestSuccess]);

  useEffect(() => {
    if (ignoreRequestSuccess) {
      dispatch(resetDialog());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreRequestSuccess]);

  return { showConfirmationModal, showPostedList, confirmAction };
};

export default useServiceWorkshiftDetailsModals;
