import React, { useEffect } from 'react';

import { useFormik } from 'formik';

import {
  IAllLanguages,
  getEmail,
  getLanguages,
  getUserGeneral,
  language,
  successSelector,
  updateUserGeneral,
  userActions,
  userGeneral,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useLanguage from '../../../../hooks/useLanguge';
import { GeneralValidator } from '../../FormValidation';
import ProfileForm from '../../ProfileForm';

export interface ICommonProfile {
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const General = ({ setSelected }: ICommonProfile) => {
  const dispatch = useAppDispatch();
  const { changeLang } = useLanguage();

  const general = useAppSelector(userGeneral);
  const currentLang = useAppSelector(language);
  const languagesArr = useAppSelector(getLanguages);
  const email = useAppSelector(getEmail);

  const generalSaveSuccess = useAppSelector((state) =>
    successSelector([userActions.generalUpdate], state));

  const formik = useFormik({
    initialValues: {
      ...general,
      email,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: (values) => {
      dispatch(updateUserGeneral(values));
    },
    enableReinitialize: true,
    validationSchema: GeneralValidator(),
  });

  useEffect(() => {
    if (generalSaveSuccess) {
      const userSelectedLang = languagesArr.filter(
        (lang: IAllLanguages) =>
          lang.languageId === formik?.values.preferdLanguage,
      );

      const isCurrentAndUserLangSame = userSelectedLang[0]?.code === currentLang;

      if (!isCurrentAndUserLangSame) {
        changeLang(userSelectedLang[0]?.code || currentLang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSaveSuccess]);

  useEffect(() => {
    dispatch(getUserGeneral());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileForm setSelected={setSelected} selected="general" formik={formik} />
  );
};

export default General;
