import {
  PharmacistLegendVariant,
  AdminLegendVariant,
  PermissionsOfAdmin,
  PermissionsRemovalKeys,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import { renderScreen } from '../../actions/drawerActions';
import { ScreenTypes } from '../../helpers/Constants';
import strings from '../../localization';

import { useAppDispatch } from '../useAppDispatch';
import { serverToday, timeString } from '../../helpers/Functions';
import useWorkshiftDelete from '../Admin/useWorkshiftDelete';
import useAdminPermissions from '../Admin/useAdminPermissions';
import { removeBasedOnPermissions } from '../../helpers/permissionsHelper';
import { getDayMonthDateYear } from '../../components/Reports/helpers';
import { useAppSelector } from '../useAppSelector';

export type genericFunction = () => void;
export type IWorkshiftId = { workshiftId: string };
type IViewDetails = {
  workshiftId: string;
  date: string;
  pharmacyId: string;
};

type IWatchlistViewDetails = {
  workshiftId: string;
  pharmacyId: string;
  pharmacistId: string;
  isRequested: boolean;
};

export const menuActions = (
  editAction: genericFunction,
  viewDetailsAction: genericFunction,
  deleteWorkshiftAction: genericFunction,
  matchingAvailabilitiesAction: genericFunction,
  expressBookingAction: genericFunction,
) =>
  [
    {
      key: '1',
      label: strings.viewDetails,
      onClick: viewDetailsAction,
    },
    {
      key: '2',
      label: strings.edit,
      onClick: editAction,
    },
    {
      key: PermissionsRemovalKeys.Delete,
      label: strings.delete,
      onClick: deleteWorkshiftAction,
    },
    {
      key: '4',
      label: strings.matchingAvailabilities,
      onClick: matchingAvailabilitiesAction,
    },
    {
      key: PermissionsRemovalKeys.ExpressBooking,
      label: strings.expressBooking,
      onClick: expressBookingAction,
    },
  ];

const useAvailableWorkshiftActions = () => {
  const dispatch = useAppDispatch();
  const today = serverToday().toISO({ includeOffset: false }) ?? '';
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const { can } = useAdminPermissions();

  const { showWorkshiftDelete } = useWorkshiftDelete();

  const goToEditWorkshift = ({ workshiftId }: IWorkshiftId) => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.availableWorkshift,
        screenNumber: 2,
        eventId: workshiftId,
      }),
    );
  };

  const goToAvailableWorkshift = (date: string) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.availableWorkshift,
          date,
        }),
      );
    };

  const goToViewDetails = ({ workshiftId, date, pharmacyId }: IViewDetails) => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.availableWorkshift,
        screenNumber: 3,
        eventId: workshiftId,
        back: goToAvailableWorkshift(date),
        pharmacyId,
      }),
    );
  };

  const goToWatchlists = (pharmacistId: string) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.adminWatchlists,
          screenNumber: 1,
          pharmacistId,
        }),
      );
    };

  const watchlistViewDetails = ({ workshiftId, pharmacyId, pharmacistId, isRequested }: IWatchlistViewDetails) => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.availableWorkshift,
        screenNumber: 3,
        eventId: workshiftId,
        type: isRequested ? AdminLegendVariant.requested : AdminLegendVariant.posted,
        back: goToWatchlists(pharmacistId),
        pharmacyId,
      }),
    );
  };

  const goToMatchingAvailabilities = ({ workshiftId }: IWorkshiftId) => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.availableWorkshift,
        screenNumber: 4,
        eventId: workshiftId,
        type: PharmacistLegendVariant.workshift,
      }),
    );
  };

  const goToExpressBooking = ({ workshiftId }: IWorkshiftId) => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.availableWorkshift,
        screenNumber: 5,
        eventId: workshiftId,
        type: AdminLegendVariant.posted,
      }),
    );
  };

  const actions = ({
    id,
    startDate,
    endDate,
    pharmacyId,
  }: {
    id: string;
    startDate: string;
    endDate: string;
    pharmacyId: string;
  }) => {
    const date = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);
    const dateTime = `${date} | ${time}`;

    let menu = menuActions(
      () =>
        goToEditWorkshift({ workshiftId: id }),
      () =>
        goToViewDetails({ workshiftId: id, date: startDate, pharmacyId }),
      () =>
        showWorkshiftDelete(id, dateTime),
      () =>
        goToMatchingAvailabilities({ workshiftId: id }),
      () =>
        goToExpressBooking({ workshiftId: id }),
    );

    if (date < today) {
      menu = menu.slice(0, 1);
    }

    menu = removeBasedOnPermissions(
      menu,
      [PermissionsOfAdmin.ExpressBooking, PermissionsOfAdmin.Delete],
      can,
    );

    return menu;
  };

  return {
    actions,
    goToViewDetails,
    watchlistViewDetails,
  };
};

export default useAvailableWorkshiftActions;
