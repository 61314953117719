import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const styles = {
  title: {
    color: ThemeConstants.grey[800],
    fontSize: 16,
    fontWeight: '600',
    mb: '16px',
  },
  valueContainer: {
    display: 'flex',
  },
  adornmentContainer: {
    mr: 1,
  },
  key: {
    color: ThemeConstants.common.grey850,
    fontSize: 12,
  },
  value: {
    color: ThemeConstants.common.grey850,
    wordBreak: 'break-word',
    fontWeight: '600',
    marginTop: '2px',
    fontSize: 14,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
