const classes = {
  root: {
    display: 'flex',
    marginTop: '7px',
    marginBottom: '10px',
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
  },
  priceContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  title: {
    fontWeight: 400,
    color: 'grey.800',
    fontSize: '16px',
  },
  price: {
    fontWeight: 600,
    color: 'grey.800',
    fontSize: '16px',
  },
  pharmacyCounterPrice: {
    fontWeight: 600,
    color: 'primary.main',
    fontSize: '16px',
  },
  strike: {
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    paddingRight: '5px',
    textDecoration: 'line-through',
    color: 'grey.800',
  },
};

export default classes;
