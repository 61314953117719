import {
  getSoftware,
  ISoftware,
  selectUserSoftware,
  updateSoftware,
} from '@pharmaplan/common';
import { FormikValues, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import ProfileForm from '../../ProfileForm';
import { ICommonProfile } from '../General/General';

const Softwares = ({ setSelected }: ICommonProfile) => {
  const dispatch = useAppDispatch();
  const softwares = useAppSelector(selectUserSoftware);

  const onSubmit = (values: FormikValues) => {
    const software = Object.keys(values)?.map((item) =>
      ({
        softwareId: item,
        expLevel: values[item as keyof typeof values] as number,
      }));
    dispatch(updateSoftware(software as ISoftware[]));
  };

  const formik = useFormik({
    initialValues: [],
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    dispatch(getSoftware());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.setValues(
      softwares?.reduce(
        (obj: any, item: { softwareId: string; expLevel: number }) =>
          Object.assign(obj, { [item?.softwareId]: item?.expLevel ?? 1 }),
        {},
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [softwares]);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="software"
      formik={formik}
    />
  );
};

export default Softwares;
