import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    position: 'fixed',
    bottom: 80,
    right: 80,
  },
  groupChannelContainer: {
    height: 588,
    width: 560,
  },
  floatingContainer: {
    position: 'fixed',
    bottom: 40,
    right: 40,
  },
  floating: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    backgroundColor: '#d32f2f',
    justifyContent: 'center',
    alignItems: 'center',
    height: 15,
    width: 15,
    borderRadius: 8,
  },
  text: {
    color: 'white',
    fontWeight: '500',
    fontSize: 10,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
