import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = {
  serviceContainer: {
    pt: '15px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
