import React, { useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';

import {
  addEmailPassword,
  successSelector,
  userActions,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';
import LoginCustomCard from '../../common/LoginCustomCard';
import { ReactComponent as ResetPasswordIcon } from '../../../assets/svg/resetPasswordIcon.svg';
import CustomTextField from '../../common/CustomTextField';
import { InputTypes } from '../../../helpers/Constants';
import genericClasses from '../../../theme/GenericClasses';

import Validator from './Validator';
import { classes } from './styles';
import useChangeEmail from '../../../hooks/Admin/useChangeEmail';

const { cardStyle, infoHeading } = classes ?? {};

const AddEmailPassword = () => {
  const dispatch = useAppDispatch();

  const { state } = useLocation();
  const email = state ?? {};

  const success = useAppSelector((st) =>
    successSelector([userActions.addEmailPassword], st));

  const handleSubmit = (values: FormikValues) => {
    const { password } = values ?? {};
    dispatch(addEmailPassword({ email, password }));
  };

  const { showSessionReset } = useChangeEmail();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleSubmit,
    validationSchema: Validator,
  });

  const textArray = [
    {
      key: 1,
      text: strings.createNewPw,
      sx: infoHeading,
    },
  ];

  const pwPlaceholder = strings.formatString(
    strings.enterCred,
    strings.password,
  );
  const confirmPwPlaceholder = strings.formatString(
    strings.enterCred,
    strings.confirmPassword,
  );

  useEffect(() => {
    if (success) {
      showSessionReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <LoginCustomCard
      customCard={cardStyle}
      icon={<ResetPasswordIcon />}
      textArray={textArray}
      buttonTitle={strings.setPassword}
      onClick={formik.handleSubmit}
    >
      <CustomTextField
        name="password"
        type={InputTypes.password}
        customClass={genericClasses.loginMargins}
        formik={formik}
        placeholder={pwPlaceholder}
        id="password"
        label={strings.password}
        showStrentghBar
        hint={strings.minCharacters}
      />
      <CustomTextField
        name="confirmPassword"
        type={InputTypes.password}
        formik={formik}
        placeholder={confirmPwPlaceholder}
        id="confirmPassword"
        label={strings.confirmPassword}
      />
    </LoginCustomCard>
  );
};

export default AddEmailPassword;
