import React, { useCallback, useEffect, useState } from 'react';
import {
  ConfirmationTypes,
  preRequest,
  requestBooking,
  resetPreRequest,
  selectPreRequest,
} from '@pharmaplan/common';
import { useAppSelector } from './useAppSelector';
import strings from '../localization';
import { useAppDispatch } from './useAppDispatch';
import { resetDialog, setDialog } from '../reducers/dialogReducer';
import ConfirmationModal from '../components/Modals/ConfirmationModal/ConfirmationModal';
import SoftwareWarning from '../components/SelfService/ServiceWorkshiftDetails/SoftwareWarning/SoftwareWarning';

const usePreRequest = () => {
  const dispatch = useAppDispatch();

  const [id, setId] = useState('');

  const preRequestStatus = useAppSelector(selectPreRequest);
  const { isSufficientSoftwareKnowledge, softwareLevel, name } = preRequestStatus ?? {};

  const softwarePercentage = (softwareLevel - 1) * 25;
  const softwareWarningMsg = strings.formatString(
    strings.softwareWarning,
    name,
    `${softwarePercentage}`,
  );

  const successAction = () => {
    dispatch(requestBooking(id));
    dispatch(resetDialog());
  };

  const showSoftwareWarning = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.softwareWarning}
            customMessage={<SoftwareWarning message={softwareWarningMsg} />}
            showCloseButton
            confirmAction={successAction}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  const runSoftwareCheck = useCallback(() => {
    if (id) {
      if (isSufficientSoftwareKnowledge === false) {
        showSoftwareWarning();
      } else if (isSufficientSoftwareKnowledge === true) {
        dispatch(requestBooking(id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSufficientSoftwareKnowledge]);

  useEffect(() => {
    runSoftwareCheck();
    dispatch(resetPreRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSufficientSoftwareKnowledge]);

  const preRequestBooking = (workshiftId: string) => {
    setId(workshiftId);
    dispatch(preRequest(workshiftId));
  };

  return { preRequestBooking };
};

export default usePreRequest;
