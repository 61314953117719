import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    paddingX: '24px',
    paddingBottom: '24px',
    flexDirection: 'column',
  },
  itemContainer: {
    marginBottom: '10px',
  },
  labelContainer: {
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: 'grey.800',
  },
  buttonContainer: {
    display: 'flex',
    marginRight: '6px',
    justifyContent: 'flex-end',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
