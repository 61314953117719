//@ts-nocheck
export const isLoggedIn = 'isLoggedIn';

const isLocalStorageAvailable = () => {
  var test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

const LogoutHandler = () => {
  const isLocalStorage = isLocalStorageAvailable();
  
  const userIsLoggedIn = () => {
    if (isLocalStorage) {
      localStorage.setItem(isLoggedIn, 'true');
    }
  };

  const userIsLoggedOut = () => {
    if (isLocalStorage) {
      localStorage.removeItem(isLoggedIn);
    }
  };

  const isUserLoggedIn = () => {
    if (isLocalStorage) {
      return localStorage.getItem(isLoggedIn) === 'true';
    }
    return false;
  };

  return { userIsLoggedIn, userIsLoggedOut, isUserLoggedIn };
};

export default LogoutHandler();
