import { createReducer } from "@reduxjs/toolkit";
import { logout } from "../actions";
import {
  getByDeviceId,
  getByRegId,
  registerMobile,
  updateDeviceToken,
} from "../actions/mobileRegistrationActions";

const firstRegistrationDetails = {
  UserMobileId: "",
  UserId: "",
  RegistrationId: "",
  AppId: "",
  Platform: 0,
  DeviceId: "",
  DeviceModel: "",
  DeviceToken: "",
  SystemVersion: "",
  AppVersion: "",
  Active: false,
  CreateDate: "",
};

const mobileDetails = Object.assign(firstRegistrationDetails, { UpdateDate: "" });

const initialState = {
  initRegistration: firstRegistrationDetails,
  registeredMobile: mobileDetails,
};

const mobileRegReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(registerMobile.fulfilled, (state, action) => {
      state.initRegistration = action.payload;
    })
    .addCase(getByRegId.fulfilled, (state, action) => {
      state.registeredMobile = action.payload;
    })
    .addCase(getByDeviceId.fulfilled, (state, action) => {
      state.registeredMobile = action.payload;
    })
    .addCase(updateDeviceToken.fulfilled, (state, action) => {
      state.registeredMobile = action.payload;
    })
    //reset all on logout
    .addCase(logout.fulfilled, () => initialState);
});

export type IMobileState = typeof initialState;
export default mobileRegReducer;
