import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  box: {
    paddingTop: '15px',
    backgroundColor: ThemeConstants.common.lightBackgroundColor,

  },
  box2: {
    justifyContent: 'flex-start',
    backgroundColor: ThemeConstants.common.lightBackgroundColor,
    padding: '20px',
    alignItems: 'center',
  },
  mr: {
    marginRight: '20px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1px',
  },
  disableHover: {
    '&:hover': {
      backgroundColor: '#48AF4D',
    },
  },
  submitButton: {
    marginRight: '5px',
    marginLeft: '5px',
    // ALIGNMENT WITH FILTER DROPDOWNS
    marginTop: '5px',
    maxWidth: '33px',
    maxHeight: '34px',
    minWidth: '33px',
    minHeight: '34px',
  },
  secondarySubmitButton: {
    backgroundColor: '#ffffff',
  },
  button: {
    '& .MuiButton-root': {
      width: '25%',
      minWidth: '25px',
      paddingLeft: 3,
      paddingRight: 1.5,
    },
  },
};

export default classes;
