import React from 'react';

import { Box, Typography } from '@mui/material';
import { FormikValues } from 'formik';

import {
  ISalesforceAdmin,
  adminConfiguration,
  createSalesforceAdmins,
} from '@pharmaplan/common';
import { ReactComponent as PlusIcon } from '@pharmaplan/common/assets/icons/plusIcon.svg';

import CustomButton from '../../../common/CustomButton';
import { ButtonSize, Constants } from '../../../../helpers/Constants';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { setDialog } from '../../../../reducers/dialogReducer';
import InputModal from '../../../Modals/InputModal';
import strings from '../../../../localization';

import SettingsInfo from '../SettingsInfo';
import EditAdminConfiguration from '../EditAdminConfiguration';

import { createNewForm, settingsInfoConfig } from './helper';
import styles from './styles';
import { Validator } from './validator';

const SalesforceTable = () => {
  const dispatch = useAppDispatch();
  const adminConfig = useAppSelector(adminConfiguration);

  const showEditAdminConfiguration = () => {
    dispatch(
      setDialog({
        showCloseButton: true,
        maxWidth: 'md',
        Component: <EditAdminConfiguration />,
        heading: {
          title: strings.formatString(
            strings.customSettings,
            strings.edit,
          ) as string,
        },
      }),
    );
  };

  const addNewSalesforceAdmin = (values: FormikValues) => {
    dispatch(createSalesforceAdmins(values as ISalesforceAdmin));
  };

  const showCreateNew = () => {
    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={addNewSalesforceAdmin}
            initialValues={{
              name: '',
              email: '',
              phone: '',
              received: false,
            }}
            submitLabel={strings.create}
            config={createNewForm}
            validator={Validator()}
          />
        ),
        heading: {
          title: strings.addNew,
        },
        showCloseButton: true,
      }),
    );
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <SettingsInfo
          onClick={showEditAdminConfiguration}
          config={settingsInfoConfig(adminConfig)}
        />
      </Box>

      <Box sx={styles.tableContent}>
        <Typography sx={styles.tableTitleFont}>
          {strings.sendCopiesOfSf}
        </Typography>

        <CustomButton
          customButtonStyle={styles.button}
          size={ButtonSize.small}
          label={strings.createNew}
          startIcon={<PlusIcon />}
          variant={Constants.variant.outlined}
          onClick={showCreateNew}
        />
      </Box>
    </Box>
  );
};

export default SalesforceTable;
