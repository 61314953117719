import { useNavigate } from 'react-router-dom';

import { getTypeOfUser, TypeOfUser } from '@pharmaplan/common';

import {
  adminMenuItems,
  menuItems,
  pharmacyAdminMenuItems,
} from '../components/Navbar/config';
import { Constants } from '../helpers/Constants';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const {
  paths: {
    home,
    admin: { home: adminHome },
    pharmacyAdmin: { home: pharmacyAdminHome },
  },
} = Constants;

const { pharmacist, pharmacy, pharmacyAdmin, admin, superAdmin } = TypeOfUser;

const useUserVariantsNav = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userType = useAppSelector(getTypeOfUser);

  const homePathsConfig = {
    [pharmacist]: home,
    [pharmacy]: home,
    [admin]: adminHome,
    [superAdmin]: adminHome,
    [pharmacyAdmin]: pharmacyAdminHome,
  };

  const menuItemConfig = {
    [pharmacist]: menuItems(navigate, dispatch),
    [pharmacy]: menuItems(navigate, dispatch),
    [admin]: adminMenuItems(navigate),
    [superAdmin]: adminMenuItems(navigate),
    [pharmacyAdmin]: pharmacyAdminMenuItems(navigate),
  };

  const homePath = homePathsConfig?.[userType] ?? {};
  const items = menuItemConfig?.[userType] ?? {};

  return {
    homePath,
    items,
  };
};

export default useUserVariantsNav;
