import React, { FC, useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';

import UploadDialog from '../../../../common/UploadDialog';
import strings from '../../../../../localization';

interface IUploadAdvertisement {
  handleSubmit: (values: FormikValues) => void;
  handleOpen: () => void;
  existingImageName?: string;
  syncImage: (base64: string) => void;
}

const filesTypes = '.png, .jpg, .jpeg';

const UploadAdvertisement: FC<IUploadAdvertisement> = ({
  handleSubmit,
  existingImageName,
  handleOpen,
  syncImage,
}) => {
  const formik = useFormik({
    initialValues: {
      image: {
        name: existingImageName,
        size: null,
      },
      base64Image: '',
      isImageChanged: false,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const { values, setFieldValue } = formik ?? {};
  const { image } = values ?? {};
  const { name, size } = image ?? {};

  const handleClear = () => {
    setFieldValue('image', null);
  };

  useEffect(() => {
    if (name) {
      setFieldValue('isImageChanged', !!size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  useEffect(() => {
    syncImage(formik.values.base64Image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.base64Image]);

  return (
    <UploadDialog
      formik={formik}
      message={strings.recommendedDimensionLabel}
      name="image"
      fileSize={2000}
      chooseLabel={strings.uploadFile}
      errorMessage={strings.sizeOfImage1Point5Mb}
      acceptedFileTypes={filesTypes}
      disableButton={!name}
      handleClear={handleClear}
      handleOpen={handleOpen}
      base64Name="base64Image"
    />
  );
};

export default UploadAdvertisement;
