import React, { useEffect } from 'react';

import {
  adminActions,
  adminCancelMessage,
  getTypeOfUser,
  pharmacistActions,
  pharmacistCancelMessage,
  pharmacyActions,
  pharmacyCancelMessage,
  resetStatus,
  successOrSelector,
  ToUserTypings,
  TypeOfUser,
} from '@pharmaplan/common';
import OutcomeModal from '../components/Modals/OutcomeModal';
import { OutcomeModalTypes } from '../helpers/Constants';
import { setDialog } from '../reducers/dialogReducer';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const successActions = [
  pharmacyActions.cancelRequestedBooking,
  pharmacistActions.cancelBooking,
  adminActions.requestCancellation,
];
const { pharmacist, pharmacy, admin, superAdmin } = TypeOfUser;

const useHandleBookingCancel = () => {
  const dispatch = useAppDispatch();

  const userType = useAppSelector(getTypeOfUser);

  const success = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const pharmacistMessage = useAppSelector(pharmacistCancelMessage);
  const pharmacyMessage = useAppSelector(pharmacyCancelMessage);
  const adminMessage = useAppSelector(adminCancelMessage);

  const messageConfig = ToUserTypings({
    [pharmacist]: pharmacistMessage?.message,
    [pharmacy]: pharmacyMessage?.message,
    [admin]: adminMessage?.message,
    [superAdmin]: adminMessage?.message,
  });

  const message = messageConfig[userType];

  const showDialog = () => {
    dispatch(
      setDialog({
        Component: (
          <OutcomeModal
            type={OutcomeModalTypes.requestSuccess}
            message={message ?? ''}
            showCloseButton
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: false,
      }),
    );
  };

  useEffect(() => {
    if (success) {
      showDialog();
      dispatch(resetStatus(successActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, success]);
};

export default useHandleBookingCancel;
