import { errorType, responseType } from '../helpers/Constants';

export const actionTypes = {
  GLOBAL_RESET: 'GLOBAL_RESET',
};

export const globalReset = () => ({
  type: actionTypes.GLOBAL_RESET,
});

export const statusDispatch = (response: any, errorDispatch: Function) => {
  const { data, message, status } = response ?? {};
  const { key, errorType: dataErrorType } = data ?? {};

  const {
    EnforceUpdatePassword,
    error,
    handleCustom,
    deletedAccount,
    enforceAppUpdate,
    refreshConfigurations,
    permissionDenied,
  } = errorType;

  switch (true) {
    case status !== 200:
    case message === responseType.network:
      return errorDispatch(data || response);
    case key === responseType.failure:
      return errorDispatch(data || response);
    case dataErrorType === EnforceUpdatePassword:
      return errorDispatch(data || response);
    case dataErrorType === error:
      return errorDispatch(data || response);
    case dataErrorType === handleCustom:
      return errorDispatch(data || response);
    case dataErrorType === deletedAccount:
      return errorDispatch(data || response);
    case dataErrorType === enforceAppUpdate:
      return errorDispatch(data || response);
    case dataErrorType === refreshConfigurations:
      return errorDispatch(data || response);
    case dataErrorType === permissionDenied:
      return errorDispatch(data || response);
  }
  return data;
};
