/* eslint-disable no-param-reassign */
// @ts-nocheck
import axios from 'axios';
import { errorType } from '../helpers/Constants';
import Routes from '../helpers/Routes';
import serverDateHandler from '../helpers/serverDateHandler';

export let setFunc: (param: string) => void;
export let getFunc: () => string;

export const funcs = (set: (param: string) => void, get: () => string) => {
  setFunc = set;
  getFunc = get;
};

const client = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let failedQueue: {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const processQueue = (error: null, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

client.interceptors.response.use(
  async function (response) {
    if (response.data.errorType === errorType.forceLogout) {
      localStorage.clear();
      window.location.reload();
      return;
    }
    if (response.data.errorType === 2) {
      const originalRequest = response.config;
      if (isRefreshing) {
        try {
          const token = await new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          });
          return await client(originalRequest);
        } catch (err) {
          return await Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;
      return new Promise(function (resolve, reject) {
        client
          .post(
            `${Routes.authentication.authBasePath}${Routes.authentication.refreshToken}`
          )
          .then(({ data }) => {
            if (data.errorType === errorType.error) {
              localStorage.clear();
              window.location.reload();
              return;
            }
            sessionStorage.setItem('serverDate', data.serverDate);
            processQueue(null, data.accessToken);
            resolve(client(originalRequest));
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return response;
  },
  async function (error) {
    console.log(error);
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
    }

    return Promise.reject(error);
  }
);

client.interceptors.request.use(function (config) {
  // if (!!sessionStorage) {
  //   const token = sessionStorage.getItem('token');

  //   if (config !== undefined && config?.headers) {
  //     config.headers.Authorization = `Bearer ${token}`;
  //   }
  // }
  return config;
});

export const configURL = (url: string) => {
  client.defaults.baseURL = url;
};

export default client;
