import { SxProps, Theme } from '@mui/system';

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '500px',
  },

  container: {
    width: '100%',
    '&.MuiList-root': {
      paddingTop: '3px',
      paddingBottom: '3px',
    },
  },

  skeletonContainer: {
    minHeight: '80px',
    display: 'flex',
    padding: '10px',
  },

  skeletonAvatar: {
    width: 50,
    height: 50,
  },

  skeletonTextcontainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  skeletonText: {
    marginLeft: '8px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
