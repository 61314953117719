import { IAdminNotesResponse, IAdminPharmacyNotesResponse } from '@pharmaplan/common';

import EditIcon from '../../../assets/svg/updateNoteIcon.svg';
import { DynamicTableCell } from '../../../helpers/Constants';
import strings from '../../../localization';
import { getDayMonthDateYear } from '../../Reports/helpers';

const { text } = DynamicTableCell;
const disableCheck = '00000000-0000-0000-0000-000000000000';

export const adminNoteHeader = () =>
  [
    { key: '0', label: strings.notes },
    {
      key: '1',
      label: strings.formatString(strings.createdFormat, strings.on) as string,
    },
    { key: '2', label: strings.action, isCenterAligned: true },
  ];

const shouldDisable = (noteId: string): boolean =>
  noteId === disableCheck;

export const notesRowConfig = (
  notes: Array<IAdminNotesResponse>,
  updateNote: (noteId: string) => () => void,
) =>
  notes?.map((item) => {
    const { note, createDate, adminPharmacistNoteId } = item ?? {};
    const createdParseDate = getDayMonthDateYear(createDate);

    const disabled = shouldDisable(adminPharmacistNoteId);
    const showDate = disabled ? '-' : createdParseDate;

    return {
      key: adminPharmacistNoteId,
      data: [
        {
          key: `0-${adminPharmacistNoteId}`,
          value: note,
          type: text,
          maxWidth: 200,
        },
        {
          key: `1-${adminPharmacistNoteId}`,
          value: showDate,
          type: text,
        },
        {
          key: `2-${adminPharmacistNoteId}`,
          show: true,
          value: [
            {
              key: '1',
              icon: EditIcon,
              disabled,
              tooltip: strings.updateNote,
              onClick: updateNote(adminPharmacistNoteId),
            },
          ],
          type: DynamicTableCell.iconButton,
        },
      ],
    };
  });

export const pharmacyNotesRowConfig = (
  notes: Array<IAdminPharmacyNotesResponse>,
  updateNote: (noteId: string) => () => void,
) =>
  notes?.map((item) => {
    const { note, createDate, adminPharmacyNoteId } = item ?? {};
    const createdParseDate = getDayMonthDateYear(createDate);

    const disabled = shouldDisable(adminPharmacyNoteId);
    const showDate = disabled ? '-' : createdParseDate;
    const tooltip = disabled ? strings.editNotAllowed : strings.updateNote;

    return {
      key: adminPharmacyNoteId,
      data: [
        {
          key: `0-${adminPharmacyNoteId}`,
          value: note,
          type: text,
          maxWidth: 200,
        },
        {
          key: `1-${adminPharmacyNoteId}`,
          value: showDate,
          type: text,
        },
        {
          key: `2-${adminPharmacyNoteId}`,
          show: true,
          value: [
            {
              key: '1',
              icon: EditIcon,
              tooltip,
              disabled,
              onClick: updateNote(adminPharmacyNoteId),
            },
          ],
          type: DynamicTableCell.iconButton,
        },
      ],
    };
  });
