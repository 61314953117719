import { Box, Paper } from '@mui/material';
import React from 'react';
import classes from './style';

interface IContentContainer {
  children: React.ReactNode,
}

const ContentContainer = ({ children }: IContentContainer) =>
  (
    <Box component={Paper} sx={classes.containerPadding}>
      {children}
    </Box>
  );

export default ContentContainer;
