import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 24px',
  },

  button: {
    marginRight: '5px',
    marginBottom: '5px',
    textAlign: 'left',
    fontSize: 11,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
