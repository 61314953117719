import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ITEMS_PER_PAGE,
  PerformerTypes,
  adminBroadcastGroupSelectedUsers,
  getBroadcastGroupSelectedUsers,
  getSelectedIds,
  setSelectedIds,
  updateBroadcastGroup,
} from '@pharmaplan/common';

import { useAppSelector } from '../../useAppSelector';
import { useAppDispatch } from '../../useAppDispatch';
import useOptimizedAvatar from '../../useOptimizedAvatar';
import useInfiniteScroll from '../../useInfiniteScroll';
import { setFooterShow, setTitle } from '../../../actions/drawerActions';

const { pharmacist } = PerformerTypes;

const useBroadcastGroup = (broadcastGroupId: string) => {
  const dispatch = useAppDispatch();

  const details = useAppSelector(adminBroadcastGroupSelectedUsers);
  const selectedIds = useAppSelector(getSelectedIds);

  const { data, group } = details ?? {};
  const { name } = group ?? {};

  const [page, setPage] = useState(1);
  const [modifiedData, setModifiedData] = useState(data);

  const observerCallback = useCallback(
    (entries: { isIntersecting: any }[]) => {
      const paginationCondition = data.length >= page * ITEMS_PER_PAGE;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    [page, data],
  );

  const { listEndRef } = useInfiniteScroll({ observerCallback });

  const pharmacistIds = data
    .filter(({ userType }) =>
      userType === pharmacist)
    .map(({ id }) =>
      id);

  const memoizedPharmacistIds = useMemo(
    () =>
      pharmacistIds,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const apiCall = (pagination = 1) => {
    dispatch(
      getBroadcastGroupSelectedUsers({
        groupId: broadcastGroupId,
        pagingModel: { page: pagination },
      }),
    );
  };

  const handleDelete = (id: string) => {
    const afterDelete = selectedIds.filter((item) =>
      item !== id);

    const viewIds = modifiedData.filter((item) =>
      item.userId !== id);

    setModifiedData(viewIds);
    dispatch(setSelectedIds(afterDelete));

    dispatch(
      updateBroadcastGroup({
        groupId: broadcastGroupId,
        groupName: name,
        userIds: afterDelete,
      }),
    );
  };

  useOptimizedAvatar(memoizedPharmacistIds);

  useEffect(() => {
    dispatch(setFooterShow(false));
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setTitle({ title: name }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (page !== 1) {
      apiCall(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setModifiedData(data);
  }, [data]);

  return { listEndRef, handleDelete, modifiedData };
};

export default useBroadcastGroup;
