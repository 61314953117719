import React, { memo, useEffect } from 'react';

import {
  AdminLegendVariant,
  ICalendarEventType,
  adminActions,
  adminRatingDetails,
  adminRatingItems,
  getAdminPharmacistRating,
  getAdminRatingItems,
} from '@pharmaplan/common';
import ServiceCard from '../../SelfService/ServiceCard';
import ViewPharmacistRatingHeader from '../../MyReplacements/PharmacistRating/ViewPharmacistRating/ViewPharmacistContent/ViewPharmacistRatingHeader';
import ViewPharmacistRatingContent from '../../MyReplacements/PharmacistRating/ViewPharmacistRating/ViewPharmacistContent';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
  ICustomBack,
  renderScreen,
  setBack,
} from '../../../actions/drawerActions';
import { ScreenTypes } from '../../../helpers/Constants';
import styles from './style';

const { bookingList, requestedList } = ScreenTypes;
interface IViewPharmacistRatingProps {
  pharmacistId: string;
  type?: ICalendarEventType;
  bookingId?: string;
  workshiftId?: string;
  eventIds?: Array<string>;
  back?: ICustomBack;
}

const ViewAdminPharmacistRating = ({
  pharmacistId,
  type,
  bookingId,
  workshiftId,
  eventIds,
  back,
}: IViewPharmacistRatingProps) => {
  const dispatch = useAppDispatch();

  const ratingDetails = useAppSelector(adminRatingDetails);
  const ratingData = useAppSelector(adminRatingItems);

  const handleBack = () => {
    switch (type) {
      case AdminLegendVariant.booked:
        dispatch(
          renderScreen({
            screenType: bookingList,
            screenNumber: 2,
            eventId: bookingId,
            eventIds,
          }),
        );
        break;
      case AdminLegendVariant.posted:
        dispatch(
          renderScreen({
            screenType: requestedList,
            screenNumber: 3,
            eventIds,
            eventId: bookingId,
            workshiftId,
          }),
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getAdminRatingItems());
    dispatch(getAdminPharmacistRating(pharmacistId));
    dispatch(setBack(back || handleBack));
    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ServiceCard
        customContainerStyle={styles.container}
        content={(
          <ViewPharmacistRatingHeader
            successAction={adminActions.getPharmacistRating}
            ratingDetails={ratingDetails}
          />
        )}
      />
      <ServiceCard
        customContainerStyle={styles.container}
        content={(
          <ViewPharmacistRatingContent
            ratingData={ratingData}
            ratingDetails={ratingDetails}
          />
        )}
      />
    </>
  );
};

export default memo(ViewAdminPharmacistRating);
