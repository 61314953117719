import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../../theme/ThemeConstants';

const styles = {
  flexContainer: {
    display: 'flex',
  },
  container: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  subContainer: {
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: ThemeConstants.common.notificationActions,
    paddingX: '4px',
  },
  iconsContainer: {
    alignItems: 'center',
  },
  button: {
    minWidth: 0,
  },
  divider: {
    height: '15px',
    width: '1px',
    opacity: 0.2,
    backgroundColor: ThemeConstants.grey[800],
    mx: '6px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
