import { Box, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import { Close } from '@mui/icons-material';
import classes from './style';

export interface IDialogTitleCustomStyles {
  customTitleContainer?: Record<string, string>;
  customModalHeading?: Record<string, string>;
}

interface ICustomDialogTitle {
  onClickClose: () => void;
  headingTitle?: string;
  customStyle?: IDialogTitleCustomStyles;
}

const defaultProps = {
  headingTitle: '',
  customStyle: {},
};

const CustomDialogTitle = ({
  onClickClose,
  headingTitle,
  customStyle,
}: ICustomDialogTitle) =>
  (
    <DialogTitle
      sx={[
        classes.titleContainer,
        customStyle?.customTitleContainer ? customStyle.customTitleContainer : {},
      ]}
    >
      <Box
        component="span"
        sx={[
          classes.modalHeading,
          customStyle?.customModalHeading ? customStyle.customModalHeading : {},
        ]}
      >
        {headingTitle}
      </Box>
      <IconButton size="small" sx={classes.closeIcon} onClick={onClickClose}>
        <Close />
      </IconButton>
    </DialogTitle>
  );

CustomDialogTitle.defaultProps = defaultProps;

export default CustomDialogTitle;
