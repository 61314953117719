import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';

import { getTypeOfUser, TypeOfUser } from '@pharmaplan/common';
import useDrawerNavigation from '../../../../hooks/useDrawerNavigation';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';

import styles from './styles';

const ToolbarAdd = () => {
  const { openAddDefaultDrawer } = useDrawerNavigation();

  const userType = useAppSelector(getTypeOfUser);
  const isPharmacy = userType === TypeOfUser.pharmacy;

  let title = strings.addAvailabilityVacation;

  if (isPharmacy) {
    title = strings.addWorkshifts;
  }

  return (
    <IconButton id="availabilties" onClick={openAddDefaultDrawer}>
      <Tooltip title={title}>
        <AddIcon sx={styles.icon} />
      </Tooltip>
    </IconButton>
  );
};

export default ToolbarAdd;
