/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useEffect, useMemo } from 'react';

import { Divider, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { FormikValues, useFormik } from 'formik';

import {
  adminBroadcastNotification,
  adminResetValue,
  BroadcastNotificationType,
  ConfirmationTypes,
  getBroadcastRecipients,
  sendBroadcastNotification,
} from '@pharmaplan/common';

import useBroadcastNotification from '../../../../../hooks/Admin/BroadcastList/useBroadcastNotification';

import {
  BroadcastGroupActionType,
  TextFieldVariants,
} from '../../../../../helpers/Constants';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import ConfirmationModal from '../../../../Modals/ConfirmationModal';
import { setDialog } from '../../../../../reducers/dialogReducer';
import genericClasses from '../../../../../theme/GenericClasses';
import CustomTextField from '../../../../common/CustomTextField';
import CustomDropdown from '../../../../common/CustomDropdown';
import CustomButton from '../../../../common/CustomButton';
import EmailEditor from '../../../../common/EmailEditor';
import strings from '../../../../../localization';

import styles from '../styles';
import RecipientList from './RecipientList';

const { sendNotification } = ConfirmationTypes;
const { filled } = TextFieldVariants;
const { email } = BroadcastNotificationType;
const { edit } = BroadcastGroupActionType;
const recipientKey = 'groupRecipientList';
const broadcastNotificationKey = 'broadcastNotification';

interface IBroadcastFields {
  type: BroadcastNotificationType;
  actionType: BroadcastGroupActionType;
}

const BroadcastFields: FC<IBroadcastFields> = ({ type, actionType }) => {
  const dispatch = useAppDispatch();
  const broadcastNotification = useAppSelector(adminBroadcastNotification);

  const {
    subject: editSubject,
    message: editMessage,
    broadcastGroups: editBroadcastGroups,
    footer: editFooter,
  } = broadcastNotification ?? {};

  const editGroupIds = useMemo(
    () =>
      editBroadcastGroups.map((item) =>
        item.broadcastGroupId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [broadcastNotification.broadcastGroups],
  );

  const isEmail = type === email;
  const isEdit = actionType === edit;

  const confirm = (values: FormikValues) =>
    () => {
      const {
        message: formikMessage,
        subject,
        groupIds: formikGroupIds,
        footer,
      } = values ?? {};
      dispatch(
        sendBroadcastNotification({
          message: formikMessage,
          subject,
          type,
          groupIds: formikGroupIds,
          footer,
        }),
      );
    };

  const handleSubmit = (values: FormikValues) => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            confirmAction={confirm(values)}
            type={sendNotification}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: false,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      subject: editSubject || '',
      message: editMessage || '',
      groupIds: editGroupIds || [],
      footer: editFooter,
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const {
    groupsDropdown,
    body,
    placeholder,
    getGroups,
    fieldTitle,
    maxLength,
  } = useBroadcastNotification(type, formik);

  const {
    values: { subject, message, groupIds },
  } = formik ?? {};

  const isDisabled = !((subject || !isEmail) && message && groupIds);

  useEffect(() => {
    if (editGroupIds.length) {
      dispatch(getBroadcastRecipients(editGroupIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastNotification]);

  useEffect(
    () =>
      () => {
        dispatch(adminResetValue([recipientKey, broadcastNotificationKey]));
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Box sx={styles.subTitleContainer}>
        <Typography sx={styles.subTitle}>{fieldTitle}</Typography>
        <Divider sx={styles.divider} />
      </Box>

      <Stack sx={styles.fieldsContainer}>
        <CustomDropdown
          formik={formik}
          label={strings.selectGroup}
          horizontal
          name="groupIds"
          variant={filled}
          placeholder={strings.selectGroup}
          showPlaceholder
          multiple
          menuItems={groupsDropdown}
          onClose={getGroups}
        />
        <RecipientList label={strings.to} type={type} />
        {isEmail && (
          <CustomTextField
            name="subject"
            formik={formik}
            label={strings.emailSubject}
            horizontal
            variant={filled}
            id="subject"
            customTextFieldStyle={genericClasses.customFilledInput}
          />
        )}
        <CustomTextField
          name="message"
          formik={formik}
          rows={6}
          label={body}
          horizontal
          variant={filled}
          maxLength={maxLength}
          placeholder={placeholder}
          id="message"
          multiline
          customTextFieldStyle={{ ...genericClasses.customFilledInput }}
        />
        {isEmail && (
          <Box sx={styles.signatureContainer}>
            <Typography sx={styles.signatureLabelContainer}>
              {strings.signature}
            </Typography>
            <Box sx={styles.editorContainer}>
              <EmailEditor
                formik={formik}
                name="footer"
                previousContent={editFooter}
              />
            </Box>
          </Box>
        )}
      </Stack>
      <Box sx={styles.buttonContainer}>
        <CustomButton
          disabled={isDisabled}
          label={isEdit ? strings.resendNotification : strings.sendNotification}
          onClick={formik.handleSubmit}
        />
      </Box>
    </>
  );
};

export default BroadcastFields;
