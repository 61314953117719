import { Box, Typography, LinearProgress } from '@mui/material';
import React, { FC } from 'react';
import style from './style';

interface ICustomProgressBar {
    progress: number;
    primaryText?: string;
    secondaryText?: string;
  }

const defaultProps = {
  primaryText: '',
  secondaryText: '',
};

const CustomProgressBar: FC<ICustomProgressBar> = ({
  progress,
  primaryText,
  secondaryText,
}) =>
  (
    <Box sx={style.container}>
      <Box sx={style.textContainer}>
        <Typography sx={style.primaryText}>{primaryText}</Typography>
        <Typography sx={style.secondaryText}>{secondaryText}</Typography>
      </Box>

      <LinearProgress variant="determinate" value={progress} sx={style.progressBar} />
    </Box>
  );

CustomProgressBar.defaultProps = defaultProps;

export default CustomProgressBar;
