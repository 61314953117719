import React from 'react';

import {
  ConfirmationTypes,
  deletePharmacistVacations,
} from '@pharmaplan/common';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import { resetDialog, setDialog } from '../reducers/dialogReducer';

import { useAppDispatch } from './useAppDispatch';

const useVacationDelete = () => {
  const dispatch = useAppDispatch();

  const deleteVacation = (id: string) =>
    () => {
      dispatch(deletePharmacistVacations(id));
      dispatch(resetDialog());
    };

  const showVacationConfirmation = (id: string) => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.deleteEntry}
            confirmAction={deleteVacation(id)}
          />
        ),
        showCloseButton: false,
        heading: {
          title: '',
        },
      }),
    );
  };

  return { showVacationConfirmation };
};

export default useVacationDelete;
