import {
  IPendingCancellationData,
  PendingCancellationStatus,
  PermissionsOfAdmin,
  PermissionsRemovalKeys,
  TypeOfUser,
} from '@pharmaplan/common';

import approveBookingIcon from '../../../assets/svg/approveBooking.svg';
import rejectBookingIcon from '../../../assets/svg/rejectBooking.svg';

import {
  DynamicTableCell,
  Constants,
  TextFieldVariants,
  OtherScreens,
} from '../../../helpers/Constants';
import { IUserProfileLinkSignature } from '../../../hooks/Admin/useUserProfileLink';
import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';
import { IInputModalConfig } from '../../Modals/InputModal/InputModal';
import { getDayMonthDateYear } from '../../Reports/helpers';
import styles from './styles';
import { removeBasedOnPermissions } from '../../../helpers/permissionsHelper';

const { text, customLink, chip, textBox } = DynamicTableCell;

const { text: formText, checkbox, typography } = Constants.formInputType;
const { filled } = TextFieldVariants;

const { pharmacist, pharmacy } = TypeOfUser;

const { PermanentDelete } = PermissionsRemovalKeys;
const { PermanentDelete: PermanentDeletePermission } = PermissionsOfAdmin;

export const StatusStylesMap = {
  [PendingCancellationStatus.Critical]: styles.critical,
  [PendingCancellationStatus.Normal]: styles.normal,
  [PendingCancellationStatus.Severe]: styles.severe,
  [PendingCancellationStatus.Urgent]: styles.urgent,
};

export const cancellationBookingHeader = (can: (params: PermissionsOfAdmin) => boolean) => {
  const arr = [
    {
      key: '0',
      label: strings.code,
      sortingKey: 'cancellationCode',
    },
    {
      key: '1',
      label: strings.bookingDate,
      sortingKey: 'selectedDate',
    },
    {
      key: '2',
      label: strings.cancellationDate,
      sortingKey: 'cancellationRequestDate',
    },
    {
      key: '3',
      label: strings.requestedBy,
      sortingKey: 'requestedByName',
    },
    {
      key: '4',
      label: strings.pharmacistName,
      sortingKey: 'pharmacistName',
    },
    {
      key: '5',
      label: strings.pharmacyName,
      sortingKey: 'pharmacyName',
    },
    {
      key: '6',
      label: strings.status,
      sortingKey: 'status',
    },
    {
      key: '7',
      label: strings.notes,
    },
    {
      key: PermanentDelete,
      label: strings.deleteRecords,
    },
  ];

  return removeBasedOnPermissions(arr, [PermanentDeletePermission], can);
};

export const pendingCancellationHeader = () =>
  [
    {
      key: '0',
      label: strings.code,
      sortingKey: 'requestCode',
    },
    {
      key: '1',
      label: strings.bookingDate,
      sortingKey: 'selectedDate',
    },
    {
      key: '2',
      label: strings.requestedDate,
      sortingKey: 'cancellationRequestDate',
    },
    {
      key: '3',
      label: strings.requestedBy,
      sortingKey: 'requestedByName',
    },
    {
      key: '4',
      label: strings.pharmacistName,
      sortingKey: 'pharmacistName',
    },
    {
      key: '5',
      label: strings.pharmacyName,
      sortingKey: 'pharmacyName',
    },
    {
      key: '6',
      label: strings.status,
      sortingKey: 'status',
    },
    {
      key: '7',
      label: strings.action,
    },
  ];

export const rejectedCancellationHeader = (can: (params: PermissionsOfAdmin) => boolean) => {
  const arr = [
    {
      key: '0',
      label: strings.code,
      sortingKey: 'requestCode',
    },
    {
      key: '1',
      label: strings.bookingDate,
      sortingKey: 'selectedDate',
    },
    {
      key: '2',
      label: strings.rejectedDate,
      sortingKey: 'cancellationRejectionDate',
    },
    {
      key: '3',
      label: strings.pharmacistName,
      sortingKey: 'pharmacistName',
    },
    {
      key: '4',
      label: strings.pharmacyName,
      sortingKey: 'pharmacyName',
    },
    {
      key: '5',
      label: strings.status,
      sortingKey: 'status',
    },
    {
      key: '6',
      label: strings.notes,
    },
    {
      key: PermanentDelete,
      label: strings.deleteRecords,
    },
  ];

  return removeBasedOnPermissions(arr, [PermanentDeletePermission], can);
};

export const pendingCancellationRowConfig = (
  list: Array<IPendingCancellationData>,
  goToLink?: (codeId: string) => () => void,
  goToUser?: IUserProfileLinkSignature,
  handleApprove?: (id: string, code: string) => void,
  handleReject?: (id: string, code: string) => void,
) =>
  list?.map((item) => {
    const {
      cancellationRequestId,
      requestCode,
      cancellationRequestDate,
      requestedByName,
      pharmacistName,
      pharmacyName,
      status,
      pharmacistId,
      pharmacyId,
      isPharmacistDeleted,
      selectedDate,
      isPharmacyDeleted,
    } = item ?? {};

    const bookingDate = selectedDate ? getDayMonthDateYear(selectedDate) : '';
    const cancellationParsedData = cancellationRequestDate
      ? getDayMonthDateYear(cancellationRequestDate)
      : '';

    return {
      key: cancellationRequestId,
      data: [
        {
          key: `0-${cancellationRequestId}`,
          value: {
            label: requestCode,
            handlePress: goToLink?.(cancellationRequestId),
          },
          type: customLink,
        },
        {
          key: `${cancellationRequestId}`,
          value: bookingDate,
          type: text,
        },
        {
          key: `1-${cancellationRequestId}`,
          value: cancellationParsedData,
          type: text,
        },
        {
          key: `2-${cancellationRequestId}`,
          value: requestedByName,
          type: text,
        },
        {
          key: `3-${cancellationRequestId}`,
          value: {
            label: pharmacistName,
            handlePress: goToUser?.({
              personnelId: pharmacistId,
              userType: pharmacist,
              fromScreen: isPharmacistDeleted
                ? OtherScreens.DeletedPendingUsers
                : null,
            }),
          },
          type: customLink,
        },
        {
          key: `4-${cancellationRequestId}`,
          value: {
            label: pharmacyName,
            handlePress: goToUser?.({
              personnelId: pharmacyId,
              userType: pharmacy,
              fromScreen: isPharmacyDeleted
                ? OtherScreens.DeletedPendingUsers
                : null,
            }),
          },
          type: customLink,
        },
        {
          key: `5-${cancellationRequestId}`,
          label: status,
          value: {
            label: status,
            style: StatusStylesMap[status],
          },
          type: chip,
        },
        {
          key: `6-${cancellationRequestId}`,
          value: [
            {
              key: '1',
              icon: approveBookingIcon,
              tooltip: strings.approve,
              onClick: () =>
                handleApprove?.(cancellationRequestId ?? '', requestCode),
            },
            {
              key: '2',
              tooltip: strings.reject,
              icon: rejectBookingIcon,
              onClick: () =>
                handleReject?.(cancellationRequestId ?? '', requestCode),
            },
          ],
          type: DynamicTableCell.bar,
        },
      ],
    };
  });

export const cancellationBookingRowConfig = (
  list: Array<IPendingCancellationData>,
  handlePress: (bookingId: string) => () => void,
  selectedItems: Array<string>,
  goToLink: (codeId: string) => () => void,
  goToUser: IUserProfileLinkSignature,
  canDelete: boolean,
) =>
  list?.map((item) => {
    const {
      cancellationRequestId,
      cancellationRequestDate,
      requestedByName,
      pharmacistName,
      pharmacyName,
      status,
      pharmacistId,
      pharmacyId,
      isPharmacistDeleted,
      selectedDate,
      isPharmacyDeleted,
      cancellationReason,
      cancellationId,
      cancellationCode,
      bookingId,
    } = item ?? {};

    const bookingDate = selectedDate ? getDayMonthDateYear(selectedDate) : '';
    const cancellationParsedData = cancellationRequestDate
      ? getDayMonthDateYear(cancellationRequestDate)
      : '';

    return {
      key: cancellationRequestId,
      data: [
        {
          key: `0-${cancellationRequestId}`,
          value: {
            label: cancellationCode,
            handlePress: goToLink?.(cancellationId ?? ''),
          },
          maxWidth: 150,
          type: customLink,
        },
        {
          key: `${cancellationRequestId}`,
          value: bookingDate,
          type: text,
        },
        {
          key: `1-${cancellationRequestId}`,
          value: cancellationParsedData,
          type: text,
        },
        {
          key: `2-${cancellationRequestId}`,
          value: requestedByName,
          maxWidth: 150,
          type: text,
        },
        {
          key: `3-${cancellationRequestId}`,
          value: {
            label: pharmacistName,
            handlePress: goToUser?.({
              personnelId: pharmacistId,
              userType: pharmacist,
              fromScreen: isPharmacistDeleted
                ? OtherScreens.DeletedPendingUsers
                : null,
            }),
          },
          maxWidth: 150,
          type: customLink,
        },
        {
          key: `4-${cancellationRequestId}`,
          value: {
            label: pharmacyName,
            handlePress: goToUser?.({
              personnelId: pharmacyId,
              userType: pharmacy,
              fromScreen: isPharmacyDeleted
                ? OtherScreens.DeletedPendingUsers
                : null,
            }),
          },
          maxWidth: 150,
          type: customLink,
        },
        {
          key: `5-${cancellationRequestId}`,
          label: status,
          value: {
            label: status,
            style: StatusStylesMap[status],
          },
          type: chip,
        },
        {
          key: `6-${cancellationRequestId}`,
          value: cancellationReason,
          type: textBox,
        },
        canDelete && {
          key: `7-${cancellationRequestId}`,
          value: {
            checked: selectedItems.includes(bookingId),
            handlePress: handlePress(bookingId),
          },
          isCenterAligned: true,
          type: DynamicTableCell.checkbox,
        },
      ],
    };
  });

export const rejectedCancellationsRowConfig = (
  list: Array<IPendingCancellationData>,
  handlePress: (bookingId: string) => () => void,
  selectedItems: Array<string>,
  goToLink: (codeId: string) => () => void,
  goToUser: IUserProfileLinkSignature,
  canDelete: boolean,
) =>
  list?.map((item) => {
    const {
      cancellationRequestId,
      requestCode,
      pharmacistName,
      pharmacyName,
      status,
      pharmacistId,
      pharmacyId,
      isPharmacistDeleted,
      selectedDate,
      isPharmacyDeleted,
      cancellationRejectionDate,
      rejectionReason,
    } = item ?? {};

    const bookingDate = selectedDate ? getDayMonthDateYear(selectedDate) : '';
    const rejectionDate = cancellationRejectionDate
      ? getDayMonthDateYear(cancellationRejectionDate)
      : '';

    return {
      key: cancellationRequestId,
      data: [
        {
          key: `0-${cancellationRequestId}`,
          value: {
            label: requestCode,
            handlePress: goToLink?.(cancellationRequestId),
          },
          type: customLink,
        },
        {
          key: `${cancellationRequestId}`,
          value: bookingDate,
          type: text,
        },
        {
          key: `1-${cancellationRequestId}`,
          value: rejectionDate,
          type: text,
        },
        {
          key: `2-${cancellationRequestId}`,
          value: {
            label: pharmacistName,
            handlePress: goToUser?.({
              personnelId: pharmacistId,
              userType: pharmacist,
              fromScreen: isPharmacistDeleted
                ? OtherScreens.DeletedPendingUsers
                : null,
            }),
          },
          type: customLink,
        },
        {
          key: `3-${cancellationRequestId}`,
          value: {
            label: pharmacyName,
            handlePress: goToUser?.({
              personnelId: pharmacyId,
              userType: pharmacy,
              fromScreen: isPharmacyDeleted
                ? OtherScreens.DeletedPendingUsers
                : null,
            }),
          },
          type: customLink,
        },
        {
          key: `4-${cancellationRequestId}`,
          label: status,
          value: {
            label: status,
            style: StatusStylesMap[status],
          },
          type: chip,
        },
        {
          key: `5-${cancellationRequestId}`,
          value: rejectionReason,
          type: textBox,
        },
        canDelete && {
          key: `7-${cancellationRequestId}`,
          value: {
            checked: selectedItems.includes(cancellationRequestId),
            handlePress: handlePress(cancellationRequestId),
          },
          isCenterAligned: true,
          type: DynamicTableCell.checkbox,
        },
      ],
    };
  });

export const approvePendingCancellationForm: IInputModalConfig[] = [
  {
    key: '0',
    name: 'code',
    type: typography,
    style: genericClasses.inputModalTypography,
    gridLabel: strings.code,
    fieldXs: 6,
    labelXs: 6,
  },
  {
    key: '1',
    name: 'notes',
    type: formText,
    gridLabel: strings.notes,
    customTextFieldContainer: genericClasses.removeProfileMargin,
    multiline: true,
    variant: filled,
    fieldXs: 6,
    labelXs: 6,
  },
  {
    key: '2',
    name: 'sendEmailToPharmacist',
    type: checkbox,
    customClass: genericClasses.checkboxContainer,
    customCheckboxStyle: genericClasses.checkboxStyle,
    gridLabelClass: genericClasses.gridLabel,
    gridLabel: strings.formatString(
      strings.sendEmailNotification,
      strings.pharmacist,
    ) as string,
    label: ' ',
    fieldXs: 6,
    labelXs: 6,
  },
  {
    key: '3',
    name: 'sendEmailToPharmacy',
    type: checkbox,
    customClass: genericClasses.checkboxContainer,
    customCheckboxStyle: genericClasses.checkboxStyle,
    gridLabelClass: genericClasses.gridLabel,
    gridLabel: strings.formatString(
      strings.sendEmailNotification,
      strings.pharmacy,
    ) as string,
    label: ' ',
    fieldXs: 6,
    labelXs: 6,
  },
];

export const rejectPendingCancellationForm: IInputModalConfig[] = [
  {
    key: '0',
    name: 'code',
    type: typography,
    style: genericClasses.inputModalTypography,
    gridLabel: strings.code,
    fieldXs: 6,
    labelXs: 6,
  },
  {
    key: '1',
    name: 'notes',
    type: formText,
    gridLabel: strings.notes,
    customTextFieldContainer: genericClasses.removeProfileMargin,
    multiline: true,
    variant: filled,
    fieldXs: 6,
    labelXs: 6,
  },
];
