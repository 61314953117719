import React, { FC } from 'react';

import { ReactComponent as PlusIcon } from '@pharmaplan/common/assets/icons/plusIcon.svg';

import CustomButton from '../CustomButton';
import styles from './styles';

interface ICreateNew {
  label: string;
  onClick: () => void;
}

const CreateNew: FC<ICreateNew> = ({ label, onClick }) =>
  (
    <CustomButton
      variant="outlined"
      startIcon={<PlusIcon />}
      onClick={onClick}
      type="button"
      customButtonStyle={styles.button}
      label={label}
    />
  );

export default CreateNew;
