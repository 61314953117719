import { IAdminPharmacistHistoryData, TimeFormat } from '@pharmaplan/common';
import strings from '../../../../../localization';
import { getDayMonthDateYear } from '../../../../Reports/helpers';
import { timeString } from '../../../../../helpers/Functions';
import { DynamicTableCell } from '../../../../../helpers/Constants';

const { text } = DynamicTableCell;

export const pharmacyHistoryHeaders = () =>
  [
    { key: '0', label: strings.code },
    { key: '1', label: `${strings.date} | ${strings.time}` },
    // { key: '2', label: strings.action },
  ];

export const pharmacyHistoryRow = (
  data: IAdminPharmacistHistoryData[],
  timeFormat: TimeFormat,
) =>
  data.map((item) => {
    const { bookingId, code, endDate, startDate } = item ?? {};

    const date = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);

    return {
      key: bookingId,
      data: [
        {
          key: '0',
          value: code,
          type: text,
        },
        {
          key: '2',
          value: `${date} | ${time}`,
          maxWidth: 200,
          type: text,
        },
        // {
        //   key: '3',
        //   show: true,
        //   width: 50,
        //   value: [
        //     {
        //       key: '1',
        //       icon: rejectBooking,
        //       tooltip: strings.requestCancellation,
        //       onClick: goToRequestedCancellation(bookingId),
        //     },
        //   ],
        //   type: DynamicTableCell.iconButton,
        // },
      ],
    };
  });
