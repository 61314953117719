import React from 'react';

import {
  Box,
  Button,
  InputLabel,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

import { Constants } from '../../../helpers/Constants';

import classes from './style';

interface ICustomButton {
  id?: string;
  fullWidth?: boolean;
  color?: MuiButtonProps['color'];
  variant?: MuiButtonProps['variant'];
  size?: MuiButtonProps['size'];
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  label: string;
  fieldLabel?: string;
  customClass?: Record<string, unknown> | Array<Record<string, unknown>>;
  customButtonStyle?: Record<string, unknown> | Array<Record<string, unknown>>;
  onClick: () => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disableElevation?: boolean;
  requireLabel?: boolean;
}
const defaultProps = {
  fullWidth: true,
  disabled: false,
  type: 'submit',
  color: Constants.color.primary,
  variant: Constants.variant.contained,
  customClass: {},
  startIcon: null,
  customButtonStyle: {},
  disableElevation: false,
  fieldLabel: '',
  requireLabel: false,
};

const CustomButton = ({
  id,
  onClick,
  fullWidth,
  color,
  variant,
  label,
  customClass,
  size,
  type,
  startIcon,
  endIcon,
  customButtonStyle,
  disabled,
  disableElevation,
  fieldLabel,
  requireLabel,
}: ICustomButton) =>
  (
    <Box>
      {fieldLabel && (
      <InputLabel>
        {fieldLabel}
        {requireLabel && (
          <Box component="span" sx={classes.customTextFieldRequireLabel}>
            *
          </Box>
        )}
      </InputLabel>
      )}
      <Box component="div" sx={customClass}>
        <Button
          id={id}
          disableElevation={disableElevation}
          onClick={onClick}
          type={type}
          size={size}
          fullWidth={fullWidth}
          variant={variant}
          color={color}
          startIcon={startIcon}
          endIcon={endIcon}
          disabled={disabled}
          sx={customButtonStyle}
        >
          {label}
        </Button>
      </Box>
    </Box>
  );
export default CustomButton;

CustomButton.defaultProps = defaultProps;
