import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import {
  IAdminSoftware,
  IAllLanguages,
  IContactPreferences,
  IPharmacistCapacity,
  IProvinces,
  ServiceTypes,
} from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import {
  Constants,
  SoftwareExperience,
  daysDropdown,
  telephoneInputMask,
} from '../../../../../helpers/Constants';
import strings from '../../../../../localization';

import { LanguageLevel, years } from '../../../../Profile/ProfileForm/Forms';
import classes from './style';

interface IPharmacyEditConfig {
  languages: IAllLanguages[];
  pharmacistCapacities: IPharmacistCapacity[];
  prefferedReplacementDurations: string | string[];
  selectedSoftwares: Array<IAdminSoftware>;
  provinces: Array<IProvinces>;
  preventChangeEmail: boolean;
  contactPreferences: Array<IContactPreferences>;
}

const selfServiceConfigKey = 'pharmaGapConfigKey';
const { mapButton } = Constants.formInputType;

export const softwareDropdown = SoftwareExperience().map(
  (experience, index) =>
    ({
      key: index + 1,
      label: experience,
    }),
);

export const handleSoftware = (data: Array<IAdminSoftware>) =>
  data.map((item) => {
    const { software, softwareId } = item ?? {};
    const { name } = software ?? {};

    return {
      label: name,
      type: Constants.formInputType.dropdown,
      name: softwareId,
      id: softwareId,
      menuItems: softwareDropdown,
    };
  });

export const pharmacistEditConfig = ({
  languages,
  pharmacistCapacities,
  prefferedReplacementDurations,
  selectedSoftwares,
  provinces,
  preventChangeEmail,
  contactPreferences,
}: IPharmacyEditConfig) => {
  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

  const selfServiceConfig = {
    title: strings.configurations,
    key: selfServiceConfigKey,
    form: () =>
      [
        {
          label: strings.noCancellationPeriod,
          type: Constants.formInputType.text,
          requireLabel: true,
          name: 'noCancellationPeriod',
          key: 'noCancellationPeriod',
          id: 'noCancellationPeriod',
          suffix: strings.days,
        },
        {
          label: strings.requestPendingPeriod,
          type: Constants.formInputType.text,
          requireLabel: true,
          name: 'bookingStateExpiration',
          key: 'bookingStateExpiration',
          id: 'bookingStateExpiration',
          suffix: strings.hrs,
        },
        {
          label: strings.cancellationFees,
          type: Constants.formInputType.text,
          requireLabel: true,
          name: 'cancellationPercentFees',
          key: 'cancellationPercentFees',
          id: 'cancellationPercentFees',
          suffix: '%',
        },
      ],
  };

  const arr = [
    {
      title: strings.general,
      key: 1,
      form: () =>
        [
          {
            label: strings.email,
            type: Constants.formInputType.changeEmail,
            requireLabel: true,
            disabled: preventChangeEmail,
            name: 'email',
          },
          {
            label: strings.firstName,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'firstName',
          },
          {
            label: strings.lastName,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'lastName',
          },
          {
            label: strings.preferredLanguage,
            type: Constants.formInputType.dropdown,
            name: 'preferdLanguage',
            requireLabel: true,
            id: 'preferdLanguage',
            menuItems: dropdownMapper(languages, 'languageId', 'name'),
          },
          {
            label: strings.contactPreferences,
            type: Constants.formInputType.dropdown,
            multiple: true,
            name: 'selectedContactPreferences',
            id: 'selectedContactPreferences',
            menuItems: dropdownMapper(
              contactPreferences,
              'contactPreferenceId',
              'name',
            ),
          },
        ],
    },
    {
      title: strings.professionalInfo,
      key: 2,
      form: () =>
        [
          {
            label: strings.licenseNumber,
            type: Constants.formInputType.text,
            requireLabel: true,
            name: 'licenseNumber',
          },
          {
            label: strings.pharmacistCapacity,
            type: Constants.formInputType.dropdown,
            name: 'pharmacistCapacityId',
            requireLabel: true,
            id: 'pharmacistCapacityId',
            menuItems: dropdownMapper(
              pharmacistCapacities,
              'pharmacistCapacityId',
              'description',
            ),
          },
          {
            label: strings.graduationYear,
            type: Constants.formInputType.dropdown,
            name: 'graduationYear',
            id: 'graduationYear',
            menuItems: years,
            requireLabel: true,
          },
          {
            label: strings.yearsOfExperience,
            type: Constants.formInputType.text,
            requireLabel: true,
            disabled: true,
            name: 'yearsOfExperience',
          },
          {
            label: strings.areYouIncorporated,
            type: Constants.formInputType.checkbox,
            name: 'incorporated',
            id: 'incorporated',
            customClass: classes.checkPosition,
            labelClass: classes.checkboxText,
          },
          {
            label: strings.englishLevel,
            type: Constants.formInputType.dropdown,
            name: 'englishLevel',
            id: 'englishLevel',
            menuItems: LanguageLevel(),
          },
          {
            label: strings.frenchLevel,
            type: Constants.formInputType.dropdown,
            name: 'frenchLevel',
            id: 'frenchLevel',
            menuItems: LanguageLevel(),
          },
          {
            label: strings.otherLanguage,
            type: Constants.formInputType.text,
            name: 'otherLanguages',
            id: 'otherLanguages',
          },
        ],
    },
    {
      title: strings.softwareFamiliarity,
      key: 3,
      form: () =>
        handleSoftware(selectedSoftwares),
    },
    {
      title: strings.contactDetails,
      key: 4,
      form: () =>
        [
          isSelfService && {
            label: strings.mapButtonLabel,
            fieldLabel: strings.pharmacistLocation,
            type: mapButton,
            xs: 6,
            name: 'pharmacistLocation',
            id: 'pharmacistLocation',
            requireLabel: true,
            fullWidth: true,
          },
          {
            label: strings.address,
            type: Constants.formInputType.text,
            name: 'address',
            requireLabel: true,
          },
          {
            label: strings.city,
            type: Constants.formInputType.text,
            name: 'city',
            requireLabel: true,
          },
          {
            type: Constants.formInputType.dropdown,
            name: 'province',
            menuItems: dropdownMapper(provinces, 'key', 'name'),
            label: strings.province,
          },
          {
            label: strings.postalCode,
            type: Constants.formInputType.text,
            name: 'postalCode',
            requireLabel: true,
          },
          {
            label: strings.homePhone,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            name: 'phone',
            requireLabel: true,
          },
          {
            label: strings.mobile,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            name: 'mobile',
            requireLabel: true,
          },
        ],
    },
    {
      title: strings.preferences,
      key: 5,
      form: () =>
        [
          {
            label: strings.preferredWorkingDays,
            type: Constants.formInputType.dropdown,
            name: 'preferredWorkingDays',
            id: 'preferredWorkingDays',
            multiple: true,
            menuItems: daysDropdown(),
          },
          {
            label: strings.preferredReplacementDuration,
            type: Constants.formInputType.dropdown,
            name: 'preferredReplacementDuration',
            id: 'preferredReplacementDuration',
            menuItems: prefferedReplacementDurations ?? [],
          },
          {
            label: strings.availabilityReminder,
            type: Constants.formInputType.checkbox,
            name: 'availabilityReminder',
            id: 'availabilityReminder',
            customClass: classes.checkPosition,
            labelClass: classes.checkboxText,
          },
        ],
    },
    selfServiceConfig,
  ];

  if (!isSelfService) {
    return arr.filter((item) =>
      item.key !== selfServiceConfigKey);
  }

  return arr;
};
