const classes = {
  calendarSelectCont: {
    marginY: 1,
  },
  onDay: {
    '& label': {
      marginRight: '14px',
    },
  },
  fontSize: {
    '& p': {
      fontSize: '0.8rem',
    },
  },
  mr: {
    marginRight: '30px',
  },
  mb: {
    marginBottom: '1.3rem',
  },
  centerlized: {
    alignItems: 'center',
  },
  month: {
    marginRight: '45px',
  },
};

export default classes;
