import { SxProps, Theme } from '@mui/system';

const styles = {
  availability: {
    backgroundColor: 'admin.availabilityLight',
    color: 'admin.availability',
  },
  posted: {
    backgroundColor: 'admin.postedLight',
    color: 'admin.posted',
  },
  requested: {
    backgroundColor: 'admin.requestedLight',
    color: 'admin.requested',
  },
  booked: {
    backgroundColor: 'admin.bookedLight',
    color: 'admin.booked',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
