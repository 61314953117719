import { convertToDecimals } from '@pharmaplan/common';

export const handlerForZeroes = {
  get(obj: any, prop: string) {
    if (!obj[prop] || obj[prop] === 0) {
      return '';
    }
    return Reflect.get(obj, prop);
  },
};

export const decimalConverterProxy = {
  get(obj: any, prop: string) {
    if (obj[prop]) {
      return convertToDecimals(Reflect.get(obj, prop));
    }
    return Reflect.get(obj, prop);
  },
};
