import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import WithSkeleton from '../../../common/WithSkeleton';
import { SkeletonVariants } from '../../../../helpers/Constants';

import classes from './styles';

interface IPharmacistCardFooterConfig {
  key?: string;
  primaryTitle?: string;
  primaryValue?: string;
  secondaryTitle?: string;
  secondaryValue?: string;
}

interface IPharmacistCardFooter {
  footerContent: Array<IPharmacistCardFooterConfig>;
  successAction: string;
}

const PharmacistCardFooter: FC<IPharmacistCardFooter> = ({
  footerContent,
  successAction,
}) => {
  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  return (
    <Box>
      {footerContent?.map(
        (item) =>
          item?.primaryTitle && (
            <Box key={item.key} sx={classes.container}>
              <Box sx={classes.subContainer}>
                <SkeletonTypography sx={classes.title}>
                  {item?.primaryTitle}
                </SkeletonTypography>
                <SkeletonTypography sx={classes.value}>
                  {item?.primaryValue}
                </SkeletonTypography>
              </Box>

              {item?.secondaryTitle && (
                <Box sx={classes.subContainer}>
                  <SkeletonTypography sx={classes.title}>
                    {item?.secondaryTitle}
                  </SkeletonTypography>
                  <SkeletonTypography sx={classes.value}>
                    {item?.secondaryValue}
                  </SkeletonTypography>
                </Box>
              )}
            </Box>
          ),
      )}
    </Box>
  );
};

export default PharmacistCardFooter;
