import React from 'react';

import { FormikValues } from 'formik';

import { ISalesforceAdmin } from '@pharmaplan/common';

import { DynamicTableCell } from '../../../helpers/Constants';
import strings from '../../../localization';
import EditIcon from '../../../assets/svg/edit.svg';
import DeleteIcon from '../../../assets/svg/delete.svg';
import { createNewForm, handleChip } from './SalesforceTable/helper';
import { setDialog } from '../../../reducers/dialogReducer';
import InputModal from '../../Modals/InputModal';

import { store } from '../../../store';
import { Validator } from './SalesforceTable/validator';

export const tableHeaderConfig = () =>
  [
    { key: '0', label: strings.name },
    { key: '1', label: strings.email },
    { key: '2', label: strings.phone },
    { key: '3', label: strings.active },
    { key: '4', label: strings.action },
  ];

export const tableRowConfig = (
  data: ISalesforceAdmin[],
  dispatch: typeof store.dispatch,
  handleSubmit: (values: FormikValues) => void,
  deleteAction: (adminId: string) => () => void,
) =>
  data?.map((item) => {
    const { adminPharmaPlanId, name, email, phone, received } = item ?? {};

    return {
      key: `${adminPharmaPlanId}`,
      data: [
        {
          key: `0-${adminPharmaPlanId}`,
          value: name,
          type: DynamicTableCell.text,
        },
        {
          key: `1-${adminPharmaPlanId}`,
          value: email,
          type: DynamicTableCell.text,
        },
        {
          key: `2-${adminPharmaPlanId}`,
          value: phone,
          type: DynamicTableCell.text,
        },
        {
          key: `3-${adminPharmaPlanId}`,
          value: {
            key: '0',
            label: handleChip(received).value,
            style: handleChip(received).chipStyle,
          },

          type: DynamicTableCell.chip,
        },
        {
          key: `4-${adminPharmaPlanId}`,
          flex: 3,
          value: [
            {
              key: '1',
              tooltip: strings.edit,
              icon: EditIcon,
              onClick: () => {
                dispatch(
                  setDialog({
                    Component: (
                      <InputModal
                        onSubmit={handleSubmit}
                        initialValues={{
                          adminPharmaPlanId,
                          name,
                          email,
                          phone: phone ?? '',
                          received,
                        }}
                        submitLabel={strings.save}
                        config={createNewForm}
                        validator={Validator()}
                      />
                    ),
                    heading: {
                      title: strings.edit,
                    },
                    showCloseButton: true,
                  }),
                );
              },
            },
            {
              key: '2',
              tooltip: strings.delete,
              icon: DeleteIcon,
              onClick: deleteAction(adminPharmaPlanId),
            },
          ],
          type: DynamicTableCell.bar,
        },
      ],
    };
  });
