import React from 'react';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getDialog, isDialogOpen } from '../../../../selectors/dialogSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { resetDialog } from '../../../../reducers/dialogReducer';
import CustomDialogTitle from '../ModalCommon/CustomDialogTitle';
import classes from './style';

// Any dialog component should have two things:
// Wrap the body with <DialogContent>
// Provid with the DialogActions

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) =>
    (
    // eslint-disable-next-line react/jsx-props-no-spreading
      <Slide direction="up" ref={ref} {...props} />
    ),
);

const CustomDialog = () => {
  const {
    showCloseButton,
    Component,
    heading,
    maxWidth,
    customStyle,
    hideBackground,
  } = useAppSelector(getDialog);

  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isDialogOpen);

  const transparentPaper = hideBackground ? classes.transparentPaper : {};

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth={maxWidth || 'xs'}
      TransitionComponent={Transition}
      closeAfterTransition
      PaperProps={{ sx: transparentPaper }}
    >
      {showCloseButton && (
        <CustomDialogTitle
          onClickClose={() =>
            dispatch(resetDialog())}
          headingTitle={heading.title}
          customStyle={customStyle}
        />
      )}
      {Component}
    </Dialog>
  );
};

export default CustomDialog;
