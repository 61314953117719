import React, { FC, memo } from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import { Box } from '@mui/material';

import { ILoginCustomCard } from '../../../types/ILoginCustomCard';
import { LoginType } from '../../../helpers/Constants';

import CustomButton from '../CustomButton';
import BackToLogin from './BackToLogin';
import { classes } from './style';

const { resetPassword } = LoginType;

const LoginCustomCard: FC<ILoginCustomCard> = ({
  icon,
  customCard = {},
  textArray,
  buttonTitle,
  onClick,
  loginType,
  children,
  fullScreen = true,
}) => {
  const {
    cardContainer,
    fullHeight,
    card,
    cardHeader,
    cardContentContainer,
    cardHeaderIcon,
    textContainer,
    childrenContainer,
  } = classes;

  const isResetPassword = loginType === resetPassword;

  return (
    <Container maxWidth="xl">
      <Box sx={fullScreen ? [cardContainer, fullHeight] : [cardContainer]}>
        <Card sx={[card, customCard]}>
          {isResetPassword && <BackToLogin />}

          <Box sx={cardContentContainer}>
            <Box sx={cardHeader}>
              <Box sx={cardHeaderIcon}>{icon}</Box>
            </Box>

            {textArray.map(({ key, sx, text }) =>
              (
                <Box key={key} sx={textContainer}>
                  <Typography sx={sx}>{text}</Typography>
                </Box>
              ))}

            <Box sx={childrenContainer}>{children}</Box>
            {buttonTitle && onClick && (
              <Box sx={childrenContainer}>
                <CustomButton label={buttonTitle} onClick={onClick} />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default memo(LoginCustomCard);
