import { PharmacyLegendVariant } from '@pharmaplan/common';

const classes = {
  containerLeftBorder: (variant: `${PharmacyLegendVariant}`) =>
    ({
      backgroundColor: `pharmacy.${variant}`,
      width: '4.5px',
      borderRadius: '0px 16px 16px 0px',
    }),
  timeIcon: (variant: `${PharmacyLegendVariant}`) =>
    ({
      color: `pharmacy.${variant}`,
      padding: '0 5px 0 0',
    }),
  mainContainer: (variant: `${PharmacyLegendVariant}`) =>
    ({
      backgroundColor: `pharmacy.${variant}Light`,
      color: `pharmacy.${variant}`,
      borderRadius: '0 17px 17px 0',
      margin: '10px 0px',
    }),

  totalHrs: {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Poppins',

  },

  legendTitle: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  legendCount: {
    paddingRight: '4px',
  },
  legendInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  infoContainer: {
    padding: '4px 7px 4px 7px',
  },
  tickIcon: {
    backgroundColor: 'white',
    width: '35px',
    height: '35px',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    '&.MuiBox-root': {
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
    },
  },
  tickIconContainer: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
  },
};

export default classes;
