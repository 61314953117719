import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import {
  PharmacistReducer,
  UserReducer,
  CalendarReducer,
  RecurrenceReducer,
  StatusReducer,
  ErrorReducer,
  UtilsReducer,
  PharmacyReducer,
  NotificationsReducer,
  AdminReducer,
  PharmacyAdminReducer,
  ChatReducer,
} from '@pharmaplan/common';
import storage from 'redux-persist/lib/storage';
import dialog from './dialogReducer';
import reducer from './mainCalendarReducer';
import FormPersistReducer from './formPersistReducer';
import drawerReducer from './drawerReducer';
import NavbarReducer from './navbarReducer';

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['isLoggedIn', 'general', 'language', 'preLoginWebLang'],
};

const appReducer = combineReducers({
  status: StatusReducer,
  dialog,
  calendar: CalendarReducer,
  utilCalendar: reducer,
  utils: UtilsReducer,
  formPersist: FormPersistReducer,
  recurrence: RecurrenceReducer,
  user: persistReducer(userPersistConfig, UserReducer),
  pharmacy: PharmacyReducer,
  pharmacist: PharmacistReducer,
  error: ErrorReducer,
  drawer: drawerReducer,
  notifications: NotificationsReducer,
  admin: AdminReducer,
  pharmacyAdmin: PharmacyAdminReducer,
  navbar: NavbarReducer,
  chat: ChatReducer,
});

export default appReducer;
