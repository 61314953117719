import * as Yup from 'yup';
import strings from '../../../localization';

const Validator = () =>
  Yup.object().shape({
    startTime: Yup.string().required(strings.requiredField),
    endTime: Yup.string().required(strings.requiredField),
  });

export default Validator;
