import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  adminDeleteAdvertisement,
  adminGetAdvertisementFilters,
  adminGetAllAdvertisements,
  advertisementFilters,
  allAdvertisementsSelector,
  ConfirmationTypes,
} from '@pharmaplan/common';

import { IDynamicTableObject } from '../../DynamicTable/types';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { setDialog } from '../../../reducers/dialogReducer';
import { Constants } from '../../../helpers/Constants';
import CreateNew from '../../common/CreateNew';
import DynamicTable from '../../DynamicTable';
import strings from '../../../localization';

import { advertisingHeaders, advertisingRows } from './helper';
import useAdvertisingSuccess from '../../../hooks/Admin/AdvertisingCampaigns/useAdvertisingSuccess';
import useTableSort from '../../../hooks/useTableSort';

const { createAdvertisingCampaign, editAdvertisingCampaign } = Constants.paths.admin;

const AdvertisingCampaign = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const data = useAppSelector(allAdvertisementsSelector);
  const advertisingFilters = useAppSelector(advertisementFilters);

  const { isDesc, orderBy, order, handleSorting, resetSorting } = useTableSort();
  useAdvertisingSuccess(resetSorting);

  const goToCreate = () => {
    navigate(createAdvertisingCampaign);
  };

  const deleteAdvertisement = (id: string) =>
    () => {
      dispatch(adminDeleteAdvertisement(id));
    };

  const handleDelete = (id: string) =>
    () => {
      dispatch(
        setDialog({
          showCloseButton: false,
          Component: (
            <ConfirmationModal
              type={ConfirmationTypes.deleteEntry}
              confirmAction={deleteAdvertisement(id)}
            />
          ),
          heading: {
            title: '',
          },
        }),
      );
    };

  const handleEdit = (id: string) =>
    () => {
      navigate(editAdvertisingCampaign, { state: { id } });
    };

  const table: IDynamicTableObject = {
    title: strings.advertisingCampaigns,
    headerBar: [
      {
        key: '1',
        Component: <CreateNew label={strings.createNew} onClick={goToCreate} />,
      },
    ],
    headers: advertisingHeaders(),
    rows: advertisingRows({
      data,
      allPlatformList: advertisingFilters.platforms,
      handleDelete,
      handleEdit,
    }),
  };

  useEffect(() => {
    dispatch(adminGetAdvertisementFilters());
  }, []);

  useEffect(() => {
    dispatch(
      adminGetAllAdvertisements({
        paging: {
          sortBy: orderBy,
          desc: isDesc,
        },
      }),
    );
  }, [orderBy, isDesc]);

  return (
    <DynamicTable
      table={table}
      loadSuccess
      hidePagination
      orderBy={orderBy}
      order={order}
      handleSort={handleSorting}
    />
  );
};

export default AdvertisingCampaign;
