import React from 'react';

import { Button, Toolbar } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import { enableChat } from '@pharmaplan/common';
import {
  primaryRoute,
  secondaryRoute,
} from '../../../../selectors/navbarSelector';
import { navigationConfig, secondaryNavigationConfig } from '../config';
import { SecondaryAdminNavigation } from '../../../../helpers/Constants';
import { setSecondaryRoute } from '../../../../actions/navbarActions';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useAdminNavigation from '../../../../hooks/Admin/useAdminNavigation';
import useAdminPermissions from '../../../../hooks/Admin/useAdminPermissions';
import useAdmin from '../../../../hooks/useAdmin';

import styles from './styles';

const SecondaryToolbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const primary = useAppSelector(primaryRoute);

  const { can } = useAdminPermissions();
  const { isSuperAdmin } = useAdmin();
  const isChatEnabled = useAppSelector(enableChat);

  const navigationConf = navigationConfig(can, isSuperAdmin, isChatEnabled);
  const secondaryConfig = secondaryNavigationConfig();

  const currentSecondaryRoute = useAppSelector(secondaryRoute);
  const { pathname } = useLocation();
  const { secondaryNavigation } = useAdminNavigation();
  const handleClick = (item: SecondaryAdminNavigation) =>
    () => {
      secondaryNavigation(item);

      const { path } = secondaryConfig[item] ?? {};
      if (path) {
        navigate(path, { relative: 'path' });
      } else {
        dispatch(setSecondaryRoute(item));
      }
    };

  const primaryConfig = navigationConf.filter(
    (item) =>
      item.key === primary,
  )[0];

  return (
    <Toolbar sx={styles.toolbar}>
      {primaryConfig.subPaths.map((item) => {
        const config = secondaryConfig[item];
        const { path, subPath, label } = config;

        const isActive = currentSecondaryRoute === item
          || pathname === path
          || subPath?.includes(pathname);

        return (
          <Button
            onClick={handleClick(item)}
            key={item}
            sx={[
              styles.navButton,
              isActive ? styles.activeButton : styles.inActiveButton,
            ]}
          >
            {label}
          </Button>
        );
      })}
    </Toolbar>
  );
};
export default SecondaryToolbar;
