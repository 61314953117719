import React from 'react';

import { useFormik } from 'formik';
import { DateTime, Info } from 'luxon';
import { Box, DialogContent, Grid, Typography } from '@mui/material';

import {
  getRecurrence,
  MonthlyYearlyRecurrence,
  setRecurrence,
} from '@pharmaplan/common';

import CustomDropdown from '../../common/CustomDropdown';
import CustomDatePicker from '../../common/CustomDatePicker/CustomDatePicker';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { resetDialog } from '../../../reducers/dialogReducer';
import CustomDialogActions from '../../common/CustomModal/ModalCommon/CustomDialogActions';
import {
  DurationTypes,
  MomentTimeFormats,
  MonthlyRecurrenceDropdown,
  RepeatMap,
} from '../../../helpers/Constants';
import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';
import CustomTextField from '../../common/CustomTextField';
import { getDateObject } from '../../../helpers/workshift/functions';
import { weekElements } from '../../common/CustomWeekSelector/helper';

import {
  getDailyRecurrenceMessage,
  getMonthlyRecurrenceMessage,
  getWeeklyRecurrenceMessage,
  RepeatEveryMonthWeekComponent,
} from './helpers';
import classes from './style';
import validator from './validator';

interface ICustomRecurrence {
  date: string;
  currentRecurrence: `${DurationTypes}`;
  primaryBtnFunction: () => void;
}

const defaultProps = {};
const weekDay = [1, 2, 3, 4, 5];
const weekend = [6, 0];

const CustomRecurrence = ({
  date,
  currentRecurrence,
  primaryBtnFunction,
}: ICustomRecurrence) => {
  const dispatch = useAppDispatch();
  const recurrence = useAppSelector(getRecurrence);
  const formik = useFormik({
    initialValues: {
      date: DateTime.fromISO(date, { setZone: true }).toFormat('cccc, MMMM d'),
      repeatEvery: currentRecurrence,
      recurrenceTimes: 1,
      monthlyRecurrence: MonthlyRecurrenceDropdown.monthlyDate,
      endDate: recurrence.endDate,
      weeklyRecurrence: recurrence.weeklyRecurrence,
      weekDayRecurrence: recurrence.weekDayRecurrence || weekDay.join(','),
      weeklyRecurrenceFullDay: recurrence.weeklyRecurrenceFullDay,
      weekDayRecurrenceFullDay: weekDay.map(
        (item) =>
          weekElements()[item].fullName,
      ),
      recurrencDayMonth: recurrence.recurrencDayMonth,
      onDay: recurrence.onDay,
      onThe: recurrence.onThe,
      onTheMonth: `${
        Info.months('long').indexOf(
          getDateObject(date).toFormat(MomentTimeFormats.fullmonthname),
        ) + 1
      }`,
      onTheDay: recurrence.onTheDay,
      weekendRecurrence: recurrence.weekendRecurrence || weekend.join(','),
      weekendRecurrenceFullDay: weekend.map((item) =>
        weekElements()[item].fullName),
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        setRecurrence({
          endDate: values.endDate,
          weekDayRecurrence: values.weekDayRecurrence,
          repeatEvery: RepeatMap[values.repeatEvery],
          recurrencDayMonth: values.recurrencDayMonth,
          weeklyRecurrence: values.weeklyRecurrence,
          weeklyRecurrenceFullDay: values.weeklyRecurrenceFullDay,
          onDay: values.onDay,
          onThe: values.onThe,
          onTheMonth: values.onTheMonth,
          onTheDay: values.onTheDay,
          weekendRecurrence: values.weekendRecurrence,
        }),
      );
      console.log(values);
      primaryBtnFunction();
    },
    validationSchema: validator,
  });
  const feedbackMessage = () => {
    const {
      repeatEvery,
      recurrenceTimes,
      weeklyRecurrenceFullDay,
      recurrencDayMonth,
      onDay,
      weekDayRecurrenceFullDay,
      onThe,
      weekendRecurrenceFullDay,
      onTheDay,
    } = formik.values;

    let message;
    switch (repeatEvery) {
      case DurationTypes.day:
        message = getDailyRecurrenceMessage(recurrenceTimes);
        break;
      case DurationTypes.week:
        message = getWeeklyRecurrenceMessage(
          recurrenceTimes,
          weeklyRecurrenceFullDay,
        );
        break;
      case DurationTypes.weekday:
        message = getWeeklyRecurrenceMessage(
          recurrenceTimes,
          weekDayRecurrenceFullDay,
        );
        break;
      case DurationTypes.weekend:
        message = getWeeklyRecurrenceMessage(
          recurrenceTimes,
          weekendRecurrenceFullDay,
        );
        break;
      case DurationTypes.month:
        message = getMonthlyRecurrenceMessage(
          recurrenceTimes,
          recurrencDayMonth as MonthlyYearlyRecurrence,
          onDay,
          onThe as number,
          parseInt(onTheDay, 10),
        );
        break;

      default:
        break;
    }
    return message;
  };

  const durationType = [
    { key: DurationTypes.day, label: strings.day },
    { key: DurationTypes.week, label: strings.week },
    { key: DurationTypes.weekday, label: strings.weekday },
    { key: DurationTypes.weekend, label: strings.weekend },
    { key: DurationTypes.month, label: strings.month },
  ];

  return (
    <>
      <DialogContent>
        <Box>
          <CustomTextField
            name="date"
            id="date"
            customClass={genericClasses.addAvailabilityMargin}
            label={strings.start}
            formik={formik}
            horizontal
            disabled
          />

          <Box sx={classes.mb}>
            <CustomDropdown
              label={strings.repeatEvery}
              horizontal
              formik={formik}
              isRecurrence
              name="repeatEvery"
              menuItems={durationType}
              errorContainer={false}
            />
          </Box>

          {RepeatEveryMonthWeekComponent(formik)}
          <Grid container flexDirection="row" sx={[classes.mb]}>
            <Grid item xs={12}>
              <CustomDatePicker
                error=""
                customClass={classes.calendarSelectCont}
                minDate={DateTime.fromISO(date)}
                formik={formik}
                name="endDate"
                horizontal
                readOnly
                label={strings.endsOn}
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={8} sx={[classes.fontSize]}>
              <Typography>
                {`${strings.occursEvery} ${feedbackMessage()}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <CustomDialogActions
        primaryBtnTitle={strings.save}
        primaryFunction={formik.handleSubmit}
        secondaryFunction={() =>
          dispatch(resetDialog())}
        secondaryBtnTitle={strings.cancel}
      />
    </>
  );
};

CustomRecurrence.defaultProps = defaultProps;

export default CustomRecurrence;
