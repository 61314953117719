import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
