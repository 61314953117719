import React, { useEffect } from 'react';

import {
  adminActions,
  adminGetDeletedUsers,
  adminPermanentlyDeleteSelected,
  ConfirmationTypes,
  pendingActivationList,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import { setDialog } from '../../reducers/dialogReducer';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../localization';
import useTableSelectCheckbox from '../useTableSelectCheckbox';

const { deletePermanentlyPendingUsers } = adminActions;

const useDeletePendingActivations = (
  setPage: React.Dispatch<React.SetStateAction<number>>,
  page: number,
) => {
  const dispatch = useAppDispatch();
  const success = useAppSelector((state) =>
    successSelector([deletePermanentlyPendingUsers], state));

  const pendingActivations = useAppSelector(pendingActivationList);
  const { data } = pendingActivations ?? {};
  const {
    selectAllFunc,
    selectAll,
    selectedIds,
    handleReset,
    allIds,
    handlePress,
  } = useTableSelectCheckbox({
    page,
    userType: 'pendingActivations',
    useOfEdit: false,
    dataLength: data.length,
  });

  const permanentDelete = () => {
    dispatch(adminPermanentlyDeleteSelected(allIds));
  };

  const count = allIds.length;

  const confirmDelete = () => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.permanentDelete}
            confirmAction={permanentDelete}
            count={count}
          />
        ),
        showCloseButton: false,
        heading: {
          title: '',
        },
      }),
    );
  };

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.recordDeletedSuccessfully);
      dispatch(resetStatus([deletePermanentlyPendingUsers]));
      dispatch(adminGetDeletedUsers({ page: 1 }));
      setPage(1);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return {
    data: pendingActivations?.data,
    handlePress,
    handleDelete: confirmDelete,
    selectAllFunc: selectAllFunc({ data, idProperty: 'userId' }),
    selectedIds,
    resetSelected: handleReset,
    selectAll,
  };
};

export default useDeletePendingActivations;
