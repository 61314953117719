import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    backgroundColor: 'white',
    display: 'flex',
    padding: '16px',
    transition: 'transform 0.2s',
    border: '2px solid transparent',
    overflow: 'hidden',
    '&:hover': {
      color: 'common.border',
      backgroundColor: 'common.hover',
      cursor: 'pointer',
      transform: 'scale(1.04)',
      border: '2px solid white',
    },
  },

  counterContainer: {
    marginBottom: '6px',
  },

  counterCard: {
    position: 'relative',
  },

  avatar: {
    height: '68px',
    width: '68px',
  },

  avatarContainer: {
    position: 'relative',
  },

  content: {
    flex: 1,
    marginLeft: '8px',
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  rowContainer: {
    display: 'flex',
    flexDirection: 'flex-end',
    alignItems: 'center',
  },

  nameText: {
    fontWeight: 600,
    fontSize: '16px',
    color: 'common.grey850',
  },

  titleMargin: {
    marginRight: '4px',
    display: 'flex',
    alignItems: 'center',
  },

  subtitleContainer: {
    display: 'flex',
    marginY: '3px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  favouriteContainer: {
    position: 'absolute',
    width: '17px',
    height: '17px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: '5px',
    bottom: '5px',
  },

  subtitle: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'grey.500',
    textAlign: 'center',
  },

  offerPrice: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'common.grey850',
  },

  counterOfferPrice: {
    textDecorationLine: 'line-through',
    color: 'grey.500',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'center',
    marginRight: '8px',
  },

  counterOfferCard: {
    position: 'absolute',
    right: 15,
    borderRadius: '0px 0px 5.762px 5.762px',
    zIndex: 100,
    width: '72.981px',
    height: '17.875px',
    backgroundColor: 'common.counterRed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  counterOfferCardText: {
    fontSize: '9px',
    fontWeight: 600,
    textAlign: 'center',
    color: 'white',
  },

  ratingText: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'common.grey850',
    textAlign: 'center',
  },

  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
