import React from 'react';

import {
  AllowanceTypes,
  TypeOfSummary,
  convertToDecimals,
} from '@pharmaplan/common';
import { ReactComponent as GreenTick } from '@pharmaplan/common/assets/icons/greenTick.svg';
import { ReactComponent as CrossIcon } from '@pharmaplan/common/assets/icons/crossIcon.svg';

import strings from '../../../localization';

type IValue = string | number | null;
type IHandleValues = number | undefined | null;

const { pharmacist, workshiftDetails } = TypeOfSummary;
const { covered, notCovered, custom } = AllowanceTypes;

export const displayIcon = (allowanceType: AllowanceTypes, value?: IValue) => {
  const parsedAllowanceType = (allowanceType ?? notCovered).toString();

  switch (parsedAllowanceType) {
    case covered:
      return { icon: <GreenTick />, data: strings.covered };
    case notCovered:
      return { icon: <CrossIcon />, data: strings.notCovered };
    case custom:
      return { icon: <GreenTick />, data: value };
    default:
      return { icon: <CrossIcon />, data: strings.notCovered };
  }
};

export const handleValues = (values: IHandleValues, fallback: string) => {
  if (!values) {
    return fallback;
  }

  return `$${convertToDecimals(values)}`;
};

export const title = (summaryType: TypeOfSummary | undefined) => {
  switch (summaryType) {
    case pharmacist:
      return strings.pharmacist;
    case workshiftDetails:
      return strings.workshiftDetails;
    default:
      return strings.pharmacist;
  }
};
