import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = {
  container: {
    my: '4px',
  },
  modalMargin: {
    marginBottom: '30px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
