import { AvailabilityTypes } from '@pharmaplan/common';

// PARAMS NECESSARY FOR ONE BLOCK OF CALENDAR EVENT TO FUNCTION
export type IBaseCalendarEvent = {
  id: string;
  selectedDate: string | null;
  startDate: string | null;
  endDate: string | null;
  type: AvailabilityTypes;
  status: number;
  count: number;
  end: string | null;
  start: string | null;
};

const EventCreator = () => {
  // CREATE CALENDAR EVENT WITH TYPE CHECK
  const createBaseCalendarEvent = (params: IBaseCalendarEvent) =>
    ({
      ...params,
    });

  return { createBaseCalendarEvent };
};

export default EventCreator;
