import { createAction } from '@reduxjs/toolkit';
import {
  PrimaryAdminNavigation,
  SecondaryAdminNavigation,
} from '../helpers/Constants';

export const actionTypes = {
  SET_SECONDARY_ROUTE: 'SET_SECONDARY_ROUTE',
  SET_PRIMARY_ROUTE: 'SET_PRIMARY_ROUTE',
  RESET_SECONDARY_ROUTE: 'RESET_SECONDARY_ROUTE',
};

export const setSecondaryRoute = createAction<SecondaryAdminNavigation | null>(
  actionTypes.SET_SECONDARY_ROUTE,
);
export const setPrimaryRoute = createAction<PrimaryAdminNavigation | null>(
  actionTypes.SET_PRIMARY_ROUTE,
);
export const resetSecondaryRoute = createAction(actionTypes.RESET_SECONDARY_ROUTE);
