import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    paddingX: '23px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'grey.800',
    mb: '6px',
  },
  itemContainer: {
    mb: '6px',
  },
  notes: {
    mb: 10,
  },
  menuContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },

} satisfies Record<string, SxProps<Theme>>;
export default styles;
