import React, { useEffect } from 'react';

import {
  ConfirmationTypes,
  deleteUserAccount,
  resetStatus,
  successSelector,
  userActions,
} from '@pharmaplan/common';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import { resetDialog, setDialog } from '../reducers/dialogReducer';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { logoutHandler } from '../helpers/Functions';

const useUserDelete = () => {
  const dispatch = useAppDispatch();
  const deleteSuccess = useAppSelector((state) =>
    successSelector([userActions.deleteUser], state));

  const deleteUser = () => {
    dispatch(deleteUserAccount());
  };

  const confirmDelete = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.userDelete}
            showCloseButton
            confirmAction={deleteUser}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  useEffect(() => {
    if (deleteSuccess) {
      logoutHandler();
      dispatch(resetStatus());
      dispatch(resetDialog());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  return { handleDelete: confirmDelete };
};

export default useUserDelete;
