import React, { useEffect } from 'react';

import {
  adminActions,
  getPendingActivationsCount,
  PendingTabTypes,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { resetDrawer } from '../../actions/drawerActions';
import { setDialog } from '../../reducers/dialogReducer';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import usePendingActivations from './usePendingActivations';
import { OutcomeModalTypes } from '../../helpers/Constants';
import OutcomeModal from '../../components/Modals/OutcomeModal';
import strings from '../../localization';

const { activateUser, deletePendingUser } = adminActions;

const usePendingActivationRefresh = (tab: PendingTabTypes) => {
  const dispatch = useAppDispatch();
  const activateSuccess = useAppSelector((state) =>
    successSelector([activateUser], state));

  const deleteSuccess = useAppSelector((state) =>
    successSelector([deletePendingUser], state));
  const { pendingActivationsDefault } = usePendingActivations(tab);

  const successModal = (type: OutcomeModalTypes) => {
    const message = {
      [OutcomeModalTypes.activationSuccess]: strings.userActivatedSuccessfully,
      [OutcomeModalTypes.deletionSuccess]: strings.recordDeletedSuccessfully,
    };

    dispatch(
      setDialog({
        Component: (
          <OutcomeModal
            message={message[type as keyof typeof message]}
            type={type}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const reset = () => {
    pendingActivationsDefault();
    dispatch(resetDrawer());
    dispatch(resetStatus([activateUser, deletePendingUser]));
    dispatch(getPendingActivationsCount());
  };

  useEffect(() => {
    if (activateSuccess) {
      successModal(OutcomeModalTypes.activationSuccess);
      reset();
    } else if (deleteSuccess) {
      successModal(OutcomeModalTypes.deletionSuccess);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateSuccess, deleteSuccess]);
};

export default usePendingActivationRefresh;
