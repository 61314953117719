const classes = {
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    pb: 5,
  },
  text: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '500',
    whiteSpace: 'pre-line',
  },
  closeContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    color: 'common.grey350',
    margin: '10px',
    backgroundColor: 'common.lightBackgroundColor',
    padding: '3px',
  },
};

export default classes;
