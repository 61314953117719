import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const styles = {
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start !important',
    padding: '16px',
    '&:hover': {
      backgroundColor: ThemeConstants.common.lightBackgroundColor,
      color: ThemeConstants.primary.main,
    },
  },
  label: {
  },
  subtitle: {
    color: ThemeConstants.common.blue400,
    fontSize: 10,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
