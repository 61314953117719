import { Box, InputLabel, TextField } from '@mui/material';
import React, { ChangeEvent, ReactNode } from 'react';
import { FormikProps } from 'formik';
import { SystemStyleObject } from '@mui/system';
import InputMask from 'react-input-mask';
import { Constants, TextFieldVariants } from '../../../helpers/Constants';
import classes from './style';
import genericClasses from '../../../theme/GenericClasses';

interface ICustomTextField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  name: string;
  label?: string;
  requireLabel?: boolean;
  horizontal?: boolean;
  customClass?: Record<string, unknown>;
  mask: string;
  variant?: TextFieldVariants;
}

const defaultProps = {
  label: Constants.null,
  requireLabel: false,
  customClass: {},
  horizontal: false,
};

const CustomMaskedTextField = ({
  formik,
  name,
  label,
  requireLabel,
  horizontal,
  customClass,
  mask,
  variant,
}: ICustomTextField) => {
  const formikErrorValidate = formik?.errors[name] && formik?.touched[name] && formik?.errors[name];
  const isFilled = variant === TextFieldVariants.filled;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik?.setFieldValue(name, e.target.value);
  };

  return (
    <Box
      component="div"
      sx={[
        classes.customTextFieldContainer,
        horizontal ? classes.horizontalTextField : {},
        customClass as SystemStyleObject,
      ]}
      data-testid="test"
    >
      {label && (
        <InputLabel>
          {label}
          {requireLabel && (
            <Box component="span" sx={classes.customTextFieldRequireLabel}>
              *
            </Box>
          )}
        </InputLabel>
      )}
      <InputMask
        onChange={handleChange}
        mask={mask}
        value={formik.values[name]}
        disabled={false}
        maskChar=""
      >
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
        {() =>
          (
            <TextField
              helperText={(formikErrorValidate as ReactNode) ?? ' '}
              error={!!formikErrorValidate}
              variant={variant}
              sx={isFilled ? genericClasses.customFilledInput : {}}
              fullWidth
            />
          )}
      </InputMask>
    </Box>
  );
};
export default CustomMaskedTextField;

CustomMaskedTextField.defaultProps = defaultProps;
