import { FormikValues, useFormik } from 'formik';
import {
  adminActions,
  adminCreateNote,
  adminCreatePharmacyNote,
  adminGetAllNotes,
  adminGetAllPharmacyNotes,
  resetStatus,
  successOrSelector,
} from '@pharmaplan/common';
import { useEffect } from 'react';
import { useAppDispatch } from '../../useAppDispatch';
import { AdminNotesType } from '../../../helpers/Constants';
import { useAppSelector } from '../../useAppSelector';
import Validator from '../../../components/Admin/AdminNotes/validator';

const { pharmacist } = AdminNotesType;

const successActions = [
  adminActions.createNote,
  adminActions.createPharmacyNote,
];

const useCreateAdminNotes = (id: string, notesType: AdminNotesType) => {
  const dispatch = useAppDispatch();

  const createSuccess = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const isPharmacist = notesType === pharmacist;

  const onSubmit = (values: FormikValues) => {
    const { note } = values ?? {};

    isPharmacist
      ? dispatch(adminCreateNote({ pharmacistId: id, notes: note }))
      : dispatch(adminCreatePharmacyNote({ pharmacyId: id, notes: note }));
  };

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    onSubmit,
    validationSchema: Validator(),
  });

  const { resetForm } = formik ?? {};

  const initCall = () => {
    isPharmacist
      ? dispatch(adminGetAllNotes(id))
      : dispatch(adminGetAllPharmacyNotes(id));
  };

  useEffect(() => {
    if (createSuccess) {
      initCall();
      dispatch(resetStatus(successActions));
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);

  return { formik };
};

export default useCreateAdminNotes;
