/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import {
  AllowanceTypes,
  AllowancesNames,
  SecondaryInputTypes,
} from '@pharmaplan/common';
import { FormikProps } from 'formik';
import classes from './style';
import strings from '../../../../localization';
import CustomTextField from '../../../common/CustomTextField/CustomTextField';
import CustomDropdown from '../../../common/CustomDropdown/CustomDropdown';
import genericClasses from '../../../../theme/GenericClasses';

interface IAllowanceWorkshift {
  formik: FormikProps<any>;
  isCounterOffer?: boolean;
}

const { covered, notCovered, custom } = AllowanceTypes;
const { single, double } = SecondaryInputTypes;

const {
  travelAllowanceRatePerKm,
  travelAllowanceType,
  minFreeTravelDistance,
  fixedTravelAmount,
  accommodationAllowanceType,
  fixedAccommodationRate,
  fixedMealRate,
  mealAllowanceType,
  paidHourlyRate,
  emergencyFees,
} = AllowancesNames;

const {
  travelLabel,
  travelTextField,
  hourlyRate,
  secondaryInputContainer,
  title,
} = classes;
const { borderNoneTextField } = genericClasses;

enum DropdownKeys {
  Covered = 0,
  NotCovered = 1,
  FixedAmount = 2,
  Calculate = 3,
}

const { Covered, NotCovered, FixedAmount, Calculate } = DropdownKeys;

const AllowanceWorkshift = ({
  formik,
  isCounterOffer,
}: IAllowanceWorkshift) => {
  const { values } = formik ?? {};

  const allowanceConfig = [
    {
      id: Covered,
      key: covered,
      label: strings.covered,
    },
    {
      id: NotCovered,
      key: notCovered,
      label: strings.notCovered,
    },
    {
      id: FixedAmount,
      key: custom,
      label: strings.fixedAmount,
    },
    {
      id: Calculate,
      key: custom,
      label: strings.calculate,
    },
  ];

  const filterCalculate = allowanceConfig.filter(({ id }) =>
    id !== Calculate);

  const filterFixedAmount = allowanceConfig.filter(
    ({ id }) =>
      id !== FixedAmount,
  );

  const travelConfig = isCounterOffer ? filterCalculate : filterFixedAmount;

  const renderConfig = [
    {
      key: '1',
      label: strings.travelAllowances,
      name: travelAllowanceType,
      config: travelConfig,
      secondaryInput: {
        type: isCounterOffer ? single : double,
        label: isCounterOffer ? strings.enterAmount : strings.forEveryKmAbove,
        name: isCounterOffer ? fixedTravelAmount : minFreeTravelDistance,
        doubleName: travelAllowanceRatePerKm,
        showOn: custom,
      },
    },
    {
      key: '2',
      label: strings.accommodationAllowances,
      name: accommodationAllowanceType,
      config: filterCalculate,
      secondaryInput: {
        type: single,
        label: strings.enterAmount,
        name: fixedAccommodationRate,
        showOn: custom,
      },
    },
    {
      key: '3',
      label: strings.mealAllowances,
      name: mealAllowanceType,
      config: filterCalculate,
      secondaryInput: {
        type: single,
        label: strings.enterAmount,
        name: fixedMealRate,
        showOn: custom,
      },
    },
  ];

  const handleSecondaryInput = (
    name: string,
    label: string,
    type: SecondaryInputTypes,
    doubleName?: string,
  ) => {
    switch (type) {
      case single:
        return (
          <CustomTextField
            usePreFilledColors={isCounterOffer}
            label={label}
            horizontal
            suffix="$"
            placeholder=" "
            name={name}
            id={name}
            formik={formik}
            customClass={hourlyRate}
          />
        );
      case double:
        return (
          <Box sx={secondaryInputContainer}>
            <CustomTextField
              usePreFilledColors={isCounterOffer}
              suffix="$"
              customClass={travelTextField}
              formik={formik}
              id={doubleName ?? ''}
              name={doubleName ?? ''}
              isCustomError
            />

            <Typography sx={travelLabel}>{strings.forEveryKmAbove}</Typography>

            <CustomTextField
              usePreFilledColors={isCounterOffer}
              customClass={travelTextField}
              suffix="km"
              name={name}
              placeholder=" "
              id={name}
              maxLength={4}
              formik={formik}
              isCustomError
            />
          </Box>
        );
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <Typography sx={title}>{strings.rateAndAllowances}</Typography>

      <CustomTextField
        usePreFilledColors={isCounterOffer}
        label={strings.hourlyRate}
        name={paidHourlyRate}
        formik={formik}
        placeholder=" "
        suffix="$"
        customClass={hourlyRate}
        id={paidHourlyRate}
        horizontal
      />
      {renderConfig.map((item) => {
        const { key, label, config, name, secondaryInput } = item ?? {};
        const {
          name: secondaryName,
          doubleName,
          type,
          label: secondaryLabel,
          showOn,
        } = secondaryInput ?? {};

        return (
          <Fragment key={key}>
            <CustomDropdown
              customClass={borderNoneTextField}
              key={key}
              label={label}
              menuItems={config}
              formik={formik}
              usePreFilledColors
              name={name}
            />
            {values[name] === showOn ? (
              handleSecondaryInput(
                secondaryName,
                secondaryLabel,
                type,
                doubleName,
              )
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}

      {!isCounterOffer && (
        <CustomTextField
          usePreFilledColors={isCounterOffer}
          customClass={borderNoneTextField}
          label={strings.optionalEmergencyFees}
          placeholder={strings.enterFixedAmount}
          formik={formik}
          id={emergencyFees}
          name={emergencyFees}
        />
      )}
    </Box>
  );
};

export default AllowanceWorkshift;
