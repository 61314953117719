export const createOffset = (
  latLng: {latitude: number; longitude: number},
  offLat: number,
  offLong: number,
) => {
  // EARTH'S RADIUS
  const R = 6378137;

  const dLat = offLat / R;
  const dLong = offLong / (R * Math.cos((Math.PI * latLng.latitude) / 180));

  return {
    lat: latLng.latitude + dLat * (180 / Math.PI),
    lng: latLng.longitude + dLong * (180 / Math.PI),
  };
};
