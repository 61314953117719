import { ITheme } from '.';

export default {
  styleOverrides: {
    root: ({ theme }: ITheme) =>
      ({
        color: theme.palette.grey[800],

        fontSize: '14px',
        marginBottom: '8px',
        fontWeight: 500,
      // lineHeight: "1.31 rem",
      }),
  },
};
