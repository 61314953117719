import { useState } from 'react';
import { SortOrder } from '../helpers/Constants';

const useTableSort = () => {
  const [order, setOrder] = useState(SortOrder.asc);
  const [orderBy, setOrderBy] = useState('');

  const handleSorting = (propertyToOrderBy: string) =>
    () => {
      setOrderBy(propertyToOrderBy);
      setOrder((prev) =>
        (prev === SortOrder.asc ? SortOrder.desc : SortOrder.asc));
    };

  const resetSorting = () => {
    setOrder(SortOrder.asc);
    setOrderBy('');
  };

  return { isDesc: order === SortOrder.desc, orderBy, handleSorting, order, resetSorting };
};

export default useTableSort;
