import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Box, Typography } from '@mui/material';
import styles from './style';

interface IFormikError {
  formik: FormikProps<any>;
  name: string;
  horizontal?: boolean;
}

const FormikError: FC<IFormikError> = ({ formik, horizontal, name }) =>
  (
    <Box component="div">
      {formik.errors[name] && formik.touched[name] && (
      <Typography sx={[styles.error, horizontal ? styles.horizontalValue : {}]}>
        {formik.errors[name] as string}
      </Typography>
      )}
    </Box>
  );
export default FormikError;
