import * as Yup from 'yup';
import { AdminAllowanceNames, AllowancesNames } from '@pharmaplan/common';
import strings from '../../../localization';
import {
  allowanceValidator,
  hourlyRateValidator,
} from '../../../helpers/commonValidators';

const Validator = () =>
  Yup.object().shape({
    [AdminAllowanceNames.mealAllowance]: allowanceValidator(),
    [AdminAllowanceNames.accommodationAllowance]: allowanceValidator(),
    [AdminAllowanceNames.travelAllowance]: allowanceValidator(),
    [AllowancesNames.paidHourlyRate]: hourlyRateValidator(),
    [AllowancesNames.emergencyFees]: allowanceValidator(),
    startTime: Yup.string().required(strings.requiredField),
    endTime: Yup.string().required(strings.requiredField),
  });

export default Validator;
