import { SxProps, Theme } from '@mui/system';

const styles = {
  root: {
    padding: '15px 20px 32px 20px',
    overflow: 'hidden',
  },

  container: {
    display: 'flex',
  },

  avatar: {
    height: 100,
    width: 100,
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 24px 24px 24px',
  },

  input: {
    display: 'none',
  },

  chooseFileButton: {
    paddingBottom: '12px',
  },

  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  buttonWidth: {
    width: 140,
  },

  messageFont: {
    fontSize: 12,
    color: 'grey.500',
  },

  errorFont: {
    fontSize: 12,
    color: 'common.error',
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  fileNameFont: {
    fontWeight: 500,
    maxWidth: '100px',
    textOverflow: 'ellipsis',
    paddingRight: '20px',
  },

  iconButton: {
    padding: 0,
    ml: '2px',
    alignItems: 'flex-start',
    mt: '4px',
  },

  crossIcon: {
    height: 14,
    width: 14,
    color: 'common.gray',
  },

  imageName: {
    display: 'flex',
    flexWrap: 'wrap',
    wordBreak: 'break-all',
    color: 'primary.main',
    fontSize: 14,
    fontWeight: 400,
    textDecorationLine: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  fileContainer: {
    display: 'flex',
    mb: '10px',
    padding: '0px 24px 0px 24px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
