import { useEffect } from 'react';

import {
  adminActions,
  calendarDate,
  resetStatus,
  successOrSelector,
} from '@pharmaplan/common';

import { resetDrawer } from '../../actions/drawerActions';

import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import { resetDialog } from '../../reducers/dialogReducer';
import useAdminDashboard from './useAdminDashboard';

const {
  deleteWorkshift,
  editAdminWorkshift,
  ignoreRequest,
  requestCancellation,
  bookAvailablity,
  approveBooking,
  deletePharmacyWorkshift,
} = adminActions;

const useAdminRefresh = () => {
  const dispatch = useAppDispatch();
  const { dashBasedRequest } = useAdminDashboard();

  const cDate = useAppSelector(calendarDate);

  const actions = [
    deleteWorkshift,
    editAdminWorkshift,
    ignoreRequest,
    requestCancellation,
    bookAvailablity,
    approveBooking,
    deletePharmacyWorkshift,
  ];

  const success = useAppSelector((state) =>
    successOrSelector(actions, state));

  useEffect(() => {
    if (success) {
      dashBasedRequest();
      dispatch(resetStatus(actions));
      dispatch(resetDialog());
      dispatch(resetDrawer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cDate, success]);
};

export default useAdminRefresh;
