import React, { FC } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { ReactComponent as StarNotFilled } from '@pharmaplan/common/assets/icons/starNotFilled.svg';
import { ReactComponent as RatingStar } from '@pharmaplan/common/assets/icons/ratingStar.svg';
import style from './style';

interface ICustomRatingStar {
    text: string;
    count: number;
    ratingLevel: number;
    handleClick: (starIndex: number) => void;
  }

const CustomRatingStar: FC<ICustomRatingStar> = ({ text, count, handleClick,
  ratingLevel }) => {
  const theme = useTheme();

  const renderStars = () =>
    Array.from({ length: count }).map((_, index) => {
      const starIndex = index + 1;
      const isFilled = starIndex <= ratingLevel;

      return (
        <IconButton
          key={starIndex}
          onClick={() =>
            handleClick(starIndex)}
        >
          {isFilled ? (
            <RatingStar fill={theme.palette.primary.main} />
          ) : (
            <StarNotFilled />
          )}
        </IconButton>
      );
    });

  return (
    <Box sx={style.container}>
      <Typography sx={style.text}>
        {text}
      </Typography>
      <Box>
        {renderStars()}
      </Box>
    </Box>
  );
};

export default CustomRatingStar;
