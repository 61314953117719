import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { Box } from '@mui/material';

import {
  adminActions,
  getOtherAvailabilities,
  ICalendarEventType,
  selectOtherAvailabilities,
  successSelector,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import DynamicTable from '../../../DynamicTable';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import strings from '../../../../localization';

import AvailabilitiesFooter from '../AvailabilitiesFooter';
import { handleClick, tableHeaderConfig, tableRowConfig } from '../helper';
import classes from './style';
import AvailabilitiesHeader from '../AvailabilitiesHeader/AvailabilitiesHeader';
import NoAvailabilities from '../NoAvailabilities/NoAvailabilities';
import genericClasses from '../../../../theme/GenericClasses';
import { renderScreen } from '../../../../actions/drawerActions';
import { ScreenTypes } from '../../../../helpers/Constants';
import useDeleteAvailability from '../../../../hooks/Admin/useDeleteAvailability';

interface IOtherAvailabilities {
  setShowOtherAvailabilities: Dispatch<SetStateAction<boolean>>;
  workshiftId: string;
  type: ICalendarEventType;
}

const OtherAvailabilities: FC<IOtherAvailabilities> = ({
  setShowOtherAvailabilities,
  workshiftId,
  type,
}) => {
  const dispatch = useAppDispatch();
  const { showDeletePreSubmit } = useDeleteAvailability();

  const otherAvailabilities = useAppSelector(selectOtherAvailabilities);
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const loadSuccess = useAppSelector((state) =>
    successSelector([adminActions.getOtherAvailabilities], state));

  const emptyContainerComponent = otherAvailabilities.length === 0
    ? <NoAvailabilities message={strings.noOtherAvailabilitiesToShow} /> : null;

  const back = () => {
    dispatch(
      renderScreen({
        screenNumber: 4,
        screenType: ScreenTypes.availableWorkshift,
        eventId: workshiftId,
        type,
      }),
    );
  };

  const table = {
    title: '',
    headerBar: [],
    headers: tableHeaderConfig(),
    rows: tableRowConfig({
      data: otherAvailabilities,
      timeFormat,
      dispatch,
      workshiftId,
      type,
      back,
      showDeletePreSubmit,
    }),
  };

  useEffect(() => {
    dispatch(getOtherAvailabilities(workshiftId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={classes.root}>
      <DynamicTable
        table={table}
        hidePagination
        loadSuccess={loadSuccess}
        showHeader={false}
        noContainerStyling
        customTableHeadStyle={classes.tableHead}
        stickyHeader
        customContainerStyle={genericClasses.tableScroll}
        emptyContainerComponent={emptyContainerComponent}
        customHeader={
          <AvailabilitiesHeader title={strings.otherAvailabilities} />
        }
        customFooter={(
          <AvailabilitiesFooter
            label={strings.hideOtherAvailabilities}
            onClick={handleClick(setShowOtherAvailabilities, true)}
          />
        )}
      />
    </Box>
  );
};

export default OtherAvailabilities;
