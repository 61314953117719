import React, { useEffect } from 'react';
import {
  ConfirmationTypes,
  PendingCancellationTabTypes,
  adminActions,
  adminPermDeleteCancelled,
  adminPermDeleteRejected,
  cancelledBookingsList,
  getCancelledBookings,
  getRejectedCancellations,
  rejectedCancellationsList,
  resetStatus,
  successOrSelector,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import { setDialog } from '../../../reducers/dialogReducer';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../../localization';
import useTableSelectCheckbox from '../../useTableSelectCheckbox';

const { permDeleteCancelledBookings, permDeleteRejectedCancellations } = adminActions;

const successActions = [
  permDeleteCancelledBookings,
  permDeleteRejectedCancellations,
];

const usePendingCancellationDelete = (
  setPage: React.Dispatch<React.SetStateAction<number>>,
  selectedTab: PendingCancellationTabTypes,
  page: number,
) => {
  const dispatch = useAppDispatch();

  const cancelledBookings = useAppSelector(cancelledBookingsList);
  const rejectedCancellations = useAppSelector(rejectedCancellationsList);

  const { data: cancelledData } = cancelledBookings ?? {};
  const { data: rejectedData } = rejectedCancellations ?? {};

  const success = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const isCancelledBookings = selectedTab === PendingCancellationTabTypes.cancelledBookings;

  const data = isCancelledBookings ? cancelledData : rejectedData;
  const api = isCancelledBookings
    ? getCancelledBookings
    : getRejectedCancellations;

  const {
    selectAll,
    selectAllFunc,
    selectedIds,
    setSelectAll,
    setSelectedIds,
    handlePress,
    allIds,
    handleReset,
  } = useTableSelectCheckbox({
    page,
    useOfEdit: false,
    userType: 'pendingCancellation',
    dataLength: data?.length,
  });

  const permanentDelete = () => {
    if (isCancelledBookings) {
      dispatch(adminPermDeleteCancelled(allIds));
    } else {
      dispatch(adminPermDeleteRejected(allIds));
    }
  };

  const count = allIds.length;

  const confirmDelete = () => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.permanentDelete}
            confirmAction={permanentDelete}
            count={count}
          />
        ),
        showCloseButton: false,
        heading: {
          title: '',
        },
      }),
    );
  };

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.recordDeletedSuccessfully);
      dispatch(resetStatus(successActions));
      dispatch(api({ page: 1 }));
      handleReset();
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return {
    handlePress,
    selectAllFunc: selectAllFunc({ data, idProperty: isCancelledBookings ? 'bookingId' : 'cancellationRequestId' }),
    setSelectedIds,
    handleReset,
    selectAll,
    setSelectAll,
    selectedIds,
    handleDelete: confirmDelete,
  };
};

export default usePendingCancellationDelete;
