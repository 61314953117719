import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import classes from './style';

interface INoAvailabilities {
  message: string | string[];
}

const NoAvailabilities: FC<INoAvailabilities> = ({ message }) =>
  (
    <Box sx={classes.root}>
      <Typography sx={classes.messageFont}>{message}</Typography>
    </Box>
  );

export default NoAvailabilities;
