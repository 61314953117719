import React from 'react';

import { Typography } from '@mui/material';
import { FormikValues } from 'formik';

import {
  adminPharmacistPreSubmitVacations,
  adminPharmacistVacations,
  ConfirmationTypes,
  getTypeOfUser,
  IVacationsParams,
  pharmacistVacations,
  SelectionType,
  selectPharmacistPreSubmitVacations,
  TypeOfUser,
} from '@pharmaplan/common';

import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import styles from '../../components/common/CreateVacation/style';
import { showError } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import { appendTimeToDate } from '../../helpers/workshift/functions';
import { MomentTimeFormats } from '../../helpers/Constants';
import strings from '../../localization';
import { resetDialog, setDialog } from '../../reducers/dialogReducer';

import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import useAdmin from '../useAdmin';

const { fullDate } = MomentTimeFormats;

const { admin, superAdmin, pharmacist } = TypeOfUser;
const { single, multiple } = SelectionType;

const useVacationsCriteria = (selectionType = single) => {
  const dispatch = useAppDispatch();

  const { isAdmin } = useAdmin();

  const preSubmit = useAppSelector(selectPharmacistPreSubmitVacations);
  const adminPreSubmit = useAppSelector(adminPharmacistPreSubmitVacations);
  const userType = useAppSelector(getTypeOfUser);

  const isMultiple = selectionType === multiple;

  const { hasAvailable, hasBooking, hasRequested } = (isAdmin ? adminPreSubmit : preSubmit) ?? {};

  const adminMessages = {
    bookedMessage: strings.adminVacationBookedConflict,
    message: hasRequested
      ? strings.vacationRequestedConflict
      : strings.vacationAvailabilityConflict,
  };

  const messageConfig = {
    [single]: {
      [admin]: adminMessages,
      [superAdmin]: adminMessages,
      [pharmacist]: {
        bookedMessage: strings.vacationBookedConflict,
        message: hasRequested
          ? strings.vacationRequestedConflict
          : strings.vacationAvailabilityConflict,
      },
    },
    [multiple]: {
      [admin]: adminMessages,
      [superAdmin]: adminMessages,
      [pharmacist]: {
        bookedMessage: strings.multipleVacationBookedConflict,
        message: hasRequested
          ? strings.multipleVacationRequestedConflict
          : strings.multipleVacationAvailabilitiesConflict,
      },
    },
  };

  const dispatchVacation = (values: FormikValues) => {
    const {
      startDate,
      startTime,
      endDate,
      endTime,
      notes,
      selectedDates,
      pharmacistId,
    } = values ?? {};

    const commonParams = () =>
      ({
        startDate: appendTimeToDate(startDate, startTime).toFormat(fullDate),
        endDate: appendTimeToDate(endDate, endTime).toFormat(fullDate),
        notes,
        selectedDates,
      });

    const vacationParams = isMultiple ? values : commonParams();

    if (isAdmin) {
      const adminVacationParams = { ...commonParams(), pharmacistId };
      dispatch(adminPharmacistVacations(adminVacationParams));
      return;
    }

    dispatch(pharmacistVacations(vacationParams as IVacationsParams));
  };

  const showWarning = (values: FormikValues, message: string) => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.vacations}
            customMessage={
              <Typography sx={styles.message}>{message}</Typography>
            }
            showCloseButton
            confirmAction={() => {
              dispatchVacation(values);
              dispatch(resetDialog());
            }}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  const createVacations = (values: FormikValues) => {
    const { message, bookedMessage } = messageConfig[selectionType][
        userType as keyof (typeof messageConfig)[SelectionType]
    ];

    if (hasBooking) {
      showError(dispatch, bookedMessage);
      return;
    }

    if (hasRequested && hasAvailable) {
      showWarning(values, strings.vacationRequestedAndAvailabilityConflict);
      return;
    }

    if (hasRequested || hasAvailable) {
      showWarning(values, message);
      return;
    }
    dispatchVacation(values);
  };

  return { createVacations };
};

export default useVacationsCriteria;
