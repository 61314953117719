import { Box } from '@mui/material';
import React from 'react';
import classes from './style';

interface ServiceNoteContentProps {
  notes: string | null;
}

const ServiceNotesContent: React.FC<ServiceNoteContentProps> = ({ notes }) =>
  (
    <Box sx={classes.notes}>
      {notes}
    </Box>
  );

export default ServiceNotesContent;
