import { SxProps, Theme } from '@mui/system';

const styles = {
  backButtonContainer: {
    display: 'flex',
    margin: '16px 0px',
    borderRadius: '10px',
    alignItems: 'center',
  },
  backIcon: {
    color: 'grey.600',
  },
  backButtonText: {
    color: 'grey.600',
    marginLeft: '5px',
    fontSize: 16,
    fontWeight: 500,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
