import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: '10px',
    '& :hover': {
      cursor: 'pointer',
    },
    pb: '8px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    color: ThemeConstants.grey[800],
  },
  iconButton: {
    padding: 0,
  },
  rightChevron: {
    height: 27,
    width: 27,
  },
  contentContainer: {
    display: 'flex',
    mt: '19px',
  },
  avatar: {
    height: 40,
    width: 40,
    mr: '15px',
  },
  footerContainer: {
    display: 'flex',
    flex: 1,
  },
  nameContainer: {
    flex: 1,
    alignItems: 'flex-start',
  },
  name: {
    fontWeight: '500',
    color: ThemeConstants.common.grey850,
    fontSize: 14,
    mb: '3px',
  },
  chipContainer: {
    alignSelf: 'flex-end',
    borderRadius: '45px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
