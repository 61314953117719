import React, { FC } from 'react';
import {
  AdminLegendVariant,
  adminActions,
  adminPharmacyNotes,
} from '@pharmaplan/common';

import AdminPharmacistCard from '../../../Profile/Pharmacist/PharmacistViewProfile/AdminPharmacistCard';
import strings from '../../../../../localization';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import AdminReplacementList from '../../../AdminReplacementList';
import { GenericReplacementActionTypes } from '../AdminPharmacyHistory/AdminPharmacyHistory';
import usePharmacyNotes from '../../../../../hooks/Admin/PharmacyList/usePharmacyNotes';

const successAction = adminActions.pharmacytNotes;

const AdminPharmacyNotes: FC<GenericReplacementActionTypes> = ({
  pharmacistId,
  pharmacyId,
}) => {
  const notes = useAppSelector(adminPharmacyNotes);

  const { table, ratingGoBack, page, setPage } = usePharmacyNotes({
    pharmacistId,
    pharmacyId,
  });

  return (
    <AdminReplacementList
      table={table}
      successAction={successAction}
      title={strings.notes}
      data={notes}
      page={page}
      setPage={setPage}
      headerComponent={(
        <AdminPharmacistCard
          pharmacistId={pharmacistId}
          type={AdminLegendVariant.posted}
          hasOwner={false}
          ratingGoBack={ratingGoBack}
        />
      )}
    />
  );
};

export default AdminPharmacyNotes;
