import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import {
  HelpTypes,
  PermissionsOfAdmin,
  pharmacyDetails,
} from '@pharmaplan/common';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { IFromScreen } from '../../../../actions/drawerActions';
import {
  ChipType,
  SecondaryAdminNavigation,
  SkeletonVariants,
} from '../../../../helpers/Constants';
import TakeOwnership from '../../../Admin/PendingActivationList/TakeOwnership';
import strings from '../../../../localization';

import WithSkeleton from '../../WithSkeleton';
import useAdminPermissions from '../../../../hooks/Admin/useAdminPermissions';
import HourlyRateChipLabel from '../../AdminHourlyRateFooter/HourlyRateChipLabel';

import styles from './style';

interface IAdminPharmacyCardFooter {
  pharmacyId: string;
  fromScreen?: IFromScreen;
  hasOwner?: boolean;
  successAction: string;
}

const { pendingActivation } = SecondaryAdminNavigation;

const AdminPharmacyCardFooter: FC<IAdminPharmacyCardFooter> = ({
  pharmacyId,
  hasOwner,
  fromScreen,
  successAction,
}) => {
  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const details = useAppSelector(pharmacyDetails);

  const { can } = useAdminPermissions();
  const canActivate = can(PermissionsOfAdmin.Activation);

  const { pharmacy } = details ?? {};
  const { primaryContactName, pharmacyHourlyRate } = pharmacy ?? {};

  const fromPendingActivations = fromScreen === pendingActivation;

  return (
    <Box sx={styles.footerContainer}>
      <Typography sx={styles.contactNameTitle}>
        {strings.contactName}
      </Typography>
      <Box sx={styles.container}>
        <SkeletonTypography sx={styles.contactName}>
          {primaryContactName}
        </SkeletonTypography>
        <Box>
          <HourlyRateChipLabel
            rate={pharmacyHourlyRate}
            successAction={successAction}
            type={ChipType.pharmacyHourlyRate}
          />
        </Box>
      </Box>
      {fromPendingActivations && !hasOwner && canActivate && (
        <TakeOwnership id={pharmacyId} userType={HelpTypes.pharmacy} />
      )}
    </Box>
  );
};

export default AdminPharmacyCardFooter;
