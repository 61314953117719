import React, { FC } from 'react';

import { Grid } from '@mui/material';
import { FormikProps } from 'formik';
import { Box } from '@mui/system';

import { searchedPharmacies, TypeOfUser } from '@pharmaplan/common';
import CustomAutocomplete from '../../../common/CustomAutocomplete';
import strings from '../../../../localization';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { toOptions } from '../../ExpressBooking/ExpressBooking';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import useAutoCompleteScroll, {
  AutoCompleteScrollTypes,
} from '../../../../hooks/useAutoCompleteScroll';

import styles from './styles';

interface IIncompatibleMatchListHeader {
  formik: FormikProps<any>;
  handleReset: () => void;
  pID: string;
}

const { pharmacy } = TypeOfUser;

const IncompatibleMatchListHeader: FC<IIncompatibleMatchListHeader> = ({
  formik,
  handleReset,
  pID,
}) => {
  const pharmacies = useAppSelector(searchedPharmacies);
  const pharmacyOptions = toOptions(pharmacies);
  const { onEndReached, pharmacyListData } = useAutoCompleteScroll(
    AutoCompleteScrollTypes.pharmacy,
  );

  const { pharmacies: formikPharmacies } = formik.values;
  const pharmacyOption = formikPharmacies ? pharmacyOptions : pharmacyListData;

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={styles.container}>
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <CustomAutocomplete
            disabled={!!pID}
            customAutoCompleteStyle={styles.autocomplete}
            label={strings.pharmacy}
            onEndReached={onEndReached(pharmacy)}
            infiniteScroll={!formikPharmacies}
            idName="pharmacyId"
            textName="pharmacies"
            autoCompleteName="pharmacySelected"
            placeholder={
              strings.formatString(
                strings.selectUser,
                strings.pharmacy,
              ) as string
            }
            formik={formik}
            options={pharmacyOption}
          />
        </Grid>
        {!pID && (
        <FilterSubmitButtons
          handleReset={handleReset}
          disableSubmit={!!formik.values.pharmacyId}
        />
        )}
      </Grid>
    </Box>
  );
};

export default IncompatibleMatchListHeader;
