import React from 'react';
import { Box } from '@mui/system';
import { ReactComponent as AddCalendar } from '@pharmaplan/common/assets/icons/addCalendar.svg';
import { RecurrenceActions, ToUserTypings, TypeOfUser } from '@pharmaplan/common';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import strings from '../../../localization';
import CustomButton from '../../common/CustomButton';
import classes from './styles';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { resetDialog } from '../../../reducers/dialogReducer';

interface IUpdateModal {
  type: TypeOfUser;
  handleSelect: (selected: RecurrenceActions) => void;
}

const UpdateModal = ({ type, handleSelect }: IUpdateModal) => {
  const config = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      title: strings.formatString(strings.editEvent, strings.availability),
    },
    [TypeOfUser.pharmacy]: {
      title: strings.formatString(strings.editEvent, strings.workshift),
    },
  });
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(resetDialog());
  };
  const onClick = (recType: RecurrenceActions) => {
    handleSelect(recType);
    handleClose();
  };

  return (
    <>
      <Box sx={classes.container}>
        <Box sx={classes.closeContainer}>
          <IconButton size="small" sx={classes.closeIcon} onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={classes.subContainer}>
          <AddCalendar />
        </Box>
        <Typography sx={classes.title}>{config[type].title}</Typography>
        <Box sx={classes.subContainer}>
          <Typography sx={classes.message}>
            {strings.pleaseSelectAction}
          </Typography>
        </Box>
      </Box>
      <Box sx={classes.buttonContainer}>
        <CustomButton
          label={strings.updateOccurrence}
          customButtonStyle={classes.button}
          variant="outlined"
          onClick={() =>
            onClick(RecurrenceActions.occurence)}
        />
        <CustomButton
          label={strings.updateSeries}
          customButtonStyle={classes.button}
          onClick={() =>
            onClick(RecurrenceActions.series)}
        />
      </Box>
    </>
  );
};

export default UpdateModal;
