import React, { Fragment, useEffect } from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikValues, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  adminActions,
  AdminTypes,
  allAdminPermissions,
  createAdmin,
  getLanguages,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { formGenerator } from '../../../../helpers/formGenerator';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { OutcomeModalTypes } from '../../../../helpers/Constants';
import CustomBreadcrumbs from '../../../common/CustomBreadcrumbs';
import { setDialog } from '../../../../reducers/dialogReducer';
import CustomButton from '../../../common/CustomButton';
import OutcomeModal from '../../../Modals/OutcomeModal';
import strings from '../../../../localization';

import AdminPermissions from './AdminPermissions';
import { addCrumbs, fieldConfig } from './helper';
import styles from './styles';
import Validator from './validator';

const successAction = adminActions.createAdmin;
const CreateNewAdmin = () => {
  const dispatch = useAppDispatch();
  const allPermissions = useAppSelector(allAdminPermissions);

  const allLanguages = useAppSelector(getLanguages);
  const navigate = useNavigate();

  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const showSuccess = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <OutcomeModal
            type={OutcomeModalTypes.adminCreatedSuccess}
            message={strings.accountDetailsHaveBeenEmailed}
          />
        ),
        showCloseButton: true,
      }),
    );
  };

  const handleSubmit = (values: FormikValues) => {
    const {
      email,
      username,
      languageId,
      userType,
      permissions,
      phone,
      supportAgentName,
    } = values;
    dispatch(
      createAdmin({
        email,
        username,
        languageId,
        userType,
        permissions,
        phone,
        supportAgentName,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      username: '',
      languageId: '',
      phone: '',
      userType: AdminTypes.admin,
      permissions: [],
      supportAgentName: '',
    },
    onSubmit: handleSubmit,
    validationSchema: Validator(),
  });

  useEffect(() => {
    if (success) {
      showSuccess();
      dispatch(resetStatus([successAction]));
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleClick = (adminTypes: AdminTypes) => {
    const isSuperAdmin = adminTypes.toString() === `${AdminTypes.superadmin}`;
    if (isSuperAdmin) {
      formik.setFieldValue(
        'permissions',
        allPermissions.permissions.map((item) =>
          item.permissionId),
      );
    } else {
      formik.setFieldValue('permissions', []);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.crumbContainer}>
        <CustomBreadcrumbs crumbs={addCrumbs} />
      </Box>
      <Typography sx={styles.title}>
        {strings.formatString(strings.addNewFormat, strings.administrator)}
      </Typography>
      <Grid container xs={10} columnSpacing={4}>
        {fieldConfig(allLanguages, handleClick).map((item) => {
          const { key } = item;
          return <Fragment key={key}>{formGenerator(item, formik)}</Fragment>;
        })}
      </Grid>
      <AdminPermissions formik={formik} />
      <Grid container xs={10} sx={styles.buttonContainer}>
        <CustomButton label={strings.create} onClick={formik.handleSubmit} />
      </Grid>
    </Box>
  );
};

export default CreateNewAdmin;
