import { SxProps, Theme } from '@mui/system';

const styles = {
  chipStyle: {
    backgroundColor: 'common.green300',
    color: 'common.green600',
  },
  customFilledInput: {
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottom: 'none',
        content: "''",
        position: 'relative',
        border: 'none',
      },
      '&:after': {
        borderBottom: 'none',
        border: 'none',
      },
      backgroundColor: 'common.lightBackgroundColor',
    },
    '& .MuiFilledInput-input': {
      paddingTop: '8px',
    },
  },
  gridLabel: {
    marginTop: '4px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
