import React, { FC } from 'react';

import { LegendPerItemProps } from '@mui/x-charts/ChartsLegend/LegendPerItem';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import styles from './styles';
import { ChartTypes } from '../types';

interface ICustomChartLegend {
  mapKey: number;
  containerXs: boolean;
  legendProps: { [key: string]: LegendPerItemProps['itemsToDisplay'] };
  chartType?: Omit<ChartTypes, 'line' | 'composite'>;
}

const { bar, pie } = ChartTypes;

const CustomChartLegend: FC<ICustomChartLegend> = ({
  containerXs,
  mapKey,
  legendProps,
  chartType = ChartTypes.bar,
}) => {
  const seriesToDisplay = legendProps?.[mapKey];
  const itemLength = seriesToDisplay?.length;

  const xsCalc = () => {
    if (itemLength <= 2) return 12;
    if (itemLength % 2 === 0) return 6;
    return 12 / itemLength;
  };

  const isSingleItem = itemLength === 1;

  const styleConfig = {
    [bar]: {
      container: styles.container,
      itemContainer: styles.itemContainer,
      itemMarkerContainer: (props: boolean) =>
        styles.itemMarkerContainer(props),
    },
    [pie]: {
      container: styles.pieContainer,
      itemContainer: styles.pieItemContainer,
      itemMarkerContainer: () =>
        styles.pieItemMarkerContainer,
    },
  };

  const { container, itemContainer, itemMarkerContainer } = styleConfig[chartType as keyof typeof styleConfig];

  return (
    <Grid sx={container} container>
      {seriesToDisplay?.map((item) => {
        const { color, label, id } = item;
        return (
          <Grid xs={xsCalc()} sx={itemContainer} key={id} item>
            <Box
              sx={itemMarkerContainer(
                (isSingleItem || containerXs) && itemLength !== 3,
              )}
            >
              <Box sx={styles.itemMarker(color)} />
            </Box>
            <Typography sx={styles.label}>{label}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CustomChartLegend;
