const classes = {
  monthSelectorContainer: {
    display: 'flex',
  },
  monthElement: {
    marginRight: '16px',
    backgroundColor: '#E3E5F0',
    borderRadius: '50%',
    height: '32px',
    width: '32px',
    alignItems: 'center',
    color: '#4F4F4F',
    justifyContent: 'center',
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none',
  },
  monthSelected: {
    color: 'white',
    backgroundColor: 'primary.main',
  },
};

export default classes;
