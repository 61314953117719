import React, { FC } from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import { ChipType, SkeletonVariants } from '../../../helpers/Constants';
import strings from '../../../localization';

import HourlyRateChipLabel from './HourlyRateChipLabel';
import WithSkeleton from '../WithSkeleton';
import styles from './styles';

interface IAdminPharmacyFooter {
  software?: string;
  successAction: string;
  pharmacyHourlyRate: number;
  pharmacistHourlyRate?: number;
}

const { pharmacyHourlyRate: pharmacyHourlyChip, hourlyRate } = ChipType;

const AdminPharmacyFooter: FC<IAdminPharmacyFooter> = ({
  software,
  pharmacyHourlyRate,
  pharmacistHourlyRate,
  successAction,
}) => {
  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  return (
    <Box sx={styles.mainContainer}>
      <HourlyRateChipLabel
        type={pharmacyHourlyChip}
        label={strings.pharmacyHourlyRate}
        rate={pharmacyHourlyRate}
        successAction={successAction}
      />
      {(!!pharmacistHourlyRate || !software) ? (
        <HourlyRateChipLabel
          type={hourlyRate}
          label={strings.pharmacistHourlyRate}
          rate={pharmacistHourlyRate}
          successAction={successAction}
        />
      ) : (
        <Box sx={styles.itemContainer}>
          <SkeletonTypography sx={styles.title}>
            {strings.software}
          </SkeletonTypography>
          <SkeletonTypography sx={styles.value}>
            {software}
          </SkeletonTypography>
        </Box>
      )}
    </Box>
  );
};

export default AdminPharmacyFooter;
