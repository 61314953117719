import { SxProps, Theme } from '@mui/system';

const styles = {
  infoHeading: {
    fontWeight: 700,
    fontSize: 36,
    textAlign: 'center',
  },

  infoDesc: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
