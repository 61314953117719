import React from 'react';

import TermsAndConditionsModal from '../../components/Modals/TermsAndConditionsModal';
import strings from '../../localization';
import { setDialog } from '../../reducers/dialogReducer';
import { useAppDispatch } from '../useAppDispatch';
import classes from '../../components/Profile/ProfileForm/style';
import ProfileLocationMapView from '../../components/Profile/ProfileForm/ProfileLocationMapView';

const useProfileForm = () => {
  const dispatch = useAppDispatch();

  const { customModalHeading, customTitleContainer, mapContainerSignUpFlow, selectContainer, footerContainer } = classes ?? {};

  const showTermsAndConditions = () => {
    dispatch(
      setDialog({
        heading: {
          title: strings.termsAndConditionsHeader,
        },
        Component: <TermsAndConditionsModal />,
        maxWidth: 'xl',
        customStyle: {
          customModalHeading,
          customTitleContainer,
        },
        showCloseButton: true,
      }),
    );
  };

  const showPickYourLocation = () => {
    dispatch(
      setDialog({
        heading: {
          title: strings.pickYourLocationOnMap,
        },
        Component: (
          <ProfileLocationMapView
            customStyles={{
              container: mapContainerSignUpFlow,
              subContainer: selectContainer,
              footer: footerContainer,
            }}
            isSignup
          />
        ),
        maxWidth: 'lg',
        customStyle: { customModalHeading },
        showCloseButton: true,
      }),
    );
  };
  return { showTermsAndConditions, showPickYourLocation };
};

export default useProfileForm;
