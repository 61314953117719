import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider } from 'react-cookie';

import { ServiceTypes, configURL } from '@pharmaplan/common';
import publicKeyHandler from '@pharmaplan/common/helpers/publicKeyHandler';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './store';

ServiceHandler.setService(process.env.REACT_APP_SERVICE_TYPE as ServiceTypes);
const root = ReactDOM.createRoot(
  document.getElementById('root') ?? ({} as DocumentFragment),
);

configURL(process.env.REACT_APP_API_URL ?? '');
publicKeyHandler.setKey(process.env.REACT_APP_PUBLIC_KEY ?? '');

root.render(
  <GoogleOAuthProvider
    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
  >
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
