import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    paddingBottom: '24px',
    paddingX: '24px',
  },
  label: {
    fontSize: '14px',
    fontWeight: '500',
  },
  labelContainer: {
    marginTop: '10px',
  },
  checkboxLabel: {
    marginTop: '2px',
  },
  imageName: {
    color: 'grey.500',
    fontSize: '14px',
    fontWeight: '400',
    textDecorationLine: 'underline',
  },
  customCheckbox: {
    marginLeft: 0,
  },
  uploadContainer: {
    my: '30px',
  },
  iconButton: {
    padding: 0,
    ml: '2px',
    marginBottom: '6px',
  },
  crossIcon: {
    height: 14,
    width: 14,
    color: 'common.gray',
  },
  linkContainer: {
    display: 'flex',
    marginBottom: '8px',
    alignItems: 'flex-start',
  },
  uploadButton: {
    padding: 0,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: '120px',
  },
  errorMsg: {
    fontSize: 12,
    color: 'common.error',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
