import React, { FC, useEffect } from 'react';

import { Avatar } from '@mui/material';
import { FormikValues, useFormik } from 'formik';

import {
  adminActions,
  adminGetPharmacyAdminPicture,
  adminPharmacistPictures,
  adminPharmacyAdminPicture,
  adminUploadPharmacyAdminPicture,
  getPharmacistDetails,
  pharmacistDetails,
  resetStatus,
  successSelector,
  uploadPharmacistPhoto,
} from '@pharmaplan/common';
import AvatarFallback from '@pharmaplan/common/assets/icons/avatarPlaceholder.png';

import UploadDialog from '../../../../../common/UploadDialog';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import strings from '../../../../../../localization';

import { showSuccess } from '../helper';
import styles from './style';
import useGetItems from '../../../../../../hooks/useGetItems';

interface IUploadPhoto {
  id: string;
  isPharmacyAdmin?: boolean;
}

const filesTypes = '.png, .jpg, .jpeg';

const UploadPhoto: FC<IUploadPhoto> = ({ id, isPharmacyAdmin = false }) => {
  const dispatch = useAppDispatch();
  const uploadSuccess = useAppSelector((state) =>
    successSelector([adminActions.uploadPharmacistPhoto], state));

  const pharmachyAdminUploadSuccess = useAppSelector((state) =>
    successSelector([adminActions.adminUploadPharmacyAdminPicture], state));

  const details = useAppSelector(pharmacistDetails);
  const pharmacyAdminDetails = useAppSelector(adminPharmacyAdminPicture);

  const { name: pharmacyAdminPicture, image: pharmacyAdminSrc } = pharmacyAdminDetails ?? {};

  const { pharmacist } = details ?? {};
  const { pictureName: pharmacistPicture } = pharmacist ?? {};

  const pictureName = isPharmacyAdmin ? pharmacyAdminPicture : pharmacistPicture;

  const { getPharmacistImage } = useGetItems();

  const onSubmit = (values: FormikValues) => {
    const { image: selectedFile } = values ?? {};

    let form = null;

    if (selectedFile) {
      form = new FormData();
      form.append('image', selectedFile);
    }

    if (isPharmacyAdmin) {
      dispatch(adminUploadPharmacyAdminPicture({ pharmacyAdminId: id, form }));
      return;
    }

    dispatch(uploadPharmacistPhoto({ pharmacistId: id, form }));
  };

  const formik = useFormik({
    initialValues: {
      image: {
        name: pictureName,
        size: null,
      },
      isLogoChanged: false,
      isDeleted: false,
    },
    enableReinitialize: true,
    onSubmit,
  });

  const { values, setFieldValue } = formik ?? {};
  const { image, isDeleted, isLogoChanged } = values ?? {};
  const { name, size } = image ?? {};

  const handleClear = () => {
    setFieldValue('image', null);
    setFieldValue('isDeleted', true);
    setFieldValue('isLogoChanged', true);
  };

  const picture = isPharmacyAdmin ? `data:image/png;base64,${pharmacyAdminSrc}` : getPharmacistImage(id);

  const displayImage = size ? URL.createObjectURL(image as unknown as File) : '';
  const serverImage = isDeleted || !pictureName ? AvatarFallback : picture;

  useEffect(() => {
    if (name) {
      setFieldValue('isLogoChanged', !!size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  useEffect(() => {
    if (uploadSuccess && !isPharmacyAdmin) {
      showSuccess(dispatch, strings.profileDataSaved);
      dispatch(resetStatus());
      dispatch(getPharmacistDetails(id));
      dispatch(adminPharmacistPictures([id]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSuccess, isPharmacyAdmin]);

  useEffect(() => {
    if (pharmachyAdminUploadSuccess && isPharmacyAdmin) {
      showSuccess(dispatch, strings.profileDataSaved);
      dispatch(resetStatus());
      dispatch(adminGetPharmacyAdminPicture(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmachyAdminUploadSuccess, isPharmacyAdmin]);

  return (
    <UploadDialog
      formik={formik}
      message={strings.sizeOfImage}
      name="image"
      fileSize={2000}
      chooseLabel={strings.uploadFile}
      errorMessage={strings.sizeOfImage}
      acceptedFileTypes={filesTypes}
      disableButton={!isLogoChanged}
      handleClear={handleClear}
      customComponent={(
        <Avatar
          imgProps={styles.imgProp}
          src={size ? displayImage : serverImage}
          sx={styles.avatar}
        />
      )}
    />
  );
};

export default UploadPhoto;
