import { createReducer } from '@reduxjs/toolkit';
import { AnyAction } from '@reduxjs/toolkit';
import { logout } from '../actions';
import { resetStatus } from '../actions/statusActions';
import { RequestStatusTypes } from '../helpers/Constants';
import { ERROR, LOADING, SUCCESS } from '../helpers/Status';

const initialState: Record<string, string> = {};

export type StatusTypes = 'NOT_STARTED' | 'LOADING' | 'SUCCESS' | 'ERROR';

const getActionName = (status: `${RequestStatusTypes}`, action: AnyAction) => {
  const [, requestName] =
    new RegExp('(.*)/' + status)?.exec(action?.type) || [];
  return requestName;
};

export const isPendingAction = (action: AnyAction) =>
  action.type.endsWith('/pending');
export const isRejectedAction = (action: AnyAction) =>
  action.type.endsWith('/rejected');
export const isFulfilledAction = (action: AnyAction) =>
  action.type.endsWith('/fulfilled');

const statusReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetStatus, (state,action) => {
      if(action.payload){        
        // LOGIC TO RESET SPECIFIC STATUS FOR SKELETON STATUS MANAGEMENT
        action.payload.forEach(item=>{          
          delete state[item];
        });
        return state;
      }
      return initialState;
    })
    .addCase(logout.fulfilled, () => {
      return initialState;
    })
    .addMatcher(isPendingAction, (state, action) => {
      const requestName = getActionName(RequestStatusTypes.pending, action);
      state[requestName] = LOADING;
    })
    .addMatcher(isRejectedAction, (state, action) => {
      const requestName = getActionName(RequestStatusTypes.rejected, action);
      state[requestName] = ERROR;
    })
    .addMatcher(isFulfilledAction, (state, action) => {
      const requestName = getActionName(RequestStatusTypes.fulfilled, action);
      state[requestName] = SUCCESS;
    });
});

export default statusReducer;
