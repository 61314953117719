import React from 'react';
import { FormikValues } from 'formik';
import {
  adminDeleteAvailability,
  adminRequestCancellation,
  deleteMulipleWorkshift,
  deletePharmacyWorkshift,
} from '@pharmaplan/common';
import { useAppDispatch } from '../useAppDispatch';
import { setDialog } from '../../reducers/dialogReducer';
import DeactivateDialog from '../../components/Admin/PharmacistList/DeactivateDialog';
import strings from '../../localization';
import { DeactivateModalIcon } from '../../helpers/Constants';
import ValidatorCancellation from '../../components/Admin/PharmacistList/DeactivateDialog/validator';
import CancellationDateTime from '../../components/Admin/PharmacyList/ListOfWorkshifts/CancellationDateTime';

const useWorkshiftDelete = () => {
  const dispatch = useAppDispatch();

  const requestCancellation = (values: FormikValues) => {
    const { bookingId, reason } = values ?? {};
    dispatch(adminRequestCancellation({ reason, bookingId }));
  };

  const showRequestCancellation = (bookingId: string, dateTime: string) => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            onSubmit={requestCancellation}
            confirmationMessage={strings.confirmWorkshiftCancel}
            id={bookingId}
            initialValues={{
              bookingId,
              reason: '',
            }}
            heading={strings.requestCancellation}
            iconType={DeactivateModalIcon.warning}
            validator={ValidatorCancellation()}
            name="reason"
            customComponent={<CancellationDateTime date={dateTime} />}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const handleWorkshiftDelete = (values: FormikValues) => {
    const { notes, workshiftIds } = values ?? {};
    dispatch(deletePharmacyWorkshift({ workshiftId: workshiftIds, notes }));
  };

  const handleMultipleDelete = (values: FormikValues) => {
    const { notes, workshiftIds } = values ?? {};
    dispatch(deleteMulipleWorkshift({ workshiftIds, notes }));
  };

  const showWorkshiftDelete = (workshiftId: string, dateTime: string) => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            onSubmit={handleWorkshiftDelete}
            confirmationMessage={strings.confirmDeleteWorkshift}
            id={workshiftId}
            initialValues={{
              workshiftIds: workshiftId,
              notes: '',
            }}
            heading={strings.deleteWorkshift}
            iconType={DeactivateModalIcon.warning}
            name="notes"
            validator={null}
            customComponent={<CancellationDateTime date={dateTime} />}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const deleteAvailability = (values: FormikValues) => {
    const { notes, availabilityId } = values ?? {};
    dispatch(
      adminDeleteAvailability({ availabilityIds: [availabilityId], notes }),
    );
  };

  const showDeleteAvailability = (availabilityId: string, dateTime: string) => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            onSubmit={deleteAvailability}
            confirmationMessage={strings.confirmDeleteAvailability}
            id={availabilityId}
            initialValues={{
              availabilityId,
              notes: '',
            }}
            heading={strings.deleteAvailability}
            iconType={DeactivateModalIcon.warning}
            validator={null}
            name="notes"
            customComponent={<CancellationDateTime date={dateTime} />}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const showMultipleWorkshiftsDelete = (workshiftIds: Array<string>) => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            onSubmit={handleMultipleDelete}
            confirmationMessage={
              strings.formatString(
                strings.confirmDeleteCountWorkshift,
                workshiftIds?.length,
              ) as string
            }
            id=""
            initialValues={{
              workshiftIds,
              notes: '',
            }}
            heading={`${strings.deleteWorkshift}s`}
            iconType={DeactivateModalIcon.warning}
            name="notes"
            validator={null}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  return {
    showWorkshiftDelete,
    showRequestCancellation,
    showDeleteAvailability,
    showMultipleWorkshiftsDelete,
    deleteAvailability,
  };
};

export default useWorkshiftDelete;
