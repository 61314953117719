import theme from '.';

const ResponsiveClasses = {

  hideOnLg: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

};

export default ResponsiveClasses;
