import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    marginBottom: '8px',
  },

  notVisited: {
    backgroundColor: '#F0F2FC',
  },

  primaryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  primaryText: {
    flex: 1,
    fontSize: '14px',
    fontWeight: 600,
    color: 'common.grey850',
  },

  hourText: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'grey.500',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
