import { currency, IAdminCancellationStatistics } from '@pharmaplan/common';

import {
  ChartTypes,
  ICompositeChart,
  IPieChart,
} from '../../../common/Charts/ChartBuilder/types';
import { totalWorkshiftStatsKeys } from '../../../../helpers/Constants';
import strings from '../../../../localization';
import ThemeConstants from '../../../../theme/ThemeConstants';
import {
  currencyFormatter,
  monthValueFormatter,
} from '../../../../helpers/Functions';

const { pie, composite } = ChartTypes;
const { posted, requested, booked, totalContractValue } = totalWorkshiftStatsKeys;

export const workshiftStatsConfig = (
  mergedDataset: Array<any>,
): Array<ICompositeChart> =>
  [
    {
      key: 1,
      type: composite,
      xs: 12,
      useDoubleYAxis: true,
      margin: {
        right: 90,
      },
      rightLabelXTranslate: '40px',
      chartSettings: {
        height: 239,
        dataset: mergedDataset,
        series: [
          {
            type: 'bar',
            dataKey: posted,
            color: ThemeConstants.chartColors.green400,
            label: strings.formatString(
              strings.numberOfWorkshift,
              strings.posted,
            ) as string,
          },
          {
            type: 'bar',
            dataKey: requested,
            color: ThemeConstants.chartColors.orange950,
            label: strings.formatString(
              strings.numberOfWorkshift,
              strings.requested,
            ) as string,
          },
          {
            type: 'bar',
            dataKey: booked,
            color: ThemeConstants.chartColors.pink950,
            label: strings.formatString(
              strings.numberOfWorkshift,
              strings.booked,
            ) as string,
          },
          {
            type: 'line',
            dataKey: totalContractValue,
            yAxisId: 'rightAxis',
            color: ThemeConstants.chartColors.blue300,
            label: strings.totalContractValue,
            valueFormatter: currencyFormatter,
          },
        ],
        yAxis: [
          { id: 'leftAxis' },
          { id: 'rightAxis', label: `${strings.contractValue} (${currency})` },
        ],
        xAxis: [
          {
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: monthValueFormatter,
          },
        ],
      },
    },
  ];

export const cancellationPieConfig = (
  cancellationStats: IAdminCancellationStatistics,
): Array<IPieChart> => {
  const {
    raisedByAdmin,
    raisedByPharmacy,
    raisedByPharmacist,
    pending,
    approved,
    rejected,
  } = cancellationStats ?? {};

  return [
    {
      key: 1,
      type: pie,
      chartSettings: {
        height: 239,
        series: [
          {
            data: [
              {
                id: 0,
                value: raisedByAdmin,
                label: strings.formatString(
                  strings.cancellationByUser,
                  strings.admin.toLowerCase(),
                ) as string,
                color: ThemeConstants.chartColors.cyan,
              },
              {
                id: 1,
                value: raisedByPharmacy,
                label: strings.formatString(
                  strings.cancellationByUser,
                  strings.pharmacy.toLowerCase(),
                ) as string,
                color: ThemeConstants.chartColors.grey200,
              },
              {
                id: 2,
                value: raisedByPharmacist,
                label: strings.formatString(
                  strings.cancellationByUser,
                  strings.pharmacist.toLowerCase(),
                ) as string,
                color: ThemeConstants.chartColors.secondaryGreen,
              },
            ],
          },
        ],
      },
    },
    {
      key: 2,
      type: pie,
      chartSettings: {
        height: 239,
        series: [
          {
            data: [
              {
                id: 0,
                value: pending,
                label: strings.pendingCancellationRequests,
                color: ThemeConstants.chartColors.whiteSmoke,
              },
              {
                id: 1,
                value: approved,
                label: strings.cancelledBookings,
                color: ThemeConstants.chartColors.blue50,
              },
              {
                id: 2,
                value: rejected,
                label: strings.rejectedCancellations,
                color: ThemeConstants.chartColors.blue300,
              },
            ],
          },
        ],
      },
    },
  ];
};
