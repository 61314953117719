import { SxProps, Theme } from '@mui/system';

const styles = {
  segment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  create: {
    ':hover': {
      cursor: 'pointer',
    },
  },
  segmentButtons: {
    width: '100% !important',
    fontSize: 12,
    borderRadius: 0,
  },
  active: {
    fontWeight: 500,
    borderBottom: '1px solid',
  },
  inActive: {
    color: 'grey.500',
    fontSize: 12,
  },
  count: {
    position: 'absolute',
    top: 3,
    right: 15,
    display: 'flex',
    backgroundColor: 'common.error',
    justifyContent: 'center',
    alignItems: 'center',
    height: 10,
    width: 10,
    borderRadius: 8,
    fontSize: 11,
    fontWeight: '500',
    color: 'secondary.main',
  },
  shakingCount: {
    '@keyframes shaking': {
      '0%': { scale: '1' },
      '15%': { scale: '1.04' },
      '30%': { scale: '1.08' },
      '40%': { scale: '1.12' },
      '50%': { scale: '1.08' },
      '60%': { scale: '1.04' },
      '100%': { scale: '1.00' },
    },
    animation: 'shaking 1s infinite linear',
    fontSize: 13,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
