import React, { useEffect } from 'react';

import { FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  adminActions,
  ConfirmationTypes,
  createBroadcastGroup,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';

import { setDialog } from '../../../reducers/dialogReducer';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../../localization';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import SaveBroadcastDialog from '../../../components/Admin/BroadcastList/CreateEditBroadcastGroup/SaveBroadcastDialog';
import { BroadcastGroupActionType } from '../../../helpers/Constants';

const createAction = adminActions.createBroadcastGroup;
const { resetSelection } = ConfirmationTypes;

interface IUseBroadcastCreateActions {
  allIds: Array<string>;
  editAction: (selectedIds: Array<string>) => (values: FormikValues) => void;
  type: BroadcastGroupActionType;
}

const useBroadcastCreateActions = ({
  allIds,
  editAction,
  type,
}: IUseBroadcastCreateActions) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isEdit = type === BroadcastGroupActionType.edit;

  const success = useAppSelector((state) =>
    successSelector([createAction], state));

  const confirmCreateAction = () =>
    (values: FormikValues) => {
      const { groupName } = values ?? {};
      dispatch(createBroadcastGroup({ groupName, userIds: allIds }));
    };

  const handleSaveGroup = () => {
    dispatch(
      setDialog({
        Component: (
          <SaveBroadcastDialog
            handleSubmit={isEdit ? editAction(allIds) : confirmCreateAction()}
            noOfSelectedUsers={allIds.length}
            type={type}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const resetConfirmation = (resetConfirmAction: () => void) => {
    const noItemsSelected = !allIds.length;
    if (noItemsSelected) {
      resetConfirmAction();
      return;
    }

    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={resetSelection}
            confirmAction={resetConfirmAction}
          />
        ),
        showCloseButton: false,
        heading: {
          title: '',
        },
      }),
    );
  };

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.groupCreatedSuccessfully);
      navigate(-1);
      dispatch(resetStatus([createAction]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return { handleSaveGroup, resetConfirmation };
};

export default useBroadcastCreateActions;
