import React from 'react';
import ServiceCard from '../ServiceCard/ServiceCard';
import strings from '../../../localization';
import ServiceNotesContent from './ServiceNotesContent/ServiceNotesContent';
import classes from './styles';

interface IServiceNotes {
  notes: string | null;
  isPharmacySummary?: boolean;
}

const ServiceNote:React.FC<IServiceNotes> = ({ notes, isPharmacySummary }) =>
  (
    <ServiceCard
      title={strings.notes}
      customContainerStyle={isPharmacySummary ? classes.pharmacySummaryRoot : classes.root}
      content={<ServiceNotesContent notes={notes} />}
    />
  );

export default ServiceNote;
