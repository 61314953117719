import React, { FC, Fragment } from 'react';

import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

import ServiceNote from '../../ServiceNotes/ServiceNotes';

import classes from './styles';

interface ISummaryContentConfig {
  key: string;
  component?: React.JSX.Element | React.ReactNode;
  data?: boolean | string | number | null;
  label?: string;
  counterLabel?: string;
  style?: SxProps;
}

interface IPharmacistSummaryContent {
  summaryContent: Array<ISummaryContentConfig>;
  notes?: string | null;
}

const { strike, strikedTime, text, notesContainer } = classes;

const PharmacistSummaryContent: FC<IPharmacistSummaryContent> = ({
  summaryContent,
  notes,
}) =>
  (
    <>
      {summaryContent.map((item) => {
        const { data, style, label, counterLabel, key, component } = item ?? {};
        const hasData = !!data;

        return hasData ? (
          <Fragment key={key}>
            <Box key={key} sx={style}>
              {component}
              {label && <Typography sx={[text, counterLabel ? strike : {}]}>{label}</Typography>}
            </Box>

            {counterLabel && (
            <Typography sx={strikedTime}>{counterLabel}</Typography>
            )}
          </Fragment>
        ) : (
          <Fragment key={key} />
        );
      })}

      {notes && (
      <Box sx={notesContainer}>
        <ServiceNote notes={notes} isPharmacySummary />
      </Box>
      )}
    </>
  );

export default PharmacistSummaryContent;
