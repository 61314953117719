import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = (avatarSize: number) =>
  ({
    container: {
      position: 'relative',
    },

    imgStyle: {
      objectFit: 'contain',
    },

    favoritePosition: {
      position: 'absolute',
      bottom: 25,
      right: 15,
    },

    avatar: {
      height: avatarSize,
      width: avatarSize,
      border: '2px solid',
      borderColor: 'common.border',
    },

    alignment: {
      mr: '15px',
      mt: '7px',
    },

    cursor: {
      cursor: 'pointer',
    },

  } satisfies Record<string, SxProps<Theme>>);

export default styles;
