import { SxProps, Theme } from '@mui/system';

const size = 42;
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  overlayContainer: {
    position: 'relative',
    height: size,
    width: size,
  },
  overlay: {
    '&:hover': {
      backgroundColor: 'black',
    },
    height: size,
    width: size,
    backgroundColor: 'footer.verticalLine',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    position: 'absolute',
    opacity: '0.5',
  },
  image: {
    height: size,
    width: size,
    borderRadius: '8px',
    position: 'absolute',
  },
  icon: {
    color: 'secondary.main',
  },
  label: {
    marginLeft: '16px',
    fontSize: 14,
    fontWeight: '600',
    whiteSpace: 'nowrap',
    color: 'common.grey850',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '99%',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
