const classes = {
  tooltip: {
    backgroundColor: 'white',
    borderRadius: 3,
    boxSizing: 'border-box',
    padding: 3,
    position: 'relative',
    maxWidth: 420,
    minWidth: 290,
    border: 'none',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: 2,
    paddingRight: 2,
  },
  skipButtonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: 16,
  },
  checkBoxContainer: {
    flex: 1,
    paddingTop: 2,
  },
  footer: {
    paddingTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
};

export default classes;
