import { SxProps, Theme } from '@mui/system';

const styles = {
  waving: {
    '@keyframes waving': {
      '0%': { transform: 'rotate(0.0deg)' },
      '15%': { transform: 'rotate(14.0deg)' },
      '30%': { transform: 'rotate(-8.0deg)' },
      '40%': { transform: 'rotate(14.0deg)' },
      '50%': { transform: 'rotate(-4.0deg)' },
      '60%': { transform: 'rotate(10.0deg)' },
      '70%': { transform: 'rotate(0.0deg)' },
      '100%': { transform: 'rotate(0.0deg)' },
    },
    animation: 'waving 2.5s infinite ease',
    fontSize: 13,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
