import { SxProps, Theme } from '@mui/system';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 24px 24px 24px',
  },

  container: {
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
  },

  titleFont: {
    fontWeight: 500,
    color: 'grey.500',
  },

  divider: {
    flex: 1,
    marginLeft: '16px',
    borderColor: 'common.border',
  },

  button: {
    width: 132,
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  checkbox: {
    padding: '0px 0px 20px 0px',
    margin: 0,
  },

  moreSettingsCheckbox: {
    padding: 0,
    margin: 0,
  },

  checkboxText: {
    fontSize: 14,
    fontWeight: 500,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
