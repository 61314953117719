import React, { FC, memo } from 'react';

import { Box, Paper, Typography } from '@mui/material';

import {
  activeDate,
  AdminLegendVariant,
  PharmacistLegendVariant,
  PharmacyLegendVariant,
  TypeOfUser,
} from '@pharmaplan/common';

import { useAppSelector } from '../../../hooks/useAppSelector';
import useUser from '../../../hooks/useUser';
import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';
import ICalendar from '../../../types/ICalendar';
import { eventsMapper } from '../../Dashboard/MainCalendar/helpers';

import PharmacistLegend from '../PharmacistLegend';
import PharmacyLegend from '../PharmacyLegend';
import {
  AdminRenderer,
  adminTotal,
  getTotalCount,
  getTotalHrs,
} from './helpers';
import classes from './style';

interface ILegendContainer {
  row?: boolean;
}

const pharmacistLegendObject = [
  { variant: PharmacistLegendVariant.requested },
  { variant: PharmacistLegendVariant.workshift },
  { variant: PharmacistLegendVariant.booked },
  { variant: PharmacistLegendVariant.availability },
  { variant: PharmacistLegendVariant.vacation },
];

export const adminLegendObject = [
  { variant: AdminLegendVariant.requested },
  { variant: AdminLegendVariant.posted },
  { variant: AdminLegendVariant.booked },
  { variant: AdminLegendVariant.total },
];

const pharmacyLegendObject = [
  { variant: PharmacyLegendVariant.posted },
  { variant: PharmacyLegendVariant.requested },
  { variant: PharmacyLegendVariant.booked },
];

const renderLegendBasedOnType = (
  typeOfUser: `${TypeOfUser}`,
  events: ICalendar[],
  currentActiveDate: string,
  row = false,
) => {
  const currentActiveMonth = new Date(currentActiveDate).getMonth();
  const isAdmin = typeOfUser === TypeOfUser.admin || typeOfUser === TypeOfUser.superAdmin;
  if (typeOfUser === TypeOfUser.pharmacist) {
    return pharmacistLegendObject.map((leg) =>
      (
        <PharmacistLegend
          totalCount={getTotalCount(
            events ?? [],
            leg.variant,
            currentActiveMonth,
          )}
          totalHrs={Math.trunc(
            getTotalHrs(
              events?.map((e) =>
                eventsMapper(e)) ?? [],
              leg.variant,
              currentActiveMonth,
            ),
          )}
          variant={leg.variant}
          customClass={
          row ? genericClasses.legendsRowMargin : genericClasses.legendsMargin
        }
          key={leg.variant}
        />
      ));
  }

  if (isAdmin) {
    const { totalCount, totalHours } = adminTotal(events, currentActiveMonth);
    return AdminRenderer(
      totalCount,
      totalHours,
      events ?? [],
      currentActiveMonth,
      row,
    );
  }

  return pharmacyLegendObject.map((leg) =>
    (
      <PharmacyLegend
        key={leg.variant}
        totalHrs={Math.trunc(
          getTotalHrs(
            events?.map((e) =>
              eventsMapper(e)) ?? [],
            leg.variant,
            currentActiveMonth,
          ),
        )}
        variant={leg.variant}
        totalCount={getTotalCount(events, leg.variant, currentActiveMonth)}
        customClass={
        row ? genericClasses.legendsRowMargin : genericClasses.legendsMargin
      }
      />
    ));
};

const LegendContainer: FC<ILegendContainer> = ({ row = false }) => {
  const { legendCalendar, userType } = useUser();
  const currentActiveDate = useAppSelector(activeDate);

  return (
    <Box
      component={Paper}
      id="legendContainer"
      sx={
        row
          ? [classes.legendContainerMain, classes.legendContainerRow]
          : classes.legendContainerMain
      }
    >
      {!row && (
        <Typography sx={classes.legendText}>{strings.legend}</Typography>
      )}
      {renderLegendBasedOnType(
        userType,
        legendCalendar,
        currentActiveDate,
        row,
      )}
    </Box>
  );
};

export default memo(LegendContainer);
