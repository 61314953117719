import { SxProps, Theme } from '@mui/system';

const classes = {
  buttonContainer: {
    padding: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  availabilitiesButton: {
    minWidth: '332px',
    backgroundColor: 'common.lightGreen',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: 'common.secondaryButtonHover',
      color: 'primary.main',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
