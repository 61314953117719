import { SxProps, Theme } from '@mui/system';

const styles = {
  timeContainer: {
    display: 'flex',
    gap: '30px',
    marginBottom: '16px',
  },

  message: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    fontWeight: '500',
    fontSize: 18,
    color: 'grey.800',
  },

  container: {
    paddingX: '23px',
  },

  dateContainer: {
    margin: 0,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
