import React from 'react';

import { Box } from '@mui/system';

import { userUnreadCountSelector } from '@pharmaplan/common';
import { useAppSelector } from '../../../../hooks/useAppSelector';

import styles from './styles';

const ChatCount = () => {
  const userUnreadCount = useAppSelector(userUnreadCountSelector);

  if (!userUnreadCount) {
    return null;
  }

  return <Box sx={[styles.shakingCount, styles.count]}>{userUnreadCount}</Box>;
};

export default ChatCount;
