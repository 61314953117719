import { SxProps, Theme } from '@mui/system';

const styles = {
  root: {
    mb: 2.8,
  },

  menuContainer: {
    border: '1px solid',
    borderColor: 'grey.300',
    maxHeight: '2.625em',
    borderRadius: '8.691px',
  },

  menu: {
    '& .MuiPaper-root': {
      mt: '2px',
      color: 'grey.800',
    },
  },

  container: {
    maxHeight: '36px',
    padding: '16px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  field: {
    '&.MuiButtonBase-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },

  placeholder: {
    '&.MuiButtonBase-root': {
      color: 'primary.inputLabel',
      fontWeight: 400,
      opacity: 0.5,
    },
  },

  selected: {
    '&.MuiButtonBase-root': {
      color: 'grey.800',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
