import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  adminCloseChat,
  chatActions,
  ConfirmationTypes,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';

import { resetDialog, setDialog } from '../../../../reducers/dialogReducer';
import ChatManager from '../../../../hooks/Admin/Chat/ChatManager';
import ConfirmationModal from '../../../Modals/ConfirmationModal';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Constants } from '../../../../helpers/Constants';
import CustomButton from '../../../common/CustomButton';
import strings from '../../../../localization';

import styles from './styles';

interface IGroupChannelTitle {
  title: string;
  url: string;
  isViewingEngagedChannel: boolean;
}

const {
  closeChat: closeChatConfirmation,
  makeChannelAvailable: makeChannelAvailableConfirmation,
} = ConfirmationTypes;

const GroupChannelTitle: FC<IGroupChannelTitle> = ({
  title,
  url,
  isViewingEngagedChannel,
}) => {
  const dispatch = useAppDispatch();
  const { makeChannelAvailable } = ChatManager();

  const success = useAppSelector((state) =>
    successSelector([chatActions.adminCloseChat], state));

  const releaseChat = () => {
    makeChannelAvailable(url);
    dispatch(resetDialog());
  };

  const closeChat = () => {
    dispatch(adminCloseChat(url));
  };

  let options = {
    action: closeChat,
    label: strings.endChat,
    type: closeChatConfirmation,
  };

  if (isViewingEngagedChannel) {
    options = {
      action: releaseChat,
      label: strings.releaseChat,
      type: makeChannelAvailableConfirmation,
    };
  }

  const { action, label, type } = options;

  const showModal = () => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={type}
            confirmAction={action}
          />
        ),
        showCloseButton: false,
        heading: {
          title: '',
        },
      }),
    );
  };

  useEffect(() => {
    if (success) {
      dispatch(resetStatus([chatActions.adminCloseChat]));
      dispatch(resetDialog());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>{title}</Typography>
      <CustomButton
        variant={Constants.variant.outlined}
        label={label}
        onClick={showModal}
      />
    </Box>
  );
};

export default GroupChannelTitle;
