import React, { FC } from 'react';

import CustomButton from '../../../common/CustomButton/CustomButton';
import { Constants } from '../../../../helpers/Constants';

import { ISoftwareExpertiseFooter } from '../types';

const {
  variant: { outlined },
} = Constants;

const SoftwareExpertiseFooter: FC<ISoftwareExpertiseFooter> = ({
  footerContent,
}) => {
  const { onClick } = footerContent ?? {};

  return (
    <CustomButton
      id="software-expertise-footer-btn"
      variant={outlined}
      label={footerContent.label}
      onClick={onClick}
    />
  );
};

export default SoftwareExpertiseFooter;
