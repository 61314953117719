import * as Yup from 'yup';
import { AllowancesNames } from '@pharmaplan/common';
import strings from '../../../localization';
import { allowanceValidator, hourlyRateValidator } from '../../../helpers/commonValidators';

const Validator = () =>
  Yup.object().shape({
    [AllowancesNames.fixedMealRate]: Yup.number().when(
      AllowancesNames.mealAllowanceType,
      {
        is: '2',
        then: () =>
          allowanceValidator().required(strings.requiredField),
      },
    ),
    [AllowancesNames.fixedAccommodationRate]: Yup.number().when(
      AllowancesNames.accommodationAllowanceType,
      {
        is: '2',
        then: () =>
          allowanceValidator().required(strings.requiredField),
      },
    ),
    [AllowancesNames.travelAllowanceRatePerKm]: Yup.number().when(
      AllowancesNames.travelAllowanceType,
      {
        is: '2',
        then: () =>
          allowanceValidator().required(strings.requiredField),
      },
    ),
    [AllowancesNames.minFreeTravelDistance]: Yup.number().when(
      AllowancesNames.travelAllowanceType,
      {
        is: '2',
        then: () =>
          Yup.number()
            .typeError(strings.mustBeNumber)
            .required(strings.requiredField)
            .moreThan(
              0,
              strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
            )
            .max(
              999.99,
              strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
            ),
      },
    ),
    [AllowancesNames.paidHourlyRate]: Yup.number().when('isSelfService', {
      is: true,
      then: () =>
        hourlyRateValidator().required(strings.requiredField),
    }),
    [AllowancesNames.emergencyFees]: Yup.number().when('isSelfService', {
      is: true,
      then: allowanceValidator,
    }),
    selectedDate: Yup.string().required(strings.requiredField),
    startTime: Yup.string().when('allDay', {
      is: false,
      then: () =>
        Yup.string().required(strings.requiredField),
    }),
    endTime: Yup.string().when('allDay', {
      is: false,
      then: () =>
        Yup.string().required(strings.requiredField),
    }),
  });

export default Validator;
