import React from 'react';
import spinner from '../../assets/img/spinner/spinner.svg';
import './style.css';

interface ILoaderProps {
  show: boolean;
}

const Loader = ({ show }: ILoaderProps) =>
  (
    <div
      className={show ? 'spinner-container' : 'spinner-container spinner-hide'}
    >
      <img alt="loader" src={spinner} />
    </div>
  );

export default Loader;
