import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import classes from './styles';

interface INotificationHeader {
  header: string;
  notificationAge: string;
}

const NotificationHeader: FC<INotificationHeader> = ({
  header,
  notificationAge,
}) => {
  const { primaryContainer, primaryText, hourText } = classes;

  return (
    <Box sx={primaryContainer}>
      <Typography sx={primaryText}>{header}</Typography>
      <Typography sx={hourText}>{notificationAge}</Typography>
    </Box>
  );
};

export default NotificationHeader;
