import React from 'react';
import { Box } from '@mui/material';
import TermsAndConditionsModal from '../../TermsAndConditionsModal';
import strings from '../../../../localization';
import { setDialog } from '../../../../reducers/dialogReducer';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import classes from './styles';

const CancelTAndCModal = () => {
  const dispatch = useAppDispatch();

  const ShowtermsAndConditionsDialog = () => {
    dispatch(
      setDialog({
        heading: {
          title: strings.termsAndConditionsHeader,
        },
        Component: <TermsAndConditionsModal isCancellation />,
        maxWidth: 'xl',
        customStyle: {
          customModalHeading: classes.customModalHeading,
          customTitleContainer: classes.customTitleContainer,
        },
        showCloseButton: true,
      }),
    );
  };

  return (
    <Box sx={classes.cancelText}>
      {strings.cancelBookingMightIncur}
      <Box
        component="span"
        onClick={ShowtermsAndConditionsDialog}
        sx={[classes.cancelText, classes.termsText]}
      >
        {strings.tAndC}
        ,
      </Box>
      {strings.confirmation}
    </Box>
  );
};

export default CancelTAndCModal;
