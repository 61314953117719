import React, { FC } from 'react';

import { Box } from '@mui/material';

import { selectPharmacistVacations } from '@pharmaplan/common';

import strings from '../../../localization';

import CustomDateRange from '../CustomDateRange';
import DateTimeRange from '../DateTimeRange';
import NotesField from '../NotesField';
import CustomTextField from '../CustomTextField';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useCreateVacation from '../../../hooks/CreateVacations/useCreateVacation';
import useAdmin from '../../../hooks/useAdmin';

import styles from './style';

export interface ICreateVacation {
  date: string;
  id: string;
  pharmacistId?: string;
  pharmacistName?: string;
}

const CreateVacation: FC<ICreateVacation> = ({
  date,
  id,
  pharmacistId,
  pharmacistName,
}) => {
  const vacationDetails = useAppSelector(selectPharmacistVacations);

  const { date: detailsDate } = vacationDetails ?? {};
  const { isAdmin } = useAdmin();

  const { formik } = useCreateVacation({
    pharmacistId,
    pharmacistName,
    date: id ? detailsDate : date,
    id,
  });

  return (
    <Box sx={styles.container}>
      {isAdmin && (
      <CustomTextField
        id="pharmacist"
        label={strings.pharmacist}
        name="pharmacist"
        formik={formik}
        disabled
      />
      )}

      <CustomDateRange
        startName="startDate"
        endName="endDate"
        formik={formik}
        preventPast
        xs={12}
        disabled={!!id}
        customClass={styles.dateContainer}
      />

      <Box component="div" sx={styles.timeContainer}>
        <DateTimeRange
          formik={formik}
          startName="startTime"
          endName="endTime"
          startPlaceholder="00:00"
          endPlaceholder="00:00"
          startNameLabel={strings.startTime}
          endNameLabel={strings.endTime}
          horizontal={false}
          disabled
        />
      </Box>

      <NotesField formik={formik} />
    </Box>
  );
};

export default CreateVacation;
