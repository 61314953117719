import {
  adminActions,
  adminPharmacistPictures,
  adminResetValue,
  resetStatus,
  successOrSelector,
} from '@pharmaplan/common';
import { useEffect } from 'react';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';

const {
  getRequestedPharmacists,
  getBookedPharmacistDetails,
  getAdminEvent,
  getRequestedDetails,
  listOfPharmacists,
  getBroadcastGroupSelectedUsers,
} = adminActions;

const resetActions = [
  'bookedPharmacistData',
  'requestedPharmacists',
  'requestedDetails',
  'listOfPharmacists',
  'getBroadcastGroupSelectedUsers',
];

const successActions = [
  getRequestedPharmacists,
  getBookedPharmacistDetails,
  getAdminEvent,
  getRequestedDetails,
  listOfPharmacists,
  getBroadcastGroupSelectedUsers,
];

const useOptimizedAvatar = (
  pharmacistIds: Array<string>,
  otherCondition = true,
) => {
  const dispatch = useAppDispatch();

  const success = useAppSelector((state) =>
    successOrSelector(successActions, state));

  useEffect(() => {
    if (
      success
      && otherCondition
      && pharmacistIds.length !== 0
      && pharmacistIds[0]
    ) {
      dispatch(adminPharmacistPictures(pharmacistIds));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, otherCondition, pharmacistIds]);

  useEffect(
    () =>
      () => {
        dispatch(adminResetValue(resetActions));
        dispatch(
          resetStatus([...successActions, adminActions.adminPharmacistPictures]),
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useOptimizedAvatar;
