const classes = {
  container: {
    alignItems: 'center',
  },
  formLabel: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  linkLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  formControl: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '-11px',
  },
};

export default classes;
