import React from 'react';

import {
  adminActivateUser,
  adminDeletePendingUser,
  adminGetDeletedUsers,
  ConfirmationTypes,
  getPendingActivations,
  HelpTypes,
  MiscType,
  PendingActivationTypes,
  PendingTabTypes,
} from '@pharmaplan/common';
import { adminUserTypeBasedSearch } from '../../helpers/Functions';

import { useAppDispatch } from '../useAppDispatch';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import { setDialog } from '../../reducers/dialogReducer';

const usePendingActivations = (tab: PendingTabTypes) => {
  const dispatch = useAppDispatch();
  const isDeletedTab = tab === PendingActivationTypes.deletedUsers;

  const pendingActivationsAPI = () => {
    if (isDeletedTab) {
      return adminGetDeletedUsers;
    }
    return getPendingActivations;
  };

  const pendingUserTypes = (
    val: HelpTypes | MiscType | null,
  ): HelpTypes | null => {
    if (isDeletedTab) {
      return adminUserTypeBasedSearch(val, null) as HelpTypes;
    }
    return tab;
  };

  const pendingActivationsDefault = () => {
    dispatch(
      pendingActivationsAPI()({ page: 1, userType: pendingUserTypes(null) }),
    );
  };

  const deleteUser = (personnelId: string, tabUserType: HelpTypes | null) =>
    () => {
      dispatch(
        adminDeletePendingUser({
          id: personnelId,
          userType: tabUserType ?? HelpTypes.pharmacist,
        }),
      );
    };

  const activateUser = (personnelId: string, tabUserType: HelpTypes | null) =>
    () => {
      dispatch(
        adminActivateUser({
          id: personnelId,
          userType: tabUserType ?? HelpTypes.pharmacist,
        }),
      );
    };

  const confirmAction = (tabType: HelpTypes | null) =>
    (confirmationType: ConfirmationTypes, personnelId: string) => {
      const action = confirmationType === ConfirmationTypes.activateUser
        ? activateUser
        : deleteUser;

      dispatch(
        setDialog({
          Component: (
            <ConfirmationModal
              confirmAction={action(personnelId, tabType)}
              type={confirmationType}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: false,
        }),
      );
    };

  return {
    pendingActivationsDefault,
    pendingActivationsAPI,
    pendingUserTypes,
    confirmAction,
  };
};

export default usePendingActivations;
