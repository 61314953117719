import {
  authResendOtp,
  authVerifyOtp,
  resendOtp,
  verifyOtp,
} from '@pharmaplan/common';
import strings from '../localization';

const useAuthOtp = (auth?: boolean) => {
  if (auth) {
    return {
      verifyOtp: authVerifyOtp,
      resendOtp: authResendOtp,
      desc: strings.otpSentNew,
    };
  }
  return {
    verifyOtp,
    resendOtp,
    desc: strings.otpSent,
  };
};

export default useAuthOtp;
