import React, { FC, useState } from 'react';

import {
  Box,
  Collapse,
  IconButton,
  IconButtonProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import classes from './style';
import strings from '../../../localization';

interface ICollapsibleCard {
  title: string;
  content: React.ReactNode;
  keepExpanded?: boolean;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const CollapsibleCard: FC<ICollapsibleCard> = ({ title, keepExpanded, content }) => {
  const [expanded, setExpanded] = useState(keepExpanded ?? false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { ...other } = props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <IconButton {...other} />;
  })(({ theme, expand }) =>
    ({
      transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    }));

  return (
    <Box sx={classes.root}>
      <Box sx={classes.container} onClick={handleExpandClick}>
        <Typography sx={classes.titleFont}>{title}</Typography>
        <Box sx={classes.iconContainer}>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={strings.showDetails}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {content}
      </Collapse>
    </Box>
  );
};

export default CollapsibleCard;
