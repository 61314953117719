import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  ({
    root: {
      pointerEvents: 'none',
    },
    expanded: {},
    content: {
      alignItems: 'center',
    },
    text: {
      marginLeft: 10,
    },
    header: {
      color: 'black',
      marginBottom: 10,
    },
    details: {
      backgroundColor: '#F6F7FC',
      fontWeight: 'normal',
    },
    detailsItem: {
      paddingLeft: '10px',
    },
  }));
