import React, { useEffect, useRef } from 'react';
import {
  ConfirmationTypes,
  ToUserTypings,
  TypeOfUser,
  cancelBooking,
  getTypeOfUser,
  pharmacistActions,
  pharmacyActions,
  pharmacyCancelBooking,
  selfPharmacistCancelBookings,
  selfPharmacyCancelBooking,
  successOrSelector,
} from '@pharmaplan/common';
import { DateTime } from 'luxon';
import { FormikValues } from 'formik';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import strings from '../localization';
import { resetDialog, setDialog } from '../reducers/dialogReducer';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import OutcomeModal from '../components/Modals/OutcomeModal/OutcomeModal';
import { DeactivateModalIcon, OutcomeModalTypes } from '../helpers/Constants';
import CancelTAndCModal from '../components/Modals/ConfirmationModal/CancelTAndCModal';
import DeactivateDialog from '../components/Admin/PharmacistList/DeactivateDialog';

const useCancellationBooking = () => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(getTypeOfUser);
  const bookingRef = useRef('');

  const isPharmacist = userType === TypeOfUser.pharmacist;

  const successes = useAppSelector((state) =>
    successOrSelector(
      [pharmacyActions.cancelBookings, pharmacistActions.selfCancelBooking],
      state,
    ));

  const pharmacistCancel = () => {
    dispatch(selfPharmacistCancelBookings(bookingRef.current));
  };

  const pharmacyCancel = () => {
    dispatch(selfPharmacyCancelBooking(bookingRef.current));
  };

  const cancelConfig = ToUserTypings({
    [TypeOfUser.pharmacist]: pharmacistCancel,
    [TypeOfUser.pharmacy]: pharmacyCancel,
  });

  const successModal = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <OutcomeModal
            type={OutcomeModalTypes.selfServiceCancelConfirmation}
            message={strings.bookingCanceled}
            showCloseButton
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  useEffect(() => {
    if (successes) {
      dispatch(resetDialog());
      dispatch(successModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successes]);

  const handleSelfCancellation = (
    workshiftDate: string,
    confirmationDate: string,
    bookingId: string,
    gracePeriod: number,
  ) => {
    bookingRef.current = bookingId;
    const weeksFromWorkshift = DateTime.fromISO(workshiftDate)
      .diff(DateTime.now(), 'weeks')
      .get('weeks');

    const hoursFromConfirmation = DateTime.fromISO(confirmationDate)
      .diff(DateTime.now(), 'hours')
      .get('hours');

    switch (true) {
      // LESS THAN 2 WEEKS NO CANCELATION
      case weeksFromWorkshift < 2:
        dispatch(
          setDialog({
            heading: {
              title: '',
            },
            Component: (
              <OutcomeModal
                type={OutcomeModalTypes.noCancellation}
                message={strings.youHaveEnteredCancelation}
                showCloseButton
              />
            ),
            showCloseButton: false,
          }),
        );
        break;
      // WITHIN GRACE PERIOD
      case Math.abs(hoursFromConfirmation) < gracePeriod:
        dispatch(
          setDialog({
            heading: {
              title: '',
            },
            Component: (
              <ConfirmationModal
                type={ConfirmationTypes.cancelTAndCModal}
                confirmAction={cancelConfig[userType]}
              />
            ),
            showCloseButton: false,
          }),
        );
        break;
      // OUTSIDE GRACE PERIOD
      case Math.abs(hoursFromConfirmation) > gracePeriod:
        dispatch(
          setDialog({
            heading: {
              title: '',
            },
            Component: (
              <ConfirmationModal
                type={ConfirmationTypes.cancelTAndCModal}
                customMessage={<CancelTAndCModal />}
                confirmAction={cancelConfig[userType]}
              />
            ),
            showCloseButton: false,
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleCancelConfirmation = (values: FormikValues) => {
    const { id, reason } = values ?? {};

    if (isPharmacist) {
      dispatch(cancelBooking({ bookingid: id, reason }));
    } else {
      dispatch(pharmacyCancelBooking({ bookingid: id, reason }));
    }
  };

  const showCancelConfirmation = (
    id: string,
    onSubmit: (values: FormikValues) => void,
  ) => {
    dispatch(
      setDialog({
        showCloseButton: true,
        Component: (
          <DeactivateDialog
            id={id}
            heading={strings.cancelBookingConfirmation}
            confirmationMessage={strings.confirmation}
            iconType={DeactivateModalIcon.cancelBooking}
            onSubmit={onSubmit}
          />
        ),
        heading: {
          title: '',
        },
      }),
    );
  };

  return { handleSelfCancellation, showCancelConfirmation, handleCancelConfirmation };
};

export default useCancellationBooking;
