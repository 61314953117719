import { SxProps, Theme } from '@mui/system';

const styles = {
  autoCompleteContainer: {
    marginLeft: '15px',
  },
  codeContainer: {
    marginLeft: '7px',
  },
  autoComplete: {
    '& .MuiAutocomplete-inputRoot': {
      paddingBottom: '2px',
    },
  },
  textFieldStyle: {
    backgroundColor: 'secondary.main',
  },
  labelStyle: {
    marginBottom: '5px',
  },
  dropdownContainer: {
    ml: '18px',
  },
  deleteContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    alignItems: 'center',
  },
  checkboxContainer: {
    marginRight: '5px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
