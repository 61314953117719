import React, { FC, Fragment } from 'react';

import { Typography, Divider, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProps } from 'formik';

import { formGenerator } from '../../../../../helpers/formGenerator';
import genericClasses from '../../../../../theme/GenericClasses';
import styles from '../styles';

interface ICreatePharmacyAdmin {
  title: string;
  form: () => Array<{
    key: number;
    label: string;
    type: string;
    xs: number;
  }>;
  formik: FormikProps<any>;
}

const CreatePharmacyAdminItem: FC<ICreatePharmacyAdmin> = ({
  title,
  form,
  formik,
}) =>
  (
    <>
      <Box sx={[genericClasses.center, styles.configTitleContainer]}>
        <Typography sx={styles.configTitle}>{title}</Typography>
        <Divider sx={styles.divider} />
      </Box>
      <Grid container>
        {form().map((inItem) =>
          (
            <Fragment key={inItem.key}>{formGenerator(inItem, formik)}</Fragment>
          ))}
      </Grid>
    </>
  );

export default CreatePharmacyAdminItem;
