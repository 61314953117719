import React from 'react';

import { Box } from '@mui/system';

import styles from './styles';

const WavingHand = () =>
  <Box sx={styles.waving}>✋</Box>;

export default WavingHand;
