import React, { FC } from 'react';
import { Typography } from '@mui/material';
import classes from './styles';
import strings from '../../../../localization';

interface IReportAnIssueSuccess {
  refNo: string;
}

const ReportAnIssueSuccess: FC<IReportAnIssueSuccess> = ({ refNo }) =>
  (
    <>
      <Typography sx={classes.text}>{strings.issueRefNo}</Typography>
      <Typography sx={classes.refNoText}>{refNo}</Typography>
      <Typography sx={classes.text}>{strings.issueResp}</Typography>
    </>
  );

export default ReportAnIssueSuccess;
