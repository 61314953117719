import React, { useRef } from 'react';

import CookieConsent from 'react-cookie-consent';
import { Box, Typography } from '@mui/material';

import { preLoginLang } from '@pharmaplan/common';

import CustomButton from '../common/CustomButton';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ReactComponent as CookieIcon } from '../../assets/svg/cookieIcon.svg';
import strings from '../../localization';
import { Constants } from '../../helpers/Constants';

import classes from './style';
import './style.css';

const {
  cookieExpiry,
  paths: { staticPages },
} = Constants;

const {
  displayNone,
  content,
  contentContainer,
  contentSubContainer,
  textContainer,
  privacyPolicyText,
  heading,
  headingContainer,
  button,
} = classes;

const CookiePrompt = () => {
  const cookieConsentRef = useRef<CookieConsent | null>(null);
  const userLanguage = useAppSelector(preLoginLang);

  const { privacyPolicy } = staticPages[userLanguage as keyof typeof staticPages];

  const handleOnClick = () => {
    if (cookieConsentRef.current) {
      cookieConsentRef.current.accept();
    }
  };

  return (
    <CookieConsent
      ref={cookieConsentRef}
      cookieName="cookieConsent"
      expires={cookieExpiry}
      buttonStyle={displayNone}
      overlay
      contentClasses="content-container"
      containerClasses="container"
    >
      <Box sx={contentContainer}>
        <Box sx={headingContainer}>
          <CookieIcon />
          <Typography sx={heading}>{strings.usesCookie}</Typography>
        </Box>

        <Box sx={contentSubContainer}>
          <Box sx={textContainer}>
            <Typography sx={content}>
              {strings.cookieConsent}
              <Typography
                component="a"
                sx={[content, privacyPolicyText]}
                href={privacyPolicy}
                target="_blank"
              >
                {strings.privacyPolicy}
              </Typography>
              .
            </Typography>
          </Box>

          <CustomButton
            customClass={button}
            label={strings.acceptCookies}
            onClick={handleOnClick}
          />
        </Box>
      </Box>
    </CookieConsent>
  );
};

export default CookiePrompt;
