import {
  PharmacistLegendVariant,
  PharmacyLegendVariant,
} from '@pharmaplan/common';

const classes = {
  eventBorder: (
    eventType: PharmacyLegendVariant | PharmacistLegendVariant,
  ) =>
    ({
      borderBottom: '5px solid',
      borderColor: `eventChip.${eventType}Text`,
      borderRadius: '6px',
    }),

  root: {
    backgroundColor: 'white',
    marginBottom: '12px',
    borderRadius: '10px',
    paddingLeft: '18px',
    paddingRight: '18px',
    paddingBottom: '15px',
  },

  title: {
    fontSize: '16px',
    fontWeight: 600,
    paddingTop: '16px',
    paddingBottom: '12px',
    color: 'grey.800',
  },

  footerDivider: {
    borderTop: '1px solid',
    borderColor: 'common.border',
    paddingTop: '12px',
  },

  counterDivider: {
    borderTop: '1px solid',
    borderColor: 'common.pharmacistCounterGreen',
    paddingTop: '12px',
  },

  footerWithoutDivider: {
    paddingTop: '12px',
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  hourlyRateContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  strike: {
    textDecorationLine: 'line-through',
    paddingRight: '6px',
    fontSize: '14px',
    fontWeight: 400,
    color: 'common.grey850',
  },

  pharmacyCounterCard: {
    backgroundColor: 'common.pharmacyCounterGreen',
  },

  pharmacistCounterCard: {
    border: '1px solid',
    borderColor: 'common.pharmacistCounterGreen',
  },
};

export default classes;
