import { FormikValues, useFormik } from 'formik';
import { DateTime } from 'luxon';

import { useAppDispatch } from '../../useAppDispatch';
import { MomentTimeFormats } from '../../../helpers/Constants';

import useLogsHandler from './useLogsHandler';

const { fullDate } = MomentTimeFormats;

const useLogsFormik = (
  setPage: React.Dispatch<React.SetStateAction<number>>,
) => {
  const dispatch = useAppDispatch();
  const { api } = useLogsHandler();

  const initialValues = {
    startDate: null,
    endDate: null,
    performerText: '',
    dropdown: '',
    userText: '',
    action: null,
    module: '',
    pharmacistText: '',

    pharmacistId: '',
    pharmacists: '',
    pharmacistSelected: '',
    pharmacyId: '',
    pharmacies: '',
    pharmacySelected: '',
  };

  const onSubmit = (values: FormikValues) => {
    const { startDate, endDate, performerText, userText, pharmacistId, pharmacyId, action } = values ?? {};
    setPage(1);

    console.log(pharmacistId, pharmacyId);
    dispatch(
      api({
        startDate: startDate
          ? (startDate as DateTime).toFormat(fullDate)
          : null,
        endDate: endDate ? (endDate as DateTime).toFormat(fullDate) : null,
        page: 1,
        performerText,
        userText,
        action: action?.key ?? action,
        pharmacyId,
        pharmacistId,
        module: action?.module,
      }),
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  return { formik, initialValues };
};

export default useLogsFormik;
