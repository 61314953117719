import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    padding: '20px 33px',
    flexDirection: 'column',
  },
  title: {
    color: 'common.blue400',
    fontSize: 14,
    mb: '8px',
  },
  divider: {
    height: 20,
    width: '1px',
    backgroundColor: 'common.borderInput',
    mx: '16px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
