import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 480,
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
  },
  body: {
    fontSize: 12,
    fontWeight: '500',
  },
  hour: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'grey.500',
  },
  content: {
    flex: 1,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
