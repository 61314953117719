import { SxProps, Theme } from '@mui/system';

const styles = {
  serviceContainer: {
    pt: '15px',
  },
  buttonContainer: {
    paddingTop: '16px',
  },
  button: {
    backgroundColor: 'common.lightGreen',
    color: 'primary.main',
  },
  empty: {
    marginBottom: 2,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
