const style = {
  boxContainer: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 800,
  },
  container: {
    backgroundColor: 'white',
    minWidth: 800,
    paddingX: 5,
    paddingTop: 3,
    borderRadius: 3,
    paddingBottom: 3,
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    marginBottom: 2,
    fontSize: 20,
    fontWeight: '500',
  },
  alignleft: {
    display: 'flex !important',
    justifyContent: 'left !important',
  },
  mapButton: {
    justifyContent: 'start',
    textTransform: 'none',
    width: '97.5%',

    '& .MuiButton-endIcon': {
      marginLeft: 'auto',
    },
  },
  mapButtonHelperTextStyle: {
    color: '#d32f2f',
    fontSize: '0.75rem',
    marginTop: '3px',
    marginLeft: '14px',
  },
};

export default style;
