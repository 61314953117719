import React from 'react';

import { FormikValues } from 'formik';

import {
  adminDeleteAvailability,
  PermissionsOfAdmin,
  PermissionsRemovalKeys,
} from '@pharmaplan/common';
import { renderScreen } from '../../../actions/drawerActions';
import InputModal from '../../../components/Modals/InputModal';
import { Constants, ScreenTypes } from '../../../helpers/Constants';
import strings from '../../../localization';
import { setDialog } from '../../../reducers/dialogReducer';
import { useAppDispatch } from '../../useAppDispatch';
import { removeBasedOnPermissions } from '../../../helpers/permissionsHelper';
import useAdminPermissions from '../useAdminPermissions';

const config = [
  {
    key: '0',
    name: 'notes',
    type: Constants.formInputType.text,
    gridLabel: strings.notes,
    multiline: true,
    variant: 'filled',
    fieldXs: 8,
    labelXs: 4,
  },
];

const useShowPharmacistAvailabilities = () => {
  const dispatch = useAppDispatch();
  const { can } = useAdminPermissions();

  const deleteAvailability = (values: FormikValues) => {
    const { notes, availabilityId } = values ?? {};
    dispatch(
      adminDeleteAvailability({ availabilityIds: [availabilityId], notes }),
    );
  };

  const showDeleteDialog = (availabilityId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <InputModal
              config={config}
              initialValues={{ availabilityId, notes: '' }}
              submitLabel={strings.delete}
              onSubmit={deleteAvailability}
            />
          ),
          showCloseButton: true,
          heading: {
            title: strings.deleteAvailability,
          },
        }),
      );
    };

  const goToMatchingWorkshifts = (availabilityId: string, code: string) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 5,
          screenType: ScreenTypes.pharmacistList,
          eventId: availabilityId,
          date: code,
        }),
      );
    };

  const actions = (availabilityId: string, code: string) => {
    const arr = [
      {
        key: PermissionsRemovalKeys.Delete,
        onClick: showDeleteDialog(availabilityId),
        label: strings.delete,
      },
      {
        key: '1',
        onClick: goToMatchingWorkshifts(availabilityId, code),
        label: strings.findMatchingWorkshifts,
      },
    ];

    return removeBasedOnPermissions(arr, [PermissionsOfAdmin.Delete], can);
  };

  return { actions };
};

export default useShowPharmacistAvailabilities;
