// THE DEFAULT COMPONENTS ARE BUILT TO HANDLE DEFAULT PROPS.
/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */

import { Skeleton, SxProps, Theme } from '@mui/material';
import { successSelector } from '@pharmaplan/common';
import React from 'react';
import { useAppSelector } from '../../../hooks/useAppSelector';

interface ICustomSkeleton {
  successAction: string;
  variant: 'text' | 'rectangular' | 'rounded' | 'circular';
  height?: number;
  width?: number;
}

type Props = React.PropsWithChildren & {
  sx?: SxProps<any>;
  src?: string;
  alt?: string;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement> & {
    sx?: SxProps<Theme>;
  };
  onClick?: () => void;
};
const withSkeleton = ({ successAction, variant, height, width }: ICustomSkeleton) =>
  (OriginalComponent: React.ComponentType<any>) => {
    const SkeletonComponent = (props: Props) => {
      const success = useAppSelector((state) =>
        successSelector([successAction], state));

      return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {success ? (
            <OriginalComponent {...props}>{props.children}</OriginalComponent>
          ) : (
            <Skeleton
              variant={variant}
              height={height}
              width={width}
              sx={props.sx}
            >
              <OriginalComponent {...props}>{props.children}</OriginalComponent>
            </Skeleton>
          )}
        </>
      );
    };
    return SkeletonComponent;
  };

export default withSkeleton;
