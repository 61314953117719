import React, { useEffect } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  adminGetSoftware,
  adminGetSoftwareList,
  adminResetValue,
  adminSoftware,
  adminUpdateSoftware,
  IAdminUpdateSoftwareApiParams,
  resetStatus,
  successOrSelector,
  successSelector,
} from '@pharmaplan/common';
import Validator from '../../components/Admin/SoftwareList/validator';
import InputModal from '../../components/Modals/InputModal';
import strings from '../../localization';
import { setDialog } from '../../reducers/dialogReducer';
import { addNewSoftwareForm } from '../../components/Admin/SoftwareList/helper';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';

const {
  getSoftware: getSoftwareAction,
  updateSoftware: updateSoftwareAction,
  createSoftware,
  deleteSoftwares,
} = adminActions;

const successAction = getSoftwareAction;
const updateSuccess = updateSoftwareAction;
const addSuccess = createSoftware;
const deleteSuccess = deleteSoftwares;

const useSofwareList = () => {
  const dispatch = useAppDispatch();

  const software = useAppSelector(adminSoftware);
  const { name, description, published, softwareId } = software ?? {};
  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const addUpdateDeleteSuccess = useAppSelector((state) =>
    successOrSelector([updateSuccess, addSuccess, deleteSuccess], state));

  const updateSoftware = (values: FormikValues) => {
    dispatch(adminUpdateSoftware(values as IAdminUpdateSoftwareApiParams));
  };

  const getSoftware = (softId: string) => {
    dispatch(adminGetSoftware(softId));
  };
  const initialValues = {
    name,
    description,
    published,
    softwareId,
  };

  const updateModal = () => {
    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={updateSoftware}
            initialValues={initialValues}
            submitLabel={strings.update}
            config={addNewSoftwareForm}
            validator={Validator()}
          />
        ),
        heading: {
          title: strings.update,
        },
        showCloseButton: true,
      }),
    );
  };

  useEffect(() => {
    if (addUpdateDeleteSuccess) {
      dispatch(adminGetSoftwareList({ paging: {} }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateDeleteSuccess]);

  useEffect(() => {
    if (initialValues.softwareId && success) {
      updateModal();
      dispatch(resetStatus([successAction]));
      dispatch(adminResetValue(['software']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.softwareId, success]);
  return { getSoftware };
};

export default useSofwareList;
