import React, { FC } from 'react';

import { currency } from '@pharmaplan/common';

import strings from '../../../../localization';
import PriceLabel from '../PriceLabel/PriceLabel';
import { IBillFooter } from '../types';

import styles from './style';

const BillFooter: FC<IBillFooter> = ({
  total,
  counterTotal,
  successAction,
  suffix,
}) =>
  (
    <PriceLabel
      successAction={successAction}
      title={strings.total}
      price={`${currency}${total}${suffix}`}
      customStyle={styles.total}
      counterPrice={counterTotal && `$${counterTotal}`}
    />
  );

export default BillFooter;
