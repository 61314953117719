const classes = {
  customTextFieldContainer: {
    marginLeft: '10px',
    position: 'relative',
  },
  zeroRadiusInput: {
    '& .MuiInputBase-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  customTextFieldRequireLabel: { color: 'error.main', marginLeft: '2px' },
  icon: {
    height: '37px',
    width: '36px',
  },
};

export default classes;
