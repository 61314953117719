import { Box, Grid } from '@mui/material';
import React from 'react';
import { formGenerator } from '../../../../helpers/formGenerator';
import strings from '../../../../localization';
import CustomButton from '../../../common/CustomButton';
import CustomRadioTable from '../../../common/CustomRadioTable';

import classes from './style';
import ProfileLocationMapView from '../ProfileLocationMapView/ProfileLocationMapView';

interface IProfileTitle {
  title: string;
  formik: any;
  items: any;
  table?: boolean;
  map?: boolean;
  hideSave?: boolean;
  isSignup?: boolean;
  setSelected?: any;
}

const defaultProps = {
  table: false,
  map: false,
  hideSave: false,
  isSignup: false,
  setSelected: null,
};

const ProfileFormGenerator = ({
  formik,
  items,
  title,
  table,
  map,
  hideSave,
  isSignup,
  setSelected,
}: IProfileTitle) =>
  (
    <Box sx={classes.profileFormContainer}>
      <Grid container>
        {title && (
        <Grid sx={classes.mainHeader} xs={12}>
          {title}

          <Box component="span" sx={classes.profileTitleLine} />

        </Grid>
        )}
        <Grid container xs={12}>
          {table ? (
            <Grid item xs={12}>
              <CustomRadioTable formik={formik} form={items} isSignup={isSignup} />
            </Grid>
          ) : (
            items.map((item: any) =>
              formGenerator(item, formik))
          )}
        </Grid>
        <Grid container xs={12}>
          {map && (
          <Grid item xs={12}>
            <ProfileLocationMapView setSelected={setSelected} />
          </Grid>
          )}
        </Grid>
        {!hideSave && (
        <Grid sx={classes.buttonContainer} container>
          <CustomButton onClick={formik?.handleSubmit} label={strings.save} />
        </Grid>
        )}
      </Grid>

    </Box>
  );
ProfileFormGenerator.defaultProps = defaultProps;

export default ProfileFormGenerator;
