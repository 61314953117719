import React, { useState } from 'react';

import { ThunkAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { ConfirmationTypes } from '@pharmaplan/common';
import { useAppDispatch } from '../useAppDispatch';
import { setDialog } from '../../reducers/dialogReducer';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';

export type PublishCallbackType<T> = (
  params: T
) => ThunkAction<unknown, any, undefined, AnyAction>;

export type IHandlePublishSignature = <T, key extends keyof T>(
  params: T,
  id: key,
  callback: PublishCallbackType<T>
) => () => void;

const usePublishConfigurations = () => {
  const dispatch = useAppDispatch();
  const [publishedRecords, setPublishedRecords] = useState<
    Record<string, boolean>
  >({});

  const mapPublishedRecords = <T, key extends keyof T>(
    list: Array<T>,
    id: key,
    publishName: key,
  ) =>
      list.reduce((acc: Record<string, boolean>, curr) => {
        const k = curr[id] as string;
        const published = curr[publishName] as boolean;
        acc[k] = published;
        return acc;
      }, {});

  const handlePublish = <T, key extends keyof T>(
    params: T,
    id: key,
    callback: PublishCallbackType<T>,
  ) =>
      () => {
        const k = params[id] as string;
        setPublishedRecords((prev) =>
          ({
            ...prev,
            [k]: !prev[k],
          }));

        dispatch(callback(params));
      };

  const confirmPublish = <T, key extends keyof T>(
    params: T,
    id: key,
    callback: PublishCallbackType<T>,
  ) =>
      () => {
        const { published } = (params as T & { published: boolean }) ?? {};
        const type = published
          ? ConfirmationTypes.publishConfiguration
          : ConfirmationTypes.unPublishConfiguration;

        dispatch(
          setDialog({
            Component: (
              <ConfirmationModal
                confirmAction={handlePublish(params, id, callback)}
                type={type}
              />
            ),
            showCloseButton: false,
          }),
        );
      };

  return {
    setPublishedRecords,
    handlePublish: confirmPublish,
    mapPublishedRecords,
    publishedRecords,
  };
};

export default usePublishConfigurations;
