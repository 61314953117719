import React, { FC } from 'react';

import { Box, Divider, Grid, Typography } from '@mui/material';

import NumberLabel from './NumberLabel';
import styles from './styles';

interface IDetailsCard {
  title: string;
  config: Array<{
    value: number | string;
    label: string;
    key: string | number;
  }>;
}

const DetailsCard: FC<IDetailsCard> = ({ config, title }) =>
  (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>{title}</Typography>

      <Grid container>
        {config.map((item, index) => {
          const { value, label, key } = item;
          const isLastItem = index === config.length - 1;
          return (
            <Grid key={key} item display="flex" alignItems="center">
              <NumberLabel value={value} label={label} />
              {!isLastItem && <Divider sx={styles.divider} />}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );

export default DetailsCard;
