import React, { FC, useEffect } from 'react';

import { SxProps, Box } from '@mui/material';

import {
  AdminLegendVariant,
  IAvailableWorkshiftData,
  adminActions,
  adminEvent,
  getAdminEvent,
  logos,
  userPreferredTimeFormat,
  resetStatus,
  adminWatchlistedWorkshifts,
} from '@pharmaplan/common';
import CustomAvatarSection from '../../common/CustomAvatarSection';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  setBack,
  setDrawerContentPadding,
  setFooterShow,
  setTitle,
} from '../../../actions/drawerActions';
import DrawerOutOfDateTitle from '../../common/DrawerOutOfDateTitle';
import AdminHourlyRateFooter from '../../common/AdminHourlyRateFooter';
import { ReactComponent as Location } from '../../../assets/svg/location.svg';
import useAvailableWorkshiftActions from '../../../hooks/AvailableWorkshift/useAvailableWorkshiftActions';
import { useAppSelector } from '../../../hooks/useAppSelector';
import ThemeConstants from '../../../theme/ThemeConstants';
import strings from '../../../localization';

import { workshiftListBuilder } from './helpers';
import styles from './styles';

export const LocationText: FC<{
  label: string | React.ReactNode;
  containerStyle?: SxProps;
}> = ({ label, containerStyle }) =>
  (
    <Box sx={[styles.locationContainer, containerStyle] as SxProps}>
      <Location
        fill={ThemeConstants.grey[600]}
        height={13}
        width={10}
        style={styles.locationIcon}
      />
      {label}
    </Box>
  );

interface IAvailableWorkshift {
  viewOnly: boolean;
  date?: string;
  pharmacistId?: string;
}

const AvailableWorkshifts: FC<IAvailableWorkshift> = ({
  pharmacistId = '',
  date = '',
  viewOnly = false,
}) => {
  const dispatch = useAppDispatch();

  const allLogos = useAppSelector(logos);
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const availableWorkshifts = useAppSelector(adminEvent);
  const watchListedWorkshifts = useAppSelector(adminWatchlistedWorkshifts);

  const eventHandler = viewOnly ? watchListedWorkshifts : availableWorkshifts;
  const successAction = viewOnly
    ? adminActions.watchlists
    : adminActions.getAdminEvent;

  const { actions, goToViewDetails, watchlistViewDetails } = useAvailableWorkshiftActions();

  useEffect(() => {
    const title = !viewOnly ? (
      <DrawerOutOfDateTitle
        dateLabel={strings.availableWorkshiftFormatted}
        date={date}
      />
    ) : (
      strings.watchlistedPharmacyWorkshifts
    );

    if (!viewOnly) {
      dispatch(
        getAdminEvent({
          type: AdminLegendVariant.posted,
          date: date.split('T')[0],
        }),
      );
    }

    dispatch(setBack(null));
    dispatch(setTitle({ title }));
    dispatch(setDrawerContentPadding('0px'));
    dispatch(setFooterShow(false));

    return () => {
      dispatch(resetStatus([successAction]));
      dispatch(setDrawerContentPadding('16px'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const events = workshiftListBuilder(
    eventHandler as IAvailableWorkshiftData[],
    allLogos,
    timeFormat,
    viewOnly,
  );

  return (
    <>
      {events.map((item) => {
        const {
          config,
          title,
          avatar,
          workshiftId,
          pharmacistHourlyRate,
          pharmacyHourlyRate,
          pharmacyId,
          startDate,
          endDate,
          isRequested,
        } = item ?? {};

        const menuActions = viewOnly
          ? null
          : actions({
            id: workshiftId,
            startDate,
            endDate,
            pharmacyId,
          });

        const handleClick = () => {
          if (viewOnly) {
            watchlistViewDetails({ workshiftId, pharmacistId, pharmacyId, isRequested });
          } else {
            goToViewDetails({ workshiftId, date, pharmacyId });
          }
        };

        return (
          <Box key={workshiftId} sx={styles.buttonContainer}>
            <CustomAvatarSection
              customMenuContainerStyle={styles.menu}
              successAction={successAction}
              config={config}
              title={title}
              avatar={avatar}
              showFullImage={false}
              onClick={handleClick}
              imageSuccessAction={successAction}
              menuActions={menuActions}
            />
            <Box sx={styles.footerContainer}>
              <AdminHourlyRateFooter
                pharmacyHourlyRate={pharmacyHourlyRate as number}
                pharmacistHourlyRate={pharmacistHourlyRate as number}
                successAction={successAction}
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default AvailableWorkshifts;
