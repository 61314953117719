import { createAction } from "@reduxjs/toolkit"
import { IRecurrenceState } from "../reducers/recurrenceReducer";

export const recurrenceActions = {
    setRecurrence: 'recurrence/setRecurrence',
    reset: 'recurrence/reset',
};

export const setRecurrence = createAction<IRecurrenceState>(recurrenceActions.setRecurrence);

export const reset = createAction(recurrenceActions.reset);
