import { ITheme } from '.';

export default {
  styleOverrides: {
    root: ({ theme }: ITheme) =>
      ({
        color: theme.palette.grey[800],
        fontSize: '0.875rem',
        fontWeight: 500,
      }),
  },
};
