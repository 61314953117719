import { useCallback, useEffect } from 'react';
import {
  DashboardType,
  TypeOfUser,
  calendarDate,
  calendarFilter,
  getLanguagesInfo,
  getNotificationsCount,
  getPharmacistCalendar,
  getPharmacistFilterValues,
  getPharmacistPicture,
  getPharmacyCalendar,
  getPharmacyInfo,
  getTypeOfUser,
  getUserGeneral,
  profileUtils,
  setActiveDashboard,
  pharmacistActions,
  successOrSelector,
  resetStatus,
  getClientAdvertisements,
} from '@pharmaplan/common';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { Constants } from '../helpers/Constants';
import { setPageTitle } from '../helpers/Functions';
import strings from '../localization';
import useInterval from './useInterval';
import useSelfService from './useSelfService';
import useFocusTab from './useFocusTab';

const { pharmacist } = TypeOfUser;
const intervalDelay = 5 * 60 * 1000;

const watchListActions = [
  pharmacistActions.watchlist,
  pharmacistActions.deleteWatchlist,
];

const useDashboard = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isFocused } = useFocusTab();
  const { isSelfService } = useSelfService();

  const cDate = useAppSelector(calendarDate);
  const userType = useAppSelector(getTypeOfUser);
  const filters = useAppSelector(calendarFilter);

  const watchListActionsSuccess = useAppSelector((state) =>
    successOrSelector(watchListActions, state));

  const { pathname } = location;
  const splitPath = pathname.split('/')[2];

  const { weekendVolume, weekVolume } = filters ?? {};
  const isWeekendVolumeString = typeof weekendVolume === Constants.string;
  const isweekVolumeString = typeof weekVolume === Constants.string;

  const isPharmacist = userType === pharmacist;

  const getUserBasedCalendar = useCallback(() => {
    if (isPharmacist) {
      dispatch(
        getPharmacistCalendar({
          ...filters,
          weekendVolume: isWeekendVolumeString
            ? JSON.parse(weekendVolume)
            : weekendVolume,
          weekVolume: isweekVolumeString ? JSON.parse(weekVolume) : weekVolume,
          ...cDate,
        }),
      );
    } else {
      dispatch(getPharmacyCalendar(cDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, filters, cDate, weekendVolume, weekVolume]);

  const getUserData = () => {
    if (isPharmacist) {
      dispatch(getPharmacistFilterValues());
      dispatch(getUserGeneral());
      dispatch(getPharmacistPicture());
    } else {
      dispatch(getPharmacyInfo());
    }
  };

  useEffect(() => {
    dispatch(setActiveDashboard(splitPath as DashboardType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    getUserBasedCalendar();
  }, [getUserBasedCalendar]);

  useEffect(() => {
    // STARTUP API CALLS
    setPageTitle(strings.dashboard);
    getUserData();
    dispatch(profileUtils(userType));
    dispatch(
      getClientAdvertisements(
        userType as TypeOfUser.pharmacist | TypeOfUser.pharmacy,
      ),
    );
    dispatch(getLanguagesInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watchListActionsSuccess) {
      getUserBasedCalendar();
      dispatch(resetStatus(watchListActions));
    }
  }, [watchListActionsSuccess]);

  const intervalCallback = () => {
    if (isFocused) {
      getUserBasedCalendar();
      isSelfService && dispatch(getNotificationsCount());
    }
  };

  useInterval(intervalCallback, intervalDelay, [cDate, isFocused]);
};

export default useDashboard;
