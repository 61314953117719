import React, { useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { resetStatus, sendOtp, successSelector, userActions } from '@pharmaplan/common';
import { ReactComponent as SetEmailIcon } from '../../../assets/svg/setEmailIcon.svg';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import strings from '../../../localization';
import CustomTextField from '../../common/CustomTextField';
import LoginCustomCard from '../../common/LoginCustomCard';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { resetDialog } from '../../../reducers/dialogReducer';
import { Constants } from '../../../helpers/Constants';

import styles from './styles';

const { sendOtp: sendAction } = userActions;
const { verifyOtp } = Constants.paths;

const SetEmail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const success = useAppSelector((state) =>
    successSelector([sendAction], state));

  const handleSubmit = (values: FormikValues) => {
    const { email } = values ?? {};
    dispatch(sendOtp(email));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: handleSubmit,
  });

  const textArray = [
    {
      key: 1,
      text: strings.setNewEmail,
      sx: styles.infoHeading,
    },
    {
      key: 2,
      text: strings.resetDesc,
      sx: styles.infoDesc,
    },
  ];

  useEffect(() => {
    if (success) {
      navigate(verifyOtp, { state: { email: formik.values?.email }, replace: true });
      dispatch(resetDialog());
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <LoginCustomCard
      fullScreen={false}
      icon={<SetEmailIcon />}
      textArray={textArray}
      buttonTitle={strings.send}
      onClick={formik.handleSubmit}
    >
      <CustomTextField
        name="email"
        placeholder="name@mail.com"
        id="email"
        formik={formik}
        label={strings.email}
      />
    </LoginCustomCard>
  );
};

export default SetEmail;
