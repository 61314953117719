import { SxProps, Theme } from '@mui/system';

const styles = {
  valueStyle: {
    color: 'primary.main',
    fontWeight: '600',
    fontSize: 22,
  },
  labelStyle: {
    fontSize: 12,
    color: 'common.gray',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
