import { Box } from '@mui/system';
import React from 'react';
import classes from './style';
import { ReactComponent as NotFoundImage } from '../../assets/svg/notFound.svg';

const NotFound = () =>
  (
    <Box sx={classes.container}>
      <NotFoundImage />
    </Box>
  );
export default NotFound;
