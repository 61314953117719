import React, { useEffect, useState } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  adminBookedPharmacistDetails,
  adminBookingDetails,
  AdminLegendVariant,
  adminRequestCancellation,
  adminResendEmail,
  adminResetValue,
  getRequestCancellation,
  PermissionsOfAdmin,
  PermissionsRemovalKeys,
  resetStatus,
  selectRequestCancellation,
  successSelector,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import { renderScreen } from '../../actions/drawerActions';
import { OutcomeModalTypes, ScreenTypes } from '../../helpers/Constants';
import InputModal from '../../components/Modals/InputModal';
import { setDialog } from '../../reducers/dialogReducer';
import styles from '../../components/Admin/RequestedPharmacistList/PharmacistList/styles';
import {
  cancellationForm,
  resendEmailForm,
} from '../../components/Admin/BookingList/helper';
import {
  getDayMonthDateYear,
  getHourMinute,
} from '../../components/Reports/helpers';
import OutcomeModal from '../../components/Modals/OutcomeModal';
import strings from '../../localization';

import { genericFunction } from './useAvailableWorkshiftActions';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';

import Validator from './Validator';
import { serverToday } from '../../helpers/Functions';
import useAdminPermissions from '../Admin/useAdminPermissions';
import { removeBasedOnPermissions } from '../../helpers/permissionsHelper';
import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import ResendEmailValidator from './ResendEmailValidator';

interface ICancellationInitialValues {
  startDate: string;
  endDate: string;
  pharmacyName: string;
}

export const menuActions = (
  requestCancellation: genericFunction,
  editAction: genericFunction,
  viewDetailsAction: genericFunction,
  resendEmail: genericFunction,
) =>
  [
    {
      key: '1',
      label: strings.viewDetails,
      onClick: viewDetailsAction,
    },
    {
      key: PermissionsRemovalKeys.ExpressBooking,
      label: strings.edit,
      onClick: editAction,
    },
    {
      key: '3',
      label: strings.requestCancellation,
      onClick: requestCancellation,
    },
    {
      key: '3',
      label: strings.resendEmail,
      onClick: resendEmail,
    },
  ];

const useBookedWorkshiftActions = (goToBookingList: () => void) => {
  const [bookingID, setBookingID] = useState('');
  const [isResendEmail, setIsResendEmail] = useState(false);
  const dispatch = useAppDispatch();
  const today = serverToday().toISO({ includeOffset: false }) ?? '';
  const { can } = useAdminPermissions();

  const successAction = useAppSelector((state) =>
    successSelector([adminActions.getBookedPharmacistDetails], state));

  const emailResendSuccess = useAppSelector((state) =>
    successSelector([adminActions.adminResendEmail], state));

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const cancellationData = useAppSelector(selectRequestCancellation);
  const bookingDetails = useAppSelector(adminBookingDetails);

  const { startDate, endDate, pharmacyName, success, message } = cancellationData ?? {};
  const {
    startDate: bookingStartDate,
    endDate: bookingEndDate,
    pharmacyName: bookingPharmacyName,
    firstName,
    lastName,
  } = bookingDetails ?? {};

  const pharmacistName = `${firstName} ${lastName}`;

  const goToViewDetails = (bookingId: string, pharmacyId: string) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 3,
          screenType: ScreenTypes.availableWorkshift,
          eventId: bookingId,
          type: AdminLegendVariant.booked,
          back: goToBookingList,
          pharmacyId,
        }),
      );
    };

  const requestCancellation = (bookingId: string) =>
    (values: FormikValues) => {
      const { reason } = values ?? {};
      dispatch(adminRequestCancellation({ reason, bookingId }));
    };

  const resendEmailSubmit = (bookingId: string) =>
    (values: FormikValues) => {
      const { sendEmailToPharmacy, sendEmailToPharmacist } = values ?? {};
      dispatch(adminResendEmail({ sendEmailToPharmacist, sendEmailToPharmacy, bookingId }));
    };

  const showRequestCancellationForm = (bookingId: string) => {
    const date = getDayMonthDateYear(startDate);
    const time = `${getHourMinute(startDate, timeFormat)}-${getHourMinute(
      endDate,
      timeFormat,
    )}`;

    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={requestCancellation(bookingId)}
            initialValues={{
              date,
              time,
              pharmacyName,
              reason: '',
            }}
            submitLabel={strings.submit}
            config={cancellationForm()}
            validator={Validator()}
          />
        ),
        heading: {
          title: strings.requestCancellation,
        },
        customStyle: {
          customTitleContainer: styles.modalMargin,
        },
        showCloseButton: true,
      }),
    );
  };

  const showResendEmail = (bookingId: string) => {
    const date = getDayMonthDateYear(bookingStartDate);
    const time = `${getHourMinute(
      bookingStartDate,
      timeFormat,
    )}-${getHourMinute(bookingEndDate, timeFormat)}`;

    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={resendEmailSubmit(bookingId)}
            initialValues={{
              date,
              time,
              pharmacyName: bookingPharmacyName,
              pharmacistName,
              sendEmailToPharmacist: true,
              sendEmailToPharmacy: true,
            }}
            submitLabel={strings.submit}
            config={resendEmailForm()}
            validator={ResendEmailValidator()}
          />
        ),
        heading: {
          title: strings.resendEmail,
        },
        customStyle: {
          customTitleContainer: styles.modalMargin,
        },
        showCloseButton: true,
      }),
    );
  };

  const showAlreadyRequestCancelled = () => {
    dispatch(
      setDialog({
        Component: (
          <OutcomeModal
            type={OutcomeModalTypes.submitSuccess}
            message={message}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const goToRequestedCancellation = (bookingId: string) =>
    () => {
      dispatch(getRequestCancellation(bookingId));
      setBookingID(bookingId);
    };

  const resendEmail = (bookingId: string) =>
    () => {
      setBookingID(bookingId);
      dispatch(adminBookedPharmacistDetails(bookingId));
      setIsResendEmail(true);
    };

  const handleRequestCase = () => {
    if (success) {
      showRequestCancellationForm(bookingID);
    } else {
      showAlreadyRequestCancelled();
    }
    dispatch(adminResetValue(['requestCancellation']));
  };

  useEffect(() => {
    if (success !== null && bookingID) {
      handleRequestCase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, bookingID]);

  useEffect(() => {
    if (bookingID && isResendEmail && successAction) {
      showResendEmail(bookingID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingID, isResendEmail, successAction]);

  useEffect(() => {
    if (emailResendSuccess) {
      showSuccess(dispatch, strings.emailResentSuccessMsg);
      dispatch(resetStatus([adminActions.adminResendEmail]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailResendSuccess]);

  const editBookingAction = (id: string) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 5,
          screenType: ScreenTypes.availableWorkshift,
          eventId: id,
          type: AdminLegendVariant.booked,
        }),
      );
    };

  const actions = (
    id: string,
    pharmacyId: string,
    additionalParams: ICancellationInitialValues,
  ) => {
    let menu = menuActions(
      goToRequestedCancellation(id),
      editBookingAction(id),
      goToViewDetails(id, pharmacyId),
      resendEmail(id),
    );

    if (additionalParams.startDate < today) {
      menu = menu.slice(0, 1);
    }

    menu = removeBasedOnPermissions(
      menu,
      [PermissionsOfAdmin.ExpressBooking],
      can,
    );

    return menu;
  };

  return { actions, goToRequestedCancellation };
};

export default useBookedWorkshiftActions;
