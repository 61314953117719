import { Theme, SxProps } from '@mui/material';

const classes = {
  footerContainer: {
    width: '100%',
    backgroundColor: 'common.lightBackgroundColor',
    zIndex: '999',
    boxShadow: '0px -2px 10px 0px rgba(0, 0, 0, 0.10)',
    borderTop: '1px solid',
    color: 'common.border',
  },

  buttonContainer: {
    padding: '8px 41px 16px 41px',
  },

  button: {
    marginTop: '18px',
  },

  paginationContainer: {
    boxShadow: '0px -2px 10px 0px rgba(0, 0, 0, 0.10)',
  },

  defaultSecondaryButton: {
    backgroundColor: 'secondary.main',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: 'common.secondaryButtonHover',
      color: 'primary.main',
    },
  },

  footerButtonMt: {
    marginTop: '10px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
