import React from 'react';

import { convertToDecimals } from '@pharmaplan/common';
import strings from '../../../../localization';

import { LocationText } from '../../AvailableWorkshifts/AvailableWorkshifts';

export const listRowConfig = (replacements: number | string, hours: number, city: string) =>
  [
    {
      key: '1',
      value: `${strings.totalReplacements}: ${replacements}`,
    },
    {
      key: '2',
      value: `${strings.totalHours}: ${convertToDecimals(hours)}`,
    },
    {
      key: '3',
      value: <LocationText label={city} />,
    },
  ];
