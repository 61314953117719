import { ITheme } from '.';

export default {
  styleOverrides: {
    root: ({ theme }: ITheme) =>
      ({
        color: theme.palette.grey[500],
      }),
  },
};
