import React from 'react';

import { FormikValues } from 'formik';

import {
  ISalesforceAdmin,
  adminActions,
  editSalesforceAdmins,
  salesforceAdmins,
  successSelector,
  ConfirmationTypes,
  deleteSalesforceAdmins,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useAdminConfigurationRefresh from '../../../hooks/Admin/useAdminConfigurationRefresh';

import DynamicTable from '../../DynamicTable';
import SalesforceTable from './SalesforceTable';
import { tableHeaderConfig, tableRowConfig } from './helper';
import styles from './style';
import { setDialog } from '../../../reducers/dialogReducer';
import ConfirmationModal from '../../Modals/ConfirmationModal';

const AdminConfiguration = () => {
  const dispatch = useAppDispatch();

  const sfAdmins = useAppSelector(salesforceAdmins);
  const loadSuccess = useAppSelector((state) =>
    successSelector([adminActions.getSalesforceAdmins], state));

  const handleSubmit = (values: FormikValues) => {
    dispatch(editSalesforceAdmins({ ...(values as ISalesforceAdmin) }));
  };

  const deleteEntry = (adminPharmaPlanId: string) =>
    () => {
      dispatch(deleteSalesforceAdmins(adminPharmaPlanId));
    };

  const deleteConfirmation = (adminPharmaPlanId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <ConfirmationModal
              confirmAction={deleteEntry(adminPharmaPlanId)}
              type={ConfirmationTypes.deleteEntry}
            />
          ),
          showCloseButton: false,
          heading: {
            title: '',
          },
        }),
      );
    };

  const table = {
    title: '',
    headerBar: [],
    headers: tableHeaderConfig(),
    rows: tableRowConfig(sfAdmins, dispatch, handleSubmit, deleteConfirmation),
  };

  useAdminConfigurationRefresh();

  return (
    <DynamicTable
      hidePagination
      loadSuccess={loadSuccess}
      noContainerStyling
      customContainerStyle={styles.tableContainer}
      showHeader={false}
      table={table}
      customHeader={<SalesforceTable />}
    />
  );
};

export default AdminConfiguration;
