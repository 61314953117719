import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { FormikValues } from 'formik';
import { Grid, Typography } from '@mui/material';

import {
  adminActions,
  adminGetIncompatibleMatches,
  adminGetIncompatibleMatchesPagination,
  adminUpdateIncompatibleMatches,
  getActivePharmacistListPagination,
  getPharmacyDetails,
  ITEMS_PER_PAGE,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import strings from '../../../../localization';
import { DeactivateModalIcon, ListType } from '../../../../helpers/Constants';
import DisplayGrid from '../../../common/DisplayGrid';
import TransferList from '../../../common/TransferList';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import CustomButton from '../../../common/CustomButton';
import { setDialog } from '../../../../reducers/dialogReducer';
import DeactivateDialog from '../../PharmacistList/DeactivateDialog';
import useUpdateIncompatibleMatch from '../../../../hooks/Admin/IncompatibleMatch/useUpdateIncompatibleMatch';

import IncompatiblePharmacistSearch from './IncompatiblePharmacistSearch';
import styles from './styles';

const UpdateIncompatibleMatches = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState({ left: 1, right: 1 });

  const { state } = useLocation();
  const { pharmacyId, pharmacyEmail } = state ?? {};

  const { displayGrid, left, right, pharmacistLists, list, setList } = useUpdateIncompatibleMatch(pharmacyId, pharmacyEmail);

  const observerCallback = useCallback(
    (type: ListType) =>
      (entries: { isIntersecting: any }[]) => {
        const data = type === ListType.left ? pharmacistLists.data : right;
        const paginationCondition = data.length >= page[type] * ITEMS_PER_PAGE;
        if (paginationCondition && entries[0].isIntersecting) {
          setPage((prevPage) =>
            ({
              ...prevPage,
              left: prevPage[type] + 1,
            }));
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, left, right],
  );

  const { listEndRef: leftRef } = useInfiniteScroll({
    observerCallback: observerCallback(ListType.left),
  });
  const { listEndRef: rightRef } = useInfiniteScroll({
    observerCallback: observerCallback(ListType.right),
  });

  useEffect(() => {
    if (pharmacyId) {
      dispatch(getPharmacyDetails(pharmacyId ?? ''));
      dispatch(
        adminGetIncompatibleMatches({ pharmacyId, paging: { page: 1 } }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page.left !== 1) {
      dispatch(getActivePharmacistListPagination({ page: page.left }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.left]);

  useEffect(() => {
    if (page.right !== 1) {
      dispatch(
        adminGetIncompatibleMatchesPagination({
          pharmacyId,
          paging: { page: page.right },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.right]);

  const handleSubmit = (values: FormikValues) => {
    const { reason } = values;
    dispatch(
      adminUpdateIncompatibleMatches({
        pharmacyId,
        pharmacistIds: list.right.map((item) =>
          item.key),
        note: reason,
      }),
    );
  };

  const saveIncompatibleMatch = () => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            validator={null}
            heading={strings.saveIncompatibleMatch}
            id={pharmacyId}
            iconType={DeactivateModalIcon.success}
            onSubmit={handleSubmit}
            confirmationMessage={strings.areYouSureSaveIncompatible}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const isRightChanged = right.length === list.right.length
    && right.every((r) =>
      list.right.some((listR) =>
        listR.key === r.key));

  return (
    <Grid container sx={styles.container}>
      <Typography sx={styles.title}>
        {strings.updateIncompatibleMatchPharmacist}
      </Typography>
      <DisplayGrid config={displayGrid} title={strings.general} />
      <Box sx={styles.listContainer}>
        <TransferList
          leftOrnament={<IncompatiblePharmacistSearch setPage={setPage} />}
          leftListStyle={styles.leftList}
          list={list}
          setList={setList}
          left={left}
          right={right}
          leftRef={leftRef}
          rightRef={rightRef}
          leftDesc={strings.selectOnePharmacistToMove}
          successAction={adminActions.updateIncompatibleMatch}
          rightDesc={strings.incompatiblePharmacistDescription}
          rightTitle={`${strings.incompatiblePharmacist}s`}
          leftTitle={strings.allPharmacists}
        />
      </Box>
      <Grid container xs={11} sx={styles.buttonContainer}>
        <CustomButton
          label={strings.save}
          customButtonStyle={styles.button}
          onClick={saveIncompatibleMatch}
          disabled={isRightChanged}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateIncompatibleMatches;
