import React, { FC } from 'react';

import { FormikProps } from 'formik';

import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  adminDropdown,
  AdminListTabTypes,
  Constants,
} from '../../../../helpers/Constants';
import PendingTabs from '../../PendingTabs';
import strings from '../../../../localization';
import CustomTextField from '../../../common/CustomTextField';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import CustomDropdown from '../../../common/CustomDropdown';
import genericClasses from '../../../../theme/GenericClasses';
import CreateNew from '../../../common/CreateNew';

interface IAdminListHeader {
  selectedTab: AdminListTabTypes;
  handleTabClick: (tab: AdminListTabTypes) => void;
  formik: FormikProps<any>;
  handleReset: () => void;
}

const AdminListHeader: FC<IAdminListHeader> = ({
  selectedTab,
  handleReset,
  handleTabClick,
  formik,
}) => {
  const navigate = useNavigate();

  const goToCreate = () => {
    navigate(Constants.paths.admin.createNewAdmin);
  };

  const tabConfig = [
    {
      key: AdminListTabTypes.activeAdmins,
      isDefault: true,
      tabName: strings.activeAdmins,
      onClick: handleTabClick,
    },
    {
      key: AdminListTabTypes.inactiveAdmins,
      tabName: strings.inactiveAdmins,
      onClick: handleTabClick,
    },
  ];

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PendingTabs
        tabConfig={tabConfig}
        selectedTab={selectedTab}
        header={strings.administratorsList}
        EndAdornment={
          <CreateNew label={strings.createNew} onClick={goToCreate} />
        }
      />
      <Grid container lg={6} alignItems="center" xs={10} wrap="nowrap">
        <Grid item xs={6}>
          <CustomTextField
            label={strings.user}
            placeholder={strings.enterNameEmail}
            name="userText"
            id="userText"
            formik={formik}
          />
        </Grid>
        <Grid item xs={6} sx={genericClasses.filterMargin}>
          <CustomDropdown
            formik={formik}
            label={strings.userType}
            name="userType"
            menuItems={adminDropdown}
          />
        </Grid>
        <FilterSubmitButtons handleReset={handleReset} />
      </Grid>
    </Box>
  );
};

export default AdminListHeader;
