import { SlotInfo } from 'react-big-calendar';
import { DateTime } from 'luxon';

import {
  BookingState,
  getTypeOfUser,
  ICalendarEventType,
  PharmacistLegendVariant,
  PharmacyLegendVariant,
  RecurrenceActions,
  TypeOfUser,
} from '@pharmaplan/common';

import { ScreenTypes } from '../helpers/Constants';
import { renderScreen, setOpen } from '../actions/drawerActions';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import useSelfService from './useSelfService';
import { getEnableAddVacations } from '../selectors/calendarSelector';

interface IOpenDrawer {
  eventIds?: Array<string>;
  recurrenceType?: RecurrenceActions;
  recurrenceId?: string;
  type?: ICalendarEventType;
  bookingState?: BookingState;
}

const { pharmacy, pharmacist } = TypeOfUser;
const { availability, vacation } = PharmacistLegendVariant;
const { posted, booked, requested } = PharmacyLegendVariant;

const {
  workshiftSummary,
  updateWorkshift,
  updateAvailability,
  serviceWorkshiftDetails,
  addWorkshift,
  addAvailability,
  createVacation,
} = ScreenTypes;

const useDrawerNavigation = () => {
  const dispatch = useAppDispatch();
  const { isSelfService } = useSelfService();

  const userType = useAppSelector(getTypeOfUser);
  const isVacations = useAppSelector(getEnableAddVacations);

  const isPharmacy = userType === pharmacy;

  const handleWorkshiftScreen = {
    screenType: isSelfService ? workshiftSummary : updateWorkshift,
    screenNumber: isSelfService ? 1 : 2,
  };

  const { screenType, screenNumber } = handleWorkshiftScreen;

  const handleScreenTypes = (type: ICalendarEventType) => {
    switch (type) {
      case availability:
        return {
          screenType: updateAvailability,
          screenNumber: 1,
        };
      case posted:
        return {
          screenType,
          screenNumber,
        };
      case requested:
        return isPharmacy
          ? {
            screenType,
            screenNumber,
          }
          : {
            screenType: serviceWorkshiftDetails,
            screenNumber: 1,
          };
      case booked:
        return isPharmacy
          ? {
            screenType: workshiftSummary,
            screenNumber: 1,
          }
          : {
            screenType: serviceWorkshiftDetails,
            screenNumber: 1,
          };
      case vacation:
        return {
          screenType: createVacation,
          screenNumber: 1,
        };
      default:
        return {
          screenType: serviceWorkshiftDetails,
          screenNumber: 1,
        };
    }
  };

  const handleAddDrawerScreenType = {
    [pharmacy]: addWorkshift,
    [pharmacist]: isVacations ? createVacation : addAvailability,
  };

  const openAddDrawer = (slot: SlotInfo) => {
    dispatch(setOpen(true));

    dispatch(
      renderScreen({
        date:
          DateTime.fromJSDate(slot.start).toISO({
            includeOffset: false,
          }) ?? '',
        screenType:
          handleAddDrawerScreenType[
            userType as keyof typeof handleAddDrawerScreenType
          ],
        screenNumber: 1,
      }),
    );
  };

  const openAddDefaultDrawer = () => {
    dispatch(setOpen(true));

    dispatch(
      renderScreen({
        screenType:
          handleAddDrawerScreenType[
            userType as keyof typeof handleAddDrawerScreenType
          ],
        screenNumber: 1,
      }),
    );
  };

  const openDrawer = ({
    eventIds,
    recurrenceType,
    recurrenceId,
    type,
    bookingState,
  }: IOpenDrawer) => {
    dispatch(
      renderScreen({
        recType: recurrenceType,
        recurrenceId,
        type,
        eventIds,
        bookingState,
        ...handleScreenTypes(type ?? PharmacistLegendVariant.workshift),
      }),
    );
    dispatch(setOpen(true));
  };

  return { openDrawer, openAddDrawer, openAddDefaultDrawer };
};

export default useDrawerNavigation;
