import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import strings from '../../../../localization';

import classes from './styles';

interface IDisplayRecurrence {
  recurrenceRepeat: string;
}

const { chipContainer, recurrenceTitle, recurrenceValue } = classes;

const DisplayRecurrence: FC<IDisplayRecurrence> = ({ recurrenceRepeat }) => {
  const recurrences = `${strings.recurrences}:`;

  return (
    <Box sx={chipContainer}>
      <Typography sx={recurrenceTitle}>{recurrences}</Typography>

      <Typography sx={recurrenceValue}>{recurrenceRepeat}</Typography>
    </Box>
  );
};

export default DisplayRecurrence;
