import React, { FC, useCallback } from 'react';

import { Stack } from '@mui/system';
import { Typography } from '@mui/material';

import { ITEMS_PER_PAGE, successSelector } from '@pharmaplan/common';

import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';

import styles from './styles';
import genericClasses from '../../../theme/GenericClasses';
import NoAvailabilities from '../MatchingAvailabilities/NoAvailabilities';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';

interface IAdminReplacementHistory {
  title: string;
  successAction: string;
  table: IDynamicTableObject;
  headerComponent: React.ReactNode;
  data: any;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const AdminReplacementList: FC<IAdminReplacementHistory> = ({
  title,
  successAction,
  table,
  headerComponent,
  data,
  page,
  setPage,
}) => {
  const loadSuccess = useAppSelector((state) =>
    successSelector([successAction], state));

  const emptyContainerComponent = data.length === 0 ? (
    <NoAvailabilities message={strings.noDataAvailable} />
  ) : null;

  const observerCallback = useCallback(
    (entries: { isIntersecting: any }[]) => {
      const paginationCondition = data.length >= page * ITEMS_PER_PAGE;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, data],
  );

  const { listEndRef } = useInfiniteScroll({ observerCallback });

  return (
    <Stack sx={styles.container}>
      {headerComponent}
      <DynamicTable
        showHeader={false}
        customHeader={<Typography sx={styles.title}>{title}</Typography>}
        noContainerStyling
        loadSuccess={loadSuccess}
        table={table}
        hidePagination
        customTableHeadStyle={genericClasses.drawerTableHead}
        emptyContainerComponent={emptyContainerComponent}
      />
      {loadSuccess && (
        <div ref={listEndRef} style={genericClasses.paginationDiv} />
      )}
    </Stack>
  );
};

export default AdminReplacementList;
