import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { SxProps, SystemStyleObject } from '@mui/system';
import { FormikProps, FormikValues } from 'formik';
import React from 'react';
import { Constants } from '../../../helpers/Constants';
import genericClasses from '../../../theme/GenericClasses';
import classes from './style';

interface RadioButtonItem {
  label: string;
  value: string;
}

interface IRadioButtons {
  name: string;
  radioItems: Array<RadioButtonItem>;
  formik: FormikProps<FormikValues>;
  radioLabel?: string;
  row?: boolean;
  customClass?: SxProps;
}

const defaultProps = {
  row: true,
  customClass: {},
  radioLabel: Constants.null,
};

const CustomRadioButton = ({
  name,
  radioItems,
  radioLabel,
  row,
  formik,
  customClass,
}: IRadioButtons) => {
  const { formLabel, radioLabelText } = classes;
  const { hidden } = genericClasses;
  const { values, setFieldValue } = formik ?? {};

  return (
    <FormControl sx={customClass as SystemStyleObject}>
      <FormLabel sx={[radioLabel ? {} : hidden, formLabel]}>
        {radioLabel}
      </FormLabel>

      <RadioGroup
        row={row}
        name={name}
        value={values[name]}
        onChange={(e) =>
          setFieldValue(name, e.target.value)}
      >
        {radioItems.map((item) => {
          const { label, value } = item;
          return (
            <FormControlLabel
              sx={radioLabelText}
              key={value}
              value={value}
              control={<Radio />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

CustomRadioButton.defaultProps = defaultProps;

export default CustomRadioButton;
