import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  container: {
    flex: 1,
    flexDirection: 'column',

  },
  profileFormContainer: {
    padding: '18px',
  },
  alignleft: {
    display: 'flex !important',
    justifyContent: 'left !important',
  },
  label: {
    padding: 0,
  },
  deleteButton: {
    color: ThemeConstants.common.counterRed,
    display: 'flex !important',
    margin: 0,
    justifyContent: 'left !important',
    '&:hover': {
      backgroundColor: 'rgba(235, 72, 105, 0.04)',
    },
  },
  backContainer: {
    width: '91px',
    justifyContent: 'space-between',
  },
  customModalHeading: {
    color: 'primary.main',
    fontSize: '1.625rem',
    fontWeight: '600',
  },
  customTitleContainer: {
    padding: '20px 40px 22px 40px',
    marginBottom: '0px',
    borderBottom: '2px solid #E1E4EF',
  },
  mapButton: {
    justifyContent: 'start',
    textTransform: 'none',
    width: '97.5%',

    '& .MuiButton-endIcon': {
      marginLeft: 'auto',
    },
  },
  mapContainerSignUpFlow: {
    paddingInline: '40px',
  },
  selectContainer: {
    position: 'absolute',
    top: '16px',
    left: '56px',
    zIndex: 1,
    width: '260px',
  },
  footerContainer: {
    borderTop: `2px solid ${ThemeConstants.common.border}`,
    justifyContent: 'end',
    paddingBlock: '24px',
  },
  mapButtonHelperTextStyle: {
    color: '#d32f2f',
    fontSize: '0.75rem',
    marginTop: '3px',
    marginLeft: '14px',
  },

};

export default classes;
