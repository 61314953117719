import { SxProps, Theme } from '@mui/system';

const styles = {
  buttonContainer: {
    display: 'flex',
    marginLeft: '8px',
    marginTop: '4px',
  },
  buttons: {
    maxHeight: 36,
    minHeight: 36,
    maxWidth: 36,
    minWidth: 36,
    marginLeft: '16px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
