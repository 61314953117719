import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const size = 40;
const dotSize = 15;

const styles = {
  channelItemContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: ThemeConstants.common.lightBackgroundColor,
    borderBottom: '1px solid',
    borderColor: ThemeConstants.common.border,
    cursor: 'pointer',
  },
  avatar: {
    borderRadius: size / 2,
    height: size,
    width: size,
    margin: 5,
    objectFit: 'contain',
    border: '1px solid',
    borderColor: '#E1E4EF',
  },
  channelInfoContainer: {
    flex: 4,
    m: 1,
  },
  selectedChannel: {
    borderLeft: '5px solid',
    borderLeftColor: 'primary.main',
  },
  channelTitle: {
    fontSize: 16,
  },
  engaged: {
    backgroundColor: 'common.error',
  },
  available: {
    backgroundColor: 'pharmacist.availability',
  },
  assignedToMe: {
    backgroundColor: 'primary.main',
  },
  away: {
    backgroundColor: 'chatColors.orange500',
  },
  dot: {
    position: 'absolute',
    bottom: 9,
    right: 0,
    height: dotSize,
    width: dotSize,
    borderRadius: dotSize / 2,
  },
  lastMessage: {
    color: 'common.grey350',
    fontSize: 13,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    lineClamp: '2',
    '-webkit-box-orient': 'vertical',
  },
  statusContainer: {
    position: 'relative',
  },

  count: {
    display: 'flex',
    backgroundColor: 'common.error',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 16,
    maxWidth: 16,
    borderRadius: 8,
    fontSize: 11,
    fontWeight: '500',
    flex: 1,
    mr: 2,
    p: 0.25,
    color: 'secondary.main',
  },
  shakingCount: {
    '@keyframes shaking': {
      '0%': { scale: '1' },
      '15%': { scale: '1.04' },
      '30%': { scale: '1.08' },
      '40%': { scale: '1.12' },
      '50%': { scale: '1.08' },
      '60%': { scale: '1.04' },
      '100%': { scale: '1.00' },
    },
    animation: 'shaking 1s infinite linear',
  },
  hideUser: {
    display: 'none',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
