const size = 36;
const classes = {
  marker: (variant:string) =>
    ({
      height: size,
      backgroundColor: `pharmacist.${variant}`,
      width: size,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: size / 2,
    }),
  markerText: {
    color: 'white',

  },
};

export default classes;
