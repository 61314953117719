import { Grid, Slider, SxProps } from '@mui/material';
import React from 'react';
import classes from './style';

interface IMarks {
  value: number;
  label: string
}

interface ICustomSlider {
  label: string;
  value: number;
  onChange?: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void;

  marks?: Array<IMarks>;
  disabled?: boolean;
  color?: 'primary' | 'secondary';
  sx?: SxProps;
}

const defaultProps = {
  disabled: false,
  marks: [],
  color: 'primary',
  sx: null,
};

const CustomSlider = ({
  label,
  value,
  onChange,
  marks,
  color,
  disabled,
  sx,
}: ICustomSlider) =>
  (
    <Grid sx={classes.customSliderContainer} container>
      <Grid item xs={3}>
        {label}
      </Grid>
      <Grid item xs={9}>
        <Slider
          sx={sx}
          color={color}
          disabled={disabled}
          onChange={onChange}
          value={value}
          step={1}
          marks={marks}
          min={1}
          max={5}
        />
      </Grid>
    </Grid>
  );

CustomSlider.defaultProps = defaultProps;
export default CustomSlider;
