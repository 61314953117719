import { SxProps, Theme } from '@mui/system';

const styles = {
  buttonContainer: {
    marginX: '2px',
    minWidth: 10,
  },
  active: {
    backgroundColor: 'primary.main',
    color: 'secondary.main',
    '&:hover': {
      backgroundColor: 'common.secondaryButtonHover',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
