import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    pl: 1,
    mb: 2,
    paddingTop: '15px',
    backgroundColor: 'common.lightBackgroundColor',
  },
  submitButtonContainer: {
    display: 'flex',
    alignItems: 'center',

  },
  submitButton: {
    marginBottom: '1px',
    marginRight: '5px',
    marginLeft: '5px',
    // ALIGNMENT WITH FILTER DROPDOWNS
    marginTop: '5px',
    maxWidth: '33px',
    maxHeight: '34px',
    minWidth: '33px',
    minHeight: '34px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
