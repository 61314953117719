import { SxProps, Theme } from '@mui/system';

const styles = {
  emptyContainer: {
    height: '60vh',
    backgroundColor: 'secondary.main',

  },
  emptyPrompt: {
    color: 'footer.verticalLine',
    fontSize: '16px',
    fontWeight: '600',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
