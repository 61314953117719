import {
  IAdminConfiguration,
  ServiceTypes,
  convertToDecimals,
} from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import {
  Constants,
  telephoneInputMask,
  TextFieldVariants,
} from '../../../../helpers/Constants';
import strings from '../../../../localization';
import { IInputModalConfig } from '../../../Modals/InputModal/InputModal';
import styles from './styles';
import genericClasses from '../../../../theme/GenericClasses';

export const handleChip = (value: boolean) =>
  ({
    chipStyle: value ? styles.bgPositive : styles.bgNegative,
    value: value ? strings.yes : strings.no,
  });

export const settingsInfoConfig = (data: IAdminConfiguration) => {
  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

  const {
    ratePharmacistDays,
    minHourWorkshift,
    pharmacistRate,
    pharmacyRate,
    representativeName,
    representativeEmail,
    representativePhone,
    representativePhoneExt,
    salesForceActive,
    salesForcePassword,
    salesForceSecurityToken,
    salesForceURL,
    salesForceUserName,
    envoiceBCCAdmin,
    technicianRate,
    showBookingDetails,
    noCancellationPeriod,
    bookingStateExpiration,
    pharmaPlanPercentFees,
    cancellationPercentFees,
    freeCancellationPeriod,
    reminder1After,
    reminder2Before,
    enableChat,
  } = data ?? {};

  const title = isSelfService ? strings.pharmagap : strings.pharmaPlan;

  const commonContent = [
    {
      key: 0,
      label: strings.noOfDaysToRatePharmacist,
      value: ratePharmacistDays,
    },
    {
      key: 1,
      label: strings.minHoursWorkshift,
      value: minHourWorkshift,
    },
    {
      key: 2,
      label: strings.formatString(
        strings.defaultHourlyRate,
        strings.pharmacist,
      ) as string,
      value: `$${convertToDecimals(pharmacistRate)}`,
    },
    {
      key: 3,
      label: strings.formatString(
        strings.defaultHourlyRate,
        strings.pharmacy,
      ) as string,
      value: `$${convertToDecimals(pharmacyRate)}`,
    },
    {
      key: 4,
      label: strings.formatString(
        strings.defaultHourlyRate,
        `${strings.tech}.`,
      ) as string,
      value: `$${convertToDecimals(technicianRate)}`,
    },
  ];

  const contentConfig = isSelfService
    ? [
      ...commonContent,
      {
        key: 5,
        label: strings.noCancellationPeriod,
        value: `${noCancellationPeriod} ${strings.days}`,
      },
      {
        key: 6,
        label: strings.requestPendingPeriod,
        value: `${bookingStateExpiration} ${strings.hrs}`,
      },
      {
        key: 7,
        label: strings.pharmaPlanFees,
        value: `${pharmaPlanPercentFees}%`,
      },
      {
        key: 8,
        label: strings.cancellationFees,
        value: `${cancellationPercentFees}%`,
      },
      {
        key: 9,
        label: strings.freeCancellationPeriod,
        value: `${freeCancellationPeriod} ${strings.hrs}`,
      },
      {
        key: 10,
        label: strings.pharmacistReminderPost,
        value: `${reminder1After} ${strings.hrs}`,
      },
      {
        key: 11,
        label: strings.pharmacistReminderPre,
        value: `${reminder2Before} ${strings.hrs}`,
      },
    ]
    : [...commonContent];

  return [
    {
      key: 0,
      title: strings.formatString(strings.customSettings, title) as string,
      content: contentConfig,
    },
    {
      key: 1,
      title: strings.formatString(
        strings.customSettings,
        strings.customerServiceRepresentative,
      ) as string,
      content: [
        {
          key: 0,
          label: strings.representativeName,
          value: representativeName,
        },
        {
          key: 1,
          label: strings.representativeEmail,
          value: representativeEmail,
        },
        {
          key: 2,
          label: strings.representativePhone,
          value: representativePhone,
        },
        {
          key: 3,
          label: strings.representativeExtPhone,
          value: representativePhoneExt,
        },
      ],
    },
    {
      key: 2,
      title: strings.formatString(
        strings.customSettings,
        strings.salesforce,
      ) as string,
      content: [
        {
          key: 0,
          label: strings.salesForceUsername,
          value: salesForceUserName,
        },
        {
          key: 1,
          label: strings.salesForcePWD,
          value: salesForcePassword,
          maskText: true,
        },
        {
          key: 2,
          label: strings.salesForceSecurityToken,
          value: salesForceSecurityToken,
          maskText: true,
        },
        {
          key: 3,
          label: strings.urlOfSalesForce,
          value: salesForceURL,
        },
        {
          key: 4,
          label: strings.integrationSalesForce,
          displayChip: true,
          ...handleChip(salesForceActive),
        },
      ],
    },
    {
      key: 2,
      title: strings.formatString(
        strings.customSettings,
        strings.more,
      ) as string,
      content: [
        {
          key: 0,
          label: strings.sendAllEmailsBCC,
          displayChip: true,
          ...handleChip(envoiceBCCAdmin),
        },
        {
          key: 1,
          label: strings.showAllBookingDetails,
          displayChip: true,
          ...handleChip(showBookingDetails),
        },
        {
          key: 2,
          label: strings.enableChat,
          displayChip: true,
          ...handleChip(enableChat),
        },
      ],
    },
  ];
};

export const createNewForm: IInputModalConfig[] = [
  {
    key: '0',
    name: 'name',
    type: Constants.formInputType.text,
    variant: TextFieldVariants.filled,
    customTextFieldStyle: genericClasses.customFilledInput,
    gridLabel: strings.name,
    fieldXs: 8,
    labelXs: 4,
  },
  {
    key: '1',
    name: 'email',
    type: Constants.formInputType.text,
    variant: TextFieldVariants.filled,
    customTextFieldStyle: genericClasses.customFilledInput,
    gridLabel: strings.email,
    fieldXs: 8,
    labelXs: 4,
  },
  {
    key: '2',
    name: 'phone',
    gridLabel: strings.phone,
    variant: TextFieldVariants.filled,
    customTextFieldStyle: genericClasses.customFilledInput,
    fieldXs: 8,
    labelXs: 4,
    type: Constants.formInputType.masked,
    mask: telephoneInputMask,
    maxLength: 12,
  },
  {
    key: '3',
    name: 'received',
    type: Constants.formInputType.checkbox,
    gridLabel: strings.active,
    fieldXs: 8,
    labelXs: 4,
    customClass: genericClasses.checkboxContainer,
    customCheckboxStyle: genericClasses.checkboxStyle,
    gridLabelClass: styles.gridLabel,
  },
];
