import { useEffect } from 'react';

import { FormikProps } from 'formik';

import {
  RecurrenceActions,
  ServiceTypes,
  calendarDate,
  deleteWorkshift,
  getPharmacyCalendar,
  getPharmacyWorkshift,
  getRecurrence,
  pharmacyActions,
  pharmacyWorkshift,
  reset,
  resetStatus,
  setRecurrence,
  successSelector,
} from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';

import { selectFooterPaginationCurrent } from '../../selectors/drawerSelector';
import {
  resetDrawer,
  setButtons,
  setPagination,
} from '../../actions/drawerActions';
import strings from '../../localization';
import { setRecurrenceProps } from '../../helpers/workshift/functions';

import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import useSelfService from '../useSelfService';

const { series } = RecurrenceActions;

const useUpdateWorkshift = (
  eventIds: Array<string>,
  formik: FormikProps<any>,
  recurrenceId?: string,
  recType?: RecurrenceActions,
) => {
  const dispatch = useAppDispatch();
  const { isSelfService } = useSelfService();

  const current = useAppSelector(selectFooterPaginationCurrent);
  const currentWorkshift = useAppSelector(pharmacyWorkshift);
  const recurrence = useAppSelector(getRecurrence);
  const cDate = useAppSelector(calendarDate);

  const serviceType = ServiceHandler.getService();

  const updateSuccess = useAppSelector((state) =>
    successSelector([pharmacyActions.updateWorkshift], state));
  const deleteSuccess = useAppSelector((state) =>
    successSelector([pharmacyActions.deleteWorkshift], state));

  const id = eventIds[current - 1];
  const isSeries = recType === series;
  const { handleSubmit } = formik ?? {};

  const { recurrenceId: currentRecurrenceId, endDate } = currentWorkshift ?? {};

  const handleReset = () => {
    dispatch(reset());
    dispatch(resetDrawer());
  };

  const serviceConfig = {
    [ServiceTypes.full]: {
      secondaryLabel: strings.deleteWorkshift,
      secondaryAction: () => {
        dispatch(
          deleteWorkshift({
            id,
            recurrenceId: isSeries ? recurrenceId : '',
          }),
        );
      },
    },
    [ServiceTypes.self]: {
      secondaryLabel: strings.discardChanges,
      secondaryAction: handleReset,
    },
  };

  const { secondaryAction, secondaryLabel } = serviceConfig[serviceType];

  const handlePagination = () => {
    if (isSelfService) {
      dispatch(
        setPagination({ show: false, current: 1, total: eventIds.length }),
      );
      return;
    }

    dispatch(
      setPagination({
        show: true,
        total: eventIds.length,
        current: 1,
      }),
    );
  };

  useEffect(() => {
    handlePagination();
    dispatch(
      setButtons({
        show: true,
        primaryButton: {
          label: strings.saveChanges,
          onClick: handleSubmit,
        },
        secondaryButton: {
          label: secondaryLabel,
          variant: 'text',
          onClick: secondaryAction,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentRecurrenceId && isSeries
      ? setRecurrenceProps(dispatch, recurrence, currentWorkshift)
      : dispatch(
        setRecurrence({
          ...recurrence,
          endDate,
          repeatEvery: 0,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkshift]);

  useEffect(() => {
    if (updateSuccess || deleteSuccess) {
      dispatch(getPharmacyCalendar(cDate));
      handleReset();
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, deleteSuccess]);

  useEffect(() => {
    if (!isSelfService) {
      dispatch(getPharmacyWorkshift(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
};

export default useUpdateWorkshift;
