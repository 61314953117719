import React from 'react';

import { CheckboxProps, Checkbox as MuiCheckbox } from '@mui/material';

import { ReactComponent as CheckboxIcon } from '../../../assets/svg/checkbox.svg';
import { ReactComponent as CheckboxTick } from '../../../assets/svg/checkboxTick.svg';
import { ReactComponent as DisabledCheckbox } from '../../../assets/svg/disabledCheckbox.svg';
import { ReactComponent as DisabledCheckboxTick } from '../../../assets/svg/disabledCheckboxTick.svg';

const Checkbox = (props: CheckboxProps) => {
  const { disabled } = props;

  return (
    <MuiCheckbox
      checkedIcon={disabled ? <DisabledCheckboxTick /> : <CheckboxTick />}
      icon={disabled ? <DisabledCheckbox /> : <CheckboxIcon />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default Checkbox;
