const classes = {
  footerPaper: {

  },

  linkText: {
    paddingRight: 2,
  },

  footerText: {
    fontWeight: 500,
    fontSize: 12,
  },

  copyRightContainer: {
    display: 'flex',
    margin: '10px',
    alignItems: 'center',

  },

  copyRightText: {
    fontWeight: 500,
    fontSize: 12,
    padding: '0 2px',
  },

  legalContainer: {
    display: 'flex',
    margin: { xs: 0, md: '10px' },
    alignItems: 'center',
  },

  verticalLine: {
    height: '14px',
    width: '2px',
    backgroundColor: 'black',
  },

  verticleLineContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },

  container: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'footer.backgroundColor',
    flexDirection: 'row',
    justifyContent: { xs: 'center', md: 'space-around' },
  },

};

export default classes;
