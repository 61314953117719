import { useLocation } from 'react-router-dom';
import {
  adminActions,
  getActivitiesLogs,
  getAvailabilitiesLogs,
  getBookingLogs,
  getWorkshiftLogs,
} from '@pharmaplan/common/actions/adminActions';
import {
  adminActivityLogs,
  adminAvailabilitiesLogs,
  adminBookingLogs,
  adminWorkshiftLogs,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import {
  Constants,
  SecondaryAdminNavigation,
} from '../../../helpers/Constants';
import strings from '../../../localization';
import {
  activityLogsHeader,
  activityLogsRow,
  availabilityLogsHeader,
  availabilityLogsRow,
  bookingLogsHeader,
  bookingLogsRow,
  workshiftLogsHeader,
  workshiftLogsRow,
} from '../../../components/Admin/Logs/helper';
import { useAppSelector } from '../../useAppSelector';
import useUserProfileLink from '../useUserProfileLink';

const { activityLogs, workshiftLogs, availabilityLogs, bookingLogs } = Constants.paths.admin;

const useLogsHandler = () => {
  const { pathname } = useLocation();
  const { goToUserProfile, goToPerformerProfile } = useUserProfileLink();

  const wLogs = useAppSelector(adminWorkshiftLogs);
  const avLogs = useAppSelector(adminAvailabilitiesLogs);
  const aLogs = useAppSelector(adminActivityLogs);
  const bLogs = useAppSelector(adminBookingLogs);
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const { data: wLogsData, totalCount: wTotalCount } = wLogs ?? {};
  const { data: avLogsData, totalCount: avTotalCount } = avLogs ?? {};
  const { data: aLogsData, totalCount: aTotalCount } = aLogs ?? {};
  const { data: bLogsData, totalCount: bTotalCount } = bLogs ?? {};

  const logsType = {
    [activityLogs]: SecondaryAdminNavigation.activityLogs,
    [workshiftLogs]: SecondaryAdminNavigation.workshiftLogs,
    [availabilityLogs]: SecondaryAdminNavigation.availabilityLogs,
    [bookingLogs]: SecondaryAdminNavigation.bookingLogs,
  };

  const titleConfig = {
    [activityLogs]: strings.activityLogs,
    [workshiftLogs]: strings.workshiftLogs,
    [availabilityLogs]: strings.availabilityLogs,
    [bookingLogs]: strings.bookingLogs,
  };

  const headersConfig = {
    [activityLogs]: activityLogsHeader(),
    [workshiftLogs]: workshiftLogsHeader(),
    [availabilityLogs]: availabilityLogsHeader(),
    [bookingLogs]: bookingLogsHeader(),
  };

  const rowsConfig = {
    [activityLogs]: activityLogsRow({ data: aLogsData, timeFormat, goToUserProfile, goToPerformerProfile }),
    [workshiftLogs]: workshiftLogsRow({ data: wLogsData, timeFormat, goToUserProfile, goToPerformerProfile }),
    [availabilityLogs]: availabilityLogsRow({ data: avLogsData, timeFormat, goToUserProfile, goToPerformerProfile }),
    [bookingLogs]: bookingLogsRow({ data: bLogsData, timeFormat, goToUserProfile, goToPerformerProfile }),
  };

  const apiConfig = {
    [activityLogs]: getActivitiesLogs,
    [workshiftLogs]: getWorkshiftLogs,
    [availabilityLogs]: getAvailabilitiesLogs,
    [bookingLogs]: getBookingLogs,
  };

  const dataConfig = {
    [activityLogs]: aLogsData,
    [workshiftLogs]: wLogsData,
    [availabilityLogs]: avLogsData,
    [bookingLogs]: bLogsData,
  };

  const totalCountConfig = {
    [activityLogs]: aTotalCount,
    [workshiftLogs]: wTotalCount,
    [availabilityLogs]: avTotalCount,
    [bookingLogs]: bTotalCount,
  };

  const loadConfig = {
    [activityLogs]: adminActions.getActivitiesLogs,
    [workshiftLogs]: adminActions.getWorkshiftLogs,
    [availabilityLogs]: adminActions.getAvailabilitiesLogs,
    [bookingLogs]: adminActions.getBookingLogs,
  };

  return {
    title: titleConfig[pathname],
    logsType: logsType[pathname],
    headers: headersConfig[pathname],
    rows: rowsConfig[pathname],
    data: dataConfig[pathname],
    api: apiConfig[pathname],
    totalCount: totalCountConfig[pathname],
    success: loadConfig[pathname],
  };
};

export default useLogsHandler;
