import React, { FC, useEffect, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';

import {
  selectFooterPagination,
  selectFooterPaginationCurrent,
} from '../../../selectors/drawerSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { setCurrent } from '../../../actions/drawerActions';
import { useAppSelector } from '../../../hooks/useAppSelector';

import classes from './style';

const { container, pageCount } = classes ?? {};

const DrawerPagination: FC = () => {
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(selectFooterPagination);

  const current = useAppSelector(selectFooterPaginationCurrent);

  const [currentPage, setCurrentPage] = useState<number>(current);

  const { total } = pagination ?? {};

  const isOne = current === 1;
  const isMax = current === total;

  const next = () => {
    if (currentPage < total) {
      setCurrentPage((prev) =>
        prev + 1);
    }
  };

  const back = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) =>
        prev - 1);
    }
  };

  const getIconColor = (isDisabled: boolean) =>
    (isDisabled ? 'disabled' : 'primary');

  useEffect(() => {
    setCurrentPage(current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    dispatch(setCurrent(currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <Box component="div" sx={container}>
      <IconButton onClick={back} disabled={isOne}>
        <NavigateBefore color={getIconColor(isOne)} />
      </IconButton>

      <Box component="span" sx={pageCount}>
        {`${currentPage} / ${total}`}
      </Box>

      <IconButton onClick={next} disabled={isMax}>
        <NavigateNext color={getIconColor(isMax)} />
      </IconButton>
    </Box>
  );
};

export default DrawerPagination;
