import { useEffect, useRef, useState } from 'react';

import genericClasses from '../theme/GenericClasses';

interface IUseTextField {
  value: string | number;
  usePreFilledColors?: boolean;
}

const useTextField = ({ value, usePreFilledColors }: IUseTextField) => {
  const isMounted = useRef(false);

  const isPreFilled = usePreFilledColors && !!value;

  const [style, setStyle] = useState(
    isPreFilled ? genericClasses.preExistingText : genericClasses.editedText,
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    setStyle(genericClasses.editedText);
  }, [value]);

  return { style };
};

export default useTextField;
