import { Box } from '@mui/material';
import React, { memo } from 'react';
import { DateHeaderProps } from 'react-big-calendar';
import { stripLeadingZeros } from '../../../../helpers/Functions';
import classes from './style';

const DayCell = ({ label }: DateHeaderProps) =>
  <Box sx={classes.dayCell}>{stripLeadingZeros(label)}</Box>;

export default memo(DayCell);
