import { SxProps, Theme } from '@mui/system';

const styles = {
  icon: {
    color: 'primary.main',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  rating: {
    fontSize: 22,
    fontWeight: 600,
    color: 'grey.800',
  },

  totalRating: {
    fontSize: 14,
    color: 'grey.500',
    marginRight: '8px',
  },

  avgRating: {
    fontSize: 16,
    fontWeight: 500,
    color: 'primary.main',
    marginRight: '3px',
  },

  reviews: {
    color: 'grey.500',
    fontSize: 12,
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  locationIcon: {
    marginRight: '5px',
  },

  cityText: {
    fontSize: 14,
    fontWeight: 400,
    color: 'common.grey850',
  },

  contactText: {
    fontSize: 14,
    fontWeight: 500,
    color: 'common.grey850',
  },

  divider: {
    marginX: '32px',
    border: '1px solid',
    borderColor: 'common.border',
    height: 40,
  },

  pharmacyNameText: {
    fontSize: 20,
    fontWeight: 600,
    color: 'common.grey850',
  },

  ratingContainer: {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    border: '1px solid',
    borderColor: 'common.border',
    padding: '32px',
    borderLeft: '5px solid',
    borderLeftColor: 'primary.main',
  },

  pharmacistContainer: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: '1px solid',
    borderColor: 'common.border',
    paddingX: '24px',
    paddingY: '16px',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
