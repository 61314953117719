import React from 'react';
import { totalRatingValue } from '@pharmaplan/common';

import StarIcon from '@mui/icons-material/Star';
import { Box, Typography } from '@mui/material';
import styles from './style';

interface IInfoConfig {
  name: string;
  code: string;
  rating?: number | string;
  date: string;
}

const ratingText = `/${totalRatingValue}`;

export const infoConfig = ({ name, code, rating, date }: IInfoConfig) =>
  [
    {
      key: 0,
      text: name,
      sx: styles.name,
    },
    {
      key: 1,
      text: code,
      sx: styles.code,
    },
    {
      key: 2,
      customComponent: (
        <Box sx={styles.rateContainer}>
          <StarIcon sx={styles.icon} fontSize="small" />
          <Typography sx={styles.rating}>{rating}</Typography>
          <Typography sx={styles.totalRating}>{ratingText}</Typography>
        </Box>
      ),
    },
    {
      key: 3,
      text: date,
      sx: styles.date,
    },
  ];
