import { SxProps, Theme } from '@mui/system';

const classes = {
  leftContainer: {
    display: {
      xs: 'none',
      md: 'none',
      lg: 'center',
    },
    justifyContent: 'center',
    flexDirection: 'column',
  },

  loginCard: {
    padding: 6,
    minWidth: '420px',
    maxWidth: '420px',
    borderRadius: '12px',
    width: '100%',
    position: 'relative',
  },

  outerContainer: {
    display: 'flex',
    borderRadius: '12px',
    width: '100%',
    position: 'relative',
    marginTop: 10,
  },

  signupMargin: {
    marginBottom: 3,
  },

  signUpText: {
    fontSize: 36,
    fontWeight: 600,
  },

  loginCardContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    height: '100%',
  },

  loginContainer: {
    marginBottom: 3,
  },

  socialContainer: {
    marginTop: 3,
  },

  loginImageContainer: {
    marginBottom: 1,
    zIndex: 10,
  },

  loginImageOne: {
    position: 'absolute',
    bottom: -10,
    left: -50,
    zIndex: 100,
  },

  loginImageTwo: {
    position: 'absolute',
    bottom: -25,
    right: -110,
    zIndex: 100,
  },

  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: { xs: 'center', lg: 'center' },
    paddingLeft: { xs: 0 },
    paddingRight: { xs: 0 },
    minHeight: 'calc(100vh - 45px)',
  },

  responsive: {
    display: { xs: 'none', md: 'flex' },
  },

  mainContainer: {
    width: '100%',
  },

  rememberMeSignup: {
    display: 'flex',
    justifyContent: 'center',
    pb: 10,
  },

  rememberMeNoAccount: {
    mr: 1,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
