const classes = {
  container: {
    p: 2,
  },
  title: {
    marginY: 2,
    fontWeight: '600',
    fontSize: 18,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttons: {
    marginLeft: 1,
    marginTop: 2,
  },
};

export default classes;
