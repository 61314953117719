import React, { useCallback } from 'react';

import { FormikValues } from 'formik';

import {
  adminDeleteAvailability,
  AvailabilityTypes,
  deleteAdminPharmacistVacations,
  ICalendarEventType,
  multipleAvails,
  PharmacistLegendVariant,
} from '@pharmaplan/common';
import { setMultipleAvailabilities } from '@pharmaplan/common/actions/calendarActions';

import DeactivateDialog from '../components/Admin/PharmacistList/DeactivateDialog';
import { getMultipleEventAdd } from '../selectors/calendarSelector';
import { DeactivateModalIcon } from '../helpers/Constants';
import { setDialog } from '../reducers/dialogReducer';
import strings from '../localization';

import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import useAdmin from './useAdmin';

const { availability, vacation } = PharmacistLegendVariant;
const { tempAvailability } = AvailabilityTypes;

interface IHandleMultipleClick {
  type: ICalendarEventType | AvailabilityTypes;
  id?: string;
}

const useMultipleDelete = () => {
  const dispatch = useAppDispatch();

  const multipleAvailabilities = useAppSelector(multipleAvails);
  const isMultipleAdd = useAppSelector(getMultipleEventAdd);

  const { isAdmin } = useAdmin();

  const handleDeleteClick = (id?: string) => {
    const arr = multipleAvailabilities.map((item) => {
      if (item.id === id) {
        return { ...item, isDeleted: !item.isDeleted };
      }
      return { ...item };
    });

    dispatch(setMultipleAvailabilities(arr));
  };

  const handleReset = () => {
    const arr = multipleAvailabilities.map((item) =>
      ({
        ...item,
        isDeleted: false,
      }));

    dispatch(setMultipleAvailabilities(arr));
  };

  const getDeletedAvailabilities = useCallback(
    () =>
      multipleAvailabilities
        .filter((item) =>
          item.isDeleted && item.type === availability)
        .map((item) =>
          item.availableTimeId),
    [multipleAvailabilities],
  );

  const getDeletedVacations = useCallback(
    () =>
      multipleAvailabilities
        .filter((item) =>
          item.isDeleted && item.type === vacation)
        .map((item) =>
          item.id),
    [multipleAvailabilities],
  );

  const deleteSelectedAvailabilities = (values: FormikValues) => {
    const { notes } = values;
    const deletedAvails = getDeletedAvailabilities();
    const deleteVacations = getDeletedVacations();

    if (deleteVacations.length !== 0) {
      dispatch(
        deleteAdminPharmacistVacations({ vacationIds: deleteVacations, notes }),
      );
    }

    if (deletedAvails.length !== 0) {
      dispatch(
        adminDeleteAvailability({ availabilityIds: deletedAvails, notes }),
      );
    }
  };

  const showDeleteConfirmation = () => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            validator={null}
            onSubmit={deleteSelectedAvailabilities}
            confirmationMessage={strings.deleteAvailabilityOrVacationConfirm}
            id=""
            initialValues={{
              notes: '',
            }}
            heading={strings.deleteAvailabilityOrVacation}
            iconType={DeactivateModalIcon.warning}
            name="notes"
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const stopMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isAdmin) e.stopPropagation();
  };

  const handleMultipleClicks = ({ type, id }: IHandleMultipleClick) => {
    const deletableTypes = [availability, vacation];
    const isMultipleAddAvailability = isMultipleAdd
      && deletableTypes.includes(type as PharmacistLegendVariant)
      && isAdmin;
    const allowClick = isMultipleAddAvailability || type === tempAvailability;

    if (isMultipleAddAvailability) {
      handleDeleteClick(id);
    }

    return allowClick;
  };

  return {
    multipleAvailabilities,
    handleMultipleClicks,
    deleteSelectedAvailabilities: showDeleteConfirmation,
    getDeletedAvailabilities,
    handleDeleteReset: handleReset,
    stopMouseDown,
    getDeletedVacations,
  };
};

export default useMultipleDelete;
