import { SxProps, Theme } from '@mui/material';
import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  headerContainer: {
    padding: '12px',
    maxHeight: '28px',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#F6F7FC',
    borderBottom: `1px solid ${ThemeConstants.common.border}`,
  },

  backButton: {
    color: 'grey.500',
  },

  closeButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginRight: '15px',
    alignItems: 'center',
  },

  closeButtonBg: {
    backgroundColor: 'secondary.main',
  },

  headerTitle: {
    fontSize: '16px',
    fontWeight: 400,
    color: 'grey.500',
  },

  headerPadding: {
    paddingLeft: '5px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
