import Routes from "../helpers/Routes";
import { AppIDType } from "../models/IAppID";
import httpClient from "./httpClient";

export interface IDeviceDetails {
  appId: AppIDType;
  deviceId: string;
  deviceModel: string;
  deviceToken: string;
  systemVersion: string;
  appVersion: string;
}

export interface IUpdateDetails {
  regId:string;
  deviceToken:string;
}

class MobileRegController {
  basePath: string;
  constructor() {
    this.basePath = Routes.mobileRegistration.registrationBasePath;
  }

  mobileRegisteration = async ({
    appId,
    deviceId,
    deviceModel,
    deviceToken,
    systemVersion,
    appVersion,
  }: IDeviceDetails) =>
    httpClient.post(`${this.basePath}${Routes.mobileRegistration.register}`, {
      appid: `pp-${appId}`,
      deviceid: deviceId,
      devicemodel: deviceModel,
      devicetoken: deviceToken,
      systemversion: systemVersion,
      appversion: appVersion,
    });

  fetchByRegId = async (regId: string) =>
    httpClient.get(`${this.basePath}${Routes.mobileRegistration.getByRegId}${regId}`);

  fetchByDeviceId = async (deviceId: string) =>
    httpClient.get(`${this.basePath}${Routes.mobileRegistration.getByDeviceID}${deviceId}`);

  fetchUpdateDeviceToken = async ({regId, deviceToken}:IUpdateDetails) =>
    httpClient.get(
      `${this.basePath}${Routes.mobileRegistration.updateDeviceToken}${regId}/${deviceToken}`
    );
}

export default new MobileRegController();
