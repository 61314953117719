import React, { FC } from 'react';

import OtpInput from 'react-otp-input';
import { FormikProps } from 'formik';
import { Box, Button, TextField, Typography } from '@mui/material';

import { ReactComponent as OtpIcon } from '@pharmaplan/common/assets/icons/otpIcon.svg';
import strings from '../../../../localization';
import CustomButton from '../../../common/CustomButton';
import LoginCustomCard from '../../../common/LoginCustomCard';
import useTimer from '../../../../hooks/useTimer';
import ThemeConstants from '../../../../theme/ThemeConstants';

import classes from './styles';

const OTP_LENGTH = 6;

const { primary, grey } = ThemeConstants;
const {
  infoHeading,
  infoDesc,
  resendCode,
  resendContainer,
  container,
  otpInput,
  input,
  timer,
  button: buttonStyle,
  disabledButton,
} = classes;

interface IOtp {
  formik: FormikProps<any>;
  resendFunc: () => void;
  disable: {
    button: boolean;
    link: boolean;
  };
  setDisable: React.Dispatch<
    React.SetStateAction<{
      button: boolean;
      link: boolean;
    }>
  >;
  desc: string;
}

const Otp: FC<IOtp> = ({ formik, resendFunc, disable, setDisable, desc }) => {
  const { otp } = formik.values;
  const { minutes, seconds, resetTimer } = useTimer(120, setDisable);

  const textArray = [
    {
      key: 1,
      text: strings.enterOtp,
      sx: infoHeading,
    },
    {
      key: 2,
      text: desc,
      sx: infoDesc,
    },
  ];

  const { button, link } = disable ?? {};

  const linkStyle = link ? { color: grey[500] } : { color: primary.main };
  const linkText = link ? strings.resendOtpIn : strings.resendOtp;

  const resend = () => {
    resetTimer();
    resendFunc();
  };

  const handleOnChange = (value: string) => {
    formik.setFieldValue('otp', value);
  };

  return (
    <LoginCustomCard icon={<OtpIcon />} textArray={textArray}>
      <Box sx={container}>
        <OtpInput
          value={otp}
          shouldAutoFocus
          inputStyle={input}
          onChange={handleOnChange}
          numInputs={OTP_LENGTH}
          renderInput={(props) =>
            (
              <TextField inputProps={props} sx={otpInput} />
            )}
        />

        <Box sx={resendContainer}>
          <Button disabled={link} onClick={resend} sx={[resendCode, linkStyle]}>
            {linkText}
            {link && (
              <Typography sx={timer}>
                {minutes > 9 ? '' : '0'}
                {minutes}
                :
                {seconds > 9 ? '' : '0'}
                {seconds}
              </Typography>
            )}
          </Button>
        </Box>
      </Box>

      <CustomButton
        disabled={button}
        customButtonStyle={disabledButton}
        customClass={buttonStyle}
        label={strings.verify}
        onClick={formik.handleSubmit}
      />
    </LoginCustomCard>
  );
};

export default Otp;
