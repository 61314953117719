import React, { FC } from 'react';

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProps } from 'formik';

import {
  HelpTypes,
  PendingActivationTypes,
  PendingTabTypes,
  adminOwnersList,
  adminPendingActivationsCount,
  PermissionsOfAdmin,
} from '@pharmaplan/common';

import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';
import CustomDateRange from '../../../common/CustomDateRange';
import { getDisabledSubmitFilter } from '../../../../selectors/calendarSelector';
import { Constants, UserTypesDropdown } from '../../../../helpers/Constants';
import PendingTabs from '../../PendingTabs';

import styles from './styles';
import usePendingActivations from '../../../../hooks/PendingActivations/usePendingActivations';
import CustomDropdown from '../../../common/CustomDropdown';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import { serverTodayMinusOne } from '../../../../helpers/Functions';
import CustomTextField from '../../../common/CustomTextField';
import useAdminPermissions from '../../../../hooks/Admin/useAdminPermissions';
import SelectAllHeader from '../../../DynamicTable/SelectAllHeader';

interface IPendingHeader {
  formik: FormikProps<any>;
  setSelected: (tab: PendingTabTypes) => void;
  selectedTab: PendingTabTypes;
  handleDelete: () => void;
  handleSelectAll: (toBeSet: boolean) => void;
  selectAll: boolean;
  resetSelected: () => void;
}

const {
  pendingActivationListPharmacist,
  pendingActivationListPharmacy,
  pendingActivationListDeletedUsers,
} = Constants.paths.admin;

const pathMap = {
  [HelpTypes.pharmacist]: pendingActivationListPharmacist,
  [HelpTypes.pharmacy]: pendingActivationListPharmacy,
  [PendingActivationTypes.deletedUsers]: pendingActivationListDeletedUsers,
};

const PendingHeader: FC<IPendingHeader> = ({
  formik,
  setSelected,
  selectedTab,
  handleSelectAll,
  selectAll,
  resetSelected,
  handleDelete,
}) => {
  const { pendingActivationsDefault } = usePendingActivations(selectedTab);
  const disableSubmit = useAppSelector(getDisabledSubmitFilter);
  const admins = useAppSelector(adminOwnersList);
  const isDeletedTab = selectedTab === PendingActivationTypes.deletedUsers;
  const navigate = useNavigate();

  const { can } = useAdminPermissions();
  const canPermanentlyDelete = can(PermissionsOfAdmin.PermanentDelete);

  const pendingCounts = useAppSelector(adminPendingActivationsCount);
  const { pharmacistCount, pharmacyCount } = pendingCounts ?? {};

  const handleTabClick = (tab: HelpTypes) => {
    if (tab in pathMap) {
      navigate(pathMap[tab as keyof typeof pathMap]);
    } else {
      navigate(pendingActivationListPharmacist);
    }

    setSelected(tab);
  };

  const tabConfig = [
    {
      key: HelpTypes.pharmacist,
      isDefault: true,
      tabName: `${strings.pharmacists} (${pharmacistCount})`,
      onClick: handleTabClick,
    },
    {
      key: HelpTypes.pharmacy,
      tabName: `${strings.pharmacies} (${pharmacyCount})`,
      onClick: handleTabClick,
    },
    {
      key: PendingActivationTypes.deletedUsers,
      tabName: strings.deletedActivationRequests,
      onClick: handleTabClick,
    },
  ];

  const handleReset = () => {
    pendingActivationsDefault();
    resetSelected();
    formik.resetForm();
  };

  const isAllowedDelete = canPermanentlyDelete && selectedTab === PendingActivationTypes.deletedUsers;

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PendingTabs
        header={strings.pendingActivationList}
        tabConfig={tabConfig}
        selectedTab={selectedTab}
      />
      <Grid container lg={10} alignItems="center" xs={12} wrap="nowrap">
        <CustomDateRange
          startName="startDate"
          endName="endDate"
          startMax={serverTodayMinusOne}
          endMax={serverTodayMinusOne}
          formik={formik}
          preventPast={false}
        />
        <Grid xs={6} sx={styles.autoCompleteContainer}>
          <CustomTextField
            name="userKey"
            formik={formik}
            label={strings.user}
            id="id"
            placeholder={strings.enterNameEmail}
          />
        </Grid>
        <Grid item xs={6} sx={[styles.dropdownContainer]}>
          <CustomDropdown
            label={strings.ownedBy}
            menuItems={dropdownMapper(admins, 'userId', 'email')}
            formik={formik}
            name="ownerId"
            customMenuStyles={styles.ownerDropdown}
          />
        </Grid>
        {isDeletedTab && (
          <Grid item xs={6} sx={[styles.dropdownContainer]}>
            <CustomDropdown
              label={strings.userType}
              menuItems={UserTypesDropdown}
              formik={formik}
              name="userType"
            />
          </Grid>
        )}
        <FilterSubmitButtons
          handleReset={handleReset}
          disableSubmit={disableSubmit}
        />
      </Grid>
      {isAllowedDelete && (
        <SelectAllHeader
          selectAll={selectAll}
          showDelete
          handleSelectAll={handleSelectAll}
          handleDelete={handleDelete}
        />
      )}
    </Box>
  );
};

export default PendingHeader;
