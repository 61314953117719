import React from 'react';

import PlaceIcon from '@mui/icons-material/Place';
import { Box } from '@mui/material';
import classes from './style';

const Marker = () =>
  (
    <Box sx={classes.mapMarkerContainer}>
      <PlaceIcon sx={classes.mapMarker} />
    </Box>
  );

export default Marker;
