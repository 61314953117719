import { SxProps, Theme } from '@mui/system';

const styles = {
  bannerDropdown: {
    maxHeight: 320,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    border: 'none',
  },
  cell: {
    '&.MuiTableCell-root': {
      border: 'none',
    },
  },
  saveContainer: {
    padding: '12px 0 32px 0',
    mx: '24px',
  },
  saveButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconContainer: {
    mb: '30px',
  },
  title: {
    fontSize: '24px',
    lineHeight: '36px',
    color: 'grey.500',
  },
  fieldContainer: {
    mt: '24px',
  },
  desc: {
    mt: '8px',
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
  },
  field: {
    mb: '32px',
    fontWeight: '500',
    fontSize: 14,
    color: 'grey.800',
  },
  button: {
    minWidth: 120,
  },
  cancelContainer: {
    mr: '20px',
  },
  backTitle: {
    fontSize: 22,
    fontWeight: '600',
    color: 'common.grey850',
    mb: '25px',
    ml: 1,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
