import {
  IMatchingWorkshiftAvailabilityData,
  TimeFormat,
  TypeOfUser,
} from '@pharmaplan/common';
import { DynamicTableCell } from '../../../helpers/Constants';
import { timeString } from '../../../helpers/Functions';
import { IUserProfileLinkSignature } from '../../../hooks/Admin/useUserProfileLink';
import strings from '../../../localization';
import { getDayMonthDateYear } from '../../Reports/helpers';
import addAvailablilityIcon from '../../../assets/svg/addAvailabilityIcon.svg';
import disabledAddAvailability from '../../../assets/svg/disabledAddAvailability.svg';

const { text, smallerCustomLink } = DynamicTableCell;

export const matchingWorkshiftsHeaders = () =>
  [
    { key: '1', label: strings.pharmacy },
    { key: '2', label: strings.date },
    { key: '3', label: strings.time },
  ];

export const availabilitiesRowConfig = (
  data: { startDate: string; endDate: string; code: string },
  timeFormat: TimeFormat,
) => {
  const { startDate, endDate, code } = data ?? {};
  const parsedDate = getDayMonthDateYear(startDate);
  const time = timeString(startDate, endDate, timeFormat);

  return [
    {
      key: '1',
      data: [
        {
          key: '0',
          value: code,
          type: text,
        },
        {
          key: '1',
          value: parsedDate,
          type: text,
        },
        {
          key: '2',
          value: time,
          type: text,
        },
      ],
    },
  ];
};

export const matchingWorkshiftsRowConfig = (
  data: Array<IMatchingWorkshiftAvailabilityData>,
  goToUserProfile: IUserProfileLinkSignature,
  timeFormat: TimeFormat,
  goToApproveBooking: (workshiftId: string) => void,
) =>
  data?.map((item) => {
    const {
      startDate,
      endDate,
      pharmacyId,
      pharmacyName,
      workShiftId,
      isRequested,
      booked,
    } = item ?? {};
    const parsedDate = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);

    const tooltip = () => {
      if (isRequested) {
        return { tooltip: strings.requested, icon: disabledAddAvailability };
      }
      if (booked) {
        return { tooltip: strings.booked, icon: disabledAddAvailability };
      }
      return { tooltip: strings.book, icon: addAvailablilityIcon };
    };

    return {
      key: workShiftId,
      data: [
        {
          key: `1-${workShiftId}`,
          value: {
            label: pharmacyName,
            handlePress: goToUserProfile({
              personnelId: pharmacyId,
              userType: TypeOfUser.pharmacy,
            }),
          },
          minWidth: 110,
          type: smallerCustomLink,
        },
        {
          key: `2-${workShiftId}`,
          value: parsedDate,
          minWidth: 90,
          type: text,
        },
        {
          key: `3-${workShiftId}`,
          value: time,
          minWidth: 90,
          type: text,
        },
        {
          key: `3-${workShiftId}`,
          show: true,
          width: 50,
          value: [
            {
              key: '1',
              icon: tooltip().icon,
              tooltip: tooltip().tooltip,
              disabled: isRequested || booked,
              onClick: goToApproveBooking(workShiftId),
            },
          ],
          type: DynamicTableCell.iconButton,
        },
      ],
    };
  });
