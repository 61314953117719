/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import MessageContentSend, {
  MessageContentProps,
} from '@sendbird/uikit-react/ui/MessageContent';

import SenderProfile from './SenderProfile';
import MessageStatus from './MessageStatus';

const MessageContent = (props: MessageContentProps) =>
  (
    <MessageContentSend
      {...props}
      renderSenderProfile={SenderProfile}
      renderMessageStatus={() =>
        (
          <MessageStatus message={props.message} channel={props.channel} />
        )}
    />
  );

export default MessageContent;
