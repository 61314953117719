import React, { FC } from 'react';
import { MessageInputWrapper } from '@sendbird/uikit-react/GroupChannel/components/MessageInputWrapper';

import { PermissionsOfAdmin } from '@pharmaplan/common';
import useAdminPermissions from '../../../../hooks/Admin/useAdminPermissions';

interface IMessageInput {
  isEngaged: boolean;
}

const MessageInput:FC<IMessageInput> = ({ isEngaged }) => {
  const { can } = useAdminPermissions();
  const canViewEngaged = can(PermissionsOfAdmin.ViewEngagedChat);
  const isDisabled = canViewEngaged && isEngaged;

  return <MessageInputWrapper disabled={isDisabled} />;
};

export default MessageInput;
