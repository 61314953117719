const classes = {
  postedButton: {
    backgroundColor: 'common.lightGreen',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: 'common.secondaryButtonHover',
      color: 'primary.main',
    },
  },

  cancelButton: {
    backgroundColor: 'secondary.main',
    color: 'primary.main',
  },

  cancelRequestButton: {
    backgroundColor: 'secondary.main',
    color: 'primary.main',
  },

  counterOfferBtn: {
    backgroundColor: 'common.lightGreen',
    border: 'none',
  },

  counterOfferContainer: {
    marginBottom: 2,
  },

};

export default classes;
