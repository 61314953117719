import React, { FC, useEffect } from 'react';

import { watchlists } from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import AvailableWorkshifts from '../AvailableWorkshifts';

interface IAdminWatchlists {
  pharmacistId: string;
}

const AdminWatchLists: FC<IAdminWatchlists> = ({ pharmacistId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(watchlists(pharmacistId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AvailableWorkshifts pharmacistId={pharmacistId} viewOnly />
  );
};

export default AdminWatchLists;
