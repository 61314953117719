import React, { FC, Fragment } from 'react';

import { IRating, IRatingItems, Language, language } from '@pharmaplan/common';

import { Box } from '@mui/material';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import AllLabelRatings from '../../../../Admin/DetailedRatings/AllRatings/AllLabelRatings';
import useAdmin from '../../../../../hooks/useAdmin';
import CustomProgressBar from '../../../../common/CustomProgressBar';

const { en } = Language;

interface IViewPharmacistRatingContent {
  ratingData: IRatingItems;
  ratingDetails: IRating;
}

const ViewPharmacistRatingContent: FC<IViewPharmacistRatingContent> = ({
  ratingData,
  ratingDetails,
}) => {
  const userLanguage = useAppSelector(language);
  const { isAdmin } = useAdmin();

  const isEN = userLanguage === en;

  const { pharmacistRatingItems } = ratingData ?? {};
  const { ratings } = ratingDetails ?? {};

  const adminContainer = isAdmin ? { padding: '32px' } : {};

  const tempObj = ratings.reduce((acc: Record<string, number>, cur) => {
    acc[cur?.pharmacistRatingItemId ?? ''] = parseFloat(cur?.rating ?? 0);
    return acc;
  }, {});

  const renderRating = (primaryText: string, secondaryText: number, progress: number) => {
    const handleSecondary = `${secondaryText}%`;

    if (isAdmin) {
      return (
        <AllLabelRatings
          value={progress}
          percentage={secondaryText}
          label={primaryText}
        />
      );
    }

    return (
      <CustomProgressBar
        progress={progress}
        primaryText={primaryText}
        secondaryText={handleSecondary}
      />
    );
  };

  return (
    <Box sx={adminContainer}>
      {pharmacistRatingItems.map((item) => {
        const { pharmacistRatingItemId, englishName, frenchName } = item ?? {};

        const primaryText = isEN ? englishName : frenchName;
        const secondaryText = tempObj[pharmacistRatingItemId] ?? 0;
        const progress = tempObj[pharmacistRatingItemId] ?? 0;

        return (
          <Fragment key={pharmacistRatingItemId}>
            {renderRating(primaryText, secondaryText, progress)}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default ViewPharmacistRatingContent;
