import { SxProps, Theme } from '@mui/system';

const classes = {
  header: {
    fontSize: '16px',
    fontWeight: 500,
  },

  filterDropdown: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      border: '1px solid',
      color: 'primary.main',
    },
    '& fieldset': { border: 'none' },
  },

  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  root: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
  },

  title: { fontSize: '16px', fontWeight: 600 },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
