import React, { FC } from 'react';
import strings from '../../../localization';
import ServiceCard from '../ServiceCard/ServiceCard';
import ContactContent from './ContactContent';

export interface IContactDetails {
  successAction: string;
}

const ContactDetails: FC<IContactDetails> = ({ successAction }) =>
  (
    <ServiceCard title={strings.contactDetails} content={<ContactContent successAction={successAction} />} />
  );

export default ContactDetails;
