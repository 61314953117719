import React, { useMemo } from 'react';

import { DateLocalizer, Navigate } from 'react-big-calendar';
import * as dates from 'date-arithmetic';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';

import { getTypeOfUser } from '@pharmaplan/common';

import EventChip from '../../EventChip';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';
import ICalendar from '../../../../types/ICalendar';

import { Localizer, isToday, parseDate } from './helper';
import classes from './style';

interface ICustomWeekView {
  date: Date;
  localizer: DateLocalizer;
  events: Array<ICalendar & { selectedDate: string }>;
}

const CustomWeekView = ({ date, localizer, events }: ICustomWeekView) => {
  const userType = useAppSelector(getTypeOfUser);

  const groupByDate = () =>
    events.reduce((acc: Record<string, Array<ICalendar>>, event) => {
      const eventDate = event.selectedDate?.substring(
        0,
        event.selectedDate?.indexOf('T'),
      );
      if (acc[eventDate]) {
        acc[eventDate].push(event);
      } else {
        acc[eventDate] = [event];
      }
      return acc;
    }, {});

  const groupedEvent = groupByDate();

  const currRange = useMemo(
    () =>
      CustomWeekView.range(date, { localizer }),
    [date, localizer],
  );

  return (
    <Grid container>
      {currRange.map((item) => {
        const timeStamp = DateTime.fromJSDate(item).toMillis();
        const eventsList = groupedEvent[parseDate(item)];
        const showNoDataAvailable = eventsList?.length === 0 || (!eventsList && strings.noDataAvailable);

        return (
          <Grid key={timeStamp} container>
            <Grid sx={classes.dayContainer} item xs={1}>
              <Box sx={classes.dayName} component="div">
                {DateTime.fromJSDate(item).toFormat('ccc')}
              </Box>

              <Box sx={[isToday(item) && classes.todayIndc]} component="div">
                {new Date(item).getDate()}
              </Box>
            </Grid>

            <Grid sx={classes.eventContainer} item xs={11}>
              {eventsList?.map((event: ICalendar) => {
                const { id } = event ?? {};

                return (
                  <Box key={id} component="div">
                    <EventChip event={event} typeOfUser={userType} />
                  </Box>
                );
              })}

              <Box component="div">{showNoDataAvailable}</Box>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

CustomWeekView.range = (date: Date, { localizer }: Localizer) => {
  const start = DateTime.fromJSDate(date).startOf('week').toJSDate();

  const end = dates.add(start, 6, 'day');
  let current = start;
  const range = [];

  while (localizer.lte(current, end, 'day')) {
    range.push(current);
    current = localizer.add(current, 1, 'day');
  }

  return range;
};

CustomWeekView.navigate = (
  date: Date,
  action: string,
  { localizer }: Localizer,
) => {
  const previous = action === Navigate.PREVIOUS;
  const next = action === Navigate.NEXT;

  if (previous) {
    return localizer.add(date, -7, 'day');
  }

  if (next) {
    return localizer.add(date, 7, 'day');
  }

  return date;
};

CustomWeekView.title = (date: Date) =>
  `My awesome week: ${date.toLocaleDateString()}`;

export default CustomWeekView;
