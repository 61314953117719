import React, { useState, useEffect } from 'react';

import { Box } from '@mui/system';
import { FormikProps } from 'formik';

import { getProfileUtils, IFilterSoftware } from '@pharmaplan/common';

import CustomSlider from '../CustomSlider';
import classes from './style';
import { useAppSelector } from '../../../hooks/useAppSelector';

interface ICustomRadioTable {
  form: {
    rows: Array<IFilterSoftware>;
    options: Array<{ label: string; key: string }>;
  };
  formik: FormikProps<any>;
  isSignup?: boolean;
}

const defaultProps = {
  isSignup: false,
};

const marks = [
  {
    value: 1,
    label: '0%',
  },
  {
    value: 2,
    label: '25%',
  },
  {
    value: 3,
    label: '50%',
  },
  {
    value: 4,
    label: '75%',
  },
  {
    value: 5,
    label: '100%',
  },
];

const CustomRadioTable = ({ form, formik, isSignup }: ICustomRadioTable) => {
  const { rows } = form ?? {};
  const { values, setValues } = formik ?? {};
  const profileUtil = useAppSelector(getProfileUtils);

  const [rowItems, setRowItems] = useState(profileUtil.softwares);

  useEffect(() => {
    setRowItems(profileUtil.softwares);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileUtil]);

  useEffect(() => {
    if (isSignup) {
      setTimeout(() => {
        setValues(
          rows.reduce(
            (obj, item) =>
              Object.assign(obj, { [item?.softwareId]: 1 }),
            {},
          ),
        );
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <Box
      component="div"
      sx={classes.customRadioButtonContainer}
      data-testid="test"
    >
      <CustomSlider label="" sx={classes.headerClass} marks={marks} value={0} />
      {rowItems?.map((rowItem) => {
        const { softwareId, name } = rowItem ?? {};
        const value = values[softwareId as keyof typeof values] ?? 1;
        const isValidID = value !== undefined || isSignup;

        return (
          isValidID && (
            <CustomSlider
              key={name}
              onChange={(event) =>
                setValues({
                  ...values,
                  [softwareId]: (event?.target as HTMLButtonElement)?.value,
                })}
              label={name}
              value={value}
            />
          )
        );
      })}
    </Box>
  );
};

CustomRadioTable.defaultProps = defaultProps;

export default CustomRadioTable;
