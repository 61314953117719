const classes = {
  monthHeaderText: {
    backgroundColor: 'common.grey150',
    color: 'grey.500',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '40px',
    fontWeight: 500,
    alignItems: 'center',
    textTransform: 'uppercase',
  },
};

export default classes;
