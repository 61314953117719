import { SxProps, Theme } from '@mui/system';

const classes = {

  refNoText: {
    fontWeight: 600,
    textAlign: 'center',
  },

  text: {
    fontWeight: 500,
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
