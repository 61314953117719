import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  activePharmacistLists,
  adminActions,
  adminGetIncompatibleMatches,
  adminIncompatibleMatches,
  getActivePharmacistList,
  pharmacyDetails,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { ITransferListItem } from '../../../components/common/TransferList/TransferList';
import { Constants, DynamicTableCell } from '../../../helpers/Constants';
import strings from '../../../localization';
import { resetDialog } from '../../../reducers/dialogReducer';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import useGetItems from '../../useGetItems';

const { text, icon } = DynamicTableCell;
const { updateIncompatibleMatch } = adminActions;

const useUpdateIncompatibleMatch = (pharmacyId: string, pharmacyEmail: string) => {
  const dispatch = useAppDispatch();

  const [list, setList] = useState<{
    left: Array<ITransferListItem>;
    right: Array<ITransferListItem>;
  }>({ left: [], right: [] });

  const details = useAppSelector(pharmacyDetails);
  const pharmacistLists = useAppSelector(activePharmacistLists);
  const incompatiblePharmacists = useAppSelector(adminIncompatibleMatches);
  const navigate = useNavigate();

  const allPharmacists = pharmacistLists.data;
  const success = useAppSelector((state) =>
    successSelector([updateIncompatibleMatch], state));

  const right = useMemo(
    () =>
      incompatiblePharmacists.data.map((item) =>
        ({
          key: item.pharmacistId,
          label: item.name,
          email: item.email,
        })),
    [incompatiblePharmacists],
  );

  const left = useMemo(
    () =>
      allPharmacists
        .filter(
          (item) =>
            !list.right.map((incom) =>
              incom.key).includes(item.pharmacistId),
        )
        .filter(
          (item) =>
            !right.map((incom) =>
              incom.key).includes(item.pharmacistId),
        )
        .map((item) =>
          ({
            key: item.pharmacistId,
            label: item.name,
            email: item.email,
          })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allPharmacists, right],
  );

  const { getImage, getLanguageName, getSoftwareName } = useGetItems();

  const {
    pharmacy: {
      name,
      softwareId,
      languageId,
      address,
      primaryContactName,
      primaryContactPhone,
      logoId,
      primaryContactEmail,
    },
    softwares,
  } = details ?? {};

  const displayGrid = [
    { key: '1', label: strings.banner, value: getImage(logoId), type: icon },
    {
      key: '2',
      label: strings.pharmacyName,
      value: name,
      type: text,
    },
    {
      key: '3',
      label: strings.software,
      value: getSoftwareName(softwares, softwareId),
      type: text,
    },
    {
      key: '4',
      label: strings.preferredLanguage,
      value: getLanguageName(languageId),
      type: text,
    },

    { key: '5', label: strings.address, value: address, type: text },
    {
      key: '6',
      label: strings.primaryContactName,
      value: primaryContactName,
      type: text,
    },
    {
      key: '7',
      label: strings.primaryContactPhone,
      value: primaryContactPhone,
      type: text,
    },
    {
      key: '8',
      label: strings.primaryContactEmail,
      value: primaryContactEmail,
      type: text,
    },
  ];

  useEffect(() => {
    setList((prevList) =>
      ({
        ...prevList,
        left,
      }));
  }, [left]);

  useEffect(() => {
    setList((prevList) =>
      ({
        ...prevList,
        right,
      }));
  }, [right]);

  useEffect(() => {
    if (success) {
      dispatch(resetStatus([updateIncompatibleMatch]));
      dispatch(resetDialog());
      dispatch(
        adminGetIncompatibleMatches({ pharmacyId, paging: { page: 1 } }),
      );

      if (pharmacyEmail) {
        navigate(Constants.paths.admin.pharmacyListIncompatible, {
          state: { pharmacyId, pharmacyEmail },
        });
      } else {
        navigate(Constants.paths.admin.incompatibleMatch);
      }

      dispatch(getActivePharmacistList({ page: 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return { displayGrid, left, right, pharmacistLists, list, setList };
};

export default useUpdateIncompatibleMatch;
