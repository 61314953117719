import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  welcomeText: {
    fontWeight: 400,
    fontSize: '18px',
  },

  pharmaPlanStyle: {
    fontSize: '18px',
    fontWeight: 700,
    color: 'common.grey850',
  },

  signUpText: {
    marginBottom: '15px',
    fontSize: 36,
    fontWeight: 600,
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  pharmaPlanContainer: {
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'flex-start',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
