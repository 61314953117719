import React, { useEffect, useState } from 'react';

const useTimer = (
  initSeconds: number,
  setDisableButton: React.Dispatch<
    React.SetStateAction<{
      button: boolean;
      link: boolean;
    }>
  >,
) => {
  const [seconds, setSeconds] = useState(initSeconds);

  useEffect(() => {
    if (!seconds) {
      setDisableButton((prev) =>
        ({ ...prev, link: false }));
      return;
    }

    const intervalId = setInterval(() => {
      setSeconds((prev: number) =>
        prev - 1);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  const divisorForMinutes = seconds % (60 * 60); // PREVENTS OVERFLOW; FOR POSTERITY

  const resetTimer = () => {
    setSeconds(initSeconds);
    setDisableButton((prev) =>
      ({ ...prev, link: true }));
  };

  return {
    minutes: Math.floor(divisorForMinutes / 60),
    seconds: Math.ceil(seconds % 60),
    resetTimer,
  };
};

export default useTimer;
