import { SxProps, Theme } from '@mui/system';

const styles = {
  gridLabel: {
    marginBottom: '5px',
  },
  chipStyle: {
    backgroundColor: 'common.green300',
    color: 'common.green600',
  },
  container: {
    minHeight: '75vh',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
