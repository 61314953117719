import React, { FC } from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as AppointIcon } from '../../../../../assets/svg/appointLargeIcon.svg';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { resetDialog } from '../../../../../reducers/dialogReducer';
import { Constants } from '../../../../../helpers/Constants';
import CustomButton from '../../../../common/CustomButton';
import strings from '../../../../../localization';

import styles from './styles';

interface IAppointModal {
  pharmacyName: string;
  email: string;
  pharmacyId: string;
}

const {
  paths,
  variant: { text },
} = Constants;
const { appointPharmacyAdministrators } = paths.admin;

const AppointModal: FC<IAppointModal> = ({
  pharmacyId,
  pharmacyName,
  email,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cancel = () => {
    dispatch(resetDialog());
  };

  const goToAppoint = () => {
    navigate(appointPharmacyAdministrators, { state: { id: pharmacyId } });
    cancel();
  };

  return (
    <Box sx={styles.container}>
      <AppointIcon />
      <Typography sx={styles.title}>{strings.appointPharmacyAdmin}</Typography>
      <Box sx={styles.layout}>
        <Typography sx={styles.label}>{strings.pharmacyName}</Typography>
        <Typography sx={styles.value}>{pharmacyName}</Typography>
      </Box>
      <Box sx={styles.layout}>
        <Typography sx={styles.label}>{strings.email}</Typography>
        <Typography sx={styles.value}>{email}</Typography>
      </Box>
      <Box sx={styles.buttonContainer}>
        <CustomButton
          customClass={styles.cancelButton}
          customButtonStyle={styles.button}
          variant={text}
          label={strings.cancel}
          onClick={cancel}
        />
        <CustomButton
          customButtonStyle={styles.button}
          label={strings.yes}
          onClick={goToAppoint}
        />
      </Box>
    </Box>
  );
};

export default AppointModal;
