import * as Yup from 'yup';
import strings from '../../../localization';
import { phoneRegex } from '../../../helpers/Constants';

export const AdminProfileValidator = () =>
  Yup.object().shape({
    phone: Yup.string()
      .notRequired()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`),
  });
