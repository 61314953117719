import { totalRatingValue } from '@pharmaplan/common';
import styles from './style';
import strings from '../../../../localization';

export const infoConfig = (rating: string, reviews: number | null) =>
  ([
    {
      key: 0,
      text: rating,
      sx: styles.rating,
    },
    {
      key: 1,
      text: `/${totalRatingValue}`,
      sx: styles.totalRating,
    },
    {
      key: 2,
      text: strings.averageRating,
      sx: styles.avgRating,
    },
    {
      key: 3,
      text: `(${reviews} ${strings.reviews})`,
      sx: styles.reviews,
    },
  ]);
