import { useNavigate } from 'react-router-dom';
import { genericFunction } from '../../AvailableWorkshift/useAvailableWorkshiftActions';
import strings from '../../../localization';
import { useAppDispatch } from '../../useAppDispatch';
import { IFromScreen, renderScreen } from '../../../actions/drawerActions';
import { Constants, ScreenTypes } from '../../../helpers/Constants';
import useDrawer from '../../useDrawer';

type IPharmacyId = { pharmacyId: string };

export const menuActions = (
  showCreateWorkshift: genericFunction,
  editAction: genericFunction,
  incompatibleAction: genericFunction,
  viewListOfWorkshift: genericFunction,
) =>
  [
    {
      key: '-1',
      label: strings.createWorkshift,
      onClick: showCreateWorkshift,
    },
    {
      key: '1',
      label: strings.incompatibleMatch,
      onClick: incompatibleAction,
    },
    {
      key: '2',
      label: strings.listOfWorkshifts,
      onClick: viewListOfWorkshift,
    },
    {
      key: '0',
      label: strings.edit,
      onClick: editAction,
    },
  ];

const usePharmacyProfileViewDetails = (
  fromScreen?: IFromScreen,
  hasOwner?: boolean,
  back?: () => void,
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { closeDrawer } = useDrawer();

  const goToViewProfile = (pharmacyId: string) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.profile,
          screenNumber: 1,
          pharmacyId,
          fromScreen,
          back,
        }),
      );
    };

  const showCreateWorkshift = (pharmacyId: string, name: string) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 1,
          back: goToViewProfile(pharmacyId),
          screenType: ScreenTypes.pharmacyList,
          pharmacyId,
          extraParameters: {
            name,
          },
        }),
      );
    };

  const goToEditPharmacyProfile = ({ pharmacyId }: IPharmacyId) => {
    dispatch(
      renderScreen({
        screenNumber: 3,
        fromScreen,
        screenType: ScreenTypes.profile,
        hasOwner,
        back,
      }),
    );
  };

  const goToIncompatibleMatches = (pharmacyId: string) => {
    closeDrawer();
    navigate(Constants.paths.admin.updateIncompatibleMatch, {
      state: { pharmacyId },
    });
  };

  const showListOfWorkshifts = (pharmacyId: string) =>
    () => {
      closeDrawer();
      navigate(Constants.paths.admin.listOfWorkshifts, {
        state: pharmacyId,
      });
    };

  return {
    actions: (id: string, name: string) =>
      menuActions(
        showCreateWorkshift(id, name),
        () =>
          goToEditPharmacyProfile({ pharmacyId: id }),
        () =>
          goToIncompatibleMatches(id),
        showListOfWorkshifts(id),
      ),
  };
};

export default usePharmacyProfileViewDetails;
