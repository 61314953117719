import React, { FC } from 'react';
import { Box } from '@mui/system';

import { deleteNotification, getNotification } from '@pharmaplan/common';
import { INotificationsData } from '@pharmaplan/common/models/NotificationsModel';
import { Button } from '@mui/material';
import { ReactComponent as CheckMark } from '../../../../../assets/svg/checkMarkOutline.svg';
import { ReactComponent as BellIcon } from '../../../../../assets/svg/bellIconWhite.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/svg/deleteNotif.svg';

import ThemeConstants from '../../../../../theme/ThemeConstants';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import useNotificationActions from '../../../../../hooks/PushNotifications/useNotificationActions';

import styles from './styles';

interface INotificationActions {
  notification: INotificationsData;
  visited: boolean;
  handleDeleteNotif: (notificationId: string) => void;
  setVisited: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationActions: FC<INotificationActions> = ({
  notification,
  setVisited,
  visited,
  handleDeleteNotif,
}) => {
  const dispatch = useAppDispatch();
  const { confirmAction } = useNotificationActions();

  const { notificationId, bookingId, notificationType } = notification;

  const visitNotifications = () => {
    dispatch(getNotification(notificationId));
    setVisited(true);
  };

  const handleDelete = () => {
    handleDeleteNotif(notificationId);
    dispatch(deleteNotification(notificationId));
  };

  const handleAction = (action: () => void) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      action();
    };

  const actions = [
    {
      key: 3,
      handler: handleAction(handleDelete),
      icon: <DeleteIcon fill={ThemeConstants.common.counterRed} />,
      showBorder: false,
    },
  ];

  if (!visited) {
    actions.unshift({
      key: 1,
      icon: <BellIcon />,
      handler: handleAction(visitNotifications),
      showBorder: true,
    });
  }

  if (confirmAction[notificationType as keyof typeof confirmAction]) {
    actions.unshift({
      key: 2,
      icon: <CheckMark />,
      handler: handleAction(
        confirmAction[notificationType as keyof typeof confirmAction]({
          bookingId,
          callback: visitNotifications,
        }),
      ),
      showBorder: true,
    });
  }

  return (
    <Box sx={[styles.container, styles.flexContainer]}>
      <Box sx={[styles.subContainer, styles.flexContainer]}>
        {actions.map((item) =>
          (
            <Box
              key={item.key}
              sx={[styles.iconsContainer, styles.flexContainer]}
            >
              <Button
                variant="text"
                onClick={item.handler}
                sx={[styles.flexContainer, styles.button]}
              >
                {item.icon}
              </Button>
              {item.showBorder && <Box sx={styles.divider} />}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default NotificationActions;
