import React, { FC, ReactNode } from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Cancel } from '@mui/icons-material';

import { FormikProps } from 'formik';
import strings from '../../../../../localization';
import { ReactComponent as UploadIcon } from '../../../../../assets/svg/uploadCloud.svg';

import styles from '../styles';
import UploadButton from '../../../../common/UploadButton';

interface IChainUploadBanner {
  name: string;
  image: string;
  handleClear: () => void;
  formik: FormikProps<any>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const filesTypes = '.png, .jpg, .jpeg';

const ChainUploadBanner: FC<IChainUploadBanner> = ({
  name,
  image,
  handleClear,
  formik,
  setDisabled,
}) => {
  const { errors, touched } = formik ?? {};

  const formikErrorValidate = errors.image && touched.image && errors.image;
  const isFormikErrorValidate = !!formikErrorValidate;

  const helperText = isFormikErrorValidate
    ? (formikErrorValidate as ReactNode)
    : ' ';

  return (
    <Grid container sx={styles.uploadContainer}>
      <Grid item xs={6}>
        <Typography sx={styles.label}>{strings.banner}</Typography>
      </Grid>
      <Grid item xs={6}>
        {!!name && (
        <Box sx={styles.linkContainer}>
          <Typography
            component="a"
            href={image}
            download={name}
            sx={styles.imageName}
          >
            {name}
          </Typography>
          <IconButton onClick={handleClear} sx={styles.iconButton}>
            <Cancel sx={styles.crossIcon} />
          </IconButton>
        </Box>
        )}
        <UploadButton
          customContainerStyle={styles.uploadButton}
          sizeConstraint={{ height: 250, width: 250 }}
          chooseLabel={strings.uploadFile}
          name="image"
          setDisabled={setDisabled}
          startIcon={<UploadIcon />}
          acceptedFileTypes={filesTypes}
          fileSize={100}
          formik={formik}
          message={strings.sizeValidation}
          errorMessage={strings.sizeValidation}
        />
        <Box sx={{ mb: 1, mt: 1 }}>
          <Typography sx={styles.errorMsg}>{helperText}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChainUploadBanner;
