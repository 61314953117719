const classes = {
  customUploadInputMainContainer: {
    cursor: 'pointer',
  },
  customUploadInputLabel: {
    fontSize: '16px',
    fontWeight: 400,
    color: 'primary.inputLabel',
    marginBottom: '8px',
    marginTop: '4px',
  },
  customUploadInputIcon: {
    marginBottom: '15px',
  },
  uploadContainer: {
    padding: '10px',
  },
  customUploadInputContainer: {
    color: 'grey.500',
    borderRadius: '14px',
    display: 'flex',
    backgroundImage: 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'13\' ry=\'13\' stroke=\'%2396A0BCFF\' stroke-width=\'3\' stroke-dasharray=\'6%2c12\' stroke-dashoffset=\'73\' stroke-linecap=\'square\'/%3e%3c/svg%3e")',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    fontSize: '12px',
  },
};

export default classes;
