import React, { FC } from 'react';
import { Typography } from '@mui/material';
import classes from './styles';

interface ISoftwareWarning {
  message: string | string[];
}

const SoftwareWarning: FC<ISoftwareWarning> = ({ message }) => {
  const { text } = classes;

  return <Typography sx={text}>{message}</Typography>;
};

export default SoftwareWarning;
