import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { isLessThanFifty } from '@pharmaplan/common/helpers/utils';

import {
  SkeletonVariants,
  SoftwareExperience,
} from '../../../../helpers/Constants';
import WithSkeleton from '../../../common/WithSkeleton';

import { ISoftwareExpertiseContent } from '../types';
import classes from './styles';

const SoftwareExpertiseContent: FC<ISoftwareExpertiseContent> = ({
  softwareDetails,
  viewMore,
  successAction,
}) => {
  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  return (
    <Box
      sx={[
        classes.container,
        viewMore ? classes.extend(softwareDetails.length) : classes.collapse,
      ]}
    >
      {softwareDetails.map((item) => {
        const { level, software } = item ?? {};
        const { name, softwareId } = software ?? {};

        const belowFiftyStyle = isLessThanFifty(level - 1) && classes.belowFifty;

        return (
          <Box key={softwareId} sx={classes.textContainer}>
            <SkeletonTypography sx={[classes.label, belowFiftyStyle]}>
              {name}
            </SkeletonTypography>
            <SkeletonTypography sx={[classes.value, belowFiftyStyle]}>
              {SoftwareExperience()[level - 1]}
            </SkeletonTypography>
          </Box>
        );
      })}
    </Box>
  );
};

export default SoftwareExpertiseContent;
