import { SxProps, Theme } from '@mui/system';

const classes = {
  titleFont: {
    color: 'grey.800',
    fontSize: 16,
    fontWeight: 600,
  },

  titleContainer: {
    padding: '16px 0px 0px 16px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
