const baseHeight = (height: number) =>
  (height * 3) / 4;

const classes = {
  mainCalendarContainer: {
    backgroundColor: 'secondary.main',
    width: '100%',
    borderRadius: '10px',
  },
  calendar: (height: number, isAdmin: boolean) =>
    ({
      height: isAdmin ? baseHeight(height) : baseHeight(height) - 40,
      minHeight: 550,
    }),
  selectableCalendar: (height: number, isAdmin: boolean) =>
    ({
      height: isAdmin ? baseHeight(height) : baseHeight(height) - 40,
      cursor: 'pointer',
    }),
};

export default classes;
