import React, { useEffect } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  adminCreatePharmacyChain,
  adminGetPharmacyChainList,
  adminPharmacyChainList,
  PermissionsOfAdmin,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import usePharmacyChains from '../../../hooks/PharmacyChains/usePharmacyChains';
import strings from '../../../localization';
import { setDialog } from '../../../reducers/dialogReducer';
import CreateNew from '../../common/CreateNew';
import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';
import usePublishConfigurations from '../../../hooks/Admin/usePublishConfigurations';
import useConfigurationsSuccess from '../../../hooks/Admin/useConfigurationsSuccess';

import useDeleteConfirmation from '../../../hooks/Admin/useDeleteConfirmation';
import { DeleteConfirmationType } from '../../../helpers/Constants';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import useTableSort from '../../../hooks/useTableSort';
import { canCreateNew } from '../PharmacistCapacities/helper';

import ChainUploadModal from './ChainUploadModal';
import { pharmacyChainHeaders, pharmacyChainMap } from './helper';
import validator from './validator';

const { createPharmacyChain, updatePharmacyChain } = adminActions;

const PharmacyChains = () => {
  const dispatch = useAppDispatch();
  const { can } = useAdminPermissions();

  const canChangeParameters = can(PermissionsOfAdmin.ChangeParameters);
  const canDelete = can(PermissionsOfAdmin.Delete);

  const pharmacyChainList = useAppSelector(adminPharmacyChainList);

  useConfigurationsSuccess({
    addAction: createPharmacyChain,
    editAction: updatePharmacyChain,
  });

  const { getPharmacyChain } = usePharmacyChains();
  const { showDeleteConfirmation } = useDeleteConfirmation(
    DeleteConfirmationType.pharmacyChains,
  );

  const { isDesc, orderBy, handleSorting, order } = useTableSort();

  const {
    publishedRecords,
    setPublishedRecords,
    handlePublish,
    mapPublishedRecords,
  } = usePublishConfigurations();

  useEffect(() => {
    const publishMap = mapPublishedRecords(
      pharmacyChainList,
      'pharmacyChainId',
      'published',
    );
    setPublishedRecords(publishMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyChainList]);

  useEffect(() => {
    dispatch(adminGetPharmacyChainList({ paging: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderBy) {
      dispatch(
        adminGetPharmacyChainList({
          paging: {
            sortBy: orderBy,
            desc: isDesc,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  const handleSubmit = (values: FormikValues) => {
    let form = null;

    if (values.image) {
      form = new FormData();
      form.append('image', values.image);
    }

    dispatch(
      adminCreatePharmacyChain({
        form,
        name: values.name,
        published: values.published,
      }),
    );
  };

  const initialValues = {
    name: '',
    published: false,
    image: null,
  };

  const createModal = () => {
    dispatch(
      setDialog({
        Component: (
          <ChainUploadModal
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validator={validator()}
            submitLabel={strings.create}
          />
        ),
        heading: {
          title: strings.addNew,
        },
        showCloseButton: true,
      }),
    );
  };

  const table: IDynamicTableObject = {
    title: strings.pharmacyChains,
    headers: pharmacyChainHeaders(),
    rows: pharmacyChainMap(
      pharmacyChainList,
      canChangeParameters,
      canDelete,
      publishedRecords,
      handlePublish,
      getPharmacyChain,
      showDeleteConfirmation,
    ),
    headerBar: canCreateNew(canChangeParameters, {
      Component: <CreateNew label={strings.createNew} onClick={createModal} />,
      key: '1',
    }),
  };

  return (
    <DynamicTable
      table={table}
      order={order}
      orderBy={orderBy}
      handleSort={handleSorting}
      hidePagination
      loadSuccess
    />
  );
};

export default PharmacyChains;
