import React, { FC, useState } from 'react';

import strings from '../../../localization';
import { TypeOfSoftwareExpertise } from '../../../helpers/Constants';

import ServiceCard from '../ServiceCard/ServiceCard';
import SoftwareExpertiseContent from './SoftwareExpertiseContent';
import SoftwareExpertiseFooter from './SoftwareExpertiseFooter/SoftwareExpertiseFooter';
import SoftwareFamilarity from './SoftwareFamilarity/SoftwareFamilarity';
import { ISoftwareExpertise } from './types';
import classes from './SoftwareExpertiseContent/styles';

const SoftwareExpertise: FC<ISoftwareExpertise> = ({
  softwareDetails,
  successAction,
  layout = TypeOfSoftwareExpertise.softwareExpertise,
}) => {
  const isSoftwareFamilarity = layout === TypeOfSoftwareExpertise.softwareFamilarity;

  const [viewMore, setViewMore] = useState(false);

  const footerContent = {
    label: viewMore ? strings.viewLess : strings.viewMore,
    onClick: !viewMore ? () =>
      setViewMore(true) : () =>
      setViewMore(false),
  };

  const footer = !isSoftwareFamilarity && (
    <SoftwareExpertiseFooter footerContent={footerContent} />
  );

  const content = isSoftwareFamilarity ? (
    <SoftwareFamilarity
      successAction={successAction}
      softwareDetails={softwareDetails}
    />
  ) : (
    <SoftwareExpertiseContent
      successAction={successAction}
      viewMore={viewMore}
      softwareDetails={softwareDetails}
    />
  );

  return (
    <ServiceCard
      title={isSoftwareFamilarity ? '' : strings.softwareExpertise}
      customContainerStyle={
        isSoftwareFamilarity ? classes.containerWithoutPadding : {}
      }
      hideDivider
      content={content}
      footer={footer}
    />
  );
};

export default SoftwareExpertise;
