import { useEffect, useMemo, useState } from 'react';

import {
  activePharmacistLists,
  adminActivePharmacyList,
  getActivePharmacistList,
  getActivePharmacistListPagination,
  getActivePharmacyList,
  getActivePharmacyListPagination,
  TypeOfUser,
} from '@pharmaplan/common';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export enum AutoCompleteScrollTypes {
  pharmacy = 'pharmacy',
  pharmacist = 'pharmacist',
  all = 'all',
  none = 'none',
}

const { pharmacist, pharmacy, all, none } = AutoCompleteScrollTypes;
const { pharmacy: pharmacyUser, pharmacist: pharmacistUser } = TypeOfUser;

const useAutoCompleteScroll = (type: AutoCompleteScrollTypes) => {
  const dispatch = useAppDispatch();

  const pharmacistLists = useAppSelector(activePharmacistLists);
  const pharmacyList = useAppSelector(adminActivePharmacyList);

  const { data } = pharmacistLists ?? {};
  const { data: pharmacyData } = pharmacyList ?? {};

  const [page, setPage] = useState({
    [pharmacyUser]: 1,
    [pharmacistUser]: 1,
  });

  const getPaginationList = (userType: keyof typeof page, inPage: number) => {
    const params = { page: inPage };
    if (userType === pharmacyUser) {
      dispatch(getActivePharmacyListPagination(params));
    } else {
      dispatch(getActivePharmacistListPagination(params));
    }
  };

  const onEndReached = (userType: keyof typeof page) =>
    () => {
      setPage((prev) =>
        ({
          ...prev,
          [userType]: prev[userType] + 1,
        }));
      getPaginationList(userType, page[userType] + 1);
    };

  const getInitialList = () => {
    switch (type) {
      case pharmacy:
        dispatch(getActivePharmacyList({ page: 1 }));
        break;
      case pharmacist:
        dispatch(getActivePharmacistList({ page: 1 }));
        break;
      case all:
        dispatch(getActivePharmacistList({ page: 1 }));
        dispatch(getActivePharmacyList({ page: 1 }));
        break;
      case none:
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getInitialList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const optionsPharmacistListData = useMemo(
    () =>
      data.map((item) => {
        const { pharmacistId, name, email } = item;
        return {
          id: pharmacistId,
          label: name,
          subtitle: email,
        };
      }),
    [data],
  );

  const optionsPharmacyListData = useMemo(
    () =>
      pharmacyData.map((item) => {
        const { pharmacyId, name, email } = item;
        return {
          id: pharmacyId,
          label: name,
          subtitle: email,
        };
      }),
    [pharmacyData],
  );

  return {
    onEndReached,
    pharmacistListData: optionsPharmacistListData,
    pharmacyListData: optionsPharmacyListData,
  };
};

export default useAutoCompleteScroll;
