import { calendarDate } from '@pharmaplan/common';
import { useEffect } from 'react';
import { setAdminCalendar } from '../../reducers/mainCalendarReducer';
import useAdmin from '../useAdmin';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { adminDashboardFilterInitialState } from './useAdminDashboardFilter';

interface IUseAdminToolbarReset {
  setFilterState: (
    value: React.SetStateAction<{
      submitted: boolean;
      showFilter: boolean;
    }>
  ) => void;
  filterState: {
    submitted: boolean;
    showFilter: boolean;
  };
}

const useAdminToolbarReset = ({ filterState, setFilterState }:IUseAdminToolbarReset) => {
  const dispatch = useAppDispatch();
  const cDate = useAppSelector(calendarDate);
  const { isAdmin } = useAdmin();

  useEffect(() => {
    if (isAdmin) {
      setFilterState({ ...filterState, submitted: false });
      dispatch(setAdminCalendar(adminDashboardFilterInitialState));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cDate]);
};
export default useAdminToolbarReset;
