import React from 'react';

import { FormikValues } from 'formik';

import {
  adminList,
  approveAdmins,
  disapproveAdmins,
  getActiveAdmins,
  getInactiveAdmins,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { useNavigate } from 'react-router-dom';
import DeactivateDialog from '../../../components/Admin/PharmacistList/DeactivateDialog';
import {
  AdminListTabTypes,
  Constants,
  DeactivateModalIcon,
} from '../../../helpers/Constants';
import { setDialog } from '../../../reducers/dialogReducer';
import { useAppDispatch } from '../../useAppDispatch';
import strings from '../../../localization';
import {
  activeAdminListingRows,
  inactiveAdminListingRows,
} from '../../../components/Admin/AdminList/helper';
import { useAppSelector } from '../../useAppSelector';

const { activeAdmins, inactiveAdmins } = AdminListTabTypes;

const useAdminListTabs = (selectedTab: AdminListTabTypes) => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(adminList);
  const navigate = useNavigate();
  const goToEdit = (userId: string) =>
    () => {
      navigate(Constants.paths.admin.updateAdmin, { state: { userId } });
    };

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const { data } = list;

  const api = {
    [activeAdmins]: getActiveAdmins,
    [inactiveAdmins]: getInactiveAdmins,
  };

  const actionAdmin = (values: FormikValues) => {
    const { id, reason } = values;
    if (selectedTab === activeAdmins) {
      dispatch(disapproveAdmins({ userId: id, reason }));
    } else {
      dispatch(approveAdmins({ userId: id, reason }));
    }
  };

  const deactivateModal = (pharmacyId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              id={pharmacyId}
              iconType={DeactivateModalIcon.warning}
              confirmationMessage={
              strings.formatString(
                strings.areYouSureDeactivateFormat,
                strings.admin,
              ) as string
            }
              initialValues={{
                id: pharmacyId,
                reason: '',
              }}
              heading={
              strings.formatString(
                strings.deactivateFormat,
                strings.admin,
              ) as string
            }
              onSubmit={actionAdmin}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: true,
        }),
      );
    };

  const activateModal = (pharmacyId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              id={pharmacyId}
              iconType={DeactivateModalIcon.positiveConfirmation}
              confirmationMessage={
              strings.formatString(
                strings.areYouSureActivateFormat,
                strings.admin,
              ) as string
            }
              initialValues={{
                id: pharmacyId,
                reason: '',
              }}
              heading={
              strings.formatString(
                strings.activateFormat,
                strings.admin,
              ) as string
            }
              onSubmit={actionAdmin}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: true,
        }),
      );
    };

  const rows = {
    [inactiveAdmins]: inactiveAdminListingRows(data, timeFormat, activateModal),
    [activeAdmins]: activeAdminListingRows(
      data,
      timeFormat,
      deactivateModal,
      goToEdit,
    ),
  };

  return { api: api[selectedTab], rows: rows[selectedTab], list };
};

export default useAdminListTabs;
