import { IAdminPharmacistNotesData } from '@pharmaplan/common';
import { DynamicTableCell } from '../../../../helpers/Constants';
import strings from '../../../../localization';
import { getDayMonthDateYear } from '../../../Reports/helpers';

const { text } = DynamicTableCell;

export const adminReplacementsNotesHeaders = () =>
  [
    {
      key: '1',
      label: strings.notes,
    },
    {
      key: '2',
      label: `${strings.created} ${strings.date}`,
    },
    {
      key: '2',
      label: `${strings.updated} ${strings.date}`,
    },
  ];

export const adminReplacementNotesRows = (
  data: Array<IAdminPharmacistNotesData>,
) =>
  data?.map((item) => {
    const { pharmacistPharmacyNoteId, note, updateDate, createDate } = item;
    const parsedCreate = getDayMonthDateYear(createDate);
    const parsedUpdate = getDayMonthDateYear(updateDate ?? '');
    return {
      key: pharmacistPharmacyNoteId,
      data: [
        {
          key: '1',
          value: note,
          type: text,
        },
        {
          key: '2',
          value: parsedCreate,
          type: text,
        },
        {
          key: '3',
          value: updateDate ? parsedUpdate : '',
          type: text,
        },
      ],
    };
  });
