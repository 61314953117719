import { SxProps, Theme } from '@mui/material';

const classes = {
  container: {
    paddingLeft: '23px',
    paddingRight: '23px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
