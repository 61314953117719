import { passwordRegex } from '@pharmaplan/common';
import * as Yup from 'yup';
import strings from '../../../localization';

const Validator = () =>
  Yup.object().shape({
    password: Yup.string()
      .required(strings.requiredField)
      .matches(passwordRegex, strings.minCharacters),
    confirmPassword: Yup.string()
      .required(strings.requiredField)
      .oneOf([Yup.ref('password')], strings.passwordsMustMatch),
  });

export default Validator;
