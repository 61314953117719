import { SxProps, Theme } from '@mui/system';

const styles = {
  backContainer: {
    pt: '1px',
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    color: 'common.grey850',
    paddingTop: '12px',
  },
  container: {
    backgroundColor: 'white',
    px: '32px',
    mx: '25px',
    my: '6px',
    minHeight: '80vh',
  },
  itemContainer: {
    pt: '40px',
  },
  searchContainer: {
    mb: '3px',
  },
  borderRadiusRemove: {
    '& .MuiInputBase-root': {
      borderRadius: 0,
    },
  },
  configTitleContainer: {
    mb: '24px',
    ml: 1,
  },
  configTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: 'grey.500',
  },
  divider: {
    flex: 1,
    ml: '23px',
  },
  buttonContainer: {
    py: '32px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonSize: {
    minWidth: '160px',
  },
  provinceDropdown: {
    maxHeight: 330,
  },
  leftList: {
    minHeight: 290,
    maxHeight: 290,
  },
  rightList: {
    minHeight: 355,
    maxHeight: 355,
  },
  uploadButton: (isName?: string) =>
    ({
      mt: isName ? '6px' : '28px',
      ml: 1,
      maxWidth: '200px',
    }),
} satisfies Record<
  string,
  ((isName: string) => SxProps<Theme>) | SxProps<Theme>
>;

export default styles;
