import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link, Typography } from '@mui/material';
import { ReactComponent as PharmaPlanLogoOrg } from '@pharmaplan/common/assets/icons/imagePharmaplan.svg';
import { Link as RouterLink } from 'react-router-dom';
import { ServiceTypes, setSignedUp } from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import { langItems } from '../config';
import classes from '../style';
import CustomMenuList from '../../common/CustomMenuList';
import { ReactComponent as LangIcon } from '../../../assets/svg/langIcon.svg';
import PharmaGapLogo from '../../../assets/img/pharmagap.png';
import { Constants } from '../../../helpers/Constants';
import strings from '../../../localization';
import useLanguge from '../../../hooks/useLanguge';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

const GuestNavbar = () => {
  const { changeLang, preLoginWebLang } = useLanguge();
  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

  const dispatch = useAppDispatch();
  const { languagePadding, navbarLangTitle, telephoneText, container } = classes;

  const {
    paths: { telephone },
    telephone: telelphoneNo,
  } = Constants;

  const language = strings[preLoginWebLang as keyof typeof strings] as string;
  const handleClick = () => {
    dispatch(setSignedUp(false));
  };

  return (
    <Box>
      <AppBar component="nav" elevation={0} color="secondary" sx={classes.guestNav}>
        <Toolbar>
          <Box flexGrow={1}>
            <Link
              component={RouterLink}
              onClick={handleClick}
              to={process.env.REACT_APP_STATIC_WEB_URL as string}
            >
              {isSelfService ? (
                <img src={PharmaGapLogo} style={classes.logo} alt="logo" />
              ) : (
                <PharmaPlanLogoOrg />
              )}
            </Link>
          </Box>

          <Box sx={languagePadding}>
            <CustomMenuList
              anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
              items={langItems(changeLang)}
            >
              <LangIcon />
              <Box component="div">
                <Typography sx={navbarLangTitle}>{language}</Typography>
              </Box>
            </CustomMenuList>
          </Box>

          <Box>
            <Box component="div">
              <Link href={telephone} underline="hover" sx={telephoneText}>
                {strings.urgentNeed}
              </Link>
            </Box>
            <Box sx={container}>
              <LocalPhoneIcon color="primary" />
              <Link href={telephone} underline="hover" sx={telephoneText}>
                {telelphoneNo}
              </Link>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default GuestNavbar;
