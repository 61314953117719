import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import classes from './style';
import { Constants } from '../../../../helpers/Constants';
import genericClasses from '../../../../theme/GenericClasses';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/deleteIcon.svg';

interface IUploadDetails {
  fileType: any;
  fileName?: string;
  progress: any;
  handleDelete: () => void;
}

const defaultProps = {
  fileName: Constants.null,
};

const UploadDetails = ({
  fileType,
  fileName,
  handleDelete,
  progress,
}: IUploadDetails) =>
  (
    <div className="UploadDetails-container" data-testid="test">
      {/* File Details */}
      <Box style={classes.uploadDetailsContainer} component="div">
        <Box style={classes.uploadDetailsFileDetails} component="span">
          <img
            alt="file icon "
            style={genericClasses.iconMargin}
            src={fileType?.icon}
          />
          {fileName}
        </Box>
        <Box sx={classes.uploadProgressContainer}>
          <LinearProgress
            sx={{ height: '100%' }}
            color="primary"
            value={progress}
            variant="determinate"
          />
        </Box>
        <Box
          style={classes.uploadDetailsDeleteIconContainer}
          onClick={handleDelete}
        >
          <DeleteIcon style={classes.uploadDetailsDeleteIcon} />
        </Box>
      </Box>
    </div>
  );
UploadDetails.defaultProps = defaultProps;

export default UploadDetails;
