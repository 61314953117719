import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { DynamicTableCell } from '../../../../helpers/Constants';
import styles from '../styles';
import strings from '../../../../localization';

interface IValueMap {
  type: DynamicTableCell;
  value: string;
}

const { icon, text } = DynamicTableCell;

const ValueMap: FC<IValueMap> = ({ type, value }) => {
  const isIcon = type === icon;
  const isText = type === text;

  const mapper = () => {
    if (isIcon) {
      return <img style={styles.image} src={value} alt={strings.banner} />;
    }

    if (isText) {
      return <Typography sx={[styles.value, styles.text]}>{value}</Typography>;
    }

    return <div />;
  };

  return <>{mapper()}</>;
};

export default ValueMap;
