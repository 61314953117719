import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    minHeight: '75vh',
  },

  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between;',
    alignItems: 'center',
    paddingBlock: '10px 20px',
    paddingInline: '8px',
    paddingTop: '24px',
  },

  tableHeaderText: {
    fontSize: '18px',
    fontWeight: '600',
  },

  chipStyle: {
    backgroundColor: 'common.green300',
    color: 'common.green600',
  },

  gridLabel: {
    marginBottom: '5px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
