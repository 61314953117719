import React from 'react';

import { adminFinancialStatistics } from '@pharmaplan/common';

import ChartsContainer from '../../../common/Charts/ChartsContainer';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import ChartBuilder from '../../../common/Charts/ChartBuilder';
import { mergeDatasets } from '../../../../helpers/Functions';
import ChartLayout from '../../../common/Charts/ChartLayout';
import strings from '../../../../localization';
import {
  AverageFinancialKey,
  FinancialStatsKey,
} from '../../../../helpers/Constants';

import {
  averageFinancialStatsConfig,
  financialInsightsStatsConfig,
} from './helper';

const FinancialInsights = () => {
  const financialStats = useAppSelector(adminFinancialStatistics);
  const {
    pharmacistPayout,
    pharmacyBilling,
    pharmaplanProfit,
    hours,
    averageHourlyProfit,
    averagePharmacistRate,
    averagePharmacyRate,
  } = financialStats ?? {};

  const mergedInsightDataset = mergeDatasets(
    [pharmacyBilling, pharmacistPayout, pharmaplanProfit, hours],
    [
      FinancialStatsKey.pharmacyBilling,
      FinancialStatsKey.pharmacistPayout,
      FinancialStatsKey.grossProfit,
      FinancialStatsKey.totalBookedHours,
    ],
  );

  const mergedAverages = mergeDatasets(
    [averageHourlyProfit, averagePharmacistRate, averagePharmacyRate],
    [
      AverageFinancialKey.averageHourlyProfit,
      AverageFinancialKey.averagePharmacistRate,
      AverageFinancialKey.averagePharmacyRate,
    ],
  );

  return (
    <ChartLayout title={strings.financialStatistics}>
      <ChartsContainer title={strings.financialInsights}>
        <ChartBuilder
          config={financialInsightsStatsConfig(mergedInsightDataset)}
        />
      </ChartsContainer>
      <ChartsContainer title={strings.averageFinancialSummary}>
        <ChartBuilder config={averageFinancialStatsConfig(mergedAverages)} />
      </ChartsContainer>
    </ChartLayout>
  );
};

export default FinancialInsights;
