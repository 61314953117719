import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import CustomButton from '../../../common/CustomButton';
import CustomTextField from '../../../common/CustomTextField';
import useCreateAdminNotes from '../../../../hooks/Admin/AdminNotes/useCreateAdminNotes';
import strings from '../../../../localization';
import { AdminNotesType, TextFieldVariants } from '../../../../helpers/Constants';
import styles from '../styles';

interface IAdminCreateNote {
  id: string;
  notesType: AdminNotesType
}

const AdminCreateNote: FC<IAdminCreateNote> = ({ id, notesType }) => {
  const { formik } = useCreateAdminNotes(id, notesType);
  const { handleSubmit } = formik ?? {};

  return (
    <Box sx={styles.notesContainer}>
      <Typography sx={styles.title}>{strings.notes}</Typography>
      <CustomTextField
        label={`${strings.addNew} ${strings.notes}`}
        variant={TextFieldVariants.filled}
        placeholder={strings.enterNotesHere}
        name="note"
        multiline
        id="note"
        formik={formik}
      />
      <Box sx={styles.notesSubmitContainer}>
        <CustomButton
          label={strings.submit}
          customClass={styles.button}
          onClick={handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default AdminCreateNote;
