import React from 'react';

import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';

import { formGenerator } from '../../../helpers/formGenerator';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getDisabledSubmitFilter } from '../../../selectors/calendarSelector';

import classes from './style';

interface IReportsFilter {
  filter: any;
  formik: any;
  isDashboard?: boolean;
}

const FilterGenerator = ({ filter, formik, isDashboard }: IReportsFilter) => {
  const disableSubmit = useAppSelector(getDisabledSubmitFilter);

  return (
    <Box>
      <Grid flexWrap="nowrap" container flexDirection="row" sx={[classes.box]}>
        <Grid container flexDirection="row" flexWrap="nowrap">
          {filter?.form?.map((item: any) =>
            formGenerator(item, formik))}
          <Grid xs={1} item sx={[classes.buttonsContainer]}>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              disabled={isDashboard ? !isDashboard : !disableSubmit}
              sx={[classes.submitButton, classes.disableHover]}
            >
              {filter?.primaryButton.icon}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default FilterGenerator;
