import { Theme, SxProps } from '@mui/material';

const classes = {
  text: {
    fontSize: '14px',
    fontWeight: 400,
    paddingLeft: '8px',
    color: 'common.grey850',
  },

  strike: {
    textDecorationLine: 'line-through',
    fontSize: '14px',
    fontWeight: 400,
  },

  strikedTime: {
    marginTop: '10px',
    marginLeft: '25px',
    fontWeight: '600',
    color: 'primary.main',
    fontSize: '14px',
  },

  notesContainer: {
    marginTop: '16px',
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
