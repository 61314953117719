import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    mx: 1,
    display: 'flex',
    overflow: 'hidden',
  },
  subContainer: {
    mt: 1,
    maxHeight: 384,
    overflow: 'auto',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
    width: '100%',
    py: 1,
    borderTop: '1px solid',
    borderColor: 'common.border',
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 14,
    color: 'common.grey850',
    textAlign: 'left',
    fontWeight: '500',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
