import React from 'react';

import { Box } from '@mui/material';
import moment from 'moment';
import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import { useLocation } from 'react-router-dom';

import {
  activeDate,
  getActiveWebView,
  Language,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import { useAppSelector } from '../../../hooks/useAppSelector';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useUser from '../../../hooks/useUser';
import useMainCalendar from '../../../hooks/useMainCalendar/useMainCalendar';
import useMultipleEvents from '../../../hooks/useMultipleEvents';
import useAdmin from '../../../hooks/useAdmin';
import { getMultipleEventAdd } from '../../../selectors/calendarSelector';
import ICalendar from '../../../types/ICalendar';
import useMultipleDelete from '../../../hooks/useMultipleDelete';
import { CalendarStateKey } from '../../../helpers/Constants';

import CustomWeekView from './CustomWeekView';
import DayCell from './DayCell';
import MonthHeader from './MonthHeader';
import WeekHeader from './WeekHeader';
import EmptyBox from './EmptyBox/EmptyBox';
import { EventWrapper } from './types/IMainCalendar';
import { transformCalendarEvents } from './helpers';
import classes from './style';
import './style.scss';

const { en } = Language;
const { month, week } = CalendarStateKey;

moment.locale(en, {
  week: {
    dow: 1,
    doy: 1,
  },
});

const MainCalendar = () => {
  const { calendar: displayEvents } = useUser();
  const { isAdmin } = useAdmin();
  const { state } = useLocation();

  const { id } = state ?? {};

  const { height } = useWindowDimensions();
  const { localizer, handleOnSelectSlot, CustomEventComponent } = useMainCalendar();
  const { multipleCalendarEvents } = useMultipleEvents();

  const activeDateSelector = useAppSelector(activeDate);
  const activeView = useAppSelector(getActiveWebView);
  const isMultipleAdd = useAppSelector(getMultipleEventAdd);

  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const isAdminMultiple = isAdmin && isMultipleAdd && id;
  const { multipleAvailabilities } = useMultipleDelete();

  const { result: transformedEvents, typeStartGroup } = transformCalendarEvents(
    isAdminMultiple
      ? (multipleAvailabilities as ICalendar[])
      : (displayEvents as ICalendar[]),
    activeView,
    timeFormat,
  );

  const { result: tempTransform, typeStartGroup: multipleEventsGroup } = transformCalendarEvents(multipleCalendarEvents, activeView, timeFormat);

  const fallbackHeight = height ?? 1;

  const calendarStyle = () => {
    switch (true) {
      case activeView === week:
        return {};
      case activeView === month:
        return classes.calendar(fallbackHeight, isAdmin);
      default:
        return {};
    }
  };

  return (
    <Box component="div" sx={classes.mainCalendarContainer}>
      <Calendar
        localizer={localizer}
        popup
        showAllEvents
        key="calendar"
        longPressThreshold={10}
        selectable
        dayLayoutAlgorithm="no-overlap"
        components={{
          eventWrapper: (eventElement) =>
            CustomEventComponent(
              eventElement as unknown as EventWrapper<ICalendar>,
              { ...multipleEventsGroup, ...typeStartGroup },
            ),
          month: {
            dateHeader: DayCell,
            header: MonthHeader,
          },
          week: {
            header: WeekHeader,
          },
          // we disable the toolbar by calendar, we built our own
          toolbar: EmptyBox,
        }}
        defaultDate={new Date()}
        view={activeView}
        date={new Date(activeDateSelector)}
        onSelectSlot={handleOnSelectSlot}
        views={{ month: true, week: CustomWeekView }}
        defaultView={month}
        events={[...tempTransform, ...(transformedEvents as object[])]}
        style={calendarStyle()}
      />
    </Box>
  );
};
export default MainCalendar;
