import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    p: 1,
  },
  panelContainer: {
    ml: 1,
    p: 1,
    pb: 0,
    minWidth: 126,
  },
  panelTitle: {
    fontSize: 16,
    fontWeight: '600',
    mb: 1,
  },
  panelList: {
    maxHeight: 280,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  panelListItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'primary.main',
    mb: '2px',
    maxHeight: 30,
    minWidth: 110,
    width: '100%',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  panelListDate: {
    fontSize: 11,
    color: 'secondary.main',
    flex: 4,
    ml: 1,
  },
  cancelIcon: {
    display: 'flex',
    flex: 1,
  },
  font: {
    '.MuiPickersCalendarHeader-root': {
      width: 'auto',
    },
    '.MuiPickersCalendarHeader-label': {
      fontSize: 16,
      whiteSpace: 'nowrap',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
