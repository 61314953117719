
import forge from "node-forge"
import fs from 'fs'
import { DateTime } from "luxon";
import publicKeyHandler from "./publicKeyHandler";


export const convertBase64ToImage = (base64: string, contentType: string) => {
  return `data:${contentType};base64,${base64}`;
};


export const ArrayToDD = (data: Array<any>) =>
  data?.map((item) => ({
    key: item,
    label: item,
  }));

export const dropdownMapper = <T, key extends keyof T>(
  data: Array<T>,
  id: key,
  desc: key
) =>
  data?.map((item) => ({
    key: item[id],
    label: item[desc],
  }));

export const dropdownMinMaxMapper = <T, key extends keyof T>(
  data: Array<T>,
  desc: key
) =>
  data?.map((item) => ({
    key: { min: item?.min, max: item?.max },
    label: item[desc],
  }));

export const radioButtonMapper = <T, key extends keyof T>(
  data: Array<T>,
  id: key,
  desc: key
) =>
  data?.map((item) => ({
    value: item[id],
    label: item[desc],
  }));

  export const getPasswordStrength = (password:string) => {
    const rates = {
      1: {
        label: 1,
        regex: "",
        color: "#D50000",
      },
      2: {
        label: 2,
        regex: "",
        color: "#FF6F00",
      },
      3: {
        label: 3,
        regex: "",
        color: "#FFD600",
      },
      4: {
        label: 4,
        regex: "",
        color: "#00C853",
      },
    };
  
    
    const { length } = password;
    const uppercaseCount = (password.match(/[A-Z]/g) || []).length;
    const specialCharCount = (password.match(/[^a-zA-Z0-9]/g) || []).length;
    const digitCount = (password.match(/[0-9]/g) || []).length;

    switch (true) {
      case length >= 8
        && uppercaseCount >= 3
        && specialCharCount >= 3
        && digitCount >= 3:
        return { level: 4, bgColor: rates[4]?.color ,rates:rates};
      case length >= 8
        && uppercaseCount >= 2
        && specialCharCount >= 2
        && digitCount >= 2:
        return { level: 3, bgColor: rates[3]?.color,rates:rates };
      case length >= 8
        && uppercaseCount >= 1
        && specialCharCount >= 1
        && digitCount >= 1:
        return { level: 2, bgColor: rates[2]?.color ,rates:rates};
      case length === 0:
        return { level: 0, bgColor: "#fffff" ,rates:rates};
      default:
        return { level: 1, bgColor: rates[1]?.color ,rates:rates};
    }
  };

export const encryptPassword = (password:string) =>{
  const pkstr = '-----BEGIN PUBLIC KEY-----' + publicKeyHandler.getKey() + '-----END PUBLIC KEY-----';
  const pk = forge.pki.publicKeyFromPem(pkstr);
  return forge.util.encode64(
    pk.encrypt(password, 'RSA-OAEP', {
      md: forge.md.sha1.create(),
      mgf1: {
        md: forge.md.sha1.create()
      }
    })
  )
}

export const uniqueHash = () => {
  var randomBytes = forge.random.getBytesSync(32);
  const sha2 = forge.md.sha256.create();
  sha2.update(randomBytes);
  return sha2.digest().toHex()
}

export const isLessThanFifty = (level: number) => level * 25 < 50;