import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = {
  container: {
    mt: '10px',
  },
  serviceContainer: {
    pt: '15px',
  },
  buttonContainer: {
    paddingTop: '16px',
  },
  button: {
    backgroundColor: 'common.lightGreen',
    color: 'primary.main',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
