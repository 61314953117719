import { SxProps, Theme } from '@mui/system';

const styles = {
  capacityMenuStyles: {
    maxHeight: 430,
  },
  softwareMenuStyles: {
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
