import React, { FC } from 'react';

import { Button, Grid } from '@mui/material';

import dashboardFilter from '@pharmaplan/common/assets/icons/searchIcon2.svg';
import resetDashboardFilter from '@pharmaplan/common/assets/icons/clearReport.svg';

import { Constants } from '../../../helpers/Constants';
import strings from '../../../localization';

import styles from './styles';

const { contained } = Constants.variant;

interface IFilterSubmitButtons {
  disableSubmit?: boolean;
  handleReset: () => void;
}

const FilterSubmitButtons: FC<IFilterSubmitButtons> = ({
  disableSubmit = true,
  handleReset,
}) =>
  (
    <Grid item sx={styles.buttonContainer}>
      <Button
        disabled={!disableSubmit}
        type="submit"
        sx={styles.buttons}
        variant={contained}
      >
        <img src={dashboardFilter} alt={strings.submit} />
      </Button>
      <Button sx={styles.buttons} onClick={handleReset} variant={contained}>
        <img src={resetDashboardFilter} alt={strings.reset} />
      </Button>
    </Grid>
  );

export default FilterSubmitButtons;
