import { useEffect } from 'react';

import { isLoggedIn } from '@pharmaplan/common/helpers/LogoutHandler';
import {
  chatActions,
  resetStatus,
  successSelector,
  userActions,
} from '@pharmaplan/common';
import { Constants } from '../helpers/Constants';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import useAdmin from './useAdmin';

const logoutAction = userActions.logout;

const useLogoutCheck = () => {
  const dispatch = useAppDispatch();
  const logoutSuccess = useAppSelector((state) =>
    successSelector([logoutAction], state));
  const { isAdmin } = useAdmin();
  const prelogoutSuccess = useAppSelector((state) =>
    successSelector([chatActions.chatPreLogout], state));

  const preLogoutOnlyAdmin = prelogoutSuccess || !isAdmin;

  useEffect(() => {
    if (logoutSuccess && preLogoutOnlyAdmin) {
      dispatch(resetStatus([logoutAction]));
      window.location.replace(Constants.paths.login);
      localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutSuccess, preLogoutOnlyAdmin]);

  useEffect(() => {
    const handleInvalidToken = (e: StorageEvent) => {
      if (e.key === isLoggedIn) {
        window.location.reload();
      }
    };
    window.addEventListener('storage', handleInvalidToken);

    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, []);
};

export default useLogoutCheck;
