import React, { useEffect } from 'react';
import {
  IAdminNotesResponse,
  IAdminPharmacyNotesResponse,
  adminAllNotes,
  adminGetAllNotes,
  adminGetAllPharmacyNotes,
  adminPharmacyAllNotes,
  getPharmacistDetails,
  getPharmacyDetails,
} from '@pharmaplan/common';
import {
  adminNoteHeader,
  notesRowConfig,
  pharmacyNotesRowConfig,
} from '../../../components/Admin/AdminNotes/helper';
import { IDynamicTableObject } from '../../../components/DynamicTable/types';
import { AdminNotesType, ScreenTypes } from '../../../helpers/Constants';
import { useAppSelector } from '../../useAppSelector';
import { useAppDispatch } from '../../useAppDispatch';
import { renderScreen, setFooterShow } from '../../../actions/drawerActions';
import { setDialog } from '../../../reducers/dialogReducer';
import strings from '../../../localization';
import AdminUpdateNote from '../../../components/Admin/AdminNotes/AdminCreateUpdateNotes/AdminUpdateNote';

const { pharmacist, pharmacy } = AdminNotesType;

const useAdminNotes = (id: string, notesType: AdminNotesType) => {
  const dispatch = useAppDispatch();

  const pharmacyAllNotes = useAppSelector(adminPharmacyAllNotes);
  const pharmacistAllNotes = useAppSelector(adminAllNotes);

  const allNotes = {
    [pharmacist]: pharmacistAllNotes,
    [pharmacy]: pharmacyAllNotes,
  };

  const isPharmacist = notesType === pharmacist;

  const updateNote = (noteId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <AdminUpdateNote
              id={id}
              noteId={noteId}
              notesType={notesType}
            />
          ),
          showCloseButton: true,
          heading: {
            title: strings.updateNote,
          },
        }),
      );
    };

  const rowsConfig = {
    [pharmacist]: notesRowConfig(allNotes[notesType] as IAdminNotesResponse[], updateNote),
    [pharmacy]: pharmacyNotesRowConfig(allNotes[notesType] as IAdminPharmacyNotesResponse[], updateNote),
  };

  const table: IDynamicTableObject = {
    headerBar: [],
    headers: adminNoteHeader(),
    rows: rowsConfig[notesType],
  };

  const ratingGoBack = () => {
    dispatch(
      renderScreen({
        screenType: ScreenTypes.pharmacistList,
        screenNumber: 1,
        pharmacistId: id,
      }),
    );
  };

  const initCall = () => {
    if (isPharmacist) {
      dispatch(getPharmacistDetails(id));
      dispatch(adminGetAllNotes(id));
    } else {
      dispatch(getPharmacyDetails(id));
      dispatch(adminGetAllPharmacyNotes(id));
    }
  };

  useEffect(() => {
    initCall();
    dispatch(setFooterShow(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ratingGoBack, table, data: allNotes[notesType], isPharmacist };
};

export default useAdminNotes;
