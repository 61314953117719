import React, { useEffect, useRef } from 'react';
import {
  adminActions,
  adminIsAvaiabailityRequested,
  preSubmitDeleteAvailabilities,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import { setDialog } from '../../reducers/dialogReducer';
import useWorkshiftDelete from './useWorkshiftDelete';
import strings from '../../localization';
import { DeactivateModalIcon } from '../../helpers/Constants';
import CancellationDateTime from '../../components/Admin/PharmacyList/ListOfWorkshifts/CancellationDateTime';
import DeactivateDialog from '../../components/Admin/PharmacistList/DeactivateDialog';

const { preSubmitDeleteAvailabilities: preSubmitAction } = adminActions;
const successAction = [preSubmitAction];

const useDeleteAvailability = () => {
  const dispatch = useAppDispatch();
  const { showDeleteAvailability, deleteAvailability } = useWorkshiftDelete();

  const apiParams = useRef<{ availabilityId: string; dateTime: string }>({
    availabilityId: '',
    dateTime: '',
  });

  const preSubmitDelete = useAppSelector(adminIsAvaiabailityRequested);
  const success = useAppSelector((state) =>
    successSelector(successAction, state));

  const { isAvaiabailityRequested } = preSubmitDelete ?? {};

  const showDeletePreSubmit = (availabilityId: string, dateTime: string) => {
    apiParams.current = { availabilityId, dateTime };

    dispatch(preSubmitDeleteAvailabilities(availabilityId));
  };

  const showDialog = () => {
    if (isAvaiabailityRequested) {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              onSubmit={deleteAvailability}
              confirmationMessage={strings.availabilityAlreadyRequested}
              id={apiParams.current.availabilityId}
              initialValues={{
                availabilityId: apiParams.current.availabilityId,
                notes: '',
              }}
              heading={strings.deleteAvailability}
              iconType={DeactivateModalIcon.warning}
              validator={null}
              name="notes"
              customComponent={
                <CancellationDateTime date={apiParams.current.dateTime} />
              }
              primaryButtonLabel={strings.continue}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: true,
        }),
      );
    } else {
      showDeleteAvailability(
        apiParams.current.availabilityId,
        apiParams.current.dateTime,
      );
    }
  };

  useEffect(() => {
    if (success) {
      showDialog();
      dispatch(resetStatus(successAction));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return { showDeletePreSubmit };
};

export default useDeleteAvailability;
