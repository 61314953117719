import { DateTime } from 'luxon';
import { TimeFormat } from '@pharmaplan/common';
import { DateFormats } from '../../../helpers/Constants';

// 13:00
export const getHourMinute = (date: string, timeFormat: TimeFormat) =>
  (timeFormat === TimeFormat.twelveHours
    ? DateTime.fromISO(date, { setZone: true }).toFormat(DateFormats.twelveHours)
    : DateTime.fromISO(date, { setZone: true }).toFormat(DateFormats.hourMinutes));

export const getMapDate = (date: string) => {
  const timeString = DateTime.fromISO(date).toFormat(DateFormats.fullDate);
  return timeString;
};

export const getHoursDiff = (startDate: string, endDate: string) => {
  const diff = DateTime.fromISO(endDate)
    .diff(DateTime.fromISO(startDate), 'hours')
    .toObject();
  return Math.abs(diff.hours ?? 0);
};

export const getCompleteDate = (date: string) => {
  const format = DateFormats.completeDate;
  return DateTime.fromISO(date).toFormat(format);
};

export const getTimeWithDiff = (startDate: string, endDate: string, timeFormat: TimeFormat) => {
  const timeString = `${getHourMinute(
    startDate,
    timeFormat,
  )} - ${getHourMinute(endDate, timeFormat)} (${getHoursDiff(
    startDate,
    endDate,
  )} Hrs)`;
  return timeString;
};
