import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import classes from './style';
import WithSkeleton from '../../../common/WithSkeleton';
import { Constants } from '../../../../helpers/Constants';

interface IProfileContentConfig {
  key: number;
  label: string;
  value: string | number | React.ReactNode;
}

interface IProfileContent {
  content?: Array<IProfileContentConfig>;
  customComponent?: React.ReactNode;
  successAction: string;
}

const ProfileContent: FC<IProfileContent> = ({
  content,
  successAction,
  customComponent,
}) => {
  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: Constants.variant.text,
  })(Typography);

  return (
    <Box component="div">
      {customComponent
        ?? content?.map((item) => {
          const { key, label, value } = item ?? {};

          return (
            <Box key={key} sx={classes.container}>
              <Box sx={classes.labelContainer}>
                <Typography sx={classes.labelFont}>
                  {label}
                </Typography>
              </Box>
              <Box sx={classes.valueContainer}>
                <SkeletonTypography sx={classes.valueFont}>
                  {value}
                </SkeletonTypography>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default ProfileContent;
