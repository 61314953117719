import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    overflow: 'unset',
    width: '500px',
    minHeight: { xs: '100vh', x: '100vh' },
    backgroundColor: 'common.lightBackgroundColor',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
  },
  header: {
    flex: 1,
    maxHeight: 55,
    zIndex: 99,
  },
  content: {
    flex: 5,
    overflow: 'auto',
  },
  innerContent: {
    overflow: 'hidden',
    overflowY: 'auto',
    paddingBottom: '5px',
  },
  footer: {
    width: '100%',
    backgroundColor: 'common.lightBackgroundColor',
    zIndex: '999',
    boxShadow: '0px -2px 10px 0px rgba(0, 0, 0, 0.10)',
    color: 'common.border',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
