import React, { FC, memo, useRef } from 'react';

import { Box } from '@mui/material';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import {
  DashboardType,
  getActiveDashboard,
  setActiveDashboard,
} from '@pharmaplan/common';

import { ReactComponent as Calendar } from '../../../../assets/svg/calendar-grey.svg';
import { ReactComponent as Map } from '../../../../assets/svg/map-grey.svg';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import useAdmin from '../../../../hooks/useAdmin';
import { Constants } from '../../../../helpers/Constants';

import useStyles from './style';

interface ICalendarMapToggle {
  disabled?: boolean;
}

const CalendarMapToggle: FC<ICalendarMapToggle> = ({ disabled }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAdmin } = useAdmin();
  const iconRef = useRef(null);
  const classes = useStyles();

  const {
    calendarMapIconContActive,
    calendarMapToggleIconCont,
    calendarMapToggle,
    calendarMapIconsActive,
    calendarMapIcons,
  } = classes;

  const activeDash = useAppSelector(getActiveDashboard);

  const { map, calendar } = DashboardType;
  const {
    dashboard,
    admin: { dashboard: adminDashboard },
  } = Constants.paths;

  const basePath = isAdmin ? adminDashboard : dashboard;
  const toggleIcons = [
    {
      id: 'calendar',
      Icon: Calendar,
      iconEnum: calendar,
    },
    {
      id: 'map',
      Icon: Map,
      iconEnum: map,
    },
  ];

  return (
    <Box display="flex" alignItems="center" className={calendarMapToggle}>
      {toggleIcons.map((i) => {
        const { iconEnum, Icon, id } = i;
        const isActive = activeDash === iconEnum;

        return (
          <Box
            key={iconEnum}
            component="button"
            className={clsx(
              calendarMapToggleIconCont,
              isActive && calendarMapIconContActive,
            )}
            disabled={disabled}
          >
            <Icon
              id={id}
              ref={iconRef}
              className={clsx(
                calendarMapIcons,
                isActive && calendarMapIconsActive,
              )}
              onClick={() => {
                navigate(`${basePath}/${iconEnum}`);
                dispatch(setActiveDashboard(iconEnum));
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(CalendarMapToggle);
