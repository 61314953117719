import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { selectContent, selectOpen } from '../selectors/drawerSelector';
import { resetDrawer, setOpen } from '../actions/drawerActions';

const useDrawer = () => {
  const isOpen = useAppSelector(selectOpen);
  const content = useAppSelector(selectContent);

  const dispatch = useAppDispatch();

  const openDrawer = () => {
    dispatch(setOpen(true));
  };

  const closeDrawer = () => {
    dispatch(resetDrawer());
  };

  return { content, isOpen, openDrawer, closeDrawer };
};

export default useDrawer;
