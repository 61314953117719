import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    ml: '8px',
  },
  autocomplete: {
    '& .MuiFormControl-root': {
      backgroundColor: 'secondary.main',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
