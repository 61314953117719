import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const styles = {
  container: (variant) =>
    ({
      '&:hover': {
        opacity: 0.9,
        backgroundColor: `admin.${variant}Light`,
        transform: 'scale(1.03)',
      },
    }),
  legendContainerBg: (variant: string) =>
    ({
      backgroundColor: `admin.${variant}Light`,
      borderRadius: '4px',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    }),

  legendTitle: {
    fontSize: '0.875rem',
    color: ThemeConstants.common.grey850,
    fontWeight: 500,
  },

  legendTotalHrs: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },

  legendTotalCount: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    backgroundColor: 'secondary.main',
    borderRadius: '50%',
  },

  totalHrsCountColor: (variant: string) =>
    ({
      color: `admin.${variant}`,
    }),

  totalCountContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  startContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  columnContainer: {
    flexDirection: 'column',
  },
} satisfies Record<string, SxProps<any>>;

export default styles;
