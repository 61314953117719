import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import strings from '../../../../localization';
import classes from './styles';

interface IInfoNote {
  pharmaPlanFees: string;
}

const InfoNote: FC<IInfoNote> = ({ pharmaPlanFees }) =>
  (
    <Box sx={classes.container}>
      <Typography sx={classes.infoText}>
        {strings.formatString(strings.pharmaplanCharges, pharmaPlanFees)}
      </Typography>
      <Typography sx={classes.infoText}>{strings.applicableTaxes}</Typography>
    </Box>
  );

export default InfoNote;
