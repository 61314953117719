import { SxProps, Theme } from '@mui/system';

const styles = {
  mainContainer: {
    display: 'flex',
    mb: '32px',
  },
  container: {
    backgroundColor: 'secondary.main',
    padding: '15px 24px',
    borderRadius: '16px',
    mr: '15px',
    width: 214,
    cursor: 'pointer',
    maxWidth: '100%',
  },
  label: {
    fontWeight: '500',
    fontSize: 12,
    color: 'rgba(51,51,51,0.5)',
  },
  data: {
    fontWeight: '600',
    fontSize: 24,
    color: 'common.grey850',
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: '3px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '7px',
    backgroundColor: 'common.iconBackground',
    height: '100%',
    borderRadius: '8px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
