import { useNavigate } from 'react-router-dom';

import { AdminLegendVariant } from '@pharmaplan/common';

import { renderScreen, setOpen } from '../../actions/drawerActions';
import { Constants, ScreenTypes, SecondaryAdminNavigation } from '../../helpers/Constants';
import { getISO } from '../../helpers/Functions';

import { useAppDispatch } from '../useAppDispatch';

interface IAdminDrawer {
  startDate?: Date;
  type: AdminLegendVariant
  isoDate?: string;
}
const useAdminNavigation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const openAdminDrawer = ({ startDate, type, isoDate }: IAdminDrawer) => {
    const parsedDate = isoDate || getISO(startDate ?? new Date());
    switch (type) {
      case AdminLegendVariant.posted:
        dispatch(renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.availableWorkshift,
          date: parsedDate,
        }));
        break;
      case AdminLegendVariant.requested:
        dispatch(renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.requestedList,
          date: parsedDate,
        }));
        break;
      case AdminLegendVariant.booked:
        dispatch(renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.bookingList,
          date: parsedDate,
        }));
        break;
      default:
        break;
    }
    dispatch(setOpen(true));
  };

  const secondaryNavigation = (navType: SecondaryAdminNavigation) => {
    if (navType === SecondaryAdminNavigation.expressBooking) {
      navigate(Constants.paths.admin.adminCalendarDashboard);
      dispatch(renderScreen({
        screenNumber: 5,
        screenType: ScreenTypes.availableWorkshift,
      }));
      dispatch(setOpen(true));
    }
  };

  return { openAdminDrawer, secondaryNavigation };
};

export default useAdminNavigation;
