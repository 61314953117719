import React, { FC, useEffect } from 'react';

import { East } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import {
  impersonatePharmacy,
  pharmacyAdminActions,
  resetStatus,
  setUserType,
  successSelector,
  TypeOfUser,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { Constants } from '../../../../../helpers/Constants';
import CustomButton from '../../../../common/CustomButton';
import strings from '../../../../../localization';

import styles from './styles';
import { useAppSelector } from '../../../../../hooks/useAppSelector';

interface ITitleCard {
  pharmacyName: string;
  pharmacyId: string;
  licenseNo: string;
  location: string;
  date: string;
}

const TitleCard: FC<ITitleCard> = ({
  pharmacyName,
  pharmacyId,
  licenseNo,
  location,
  date,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const impersonateSuccess = useAppSelector((state) =>
    successSelector([pharmacyAdminActions.impersonatePharmacy], state));

  const impersonate = () => {
    dispatch(setUserType(TypeOfUser.pharmacy));
    navigate(Constants.paths.calendarDashboard, { replace: true });
    window.scrollTo(0, 0);
  };

  const handleClick = () => {
    dispatch(impersonatePharmacy(pharmacyId));
  };

  useEffect(() => {
    if (impersonateSuccess) {
      impersonate();
      dispatch(resetStatus([pharmacyAdminActions.impersonatePharmacy]));
    }
  }, [impersonateSuccess]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>{pharmacyName}</Typography>

        {/* DATA */}
        <Box sx={styles.detailContainer}>
          <Typography sx={styles.subTitle}>{location}</Typography>
          <Divider sx={styles.divider} />
          <Typography sx={styles.subTitle}>{licenseNo}</Typography>
          <Divider sx={styles.divider} />
          <Typography sx={styles.subTitle}>{date}</Typography>
        </Box>
      </Box>

      <CustomButton
        label={strings.enterPharmacy}
        onClick={handleClick}
        variant={Constants.variant.outlined}
        endIcon={<East />}
      />
    </Box>
  );
};

export default TitleCard;
