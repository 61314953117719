import * as Yup from 'yup';
import { phoneRegex } from '../../../../helpers/Constants';
import strings from '../../../../localization';
import { emailValidation } from '../../../Login/LoginForm/Validator';
import { formikRequired } from '../../SoftwareList/validator';

export const Validator = () =>
  Yup.object().shape({
    name: formikRequired,
    email: emailValidation(),
    phone: Yup.string()
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
  });
