/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Step, StepButton, Stepper } from '@mui/material';
import { Box } from '@mui/system';
import { getTypeOfUser, ToUserTypings, TypeOfUser } from '@pharmaplan/common';
import React from 'react';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { pharmacistSteps, pharmacySteps } from '../helper';
import classes from './styles';

interface ISignupStepper {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const SignupStepper = ({ step, setStep }: ISignupStepper) => {
  const userType = useAppSelector(getTypeOfUser);

  const stepConfig = ToUserTypings({
    [TypeOfUser.pharmacy]: pharmacySteps(),
    [TypeOfUser.pharmacist]: pharmacistSteps(),
  });

  const handleStep = (index: number) => {
    setStep(index);
  };

  return (
    <Box sx={classes.container}>
      <Stepper activeStep={step}>
        {Object.keys(stepConfig[userType]).map((item, index) =>
          (
            <Step key={item}>
              <StepButton
                color="primary"
                onClick={() =>
                  handleStep(index)}
              >
                {stepConfig[userType][item].label}
              </StepButton>
            </Step>
          ))}
      </Stepper>
    </Box>
  );
};

export default SignupStepper;
