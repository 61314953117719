import React, { FC } from 'react';

import {
  Box,
  Divider,
  Grid,
  SxProps,
  Typography,
} from '@mui/material';

import { adminActions } from '@pharmaplan/common';

import { ReactComponent as EditIcon } from '../../../../assets/svg/edit.svg';
import strings from '../../../../localization';
import DisplayChip from '../../../common/DisplayChip';

import WithSkeleton from '../../../common/WithSkeleton';
import { SkeletonVariants } from '../../../../helpers/Constants';

import MaskedText from './MaskedText/MaskedText';
import classes from './style';

interface ISettingsConfigContent {
  key: number;
  label: string;
  value: string | number;
  displayChip?: boolean;
  chipStyle?: SxProps;
  maskText?: boolean;
}

interface ISettingsConfig {
  key: number;
  title: string;
  content: Array<ISettingsConfigContent>;
}

interface ISettingsInfo {
  config: Array<ISettingsConfig>;
  onClick: () => void;
}

const spacing = 2;
const columns = { xs: 12, sm: 12, md: 12 };

const successAction = adminActions.getAdminConfiguration;

export const SkeletonTypography = WithSkeleton({
  successAction,
  variant: SkeletonVariants.text,
})(Typography);

const SettingsInfo: FC<ISettingsInfo> = ({ config, onClick }) => {
  const renderDisplayChip = (
    displayChip: boolean,
    maskText: boolean,
    value: string | number,
    chipStyle: SxProps,
  ) => {
    if (displayChip) {
      return (
        <DisplayChip
          style={chipStyle}
          value={value}
          successAction={successAction}
        />
      );
    }

    if (maskText) {
      return (
        <MaskedText value={value as string} />
      );
    }

    return (
      <SkeletonTypography sx={classes.valueStyle}>{value}</SkeletonTypography>
    );
  };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.titleContainer}>
        <Box sx={classes.titleSubContainer}>
          <Box component="div" sx={classes.divider} />
          <Typography sx={classes.mainTitle}>{strings.settingsInfo}</Typography>
        </Box>

        <Box component="div" sx={classes.editIcon} onClick={onClick}>
          <EditIcon />
        </Box>
      </Box>

      <Grid
        container
        spacing={spacing}
        columns={columns}
        sx={classes.container}
      >
        {config.map((item) => {
          const { key, title, content } = item ?? {};

          return (
            <Grid item xs={12} key={key} sx={classes.contentContainer}>
              <Box sx={classes.contentTitleContainer}>
                <Typography sx={classes.titleFont}>{title}</Typography>

                <Divider sx={classes.titleDivider} />
              </Box>

              <Grid container spacing={spacing}>
                {content.map((contentItem) => {
                  const {
                    displayChip = false,
                    maskText = false,
                    label,
                    value,
                    key: contentKey,
                    chipStyle,
                  } = contentItem ?? {};

                  return (
                    <Grid item xs={6} sm={4} md={3} key={contentKey}>
                      <Typography sx={classes.labelStyle}>{label}</Typography>
                      {renderDisplayChip(
                        displayChip,
                        maskText,
                        value,
                        chipStyle ?? {},
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SettingsInfo;
