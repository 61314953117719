import React, { FC } from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { FormikValues, useFormik } from 'formik';

import { ReactComponent as SuccessIcon } from '@pharmaplan/common/assets/icons/successIcon.svg';
import { useLocation } from 'react-router-dom';
import { ReactComponent as WarningYellowIcon } from '../../../../../assets/svg/yellowWarning.svg';

import strings from '../../../../../localization';
import CustomTextField from '../../../../common/CustomTextField';
import CustomButton from '../../../../common/CustomButton';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { resetDialog } from '../../../../../reducers/dialogReducer';
import genericClasses from '../../../../../theme/GenericClasses';
import {
  BroadcastGroupActionType,
  Constants,
  TextFieldVariants,
} from '../../../../../helpers/Constants';

import styles from '../styles';

const { filled } = TextFieldVariants;
const { text } = Constants.variant;

interface ISaveBroadcastDialog {
  handleSubmit: (values: FormikValues) => void;
  noOfSelectedUsers: number;
  type: BroadcastGroupActionType;
}

const { create, edit } = BroadcastGroupActionType;

const SaveBroadcastDialog: FC<ISaveBroadcastDialog> = ({
  handleSubmit,
  noOfSelectedUsers,
  type,
}) => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { groupName } = state ?? {};

  const formik = useFormik({
    initialValues: {
      groupName: groupName || '',
    },
    onSubmit: handleSubmit,
  });

  const handleCancelClick = () => {
    dispatch(resetDialog());
  };

  const icon = {
    [create]: <SuccessIcon />,
    [edit]: <WarningYellowIcon />,
  };

  const title = {
    [create]: strings.saveGroup,
    [edit]: strings.editGroup,
  };

  const label = {
    [create]: strings.formatString(
      strings.enterCred,
      strings.groupName,
    ) as string,
    [edit]: strings.updateGroupName,
  };

  const isEdit = type === edit;

  return (
    <Box sx={styles.saveContainer}>
      <Box sx={styles.headerContainer}>
        <Box sx={styles.iconContainer}>{icon[type]}</Box>
        <Typography sx={styles.title}>{title[type]}</Typography>
        {isEdit && (
          <Typography sx={styles.desc}>
            {strings.clickingYesWillOverride}
          </Typography>
        )}
      </Box>

      <Box sx={styles.fieldContainer}>
        <CustomTextField
          formik={formik}
          name="groupName"
          id="groupName"
          label={label[type]}
          placeholder=" "
          variant={filled}
          customTextFieldStyle={genericClasses.customFilledInput}
        />
        <Typography sx={styles.field}>
          {strings.selectedUsers}
          :
          {' '}
          {noOfSelectedUsers}
        </Typography>
      </Box>
      <Box sx={styles.saveButtonsContainer}>
        <CustomButton
          label={strings.cancel}
          variant={text}
          onClick={handleCancelClick}
          customClass={styles.cancelContainer}
          customButtonStyle={styles.button}
        />
        <CustomButton
          customButtonStyle={styles.button}
          label={strings.yes}
          onClick={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default SaveBroadcastDialog;
