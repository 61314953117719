import React from 'react';

import { Route } from 'react-router-dom';

import LogoutHandler from '@pharmaplan/common/helpers/LogoutHandler';
import { isPharmacyAdminLoginSelector } from '@pharmaplan/common';

import PharmacyAdminHome from '../components/PharmacyAdmin/PharmacyAdminHome';
import PharmacyAdminListings from '../components/PharmacyAdmin/PharmacyAdminListings';
import { useAppSelector } from '../hooks/useAppSelector';
import { Constants } from '../helpers/Constants';

import ProtectedRoute from './ProtectedRoute';
import PharmacyAdminProfile from '../components/PharmacyAdmin/Profile/PharmacyAdminProfile';
import ChangePassword from '../components/ChangePassword';
import Help from '../components/Help';

const { home, profile, changePassword, help } = Constants.paths.pharmacyAdmin;

const PharmacyAdminRouter = () => {
  const user = !!LogoutHandler.isUserLoggedIn();
  const isPharmacyAdminLogin = useAppSelector(isPharmacyAdminLoginSelector);

  const isAuthenticated = user && isPharmacyAdminLogin;

  return (
    <Route
      path={home}
      element={<ProtectedRoute authenticated={isAuthenticated} />}
    >
      <Route path={home} element={<PharmacyAdminHome />}>
        <Route path={home} element={<PharmacyAdminListings />} />
        <Route path={profile} element={<PharmacyAdminProfile />} />
        <Route path={changePassword} element={<ChangePassword />} />
        <Route path={help} element={<Help isPharmacyAdmin />} />
      </Route>
    </Route>
  );
};
export default PharmacyAdminRouter;
