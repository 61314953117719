import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Typography } from '@mui/material';

import styles from './styles';

interface ICustomTableBack {
  backLabel: string;
  onClick?: () => void;
}

const CustomTableBack: FC<ICustomTableBack> = ({ backLabel, onClick }) => {
  const navigate = useNavigate();

  const backNavigate = () => {
    navigate(-1);
  };

  return (
    <IconButton
      sx={styles.backButtonContainer}
      onClick={onClick || backNavigate}
    >
      <BackIcon sx={styles.backIcon} />
      <Typography sx={styles.backButtonText}>{backLabel}</Typography>
    </IconButton>
  );
};

export default CustomTableBack;
