import React, { FC, useEffect, useMemo } from 'react';

import { Box, Stack } from '@mui/system';
import { FormikValues, useFormik } from 'formik';
import { DateTime } from 'luxon';

import {
  adminActions,
  adminCreateAvailabilities,
  getRecurrence,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';

import {
  InvertedRepeatMap,
  serverDateKey,
} from '../../../../helpers/Constants';

import { getZeroHourDate } from '../../../../helpers/Functions';
import {
  makeReqParams,
  setHour,
} from '../../../../helpers/workshift/functions';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import strings from '../../../../localization';
import CustomCheckbox from '../../../common/CustomCheckbox';
import CustomDatePicker from '../../../common/CustomDatePicker';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { showSuccess } from '../../Profile/Pharmacist/PharmacistViewProfile/helper';
import CustomTextField from '../../../common/CustomTextField';
import genericClasses from '../../../../theme/GenericClasses';
import { resetDrawer, setFooter } from '../../../../actions/drawerActions';
import { getDisableAvailability } from '../../../../selectors/calendarSelector';
import AddUpdateEvents from '../../../common/AddUpdateEvents';
import useRecurrence from '../../../../hooks/useRecurrence';

import styles from './styles';
import Validator from './validator';

interface IAdminCreateAvailability {
  pharmacistId: string;
  pharmacistName: string;
}

const successAction = adminActions.createAvailabilities;

const AdminCreateAvailability: FC<IAdminCreateAvailability> = ({
  pharmacistId,
  pharmacistName,
}) => {
  const dispatch = useAppDispatch();
  const disabledButton = useAppSelector(getDisableAvailability);
  const luxonDate = useMemo(() =>
    DateTime.now(), []);

  const createSuccess = useAppSelector((state) =>
    successSelector([successAction], state));

  const recurrence = useAppSelector(getRecurrence);

  const handleSubmit = (values: FormikValues) => {
    const { notes } = values ?? {};

    dispatch(
      adminCreateAvailabilities({
        pharmacistId,
        model: {
          ...makeReqParams(values, recurrence),
          notes,
        },
      }),
    );
  };

  useEffect(() => {
    if (createSuccess) {
      dispatch(resetStatus([successAction]));
      dispatch(resetDrawer());
      showSuccess(dispatch, strings.availabilityCreatedSuccessfully);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);

  const formik = useFormik({
    initialValues: {
      pharmacistName,
      startTime: null,
      endTime: null,
      selectedDate: luxonDate,
      allday: false,
      recurrence: InvertedRepeatMap[recurrence.repeatEvery],
    },
    validationSchema: Validator(),
    onSubmit: handleSubmit,
  });

  const { allday } = formik.values ?? {};

  const serverToday = getZeroHourDate(
    sessionStorage.getItem(serverDateKey) ?? '',
  );

  const handleAllDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('allday', e.target.checked);
    if (e.target.checked) {
      formik.setFieldValue('startTime', setHour(luxonDate, 8));
      formik.setFieldValue('endTime', setHour(luxonDate, 22));
    }
  };

  useRecurrence(formik);

  useEffect(() => {
    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.submit,
            disabled: !disabledButton,
            onClick: formik.handleSubmit,
          },
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledButton]);

  return (
    <Stack sx={styles.container}>
      <CustomTextField
        label={strings.pharmacist}
        formik={formik}
        name="pharmacistName"
        id="pharmacistName"
        disabled
      />
      <CustomDatePicker
        readOnly
        minDate={serverToday}
        formik={formik}
        name="selectedDate"
        label={strings.selectedDate}
        error=""
      />
      <Box sx={styles.checkboxContainer}>
        <CustomCheckbox
          id="id"
          value={allday}
          name="allDay"
          handleChange={handleAllDay}
          label={strings.allDay}
        />
      </Box>

      <AddUpdateEvents
        formik={formik}
        isSeries
        setUserWorkTimings={() =>
          null}
      />

      <CustomTextField
        variant="outlined"
        formik={formik}
        id="notes"
        placeholder=" "
        label={strings.notes}
        name="notes"
        multiline
        maxLength={200}
        customClass={genericClasses.borderNoneTextField}
      />
    </Stack>
  );
};

export default AdminCreateAvailability;
