import { useNavigate } from 'react-router-dom';

import {
  AddEventType,
  AvailabilityTypes,
  CalendarStateKey,
  DashboardType,
  getTypeOfUser,
  setActiveDashboard,
  setActiveWebView,
  TypeOfUser,
} from '@pharmaplan/common';

import { useAppDispatch } from './useAppDispatch';
import { Constants } from '../helpers/Constants';
import {
  getEnableAddVacations,
  getEnabledAddEvents,
  getTempVacations,
} from '../selectors/calendarSelector';
import { useAppSelector } from './useAppSelector';
import {
  enableAddCalendarEvent,
  enableAddVacations,
} from '../reducers/mainCalendarReducer';
import strings from '../localization';

const { calendar } = DashboardType;
const { month } = CalendarStateKey;

const {
  paths: { calendarDashboard },
} = Constants;

const useAddEvent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isVacations = useAppSelector(getEnableAddVacations);
  const isAvailabilities = useAppSelector(getEnabledAddEvents);
  const userType = useAppSelector(getTypeOfUser);
  const tempVacations = useAppSelector(getTempVacations);

  const isPharmacist = userType === TypeOfUser.pharmacist;
  const isAddEvents = isVacations || isAvailabilities;
  const events = tempVacations;

  const typeofAvailability = isVacations
    ? AvailabilityTypes.newVacation
    : AvailabilityTypes.tempAvailability;

  const setActiveView = () => {
    dispatch(setActiveDashboard(calendar));
    dispatch(setActiveWebView(month));
    navigate(calendarDashboard);
  };

  const disableAddEvents = () => {
    dispatch(enableAddCalendarEvent(false));
    dispatch(enableAddVacations(false));
  };

  const dashboardTitle = () => {
    const secondaryTitle = isAvailabilities
      ? strings.availabilities
      : strings.vacations;

    if (isPharmacist) {
      return strings.formatString(strings.createEvent, secondaryTitle);
    }

    return strings.manageWorkshifts;
  };

  const gotoAddEvent = (addEventType: AddEventType) =>
    () => {
      setActiveView();
      if (addEventType === AddEventType.Availabilities) {
        dispatch(enableAddCalendarEvent(!isAvailabilities));
        dispatch(enableAddVacations(false));
        return;
      }

      if (addEventType === AddEventType.Vacations) {
        dispatch(enableAddVacations(!isVacations));
        dispatch(enableAddCalendarEvent(false));
      }
    };

  return {
    setActiveView,
    gotoAddEvent,
    disableAddEvents,
    isAddEvents,
    dashboardTitle,
    isVacations,
    isAvailabilities,
    events,
    typeofAvailability,
  };
};

export default useAddEvent;
