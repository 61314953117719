import * as Yup from 'yup';
import strings from '../../../localization';

export const formikRequired = Yup.string().required(strings.requiredField);

const Validator = () =>
  Yup.object().shape({
    name: formikRequired,
    description: formikRequired,
  });

export default Validator;
