import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, IconButton, Skeleton, Tooltip } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import debounce from 'lodash.debounce';

import {
  ConfirmationTypes,
  deleteWatchlist,
  isLoadingSelector,
  pharmacistActions,
  watchlist,
} from '@pharmaplan/common';
import strings from '../../../localization';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { resetDialog, setDialog } from '../../../reducers/dialogReducer';
import styles from './styles';

interface IWatchlist {
  initState: boolean;
  workshiftId: string;
  showConfirmation?: boolean;
  customOnClick?: () => void;
}

const Watchlist: FC<IWatchlist> = ({
  workshiftId,
  initState,
  customOnClick,
  showConfirmation = false,
}) => {
  const [isWatchlist, setIsWatchlist] = useState(initState);
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) =>
    isLoadingSelector([pharmacistActions.getPharmacistEvent], state));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleToggleWatchlist = useCallback(
    debounce(
      () => {
        setIsWatchlist((prevState) => {
          const newState = !prevState;
          if (newState) {
            dispatch(watchlist(workshiftId));
          } else {
            dispatch(deleteWatchlist(workshiftId));
          }
          return newState;
        });
      },
      300,
      { leading: true, trailing: false },
    ),
    [workshiftId],
  ); // 300ms debounce delay

  const confirmAction = () => {
    dispatch(resetDialog());
    dispatch(deleteWatchlist(workshiftId));
    setIsWatchlist(false);
  };

  const showConfirmationModal = () => {
    dispatch(
      setDialog({
        showCloseButton: false,
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            showCloseButton
            type={ConfirmationTypes.removeWatchlist}
            confirmAction={confirmAction}
          />
        ),
      }),
    );
  };

  const handleOnClick = () => {
    if (showConfirmation && isWatchlist) {
      showConfirmationModal();
    } else {
      handleToggleWatchlist();
    }
  };

  const renderIcon = () => {
    if (!showConfirmation && isLoading) {
      return <Skeleton variant="rectangular" width={18} height={18} />;
    }

    if (isWatchlist) {
      return <BookmarkIcon sx={styles.iconColor} />;
    }

    return <BookmarkBorderIcon sx={styles.iconColor} />;
  };

  useEffect(() => {
    setIsWatchlist(initState);
  }, [initState, workshiftId]);

  return (
    <Tooltip title={isWatchlist ? strings.removeFromWatchlist : strings.addToWatchlist} placement="top-start" arrow>
      <Box>
        <IconButton onClick={customOnClick ?? handleOnClick}>{renderIcon()}</IconButton>
      </Box>
    </Tooltip>
  );
};

export default Watchlist;
