import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import strings from '../../../../../localization';
import styles from './style';

interface ICancellationDateAndTime {
  date: string;
}

const CancellationDateTime: FC<ICancellationDateAndTime> = ({ date }) => {
  const dateTime = `${strings.date} | ${strings.time}: ${date}`;

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.text}>{dateTime}</Typography>
    </Box>
  );
};

export default CancellationDateTime;
