const classes = {
  weekHeaderContainer: {
    padding: '0.8rem',
    backgroundColor: 'common.grey150',
    width: '100%',
  },
  weekHeaderDate: {
    color: 'grey.500',
    fontSize: '16px',
    fontWeight: '600',
  },
  weekHeaderDay: {
    color: 'grey.500',
    fontSize: '13px',
    fontWeight: '500',
  },
};

export default classes;
