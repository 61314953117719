import React from 'react';

import { Route } from 'react-router-dom';

import { getIsUserSignedUp, isRequestedReset } from '@pharmaplan/common';
import CreateNewPassword from '../components/CreateNewPassword';
import GuestLayout from '../components/GuestLayout';
import Login from '../components/Login';
import OtpForm from '../components/Login/OtpForm';
import ResetPassword from '../components/ResetPassword';
import Signup from '../components/Signup';
import SignupFlow from '../components/SignupFlow';
import { Constants } from '../helpers/Constants';
import { useAppSelector } from '../hooks/useAppSelector';

import ProtectedRoute from './ProtectedRoute';

const LoginRouter = () => {
  const isUserSignedUp = useAppSelector(getIsUserSignedUp);
  const didRequest = useAppSelector(isRequestedReset);

  return (
    <Route element={<GuestLayout />}>
      <Route path={Constants.paths.login} element={<Login />} />
      <Route path={Constants.paths.signup} element={<Signup />} />
      <Route
        path={Constants.paths.signupFlow}
        element={<ProtectedRoute authenticated={isUserSignedUp} />}
      >
        <Route path={Constants.paths.signupFlow} element={<SignupFlow />} />
      </Route>
      <Route path={Constants.paths.resetPassword} element={<ResetPassword />} />
      <Route path={Constants.paths.setPassword} element={<ResetPassword />} />
      <Route element={<ProtectedRoute authenticated={didRequest} />}>
        <Route path={Constants.paths.otpForm} element={<OtpForm />} />
        <Route
          path={Constants.paths.newPassword}
          element={<CreateNewPassword />}
        />
      </Route>
    </Route>
  );
};

export default LoginRouter;
