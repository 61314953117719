import ThemeConstants from '../../../../theme/ThemeConstants';

const classes = {
  uploadDetailsContainer: {
    display: 'flex',
    backgroundColor: ThemeConstants.common.lightBackgroundColor,
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  uploadDetailsFileDetails: {
    fontSize: '12px',
    flex: 2,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#6E757C',
  },
  uploadDetailsDeleteIcon: { cursor: 'pointer' },
  uploadDetailsDeleteIconContainer: {
    justifyContent: 'center',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  uploadProgressContainer: {
    border: 'solid',
    flex: 3,
    alignSelf: 'center',
    height: 8,
  },
  uploadDetailsSuccessIcon: {
    verticalAlign: 'middle',
  },
  uploadDetailsLabel: {
    color: 'primary.inputLabel',
    fontSize: '12px',
  },
};

export default classes;
