import React from 'react';

import { FormHelperText, Grid, Typography } from '@mui/material';

import { Constants } from './Constants';
import genericClasses from '../theme/GenericClasses';
import CustomDropdown from '../components/common/CustomDropdown';
import CustomRadioButton from '../components/common/CustomRadioButton';
import CustomTextField from '../components/common/CustomTextField';
import CustomUploadInput from '../components/common/CustomUploadInput';
import CustomAccordion from '../components/common/CustomAccordion';
import CustomButton from '../components/common/CustomButton';
import CustomCheckbox from '../components/common/CustomCheckbox';
import DateRange from '../components/common/CustomDateRange';
import CustomMaskedTextField from '../components/common/CustomMaskedTextField';
import CustomOpreation from '../components/common/CustomOpreation';
import CustomLabel from '../components/common/CustomLabel';
import ChangeEmailField from '../components/common/ChangeEmailField';
import MapButton from '../components/common/MapButton';
import PharmacyImageLists from '../components/common/PharmacyImageLists';
import FormikError from '../components/common/FormikError';

const { changeEmail, mapButton, imageNameList, uploadIconLabel } = Constants.formInputType;

export const formGenerator = (item: any, formikParam: any) => {
  const {
    disabled,
    customTextFieldContainer,
    radioItems,
    maxLength,
    menuItems,
    multiple,
    imageMenu,
    fieldLabel,
    handleChange,
    preventPast,
    items,
    style,
    onClick,
    customClass,
    startIcon,
    endIcon,
    customTextFieldStyle,
    customCheckboxStyle,
    fullWidth,
    linkLabel,
    onLinkClick,
    opreationType,
    mask,
    name,
    image,
    suffix,
    xs,
    customType,
    id,
    label,
    showStrentghBar,
    requireLabel,
    hint,
    capitalize,
    placeholder,
    startMax,
    endMax,
    customMenuStyles,
    multiline,
    variant,
    labelClass,
    rightIcon,
    customHelperText,
    dropdownErrorContainer,
    imageItems,
    uploadLabel,
  } = item ?? {};

  switch (item.type) {
    case Constants.formInputType.text:
      return (
        <Grid xs={xs} item>
          <CustomTextField
            disabled={disabled}
            maxLength={maxLength}
            customTextFieldStyle={customTextFieldStyle}
            customClass={{
              ...genericClasses.profileInputMargins,
              ...customTextFieldContainer,
            }}
            name={name}
            capitalize={capitalize}
            formik={formikParam}
            suffix={suffix}
            customType={customType}
            id={id}
            label={label}
            showStrentghBar={showStrentghBar}
            requireLabel={requireLabel}
            hint={hint}
            placeholder={placeholder}
            variant={variant}
            multiline={multiline}
            rightIcon={rightIcon}
            customHelperText={customHelperText}
          />
        </Grid>
      );
    case Constants.formInputType.dropdown:
      return (
        <Grid xs={xs} item>
          <CustomDropdown
            customClass={{
              ...genericClasses.profileInputMargins,
              ...customTextFieldContainer,
            }}
            customMenuStyles={customMenuStyles}
            menuItems={menuItems}
            name={name}
            onClickMenuItem={onClick}
            disabled={disabled}
            requireLabel={requireLabel}
            formik={formikParam}
            multiple={multiple}
            imageMenu={imageMenu}
            variant={variant}
            label={label}
            placeholder={label}
            errorContainer={dropdownErrorContainer}
          />
        </Grid>
      );
    case Constants.formInputType.datePicker:
      return (
        <Grid container xs={xs}>
          <DateRange
            formik={formikParam}
            startName="startDate"
            preventPast={preventPast}
            endName="endDate"
            startMax={startMax}
            endMax={endMax}
          />
        </Grid>
      );
    case Constants.formInputType.radio:
      return (
        <Grid xs={xs} style={genericClasses.inputPadding} item>
          <CustomRadioButton
            radioItems={radioItems}
            name={name}
            formik={formikParam}
            radioLabel={label}
          />
        </Grid>
      );
    case Constants.formInputType.typography:
      return (
        <Grid xs={xs} item>
          <Typography sx={style}>{formikParam.values[name] ?? ''}</Typography>
        </Grid>
      );
    case Constants.formInputType.upload:
      return (
        <Grid xs={12} item>
          <CustomUploadInput
            uploadType={Constants.upload.type.pdf}
            label={item.label}
          />
        </Grid>
      );

    case Constants.formInputType.accordion:
      return <CustomAccordion formik={formikParam} items={items} />;
    case Constants.formInputType.button:
      return (
        <Grid xs={6} item>
          <CustomButton
            customClass={customClass}
            customButtonStyle={style}
            label={label}
            onClick={onClick}
            variant={variant ?? Constants.variant.text}
            startIcon={startIcon}
          />
        </Grid>
      );
    case Constants.formInputType.checkbox:
      return (
        <>
          <Grid xs={xs} item>
            <CustomCheckbox
              id={name}
              name={name}
              value={formikParam.values[name]}
              customCheckboxStyle={customCheckboxStyle}
              label={label}
              customClass={customClass}
              labelClass={labelClass}
              linkLabel={linkLabel}
              onLinkClick={onLinkClick}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange?.(e.target.checked);
                formikParam.setFieldValue(name, e.target.checked);
              }}
            />
          </Grid>
          {formikParam.errors[name] && formikParam.touched[name] && (
            <FormHelperText style={genericClasses.error}>
              {formikParam.errors[name]}
            </FormHelperText>
          )}
        </>
      );
    case Constants.formInputType.masked:
      return (
        <Grid xs={xs} item>
          <CustomMaskedTextField
            mask={mask}
            variant={variant}
            customClass={genericClasses.profileInputMargins}
            name={item.name}
            label={label}
            requireLabel={requireLabel}
            formik={formikParam}
          />
        </Grid>
      );
    case Constants.formInputType.opreation:
      return (
        <Grid xs={xs} item>
          <CustomOpreation
            requireLabel={requireLabel}
            suffix={suffix}
            label={label}
            opreationType={opreationType}
            id={id}
            formik={formikParam}
          />
        </Grid>
      );
    case Constants.formInputType.label:
      return (
        <CustomLabel
          image={image}
          xs={xs}
          customClass={style}
          name={name}
          formik={formikParam}
          label={label}
        />
      );
    case mapButton:
      return (
        <Grid xs={xs} item>
          <MapButton
            label={label}
            fieldLabel={fieldLabel}
            onClick={onClick}
            customStyle={style}
            requireLabel={requireLabel}
            startIcon={startIcon}
            fullWidth={fullWidth}
            endIcon={endIcon}
            formik={formikParam}
            helperTextStyle={item.helperTextStyle}
          />
        </Grid>
      );

    case changeEmail:
      return (
        <ChangeEmailField
          formik={formikParam}
          fieldName={name}
          xs={xs}
          preventChangeEmail={disabled}
        />
      );
    case imageNameList:
      return <PharmacyImageLists items={imageItems} />;
    case uploadIconLabel:
      return (
        <Grid onClick={onClick} sx={genericClasses.button} xs={xs} item>
          <Typography sx={genericClasses.imageName}>{uploadLabel}</Typography>
          <CustomButton
            customClass={customClass}
            customButtonStyle={style}
            label={label}
            onClick={onClick}
            variant={variant ?? Constants.variant.text}
            startIcon={startIcon}
          />
          <FormikError formik={formikParam} name={name} />
        </Grid>
      );
    default:
      return '';
  }
};
