import * as Yup from 'yup';

import { emailRegex } from '@pharmaplan/common';
import strings from '../../../../localization';
import { formikRequired } from '../../SoftwareList/validator';
import { phoneValidation } from '../../../Profile/FormValidation';

const Validator = () =>
  Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .required(strings.requiredField),
    username: formikRequired,
    languageId: formikRequired,
    phone: phoneValidation(),
    permissions: Yup.array().min(1, strings.pleaseSelectOne),
  });

export default Validator;
