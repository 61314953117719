/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getProInfo, proInfo, updateProInfo } from '@pharmaplan/common';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ProInfoValidator } from '../../FormValidation';
import ProfileForm from '../../ProfileForm';
import { ICommonProfile } from '../General/General';

const handleUndefined = <T, >(val: string | string[] | undefined | null, expected: T): T => {
  if (val === undefined || val === null) return expected;
  return val as T;
};

const ProfessionalInfo = ({ setSelected }: ICommonProfile) => {
  const dispatch = useAppDispatch();
  const info = useAppSelector(proInfo);

  const { preferredWorkingDays: contactPreferred } = info ?? {};

  const getMinusCurrentYear = (year: number) =>
    new Date().getFullYear() - year;

  const formik = useFormik({
    initialValues: { ...info,
      preferredWorkingDays:
      handleUndefined<Array<string>>(contactPreferred, [])
        .length !== 0
        ? (contactPreferred as string)?.split(',')
        : [] },

    onSubmit: (values) => {
      const { preferredWorkingDays } = values ?? {};
      dispatch(updateProInfo({ ...values,
        preferredWorkingDays: (preferredWorkingDays as Array<string>)?.join(
          ',',
        ) }));
    },
    enableReinitialize: true,
    validationSchema: ProInfoValidator(),
  });

  useEffect(() => {
    formik.setFieldValue(
      'yearsOfExperience',
      getMinusCurrentYear(parseInt(formik.values.graduationYear, 10)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.graduationYear]);

  useEffect(() => {
    dispatch(getProInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.setValues({
      ...info,
      englishLevel: info.englishLevel.toString(),
      frenchLevel: info.frenchLevel.toString(),
      preferredWorkingDays:
      handleUndefined<Array<string>>(contactPreferred, [])
        .length !== 0
        ? (contactPreferred as string)?.split(',')
        : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="professionalInfo"
      formik={formik}
    />
  );
};

export default ProfessionalInfo;
