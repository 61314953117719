import React, { FC, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { SkeletonTypography } from '../SettingsInfo';
import classes from '../style';

interface IMaskedText {
  value: string;
}

const MaskedText: FC<IMaskedText> = ({ value }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={classes.maskedTextContainer}>
      <SkeletonTypography sx={classes.valueStyle}>
        {showPassword ? value : '*'.repeat(value?.length)}
      </SkeletonTypography>
      <IconButton onClick={handleTogglePasswordVisibility}>
        {showPassword ? (
          <VisibilityOff sx={classes.passwordIcon} />
        ) : (
          <Visibility sx={classes.passwordIcon} />
        )}
      </IconButton>
    </Box>
  );
};

export default MaskedText;
