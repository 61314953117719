import { useEffect, useMemo } from 'react';

import { FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  adminActions,
  adminBroadcastGroupList,
  adminResetValue,
  BroadcastNotificationType,
  getBroadcastRecipients,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';

import strings from '../../../localization';

import { useAppSelector } from '../../useAppSelector';
import { useAppDispatch } from '../../useAppDispatch';

const { message, email } = BroadcastNotificationType;
const { sendBroadcastNotification: successAction } = adminActions;
const recipientKey = 'groupRecipientList';

const useBroadcastNotification = (
  type: BroadcastNotificationType,
  formik: FormikProps<any>,
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const { groupIds } = formik.values;
  const broadcastGroups = useAppSelector(adminBroadcastGroupList);
  const { data } = broadcastGroups;
  const groupsDropdown = useMemo(
    () =>
      data.map((item) =>
        ({
          key: item.broadcastGroupId,
          label: item.name,
        })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [broadcastGroups],
  );

  const fieldsConfig = {
    [message]: {
      placeholder: strings.writeMessageContent,
      body: strings.textMessageBody,
      fieldTitle: strings.enterMessageData,
      maxLength: 320,
    },
    [email]: {
      placeholder: strings.writeEmailContent,
      body: strings.emailBody,
      fieldTitle: strings.enterEmailData,
      maxLength: null,
    },
  };

  const getGroups = () => {
    if (groupIds.length) {
      dispatch(getBroadcastRecipients(groupIds));
    } else {
      dispatch(adminResetValue([recipientKey]));
    }
  };

  const { body, placeholder, fieldTitle, maxLength } = fieldsConfig[type as keyof typeof fieldsConfig] ?? {};

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.sentSuccessfully);
      dispatch(resetStatus([successAction]));
      navigate(-1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(
    () =>
      () => {
        dispatch(adminResetValue([recipientKey]));
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { body, placeholder, groupsDropdown, getGroups, fieldTitle, maxLength };
};

export default useBroadcastNotification;
