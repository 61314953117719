import React from 'react';
import { activeDate } from '@pharmaplan/common';
import moment from 'moment';
import { Typography } from '@mui/material';
import { MomentFormatOptions } from '../../../../helpers/Constants';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import styles from './styles';

const { shortMonthName, fullYear } = MomentFormatOptions;

const FormattedDate = () => {
  const date = useAppSelector(activeDate);

  const formattedDate = moment(date).format(`${shortMonthName}, ${fullYear}`);

  return (
    <Typography variant="subtitle1" sx={styles.label}>
      {formattedDate}
    </Typography>
  );
};

export default FormattedDate;
