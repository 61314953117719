import { SxProps, Theme } from '@mui/system';

const classes = {
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'common.border',
    padding: '2px 8px',
    borderRadius: '30px',
  },

  recurrenceTitle: {
    color: 'common.grey850',
    fontWeight: 500,
    fontSize: '12px',
  },

  recurrenceValue: {
    color: 'common.grey850',
    fontWeight: 400,
    fontSize: '12px',
    marginLeft: '3px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
