import React, { FC } from 'react';

import { Typography, IconButton, Avatar, Box } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { adminActions, ICalendarEventType } from '@pharmaplan/common';
import { ChipType, SkeletonVariants } from '../../../helpers/Constants';
import strings from '../../../localization';

import CustomChip from '../CustomChip';
import WithSkeleton from '../WithSkeleton';

import styles from './styles';

interface IPharmacistBar {
  name: string;
  title: string;
  image: string | null;
  successAction: string;
  type: ICalendarEventType;
  hourlyRate: number | null;
  arrowClick: () => void;
}

const imageSuccessAction = adminActions.adminPharmacistPictures;

const PharmacistBar: FC<IPharmacistBar> = ({
  name,
  image,
  type,
  successAction,
  hourlyRate,
  arrowClick,
  title,
}) => {
  const SkeletonTypography = WithSkeleton({ successAction, variant: 'text' })(
    Typography,
  );
  const SkeletonAvatar = WithSkeleton({
    successAction: imageSuccessAction,
    variant: SkeletonVariants.circular,
    height: 40,
    width: 40,
  })(Avatar);

  const SkeletonBox = WithSkeleton({
    successAction,
    variant: SkeletonVariants.rounded,
  })(Box);

  return (
    <Box sx={styles.container} onClick={arrowClick} component="div">
      <Box sx={styles.titleContainer}>
        <SkeletonTypography sx={styles.title}>{title}</SkeletonTypography>
        <IconButton sx={styles.iconButton}>
          <KeyboardArrowRightIcon sx={styles.rightChevron} />
        </IconButton>
      </Box>

      <Box sx={styles.contentContainer}>
        <SkeletonAvatar sx={styles.avatar} src={image ?? ''} />

        <Box sx={styles.footerContainer}>
          <Box sx={styles.nameContainer}>
            <SkeletonTypography sx={styles.name}>{name}</SkeletonTypography>
            <CustomChip type={type} label={strings.booked} />
          </Box>
          <SkeletonBox sx={styles.chipContainer}>
            <CustomChip
              type={ChipType.hourlyRate}
              label={
                strings.formatString(
                  strings.hourlyRateSuffix,
                  `${hourlyRate ?? 0}`,
                ) as string
              }
            />
          </SkeletonBox>
        </Box>
      </Box>
    </Box>
  );
};
export default PharmacistBar;
