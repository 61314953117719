import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { convertToDecimals, currency, perHour } from '@pharmaplan/common';
import { ChipType, SkeletonVariants } from '../../../helpers/Constants';

import WithSkeleton from '../WithSkeleton';
import CustomChip from '../CustomChip';
import styles from './styles';

interface IHourlyRateChipLabel {
  type: ChipType;
  rate: number | undefined | string;
  successAction: string;
  label?: string;
}

const HourlyRateChipLabel: FC<IHourlyRateChipLabel> = ({
  type,
  rate,
  label,
  successAction,
}) => {
  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const SkeletonBox = WithSkeleton({
    successAction,
    variant: SkeletonVariants.rounded,
  })(Box);

  return (
    <Box sx={styles.itemContainer}>
      {!!label && (
        <SkeletonTypography sx={styles.title}>{label}</SkeletonTypography>
      )}
      <SkeletonBox sx={styles.chip}>
        <CustomChip
          type={type}
          label={`${currency}${convertToDecimals(
            parseFloat((rate as string) ?? '0'),
          )}${perHour}`}
        />
      </SkeletonBox>
    </Box>
  );
};

export default HourlyRateChipLabel;
