import React, { FC } from 'react';
import {
  AdminLegendVariant,
  IBookedPharmacistData,
  adminActions,
  convertToDecimals,
} from '@pharmaplan/common';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { ReactComponent as MenuIcon } from '@pharmaplan/common/assets/icons/menuIcon.svg';
import PharmacyCard from '../../../SelfService/PharmacyCard';
import useGetItems from '../../../../hooks/useGetItems';
import strings from '../../../../localization';
import PharmacistBar from '../../../common/PharmacistBar';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { renderScreen } from '../../../../actions/drawerActions';
import { ScreenTypes } from '../../../../helpers/Constants';
import styles from './styles';
import { LocationText } from '../../AvailableWorkshifts/AvailableWorkshifts';
import WithSkeleton from '../../../common/WithSkeleton';
import CustomMenuList from '../../../common/CustomMenuList';
import useBookedWorkshiftActions from '../../../../hooks/AvailableWorkshift/useBookedWorkshiftActions';
import AdminHourlyRateFooter from '../../../common/AdminHourlyRateFooter';

interface ISingleBooking {
  item: IBookedPharmacistData;
  image?: string;
}
const successAction = adminActions.getAdminEvent;

const SingleBooking: FC<ISingleBooking> = ({ item, image }) => {
  const dispatch = useAppDispatch();
  const {
    logoId,
    pharmacyCity,
    pharmacistHourlyRate,
    workshiftHourlyRate,
    startDate,
    endDate,
    pharmacyName,
    bookingId,
    pharmacistName,
    pharmacyHourlyRate,
    softwareName,
    pharmacyId,
  } = item ?? {};

  const { getImage } = useGetItems();
  const logoImage = getImage(logoId);

  const goToPharmacyDetails = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.bookingList,
        eventId: bookingId,
      }),
    );
  };

  const goToBookingList = () => {
    dispatch(
      renderScreen({
        screenNumber: 1,
        screenType: ScreenTypes.bookingList,
        date: startDate,
      }),
    );
  };

  const goToViewDetails = () => {
    dispatch(
      renderScreen({
        screenNumber: 3,
        screenType: ScreenTypes.availableWorkshift,
        eventId: bookingId,
        type: AdminLegendVariant.booked,
        back: goToBookingList,
        pharmacyId,
      }),
    );
  };

  const SkeletonTypography = WithSkeleton({ successAction, variant: 'text' })(
    Typography,
  );

  const { actions } = useBookedWorkshiftActions(goToBookingList);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.subContainer}>
        <PharmacyCard
          customContainerStyle={styles.mainContainer}
          event={{
            logoImage,
            hourlyRate: pharmacyHourlyRate ?? workshiftHourlyRate,
            ...item,
          }}
          CustomContent={(
            <LocationText
              containerStyle={styles.footerContainer}
              label={(
                <SkeletonTypography sx={styles.footer}>
                  {pharmacyCity}
                </SkeletonTypography>
              )}
            />
          )}
          CustomFooter={(
            <>
              <Box sx={styles.footerBox}>
                <AdminHourlyRateFooter
                  pharmacyHourlyRate={pharmacyHourlyRate}
                  successAction={successAction}
                  software={softwareName}
                />
              </Box>
              <PharmacistBar
                name={pharmacistName}
                successAction={successAction}
                hourlyRate={convertToDecimals(pharmacistHourlyRate) as number}
                title={strings.pharmacist}
                image={image ?? ''}
                type={AdminLegendVariant.booked}
                arrowClick={goToPharmacyDetails}
              />
            </>
          )}
          successAction={successAction}
          type={AdminLegendVariant.booked}
          contentClick={goToViewDetails}
        />
        <Box sx={styles.menuContainer}>
          <CustomMenuList
            margin={10}
            customMenuTextStyle={styles.menuText}
            customIconButtonStyle={styles.button}
            items={actions(bookingId, pharmacyId, { startDate, pharmacyName, endDate })}
          >
            <MenuIcon height={24} width={24} />
          </CustomMenuList>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleBooking;
