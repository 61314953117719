import React, { FC } from 'react';

import { Box } from '@mui/material';
import { FormikProps } from 'formik';

import PendingTabs from '../../PendingTabs';
import strings from '../../../../localization';

import AdminPharmacySearchFields from '../../../common/AdminPharmacySearchFields';
import { PharmacyListTabTypes } from '../../../../helpers/Constants';

import { tabConfig } from './helper';

interface IPharmacyListHeader {
  formik: FormikProps<any>;
  setSelected: React.Dispatch<React.SetStateAction<PharmacyListTabTypes>>;
  selectedTab: PharmacyListTabTypes;
  handleReset: () => void;
}

const PharmacyListHeader: FC<IPharmacyListHeader> = ({
  formik,
  selectedTab,
  setSelected,
  handleReset,
}) => {
  const handleTabClick = (tab: PharmacyListTabTypes) => {
    setSelected(tab);
  };

  const { handleSubmit } = formik ?? {};

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <PendingTabs
        selectedTab={selectedTab}
        tabConfig={tabConfig(handleTabClick)}
        header={strings.pharmaciesList}
      />
      <AdminPharmacySearchFields formik={formik} handleReset={handleReset} />
    </Box>
  );
};

export default PharmacyListHeader;
