import React from 'react';

import { Box } from '@mui/material';

import CustomButton from '../../common/CustomButton/CustomButton';
import DrawerPagination from '../DrawerPagination/DrawerPagination';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
  selectFooterButtonsShow,
  selectFooterPaginationShow,
  selectFooterPrimaryButton,
  selectFooterSecondaryButton,
} from '../../../selectors/drawerSelector';

import classes from './styles';

const DrawerFooter = () => {
  const primaryButton = useAppSelector(selectFooterPrimaryButton);
  const secondaryButton = useAppSelector(selectFooterSecondaryButton);
  const showButton = useAppSelector(selectFooterButtonsShow);
  const showPagination = useAppSelector(selectFooterPaginationShow);

  const {
    label: primaryBtnLabel,
    variant: primaryBtnVariant,
    disabled: isPrimaryBtnDisabled,
    customButtonStyle: primaryBtnStyle,
    onClick: primaryOnClick,
  } = primaryButton ?? {};

  const {
    label: secondaryBtnLabel,
    variant: secondaryBtnVariant,
    disabled: isSecondaryBtnDisabled,
    customButtonStyle: secondaryBtnStyle,
    onClick: secondaryOnClick,
  } = secondaryButton ?? {};

  const {
    footerButtonMt,
    buttonContainer,
    paginationContainer,
    defaultSecondaryButton,
  } = classes;

  return (
    <>
      {/* Button Container */}
      {showButton && (
        <Box sx={buttonContainer}>
          {primaryBtnLabel && (
            <CustomButton
              customClass={footerButtonMt}
              customButtonStyle={primaryBtnStyle}
              onClick={primaryOnClick}
              label={primaryBtnLabel}
              variant={primaryBtnVariant}
              disabled={isPrimaryBtnDisabled}
            />
          )}
          {secondaryBtnLabel && (
            <CustomButton
              customClass={footerButtonMt}
              customButtonStyle={[defaultSecondaryButton, secondaryBtnStyle]}
              onClick={secondaryOnClick}
              label={secondaryBtnLabel}
              variant={secondaryBtnVariant}
              disabled={isSecondaryBtnDisabled}
            />
          )}
        </Box>
      )}
      {showPagination && (
        <Box sx={paginationContainer}>
          <DrawerPagination />
        </Box>
      )}
    </>
  );
};

export default DrawerFooter;
