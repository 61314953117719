import { SxProps, Theme } from '@mui/system';

const classes = {
  progressBar: {
    height: '12px',
    borderRadius: '12px',
    backgroundColor: 'common.border',
  },
  container: {
    flex: 1,
    paddingBlock: '19px',
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  primaryText: {
    fontSize: '13px',
    fontWeight: 500,
    color: 'primary.inputLabel',
    paddingBottom: '10px',
  },
  secondaryText: {
    fontSize: '13px',
    fontWeight: 500,
    color: 'primary.inputLabel',
    paddingBottom: '10px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
