import ThemeConstants from '../../theme/ThemeConstants';

const classes = {
  container: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    m: 1,
    borderBottom: 'solid #E4E8F1',
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    ml: '12px',
    color: ThemeConstants.grey[500],
  },
  listTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    color: ThemeConstants.grey[500],
    fontSize: 16,
    fontWeight: '500',
  },
  collapse: {
    backgroundColor: ThemeConstants.common.lightBackgroundColor,
    p: 2,
  },
  backButton: {
    display: 'flex',
    maxWidth: '88px',
    width: '100%',
    margin: 0,
    justifyContent: 'space-between',
    marginBottom: '25px',
  },
};

export default classes;
