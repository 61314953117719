import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    paddingX: '24px',
  },
  nameRow: {
    display: 'flex',
    marginBottom: '31px',
    marginTop: '13px',
  },
  text: {
    fontSize: 14,
  },

  value: {
    color: 'common.gray',
  },
  datePicker: {
    marginBottom: '25px',
  },
  checkboxContainer: {
    display: 'flex',
    margin: '8px 0px 6px 6px',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: '24px',
  },
  button: {
    width: 120,
  },
  horizontalFlex: {
    display: 'flex',
    gap: '30px',
    marginBottom: '16px',
  },
  timeContainer: {
    marginBottom: '32px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
