import { useEffect, useState, useRef } from 'react';

import { CallBackProps, STATUS } from 'react-joyride';
import { useCookies } from 'react-cookie';

import { setStartTour, startTour } from '@pharmaplan/common';
import { Constants } from '../helpers/Constants';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const useGuidelines = () => {
  const dispatch = useAppDispatch();
  const tourRef = useRef(null);
  const [cookies, setCookie] = useCookies(['tourCompleted', 'tourCount']);

  const isReplay = useAppSelector(startTour);

  const maxCount = 3;
  const { tourCompleted, tourCount } = cookies;
  const parseTourCount = parseInt(tourCount, 10);

  const [runTour, setRunTour] = useState<{
    tour: boolean;
    count: number;
  }>({
    tour: false,
    count: parseTourCount || 0,
  });

  const isMaxCount = runTour.count === maxCount;

  const handleTourCount = () => {
    !isReplay
      && setCookie('tourCount', runTour.count + 1, {
        path: '/',
        maxAge: Constants.cookieExpiry,
      });
    dispatch(setStartTour(false));
  };

  const handleJoyrideCallback = (state: CallBackProps) => {
    const statuses = [STATUS.FINISHED, STATUS.SKIPPED];

    const status: 'skipped' | 'finished' = state.status as
      | 'skipped'
      | 'finished';

    if (statuses.includes(status)) {
      sessionStorage.setItem('tempSkip', 'true');
      handleTourCount();
    }

    if (statuses.includes(status) && isMaxCount) {
      setRunTour((prev) =>
        ({ ...prev, tour: false }));

      setCookie('tourCompleted', runTour.tour, {
        path: '/',
        maxAge: Constants.cookieExpiry,
      });
      handleTourCount();
    }
  };

  useEffect(() => {
    !tourCompleted && setRunTour((prev) =>
      ({ ...prev, tour: true }));
  }, [tourCompleted]);

  return { runTour, tourRef, handleJoyrideCallback };
};

export default useGuidelines;
