import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { useLocation } from 'react-router-dom';

import { adminLogsFilters, TypeOfUser } from '@pharmaplan/common';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';

import useLogsAutocomplete from '../../../../hooks/Admin/Logs/useLogsAutocomplete';
import { AutoCompleteScrollTypes } from '../../../../hooks/useAutoCompleteScroll';
import useLogsHandler from '../../../../hooks/Admin/Logs/useLogsHandler';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import MultilevelDropdown from '../../../common/MultillevelDropdown';
import CustomAutocomplete from '../../../common/CustomAutocomplete';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import CustomTextField from '../../../common/CustomTextField';
import CustomDateRange from '../../../common/CustomDateRange';
import CustomDropdown from '../../../common/CustomDropdown';
import { Constants } from '../../../../helpers/Constants';
import strings from '../../../../localization';

import styles from './style';

interface IActivityLogsHeader {
  formik: FormikProps<any>;
  handleReset: () => void;
}

const { workshiftLogs, availabilityLogs, bookingLogs, activityLogs } = Constants.paths.admin;

const LogsHeader: FC<IActivityLogsHeader> = ({ formik, handleReset }) => {
  const { title } = useLogsHandler();
  const { pathname } = useLocation();
  const filterValues = useAppSelector(adminLogsFilters);

  const {
    workshiftActions,
    availabilityActions,
    bookingActions,
    activityActions,
  } = filterValues ?? {};
  const { handleSubmit } = formik ?? {};

  const isActivityLog = pathname === activityLogs;
  const isBookingLog = pathname === bookingLogs;

  const config = {
    [workshiftLogs]: {
      label: strings.pharmacy,
      placeholder: strings.formatString(strings.enterCred, strings.pharmacy),
      filter: workshiftActions,
      autocompleteType: AutoCompleteScrollTypes.pharmacy,
    },
    [activityLogs]: {
      label: strings.user,
      placeholder: strings.enterNameEmail,
      filter: workshiftActions,
      autocompleteType: AutoCompleteScrollTypes.none,
    },
    [availabilityLogs]: {
      label: strings.pharmacist,
      placeholder: strings.formatString(strings.enterCred, strings.pharmacist),
      filter: availabilityActions,
      autocompleteType: AutoCompleteScrollTypes.pharmacist,
    },
    [bookingLogs]: {
      label: strings.pharmacy,
      placeholder: strings.formatString(strings.enterCred, strings.pharmacy),
      filter: bookingActions,
      autocompleteType: AutoCompleteScrollTypes.all,
    },
  };

  const { label, placeholder, filter, autocompleteType } = config[pathname];

  const {
    onEndReached,
    pharmacistOption,
    formikPharmacists,
    autocompleteConfig,
  } = useLogsAutocomplete({ formik, autocompleteType });

  const { option, formikType, idName, textName, autoCompleteName, endType } = autocompleteConfig[pathname] ?? {};
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>{title}</Typography>
      </Box>

      <Grid container spacing={0.5} alignItems="center" wrap="nowrap">
        <CustomDateRange
          startName="startDate"
          endName="endDate"
          formik={formik}
          preventPast={false}
          preventFuture
        />

        <Grid item xs={6} sx={styles.dropdownContainer}>
          {isActivityLog ? (
            <MultilevelDropdown
              label={strings.action}
              name="action"
              formik={formik}
              menuItems={activityActions}
            />
          ) : (
            <CustomDropdown
              label={strings.action}
              menuItems={dropdownMapper(filter, 'key', 'value')}
              formik={formik}
              name="action"
            />
          )}
        </Grid>

        <Grid item xs={6} sx={styles.container}>
          <CustomTextField
            label={strings.performer}
            placeholder={strings.enterNameEmail}
            name="performerText"
            id="performerText"
            formik={formik}
          />
        </Grid>

        <Grid item xs={6} sx={styles.container}>
          {isActivityLog ? (
            <CustomTextField
              label={label}
              placeholder={placeholder}
              name="userText"
              id="userText"
              formik={formik}
            />
          ) : (
            <CustomAutocomplete
              formik={formik}
              options={option}
              customAutoCompleteStyle={styles.autocomplete}
              textName={textName}
              autoCompleteName={autoCompleteName}
              idName={idName}
              label={label}
              placeholder={placeholder as string}
              infiniteScroll={!formikType}
              onEndReached={onEndReached(
                endType as TypeOfUser.pharmacist | TypeOfUser.pharmacy,
              )}
            />
          )}
        </Grid>

        {isBookingLog && (
          <Grid item xs={6} sx={styles.container}>
            <CustomAutocomplete
              customAutoCompleteStyle={styles.autocomplete}
              label={strings.pharmacist}
              infiniteScroll={!formikPharmacists}
              idName="pharmacistId"
              textName="pharmacists"
              autoCompleteName="pharmacistSelected"
              onEndReached={onEndReached(TypeOfUser.pharmacist)}
              placeholder={
                strings.formatString(
                  strings.enterCred,
                  strings.pharmacist,
                ) as string
              }
              formik={formik}
              options={pharmacistOption}
            />
          </Grid>
        )}
        <FilterSubmitButtons handleReset={handleReset} />
      </Grid>
    </Box>
  );
};

export default LogsHeader;
