// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

import { firebaseInit } from './configs';

// https://firebase.google.com/docs/web/setup#available-libraries
// Initialize Firebase
const app = initializeApp(firebaseInit);
getMessaging(app);

const firebaseConfig = new URLSearchParams(firebaseInit).toString();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`/firebase-messaging-sw.js?${firebaseConfig}`)
    .then((registration) => {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch((err) => {
      console.log('Service worker registration failed, error:', err);
    });
}
