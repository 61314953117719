const classes = {
  backIcon: {
    paddingRight: 1,
  },

  iconContainer: {
    display: 'flex',
  },

};

export default classes;
