import { emailRegex, passwordRegex } from '@pharmaplan/common';
import * as Yup from 'yup';
import strings from '../../../localization';

const Validator = () =>
  Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .trim()
      .required(strings.requiredField),
    role: Yup.string().required(strings.requiredField),
    password: Yup.string().when('isMediaLogin', {
      is: false,
      then: () =>
Yup.string()
  .required(strings.requiredField)
  .matches(passwordRegex, strings.minCharacters) as any,
    }),
    confirmPassword: Yup.string().when('isMediaLogin', {
      is: false,
      then: () =>
        Yup.string()
          .equals([Yup.ref('password')], strings.passwordsMustMatch)
          .required(strings.requiredField),
    }),
    language: Yup.string().required(strings.requiredField),
  });

export default Validator;
