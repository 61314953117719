import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { Language, language } from '@pharmaplan/common';
import styles from './styles';
import { useAppSelector } from '../../../../../hooks/useAppSelector';

interface IChartBorderLayout {
  children: React.ReactNode;
  title?: string;
  isPie?: boolean;
}

const ChartBorderLayout: FC<IChartBorderLayout> = ({
  children,
  title,
  isPie = false,
}) => {
  const lang = useAppSelector(language);
  const isFr = lang === Language.fr;

  return (
    <Box
      sx={[styles.container(isFr), isPie ? styles.pieContainer : styles.barContainer]}
    >
      {title && (
      <Box sx={styles.titleContainer}>
        <Typography style={styles.title}>{title}</Typography>
      </Box>
      )}
      {children}
    </Box>
  );
};

export default ChartBorderLayout;
