import { SxProps, Theme } from '@mui/system';

const size = 15;

const styles = {
  container: {},
  pieContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    display: 'flex',
  },
  pieItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
  },
  itemMarkerContainer: (isSingleItem: boolean) =>
    ({
      display: 'flex',
      flex: isSingleItem ? 2 : 1,
      justifyContent: 'flex-end',
      mr: '5px',
    }),
  pieItemMarkerContainer: {
    display: 'flex',
    mx: 1,
    ml: 3,
  },
  itemMarker: (color: string) =>
    ({
      backgroundColor: color,
      height: size,
      width: size,
      borderRadius: size / 2,
    }),
  label: {
    fontSize: 12,
    flex: 4,
  },
} satisfies Record<string, SxProps<Theme> | ((height: any) => SxProps<Theme>)>;

export default styles;
