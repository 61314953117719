import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { userPreferredTimeFormat } from '@pharmaplan/common';

import { timeString } from '../../../../helpers/Functions';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';
import { getDayMonthDateYear } from '../../../Reports/helpers';
import styles from './styles';
import { getHoursDiff } from '../../../Modals/WorkshiftDetails/utils';

interface IDetailsHeader {
  pharmacyName: string;
  pharmacistName?: string;
  startDate: string;
  endDate: string;
}

const DetailsHeader: FC<IDetailsHeader> = ({
  pharmacyName,
  pharmacistName,
  startDate,
  endDate,
}) => {
  const timeformat = useAppSelector(userPreferredTimeFormat);
  const date = getDayMonthDateYear(startDate);
  const time = `${timeString(startDate, endDate, timeformat)} (${getHoursDiff(
    startDate,
    endDate,
  )} Hrs)`;

  const config = [
    { key: '0', label: strings.pharmacyName, value: pharmacyName },
    { key: '1', label: strings.pharmacistName, value: pharmacistName },
    { key: '2', label: strings.date, value: date },
    { key: '3', label: strings.time, value: time },
  ];

  return (
    <Stack sx={styles.container}>
      <Typography sx={styles.title}>{strings.details}</Typography>
      {config.map((item) => {
        const { key, label, value } = item ?? {};
        return (
          !!value && (
            <Box sx={styles.labelContainer} key={key}>
              <Typography sx={styles.label}>{label}</Typography>
              <Typography sx={styles.value}>{value}</Typography>
            </Box>
          )
        );
      })}
    </Stack>
  );
};

export default DetailsHeader;
