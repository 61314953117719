import { ICalendarEventType, TypeOfUser } from '@pharmaplan/common';
import ThemeConstants from '../../../../theme/ThemeConstants';

const {
  common: { deletedAvails },
} = ThemeConstants;

export const adminArray = [TypeOfUser.admin, TypeOfUser.superAdmin].map(
  (item) =>
    item.toLowerCase(),
);

const classes = {
  eventContainer: (
    eventType: ICalendarEventType,
    style: (React.CSSProperties & { xOffset: number }) | undefined,
    typeOfUser: TypeOfUser,
    isDeleted?: boolean,
  ) => {
    const isAdmin = adminArray.includes(typeOfUser);

    return {
      display: 'flex',
      flexDirection: 'column',
      borderLeftStyle: 'solid',
      borderLeftWidth: '3px',
      justifyContent: 'space-between',
      minHeight: isAdmin ? '18px' : '22px',
      borderLeftColor: isDeleted ? deletedAvails : `${typeOfUser}.${eventType}`,
      backgroundColor: isDeleted
        ? '#ffadad'
        : `${typeOfUser}.${eventType}Light`,
      borderRadius: isAdmin ? '0px 3px 3px 0px' : '0px 5px 5px 0px',
      cursor: 'pointer',
      // style only comes in the week view
      ...(style
        ? {
          height: `${style?.height}`,
          width: `${style?.width}`,
          top: `${style?.top}%`,
          left: `${style?.left}%`,
          position: 'absolute',
        }
        : {}),
    };
  },

  eventDataContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  tooltipContainer: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
  ) =>
    ({
      backgroundColor: `${typeOfUser}.${eventType}Light`,
    }),

  gridContainer: {
    flex: 1,
    alignItems: 'center',
  },

  eventTitle: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
    isDeleted: boolean,
  ) => {
    const isAdmin = adminArray.includes(typeOfUser);

    return {
      color: isDeleted ? deletedAvails : `${typeOfUser}.${eventType}`,
      fontWeight: 500,
      fontSize: isAdmin ? 10 : 11,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginLeft: '6px',
      overflow: 'hidden',
    };
  },

  eventTime: (eventType: ICalendarEventType, typeOfUser: TypeOfUser) =>
    ({
      color: `${typeOfUser}.${eventType}`,
      fontWeight: 500,
      fontSize: 12,
      marginLeft: '0.3rem',
      display: 'flex',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),

  rightGrid: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },

  rightBlock: (typeOfUser: TypeOfUser) => {
    const isAdmin = adminArray.includes(typeOfUser);

    return {
      display: 'flex',
      backgroundColor: 'white',
      height: isAdmin ? 12 : 15,
      width: isAdmin ? 12 : 15,
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
    };
  },

  textColor: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
    isDeleted: boolean,
  ) =>
    ({
      color: isDeleted ? deletedAvails : `${typeOfUser}.${eventType}`,
      fontWeight: '600',
      fontSize: 9,
      textAlign: 'center',
    }),

  eventClockIconContainer: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
  ) =>
    ({
      color: `${typeOfUser}.${eventType}`,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      overflow: 'hidden',
      '& svg': {
        maxHeight: '15px',
        maxWidth: '15px',
      },
    }),

  titleContainer: {
    overflow: 'hidden',
  },

  bellContainer: {
    marginLeft: '3px',
  },

  center: {
    display: 'flex',
    alignItems: 'center',
  },

  waving: {
    '@keyframes waving': {
      '0%': { transform: 'rotate(0.0deg)' },
      '15%': { transform: 'rotate(14.0deg)' },
      '30%': { transform: 'rotate(-8.0deg)' },
      '40%': { transform: 'rotate(14.0deg)' },
      '50%': { transform: 'rotate(-4.0deg)' },
      '60%': { transform: 'rotate(10.0deg)' },
      '70%': { transform: 'rotate(0.0deg)' },
      '100%': { transform: 'rotate(0.0deg)' },
    },
    animation: 'waving 2.5s infinite ease',
  },
};

export default classes;
