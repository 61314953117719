import { DateTime } from 'luxon';
import { DateLocalizer } from 'react-big-calendar';
import { MomentTimeFormats } from '../../../../helpers/Constants';

export type Localizer = { localizer: DateLocalizer };

export const isToday = (eventDate: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const evDate = new Date(eventDate);
  evDate.setHours(0, 0, 0, 0);

  return evDate.getTime() === today.getTime();
};

export const parseDate = (dateToParse: Date) =>
  DateTime.fromISO(new Date(dateToParse).toISOString()).toFormat(
    MomentTimeFormats.yearMonthDateNumericLuxonStandard,
  );
