import React, { useEffect } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { FormikValues, useFormik } from 'formik';
import BackIcon from '@mui/icons-material/ArrowBack';
import {
  Language,
  createProfile,
  defaultPrefferedRange,
  getLanguages,
  getTypeOfUser,
  getUserProps,
  istermsAgreedStatus,
  setAddress,
  userAddress,
  userLocation,
} from '@pharmaplan/common';

import CustomButton from '../../common/CustomButton';
import ProfileForm from '../../Profile/ProfileForm';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';
import { pharmacistPersist } from '../../../selectors/formPersistSelector';
import { filterLanguage } from '../../../helpers/Functions';

import { pharmacistSteps } from '../helper';

import classes from './styles';

interface IPharmacistSignupFlow {
  step: number;
  changeStep: (steps: object, values: FormikValues, type: string) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const PharmacistSignupFlow = ({
  step,
  setStep,
  changeStep,
}: IPharmacistSignupFlow) => {
  const dispatch = useAppDispatch();

  const pharmacistPersistSelector = useAppSelector(pharmacistPersist);
  const userType = useAppSelector(getTypeOfUser);
  const isTerms = useAppSelector(istermsAgreedStatus);
  const getUserAddress = useAppSelector(userAddress);
  const location = useAppSelector(userLocation);
  const languages = useAppSelector(getLanguages);
  const userProps = useAppSelector(getUserProps);
  const pharmacistStep = pharmacistSteps()?.[step as keyof typeof pharmacistSteps];
  const isLastStep = step === Object.keys(pharmacistSteps()).length - 1;
  const validStep = step !== 0;

  const { back, backText, buttons } = classes;

  const { selectedSoftwares, general, professionalInfo, contacts } = pharmacistPersistSelector ?? {};
  const { longitude, latitude } = location ?? {};

  const { type: pharmacistStepType, validator } = pharmacistStep;
  const { userType: role, language, ...rest } = userProps ?? {};

  const handleSubmit = (values: FormikValues) => {
    const software = Object.keys(selectedSoftwares)?.map((item) =>
      ({
        softwareId: item,
        expLevel: selectedSoftwares[item as keyof typeof values] as number,
      }));
    const allData = {
      ...rest,
      ...general,
      ...professionalInfo,
      ...contacts,
      selectedSoftwares: software,
      ...values,
      latitude,
      longitude,
      role,
      preferdLanguage: language,
      prefferedDistanceRange: defaultPrefferedRange,
    };

    dispatch(
      createProfile({
        params: allData,
        user: userType,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      ...pharmacistPersistSelector[pharmacistStepType],
      latitude: null,
      longitude: null,
      preferdLanguage: filterLanguage(languages, language ?? Language.fr)?.name,
    },
    onSubmit: (values) => {
      isLastStep
        ? handleSubmit(values)
        : changeStep(pharmacistSteps(), values, pharmacistStepType);
    },
    enableReinitialize: true,
    validationSchema: validator,
  });

  const {
    values: formikValues,
    setFieldValue,
    handleSubmit: formikHandleSubmit,
  } = formik ?? {};
  const { graduationYear, termsAndConditions } = formikValues ?? {};

  const getMinusCurrentYear = (year: number) =>
    new Date().getFullYear() - year;

  const handleBackButton = () => {
    if (step > 0) {
      setStep((prevStep) =>
        prevStep - 1);
    }
  };

  const buttonLabel = isLastStep ? strings.submit : strings.next;
  const shouldShowSubmit = isLastStep && !termsAndConditions;

  useEffect(() => {
    graduationYear
      && setFieldValue(
        'yearsOfExperience',
        getMinusCurrentYear(parseInt(graduationYear, 10)),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graduationYear]);

  useEffect(() => {
    setFieldValue('termsAndConditions', isTerms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTerms]);

  useEffect(() => {
    getUserAddress.length > 0 && setFieldValue('address', getUserAddress);
    (latitude ?? 0) && setFieldValue('latitude', latitude);
    (longitude ?? 0) && setFieldValue('longitude', longitude);

    return () => {
      dispatch(setAddress(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserAddress]);

  return (
    <>
      <Box>
        <ProfileForm
          selected={pharmacistStepType}
          formik={formik}
          isSignup
        />
      </Box>

      <Box display="flex" flex={1} alignSelf="flex-end" alignItems="flex-end">
        {validStep && (
          <IconButton sx={back} onClick={handleBackButton}>
            <BackIcon />
            <Typography sx={backText}>{strings.back}</Typography>
          </IconButton>
        )}

        <CustomButton
          label={buttonLabel}
          customClass={buttons}
          onClick={formikHandleSubmit}
          disabled={shouldShowSubmit}
        />
      </Box>
    </>
  );
};

export default PharmacistSignupFlow;
