import { SxProps, Theme } from '@mui/system';

const size = 36;

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    p: 0.7,
  },
  avatar: {
    height: size,
    width: size,
    border: '1px solid',
    borderColor: 'primary.main',
  },
  img: {
    objectFit: 'cover',
  },
  name: {
    ml: 1,
    fontSize: 14,
    color: '#333',
  },
  avatarContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  refreshIcon: {
    color: 'primary.main',
    mb: '3px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
