import { adminGetPharmacyNotes, adminPharmacyNotes, getPharmacistDetails } from '@pharmaplan/common';
import { useEffect, useState } from 'react';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';

import { IDynamicTableObject } from '../../../components/DynamicTable/types';
import { renderScreen, setBack } from '../../../actions/drawerActions';
import { ScreenTypes } from '../../../helpers/Constants';
import { GenericReplacementActionTypes } from '../../../components/Admin/PharmacyList/ListOfPharmacists/AdminPharmacyHistory/AdminPharmacyHistory';
import { pharmacyNotesHeaders, pharmacyNotesRow } from '../../../components/Admin/PharmacyList/ListOfPharmacists/AdminPharmacyNotes/helper';

const usePharmacyNotes = ({ pharmacistId, pharmacyId }: GenericReplacementActionTypes) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const notes = useAppSelector(adminPharmacyNotes);

  const table: IDynamicTableObject = {
    headerBar: [],
    headers: pharmacyNotesHeaders(),
    rows: pharmacyNotesRow(notes),
  };

  const goBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.pharmacyList,
        pharmacyId,
      }),
    );
  };

  const ratingGoBack = () => {
    dispatch(
      renderScreen({
        screenNumber: 4,
        screenType: ScreenTypes.pharmacyList,
        pharmacistId,
        pharmacyId,
      }),
    );
  };

  useEffect(() => {
    dispatch(adminGetPharmacyNotes({ pharmacistId, pharmacyId }));
    dispatch(getPharmacistDetails(pharmacistId));
    dispatch(setBack(goBack));

    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { table, ratingGoBack, page, setPage };
};

export default usePharmacyNotes;
