const classes = {
  navButtonIconCont: {
    width: '100%',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'common.grey350',
  },
  navButtonActive: {
    backgroundColor: 'secondary.main',
  },

  toolbarToggleContainer: {
    backgroundColor: 'common.lightBackgroundColor',
    border: '1px solid #F2F2F2',
    borderRadius: '5px',
    cursor: 'pointer',
    marginLeft: '1rem',
  },

  todayButton: {
    color: 'grey.500',
    '& :hover': {
      backgroundColor: 'white',
    },
  },
};

export default classes;
