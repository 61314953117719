import React, { useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';

import {
  resetPassword,
  resetStatus,
  setRequestReset,
  successSelector,
  userActions,
} from '@pharmaplan/common';

import CustomTextField from '../common/CustomTextField';
import LoginCustomCard from '../common/LoginCustomCard';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import strings from '../../localization';
import { InputTypes } from '../../helpers/Constants';
import genericClasses from '../../theme/GenericClasses';
import { ReactComponent as ResetPasswordIcon } from '../../assets/svg/resetPasswordIcon.svg';

import Validator from './Validator';
import { classes } from './style';

const { cardStyle, infoHeading, infoDesc } = classes ?? {};

const CreateNewPassword = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const resetSuccess = useAppSelector((st) =>
    successSelector([userActions.resetPassword], st));

  const { email } = state ?? {};

  const handleSubmit = (values: FormikValues) => {
    const { password } = values ?? {};

    dispatch(resetPassword({ email, password }));
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleSubmit,
    validationSchema: Validator,
  });

  const textArray = [
    {
      key: 1,
      text: strings.createNewPw,
      sx: infoHeading,
    },
    {
      key: 2,
      text: strings.newPasswordDesc,
      sx: infoDesc,
    },
  ];

  const pwPlaceholder = strings.formatString(strings.enterCred, strings.password);
  const confirmPwPlaceholder = strings.formatString(strings.enterCred, strings.confirmPassword);

  useEffect(() => {
    if (resetSuccess) {
      dispatch(setRequestReset(false));
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSuccess]);

  return (
    <LoginCustomCard
      customCard={cardStyle}
      icon={<ResetPasswordIcon />}
      textArray={textArray}
      buttonTitle={strings.resetPassword}
      onClick={formik.handleSubmit}
    >
      <CustomTextField
        name="password"
        type={InputTypes.password}
        customClass={genericClasses.loginMargins}
        formik={formik}
        placeholder={pwPlaceholder}
        id="password"
        label={strings.password}
        showStrentghBar
        hint={strings.minCharacters}
      />

      <CustomTextField
        name="confirmPassword"
        type={InputTypes.password}
        formik={formik}
        placeholder={confirmPwPlaceholder}
        id="confirmPassword"
        label={strings.confirmPassword}
      />
    </LoginCustomCard>
  );
};

export default CreateNewPassword;
