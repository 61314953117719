import { SxProps, Theme } from '@mui/system';

const styles = {
  total: {
    fontWeight: 700,
    color: 'primary.main',
    fontSize: 17,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
