import React, { FC, useEffect } from 'react';
import { FormikValues, useFormik } from 'formik';
import { Box } from '@mui/material';
import {
  createAvailability,
  getRecurrence,
  pharmacistActions,
  preSubmitAvailabilities,
  reset,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { DateTime } from 'luxon';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { makeReqParams, setJSHour } from '../../../helpers/workshift/functions';
import { DurationTypes } from '../../../helpers/Constants';
import {
  resetDrawer,
  setButtons,
  setPagination,
} from '../../../actions/drawerActions';
import AddUpdateEvents from '../../common/AddUpdateEvents/AddUpdateEvents';
import strings from '../../../localization';
import NotesField from '../../common/NotesField/NotesField';
import { getDisableAvailability } from '../../../selectors/calendarSelector';
import useRecurrence from '../../../hooks/useRecurrence';
import useAvailabilitiesCriteria from '../../../hooks/useAvailabilitiesCriteria';

import classes from '../styles';
import Validator from '../Validator';

interface IAddAvailability {
  date: string;
  showMultiple: boolean;
  handleSetSave: (callback: () => void) => () => void;
  saveAndRemain: boolean;
}

const AddAvailability: FC<IAddAvailability> = ({
  date,
  showMultiple,
  handleSetSave,
  saveAndRemain,
}) => {
  const dispatch = useAppDispatch();

  const recurrence = useAppSelector(getRecurrence);
  const disabled = useAppSelector(getDisableAvailability);

  const availabilitySuccess = useAppSelector((state) =>
    successSelector([pharmacistActions.createAvailability], state));

  const timeSet = DateTime.now();
  const luxonDate = date ? DateTime.fromISO(date) : timeSet;

  const handleSubmit = (values: FormikValues) => {
    dispatch(preSubmitAvailabilities(makeReqParams(values, recurrence)));
  };

  const formik = useFormik({
    initialValues: {
      selectedDate: luxonDate,
      allday: false,
      startTime: null,
      endTime: null,
      notes: '',
      recurrence: DurationTypes.notRepeat,
      selectedDates: [],
    },
    onSubmit: handleSubmit,
    validationSchema: Validator(),
  });

  const { values } = formik ?? {};

  useRecurrence(formik);

  const setUserWorkTimings = () => {
    formik.setFieldValue('startTime', setJSHour(luxonDate, 8));
    formik.setFieldValue('endTime', setJSHour(luxonDate, 22));
  };

  const dispatchCreateAvailability = () => {
    dispatch(createAvailability(makeReqParams(values, recurrence)));
  };

  useAvailabilitiesCriteria(dispatchCreateAvailability, !showMultiple);

  useEffect(() => {
    dispatch(setPagination({ show: false }));
    if (!showMultiple) {
      dispatch(
        setButtons({
          show: true,
          primaryButton: {
            label: strings.create,
            disabled: !disabled,
            onClick: formik.handleSubmit,
          },
          secondaryButton: {
            label: strings.saveAndCreate,
            onClick: handleSetSave(formik.handleSubmit),
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, showMultiple]);

  useEffect(() => {
    if (saveAndRemain && availabilitySuccess) {
      formik.resetForm();
    }
  }, [availabilitySuccess]);

  return (
    <Box sx={classes.container}>
      <AddUpdateEvents
        formik={formik}
        disableAll={showMultiple}
        setUserWorkTimings={setUserWorkTimings}
        isSeries
      />
      <NotesField formik={formik} disabled={showMultiple} />
    </Box>
  );
};

export default AddAvailability;
