import * as Yup from 'yup';
import strings from '../../../localization';
import { latitudeValidator } from '../PharmacySignupFlow/Validator';
import { nameRegex, onlyNumbersRegex, postalCodeRegExp } from '../../../helpers/Constants';

export const GeneralValidator = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.alphaOnly),
    lastName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.alphaOnly),
    preferdLanguage: Yup.string().required(strings.requiredField),
  });

export const ProfessionalValidator = () =>
  Yup.object().shape({
    licenseNumber: Yup.string()
      .matches(
        onlyNumbersRegex,
      strings.formatString(
        strings.numberValidation,
        strings.licenseNo,
      ) as string,
      )
      .required(strings.requiredField),
    pharmacistCapacityId: Yup.string().required(strings.requiredField),
    graduationYear: Yup.string().required(strings.requiredField),
    otherLanguages: Yup.string(),
  });
export const SoftwareValidator = () =>
  Yup.object().shape({});
export const ContactValidator = () =>
  Yup.object().shape({
    province: Yup.string().required(strings.requiredField),
    address: Yup.string().required(strings.requiredField),
    city: Yup.string().required(strings.requiredField),
    postalCode: Yup.string()
      .required(strings.requiredField)
      .matches(postalCodeRegExp, strings.postalCodeValidation),
    phone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
      strings.formatString(strings.phoneValidation, strings.homePhone) as string,
      ),
    mobile: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
      strings.formatString(strings.phoneValidation, strings.mobile) as string,
      ),
    latitude: latitudeValidator(),
  });
