import { useEffect, useMemo } from 'react';

import { FormikValues, useFormik } from 'formik';
import { DateTime } from 'luxon';

import {
  adminActions,
  adminPreSubmitVacations,
  allDayEnd,
  allDayStart,
  deletePharmacistVacations,
  getPharmacistVacations,
  pharmacistActions,
  preSubmitVacations,
  resetStatus,
  selectPharmacistVacations,
  successOrSelector,
  updatePharmacistVacations,
} from '@pharmaplan/common';

import { appendTimeToDate, setHour } from '../../helpers/workshift/functions';
import strings from '../../localization';
import {
  resetDrawer,
  setButtons,
  setPagination,
  setTitle,
} from '../../actions/drawerActions';
import { Constants, MomentTimeFormats } from '../../helpers/Constants';
import { ICreateVacation } from '../../components/common/CreateVacation/CreateVacation';
import { useAppSelector } from '../useAppSelector';
import useUser from '../useUser';
import { useAppDispatch } from '../useAppDispatch';
import useVacationsCriteria from './useVacationsCriteria';
import useAdmin from '../useAdmin';
import { getDisabledSubmitFilter } from '../../selectors/calendarSelector';
import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';

const { fullDate } = MomentTimeFormats;
const { text, contained } = Constants.variant;

const preSubmitSuccessActions = [
  pharmacistActions.preSubmitVacations,
  adminActions.adminPreSubmitVacations,
];
const createSuccessActions = [
  pharmacistActions.vacations,
  adminActions.adminPharmacistVacations,
];
const deleteSuccessAction = [pharmacistActions.deleteVacations];

const useCreateVacation = ({
  date,
  id,
  pharmacistId,
  pharmacistName,
}: ICreateVacation) => {
  const dispatch = useAppDispatch();

  const { isAdmin } = useAdmin();

  const vacationDetails = useAppSelector(selectPharmacistVacations);
  const disableSubmit = useAppSelector(getDisabledSubmitFilter);

  const success = useAppSelector((state) =>
    successOrSelector(preSubmitSuccessActions, state));

  const createSuccess = useAppSelector((state) =>
    successOrSelector(createSuccessActions, state));

  const deleteSuccess = useAppSelector((state) =>
    successOrSelector(deleteSuccessAction, state));

  const { notes } = vacationDetails ?? {};

  const currentDate = useMemo(() =>
    DateTime.now(), []);

  const luxonDate = date ? DateTime.fromISO(date) : currentDate;

  const { name } = useUser();
  const { createVacations } = useVacationsCriteria();

  const onSubmit = (values: FormikValues) => {
    const {
      startDate,
      startTime,
      endDate,
      endTime,
      notes: formikNotes,
    } = values ?? {};

    if (id) {
      dispatch(
        updatePharmacistVacations({
          vacationId: id,
          notes: formikNotes,
        }),
      );
      return;
    }

    if (isAdmin) {
      dispatch(
        adminPreSubmitVacations({
          pharmacistId,
          startDate: appendTimeToDate(startDate, startTime).toFormat(fullDate),
          endDate: appendTimeToDate(endDate, endTime).toFormat(fullDate),
          selectedDates: [],
        }),
      );
      return;
    }

    dispatch(
      preSubmitVacations({
        startDate: appendTimeToDate(startDate, startTime).toFormat(fullDate),
        endDate: appendTimeToDate(endDate, endTime).toFormat(fullDate),
        selectedDates: [],
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      pharmacistId,
      pharmacist: isAdmin ? pharmacistName : name,
      startDate: luxonDate ?? currentDate,
      endDate: luxonDate ?? currentDate,
      startTime: setHour(luxonDate, allDayStart),
      endTime: setHour(luxonDate, allDayEnd),
      notes: id ? notes : '',
    },
    onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, values } = formik ?? {};

  const buttonConfig = id
    ? {
      primaryButton: {
        variant: contained,
        label: strings.saveChanges,
        disabled: !disableSubmit,
        onClick: handleSubmit,
      },
      secondaryButton: {
        label: strings.delete,
        variant: text,
        onClick: () => {
          dispatch(deletePharmacistVacations(id));
        },
      },
    }
    : {
      primaryButton: {
        variant: contained,
        label: strings.create,
        disabled: !disableSubmit,
        onClick: handleSubmit,
      },
    };

  useEffect(() => {
    if (id) {
      dispatch(setTitle({ title: strings.updateVacation }));
      dispatch(getPharmacistVacations(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setButtons({
        show: true,
        ...buttonConfig,
      }),
    );

    dispatch(setPagination({ show: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableSubmit]);

  useEffect(() => {
    if (success) {
      createVacations(values);
      dispatch(resetStatus(preSubmitSuccessActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (createSuccess) {
      showSuccess(dispatch, strings.vacationCreatedSuccessfully);
      dispatch(resetStatus(createSuccessActions));
      isAdmin && dispatch(resetDrawer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      showSuccess(dispatch, strings.deletedSuccessfully);
      dispatch(resetStatus(deleteSuccessAction));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  return { formik };
};

export default useCreateVacation;
