import React from 'react';

import { Route, Navigate } from 'react-router-dom';

import {
  getIsUserSignedUp,
  DashboardType,
  didRequestChangeEmail,
  isPharmacyAdminLoginSelector,
} from '@pharmaplan/common';

import LogoutHandler from '@pharmaplan/common/helpers/LogoutHandler';
import History from '../components/MyReplacements/History';
import ChangePassword from '../components/ChangePassword';
import MyReplacements from '../components/MyReplacements';
import NotFound from '../components/NotFound';
import Profile from '../components/Profile';
import Reports from '../components/Reports';
import Home from '../components/Home';
import Dashboard from '../components/Dashboard';
import Help from '../components/Help';
import Notes from '../components/MyReplacements/Notes';
import IncomeReport from '../components/Reports/IncomeReport';
import useAdmin from '../hooks/useAdmin';
import { useAppSelector } from '../hooks/useAppSelector';
import { Constants } from '../helpers/Constants';

import ProtectedRoute from './ProtectedRoute';
import SetEmail from '../components/ChangeEmail/SetEmail';
import GuestLayout from '../components/GuestLayout';
import OtpForm from '../components/Login/OtpForm';
import AddEmailPassword from '../components/ChangeEmail/AddEmailPassword';

const {
  home,
  dashboard,
  calendarDashboard,
  reports,
  incomeReport,
  profile,
  help,
  notes,
  history,
  changePassword,
  myReplacements,
  setNewEmail,
  verifyOtp,
  setFlow,
  addPassword,
} = Constants.paths;

const PharmaRouter = () => {
  const { isAdmin } = useAdmin();

  const user = !!LogoutHandler.isUserLoggedIn();
  const isUserSignedUp = useAppSelector(getIsUserSignedUp);
  const didUserRequestChangeEmail = useAppSelector(didRequestChangeEmail);
  const isPharmacyAdminLogin = useAppSelector(isPharmacyAdminLoginSelector);

  const isAuthenticatedUser = (user && !isUserSignedUp) || isPharmacyAdminLogin;
  const isAuthenticatedPharma = !isAdmin && isAuthenticatedUser;

  return (
    <Route
      path={home}
      element={<ProtectedRoute authenticated={isAuthenticatedPharma} />}
    >
      <Route path={home} element={<Home />}>
        <Route
          path={home}
          element={<Navigate replace to={calendarDashboard} />}
        />

        <Route
          path={dashboard}
          element={<Navigate replace to={calendarDashboard} />}
        />

        <Route path={dashboard} element={<Dashboard />}>
          <Route path={DashboardType.calendar} element={<Dashboard />} />
          <Route path={DashboardType.map} element={<Dashboard />} />
        </Route>
        <Route path={profile} element={<Profile />} />
        <Route path={myReplacements} element={<MyReplacements />} />
        <Route path={help} element={<Help />} />
        <Route path={reports} element={<Reports />} />
        <Route path={incomeReport} element={<IncomeReport />} />
        <Route path="*" element={<NotFound />} />
        <Route path={history} element={<History />} />
        <Route path={notes} element={<Notes />} />
        <Route path={changePassword} element={<ChangePassword />} />
      </Route>
      <Route path={setFlow} element={<GuestLayout />}>
        <Route path={setNewEmail} element={<SetEmail />} />
        <Route
          element={<ProtectedRoute authenticated={didUserRequestChangeEmail} />}
        >
          <Route path={verifyOtp} element={<OtpForm auth />} />
          <Route path={addPassword} element={<AddEmailPassword />} />
        </Route>
      </Route>
    </Route>
  );
};

export default PharmaRouter;
