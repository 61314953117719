import { SxProps, Theme } from '@mui/system';

const classes = {
  root: {
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  messageFont: {
    color: 'common.blue400',
    fontSize: 16,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
