import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { pharmacistRegionList } from '@pharmaplan/common';

import CustomAccordion from '../../../common/CustomAccordion';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useGeographicAvailability from '../../../../hooks/Admin/Profile/useGeographicAvailability';
import strings from '../../../../localization';

import AdminPharmacistCard from '../Pharmacist/PharmacistViewProfile/AdminPharmacistCard';
import { IPharmacistViewProfile } from '../Pharmacist/PharmacistViewProfile/PharmacistViewProfile';
import styles from './style';
import { ICustomBack } from '../../../../actions/drawerActions';

export interface IAdminGeographicAvailability extends IPharmacistViewProfile {
  back?: ICustomBack;
}

const AdminGeographicAvailability: FC<IAdminGeographicAvailability> = ({
  pharmacistId,
  fromScreen,
  type,
  back,
  hasOwner,
}) => {
  const regions = useAppSelector(pharmacistRegionList);

  const { formik } = useGeographicAvailability({
    pharmacistId,
    fromScreen,
    back,
    type,
    hasOwner,
  });

  return (
    <Box>
      <AdminPharmacistCard
        fromScreen={fromScreen}
        hasOwner={hasOwner}
        type={type}
        pharmacistId={pharmacistId}
        showMenu={false}
      />

      <Box sx={styles.container}>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.titleFont}>
            {strings.pharmacistGeographicAvailability}
          </Typography>
        </Box>

        <CustomAccordion
          showTitle={false}
          formik={formik as any}
          items={regions}
        />
      </Box>
    </Box>
  );
};

export default AdminGeographicAvailability;
