import { ServiceTypes } from "./Constants"

const ServiceHandler = () => {
  let service = ServiceTypes.full;
  const setService = (inService: ServiceTypes) => {
    service = inService;
  }
  const getService = () => service;
  return { setService, getService };
}

export default ServiceHandler();