import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
  deleteNote,
  deletePharmacyNote,
  getNotes,
  getPharmacyNotes,
  getTypeOfUser,
  INotes,
  IPharmacyNotes,
  pharmacistActions,
  pharmacyActions,
  resetStatus,
  successOrSelector,
  ToUserTypings,
  TypeOfUser,
} from '@pharmaplan/common';
import { ReactComponent as PlusIcon } from '@pharmaplan/common/assets/icons/plusIcon.svg';

import AdsContainer from '../../common/AdsContainer';
import CustomButton from '../../common/CustomButton';
import DynamicTable from '../../DynamicTable';
import { NavType } from '../../../helpers/Constants';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useUser from '../../../hooks/useUser';
import strings from '../../../localization';
import { setDialog } from '../../../reducers/dialogReducer';
import ResponsiveClasses from '../../../theme/ResponsiveClasses';

import { pharmacistNotesMapper, pharmacyNotesMapper } from '../helpers';
import AddNoteModal from './AddNoteModal';

const CreateNote = () =>
  (
    <CustomButton
      variant="outlined"
      startIcon={<PlusIcon />}
      onClick={() => {
        console.log('du');
      }}
      label={strings.createNewNote}
    />
  );

const Notes = () => {
  const dispatch = useAppDispatch();
  const { notes } = useUser();
  const { state } = useLocation();

  const { id, name } = state;

  const userType = useAppSelector(getTypeOfUser);

  const loadSuccess = useAppSelector((st) =>
    successOrSelector([pharmacistActions.getNotes, pharmacyActions.notes], st));

  const success = useAppSelector((st) =>
    successOrSelector(
      [
        pharmacistActions.createNote,
        pharmacistActions.updateNote,
        pharmacistActions.deleteNote,
        pharmacyActions.createNote,
        pharmacyActions.updateNote,
        pharmacyActions.deleteNote,
      ],
      st,
    ));

  const OpenModal = (noteId: string, update: boolean) => {
    dispatch(
      setDialog({
        showCloseButton: false,
        Component: <AddNoteModal update={update} id={noteId} />,
        heading: {
          title: '',
        },
      }),
    );
  };

  const handleDelete = (noteId: string) => {
    dispatch(
      userType === TypeOfUser.pharmacist
        ? deleteNote(noteId)
        : deletePharmacyNote(noteId),
    );
  };

  const userConfig = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      label: strings.pharmacy,
      getNotes,
      rows: pharmacistNotesMapper(notes as INotes[], OpenModal, handleDelete),
    },
    [TypeOfUser.pharmacy]: {
      label: strings.pharmacist,
      getNotes: getPharmacyNotes,
      rows: pharmacyNotesMapper(
        notes as IPharmacyNotes[],
        OpenModal,
        handleDelete,
      ),
    },
  });

  const Table = {
    title: `${strings.formatString(
      strings.myUserNotes,
      userConfig[userType].label,
    )} [${name}]`,
    headers: [
      { key: '0', label: strings.notes },
      { key: '1', label: strings.created },
      { key: '2', label: strings.updated },
      { key: '3', label: strings.action },
    ],
    headerBar: [
      {
        Component: <CreateNote />,
        key: '1',
        onClick: () =>
          OpenModal(id, false),
      },
    ],
    rows: userConfig[userType].rows,
  };

  useEffect(() => {
    dispatch(userConfig[userType].getNotes(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(userConfig[userType].getNotes(id));
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={10}>
        <DynamicTable loadSuccess={loadSuccess} table={Table} hidePagination type={NavType.notes} />
      </Grid>
      <Grid item xs={2} sx={ResponsiveClasses.hideOnLg}>
        <AdsContainer />
      </Grid>
    </Grid>
  );
};

export default Notes;
