import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const styles = {
  autoCompleteContainer: {
    marginLeft: '10px',
  },
  autoComplete: {
    '& .MuiAutocomplete-inputRoot': {
      paddingBottom: '2px',
    },
  },
  textFieldStyle: {
    backgroundColor: 'secondary.main',
  },
  labelStyle: {
    marginBottom: '5px',
  },
  dropdownContainer: {
    ml: '18px',
  },
  deleteContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    alignItems: 'center',
  },
  checkboxContainer: {
    marginRight: '5px',
  },
  ownerDropdown: {
    maxHeight: 320,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
