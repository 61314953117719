import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterContainer: {
    display: 'flex',
  },
  title: {
    color: 'common.grey850',
    fontSize: 21.7,
    fontWeight: '600',
    mb: '24px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
