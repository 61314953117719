import React, { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { FormikValues } from 'formik';

import { getEmail, getTypeOfUser, TypeOfUser } from '@pharmaplan/common';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import useSignupFlow from '../../hooks/useSignupFlow/useSignupFlow';
import { setData } from '../../actions/persistActions';
import strings from '../../localization';

import PharmacistSignupFlow from './PharmacistSignupFlow';
import PharmacySignupFlow from './PharmacySignupFlow';
import SignupStepper from './SignupStepper';
import styles from './styles';

const { pharmacist } = TypeOfUser;

const SignupFlow = () => {
  const dispatch = useAppDispatch();

  const [step, setStep] = useState(0);

  const userType = useAppSelector(getTypeOfUser);
  const email = useAppSelector(getEmail);

  const isPharmacist = userType === pharmacist;

  const title = strings.formatString(
    strings.signupAs,
    isPharmacist ? strings.pharmacist : strings.pharmacy,
  );

  const changeStep = (steps: object, values: FormikValues, type: string) => {
    dispatch(
      setData({
        userType,
        type,
        data: { ...values },
        email,
      }),
    );

    if (step < Object.keys(steps).length - 1) {
      setStep(step + 1);
    }

    return null;
  };

  const userProfile = () => {
    if (isPharmacist) {
      return (
        <PharmacistSignupFlow
          setStep={setStep}
          step={step}
          changeStep={changeStep}
        />
      );
    }

    return (
      <PharmacySignupFlow
        setStep={setStep}
        step={step}
        changeStep={changeStep}
      />
    );
  };

  useSignupFlow();

  return (
    <Box sx={styles.boxContainer}>
      <Grid lg={8} sx={styles.container} container direction="column">
        <Typography color="primary" sx={styles.title}>
          {title}
        </Typography>

        <SignupStepper step={step} setStep={setStep} />
        {userProfile()}
      </Grid>
    </Box>
  );
};

export default SignupFlow;
