import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const crossMarkSize = 20;
const imageDimensions = {
  width: 700,
  height: 500,
};

export const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 99,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  displayContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
  },
  crossContainer: {
    height: crossMarkSize,
    maxWidth: crossMarkSize,
    minWidth: crossMarkSize,
    borderRadius: crossMarkSize / 2,
    padding: 0,
    backgroundColor: ThemeConstants.common.lightBackgroundColor,
    cursor: 'pointer',
    position: 'absolute',
    top: 5,
    right: 6,
    '&:hover': {
      backgroundColor: ThemeConstants.common.lightBackgroundColor,
    },
  },
  imageContainer: {
    position: 'relative',
    maxHeight: imageDimensions.height,
    maxWidth: imageDimensions.width,
    height: 'auto',
    width: 'auto',
  },
  image: {
    maxHeight: imageDimensions.height,
    maxWidth: imageDimensions.width,
    borderRadius: 4,
  },
} satisfies Record<string, SxProps<Theme>>;
