import { useEffect } from 'react';

import { FormikProps } from 'formik';

import {
  HelpTypes,
  searchedPharmacies,
  searchedPharmacists,
  TypeOfUser,
} from '@pharmaplan/common';
import { toOptions } from '../../../components/Admin/ExpressBooking/ExpressBooking';
import { Constants } from '../../../helpers/Constants';
import { useAppSelector } from '../../useAppSelector';
import useAutoComplete from '../../useAutocomplete';
import useAutoCompleteScroll, {
  AutoCompleteScrollTypes,
} from '../../useAutoCompleteScroll';

interface IUseLogsAutocomplete {
  formik: FormikProps<any>;
  autocompleteType: AutoCompleteScrollTypes;
}
const { workshiftLogs, availabilityLogs, bookingLogs } = Constants.paths.admin;

const useLogsAutocomplete = ({
  formik,
  autocompleteType,
}: IUseLogsAutocomplete) => {
  const pharmacists = useAppSelector(searchedPharmacists);
  const pharmacies = useAppSelector(searchedPharmacies);

  const pharmacistOptions = toOptions(pharmacists);
  const pharmacyOptions = toOptions(pharmacies);

  const { debouncedInput: pharmacyInput } = useAutoComplete({
    userTypes: [HelpTypes.pharmacy],
  });
  const { debouncedInput: pharmacistInput } = useAutoComplete({
    userTypes: [HelpTypes.pharmacist],
  });

  const { onEndReached, pharmacistListData, pharmacyListData } = useAutoCompleteScroll(autocompleteType);
  const { pharmacists: formikPharmacists, pharmacies: formikPharmacies } = formik.values;

  const pharmacistOption = formikPharmacists
    ? pharmacistOptions
    : pharmacistListData;
  const pharmacyOption = formikPharmacies ? pharmacyOptions : pharmacyListData;

  const autocompleteConfig = {
    [workshiftLogs]: {
      option: pharmacyOption,
      formikType: formikPharmacies,
      idName: 'pharmacyId',
      textName: 'pharmacies',
      autoCompleteName: 'pharmacySelected',
      endType: TypeOfUser.pharmacy,
    },
    [availabilityLogs]: {
      option: pharmacistOption,
      formikType: formikPharmacists,
      idName: 'pharmacistId',
      textName: 'pharmacists',
      autoCompleteName: 'pharmacistSelected',
      endType: TypeOfUser.pharmacist,
    },
    [bookingLogs]: {
      option: pharmacyOption,
      formikType: formikPharmacies,
      idName: 'pharmacyId',
      textName: 'pharmacies',
      autoCompleteName: 'pharmacySelected',
      endType: TypeOfUser.pharmacy,
    },
  };

  useEffect(() => {
    pharmacyInput(formikPharmacies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikPharmacies]);

  useEffect(() => {
    pharmacistInput(formikPharmacists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikPharmacists]);

  return {
    pharmacyOption,
    pharmacistOption,
    onEndReached,
    formikPharmacies,
    formikPharmacists,
    autocompleteConfig,
  };
};

export default useLogsAutocomplete;
