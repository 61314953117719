import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  button: {
    mt: 1,
  },
  radioButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 2,
  },
  error: {
    color: ThemeConstants.common.error,
    fontSize: 12,
    marginLeft: '10px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
