import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { adminActions, adminGetAllAdvertisements, resetStatus, successSelector } from '@pharmaplan/common';

import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import { useAppSelector } from '../../useAppSelector';
import { useAppDispatch } from '../../useAppDispatch';
import strings from '../../../localization';

const { createAdvertisement, updateAdvertisement, deleteAdvertisement } = adminActions;

const useAdvertisingSuccess = (handleReset?: () => void) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createSuccess = useAppSelector((state) =>
    successSelector([createAdvertisement], state));
  const updateSuccess = useAppSelector((state) =>
    successSelector([updateAdvertisement], state));
  const deleteSuccess = useAppSelector((state) =>
    successSelector([deleteAdvertisement], state));

  useEffect(() => {
    if (createSuccess) {
      showSuccess(dispatch, strings.advertisementCreatedSuccessfully);
      dispatch(resetStatus([createAdvertisement]));
      navigate(-1);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      showSuccess(dispatch, strings.advertisementUpdateSuccessfully);
      dispatch(resetStatus([updateAdvertisement]));
      navigate(-1);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      showSuccess(dispatch, strings.advertisementDeletedSuccessfully);
      dispatch(resetStatus([deleteAdvertisement]));
      dispatch(adminGetAllAdvertisements({}));
      handleReset?.();
    }
  }, [deleteSuccess]);
};

export default useAdvertisingSuccess;
