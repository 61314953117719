import { createSlice } from '@reduxjs/toolkit';
import IDialog from '../types/IDialog';

interface IDialogState {
  open: boolean;
  dialog: IDialog | Record<string, never>;
}

interface IDialogAction {
  type: string;
  payload: IDialog;
}

const initialState: IDialogState = {
  open: false,
  dialog: {},
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setDialog(state, action: IDialogAction) {
      state.open = true;
      state.dialog = action.payload;
    },

    resetDialog(state) {
      state.open = false;
      state.dialog = {};
    },
  },
});

const { reducer, actions } = modalSlice;

export const { setDialog, resetDialog } = actions;
export default reducer;
