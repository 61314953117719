import React, { useEffect } from 'react';

import { useFormik } from 'formik';

import {
  IPharmacyInfoParams,
  convertToDecimals,
  getLanguages,
  getPharmacyInfo,
  language,
  resetUpdateOperations,
  successSelector,
  updatePharmacyInfo,
  userActions,
  userPharmacyInfo,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useLanguage from '../../../../hooks/useLanguge';
import useSelfService from '../../../../hooks/useSelfService';
import { ICommonProfile } from '../../PharmacistFlow/General/General';

import { PharmacyInfoValidator } from '../../FormValidation';
import ProfileForm from '../../ProfileForm';

const PharmacyInfo = ({ setSelected }: ICommonProfile) => {
  const dispatch = useAppDispatch();
  const { isSelfService } = useSelfService();
  const { changeLang } = useLanguage();

  const general = useAppSelector(userPharmacyInfo);
  const languagesArr = useAppSelector(getLanguages);
  const currentLang = useAppSelector(language);

  const generalSaveSuccess = useAppSelector((state) =>
    successSelector([userActions.updatePharmacyInfo], state));

  const onSubmit = (values: IPharmacyInfoParams) => {
    dispatch(updatePharmacyInfo(values));
  };

  const formik = useFormik({
    initialValues: {
      ...general,
      hourlyRate: convertToDecimals(general.hourlyRate) as number,
      isSelfService,
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: PharmacyInfoValidator(),
  });

  const { values } = formik ?? {};
  const { languageId } = values ?? {};

  useEffect(() => {
    if (generalSaveSuccess) {
      const userSelectedLang = languagesArr.filter(
        (lang) =>
          lang.languageId === languageId,
      );

      const isCurrentAndUserLangSame = userSelectedLang[0]?.code === currentLang;

      if (!isCurrentAndUserLangSame) {
        changeLang((userSelectedLang[0]?.code as string) || currentLang);
        dispatch(resetUpdateOperations());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSaveSuccess]);

  useEffect(() => {
    dispatch(getPharmacyInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="pharmacyInfo"
      formik={formik}
    />
  );
};

export default PharmacyInfo;
