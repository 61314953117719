import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  horizontalTextField: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      flex: 2,
      marginBottom: 3,
    },
    '& .MuiTextField-root': {
      flex: 4,
    },
  },

  customTextFieldRequireLabel: {
    color: 'error.main',
    marginLeft: '2px',
  },

  customTextFieldPasswordIcon: {
    position: 'absolute',
    right: '9px',
    top: '33px',
    cursor: 'pointer',
  },

  filledInput: {
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottom: 'none',
        content: "''",
        position: 'relative',
        border: 'none',
      },
      '&:after': {
        borderBottom: 'none',
        border: 'none',
      },
      padding: '0px 9px 8px',
      fontSize: 14,
      backgroundColor: 'common.lightBackgroundColor',
    },
  },

  suffix: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'grey.500',
  },

  hintContainer: {
    display: 'flex',
    marginBottom: '3px',
  },

  infoIcon: {
    color: ThemeConstants.common.hint,
    marginTop: '2px',
    marginRight: '2px',
  },

  hintText: {
    color: ThemeConstants.common.hint,
  },

  cursor: {
    cursor: 'pointer',
  },

  capitalize: {
    textTransform: 'uppercase',
  },

  errorContainer: {
    mt: 1,
    mb: 1,
  },

  customErrorMessage: {
    fontSize: 12,
    color: 'common.error',
  },

  customHelperText: {
    fontSize: 10,
    color: 'grey.500',
    textAlign: 'right',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
