import { useEffect } from 'react';

import { FormikProps } from 'formik';
import { Info } from 'luxon';

import { getRecurrence, Ordinals, setRecurrence } from '@pharmaplan/common';
import { MomentTimeFormats } from '../helpers/Constants';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const { dayOfTheMonth, fullmonthname, dayOfWeek } = MomentTimeFormats;
const { first } = Ordinals;
const useRecurrence = (formik: FormikProps<any>) => {
  const dispatch = useAppDispatch();
  const recurrence = useAppSelector(getRecurrence);

  const { selectedDate } = formik.values ?? {};
  const dateString = JSON.stringify(selectedDate);

  useEffect(() => {
    dispatch(
      setRecurrence({
        ...recurrence,
        onDay: selectedDate.toFormat(dayOfTheMonth),
        onThe: first,
        onTheDay: (selectedDate.toFormat(dayOfWeek) % 7).toString(),
        endDate: selectedDate.toISO() ?? '',
        onTheMonth: `${
          Info.months('long').indexOf(selectedDate.toFormat(fullmonthname)) + 1
        }`,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateString]);
};

export default useRecurrence;
