import { renderScreen } from '../../actions/drawerActions';
import { ScreenTypes } from '../../helpers/Constants';
import strings from '../../localization';
import { useAppDispatch } from '../useAppDispatch';

type actionsParams = { pharmacistId: string; pharmacyId: string };

const actions = (goToNotes: () => void, goToHistory: () => void) =>
  [
    {
      key: '1',
      onClick: goToHistory,
      label: strings.history,
    },
    {
      key: '2',
      onClick: goToNotes,
      label: `${strings.pharmacist} ${strings.notes}`,
    },
  ];

const useListOfPharmacies = () => {
  const dispatch = useAppDispatch();

  const goToHistory = (params: actionsParams) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.pharmacistList,
          screenNumber: 6,
          ...params,
        }),
      );
    };

  const goToNotes = (params: actionsParams) =>
    () => {
      dispatch(
        renderScreen({
          screenType: ScreenTypes.pharmacistList,
          screenNumber: 7,
          ...params,
        }),
      );
    };

  return {
    menuAction: (params: actionsParams) =>
      actions(goToNotes(params), goToHistory(params)),
  };
};

export default useListOfPharmacies;
