import React, { ChangeEvent } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TooltipRenderProps } from 'react-joyride';
import { useFormik } from 'formik';
import { Cookies } from 'react-cookie';

import { startTour } from '@pharmaplan/common';

import CustomButton from '../common/CustomButton/CustomButton';
import CustomCheckbox from '../common/CustomCheckbox/CustomCheckbox';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Constants } from '../../helpers/Constants';
import strings from '../../localization';

import classes from './styles';

type voidFunc = () => void;

const GuideTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  isLastStep,
  skipProps,
  size,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => {
  const nextButton = `${strings.next} (${index + 1}/${size})`;
  const endButton = `${strings.end} (${index + 1}/${size})`;
  const cookies = new Cookies();

  const isReplay = useAppSelector(startTour);

  const formik = useFormik({
    initialValues: {
      skipConfirmation: false,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const handleSkipCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('skipConfirmation', e.target.checked);
    cookies.set('tourCompleted', true, {
      path: '/',
      maxAge: Constants.cookieExpiry,
    });
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box sx={classes.tooltip} {...tooltipProps}>
      {step.title && <Typography>{step.title}</Typography>}
      <Typography sx={classes.text}>{step.content}</Typography>
      <Box sx={classes.checkBoxContainer}>
        {!isReplay && (
          <CustomCheckbox
            id="skipConfirmation"
            name="skipConfirmation"
            handleChange={handleSkipCheckBox}
            value={formik.values.skipConfirmation}
            label={strings.skipConfirmation}
          />
        )}
      </Box>
      <Box sx={classes.footer}>
        {!isLastStep && (
          <Box sx={classes.skipButtonContainer}>
            <CustomButton
              variant="outlined"
              type="button"
              label={strings.skip}
              onClick={skipProps.onClick as voidFunc}
            />
          </Box>
        )}
        {index > 0 && (
          <Box sx={classes.buttonContainer}>
            <CustomButton
              variant="outlined"
              type="button"
              label={strings.backGuide}
              onClick={backProps.onClick as voidFunc}
            />
          </Box>
        )}
        {continuous && !isLastStep && (
          <CustomButton
            id="next"
            type="button"
            label={nextButton}
            onClick={primaryProps.onClick as voidFunc}
          />
        )}
        {isLastStep && (
          <CustomButton
            id="skip"
            type="button"
            label={endButton}
            onClick={closeProps.onClick as voidFunc}
          />
        )}
      </Box>
    </Box>
  );
};

export default GuideTooltip;
