import React, { FC } from 'react';

import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import toast, { Toast } from 'react-hot-toast';
import { Close } from '@mui/icons-material';

import { getNotification, NotificationsType } from '@pharmaplan/common';
import { ReactComponent as CheckMark } from '../../assets/svg/checkMarkOutline.svg';
import genericClasses from '../../theme/GenericClasses';

import useNotification from '../../hooks/useNotification';
import useNotificationActions from '../../hooks/PushNotifications/useNotificationActions';
import { useAppDispatch } from '../../hooks/useAppDispatch';

import styles from './styles';

interface IToastNotification {
  data: {
    title: string;
    body: string;
    notificationType: NotificationsType;
    bookingId: string;
    notificationId: string;
  };
  toastParams: Toast;
}

const ToastNotification: FC<IToastNotification> = ({ toastParams, data }) => {
  const dispatch = useAppDispatch();

  const { title, body, notificationType, bookingId, notificationId } = data ?? {};

  const handleDismiss = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e?.stopPropagation();
    toast.dismiss(toastParams.id);
  };

  const visitNotifications = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    () => {
      dispatch(getNotification(notificationId));
      e?.stopPropagation();
    };

  const { handleActions } = useNotification(handleDismiss);
  const { confirmAction } = useNotificationActions();

  const showApproveButton = Object.hasOwn(confirmAction, notificationType);

  return (
    <Box
      onClick={() =>
        handleActions(data as any)}
      sx={[genericClasses.flex, styles.container]}
    >
      <Box sx={styles.content}>
        <Typography sx={styles.title}>{title}</Typography>
        <Typography sx={styles.body}>{body}</Typography>
      </Box>
      <Box>
        {showApproveButton && (
          <IconButton
            onClick={(e) =>
              confirmAction[notificationType as keyof typeof confirmAction]({
                bookingId,
                callback: visitNotifications(e),
              })()}
          >
            <CheckMark />
          </IconButton>
        )}
        <IconButton onClick={handleDismiss}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ToastNotification;
