import React, { FC } from 'react';

import { Box, SxProps } from '@mui/system';
import { Typography } from '@mui/material';

import WithSkeleton from '../../../common/WithSkeleton';

import classes from './styles';

interface IPharmacyOperationContent {
  operations: Array<{
    key: string;
    volume: string;
    container: SxProps;
    data: Array<{
      key: string;
      name: string;
      icon: React.JSX.Element;
      pharmacist: string;
      technician: string | boolean;
    }>;
  }>;
  showTechnician?: boolean;
  successAction: string;
}

const {
  pharmacist,
  technician,
  shiftText,
  shiftContainer,
  mainContainer,
  title,
} = classes ?? {};

const PharmacyOperationContent: FC<IPharmacyOperationContent> = ({
  operations,
  showTechnician,
  successAction,
}) => {
  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: 'text',
  })(Typography);

  return (
    <>
      {operations.map((operation) =>
        (
          <Box key={operation.key} sx={operation.container}>
            <SkeletonTypography sx={title}>{operation.volume}</SkeletonTypography>

            <Box sx={mainContainer}>
              {operation?.data?.map((item) => {
                const Icon = item?.icon;
                return (
                  <Box key={item.key}>
                    <Box sx={shiftContainer}>
                      {Icon}
                      <SkeletonTypography sx={shiftText}>
                        {item.name}
                      </SkeletonTypography>
                    </Box>

                    <SkeletonTypography sx={pharmacist}>
                      {item.pharmacist}
                    </SkeletonTypography>
                    {showTechnician && (
                    <SkeletonTypography sx={technician}>
                      {item.technician}
                    </SkeletonTypography>
                    ) }
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
    </>
  );
};

export default PharmacyOperationContent;
