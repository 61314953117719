import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: '20px 24px 32px',
  },
  layout: {
    display: 'flex',
    width: '100%',
    my: '8px',
  },
  title: {
    fontSize: 24,
    color: 'grey.500',
    m: '32px 0px 24px',
    lineHeight: '32px',
  },
  label: {
    width: '40%',
    fontSize: 14,
    fontWeight: '500',
    color: 'grey.800',
  },
  value: {
    maxWidth: '60%',
    fontSize: 14,
    display: 'flex',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    color: 'common.gray',
  },
  buttonContainer: {
    display: 'flex',
    mt: '40px',
  },
  button: {
    minWidth: 120,
  },
  cancelButton: {
    mr: '20px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
