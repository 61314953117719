import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TabTypes } from './useFormik';
import { PharmacistListTabTypes } from '../../helpers/Constants';

const useFormikUtils = (expected: Array<string>, fallback: string) => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const getPageFromUrl = () => {
    const pageParam = params.get('page');

    if (pageParam === null) {
      return 1;
    }

    const page = parseInt(pageParam, 10);

    if (Number.isNaN(page) || page <= 0) {
      return 1;
    }

    return page;
  };

  const [page, setPage] = useState(getPageFromUrl);

  let urlSelectedTab = (params.get('selectedTab') || PharmacistListTabTypes.activePharmacists) as TabTypes;

  if (!expected.includes(urlSelectedTab)) {
    urlSelectedTab = fallback as TabTypes;
  }

  return { page, setPage, urlSelectedTab, getPageFromUrl };
};

export default useFormikUtils;
