import React, { FC } from 'react';

import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  IAdminActiveUsersData,
  TypeOfUser,
  adminActions,
} from '@pharmaplan/common';
import { ReactComponent as LocationIcon } from '../../../../assets/svg/location.svg';

import styles from './styles';
import ThemeConstants from '../../../../theme/ThemeConstants';
import WithSkeleton from '../../../common/WithSkeleton';

interface IActiveUserItem {
  item: IAdminActiveUsersData;
  logo?: string;
  segment: TypeOfUser;
}

const { pharmacist } = TypeOfUser;
const ActiveUserItem: FC<IActiveUserItem> = ({ item, logo, segment }) => {
  const { name, city, image } = item ?? {};
  const isPharmacist = segment === pharmacist;
  const SkeletonTypography = WithSkeleton({
    successAction: adminActions.getActiveUsers,
    variant: 'text',
  })(Typography);

  const SkeletonAvatar = WithSkeleton({
    successAction: adminActions.getActiveUsers,
    variant: 'circular',
    height: 32,
    width: 32,
  })(Avatar);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.avatarContainer}>
        <SkeletonAvatar
          imgProps={
            !isPharmacist
              ? { style: styles.containStyle }
              : { style: styles.coverStyle }
          }
          sx={styles.avatar}
          src={logo || image}
        />
        <Box sx={styles.activeDot} />
      </Box>
      <Box sx={styles.detailsContainer}>
        <SkeletonTypography sx={styles.name}>{name}</SkeletonTypography>
        <Box sx={styles.infoContainer}>
          <LocationIcon
            height={12}
            width={12}
            fill={ThemeConstants.grey[800]}
          />
          <SkeletonTypography sx={styles.info}>{city}</SkeletonTypography>
        </Box>
      </Box>
    </Box>
  );
};
export default ActiveUserItem;
