import * as Yup from 'yup';
import { PublishTypes } from '@pharmaplan/common';
import { formikRequired } from '../../SoftwareList/validator';
import strings from '../../../../localization';

const Validator = () =>
  Yup.object().shape({
    name: formikRequired,
    languageId: formikRequired,
    platforms: Yup.array().min(1, strings.pleaseSelectOne),
    imageName: formikRequired,
    expiry: Yup.string().nullable().when('publishType', {
      is: PublishTypes.PublishTemporarily,
      then: () =>
        Yup.string().required(strings.requiredField),
    }),
  });

export default Validator;
