import httpClient from './httpClient';
import Routes from '../helpers/Routes';
import {
  AdminLegendVariant,
  IAdminApproveParams,
  IAdminBookAvailabilityParams,
  IAdminCalendarParams,
  IAdminEditWorkshiftParams,
  IAdminIgnoreRequestParams,
  IAdminSearchAPIParams,
  IExpressBookingAPIParams,
  IAdminPendingActivationAPIParams,
  IPharmacistSubmission,
  IPharmacyDetails,
  IRequestCancellationParams,
  IValidateExpressBookingAPIParams,
  IAdminTakeOwnershipAPIParams,
  IAdminCreateSoftwareApiParams,
  IAdminUpdateSoftwareApiParams,
  IAdminCreatePharmacistCapacityApiParams,
  IAdminUpdatePharmacistCapacityApiParams,
  IAdminConfiguration,
  ISalesforceAdmin,
  IAdminCreatePharmacyChainApiParams,
  IAdminUpdatePharmacyChainApiParams,
  IUpdatePharmacistRegions,
  IPendingCancellationApiParmas,
  IAdminApprovePendingCancellationApiParams,
  IAdminRejectPendingCancellationApiParams,
  IAdminActivePharmacistListApiParams,
  IAdminCreateAvailabilityApiParams,
  IAdminCreateNoteApiParams,
  IAdminUpdateNoteApiParams,
  IAdminPharmacistBookingsAPIParams,
  IAdminDeletePharmacistAvailabilityAPIParams,
  IDeactivePharmacistApiParams,
  IAdminListOfPharmaciesApiParams,
  IAdminPharmacistHistoryApiParams,
  IActivatePharmacistApiParams,
  IDeactivatedPharmacistListApiParams,
  IAdminCreateWorkshiftParams,
  IAdminListOfPharmacistsApiParams,
  IAdminIncompatibleMatchApiParams,
  IAdminUpdateIncompatibleMatchApiParams,
  IAdminPharmacistHistoryBaseApiParams,
  IAdminPharmacyWorkshiftsList,
  IAdminPharmacyWorkshiftsListAPIParams,
  IAdminDeleteWorkshift,
  IAdminPharmacyListBaseParams,
  IAdminDisapprove,
  IAdminReportsAPIParams,
  ICalendarEventType,
  IAdminListingApiParams,
  IApproveAdminsApiParams,
  IAdminDeleteSoftwaresAPIParams,
  IAdminDeletePharmacyChainsAPIParams,
  IAdminDeletePharmacistCapacitiesAPIParams,
  ICreateAdminParams,
  IUpdateAdminParams,
  IAdminCreateContactPreferenceApiParams,
  IAdminUpdateContactPreferenceApiParams,
  IAdminDeleteContactPreferenceApiParams,
  IAdminPharmacistDetailsSearchApiParams,
  IAdminUpdatePharmacyNoteApiParams,
  IAdminCreatePharmacyNoteApiParams,
  IAdminBroadcastUserGroupsPharmacyApiParams,
  IAdminBroadcastUserGroupsPharmacistApiParams,
  IAdminCreateBroadcastGroupApiParams,
  IAdminUpdateBroadcastGroupApiParams,
  IAdminBroadcastGroupListApiParams,
  IBroadcastNotificationsApiParams,
  IAdminSendBroadcastApiParams,
  IAdminLogsGenericParams,
  IDeleteBroadcastGroupApiParams,
  IDeleteBroadcastNotificationApiParams,
  IUpdateAdminInfo,
  ICreatePharmacyAdmin,
  IUpdatePharmacyAdmin,
  IGetPharmaciesForPharmacyAdminParams,
  IDeletePharmacyAdminParams,
  IAppointPharmacyParams,
  IBroadcastGroupParams,
  IPharmacyAdminListApiParams,
  IAdminDashboardFilterAPIParams,
  IAdminMultipleAvailabilityCalendarParams,
  IAdminPharmacistVacationsDeleteParams,
  IAdminStatisticsParams,
  IAdminDeleteSingleWorkshift,
  IAllClientApiParams,
  IAdminResendEmail,
  IGenericSortingParams,
  ICreateAdvertisementParams,
  IUpdateAdvertisementParams,
} from '../models/AdminModels';
import {
  CreateTypes,
  ITEMS_PER_PAGE,
  PendingCancellationDetailsRouteTypes,
  PendingCancellationTabTypes,
  TypeOfUser,
} from '../helpers/Constants';
import {
  IPreSubmitVacationsParams,
  IUpdateVacationsParams,
  IVacationsParams,
} from '../models/PharmacistModels';

class AdminController {
  basePath: string;
  broadcastGroupPath: string;
  statisticsPath: string;

  constructor() {
    this.basePath = Routes.admin.adminBasePath;
    this.broadcastGroupPath = `${this.basePath}${Routes.admin.broadcasts}${Routes.admin.groups}`;
    this.statisticsPath = `${this.basePath}${Routes.admin.statistics}`;
  }
  calendar = async (params: IAdminDashboardFilterAPIParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.calendar}`, params);

  calendarFilters = async () =>
    httpClient.get(`${this.basePath}${Routes.admin.calendar}`);

  map = async (params: IAdminCalendarParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.map}`, params);

  activeUsers = async (page: number, user: TypeOfUser) =>
    httpClient.post(
      `${this.basePath}/${CreateTypes[user]}${Routes.admin.active}`,
      {
        pagingModel: {
          page,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  getAdminEvent = async (type: AdminLegendVariant, date: string) =>
    httpClient.get(`${this.basePath}${Routes.general.events}/${type}/${date}`);

  workshiftDetails = async (workshiftId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.workshifts}${Routes.admin.posted}/${workshiftId}`
    );

  deleteWorkshift = async (workshiftId: string) =>
    httpClient.delete(
      `${this.basePath}${Routes.admin.workshifts}/${workshiftId}`
    );

  editWorkshift = async (params: IAdminEditWorkshiftParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.workshifts}`, {
      model: params,
    });

  requestedPharmacists = async (workshiftId: string) =>
    httpClient.get(`${this.basePath}${Routes.admin.requested}/${workshiftId}`);

  requestedDetails = async (bookingId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.workshifts}${Routes.admin.requested}/${bookingId}`
    );

  ignoreDetails = async (bookingId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.general.bookings}${Routes.pharmacy.ignore}/${bookingId}`
    );

  ignoreRequest = async (params: IAdminIgnoreRequestParams) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.pharmacy.ignore}`,
      params
    );

  approveDetails = async (bookingId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.general.bookings}${Routes.pharmacy.approve}/${bookingId}`
    );

  adminApproveBookings = async (params: IAdminApproveParams) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.pharmacy.approve}`,
      { model: params }
    );

  getAdminRatingItems = async () =>
    httpClient.get(`${this.basePath}${Routes.pharmacy.ratingItems}`);

  adminBookedPharmacistDetails = async (bookingId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.workshifts}${Routes.admin.booked}/${bookingId}`
    );

  requestCancellation = async (params: IRequestCancellationParams) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.general.submit}${Routes.admin.cancellation}`,
      params
    );

  getRequestCancellation = async (bookingId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.general.bookings}${Routes.general.submit}${Routes.admin.cancellation}/${bookingId}`
    );

  getAdminPharmacistRating = async (pharmacistId: string) =>
    httpClient.get(`${this.basePath}${Routes.pharmacy.rating}/${pharmacistId}`);

  getMatchingAvailabilities = async (workshiftId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.matchingAvailabilities}/${workshiftId}`
    );

  getOtherAvailabilities = async (workshiftId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.notMatchingAvailabilities}/${workshiftId}`
    );

  getBookAvailablity = async (workshiftId: string, availabilityId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.bookAvailability}/${workshiftId}/${availabilityId}`
    );

  bookAvailablity = async (params: IAdminBookAvailabilityParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.bookAvailability}`, {
      model: params,
    });

  getPharmacyDetails = async (pharmacyId: string) =>
    httpClient.get(`${this.basePath}${Routes.admin.pharmacies}/${pharmacyId}`);

  getPharmacistDetails = async (pharmacistId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacists}/${pharmacistId}`
    );

  expressBooking = async (params: IExpressBookingAPIParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.expressBooking}`, {
      model: params,
    });

  validateExpressBooking = async ({
    pharmacistId,
    pharmacyId,
  }: IValidateExpressBookingAPIParams) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.validateExpressBooking}/${pharmacyId}/${pharmacistId}`
    );

  searchUsers = async ({
    userTypes,
    query,
    active = true,
    isForDeletedUsers,
    showAppointedPharmacies,
  }: IAdminSearchAPIParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.users}`, {
      active,
      input: query,
      userTypes,
      isForDeletedUsers,
      showAppointedPharmacies,
    });

  pendingActivation = async (params: IAdminPendingActivationAPIParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.pendingActivations}`, {
      model: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  submitPharmacyDetails = async (params: IPharmacyDetails) =>
    httpClient.put(`${this.basePath}${Routes.admin.pharmacies}`, {
      model: params,
    });

  submitPharmacistDetails = async (params: IPharmacistSubmission) =>
    httpClient.put(`${this.basePath}${Routes.admin.pharmacists}`, {
      model: params,
    });

  takeOwnership = async (params: IAdminTakeOwnershipAPIParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.takeOwnership}`, params);

  adminDeletePendingUser = async ({
    userType,
    id,
  }: IAdminTakeOwnershipAPIParams) =>
    httpClient.delete(
      `${this.basePath}${Routes.admin.users}/${userType}/${id}`
    );

  adminGetDeletedUsers = async (params: IAdminPendingActivationAPIParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.deletedUsers}`, {
      model: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  adminActivateUser = async (params: IAdminTakeOwnershipAPIParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.users}${Routes.admin.activate}`,
      params
    );

  uploadPharmacistPhoto = async (pharmacistId: string, form?: any) =>
    httpClient.post(
      `${Routes.pharmacist.fileupload}${this.basePath}${Routes.admin.pictures}`,
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { pharmacistId },
      }
    );

  uploadPharmacistResume = async (pharmacistId: string, form: any) =>
    httpClient.post(
      `${Routes.pharmacist.fileupload}${this.basePath}${Routes.admin.resumes}`,
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { pharmacistId },
      }
    );

  deletedUserProfile = async (userId: string, approved = false) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.deletedUsers}/${userId}/${approved}`
    );

  getSoftwareList = async (params: IGenericSortingParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.softwaresList}`, params);

  getSoftware = async (softwareId: string) =>
    httpClient.get(`${this.basePath}${Routes.admin.softwares}/${softwareId}`);

  createSoftware = async (params: IAdminCreateSoftwareApiParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.softwares}`, {
      model: params,
    });

  updateSoftware = async (params: IAdminUpdateSoftwareApiParams) =>
    httpClient.put(`${this.basePath}${Routes.admin.softwares}`, {
      model: params,
    });

  getPharmacistCapacitiesList = async (params: IGenericSortingParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacistCapacitiesList}`,
      params
    );

  getPharmacistCapacity = async (capacityId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacistCapacities}/${capacityId}`
    );

  createPharmacistCapacity = async (
    params: IAdminCreatePharmacistCapacityApiParams
  ) =>
    httpClient.post(`${this.basePath}${Routes.admin.pharmacistCapacities}`, {
      model: params,
    });

  updatePharmacistCapacity = async (
    params: IAdminUpdatePharmacistCapacityApiParams
  ) =>
    httpClient.put(`${this.basePath}${Routes.admin.pharmacistCapacities}`, {
      model: params,
    });

  contactPreferencesList = async (params: IGenericSortingParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.contactPreferencesList}`,
      params
    );

  getAdminConfiguration = async () =>
    httpClient.get(`${this.basePath}${Routes.admin.pharmaplan}`);

  getSalesforceAdmins = async () =>
    httpClient.get(`${this.basePath}${Routes.admin.salesforceAdmins}`);

  createSalesforceAdmins = async (params: ISalesforceAdmin) =>
    httpClient.post(`${this.basePath}${Routes.admin.salesforceAdmins}`, {
      model: params,
    });

  editSalesforceAdmins = async (params: ISalesforceAdmin) =>
    httpClient.put(`${this.basePath}${Routes.admin.salesforceAdmins}`, {
      model: params,
    });

  editAdminConfiguration = async (params: IAdminConfiguration) =>
    httpClient.put(`${this.basePath}${Routes.admin.pharmaplan}`, {
      model: params,
    });

  deleteSalesforceAdmins = async (id: string) =>
    httpClient.delete(`${this.basePath}${Routes.admin.salesforceAdmins}/${id}`);

  getPharmacyChainList = async (params: IGenericSortingParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacyChainList}`,
      params
    );

  getPharmacyChain = async (chainId: string) =>
    httpClient.get(`${this.basePath}${Routes.admin.pharmacyChain}/${chainId}`);

  createPharmacyChain = async ({
    name,
    published,
    form,
  }: IAdminCreatePharmacyChainApiParams) =>
    httpClient.post(
      `${Routes.general.fileupload}${this.basePath}${Routes.admin.pharmacyChain}`,
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { name: name, published },
      }
    );

  updatePharmacyChain = async ({
    name,
    published,
    pharmacyChainId,
    isLogoChanged,
    form,
  }: IAdminUpdatePharmacyChainApiParams) =>
    httpClient.put(
      `${Routes.general.fileupload}${this.basePath}${Routes.admin.pharmacyChain}`,
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { pharmacyChainId, name: name, published, isLogoChanged },
      }
    );

  getPharmacistRegions = async (pharmacistId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacistRegions}/${pharmacistId}`
    );

  getAdminPharmacistResumes = async (pharmacistId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacistResumes}/${pharmacistId}`
    );

  updatePharmacistRegions = async ({
    pharmacistId,
    regions,
  }: IUpdatePharmacistRegions) =>
    httpClient.post(`${this.basePath}${Routes.admin.pharmacistRegions}`, {
      pharmacistId,
      regions,
    });

  pendingCancellation = async (params: IPendingCancellationApiParmas) =>
    httpClient.post(`${this.basePath}${Routes.admin.pendingCancellations}`, {
      model: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  pendingCancellationCodeDetails = async (
    id: string,
    type: PendingCancellationTabTypes
  ) =>
    httpClient.get(
      `${this.basePath}/${PendingCancellationDetailsRouteTypes[type]}/${id}`
    );

  approvePendingCancellation = async (
    params: IAdminApprovePendingCancellationApiParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pendingCancellations}${Routes.pharmacy.approve}`,
      params
    );

  rejectPendingCancellation = async (
    params: IAdminRejectPendingCancellationApiParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pendingCancellations}${Routes.pharmacy.reject}`,
      params
    );

  cancelledBookings = async (params: IPendingCancellationApiParmas) =>
    httpClient.post(`${this.basePath}${Routes.admin.cancelledBookings}`, {
      model: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  getCancelledBookingDetails = async (cancellationId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.cancelledBookings}/${cancellationId}`
    );

  getActivePharmacistList = async (
    params: IAdminActivePharmacistListApiParams
  ) =>
    httpClient.post(`${this.basePath}${Routes.admin.pharmacists}`, {
      model: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  createAvailabilities = async (params: IAdminCreateAvailabilityApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.availabilities}`,
      params
    );

  rejectedCancellation = async (params: IPendingCancellationApiParmas) =>
    httpClient.post(`${this.basePath}${Routes.admin.rejectedCancellations}`, {
      model: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  getAllAdminNotes = async (pharmacistId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.allNotes}/${pharmacistId}`
    );

  getAdminNote = async (pharmacistNoteId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.notes}/${pharmacistNoteId}`
    );

  createAdminNote = async (params: IAdminCreateNoteApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.notes}`,
      params
    );

  updateAdminNote = async (params: IAdminUpdateNoteApiParams) =>
    httpClient.put(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.notes}`,
      params
    );

  pharmacistBookingsList = async (params: IAdminPharmacistBookingsAPIParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.bookings}`,
      {
        ...params,
        paging: {
          ...params.paging,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  pharmacistBooking = async (bookingId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.bookings}/${bookingId}`
    );

  showAvailabilities = async (params: IAdminPharmacistBookingsAPIParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.showAvailabities}`,
      {
        ...params,
        paging: {
          ...params.paging,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  deletePharmacistAvailability = async (
    params: IAdminDeletePharmacistAvailabilityAPIParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.availabilities}${Routes.general.delete}`,
      params
    );

  matchingWorkshifts = async (availabilityId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.matchingWorkshifts}/${availabilityId}`
    );

  hidePharmacist = async (params: IDeactivePharmacistApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.hide}`,
      params
    );

  deactivatePharmacist = async (params: IDeactivePharmacistApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.deactivate}`,
      params
    );

  adminPharmacistPictures = async (pharmacistIds: Array<string>) =>
    httpClient.post(`${this.basePath}${Routes.admin.pharmacistPictures}`, {
      pharmacistIds,
    });

  getPendingActivationsCount = async () =>
    httpClient.get(`${this.basePath}${Routes.admin.pendingActivationsCount}`);

  getOwnersList = async () =>
    httpClient.get(`${this.basePath}${Routes.admin.admins}`);

  adminListOfPharmacies = async (params: IAdminListOfPharmaciesApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}/${Routes.admin.replacements}`,
      {
        ...params,
        paging: {
          ...params.paging,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  adminPharmacistHistory = async (params: IAdminPharmacistHistoryApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.replacements}${Routes.general.history}`,
      {
        ...params,
        paging: {
          ...params.paging,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  adminPharmacistNotes = async (params: IAdminPharmacistHistoryBaseApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.replacements}${Routes.general.notes}`,
      params
    );

  deactivatedPharmacistList = async (
    params: IDeactivatedPharmacistListApiParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.inactive}`,
      {
        model: {
          itemPerPage: ITEMS_PER_PAGE,
          ...params,
        },
      }
    );

  hiddenPharmacistList = async (params: IDeactivatedPharmacistListApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.hidden}`,
      {
        model: {
          itemPerPage: ITEMS_PER_PAGE,
          ...params,
        },
      }
    );

  activatePharmacist = async (params: IActivatePharmacistApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.activate}`,
      params
    );

  showPharmacist = async (params: IDeactivePharmacistApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.show}`,
      params
    );

  deletedPharmacistList = async (params: IDeactivatedPharmacistListApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.deleted}`,
      {
        model: {
          itemPerPage: ITEMS_PER_PAGE,
          ...params,
        },
      }
    );
  getActivePharmacyList = async (params: IAdminPharmacyListBaseParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.pharmacies}`, {
      model: { ...params, itemPerPage: ITEMS_PER_PAGE },
    });

  createWorkshiftAdmin = async (params: IAdminCreateWorkshiftParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.admin.workshifts}`,
      params
    );

  getPublishedSoftwareList = async () =>
    httpClient.get(
      `${this.basePath}${Routes.admin.softwares}${Routes.admin.published}`
    );

  adminListOfPharmacists = async (params: IAdminListOfPharmacistsApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.admin.replacements}`,
      {
        ...params,
        paging: {
          ...params.paging,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  adminPharmacyHistory = async (params: IAdminPharmacistHistoryApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.admin.replacements}${Routes.general.history}`,
      {
        ...params,
        paging: {
          ...params.paging,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  adminPharmacytNotes = async (params: IAdminPharmacistHistoryBaseApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.admin.replacements}${Routes.general.notes}`,
      params
    );

  incompatibleMatchList = async (params: IAdminIncompatibleMatchApiParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.incompatibleMatches}`, {
      ...params,
      paging: {
        ...params.paging,
        itemPerPage: ITEMS_PER_PAGE,
      },
    });

  updateIncompatibleMatch = async (
    params: IAdminUpdateIncompatibleMatchApiParams
  ) =>
    httpClient.put(
      `${this.basePath}${Routes.pharmacy.incompatibleMatches}`,
      params
    );

  deleteSelectedPendingActivations = async (userIds: Array<string>) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.deletedUsers}${Routes.admin.permanentDelete}`,
      { userIds }
    );

  pharmacyWorkshiftsList = async (
    params: IAdminPharmacyWorkshiftsListAPIParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.general.allWorkshifts}`,
      {
        ...params,
        pagingModel: {
          ...params.pagingModel,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  adminDeleteMultiWorkshift = async (params: IAdminDeleteWorkshift) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.admin.workshifts}${Routes.general.delete}`,
      params
    );

  deletePharmacyWorkshift = async (params: IAdminDeleteSingleWorkshift) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.workshifts}${Routes.general.delete}`,
      params
    );

  getDeactivatedPharmacyList = async (params: IAdminPharmacyListBaseParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.admin.inactive}`,
      {
        model: { ...params, itemPerPage: ITEMS_PER_PAGE },
      }
    );

  getDeletedPharmacyList = async (params: IAdminPharmacyListBaseParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.admin.deleted}`,
      {
        model: { ...params, itemPerPage: ITEMS_PER_PAGE },
      }
    );

  adminDisapprove = async (params: IAdminDisapprove) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.users}${Routes.admin.disapprove}`,
      params
    );

  adminApprove = async (params: IAdminDisapprove) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.users}${Routes.admin.approve}`,
      params
    );

  adminReports = async (params: IAdminReportsAPIParams) =>
    httpClient.post(`${this.basePath}${Routes.general.reports}`, {
      reportFilters: {
        ...params,
        itemPerPage: ITEMS_PER_PAGE,
      },
    });

  getAllPharmacistAvailabilities = async (
    params: IAdminMultipleAvailabilityCalendarParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.calendar}`,
      params
    );

  adminReportsPDF = async (params: IAdminReportsAPIParams) =>
    httpClient.post(
      `${this.basePath}${Routes.general.reports}${Routes.general.getPDF}`,
      {
        reportFilters: {
          ...params,
          itemPerPage: 0,
        },
      }
    );

  getActiveAdmins = async (params: IAdminListingApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.admins}${Routes.admin.active}`,
      {
        model: {
          ...params,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  adminReportsFilter = async (type: ICalendarEventType) =>
    httpClient.get(`${this.basePath}${Routes.general.reports}/${type}`);

  getInactiveAdmins = async (params: IAdminListingApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.admins}${Routes.admin.inactive}`,
      {
        model: {
          ...params,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  approveAdmins = async (params: IApproveAdminsApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.admins}${Routes.admin.approve}`,
      params
    );

  disapproveAdmins = async (params: IApproveAdminsApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.admins}${Routes.admin.disapprove}`,
      params
    );

  deletePharmacyChains = async (params: IAdminDeletePharmacyChainsAPIParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacyChain}${Routes.general.delete}`,
      params
    );

  deletePharmacistCapacities = async (
    params: IAdminDeletePharmacistCapacitiesAPIParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacistCapacities}${Routes.general.delete}`,
      params
    );

  deleteSoftwares = async (params: IAdminDeleteSoftwaresAPIParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.softwares}${Routes.general.delete}`,
      params
    );

  getAdminProfile = async () =>
    httpClient.get(
      `${this.basePath}${Routes.admin.admins}${Routes.admin.profile}`
    );

  createAdmin = async (params: ICreateAdminParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.admins}`, {
      model: params,
    });

  updateAdmin = async (params: IUpdateAdminParams) =>
    httpClient.put(`${this.basePath}${Routes.admin.admins}`, {
      model: params,
    });

  getAdminDetails = async (userId: string) =>
    httpClient.get(`${this.basePath}${Routes.admin.admins}/${userId}`);

  createContactPreference = async (
    params: IAdminCreateContactPreferenceApiParams
  ) =>
    httpClient.post(`${this.basePath}${Routes.admin.contactPreferences}`, {
      model: params,
    });

  updateContactPreference = async (
    params: IAdminUpdateContactPreferenceApiParams
  ) =>
    httpClient.put(`${this.basePath}${Routes.admin.contactPreferences}`, {
      model: params,
    });

  deleteContactPreference = async (
    params: IAdminDeleteContactPreferenceApiParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.contactPreferences}${Routes.general.delete}`,
      params
    );

  getContactPreference = async (contactPreferenceId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.contactPreferences}/${contactPreferenceId}`
    );

  getPharmacistDetailsSearch = async (
    params: IAdminPharmacistDetailsSearchApiParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.detailsSearch}`,
      {
        ...params,
        paging: {
          ...params.paging,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  getPharmacistDetailsSearchFilters = async () =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.general.filters}`
    );

  getAllPharmacyAdminNotes = async (pharmacyId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.admin.allNotes}/${pharmacyId}`
    );

  getPharmacyAdminNote = async (pharmacyNoteId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.general.notes}/${pharmacyNoteId}`
    );

  createPharmacyAdminNote = async (params: IAdminCreatePharmacyNoteApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.general.notes}`,
      params
    );

  updatePharmacyAdminNote = async (params: IAdminUpdatePharmacyNoteApiParams) =>
    httpClient.put(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.general.notes}`,
      params
    );

  getPharmaciesInfo = async (pharmacyId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.user.info}/${pharmacyId}`
    );

  getRatingDetails = async (pharmacistId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.ratingDetails}/${pharmacistId}`
    );

  adminSettings = async () =>
    httpClient.get(`${this.basePath}${Routes.general.settings}`);

  deleteCancelledBookings = async (bookingIds: Array<string>) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.cancelledBookings}${Routes.general.delete}`,
      { bookingIds }
    );

  deleteRejectedCancellations = async (cancellationIds: Array<string>) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.rejectedCancellations}${Routes.general.delete}`,
      { cancellationIds }
    );

  getBroadcastGroupUsers = async (
    params:
      | IAdminBroadcastUserGroupsPharmacyApiParams
      | IAdminBroadcastUserGroupsPharmacistApiParams
  ) =>
    httpClient.post(`${this.broadcastGroupPath}${Routes.admin.users}`, {
      model: {
        ...params,
        itemPerPage: ITEMS_PER_PAGE,
      },
    });

  createBroadcastGroups = async (params: IAdminCreateBroadcastGroupApiParams) =>
    httpClient.post(`${this.broadcastGroupPath}`, params);

  updateBroadcastGroups = async (params: IAdminUpdateBroadcastGroupApiParams) =>
    httpClient.put(`${this.broadcastGroupPath}`, params);

  deleteBroadcastGroup = async (params: IDeleteBroadcastGroupApiParams) =>
    httpClient.post(
      `${this.broadcastGroupPath}${Routes.general.delete}`,
      params
    );

  getBroadcastList = async (params: IAdminBroadcastGroupListApiParams) =>
    httpClient.post(`${this.broadcastGroupPath}${Routes.general.list}`, {
      ...params,
      pagingModel: {
        itemPerPage: ITEMS_PER_PAGE,
        ...params.pagingModel,
      },
    });

  getBroadcastGroup = async (id: string) =>
    httpClient.get(`${this.broadcastGroupPath}/${id}`);

  getBroadcastFilter = async () =>
    httpClient.get(`${this.broadcastGroupPath}${Routes.general.filters}`);

  getBroadcastNotifications = async (
    params: IBroadcastNotificationsApiParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.broadcasts}${Routes.general.history}`,
      {
        model: {
          ...params,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  getLogsFilters = async () =>
    httpClient.get(
      `${this.basePath}${Routes.admin.logs}${Routes.general.filters}`
    );

  getWorkshiftLogs = async (params: IAdminLogsGenericParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.logs}${Routes.admin.workshifts}`,
      {
        model: {
          ...params,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  getGroupRecipients = async (groupIds: Array<string>) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.broadcasts}${Routes.admin.users}`,
      {
        groupIds,
      }
    );

  sendBroadcast = async (params: IAdminSendBroadcastApiParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.broadcasts}`, {
      model: params,
    });

  getBroadcastNotification = async (id: string) =>
    httpClient.get(`${this.basePath}${Routes.admin.broadcasts}/${id}`);

  deleteBroadcastNotification = async (
    params: IDeleteBroadcastNotificationApiParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.broadcasts}${Routes.general.delete}`,
      params
    );

  getAvailabilitiesLogs = async (params: IAdminLogsGenericParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.logs}${Routes.admin.availabilities}`,
      {
        model: {
          ...params,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  getBookingLogs = async (params: IAdminLogsGenericParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.logs}${Routes.general.bookings}`,
      {
        model: {
          ...params,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  getActivitiesLogs = async (params: IAdminLogsGenericParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.logs}${Routes.admin.activities}`,
      {
        model: {
          ...params,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  getAdminInfo = async () =>
    httpClient.get(`${this.basePath}${Routes.admin.me}`);

  updateAdminInfo = async (params: IUpdateAdminInfo) =>
    httpClient.put(`${this.basePath}${Routes.admin.me}`, params);

  getAdminPicture = async () =>
    httpClient.get(`${this.basePath}${Routes.admin.picture}`);

  deleteAdminPicture = async () =>
    httpClient.delete(`${this.basePath}${Routes.admin.picture}`);

  uploadAdminPicture = async (form: any) =>
    httpClient.post(
      `${Routes.pharmacist.fileupload}${this.basePath}${Routes.admin.picture}`,
      form,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

  getPharmacyAdminList = async (params: IPharmacyAdminListApiParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacyAdminList}`,
      params
    );

  getPharmacyAdminDetails = async (id: string) =>
    httpClient.get(`${this.basePath}${Routes.admin.pharmacyAdmins}/${id}`);

  createPharmacyAdmin = async (params: ICreatePharmacyAdmin) =>
    httpClient.post(`${this.basePath}${Routes.admin.pharmacyAdmins}`, {
      model: params,
    });

  editPharmacyAdmin = async (params: IUpdatePharmacyAdmin) =>
    httpClient.put(`${this.basePath}${Routes.admin.pharmacyAdmins}`, {
      model: params,
    });

  pharmacyAdminProfile = async () =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacyAdmins}${Routes.admin.profile}`
    );

  getPharmaciesForPharmacyAdmin = async (
    params: IGetPharmaciesForPharmacyAdminParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacyAdmins}${Routes.admin.pharmacies}`,
      {
        model: {
          ...params,
          itemPerPage: ITEMS_PER_PAGE,
        },
      }
    );

  deletePharmacyAdmin = async (params: IDeletePharmacyAdminParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacyAdmins}${Routes.general.delete}`,
      params
    );

  appointExistingPharmacy = async (params: IAppointPharmacyParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacyAdmins}${Routes.admin.appoint}`,
      params
    );

  appointedPharmaciesList = async (params: IAdminPharmacyListBaseParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacies}${Routes.admin.appointed}`,
      {
        model: { ...params, itemPerPage: ITEMS_PER_PAGE },
      }
    );

  watchlists = async (pharmacistId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.watchlists}/${pharmacistId}`
    );

  broadcastGroupSelectedUsers = async (params: IBroadcastGroupParams) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.broadcasts}${Routes.admin.groups}${Routes.admin.selectedUsers}`,
      {
        groupId: params.groupId,
        pagingModel: { ...params.pagingModel, itemPerPage: ITEMS_PER_PAGE },
      }
    );

  adminPreSubmitVacations = async (params: IPreSubmitVacationsParams) => {
    const { pharmacistId, ...rest } = params;

    return httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.pharmacist.vacations}${Routes.general.preSubmit}`,
      {
        pharmacistId,
        model: rest,
      }
    );
  };

  adminPharmacistVacations = async (params: IVacationsParams) => {
    const { pharmacistId, ...rest } = params;

    return httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.pharmacist.vacations}`,
      {
        pharmacistId,
        model: rest,
      }
    );
  };

  getAdminPharmacistVacations = async (id: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.pharmacist.vacations}/${id}`
    );

  deleteAdminPharmacistVacations = async (
    params: IAdminPharmacistVacationsDeleteParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.pharmacist.vacations}${Routes.general.delete}`,
      params
    );

  updateAdminPharmacistVacations = async (params: IUpdateVacationsParams) =>
    httpClient.put(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.pharmacist.vacations}`,
      {
        model: params,
      }
    );

  adminPreSubmitAvailabilities = async (
    params: IAdminCreateAvailabilityApiParams
  ) =>
    httpClient.post(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.availabilities}${Routes.general.preSubmit}`,
      params
    );

  preSubmitDeleteAvailabilities = async (availabilityId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacists}${Routes.admin.availabilities}${Routes.general.delete}${Routes.general.preSubmit}/${availabilityId}`
    );

  activeUsersStatistics = async (params: IAdminStatisticsParams) =>
    httpClient.get(
      `${this.statisticsPath}${Routes.admin.users}/${params.startDate}/${params.endDate}`
    );

  cancellationStatistics = async (params: IAdminStatisticsParams) =>
    httpClient.get(
      `${this.statisticsPath}${Routes.admin.cancellation}/${params.startDate}/${params.endDate}`
    );

  financialStatistics = async (params: IAdminStatisticsParams) =>
    httpClient.get(
      `${this.statisticsPath}${Routes.admin.financial}/${params.startDate}/${params.endDate}`
    );

  workshiftStatistics = async (params: IAdminStatisticsParams) =>
    httpClient.get(
      `${this.statisticsPath}${Routes.general.workshift}/${params.startDate}/${params.endDate}`
    );

  dailyStats = async () =>
    httpClient.get(`${this.statisticsPath}${Routes.admin.daily}`);

  adminUploadPharmacyAdminPicture = async (
    pharmacyAdminId: string,
    form: FormData | null
  ) =>
    httpClient.post(
      `${Routes.pharmacist.fileupload}${this.basePath}${Routes.admin.pharmacyAdmins}${Routes.pharmacist.picture}`,
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { pharmacyAdminId },
      }
    );

  adminGetPharmacyAdminPicture = async (pharmacyAdminId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.admin.pharmacyAdmins}${Routes.pharmacist.picture}/${pharmacyAdminId}`
    );

  allClientUsers = async (params: IAllClientApiParams) =>
    httpClient.post(`${this.basePath}${Routes.admin.allUsers}`, {
      ...params,
      pagingModel: {
        ...params.pagingModel,
        itemPerPage: ITEMS_PER_PAGE,
      },
    });

  adminResendEmail = async (params: IAdminResendEmail) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.general.resendEmail}`,
      params
    );

  getAllAdvertisements = async (params: IGenericSortingParams) =>
    httpClient.post(`${this.basePath}${Routes.general.advertisements}`, params);

  getAdvertisement = async (advertisementId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.general.advertisements}/${advertisementId}`
    );

  deleteAdvertisements = async (advertisementId: string) =>
    httpClient.delete(
      `${this.basePath}${Routes.general.advertisements}/${advertisementId}`
    );

  getAdvertisementFilters = async () =>
    httpClient.get(
      `${this.basePath}${Routes.general.advertisements}${Routes.general.filters}`
    );

  createAdvertisement = async (params: ICreateAdvertisementParams) => {
    const { form, ...rest } = params;

    const advertisingModel = btoa(JSON.stringify(rest));

    return httpClient.post(
      `${Routes.general.fileupload}${this.basePath}${Routes.general.advertisements}`,
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: (form) => form,
        params: { advertisingModel },
      }
    );
  };

  updateAdvertisement = async (params: IUpdateAdvertisementParams) => {
    const { form, ...rest } = params;
    const advertisingModel = btoa(JSON.stringify(rest));

    return httpClient.put(
      `${Routes.general.fileupload}${this.basePath}${Routes.general.advertisements}`,
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: (form) => form,
        params: { advertisingModel },
      }
    );
  };
}

export default new AdminController();
