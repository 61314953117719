import * as Yup from 'yup';
import strings from '../../../localization';

const Validator = () =>
  Yup.object().shape({
    description: Yup.string().required(strings.requiredField),
    issues: Yup.string().required(strings.requiredField).min(1, strings.requiredField),
  });

export default Validator;
